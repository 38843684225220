import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import StarRatings from 'react-star-ratings';
import bubble from './../audio/bubble.mp3';
import ping from './../audio/ping.mp3';
import openSocket from 'socket.io-client';
import Variable from './../variable'

const audio = new Audio(bubble);
const audio2 = new Audio(ping);

export default class componentName extends Component {

    host = Variable.host;

    socket;

    state = { 
        rating:0,
        isOpen: false,
        value: undefined,
        addModalOpen: false,
        ticketTitle: "",
        phone: "",
        email: "",
        description: "",
        name: "",
        titleError: false,
        phoneError: false,
        emailError: false,
        desError: false,
        nameError: false,
        continue: false,
        clientInfo: {},
        liveChatError: false,
        liveChatSubmitted: false,
        firstLoad: true,
        errorTitle: "Invalid link",
        errorText:"This link maybe used or deleted. Please request a link from your live chat provider again.",
        ended: false,
        reason: "",
        queueNumber:0,
        socketError:false,
        noAgent: false,
        formSubmitted:false
      };

    componentDidMount(){
        const queryString = window.location.search;

        const urlParams = new URLSearchParams(queryString);

        const name = urlParams.get('name');

        const phone = urlParams.get('phone');

        const email = urlParams.get('email');

        const ic = urlParams.get('ic');

        const reason = urlParams.get('reason');

        const title = urlParams.get('title');

        const api = urlParams.get('api');

        this.setState({api:api});



        axios.post(this.host+'/api/livechat/info', {token:this.props.match.params.token},{withCredentials: true})
        .then(res => {
            if(res.data.result === "success"){
                
                if(res.data.ticket_id === null){
                    
                    this.setState({clientInfo: res.data,firstLoad:false},()=>{
                        if((name !== null && phone !== null && email !== null ) || !api){
                            this.setState({phone:phone,name:name,description:reason || name,email:email,continue:true,ic:ic || "",reason: reason || ""},()=>{
                                this.submitLiveChat();
                            })
                        }
                    });
                }
                else{
                    window.location.replace("/start/livechat/"+this.props.match.params.token);
                }
            }
            else if(res.data.result === "converted"){
                let info = {csrf:res.data.csrf};
                this.setState({firstLoad:false,errorTitle:"Live Chat Converted",errorText:"Your live chat has been converted to ticket, thank you!",ended:true,clientInfo:info});
            }            
            else if(res.data.result === "rating"){
                let info = {csrf:res.data.csrf};
                this.setState({firstLoad:false,errorTitle:"Live Chat Ended",errorText:"Thank you for contacting us!",ended:true,clientInfo:info});
            }
            else if(res.data.result === "noAgent"){
                this.setState({noAgent: true,clientInfo: res.data});
                if(name !== null && phone !== null && email !== null){
                    this.setState({phone:phone,name:name,description:name,email:email},()=>{
                    })
                }                
            }             
            else if(res.data.result === "closed"){
                this.setState({firstLoad:false,errorTitle:"Live Chat Ended",errorText:"Thank you for contacting us!"});
            }            
            else{
                this.setState({firstLoad:false});
            }
        }).catch(err => {
            this.setState({firstLoad:false});
        });
    }
  
    changeRating = ( newRating, name ) => {
        this.setState({
          rating: newRating,
          starError: false
        });
        if(newRating === 5){
            this.setState({reasonError:false});
        }
    }

    submitRating = () => {
        let error = false
        if(this.state.rating === 0){
            error = true;
            this.setState({starError: true});
        }
        else if(this.state.rating < 5 && this.state.reason === ""){
            error = true;
            this.setState({reasonError:true});
        }
        if(!error){
            axios.post(this.host+'/api/livechat/rate', {_token:this.state.clientInfo.csrf,token:this.props.match.params.token,rating:this.state.rating,reason:this.state.reason},{withCredentials: true})
            .then(res => {
                if(res.data.result === "success"){
                    this.setState({submitted:true})   
                } 
            })
            
        }
    }

    reasonChanged = (e) => {
        this.setState({reason:e.target.value,reasonError:false});
    }

    submitLiveChat = () => {
        var error = false;
        // if(this.state.ticketTitle === ""){
        //   this.setState({titleError : true});
        //   error = true;
        // }
        // else{
        //   this.setState({titleError : false});
        // }
        if(this.state.phone === ""){
          this.setState({phoneError : true});
          error = true;
        }
        else{
          this.setState({phoneError : false});
        }     
        if(this.state.email === ""){
          this.setState({emailError : true});
          error = true;
        }
        else{
          this.setState({emailError : false});
        }      
        if(this.state.description === ""){
          this.setState({desError : true});
          error = true;
        }
        else{
          this.setState({desError : false});
        }     
        if(this.state.name === ""){
          this.setState({nameError : true});
          error = true;
        }
        else{
          this.setState({nameError : false});
        }              
  
        if(!error){
            if(!this.state.noAgent){
                this.setState({liveChatSubmitted:true}); 
                this.socket = openSocket("https://ticket-api.superceed.com:8000");
                this.socket.emit("registerQueue",{token:this.props.match.params.token,csrf:this.state.clientInfo.csrf});
                this.socket.on("queueNumber",(data)=>{
                    this.setState({queueNumber:data.count});
                });
                this.socket.on("disconnect", ()=>{
                    this.setState({ socketError: true })
                });
        
                this.socket.on("connect_failed", ()=>{
                    this.setState({ socketError: true })
                });               
                this.socket.on("queueAccepted",()=>{
                    axios.post(this.host+'/api/livechat/register'+(this.state.api ? '?api=false' : ''), {_token:this.state.clientInfo.csrf,title:this.state.description,email:this.state.email,phone:this.state.phone,name:this.state.name,des:this.state.description,token:this.props.match.params.token,ic:this.state.ic || ""},{withCredentials: true})
                    .then(res => {
                        if(res.data.result === "success"){
                            
                            audio2.play();
                            setTimeout(()=>{
                                window.location.replace("/start/livechat/"+this.props.match.params.token);
                            },2000)                            
                        }
                        else{
                            console.log(res.data);
                        }
                    })
                });  
            } 
            else{


                axios.post(this.host+'/api/livechat/register', {_token:this.state.clientInfo.csrf,title:this.state.description,email:this.state.email,phone:this.state.phone,name:this.state.name,des:this.state.description,token:this.props.match.params.token,ic:this.state.ic},{withCredentials: true})
                .then(res => {
                    if(res.data.result === "success"){
                        
                        this.setState({firstLoad:false,errorTitle:"Message sent",errorText:"Thank you for contacting us!",ended:true,formSubmitted: true});                     
                    }
                    else{
                        console.log(res.data);
                    }
                })

                
            }
        }
      }

      ticketTitleChanged = (e) => {
        this.setState({ticketTitle:e.target.value});
      }
  
      phoneChanged = (e) => {
        this.setState({phone:e.target.value});
      }
  
      emailChanged = (e) => {
        this.setState({email:e.target.value});
      }
      
      descriptionChanged = (e) => {
        this.setState({description:e.target.value});
      }    
  
      nameChanged = (e) => {
        this.setState({name:e.target.value});
      }      
      
      goBack = () => {
        window.location.replace("/end/livechat");
      }

  render() {
    return (
      <div className="flex-col">
        <div className="valign-wrapper">
            <div className="logo-section">
                <div style={{padding:"20px"}}>
                    <a href='/' target='_blank'>
                        <div className="valign-wrapper">
                        <img style={{width:"30px"}} src={process.env.PUBLIC_URL + '/icon-256px.png'} /> 
                        <h5 className="bold-text-2 hyperceed-bold">Hyperceed</h5>
                        </div>
                    </a>
                </div>
            </div>
        </div>
            <div className="full-width">
                <div className="limit-width-500 block-center" style={{padding:"20px"}}>
                    {(
                        this.state.clientInfo.ticket_id === null ?
                    <React.Fragment>
                    <h3 className="bold-text-2 emphasize-text">Live Chat</h3>
                    
                    <h5>You are connecting to</h5>
                    
                    {(
                        this.state.clientInfo.name ? 
                        <div className="valign-wrapper margin-bottom-2x">
                            <img className="circle" style={{width:'35px', height: '35px', marginRight:'12px'}} src={this.state.clientInfo.photo} alt="error"/>
                            <h5 className="small-font bold-text">{this.state.clientInfo.name}</h5>
                        </div>
                        :
                        null
                    )}
                    {(this.state.noAgent ? <h6 className="red-text">Unfortunately, we are offline now. Please leave us a message and we'll contact you shortly.</h6> : null)}
                    {(!this.state.continue ? 
                        <button className="button-new pink white-text" onClick={()=>this.setState({continue:true})}>Continue</button>                    

                        :
                        null
                    )}
                    {(
                        this.state.liveChatSubmitted && !this.state.socketError ?
                            <React.Fragment>
                                <h5 className="bold-text">We are finding available agents now. Please wait...</h5>
                                <h5>Your queue number is {this.state.queueNumber}</h5>                                
                                <h6>Do not refresh the page, otherwise you have to apply the queue again.</h6>
                            </React.Fragment>
                        :
                        (
                            this.state.liveChatSubmitted && this.state.socketError ?
                            <React.Fragment>
                                <h5 className="bold-text">You have disconnected from server.</h5>
                                <h6>Please check your internet connection and try again.</h6>
                            </React.Fragment>
                            :null                            
                        )
                    )}
                    {(this.state.formSubmitted ? <React.Fragment><h5 className="bold-text-2">{this.state.errorTitle}</h5>
                             <h6>{this.state.errorText}</h6></React.Fragment> : null)}
                    {(this.state.continue && !this.state.liveChatSubmitted && !this.state.formSubmitted ? <React.Fragment>
                    {(!this.state.noAgent ? <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                        <input className={"browser-default "+(this.state.nameError ? "invalid" : "")} type="text" placeholder=" " onChange={this.nameChanged}/>
                        <span>Name</span>
                        <p className="red-text hide">This cannot be empty.</p>
                    </label> : null)}                         
                    {/* <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                        <input className={"browser-default "+(this.state.titleError ? "invalid" : "")} type="text" placeholder=" " onChange={this.ticketTitleChanged}/>
                        <span>Title</span>
                        <p className="red-text hide">This cannot be empty.</p>
                    </label> */}
                     {(!this.state.noAgent ? <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                        <input className={"browser-default "+(this.state.phoneError ? "invalid" : "")} type="text" placeholder=" " onChange={this.phoneChanged}/>
                        <span>Phone</span>
                        <p className="red-text hide">This cannot be empty.</p>
                    </label> : null )} 
                    {(!this.state.noAgent ? <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                        <input className={"browser-default "+(this.state.emailError ? "invalid" : "")} type="text" placeholder=" " onChange={this.emailChanged}/>
                        <span>Email</span>
                        <p className="red-text hide">This cannot be empty.</p>
                    </label>  : null )}                         
                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                        <textarea className={"browser-default "+(this.state.desError ? "invalid" : "")} type="text" placeholder=" " onChange={this.descriptionChanged} style={{resize:"none",height:"200px"}}/>
                        <span>Message</span>
                        <p className="red-text hide">This cannot be empty.</p>
                    </label>
                    <button className="button-new pink white-text" onClick={this.submitLiveChat}>{(this.state.noAgent ? "Submit" : "Connect")}</button> 
                    </React.Fragment> : null)}   
                    <button className="button-new grey lighten-3 grey-text text-darken-3 linked margin-left-1x" onClick={this.goBack}>Close</button>    
                    {(!this.state.noAgent ? <h6>By using live chat, you are agreed to Hyperceed's Privacy Policy and Term of Use.</h6> : null)}
                    </React.Fragment>
                    :
                    (this.state.firstLoad ?
                         <h5 className="bold-text-2">Please wait...</h5>
                         : 
                         <React.Fragment>
                            <h5 className="bold-text-2">{this.state.errorTitle}</h5>
                             <h6>{this.state.errorText}</h6>
                             {
                                (this.state.ended ? 
                                    
                                    <div className="card">
                                        <div className="card-content">
                                            {(!this.state.submitted ? <React.Fragment>
                                                <h6>Please rate the live chat experience</h6>
                                                <StarRatings
                                                    starRatedColor="#E91E63"
                                                    changeRating={this.changeRating}
                                                    rating={this.state.rating}
                                                    starDimension="40px"
                                                    starSpacing="5px"
                                                    starHoverColor="#F48FB1"
                                                /> 
                                                {(this.state.starError ? <p className="red-text">Please fill in.</p>: false)}
                                                <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                                    <textarea className={"browser-default "+(this.state.reasonError ? "invalid" : "")} type="text" placeholder=" " onChange={this.reasonChanged} style={{resize:"none",height:"200px"}}/>
                                                    <span>Reason {(this.state.rating < 5 ? "" : "(Optional)")}</span>
                                                    {(this.state.reasonError ? <p className="red-text">Please tell us what should we improve!</p>: false)}
                                                </label>                                            
                                                <div>
                                                    
                                                    <button className="button-new pink white-text" onClick={this.submitRating}>Submit</button>    
                                                    <button className="button-new grey lighten-3 grey-text text-darken-3 linked margin-left-1x" onClick={this.goBack}>Cancel</button>                                          
                                                </div>
                                            </React.Fragment> :
                                                <React.Fragment>
                                                    <h5 className="bold-text-2">Thank you for your feedback!</h5>
                                                    <button className="button-new grey lighten-3 grey-text text-darken-3 linked margin-left-1x" onClick={this.goBack}>Close</button>   
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>    
                                    :
                                    null
                                )
                            }                             
                        </React.Fragment>)
                    )} 

                    {/* <h6 className="grey-text">© 2020 Superceed</h6>          */}
                </div>
            </div>      
      </div>
    );
  }
}
