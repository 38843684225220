import React from 'react';
import ring from './audio/ring.mp3';
import { connect } from 'react-redux';

const audio = new Audio(ring);

audio.loop = true;



class SIPml extends React.Component{

    sipInterval;

    sTransferNumber;
    oRingTone;
    oRingbackTone;
    oSipStack;
    oSipSessionRegister;
    oSipSessionCall;
    oSipSessionTransferCall;
    videoRemote;
    videoLocal;
    audioRemote;
    bFullScreen = false;
    oNotifICall;
    bDisableVideo = false;
    viewVideoLocal;
    viewVideoRemote;
    viewLocalScreencast; // <video> (webrtc) or <div> (webrtc4all)
    oConfigCall;
    oReadyStateTimer;
    getPval;

    txtPhoneNumber = "110196";
    txtCallStatus;
    txtDisplayName;
    txtPrivateIdentity;
    txtPublicIdentity;
    txtPassword;
    txtRealm;

    btnUnRegister = {};
    btnRegister = {};
    btnHangUp = {};
    btnCall = {};
    btnHangUp = {};
    btnTransfer = {};
    btnHoldResume = {};
    btnFullScreen = {};
    btnMute = {};
    btnHoldResume = {};

    oReadyStateTimer;
    
    componentDidMount(){

        // audio.play();
        console.log("init sip component");
        
        this.txtDisplayName = this.props.identity;
        this.txtPrivateIdentity = this.props.identity;
        this.txtPublicIdentity = "sip:"+this.props.identity+"@"+this.props.sipIp;
        this.txtPassword = this.props.password;
        this.txtRealm = ""+this.props.sipIp;

        this.videoLocal = document.createElement("video");
        this.videoRemote = document.createElement("video");
        this.audioRemote = document.createElement("audio");

        this.audioRemote.autoplay = true;
        this.videoLocal.muted = true;

        

        this.props.callNumber(this.callNumber);
        this.props.hangUp(this.hangUp);
        this.props.reconnect(this.reconnect);
        this.props.setMute(this.muteFn);


        this.oReadyStateTimer = setInterval( () => {
            if (document.readyState === "complete") {
                clearInterval(this.oReadyStateTimer);
                // initialize SIPML5
                this.preInit();

                this.sipRegister();
            }
        },
        500);



    }

    constructor(props){
        super(props);



        this.callNumber = this.sipCall.bind(this); 
        this.hangUp = this.sipHangUp.bind(this);
        this.reconnect = this.sipRegister.bind(this);
        this.muteFn = this.sipToggleMute.bind(this);
        
        // this.sipInterval = setInterval( () => {
        //     if (document.readyState === "complete") {
        //         clearInterval(this.sipInterval);
        //         // initialize window.SIPml5
                
        //     }
        // },
        // 500);   
        
        this.getPVal = (PName) => {
            var query = window.location.search.substring(1);
            var vars = query.split('&');
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=');
                if (decodeURIComponent(pair[0]) === PName) {
                    return decodeURIComponent(pair[1]);
                }
            }
            return null;
        }     
        
        // this.preInit();
    }

    uiOnConnectionEvent = (b_connected, b_connecting) => { // should be enum: connecting, connected, terminating, terminated
        this.btnRegister.disabled = b_connected || b_connecting;
        this.btnUnRegister.disabled = !b_connected && !b_connecting;
        this.btnCall.disabled = !(b_connected && window.tsk_utils_have_webrtc() && window.tsk_utils_have_stream());
        this.btnHangUp.disabled = !this.oSipSessionCall;
    }



    loadCallOptions = () => {
        if (window.localStorage) {
            var s_value;
            if ((s_value = window.localStorage.getItem('org.doubango.call.phone_number'))) this.props.value.phoneNumber = s_value;
            this.bDisableVideo = (window.localStorage.getItem('org.doubango.expert.disable_video') == "true");

            this.txtCallStatus.innerHTML = '<i>Video ' + (this.bDisableVideo ? 'disabled' : 'enabled') + '</i>';
        }
    }

    saveCallOptions = () => {
        if (window.localStorage) {
            window.localStorage.setItem('org.doubango.call.phone_number', this.props.value.phoneNumber);
            window.localStorage.setItem('org.doubango.expert.disable_video', this.bDisableVideo ? "true" : "false");
        }
    }

    loadCredentials = () => {
        if (window.localStorage) {
            // IE retuns 'null' if not defined
            var s_value;
            if ((s_value = window.localStorage.getItem('org.doubango.identity.display_name'))) this.txtDisplayName = s_value;
            if ((s_value = window.localStorage.getItem('org.doubango.identity.impi'))) this.txtPrivateIdentity = s_value;
            if ((s_value = window.localStorage.getItem('org.doubango.identity.impu'))) this.txtPublicIdentity = s_value;
            if ((s_value = window.localStorage.getItem('org.doubango.identity.password'))) this.txtPassword = s_value;
            if ((s_value = window.localStorage.getItem('org.doubango.identity.realm'))) this.txtRealm = s_value;
        }
        else {
            //this.txtDisplayName.value = "005";
            //this.txtPrivateIdentity.value = "005";
            //this.txtPublicIdentity.value = "sip:005@sip2sip.info";
            //this.txtPassword.value = "005";
            //this.txtRealm.value = "sip2sip.info";
            //this.txtPhoneNumber = "701020";
        }
    };

    saveCredentials = () => {
        if (window.localStorage) {
            window.localStorage.setItem('org.doubango.identity.display_name', this.txtDisplayName);
            window.localStorage.setItem('org.doubango.identity.impi', this.txtPrivateIdentity);
            window.localStorage.setItem('org.doubango.identity.impu', this.txtPublicIdentity);
            window.localStorage.setItem('org.doubango.identity.password', this.txtPassword);
            window.localStorage.setItem('org.doubango.identity.realm', this.txtRealm);
        }
    };

    // sends SIP REGISTER request to login
    sipRegister = () => {
        // catch exception for IE (DOM not ready)
        try {
            // this.btnRegister.disabled = true;
            if (!this.txtPrivateIdentity) {
                // txtRegStatus.innerHTML = '<b>Please fill madatory fields (*)</b>';
                // this.btnRegister.disabled = false;
                return;
            }
            var o_impu = window.tsip_uri.prototype.Parse("sip:" + this.txtPrivateIdentity + "@" + ""+this.props.sipIp);
            if (!o_impu || !o_impu.s_user_name || !o_impu.s_host) {
                // txtRegStatus.innerHTML = "<b>[" + "sip:" + this.txtPrivateIdentity.value + "@" + ""+this.props.sipIp + "] is not a valid Public identity</b>";
                // this.btnRegister.disabled = false;
                return;
            }

            // enable notifications if not already done
            if (window.webkitNotifications && window.webkitNotifications.checkPermission() != 0) {
                window.webkitNotifications.requestPermission();
            }

            // save credentials
            this.saveCredentials();

            // update debug level to be sure new values will be used if the user haven't updated the page
            window.SIPml.setDebugLevel((window.localStorage && window.localStorage.getItem('org.doubango.expert.disable_debug') == "true") ? "error" : "info");

            // create SIP stack
            this.oSipStack = new window.SIPml.Stack({
                realm: ""+this.props.sipIp,
                impi: this.txtPrivateIdentity,
                impu: "sip:" + this.txtPrivateIdentity + "@" + ""+this.props.sipIp,
                password: this.txtPassword,
                display_name: this.txtPrivateIdentity,
                websocket_proxy_url: "wss:"+this.props.sipIp+":8089/ws",
                outbound_proxy_url: (window.localStorage ? window.localStorage.getItem('org.doubango.expert.sip_outboundproxy_url') : null),
                ice_servers: [{ url: 'stun:stun.l.google.com:19302'}],
                enable_rtcweb_breaker: (window.localStorage ? window.localStorage.getItem('org.doubango.expert.enable_rtcweb_breaker') == "true" : false),
                events_listener: { events: '*', listener: this.onSipEventStack },
                enable_early_ims: (window.localStorage ? window.localStorage.getItem('org.doubango.expert.disable_early_ims') != "true" : true), // Must be true unless you're using a real IMS network
                enable_media_stream_cache: (window.localStorage ? window.localStorage.getItem('org.doubango.expert.enable_media_caching') == "true" : false),
                bandwidth: (window.localStorage ? window.tsk_string_to_object(window.localStorage.getItem('org.doubango.expert.bandwidth')) : null), // could be redefined a session-level
                video_size: (window.localStorage ? window.tsk_string_to_object(window.localStorage.getItem('org.doubango.expert.video_size')) : null), // could be redefined a session-level
                sip_headers: [
                        { name: 'User-Agent', value: 'IM-client/OMA1.0 sipML5-v1.2016.03.04' },
                        { name: 'Organization', value: 'Doubango Telecom' }
                ]
            }
            );
            console.log(this.oSipStack);
            this.props.stateChange('asteriskError',false);
            if (this.oSipStack.start() != 0) {
                alert("fail to start sip stack");
                // txtRegStatus.innerHTML = '<b>Failed to start the SIP stack</b>';
            }
            else {
                console.log("yay")
                return;
            }
        }
        catch (e) {
            alert(e);
            // txtRegStatus.innerHTML = "<b>2:" + e + "</b>";
        }
        this.btnRegister.disabled = false;
    }

    // sends SIP REGISTER (expires=0) to logout
    sipUnRegister = () => {
        if (this.oSipStack) {
            this.oSipStack.stop(); // shutdown all sessions
        }
    }

    // makes a call (SIP INVITE)
    sipCall = (s_type) => {
        this.props.mute(false);
        if (this.oSipStack && !this.oSipSessionCall && !window.tsk_string_is_null_or_empty(this.props.value.phoneNumber)) {
            console.log("calling");
            if (s_type == 'call-screenshare') {
                if (!window.SIPml.isScreenShareSupported()) {
                    alert('Screen sharing not supported. Are you using chrome 26+?');
                    return;
                }
                if (!window.location.protocol.match('https')) {
                    if (window.confirm("Screen sharing requires https://. Do you want to be redirected?")) {
                        this.sipUnRegister();
                        window.location = 'https://ns313841.ovh.net/call.htm';
                    }
                    return;
                }
            }
            this.btnCall.disabled = true;
            this.btnHangUp.disabled = false;

            if (window.localStorage) {
                this.oConfigCall.bandwidth = window.tsk_string_to_object(window.localStorage.getItem('org.doubango.expert.bandwidth')); // already defined at stack-level but redifined to use latest values
                this.oConfigCall.video_size = window.tsk_string_to_object(window.localStorage.getItem('org.doubango.expert.video_size')); // already defined at stack-level but redifined to use latest values
            }

            console.log(this.oConfigCall);

            

            // create call session
            this.oSipSessionCall = this.oSipStack.newSession(s_type, this.oConfigCall);

            console.log(this.oSipSessionCall);

            this.props.callStatusChanged('Initializing');
            this.props.callerNumberChanged(this.props.value.phoneNumber);   
            // make call
            if (this.oSipSessionCall.call(this.props.value.phoneNumber) != 0) {
                this.oSipSessionCall = null;
                // this.txtCallStatus.value = 'Failed to make call';
                alert("failed to make call");
                this.btnCall.disabled = false;
                this.btnHangUp.disabled = true;
                return;
            }
            this.saveCallOptions();
        }
        else if (this.oSipSessionCall) {
            console.log("accept")
            // this.txtCallStatus.innerHTML = '<i>Connecting...</i>';
            this.oSipSessionCall.accept(this.oConfigCall);
        }
    }

    // Share entire desktop aor application using BFCP or WebRTC native implementation
    sipShareScreen = () => {
        if (window.SIPml.getWebRtcType() === 'w4a') {
            // Sharing using BFCP -> requires an active session
            if (!this.oSipSessionCall) {
                // this.txtCallStatus.innerHTML = '<i>No active session</i>';
                return;
            }
            if (this.oSipSessionCall.bfcpSharing) {
                if (this.oSipSessionCall.stopBfcpShare(this.oConfigCall) != 0) {
                    this.txtCallStatus.value = 'Failed to stop BFCP share';
                }
                else {
                    this.oSipSessionCall.bfcpSharing = false;
                }
            }
            else {
                this.oConfigCall.screencast_window_id = 0x00000000;
                if (this.oSipSessionCall.startBfcpShare(this.oConfigCall) != 0) {
                    this.txtCallStatus.value = 'Failed to start BFCP share';
                }
                else {
                    this.oSipSessionCall.bfcpSharing = true;
                }
            }
        }
        else {
            this.sipCall('call-screenshare');
        }
    }

    // transfers the call
    sipTransfer = () => {
        if (this.oSipSessionCall) {
            var s_destination = prompt('Enter destination number', '');
            if (!window.tsk_string_is_null_or_empty(s_destination)) {
                this.btnTransfer.disabled = true;
                if (this.oSipSessionCall.transfer(s_destination) != 0) {
                    // this.txtCallStatus.innerHTML = '<i>Call transfer failed</i>';
                    this.btnTransfer.disabled = false;
                    return;
                }
                // this.txtCallStatus.innerHTML = '<i>Transfering the call...</i>';
            }
        }
    }

    // holds or resumes the call
    sipToggleHoldResume = () => {
        if (this.oSipSessionCall) {
            var i_ret;
            this.btnHoldResume.disabled = true;
            // this.txtCallStatus.innerHTML = this.oSipSessionCall.bHeld ? '<i>Resuming the call...</i>' : '<i>Holding the call...</i>';
            i_ret = this.oSipSessionCall.bHeld ? this.oSipSessionCall.resume() : this.oSipSessionCall.hold();
            if (i_ret != 0) {
                // this.txtCallStatus.innerHTML = '<i>Hold / Resume failed</i>';
                this.btnHoldResume.disabled = false;
                return;
            }
        }
    }

    // Mute or Unmute the call
    sipToggleMute = () => {
        if (this.oSipSessionCall) {
            var i_ret;
            var bMute = !this.oSipSessionCall.bMute;
            // this.txtCallStatus.innerHTML = bMute ? '<i>Mute the call...</i>' : '<i>Unmute the call...</i>';
            i_ret = this.oSipSessionCall.mute('audio'/*could be 'video'*/, bMute);
            if (i_ret != 0) {
                // this.txtCallStatus.innerHTML = '<i>Mute / Unmute failed</i>';
                alert("mute failed");
                return;
            }
            this.oSipSessionCall.bMute = bMute;

            this.props.mute(bMute);
            // this.btnMute.value = bMute ? "Unmute" : "Mute";
        }
        else{
            alert("Call not initiated");
        }
    }

    // terminates the call (SIP BYE or CANCEL)
    sipHangUp = () => {
        if (this.oSipSessionCall) {
            // this.txtCallStatus.innerHTML = '<i>Terminating the call...</i>';
            this.oSipSessionCall.hangup({ events_listener: { events: '*', listener: this.onSipEventSession } });
        }
    }

    sipSendDTMF = (c) => {
        if (this.oSipSessionCall && c) {
            if (this.oSipSessionCall.dtmf(c) == 0) {
                try { window.dtmfTone.play(); } catch (e) { }
            }
        }
    }

    startRingTone = () => {
        try { audio.play(); }
        catch (e) { }
    }

    stopRingTone =() => {
        try { audio.pause(); }
        catch (e) { }
    }

    startRingbackTone = () => {
        try { window.ringbacktone.play(); }
        catch (e) { }
    }

    stopRingbackTone = () => {
        try { window.ringbacktone.pause(); }
        catch (e) { }
    }

    toggleFullScreen = () => {
        if (this.videoRemote.webkitSupportsFullscreen) {
            this.fullScreen(!this.videoRemote.webkitDisplayingFullscreen);
        }
        else {
            this.fullScreen(!this.bFullScreen);
        }
    }

    openKeyPad = () => {
        // divKeyPad.style.visibility = 'visible';
        // divKeyPad.style.left = ((document.body.clientWidth - C.divKeyPadWidth) >> 1) + 'px';
        // divKeyPad.style.top = '70px';
        // divGlassPanel.style.visibility = 'visible';
    }

    closeKeyPad = () => {
        // divKeyPad.style.left = '0px';
        // divKeyPad.style.top = '0px';
        // divKeyPad.style.visibility = 'hidden';
        // divGlassPanel.style.visibility = 'hidden';
    }

    fullScreen = (b_fs) => {
        this.bFullScreen = b_fs;
        if (window.tsk_utils_have_webrtc4native() && this.bFullScreen && this.videoRemote.webkitSupportsFullscreen) {
            if (this.bFullScreen) {
                this.videoRemote.webkitEnterFullScreen();
            }
            else {
                this.videoRemote.webkitExitFullscreen();
            }
        }
        else {
            if (window.tsk_utils_have_webrtc4npapi()) {
                try { if (window.__o_display_remote) window.__o_display_remote.setFullScreen(b_fs); }
                catch (e) { 
                    // divVideo.setAttribute("class", b_fs ? "full-screen" : "normal-screen"); 
                }
            }
            else {
                // divVideo.setAttribute("class", b_fs ? "full-screen" : "normal-screen");
            }
        }
    }

    showNotifICall = (s_number) => {
        // permission already asked when we registered
        if(Notification.permission === "granted" && Notification.permission !== "denied"){
            // if (this.oNotifICall) {
            //     this.oNotifICall.cancel();
            // }
            // this.oNotifICall = window.webkitNotifications.createNotification('images/sipml-34x39.png', 'Incaming call', 'Incoming call from ' + s_number);
            // this.oNotifICall.onclose = function () { this.oNotifICall = null; };
            // this.oNotifICall.show();
            if(!this.props.focused){
                var notification = new Notification('Incoming', {
                    // icon: 'http://cdn.sstatic.net/stackexchange/img/logos/so/so-icon.png',
                    body: 'Incoming call from ' + s_number,
                });
                notification.onclick = function() {
                    window.focus();
                    this.close()
                };  
            }
            //    notification.show();          
        }
    }

    onKeyUp = (evt) => {
        evt = (evt || window.event);
        if (evt.keyCode == 27) {
            this.fullScreen(false);
        }
        else if (evt.ctrlKey && evt.shiftKey) { // CTRL + SHIFT
            if (evt.keyCode == 65 || evt.keyCode == 86) { // A (65) or V (86)
                this.bDisableVideo = (evt.keyCode == 65);
                // this.txtCallStatus.innerHTML = '<i>Video ' + (this.bDisableVideo ? 'disabled' : 'enabled') + '</i>';
                window.localStorage.setItem('org.doubango.expert.disable_video', this.bDisableVideo);
            }
        }
    }

    onDivCallCtrlMouseMove = (evt) => {
        try { // IE: DOM not ready
            if (window.tsk_utils_have_stream()) {
                this.btnCall.disabled = (!window.tsk_utils_have_stream() || !this.oSipSessionRegister || !this.oSipSessionRegister.is_connected());
                document.getElementById("divCallCtrl").onmousemove = null; // unsubscribe
            }
        }
        catch (e) { }
    }

    uiOnConnectionEvent = (b_connected, b_connecting) => { // should be enum: connecting, connected, terminating, terminated
        this.btnRegister.disabled = b_connected || b_connecting;
        this.btnUnRegister.disabled = !b_connected && !b_connecting;
        this.btnCall.disabled = !(b_connected && window.tsk_utils_have_webrtc() && window.tsk_utils_have_stream());
        this.btnHangUp.disabled = !this.oSipSessionCall;
    }

    uiVideoDisplayEvent = (b_local, b_added) => {
        var o_elt_video = b_local ? this.videoLocal : this.videoRemote;

        if (b_added) {
            // o_elt_video.style.opacity = 1;
            this.uiVideoDisplayShowHide(true);
        }
        else {
            // o_elt_video.style.opacity = 0;
            this.fullScreen(false);
        }
    }

    uiVideoDisplayShowHide = (b_show) => {
        if (b_show) {
            // tdVideo.style.height = '340px';
            // divVideo.style.height = navigator.appName == 'Microsoft Internet Explorer' ? '100%' : '340px';
        }
        else {
            // tdVideo.style.height = '0px';
            // divVideo.style.height = '0px';
        }
        this.btnFullScreen.disabled = !b_show;
    }

    uiDisableCallOptions = () => {
        if (window.localStorage) {
            window.localStorage.setItem('org.doubango.expert.disable_callbtn_options', 'true');
            this.uibtnCallSetText('Call');
            alert('Use expert view to enable the options again (/!\\requires re-loading the page)');
        }
    }

    uibtnCallSetText = (s_text) => {
        switch (s_text) {
            case "Call":
                {
                    var bDisableCallBtnOptions = (window.localStorage && window.localStorage.getItem('org.doubango.expert.disable_callbtn_options') == "true");
                    // this.btnCall.value = this.btnCall.innerHTML = bDisableCallBtnOptions ? 'Call' : 'Call <span id="spanCaret" class="caret">';
                    // this.btnCall.setAttribute("class", bDisableCallBtnOptions ? "btn btn-primary" : "btn btn-primary dropdown-toggle");
                    // this.btnCall.onclick = bDisableCallBtnOptions ? function () { this.sipCall(this.bDisableVideo ? 'call-audio' : 'call-audiovideo'); } : null;
                    // ulCallOptions.style.visibility = bDisableCallBtnOptions ? "hidden" : "visible";
                    // if (!bDisableCallBtnOptions && ulCallOptions.parentNode != divthis.btnCallGroup) {
                    //     divthis.btnCallGroup.appendChild(ulCallOptions);
                    // }
                    // else if (bDisableCallBtnOptions && ulCallOptions.parentNode == divthis.btnCallGroup) {
                    //     document.body.appendChild(ulCallOptions);
                    // }

                    break;
                }
            default:
                {
                    this.btnCall.value = this.btnCall.innerHTML = s_text;
                    // this.btnCall.setAttribute("class", "btn btn-primary");
                    // this.btnCall.onclick = function () { this.sipCall(this.bDisableVideo ? 'call-audio' : 'call-audiovideo'); };
                    // ulCallOptions.style.visibility = "hidden";
                    // if (ulCallOptions.parentNode == divthis.btnCallGroup) {
                    //     document.body.appendChild(ulCallOptions);
                    // }
                    break;
                }
        }
    }

    uiCallTerminated = (s_description) => {
        this.uibtnCallSetText("Call");
        this.btnHangUp.value = 'HangUp';
        this.btnHoldResume.value = 'hold';
        this.btnMute.value = "Mute";
        this.btnCall.disabled = false;
        this.btnHangUp.disabled = true;
        if (window.btnBFCP) window.btnBFCP.disabled = true;

        this.oSipSessionCall = null;

        this.stopRingbackTone();
        this.stopRingTone();

        this.props.callStatusChanged('Ready');

        this.props.refreshCallHistory();

        // this.txtCallStatus.innerHTML = "<i>" + s_description + "</i>";
        this.uiVideoDisplayShowHide(false);
        // divCallOptions.style.opacity = 0;

        if (this.oNotifICall) {
            this.oNotifICall.cancel();
            this.oNotifICall = null;
        }

        this.uiVideoDisplayEvent(false, false);
        this.uiVideoDisplayEvent(true, false);

        setTimeout(function () { if (!this.oSipSessionCall)
            //  this.txtCallStatus.innerHTML = ''; 
            console.log("test");
            }, 2500);
    }

    // Callback function for SIP Stacks
    onSipEventStack = (e /*SIPml.Stack.Event*/) => {
        console.log("OSES")
        window.tsk_utils_log_info('==stack event = ' + e.type);
        switch (e.type) {
            case 'started':
                {                    
                    // catch exception for IE (DOM not ready)
                    try {
                        // LogIn (REGISTER) as soon as the stack finish starting
                        console.log("reg")
                        this.oSipSessionRegister = this.oSipStack.newSession('register', {
                            expires: 200,
                            events_listener: { events: '*', listener: this.onSipEventSession },
                            sip_caps: [
                                        { name: '+g.oma.sip-im', value: null },
                                        //{ name: '+sip.ice' }, // rfc5768: FIXME doesn't work with Polycom TelePresence
                                        { name: '+audio', value: null },
                                        { name: 'language', value: '\"en,fr\"' }
                            ]
                        });
                        
                        this.oSipSessionRegister.register();
                    }
                    catch (e) {
                        // txtRegStatus.value = txtRegStatus.innerHTML = "<b>1:" + e + "</b>";
                        alert(e);
                        this.btnRegister.disabled = false;
                    }
                    break;
                }
            case 'stopping': case 'stopped': case 'failed_to_start': case 'failed_to_stop':
                {
                    var bFailure = (e.type == 'failed_to_start') || (e.type == 'failed_to_stop');
                    this.oSipStack = null;
                    this.oSipSessionRegister = null;
                    this.oSipSessionCall = null;
                    console.log("q1")
                    this.uiOnConnectionEvent(false, false);

                    this.stopRingbackTone();
                    this.stopRingTone();

                    this.props.callStatusChanged('Ready');

                    this.props.stateChange('asteriskError',true);

                    this.uiVideoDisplayShowHide(false);
                    // divCallOptions.style.opacity = 0;

                    // this.txtCallStatus.innerHTML = '';
                    // txtRegStatus.innerHTML = bFailure ? "<i>Disconnected: <b>" + e.description + "</b></i>" : "<i>Disconnected</i>";
                    break;
                }

            case 'i_new_call':
                {
                    if (this.oSipSessionCall) {
                        // do not accept the incoming call if we're already 'in call'
                        e.newSession.hangup(); // comment this line for multi-line support
                    }
                    else {
                        this.oSipSessionCall = e.newSession;
                        // start listening for events
                        this.oSipSessionCall.setConfiguration(this.oConfigCall);

                        this.uibtnCallSetText('Answer');
                        this.btnHangUp.value = 'Reject';
                        this.btnCall.disabled = false;
                        this.btnHangUp.disabled = false;

                        this.startRingTone();

                        var sRemoteNumber = (this.oSipSessionCall.getRemoteFriendlyName() || 'unknown');

                        this.props.callStatusChanged('Incoming');
                        this.props.callerNumberChanged(sRemoteNumber);
                        console.log(sRemoteNumber);
                        // this.txtCallStatus.innerHTML = "<i>Incoming call from [<b>" + sRemoteNumber + "</b>]</i>";
                        this.showNotifICall(sRemoteNumber);
                    }
                    break;
                }

            case 'm_permission_requested':
                {
                    // divGlassPanel.style.visibility = 'visible';
                    break;
                }
            case 'm_permission_accepted':
            case 'm_permission_refused':
                {
                    // divGlassPanel.style.visibility = 'hidden';
                    if (e.type == 'm_permission_refused') {
                        this.uiCallTerminated('Media stream permission denied');
                    }
                    break;
                }

            case 'starting': default: break;
        }
    };

    
      onSipEventSession = (e /* SIPml.Session.Event */) => {
        window.tsk_utils_log_info('==session event = ' + e.type);
    
        switch (e.type) {
            case 'connecting': case 'connected':
                {
                    var bConnected = (e.type == 'connected');
                    if (e.session == this.oSipSessionRegister) {
                        console.log("q1")
                        this.uiOnConnectionEvent(bConnected, !bConnected);
                        // txtRegStatus.innerHTML = "<i>" + e.description + "</i>";
                    }
                    else if (e.session == this.oSipSessionCall) {
                        // this.btnHangUp.value = 'HangUp';
                        // this.btnCall.disabled = true;
                        // this.btnHangUp.disabled = false;
                        // this.btnTransfer.disabled = false;
                        if (window.btnBFCP) window.btnBFCP.disabled = false;
    
                        if (bConnected) {
                            this.stopRingbackTone();
                            this.stopRingTone();

                            this.props.callStatusChanged('Engaged');
                            // this.props.callerNumberChanged(e.description);                            
    
                            if (this.oNotifICall) {
                                this.oNotifICall.cancel();
                                this.oNotifICall = null;
                            }
                        }
    
                        // this.txtCallStatus.innerHTML = "<i>" + e.description + "</i>";
                        // divCallOptions.style.opacity = bConnected ? 1 : 0;
    
                        if (window.SIPml.isWebRtc4AllSupported()) { // IE don't provide stream callback
                            this.uiVideoDisplayEvent(false, true);
                            this.uiVideoDisplayEvent(true, true);
                        }
                    }
                    break;
                } // 'connecting' | 'connected'
            case 'terminating': case 'terminated':
                {
                    if (e.session == this.oSipSessionRegister) {
                        console.log("q1")
                        this.uiOnConnectionEvent(false, false);
    
                        this.oSipSessionCall = null;
                        this.oSipSessionRegister = null;
    
                        // txtRegStatus.innerHTML = "<i>" + e.description + "</i>";
                    }
                    else if (e.session == this.oSipSessionCall) {
                        this.uiCallTerminated(e.description);
                    }
                    break;
                } // 'terminating' | 'terminated'
    
            case 'm_stream_video_local_added':
                {
                    if (e.session == this.oSipSessionCall) {
                        this.uiVideoDisplayEvent(true, true);
                    }
                    break;
                }
            case 'm_stream_video_local_removed':
                {
                    if (e.session == this.oSipSessionCall) {
                        this.uiVideoDisplayEvent(true, false);
                    }
                    break;
                }
            case 'm_stream_video_remote_added':
                {
                    if (e.session == this.oSipSessionCall) {
                        this.uiVideoDisplayEvent(false, true);
                    }
                    break;
                }
            case 'm_stream_video_remote_removed':
                {
                    if (e.session == this.oSipSessionCall) {
                        this.uiVideoDisplayEvent(false, false);
                    }
                    break;
                }
    
            case 'm_stream_audio_local_added':
            case 'm_stream_audio_local_removed':
            case 'm_stream_audio_remote_added':
            case 'm_stream_audio_remote_removed':
                {
                    break;
                }
    
            case 'i_ect_new_call':
                {
                    this.oSipSessionTransferCall = e.session;
                    break;
                }
    
            case 'i_ao_request':
                {
                    if (e.session == this.oSipSessionCall) {
                        var iSipResponseCode = e.getSipResponseCode();
                        if (iSipResponseCode == 180 || iSipResponseCode == 183) {
                            this.startRingbackTone();
                            this.props.callStatusChanged('Dialing');
                            // this.txtCallStatus.innerHTML = '<i>Remote ringing...</i>';
                        }
                    }
                    break;
                }
    
            case 'm_early_media':
                {
                    if (e.session == this.oSipSessionCall) {
                        this.stopRingbackTone();
                        this.stopRingTone();
                        // this.txtCallStatus.innerHTML = '<i>Early media started</i>';
                    }
                    break;
                }
    
            case 'm_local_hold_ok':
                {
                    if (e.session == this.oSipSessionCall) {
                        if (this.oSipSessionCall.bTransfering) {
                            this.oSipSessionCall.bTransfering = false;
                            // this.AVSession.TransferCall(this.transferUri);
                        }
                        this.btnHoldResume.value = 'Resume';
                        this.btnHoldResume.disabled = false;
                        // this.txtCallStatus.innerHTML = '<i>Call placed on hold</i>';
                        this.oSipSessionCall.bHeld = true;
                    }
                    break;
                }
            case 'm_local_hold_nok':
                {
                    if (e.session == this.oSipSessionCall) {
                        this.oSipSessionCall.bTransfering = false;
                        this.btnHoldResume.value = 'Hold';
                        this.btnHoldResume.disabled = false;
                        // this.txtCallStatus.innerHTML = '<i>Failed to place remote party on hold</i>';
                    }
                    break;
                }
            case 'm_local_resume_ok':
                {
                    if (e.session == this.oSipSessionCall) {
                        this.oSipSessionCall.bTransfering = false;
                        this.btnHoldResume.value = 'Hold';
                        this.btnHoldResume.disabled = false;
                        // this.txtCallStatus.innerHTML = '<i>Call taken off hold</i>';
                        this.oSipSessionCall.bHeld = false;
    
                        if (window.SIPml.isWebRtc4AllSupported()) { // IE don't provide stream callback yet
                            this.uiVideoDisplayEvent(false, true);
                            this.uiVideoDisplayEvent(true, true);
                        }
                    }
                    break;
                }
            case 'm_local_resume_nok':
                {
                    if (e.session == this.oSipSessionCall) {
                        this.oSipSessionCall.bTransfering = false;
                        this.btnHoldResume.disabled = false;
                        // this.txtCallStatus.innerHTML = '<i>Failed to unhold call</i>';
                    }
                    break;
                }
            case 'm_remote_hold':
                {
                    if (e.session == this.oSipSessionCall) {
                        // this.txtCallStatus.innerHTML = '<i>Placed on hold by remote party</i>';
                    }
                    break;
                }
            case 'm_remote_resume':
                {
                    if (e.session == this.oSipSessionCall) {
                        // this.txtCallStatus.innerHTML = '<i>Taken off hold by remote party</i>';
                    }
                    break;
                }
            case 'm_bfcp_info':
                {
                    if (e.session == this.oSipSessionCall) {
                        // this.txtCallStatus.innerHTML = 'BFCP Info: <i>' + e.description + '</i>';
                    }
                    break;
                }
    
            case 'o_ect_trying':
                {
                    if (e.session == this.oSipSessionCall) {
                        // this.txtCallStatus.innerHTML = '<i>Call transfer in progress...</i>';
                    }
                    break;
                }
            case 'o_ect_accepted':
                {
                    if (e.session == this.oSipSessionCall) {
                        // this.txtCallStatus.innerHTML = '<i>Call transfer accepted</i>';
                    }
                    break;
                }
            case 'o_ect_completed':
            case 'i_ect_completed':
                {
                    if (e.session == this.oSipSessionCall) {
                        // this.txtCallStatus.innerHTML = '<i>Call transfer completed</i>';
                        this.btnTransfer.disabled = false;
                        if (this.oSipSessionTransferCall) {
                            this.oSipSessionCall = this.oSipSessionTransferCall;
                        }
                        this.oSipSessionTransferCall = null;
                    }
                    break;
                }
            case 'o_ect_failed':
            case 'i_ect_failed':
                {
                    if (e.session == this.oSipSessionCall) {
                        // this.txtCallStatus.innerHTML = '<i>Call transfer failed</i>';
                        this.btnTransfer.disabled = false;
                    }
                    break;
                }
            case 'o_ect_notify':
            case 'i_ect_notify':
                {
                    if (e.session == this.oSipSessionCall) {
                        // this.txtCallStatus.innerHTML = "<i>Call Transfer: <b>" + e.getSipResponseCode() + " " + e.description + "</b></i>";
                        if (e.getSipResponseCode() >= 300) {
                            if (this.oSipSessionCall.bHeld) {
                                this.oSipSessionCall.resume();
                            }
                            this.btnTransfer.disabled = false;
                        }
                    }
                    break;
                }
            case 'i_ect_requested':
                {
                    if (e.session == this.oSipSessionCall) {
                        var s_message = "Do you accept call transfer to [" + e.getTransferDestinationFriendlyName() + "]?";//FIXME
                        if (window.confirm(s_message)) {
                            // this.txtCallStatus.innerHTML = "<i>Call transfer in progress...</i>";
                            this.oSipSessionCall.acceptTransfer();
                            break;
                        }
                        this.oSipSessionCall.rejectTransfer();
                    }
                    break;
                }
        }
    }
    
      postInit = () => {
          
        // check for WebRTC support
        if (!window.SIPml.isWebRtcSupported()) {
            // is it chrome?
            if (window.SIPml.getNavigatorFriendlyName() == 'chrome') {
                if (window.confirm("You're using an old Chrome version or WebRTC is not enabled.\nDo you want to see how to enable WebRTC?")) {
                    window.location = 'http://www.webrtc.org/running-the-demos';
                }
                else {
                    window.location = "index.html";
                }
                return;
            }
            else {
                if (window.confirm("webrtc-everywhere extension is not installed. Do you want to install it?\nIMPORTANT: You must restart your browser after the installation.")) {
                    window.location = 'https://github.com/sarandogou/webrtc-everywhere';
                }
                else {
                    // Must do nothing: give the user the chance to accept the extension
                    // window.location = "index.html";
                }
            }
        }
    
        // checks for WebSocket support
        if (!window.SIPml.isWebSocketSupported()) {
            if (window.confirm('Your browser don\'t support WebSockets.\nDo you want to download a WebSocket-capable browser?')) {
                window.location = 'https://www.google.com/intl/en/chrome/browser/';
            }
            else {
                window.location = "index.html";
            }
            return;
        }
    
        // FIXME: displays must be per session
        this.viewVideoLocal = this.videoLocal;
        this.viewVideoRemote = this.videoRemote;
    
        if (!window.SIPml.isWebRtcSupported()) {
            if (window.confirm('Your browser don\'t support WebRTC.\naudio/video calls will be disabled.\nDo you want to download a WebRTC-capable browser?')) {
                window.location = 'https://www.google.com/intl/en/chrome/browser/';
            }
        }
    
        // this.btnRegister.disabled = false;
        // document.body.style.cursor = 'default';
        this.oConfigCall = {
            audio_remote: this.audioRemote,
            video_local: this.viewVideoLocal,
            video_remote: this.viewVideoRemote,
            screencast_window_id: 0x00000000, // entire desktop
            bandwidth: { audio: undefined, video: undefined },
            video_size: { minWidth: undefined, minHeight: undefined, maxWidth: undefined, maxHeight: undefined },
            events_listener: { events: '*', listener: this.onSipEventSession },
            sip_caps: [
                            { name: '+g.oma.sip-im' },
                            { name: 'language', value: '\"en,fr\"' }
            ]
        };
    }
    
      preInit = () => {
          
        // set default webrtc type (before initialization)
        var s_webrtc_type = this.getPVal("wt");
        var s_fps = this.getPVal("fps");
        var s_mvs = this.getPVal("mvs"); // maxVideoSize
        var s_mbwu = this.getPVal("mbwu"); // maxBandwidthUp (kbps)
        var s_mbwd = this.getPVal("mbwd"); // maxBandwidthUp (kbps)
        var s_za = this.getPVal("za"); // ZeroArtifacts
        var s_ndb = this.getPVal("ndb"); // NativeDebug
    
        if (s_webrtc_type) window.SIPml.setWebRtcType(s_webrtc_type);
    
        // initialize window.SIPml5
        // console.log(this.postInit);
        console.log(window.SIPml.b_initialized);
        window.SIPml.init().then((success) => {
            // alert(success);
            this.postInit();
        }).catch((error) => {
            console.log(error);
        });
    
        // set other options after initialization
        if (s_fps) window.SIPml.setFps(parseFloat(s_fps));
        if (s_mvs) window.SIPml.setMaxVideoSize(s_mvs);
        if (s_mbwu) window.SIPml.setMaxBandwidthUp(parseFloat(s_mbwu));
        if (s_mbwd) window.SIPml.setMaxBandwidthDown(parseFloat(s_mbwd));
        if (s_za) window.SIPml.setZeroArtifacts(s_za === "true");
        if (s_ndb == "true") window.SIPml.startNativeDebug();
    
        //var rinningApps = window.SIPml.getRunningApps();
        //var _rinningApps = Base64.decode(rinningApps);
        //tsk_utils_log_info(_rinningApps);
    }    

    render(){
        return (<div></div>);
    }

}

// export default SIPml
const mapDispatchToProps = dispatch => ( {
    callStatusChanged: (status) => 
    {
        dispatch({type: 'callStatus' , value: status});
    },
    callerNumberChanged: (number) => 
    {
        dispatch({type: 'callerNumber' , value: number});
    } ,
    mute: (muted) => 
    {
        dispatch({type: 'mute' , value: muted});
    }   
  } )
  const mapStateToProps = ( state ) => ( {
    value: state
} )

export default connect(mapStateToProps,mapDispatchToProps)(SIPml)