import { createStore } from 'redux'

const initialState = {
    newVersion: false,
    emotion: false,
    callStatus: 'Ready',
    callerNumber: '0',
    threshold: 0.5,
    threshold2: 0.5,
    threshold3: 0.5,
    threshold4: 0.5,
    threshold5: 0.5,
    mute:false
};

function init(state = initialState, action) {
    // console.log(action);
    switch (action.type) {
        case 'new_version':
            state.newVersion = !state.newVersion;
        return  state;
        case 'mute':
            state.mute = action.value;
        return {...state};
        case 'emotion':
            state.emotion = !state.emotion;
            if(state.agent_emotion){
                state.agent_emotion = !state.agent_emotion;
            }
            if(state.context){
                state.context = !state.context;
            }
        return {...state};
        case 'context':
            state.context = !state.context;
            if(state.emotion){
                state.emotion = !state.emotion;
            }            
            if(state.agent_emotion){
                state.agent_emotion = !state.agent_emotion;
            }            
        return {...state};   
        case 'agent_emotion':
            state.agent_emotion = !state.agent_emotion;
            if(state.emotion){
                state.emotion = !state.emotion;
            }
            if(state.context){
                state.context = !state.context;
            }            
        return {...state}; 
        case 'threshold':
            state.threshold = action.value;
        return {
            newVersion: state.newVersion,
            emotion: state.emotion,
            callStatus: state.callStatus,
            callerNumber: state.callerNumber,
            phoneNumber: state.phoneNumber,
            threshold: state.threshold,
            threshold2: state.threshold2,
            threshold3: state.threshold3,
            threshold4: state.threshold4,
            threshold5: state.threshold5,
        };  
        case 'threshold2':
            state.threshold2 = action.value;            
        return {
            newVersion: state.newVersion,
            emotion: state.emotion,
            callStatus: state.callStatus,
            callerNumber: state.callerNumber,
            phoneNumber: state.phoneNumber,
            threshold: state.threshold,
            threshold2: state.threshold2,
            threshold3: state.threshold3,
            threshold4: state.threshold4,
            threshold5: state.threshold5,
        };
        case 'threshold3':
            state.threshold3 = action.value;            
        return {
            newVersion: state.newVersion,
            emotion: state.emotion,
            callStatus: state.callStatus,
            callerNumber: state.callerNumber,
            phoneNumber: state.phoneNumber,
            threshold: state.threshold,
            threshold2: state.threshold2,
            threshold3: state.threshold3,
            threshold4: state.threshold4,
            threshold5: state.threshold5,
        };    
        case 'threshold4':
            state.threshold4 = action.value;            
        return {
            newVersion: state.newVersion,
            emotion: state.emotion,
            callStatus: state.callStatus,
            callerNumber: state.callerNumber,
            phoneNumber: state.phoneNumber,
            threshold: state.threshold,
            threshold2: state.threshold2,
            threshold3: state.threshold3,
            threshold4: state.threshold4,
            threshold5: state.threshold5,
        };     
        case 'threshold5':
            state.threshold5 = action.value;            
        return {
            newVersion: state.newVersion,
            emotion: state.emotion,
            callStatus: state.callStatus,
            callerNumber: state.callerNumber,
            phoneNumber: state.phoneNumber,
            threshold: state.threshold,
            threshold2: state.threshold2,
            threshold3: state.threshold3,
            threshold4: state.threshold4,
            threshold5: state.threshold5,
        };     
        case 'callStatus':
            state.callStatus = action.value;
        return {
            newVersion: state.newVersion,
            emotion: state.emotion,
            callStatus: state.callStatus,
            callerNumber: state.callerNumber,
            phoneNumber: state.phoneNumber,
            threshold: state.threshold,
            threshold2: state.threshold2,
            threshold3: state.threshold3,
            threshold4: state.threshold4,
            threshold5: state.threshold5,
        };
        case 'callerNumber':
            state.callerNumber = action.value;
        return {
            newVersion: state.newVersion,
            emotion: state.emotion,
            callStatus: state.callStatus,
            callerNumber: state.callerNumber,
            phoneNumber: state.phoneNumber,
            threshold: state.threshold,
            threshold2: state.threshold2,
            threshold3: state.threshold3,
            threshold4: state.threshold4,
            threshold5: state.threshold5,
        };
        case 'phoneNumber':
            state.phoneNumber = action.value;
        return {
            newVersion: state.newVersion,
            emotion: state.emotion,
            callStatus: state.callStatus,
            callerNumber: state.callerNumber,
            phoneNumber: state.phoneNumber,
            threshold: state.threshold,
            threshold2: state.threshold2,
            threshold3: state.threshold3,
            threshold4: state.threshold4,
            threshold5: state.threshold5,
        };
      default:
        return state
    }
  }
  

export default function store(text) {
    const store = createStore(init);
    return store;
}
