import React, { Component } from 'react';
import { Close,Photo,InsertDriveFileOutlined,Add, Cancel,CancelOutlined,CallOutlined,EmailOutlined,SentimentSatisfiedAlt,EmojiObjectsOutlined, EmojiObjects } from '@material-ui/icons';
import FileManager from './chatinfo/FileManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook,faFacebookMessenger,faTwitterSquare,faWhatsapp, faLine } from '@fortawesome/free-brands-svg-icons'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import Moment from 'react-moment';
import 'moment-timezone';

import Modal from 'react-modal';
import { faEnvelope, faComment, faHeadset, faCoffee,faPhone,faRedo,faCrown,faShareSquare,faTicketAlt,faSmileWink } from '@fortawesome/free-solid-svg-icons';
import AgentPicker from './chatbox/AgentPicker';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Owner from './chatinfo/Owner';
import { connect } from 'react-redux';
import Tippy from '@tippyjs/react';
import Variable from './../../variable';
import { IconAlertCircleFilled, IconCircleCheckFilled, IconCircleXFilled } from '@tabler/icons-react';
// import Store from './../../store';
// const store = Store();

const modalStyles = {
    content : {
      top                   : 'auto',
      left                  : 'auto',
      right                 : 'auto',
      bottom                : 'auto',
      maxWidth:'500px',
      border: 'none',
      boxShadow: '0 2px 3px 0 rgba(60,64,67,.3),0 6px 10px 4px rgba(60,64,67,.15)',
      borderRadius : '15px',
      padding : '0px'
    },
    overlay : {
      backgroundColor: ('rgba(0,0,0,0.5)')
    }
  };


  const emotionColor = {
    "neutral" : "#787878",
    "angry" : "#C0392B",
    "fear" : "#F4D03F",
    "frustration" : "#E67E22",
    "threat" : "#A93226",
    "commitment" : "#138D75",
    "satisfaction" : "#94C973",
    "appreciation" : "#DE3163",
    "gratitude" : "#82E0AA",
    "joy" : "#85C1E9",
    "disappointment" : "#90A4AE",
    "dissapointment" : "#90A4AE",
    "sadness" : "#5885AF",
    "dissatisfaction" : "#FAD7A0",
    "sarcasm" : "#F5B7B1",
    "surprise" : "#D2B4DE",
    "concerned" : "#7D6608",
    "sad":"#455A64",
    "happy" : "#85C1E9",
}


export class ChatInfo extends Component {

    host = Variable.host;
    
    state = {
        viewMode : 'image',
        id : null,
        activity: [],
        loadMore: false,
        showAddButton: false,
        modalIsOpen: false,
        profile: "",
        tabSelected: "new",
        keyword: "",
        profileData: [],
        invalidName: true,
        participants: [],
        agentPickerShow: false,
        escalateModalOpen : false,
        convertModalIsOpen: false,
        reason: "",
        invalidReason: false,
        department:[],
        channelData:{},
        showAtTop: false,
        keepCopy:false,
        displayName:"",
        kbData:[],
        qaJson:null,
        securityText:{responses:[]},
        professionalism:{responses:[]},
        callHandling:{responses:[]},
        communication:{responses:[]},
        problemSolving:{responses:[]},
        category:[],
        qaRes:{},
        personalityText:{},
        modalType: ""
    }

    name = "";

    userId = "";

    loadMore = false;

    searchTimeout;

    profile = [];

    selectedId = null;

    selectedDepartment = null;

    channel = "";

    ticketId = "";

    firstLoaded = false;

    constructor(props){
        super(props);
        // this.scrollerDiv = React.createRef();
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this); 
        this.updateAll = this.update.bind(this);     
        this.refBar = React.createRef();  
        //this.refresh = this.update.bind(this);
        //this.refresh2 = this.refreshParticipant.bind(this);

        // store.subscribe(() => {
        //     // When state will be updated(in our case, when items will be fetched), 
        //     // we will update local component state and force component to rerender 
        //     // with new data.
        //     //alert(store.getState().emotion);
        //     this.setState({
        //       emotion: store.getState().emotion
        //     });
        //   });        
    }



    setProfileName = (e) => {
        this.setState({ profile: e.target.value });
    }    

    setReason = (e) => {
        this.setState({ reason: e.target.value });
    }        

    searchProfile = (e) => {
        clearTimeout(this.searchTimeout);
        let value = e.target.value;
        this.setState({profileData:[]});
        this.searchTimeout = setTimeout(()=>{
            axios.get(this.host+'/profile/search/'+(encodeURIComponent(value))+'?_token='+this.getCookie("auth"))
            .then(res => {
                this.setState({profileData:res.data})
            }); 
        },2000);
        //this.setState({ keyword: e.target.value });
    }  
    
    selectProfile = (id,index) => {
        let temp = this.state.profileData;
        for(let i = 0; i < temp.length; i++){
            temp[i].selected = false;
        }
        temp[index].selected = true;
        this.setState({profileData:temp});
        this.selectedId = id;
    }

    selectDepartment = (id,index) => {
        let temp = this.state.department;
        for(let i = 0; i < temp.length; i++){
            temp[i].selected = false;
        }
        temp[index].selected = true;
        this.setState({department:temp});
        this.selectedDepartment = id;
    }    

    openModal() {
        this.selectedId = null;
        this.setState({modalIsOpen: true,overflowMenuVisible:false,profileData:[],tabSelected:"new",invalidName:false,profile:this.props.channelData.name});
      }

      openEscModal = () => {
          
          this.selectedDepartment = null;
          this.setState({escalateModalOpen: true,reason:""});
          axios.get(this.host+'/department/'+this.props.client)
          .then(res => {
            this.setState({department:res.data});
          })           
      }

      
      openConvertModal = () => {
          

        this.setState({convertModalIsOpen: true,modalType:""});        
    }      

    openAssistanceModal = () => {
          

        this.setState({convertModalIsOpen: true,modalType:"assistance"});        
    } 

    closeConvertModal = () => {
        this.setState({convertModalIsOpen: false});    
    }
      
    
      afterOpenModal() {
        // references are now sync'd and can be accessed.
        // if(this.state.darkTheme){
        //   this.subtitle.style.color = '#fefefe';
        // }
        // else{
        //   this.subtitle.style.color = '#f00';
        // }
      }
    
      closeModal() {
        this.setState({modalIsOpen: false});
      }
      
      closeEscModal = () => {
          this.setState({escalateModalOpen: false});
      }

    componentDidMount(){
        //this.props.refresh(this.refresh);
       // this.props.refreshParticipant(this.refresh2);
        this.firstLoaded = true;
        this.props.updateInfo(this.updateAll);

        // axios.get(this.host+'/api/wauchat/kb/get')
        // .then(res => {
        //     this.setState({kbData:res.data});
        // })        
        //this.scrollerDiv.current.addEventListener('scroll', this.scrollFunction);
        //this.updateInfo();
               
        //console.log(this.props.match.params[0]);

    }

    handleTicket = () => {
        axios.post(this.host+'/api/ticket/handle',{_token:this.getCookie("auth"),action:(this.props.channelData.handler.indexOf(this.props.username) !== -1 ? "remove" : "add"),"ticket_id":this.props.channelData.id,username:this.props.username},{withCredentials: true})
        .then(res => {
            if(res.data.result === "success"){
                let channelData = this.props.channelData;
                channelData.handler = JSON.parse(res.data.data);
                this.setState({channelData : channelData});
            }
        });        
    }    

    escalateTicket = () => {
        this.setState({escalating:true});
        axios.post(this.host+'/api/ticket/escalate',{_token:this.getCookie("auth"),"id":this.selectedDepartment,"ticket_id":this.props.channelData.id,"reason":this.state.reason},{withCredentials: true})
        .then(res => {
            if(res.data.result === "success"){
                this.setState({escalateModalOpen:false,escalating:false});
                axios.get(this.host+'/ticket/participants/'+this.props.match.params[0]+'/'+this.getCookie("auth"))
                .then(res => {
                    this.props.setParticipant(res.data)
                })
            }
        });        
    }

    addProfile = () => {
        this.closeModal();
        let channelGroup = (this.props.channelData.channel === "FB_PM" || this.props.channelData.channel === "FB_PAGE_POST") ? "facebook" : ((this.props.channelData.channel === "TW_DM" || this.props.channelData.channel === "TW_MENTION") ? "twitter" : (this.props.channelData.channel === "LINE_DM" ? "line" : (this.props.channelData.channel === "EMAIL" ? "email" : (this.props.channelData.channel === "WAUCHAT" ? "wauchat" : (this.props.channelData.channel === "MANUAL" ? "manual" :  (this.props.channelData.channel === "LIVECHAT" ? "livechat" : (this.props.channelData.channel === "WHATSAPP" ? "whatsapp" : "other")))))))
        if(this.state.tabSelected === "new"){ 
            if(this.state.profile.length !== 0){    
                this.setState({invalidName:false});       
                axios.post(this.host+'/api/profile/insert',{_token:this.getCookie("auth"),channel:channelGroup,userId: this.props.channelData.channel === "LIVECHAT" ? this.props.channelData.session_id : this.props.channelData.userId,name:this.state.profile,phone:this.props.channelData.phone || "N/A"},{withCredentials: true})
                .then(res => {
                    if(res.data.result === "success"){
                        this.name = res.data.data.name
                        this.setState({activity:res.data.activity,showAddButton:false,profile:res.data.data.name})
                            if(res.data.activity.length >= 2){
                                this.setState({loadMore:true})
                            }
                            else{
                                this.setState({loadMore:false})
                            }
                    }
                }); 
            }     
            else{
                this.setState({invalidName:true});
            } 
        }
        else{
            axios.post(this.host+'/api/profile/update',{_token:this.getCookie("auth"),channel:channelGroup,userId:this.props.channelData.channel === "LIVECHAT" ? this.props.channelData.session_id : this.props.channelData.userId,name:this.state.profile,id:this.selectedId,phone:this.props.channelData.phone || "N/A"},{withCredentials: true})
            .then(res => {
                if(res.data.result === "success"){
                    this.name = res.data.data.name
                    this.setState({activity:res.data.activity,showAddButton:false,profile:res.data.data.name})
                        if(res.data.activity.length >= 2){
                            this.setState({loadMore:true})
                        }
                        else{
                            this.setState({loadMore:false})
                        }
                }
            });              
        }   
    }

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }    
    
    tabSwitch = () => {
        let temp = this.state.profileData;
        for(let i = 0; i < temp.length; i++){
            temp[i].selected = false;
        }
        this.setState({profileData:temp});
        this.selectedId = null;        
    }

    loadMoreActivity = () => {
        axios.get(this.host+'/user/profile/'+(this.props.channelData.userId !== "" ? (this.props.channelData.channel === "LIVECHAT" ? this.props.channelData.session_id : this.props.channelData.userId) : "none")+'/'+this.state.activity.length+'?_token='+this.getCookie("auth"))
        .then(res => {
            if(res.data.result === undefined){
                this.name = res.data.data.name;
                let temp = this.state.activity;
                temp = temp.concat(res.data.activity);
                this.setState({activity:temp})
                if(res.data.activity.length >= 2){
                    this.setState({loadMore:true})
                }
                else{
                    this.setState({loadMore:false})
                }
            }
        });         
    }

    isBottom = () => {
          
        var scrolled = (this.scrollerDiv.current.scrollHeight - this.scrollerDiv.current.scrollTop)
        //console.log(scrolled +" - " +(this.scrollElement.clientHeight + 50));
        var threshold = (this.scrollerDiv.current.clientHeight);
        //console.log(el.scrollTop >= scrollHeight);
        return scrolled <= threshold;
    }          

    componentDidUpdate(){
        
    }

    updateInfo = () => {
        // this.update();
            // axios.get(this.host+'/ticket/info2/'+this.getCookie("auth")+'/'+this.props.match.params[0], {params:{_token:this.getCookie("auth")}},{withCredentials: true})
            // .then(res => {      
            //     let data = res.data[0];
            //     data.handler = JSON.parse(data.handler);
            //     console.log(data.handler);
            //     this.setState({channelData: data},()=>{

                    


            //     });
            // })
              
    }

    scrollFunction = (e) => {
        if (e.top === 1) {
            //do someting
            //this.loadMoreFiles(this.state.viewMode);
            //alert("bottom!");
        }
    }

    getEmotionPercentage = (data) => {
        let total = this.props.channelData.emotion.anger + this.props.channelData.emotion.fear + this.props.channelData.emotion.happy + this.props.channelData.emotion.love + this.props.channelData.emotion.sad + this.props.channelData.emotion.surprise;
        if(this.props.channelData.emotion.curious){
            total = total + this.props.channelData.emotion.curious;
        }
        return this.props.channelData.emotion[data] === 0 ? 0 : Math.round(this.props.channelData.emotion[data] / total * 100);
    }

    getEmotionPercentage2 = (data,type) => {

        let total = 0;

        if(type === "all"){
            if(!Array.isArray(this.props.channelData.emotion_2)){
                Object.keys(this.props.channelData.emotion_2).map((key, index)=> {
                    total = total + this.props.channelData.emotion_2[key];
                })
                return this.props.channelData.emotion_2[data] === 0 ? 0 : Math.round(this.props.channelData.emotion_2[data] / total * 100);
            }
            else{
                return 0;
            }
        }
        else if(type === "customer"){
            if(!Array.isArray(this.props.channelData.customerEmotion)){
                Object.keys(this.props.channelData.customerEmotion).map((key, index)=> {
                    total = total + this.props.channelData.customerEmotion[key];
                })
                return this.props.channelData.customerEmotion[data] === 0 ? 0 : Math.round(this.props.channelData.customerEmotion[data] / total * 100);
            }
            else{
                return 0;
            }
        }
        else if(type === "agent"){
            if(!Array.isArray(this.props.channelData.agentEmotion)){
                Object.keys(this.props.channelData.agentEmotion).map((key, index)=> {
                    total = total + this.props.channelData.agentEmotion[key];
                })
                return this.props.channelData.agentEmotion[data] === 0 ? 0 : Math.round(this.props.channelData.agentEmotion[data] / total * 100);
            }
            else{
                return 0;
            }
        }
        return 0;
        // return this.props.channelData.emotion[data] === 0 ? 0 : Math.round(this.props.channelData.emotion[data] / total * 100);
    }    

    update = () => {

            //this.setState({id:this.props.channelData.id});

            
            console.log("updating channel info");
            if(this.props.channelData.userId !== undefined){
                console.log(this.props.channelData.id);
                    axios.get(this.host+'/api/intelligence/'+this.props.channelData.id+'/summary?_token='+this.getCookie("auth"))
                    .then(res => {
                        if(res.data.result){
                            if(res.data.result === "Still in progress"){
                                alert("Another analysis is in progress");
                                return false;
                            }
                        }
                        this.setState({summaryText:res.data.result ?? res.data.summary});
                    });  

                    axios.get(this.host+'/api/intelligence/'+this.props.channelData.id+'/personality?_token='+this.getCookie("auth"))
                    .then(res => {
                        if(res.data.result){
                            if(res.data.result === "Still in progress"){
                                alert("Another analysis is in progress");
                                return false;
                            }
                        }
                        this.setState({personalityText:res.data});
                    });  



                    if(1){
                        axios.get(this.host+'/api/qa/category', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
                        .then(res => {
                            this.setState({category:res.data});

                            for(let i = 0; i < res.data.length;i++){
                                axios.post(this.host+'/api/intelligence/'+this.props.channelData.id+'/qa_2',{_token:this.getCookie("auth"),question:res.data[i].question,category:res.data[i].category_name})
                                .then(res2 => {
                                    if(res.data.result){
                                        if(res.data.result === "Still in progress"){
                                            alert("Another analysis is in progress");
                                            return false;
                                        }
                                    }
                                    let temp = this.state.qaRes;
                                    temp[res.data[i].id] = res2.data;
                                    this.setState({qaRes:temp});
                                });  
                            }
                        }   
                        )


                        // axios.get(this.host+'/api/intelligence/'+this.props.channelData.id+'/bmw_security')
                        // .then(res => {
                        //     if(res.data.result){
                        //         if(res.data.result === "Still in progress"){
                        //             alert("Another analysis is in progress");
                        //             return false;
                        //         }
                        //     }
                        //     this.setState({securityText:res.data});
                        // });  

                        // axios.get(this.host+'/api/intelligence/'+this.props.channelData.id+'/bmw_professionalism')
                        // .then(res => {
                        //     if(res.data.result){
                        //         if(res.data.result === "Still in progress"){
                        //             alert("Another analysis is in progress");
                        //             return false;
                        //         }
                        //     }
                        //     this.setState({professionalism:res.data});
                        // }); 

                        // axios.get(this.host+'/api/intelligence/'+this.props.channelData.id+'/bmw_call_handling_skills')
                        // .then(res => {
                        //     if(res.data.result){
                        //         if(res.data.result === "Still in progress"){
                        //             alert("Another analysis is in progress");
                        //             return false;
                        //         }
                        //     }
                        //     this.setState({callHandling:res.data});
                        // }); 

                        // axios.get(this.host+'/api/intelligence/'+this.props.channelData.id+'/bmw_communication_skills')
                        // .then(res => {
                        //     if(res.data.result){
                        //         if(res.data.result === "Still in progress"){
                        //             alert("Another analysis is in progress");
                        //             return false;
                        //         }
                        //     }
                        //     this.setState({communication:res.data});
                        // }); 

                        // axios.get(this.host+'/api/intelligence/'+this.props.channelData.id+'/bmw_problem_solving_skills')
                        // .then(res => {
                        //     if(res.data.result){
                        //         if(res.data.result === "Still in progress"){
                        //             alert("Another analysis is in progress");
                        //             return false;
                        //         }
                        //     }
                        //     this.setState({problemSolving:res.data});
                        // }); 
     
                    }

                // axios.get(this.host+'/user/profile/'+(this.props.channelData.userId !== "" ? (this.props.channelData.channel === "LIVECHAT" ? this.props.channelData.session_id : this.props.channelData.userId) : "none")+'/0?_token='+this.getCookie("auth"))
                // .then(res => {
                //     if(res.data.result === undefined){
                //         this.name = res.data.data.name
                //         this.setState({activity:res.data.activity,showAddButton:false,profile:res.data.data.name})
                //             if(res.data.activity.length >= 2){
                //                 this.setState({loadMore:true,displayName:res.data.data.name})
                //             }
                //             else{
                //                 this.setState({loadMore:false,displayName:res.data.data.name})
                //             }
                //     }
                //     else{
                //         if(this.props.channelData.userId === ""){
                //             this.setState({activity:[],loadMore:false,showAddButton:false,displayName:""})
                //         }
                //         else{
                //             this.setState({activity:[],loadMore:false,showAddButton:true,profile:this.props.channelData.name,displayName:""})
                //         }
                //     }
                // });    
            }  
            else{
                console.log("is undefined");
            }

            // axios.get(this.host+'/ticket/participants/'+this.props.match.params[0]+'/'+this.getCookie("auth"))
            // .then(res => {
            //     this.props.setParticipant(res.data)
            // })
            // this.loadFiles(this.state.viewMode);

    }

    refreshParticipant = () => {
        axios.get(this.host+'/ticket/participants/'+this.props.match.params[0]+'/'+this.getCookie("auth"))
        .then(res => {
            this.props.setParticipant(res.data)
        })        
    }

    refreshParticipant2 = () => {
        axios.post(this.host+'/api/ticket/refresh',{_token:this.getCookie("auth"),ticket_id:this.props.match.params[0]},{withCredentials: true})  
        .then(res => {
            this.refreshParticipant();
            //console.log(res.data);
        })        
    }    

    getName = (id) => {

    }

    toggleEmotion = () => {
        this.props.emotionChanged();
        // alert("clicked");
        // store.dispatch({type: 'emotion' });
    }

    toggleEmotion2 = () => {
        this.props.agentEmotionChanged();
        // alert("clicked");
        // store.dispatch({type: 'emotion' });
    }

    toggleContext = () => {
        this.props.toggleContext();
        // alert("clicked");
        // store.dispatch({type: 'emotion' });
    }

    getContextPercentage = (data) => {

        let total = 0;

        if(!Array.isArray(this.props.channelData.context)){
            Object.keys(this.props.channelData.context).map((key, index)=> {
                total = total + this.props.channelData.context[key];
            })
            return this.props.channelData.context[data] === 0 ? 0 : Math.round(this.props.channelData.context[data] / total * 100);
        }
        else{
            return 0;
        }

        // return this.props.channelData.emotion[data] === 0 ? 0 : Math.round(this.props.channelData.emotion[data] / total * 100);
    }    



    closePanel = () => {
        this.props.panel(false);
    }

    showAgentPicker = (e) => {
        this.setState({agentPickerShow:!this.state.agentPickerShow,showAtTop: (e.clientY + 267) > Math.max(document.documentElement.clientHeight, window.innerHeight || 0)});
    }

    addParticipant = (username) => {
        axios.post(this.host+'/api/ticket/participant/add',{_token:this.getCookie("auth"),username:username,ticket_id:this.props.match.params[0]},{withCredentials: true})        
        .then(res => {
            //console.log(res.data);
            if(res.data.result === "success"){
                this.props.updateTicketTime(this.props.channelData.id);
                axios.get(this.host+'/ticket/participants/'+this.props.match.params[0]+'/'+this.getCookie("auth"))
                .then(res => {
                    this.props.setParticipant(res.data);
                    this.props.socket.emit("participantChanged",{ticketId:this.props.channelData.id,client:this.props.client,username:username,action:"add",ticket_number:this.props.match.params[0]});
                })                
            }
        })
    }

    toggleCopy = () => {
        this.setState({keepCopy:!this.state.keepCopy});
      }    

    removeParticipant = (username) => {
        axios.post(this.host+'/api/ticket/participant/remove',{_token:this.getCookie("auth"),username:username,ticket_id:this.props.match.params[0]},{withCredentials: true})        
        .then(res => {
            //console.log(res.data);
            if(res.data.result === "success"){
                this.props.updateTicketTime(this.props.channelData.id);
                axios.get(this.host+'/ticket/participants/'+this.props.match.params[0]+'/'+this.getCookie("auth"))
                .then(res => {
                    this.props.setParticipant(res.data);
                    this.props.socket.emit("participantChanged",{ticketId:this.props.channelData.id,client:this.props.client,username:username,action:"remove",ticket_number:this.props.match.params[0]});
                })                
            }
        })
    }    

    getSeverityFull = (letter) => {
        switch(letter){
            case "H":
                return "High";
            case "M":
                return "Medium";
            case "L":
                return "Low";
            case "default":
                    return "Unknown";
        }
    }

    getSeverityColor = (letter) => {
        switch(letter){
            case "H":
                return "red-text";
            case "M":
                return "orange-text";
            case "L":
                return "green-text";
            case "default":
                    return "";
        }
    }    

    convertLiveChat = () => {
       
        
        this.setState({ticketCreating:true},()=>{
            if(this.state.modalType === "assistance"){
                axios.get(this.host+'/api/facebook/pages/create_link',{params:{_token:this.getCookie("auth"),number:this.props.channelData.ref}},{withCredentials: true})
                .then(res => {
                    this.setState({convertModalIsOpen:false,ticketCreating:false});
                    if(res.data.uid){
                        // window.open(res.data.uid, '_blank').focus();
                        return false;
                    }
                    // if(res.data.result === "success"){
                    //     this.setState({escalateModalOpen:false});
                    //     axios.get(this.host+'/ticket/participants/'+this.props.match.params[0]+'/'+this.getCookie("auth"))
                    //     .then(res => {
                    //         this.props.setParticipant(res.data)
                    //     })
                    // }
                }).catch(err=>{
                    alert(JSON.stringify(err));
                    this.setState({ticketCreating:false});
                });  
            }
            else{
                axios.post(this.host+'/api/ticket2/call_to_ticket',{_token:this.getCookie("auth"),id:this.props.channelData.id},{withCredentials: true})
                .then(res => {
                    this.setState({convertModalIsOpen:false,ticketCreating:false});
                    // if(res.data.result === "success"){
                    //     this.setState({escalateModalOpen:false});
                    //     axios.get(this.host+'/ticket/participants/'+this.props.match.params[0]+'/'+this.getCookie("auth"))
                    //     .then(res => {
                    //         this.props.setParticipant(res.data)
                    //     })
                    // }
                }).catch(err=>{
                    alert(JSON.stringify(err));
                    this.setState({ticketCreating:false});
                });     
            }
        });     
    }

    kbList = (kb) => {
        return kb.map((obj,i) => (
            <div className="kb-parent">
            <div className="kb-item" key={i}>
                                          
                <h6 className="margin-left-1x">{obj.title}</h6>
                <div className="kb-children">
                {
                    obj.qa ? 
                        obj.qa.map((obj2,i2) => (
                            <div className="emotion-notification-item" key={i2}>
                                <div className={("avatar-container flex-no-shrink yellow")}>
                                {/* <FontAwesomeIcon icon={faCheckDouble}/> */}
                                    <EmojiObjects/>
                                </div>                            
                                <h6 className="margin-left-1x">{obj2.q.en}</h6>
                            </div>                             
                        ))
                        :
                    null
                }      
                </div>          
            </div> 
            {
                obj.children ? 
                this.kbList(obj.children)
                :
                null
            } 
            </div>                   
        ))
    }

    kbList2 = (kb) => {
        return kb.map((obj,i) => (
                                   
            <div className={"emotion-notification-item"+(obj.highlighted ? " yellow lighten-3" : "")} key={i}>
                <div className={("avatar-container flex-no-shrink yellow")}>
                {/* <FontAwesomeIcon icon={faCheckDouble}/> */}
                    <EmojiObjects/>
                </div>                            
                <div>
                    <h6 className="margin-left-1x bold-text">{obj.qa.q.en}</h6>
                    <h6 className="margin-left-1x">{obj.qa.a.en}</h6>
                </div>
            </div>           
                   
        ))
    }

    darkMode = {maxWidth:'400px',width: '40%',min:'1px',flexBasis:'40%',flexShrink:0,backgroundColor:'#333333',marginLeft:'-40px',paddingLeft:'40px',zIndex:'-1'};  
    lightMode = {maxWidth:'400px',width: '40%',min:'1px',flexBasis:'40%',flexShrink:0,backgroundColor:'#fefefe',marginLeft:'-40px',paddingLeft:'40px',zIndex:'-1'};  


    render() {
        //console.log(this.props.channel);
        return (
            <div className={"flex-col "+(this.props.show && !this.props.onMeeting ? "" : "hide")+((this.props.match.params[0] === "ongoing" ? " shrinked-panel" : ""))} style={this.props.dark ? this.darkMode : this.lightMode}>
                <div className="valign-wrapper padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x flex-row header-border-bottom" style={{flexShrink:0,position:'relative',height:'64px',backgroundColor:!this.props.dark ? 'rgba(255,255,255,1)' : '#333333'}}>
                    <div className="chat-info-header full-width">
                        {/* <span className="bold-text emphasize-text chat-info-header-text">Info</span> */}
                    </div>
                    <div className="icon-button-hover linked" style={{marginTop:"5px"}} onClick={this.closePanel}>
                        <Close className="material-icons linked"/>
                    </div>
                </div>  
                
                <Modal
                      isOpen={this.state.modalIsOpen}
                      onAfterOpen={this.afterOpenModal}
                      onRequestClose={this.closeModal}
                      style={modalStyles}
                      contentLabel="Example Modal"
                      ariaHideApp={false} 
                      closeTimeoutMS={200}       
                                                       
                      className={""+(this.props.dark ? "dark" : null)}   
 
                      
                    >
                      <div className="flex-col" style={{width:'500px',maxHeight:'100vh'}}>
                        <div className={"ng-modal-main-header"+(this.state.profileScrolled ? " scrolled" : "")} style={{paddingBottom:0}}>
                          <h5 className="bold-text-2 smaller-font">Add Contacts</h5>
                          <div className="custom-tab">
                            <div className={"custom-tab-item unselectable waves-effect "+(this.state.tabSelected === "new" ? "clicked" : "")+(this.props.dark ? " dark" : "")} onClick={()=>{this.setState({tabSelected:"new"});this.tabSwitch()}}>New</div>
                            <div className={"custom-tab-item unselectable waves-effect "+(this.state.tabSelected === "existing" ? "clicked" : "")+(this.props.dark ? " dark" : "")} onClick={()=>{this.setState({tabSelected:"existing"});this.tabSwitch()}}>Existing</div> 
                                                     
                        </div>
                        </div>
                        <div className="divider"></div>
                        <Scrollbars autoHeight autoHeightMax={200} onScrollFrame={(e)=>{this.setState({profileScrolled:(e.top > 0)})}}>
                        <div className="ng-modal-main-body padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x">
                            <div className={"new-profile-container "+(this.state.tabSelected === "new" ? "" : "hide")}>
                                <div>
                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                    <input className={"browser-default " + (this.state.invalidName ? "invalid" : "")} type="text" onChange={ e => this.setProfileName(e) } value={this.state.profile}/>
                                    <span>Name</span>
                                    </label>
                                </div>
                                { this.state.invalidName ? <h6 className="red callout-box white-text">Please fill this field.</h6> : null }
                            </div>
                            <div className={"existing-profile-container "+(this.state.tabSelected === "existing" ? "" : "hide")}>
                                <div>
                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                    <input className="browser-default" type="text" onChange={ e => this.searchProfile(e) }/>
                                        <span>Search by name</span>
                                    </label>
                                </div>
                                <div className="search-profile-container">
                                    {this.state.profileData.map( (obj,i) => {
                                        return(
                                        <div className={"border-base margin-bottom-1x hover-tint linked "+(obj.selected !== undefined && obj.selected === true ? (this.props.dark ? "green-border white-text" : "green-border white-text") : "")} onClick={()=>{this.selectProfile(obj.id,i)}} key={obj.id} style={{padding:'10px',borderRadius:'5px',backgroundColor:this.props.dark ? 'rgba(255,255,255,0.1)':'rgba(0,0,0,0.05)'}}>
                                            <h6>{obj.name}</h6>
                                            <h6>{obj.email}</h6>
                                            <h6>{obj.location}</h6>
                                            <h6>{obj.phone}</h6>
                                        </div>
                                        )
                                    })}
                                </div>
                            </div>                            
                        </div>
                        </Scrollbars>
                        <div className="ng-modal-main-footer right-text">                      
                          <button className="btn-flat ng-close-btn waves-effect waves-dark margin-right-1x" onClick={this.closeModal}>cancel</button>
                          <button className={"btn-flat green waves-effect waves-light white-text "+((this.state.tabSelected === "new" && this.state.profile.length > 0) || (this.state.tabSelected === "existing" && this.selectedId !== null) ? "" : "disabled")} onClick={this.addProfile}>Save</button>
                          {/* <button className="btn waves-effect waves-light margin-left-1x" onClick={this.closeModal}>OK</button> */}
                        </div>
                      </div>
                    </Modal>    

                    <Modal
                        isOpen={this.state.escalateModalOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeEscModal}
                        style={modalStyles}
                        contentLabel="Example Modal"
                        ariaHideApp={false} 
                        closeTimeoutMS={200}       
                                                        
                        className={""+(this.props.dark ? "dark" : null)}   
  
                        
                      >
                        <div className="flex-col" style={{width:'100%',maxHeight:'100vh'}}>
                          <div className={"ng-modal-main-header"+(this.state.escalateScrolled ? " scrolled" : "")}>
                            <h5 className="bold-text-2 smaller-font">Escalate {this.props.chatType ==="livechat" ? "Live Chat" : "Ticket"}</h5>
                          </div>
                          <Scrollbars autoHeight autoHeightMax={300} onScrollFrame={(e)=>{this.setState({escalateScrolled:(e.top > 0)})}}>
                          <div className="ng-modal-main-body padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x">
                              <h6>Click to select department</h6>
                              {
                                  this.state.department.map((obj,i) => ( 
                                      <div className={"border-base margin-bottom-1x hover-tint linked "+(obj.selected !== undefined && obj.selected === true ? (this.props.dark ? "green-border white-text" : "green-border white-text") : "")} onClick={()=>{this.selectDepartment(obj.id,i)}} key={i} style={{padding:'10px',borderRadius:'5px',backgroundColor:this.props.dark ? 'rgba(255,255,255,0.1)':'rgba(0,0,0,0.05)'}}>
                                        <h6>{obj.name}</h6>
                                      </div>
                                  )
                                  )
                              }
                              {
                                  this.state.department.length === 0 ? <p className="red-text">You don't have any department yet. Please add a new one at superceed.com</p> : null
                              }
                                <div>
                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <input className={"browser-default " + (this.state.invalidReason ? "invalid" : "")} type="text" onChange={ e => this.setReason(e) } value={this.state.reason}/>
                                        <span>Reason</span>
                                    </label>
                                </div>                              
                                <h6>Once you escalated the {this.props.chatType ==="livechat" ? "live chat" : "ticket"}, you will no longer a participant in this conversation.</h6>
                          </div>
                          </Scrollbars>
                          <div className="ng-modal-main-footer right-text">                      
                            <button className="btn-flat ng-close-btn waves-effect waves-dark margin-right-1x" onClick={this.closeEscModal}>close</button>
                            <button className={(this.state.escalating ? "disabled " : "" )+"btn-flat green waves-effect waves-light white-text "+( (this.state.reason !== "" && this.selectedDepartment !== null) ? "" : "disabled")} onClick={this.escalateTicket}>Escalate</button>
                            {/* <button className="btn waves-effect waves-light margin-left-1x" onClick={this.closeModal}>OK</button> */}
                          </div>
                        </div>
                      </Modal>  


                    <Modal
                      isOpen={this.state.convertModalIsOpen}
                      onAfterOpen={this.afterOpenModal}
                      onRequestClose={this.closeConvertModal}
                      style={modalStyles}
                      contentLabel="Example Modal"
                      ariaHideApp={false} 
                      closeTimeoutMS={200}       
                                                       
                      className={""+(this.props.dark ? "dark" : null)}   
 
                      
                    >
                      <div style={{width:'500px'}}>
                        <div className="ng-modal-main-header">
                          <h5 className="bold-text-2 smaller-font">Create new Ticket</h5>
                        </div>
                        <div className="ng-modal-main-body padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x">
                          <h6 className="modal-bottom-margin">Are you sure you want to create a new ticket in <span className='bold-text-2'>SuperOne</span>?</h6>
                          {/* <div className="">
                                <input className="material filled-in" type="checkbox" id="switch" checked={this.state.keepCopy} onChange={this.toggleCopy}/><label className="material" htmlFor="switch">Keep a copy of this conversation in live chat</label>
                            </div>                               */}
                          {/* <p>Opening live chat will be closed.</p> */}
                        </div>
                        <div className="ng-modal-main-footer right-text">                      
                          <button className="btn-flat ng-close-btn waves-effect waves-dark margin-right-1x" onClick={this.closeConvertModal}>cancel</button>
                          <button className={"button-new waves-effect waves-light supercall-blue white-text"+(this.state.ticketCreating ? " disabled" : "")} onClick={this.convertLiveChat}>Yes</button>
                          {/* <button className="btn waves-effect waves-light margin-left-1x" onClick={this.closeModal}>OK</button> */}
                        </div>
                      </div>
                    </Modal>                                              
                <Scrollbars ref={ e => this.scrollerDiv = e }>
                <div className="full-height info-container">

                
                        {/* <div style={{padding:'5px',margin:'5px',borderRadius:'5px',backgroundColor:this.props.dark ? 'rgba(255,255,255,0.1)':'rgba(0,0,0,0.05)'}}>
                            <p className="grey-text no-line-height mid-text">20-7-2019</p>
                            <div className="flex-row" style={{alignItems:'center'}}>
                                <FontAwesomeIcon className="linked margin-left-1x show-tooltip margin-right-1x" style={{fontSize:'20px'}} icon={faFacebookMessenger}/>                            
                                <h6>Contacted via Facebook Messenger</h6>
                            </div>
                        </div>                         */}
                    <div>
                        {
                            this.kbList2(this.props.kbData)
                        }
                    </div>
                    {(this.props.match.params[0] === "1ongoing" ? <div className="meter-holder">
                        <h6>Energy</h6>
                        <div className="meter">
                                <div className="meter-color">
                                    <div className="meter-color-wrapper">
                                        <span className="color1"></span>
                                        <span className="color2"></span>
                                        <span className="color3"></span>
                                        <span className="color4"></span>
                                        <span className="color5"></span>
                                        <span className="cover"></span>                                        
                                    </div>
                                </div>  
                                <div className="meter-text flex-row full-width">
                                    <p className="full-width">Soft</p>  
                                    <p>Loud</p>  
                                </div>
                                <span class="needle" style={{transform: 'rotate('+(-90 + (90 * (this.props.energy / 50)))+'deg)', }}></span>                        
                        </div>
                    </div> : null)}

                    {(this.props.match.params[0] === "1ongoing" ? <div className="meter-holder">
                        <h6>Pace</h6>
                        <div className="meter">
                            {/* <div className="meter-mask"> */}
                                <div className="meter-color">
                                    <div className="meter-color-wrapper">
                                        <span className="color1"></span>
                                        <span className="color2"></span>
                                        <span className="color3"></span>
                                        <span className="color4"></span>
                                        <span className="color5"></span>
                                        <span className="cover"></span>
                                    </div>
                                    <div className="meter-text flex-row full-width">
                                        <p className="full-width">Slow</p>  
                                        <p>Fast</p>  
                                    </div>
                                </div>
                            {/* </div> */}
                            
                            {/* <span class="speedometer"></span> */}
	                        <span class="needle" style={{transform: 'rotate('+(-90 + (90 * (this.props.pace / 50)))+'deg)', }}></span>                           
                        </div>
                    </div> : null)}
 
                    {(this.props.chatType === "call"  || this.props.chatType === "livechat" ?
                        <React.Fragment>
                      
                                <div className="chat-info-padding margin-top-2x">
                                    <div className='info-section-container'>
                                    <h6 className="bold-text-2 chat-info-panel-title-text margin-bottom-1x">Call Options</h6> 
                                    {(this.props.chatType === "call" ? 
                                            <React.Fragment>
                                            <div className="flex-row chat-info-toggle">
                                                    <h6 className="full-width">Customer Emotion</h6>
                                                    <div className="ios-toggle">
                                                        <input className="ios" type="checkbox" id="emotion" checked={this.props.value.emotion} onChange={this.toggleEmotion}/><label className="ios" htmlFor="emotion">Toggle</label>
                                                    </div> 
                                                </div>
                                                <div className="flex-row chat-info-toggle">
                                                    <h6 className="full-width">Agent Emotion</h6>
                                                    <div className="ios-toggle">
                                                        <input className="ios" type="checkbox" id="emotion-2" checked={this.props.value.agent_emotion} onChange={this.toggleEmotion2}/><label className="ios" htmlFor="emotion-2">Toggle</label>
                                                    </div> 
                                                </div>
                                                <div className="flex-row chat-info-toggle">
                                                    <h6 className="full-width">Customer Context</h6>
                                                    <div className="ios-toggle">
                                                        <input className="ios" type="checkbox" id="context" checked={this.props.value.context} onChange={this.toggleContext}/><label className="ios" htmlFor="context">Toggle</label>
                                                    </div> 
                                                </div>
                                                {/* <button className={"margin-bottom-1x btn-flat grey "+(this.props.dark ? "darken-3" : "lighten-2")+(this.props.channelData.closed === 3 || this.props.channelData.closed === 2 ? " disabled" : "")} onClick={this.openEscModal}><span className="left margin-right-1x button-center-icon"><FontAwesomeIcon icon={faShareSquare}/></span>Escalate {this.props.chatType === "ticket" ? "Ticket" : "Live chat"}</button>  */}
                                                {/* <ReportSpam setBlock={this.setBlock}  userBlocked={this.state.userBlocked} dark={this.props.dark} channelData={this.props.channelData}/> */}
                                                
                                            </React.Fragment>
                                            // <React.Fragment>
                                            //     <button className={"margin-bottom-1x btn-flat grey "+(this.props.value.emotion ? "green white-text" : (this.props.dark ? "darken-3" : "lighten-2"))} onClick={this.toggleEmotion}><span className="valign-wrapper"><SentimentSatisfiedAlt className="left margin-right-1x"/>Toggle Emotion</span></button> 
                                            //     <button className={"margin-bottom-1x btn-flat grey "+(this.props.dark ? "darken-3" : "lighten-2")+(this.props.channelData.closed === 3 || this.props.channelData.closed === 2 ? " disabled" : "")} onClick={this.openEscModal}><span className="left margin-right-1x"><FontAwesomeIcon icon={faShareSquare}/></span>Escalate {this.props.chatType === "ticket" ? "Ticket" : "Live chat"}</button> 
                                                
                                            // </React.Fragment>

                                        :
                                        null
                                        )
                                    }
                                    {(this.props.chatType === "call" && this.props.match.params[0] !== "ongoing" ? 
                                        (this.props.chatType === "call"  ? 
                                            <React.Fragment>
                                                {/* <button className={"margin-bottom-1x btn-flat grey "+(this.props.value.emotion ? "green white-text" : (this.props.dark ? "darken-3" : "lighten-2"))} onClick={this.toggleEmotion}><span className="valign-wrapper"><SentimentSatisfiedAlt className="left margin-right-1x"/>Toggle Emotion</span></button>  */}
                                                <button className={"btn-flat grey "+(this.props.dark ? "darken-3" : "lighten-2")+(this.props.channelData.closed === 3  ? " disabled" : "")} onClick={this.openConvertModal}><span className="left margin-right-1x"><FontAwesomeIcon icon={faTicketAlt}/></span>Create Wrap Call Ticket</button>
                                                <button className={"btn-flat grey "+(this.props.dark ? "darken-3" : "lighten-2")+(this.props.channelData.closed === 3  ? " disabled" : "")} onClick={this.openAssistanceModal}><span className="left margin-right-1x"><FontAwesomeIcon icon={faTicketAlt}/></span>Create Vision Ticket</button>
                                            </React.Fragment>
                                        : 
                                            <React.Fragment>
                                                <button className={"margin-bottom-1x btn-flat grey "+(this.props.value.emotion ? "green white-text" : (this.props.dark ? "darken-3" : "lighten-2"))} onClick={this.toggleEmotion}><span className="valign-wrapper"><SentimentSatisfiedAlt className="left margin-right-1x"/>Toggle Emotion</span></button> 
                                                <button className={"btn-flat grey "+(this.props.dark ? "darken-3" : "lighten-2")+(this.props.channelData.closed === 3 || this.props.channelData.livechat ? " disabled" : "")} onClick={this.openEscModal}><span className="left margin-right-1x"><FontAwesomeIcon icon={faShareSquare}/></span>Escalate {this.props.chatType === "ticket" ? "Ticket" : "Live chat"}</button> 
                                            </React.Fragment>
                                        )
                                        :
                                        null
                                        )
                                    }
                                    {/* {(this.props.channelData.handler !== undefined  ? (this.props.channelData.handler.length === 0 ? <button className={"btn margin-top-1x "+(this.props.channelData.handler.indexOf(this.props.username) !== -1 ? "red" : "")} onClick={this.handleTicket}>{this.props.channelData.handler.indexOf(this.props.username) !== -1 ? "Remove Handle" : "Handle"} Ticket</button> : null) : null)} */}
                                </div>
                            </div>
                        </React.Fragment>
                     : null)}
                     {(this.props.match.params[0] !== "ongoing" ?
                        <React.Fragment>
                            <div className="chat-info-padding">
                                <h6 className="bold-text-2 chat-info-panel-title-text margin-bottom-1x">Summary</h6> 
                                <div className='info-section-container'>
                                    <h6 className=''>{this.state.summaryText}</h6>
                                </div>
                            </div>
                            <div className="chat-info-padding">
                                <h6 className="bold-text-2 chat-info-panel-title-text margin-bottom-1x">How to Handle</h6> 
                                <div className='info-section-container'>
                                    <h5 className='prewrap bold-text'>Personality</h5>
                                    <h6 className=' panel-description'>{this.state.personalityText.personality}</h6>
                                    <h5 className='prewrap bold-text'>Strategy</h5>
                                    {
                                        this.state.personalityText.expectation ?
                                        <h6 className=' panel-description'>{this.state.personalityText.expectation}</h6>
                                        :
                                        null
                                    }
                                    <h5 className='prewrap bold-text'>Recommendation</h5>
                                    {
                                        this.state.personalityText.agent_recommendation ?
                                        this.state.personalityText.agent_recommendation.map((obj,i)=>(<h6 className=' panel-description' key={i}>{obj}</h6>))
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="chat-info-padding">
                                <h6 className="bold-text-2 chat-info-panel-title-text margin-bottom-1x">QA</h6> 
                                {/* <div className='info-section-container'>
                                    <h6 className="bold-text-2 chat-info-panel-title-text margin-bottom-1x">Overall</h6> 
                                </div> */}
                                <div className='row'>
    
                                   
                               
                                    {
                                        this.state.category ? 
                                    this.state.category.map((obj2,i)=>(
                                        <div className='col s12 margin-bottom-2x no-padding'>
                                        <div className='info-section-container'>
                                        <>                            
                                        <h6 className="bold-text-2 chat-info-panel-title-text margin-bottom-1x">{obj2.category_name}</h6> 
                                        {
                                            this.state.qaRes[obj2.id] ? 
                                            <>
                                            <h6 className=' panel-description margin-bottom-2x'>{this.state.qaRes[obj2.id].overall}</h6>
                                            {
                                                this.state.qaRes[obj2.id].responses ?
                                                this.state.qaRes[obj2.id].responses.map((obj,i) => (
                                                    <div className='question-wrapper margin-bottom-2x' key={i}>
                                                        <h5 className='prewrap bold-text'>{obj.question}</h5>
                                                        {
                                                            obj.weightage === 1  || obj.weightage === true ?
                                                            <IconCircleCheckFilled
                                                                size={24} 
                                                                className="green-text"
                                                                stroke={2} 
                                                                strokeLinejoin="miter"
                                                            /> 
                                                            :
                                                            (obj.weightage === 0.5 ?
                                                                <IconAlertCircleFilled
                                                                    size={24} 
                                                                    className="orange-text"
                                                                    stroke={2} 
                                                                    strokeLinejoin="miter"
                                                                /> 
                                                                :
                                                                (obj.weightage !== undefined ? <IconCircleXFilled
                                                                    size={24} 
                                                                    className="red-text"
                                                                    stroke={2} 
                                                                    strokeLinejoin="miter"
                                                                /> : null ))
                                                        }
                                                        {
                                                            obj.mark === 1 ?
                                                            <IconCircleCheckFilled
                                                                size={24}                                                              
                                                                className="green-text"
                                                                stroke={2} 
                                                                strokeLinejoin="miter"
                                                            /> 
                                                            :
                                                            (obj.mark !== undefined ?
                                                                <IconCircleXFilled
                                                                size={24}                                                              
                                                                className="red-text"
                                                                stroke={2} 
                                                                strokeLinejoin="miter"
                                                            /> :
                                                            null)
                                                        }
                                                        <h6 className='prewrap justification-text'>{obj.justification}</h6>
                                                        {/* {obj.mark ? <h6 className='prewrap'>Mark: <span className='bold-text-2'>{obj.mark}</span></h6> : null} */}
                                                        
                                                    </div>
                                                ))
                                                :
                                                null
                                            }
                                            </>
                                            :
                                            null
                                        }
                                        </>
                                        <h6>Total: {this.state.qaRes[obj2.id] ? this.state.qaRes[obj2.id].total : ""}</h6>
                                        </div>
                                        </div>
                                    ))
                                    :
                                    null

                                }
                                  

                                  

                        

                                  

                                   
                                </div>
                               
                            </div>
                        </React.Fragment>
                            :
                            null
                       )}
                    <div className="chat-info-padding">
                    {
                                        this.props.value.emotion || this.props.value.agent_emotion ?
                                        <h6 className="bold-text margin-top-2x">{this.props.value.emotion && this.props.value.agent_emotion ? "": (this.props.value.emotion ? "Customer" : (this.props.value.agent_emotion ? "Agent" : ""))} Emotion Summary</h6>
                                        :
                                        null
                                    }
                                    {
                                        
                                    
                                        this.props.value.emotion || this.props.value.agent_emotion ?
                                        (

                                            this.props.value.emotion && this.props.value.agent_emotion ? 
                                            (
                                                !Array.isArray(this.props.channelData.emotion_2) && this.props.channelData.emotion_2 ?
                                                Object.keys(this.props.channelData.emotion_2).map((key, index)=> (
                                                    <React.Fragment key={index}>
                                                        <h6 >{key} - {this.props.channelData.emotion_2[key]} ({ (this.getEmotionPercentage2(key,"all")) + "%"})</h6>
                                                        <ProgressBar  className="browser-default margin-bottom-1x">
                                                            <ProgressBar now={this.getEmotionPercentage2(key,"all")} style={{backgroundColor: emotionColor[key.toLowerCase()]}}/>
                                                        </ProgressBar>

                                                    </React.Fragment>
                                                ))
                                                :
                                                null

                                            )

                                            :
                                            (this.props.value.emotion ?
                                                (
                                                    !Array.isArray(this.props.channelData.customerEmotion) && this.props.channelData.customerEmotion ?
                                                    Object.keys(this.props.channelData.customerEmotion).map((key, index)=> (
                                                        <React.Fragment key={index}>
                                                            <h6 >{key} - {this.props.channelData.customerEmotion[key]} ({ (this.getEmotionPercentage2(key,"customer")) + "%"})</h6>
                                                            <ProgressBar  className="browser-default margin-bottom-1x">
                                                                <ProgressBar now={this.getEmotionPercentage2(key,"customer")} style={{backgroundColor: emotionColor[key.toLowerCase()]}}/>
                                                            </ProgressBar>

                                                        </React.Fragment>
                                                    ))
                                                    :
                                                    null
                                                )
                                                :
                                                (
                                                    this.props.value.agent_emotion ?
                                                    (
                                                        !Array.isArray(this.props.channelData.agentEmotion) && this.props.channelData.agentEmotion ?
                                                        Object.keys(this.props.channelData.agentEmotion).map((key, index)=> (
                                                            <React.Fragment key={index}>
                                                                <h6 >{key} - {this.props.channelData.agentEmotion[key]} ({ (this.getEmotionPercentage2(key,"agent")) + "%"})</h6>
                                                                <ProgressBar  className="browser-default margin-bottom-1x">
                                                                    <ProgressBar now={this.getEmotionPercentage2(key,"agent")} style={{backgroundColor: emotionColor[key.toLowerCase()]}}/>
                                                                </ProgressBar>
        
                                                            </React.Fragment>
                                                        ))
                                                        :
                                                        null
                                                    )
                                                    :
                                                    null
                                                )

                                        )

                                        )
                                        :
                                        // (this.props.channelData.emotion ?
                                        //     <ProgressBar className="browser-default margin-bottom-1x">
                                                
                                        //         <ProgressBar  className="red" now={this.getEmotionPercentage("anger")} key={1} ref={this.refBar}/>
                                        //         <Tippy content={"Anger (" + (this.getEmotionPercentage("anger")) + "%)"} reference={this.refBar}/>      

                                        //         <ProgressBar className="yellow" now={this.getEmotionPercentage("fear")} key={2} ref={this.refBar} />
                                        //         <Tippy content={"Fear (" + (this.getEmotionPercentage("fear")) + "%)"} reference={this.refBar}/>
    
                                                
                                        //         <ProgressBar className="green" now={this.getEmotionPercentage("happy")} key={3}  ref={this.refBar}/>
                                        //         <Tippy content={"Happy (" + (this.getEmotionPercentage("happy")) + "%)"} reference={this.refBar}/>
    
                                                
                                        //         <ProgressBar className="pink" now={this.getEmotionPercentage("love")} key={4}  ref={this.refBar}/>
                                        //         <Tippy content={"Love (" + (this.getEmotionPercentage("love")) + "%)"} reference={this.refBar}/>
    
                                                
                                        //         <ProgressBar className="blue" now={this.getEmotionPercentage("sad")} key={5}  ref={this.refBar}/>
                                        //         <Tippy content={"Sad (" + (this.getEmotionPercentage("sad")) + "%)"} reference={this.refBar}/>
                                                
                                        //         <ProgressBar className="purple" now={this.getEmotionPercentage("surprise")} key={6}  ref={this.refBar}/>
                                        //         <Tippy content={"Surprise (" + (this.getEmotionPercentage("surprise")) + "%)"} reference={this.refBar}/>

                                        //         <ProgressBar className="brown" now={this.getEmotionPercentage("curious")} key={7}  ref={this.refBar}/>
                                        //         <Tippy content={"Curious (" + (this.getEmotionPercentage("curious")) + "%)"} reference={this.refBar}/>
                                        //     </ProgressBar>
                                        //     :
                                        //     null
                                        // )
                                        null
                                    }
                                    {
                                        this.props.value.context ?
                                        <h6 className="bold-text margin-top-2x">Context Summary</h6>
                                        :
                                        null
                                    }
                                    {
                                        this.props.value.context ?
                                        (
                                            !Array.isArray(this.props.channelData.context) && this.props.channelData.context ?
                                            Object.keys(this.props.channelData.context).map((key, index)=> (
                                                <React.Fragment key={index}>
                                                    <h6 >{key} - {this.props.channelData.context[key]} ({ (this.getContextPercentage(key)) + "%"})</h6>
                                                    <ProgressBar  className="browser-default margin-bottom-1x">
                                                        <ProgressBar now={this.getContextPercentage(key)} style={{backgroundColor: emotionColor[key]}}/>
                                                    </ProgressBar>

                                                </React.Fragment>
                                            ))
                                            :
                                            null

                                        )
                                        :
                                        null
                                    }

                    </div>
                    {/* <div className="divider margin-top-2x"></div>
                        <div className="chat-info-padding">
                            <h6 className="bold-text-2 chat-info-panel-title-text margin-bottom-1x">Uploaded Files</h6>  
                            <div className="category-container mid-text relative-content flex-row margin-bottom-1x">
                                <div onClick={()=>{this.setState({viewMode:'image'});this.loadFiles("image")}} className={"linked hover-tint-2 valign-wrapper margin-right-1x relative-content "+(this.state.viewMode==="image"?'custom-tab-item clicked':'custom-tab-item')} style={{padding:'10px'}}>
                                    <Photo className={this.state.viewMode==="image"?'accent-icon-color':''} style={{fontSize:'20px'}}/>                                                        
                                    <span className={"margin-left-1x margin-right-1x "+ (this.state.viewMode==="image"?'accent-icon-color':'')}>Image</span>
                                </div>
                                
                                <div onClick={()=>{this.setState({viewMode:'file'});this.loadFiles("file")}} className={"linked hover-tint-2 valign-wrapper relative-content " + (this.state.viewMode==="file"?'custom-tab-item clicked':'custom-tab-item')} style={{padding:'10px'}}>
                                    <InsertDriveFileOutlined className={this.state.viewMode==="file"?'accent-icon-color':''} style={{fontSize:'20px'}}/>                                
                                    <span className={"margin-left-1x margin-right-1x "+ (this.state.viewMode==="file"?'accent-icon-color':'')}>File</span>
                                </div>  
                                <div className="divider"></div>                   
                            </div>  
                            <FileManager
                                dark={this.props.dark}
                                id={this.props.channelData.id}
                                type={(this.state.viewMode === 'image' ?  "image" : "file")}
                                loadFiles={e=>this.loadFiles=e}
                                loadMore={e=>this.loadMoreFiles=e}
                                receiveImage={this.props.receiveImage}
                                deleteFile={this.props.deleteFile}
                                receiveFile={this.props.receiveFile}/>
                        </div> */}
                </div>  
                </Scrollbars>                      
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ( {
    emotionChanged: () => 
    {
        dispatch({type: 'emotion' });
    },
    agentEmotionChanged: () => 
    {
        dispatch({type: 'agent_emotion' });
    },
    toggleContext: () => 
    {
        dispatch({type: 'context' });
    }
  } )
  const mapStateToProps = ( state ) => ( {
    value: state
} )

export default connect(mapStateToProps,mapDispatchToProps)(ChatInfo)
