import React, { Component } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveBump } from '@nivo/bump'
import { ResponsiveCalendar } from '@nivo/calendar'
import { Notifications, ArrowDropDown, ArrowDropUp, Minimize, Remove } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee,faComment,faEnvelope, faHeadset,faCaretDown,faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { faFacebook, faTwitter, faLine } from '@fortawesome/free-brands-svg-icons'
import axios from 'axios';
import { BrowserRouter as Router, Route, Link, Switch, Redirect,useHistory } from 'react-router-dom';
import Circular from './../components/Circular';
import Variable from './../variable';
import Tippy from '@tippyjs/react';
import DashboardChart from './DashboardChart';

import { ResponsiveScatterPlot } from '@nivo/scatterplot'

const qaData = [
  {
    "id": "group A",
    "data": [
      {
        "x": 69,
        "y": 17
      },
      {
        "x": 93,
        "y": 13
      },
      {
        "x": 8,
        "y": 36
      },
      {
        "x": 48,
        "y": 102
      },
      {
        "x": 20,
        "y": 75
      },
      {
        "x": 61,
        "y": 83
      },
      {
        "x": 18,
        "y": 110
      },
      {
        "x": 30,
        "y": 72
      },
      {
        "x": 50,
        "y": 79
      },
      {
        "x": 62,
        "y": 26
      },
      {
        "x": 0,
        "y": 72
      },
      {
        "x": 61,
        "y": 94
      },
      {
        "x": 45,
        "y": 110
      },
      {
        "x": 55,
        "y": 112
      },
      {
        "x": 34,
        "y": 3
      },
      {
        "x": 28,
        "y": 101
      },
      {
        "x": 87,
        "y": 119
      },
      {
        "x": 76,
        "y": 118
      },
      {
        "x": 74,
        "y": 99
      },
      {
        "x": 3,
        "y": 97
      },
      {
        "x": 84,
        "y": 114
      },
      {
        "x": 37,
        "y": 4
      },
      {
        "x": 86,
        "y": 86
      },
      {
        "x": 65,
        "y": 62
      },
      {
        "x": 19,
        "y": 98
      },
      {
        "x": 37,
        "y": 42
      },
      {
        "x": 74,
        "y": 39
      },
      {
        "x": 9,
        "y": 7
      },
      {
        "x": 1,
        "y": 15
      },
      {
        "x": 15,
        "y": 112
      },
      {
        "x": 67,
        "y": 34
      },
      {
        "x": 12,
        "y": 3
      },
      {
        "x": 36,
        "y": 79
      },
      {
        "x": 27,
        "y": 64
      },
      {
        "x": 17,
        "y": 58
      },
      {
        "x": 62,
        "y": 65
      },
      {
        "x": 36,
        "y": 12
      },
      {
        "x": 24,
        "y": 50
      },
      {
        "x": 33,
        "y": 84
      },
      {
        "x": 32,
        "y": 102
      },
      {
        "x": 5,
        "y": 17
      },
      {
        "x": 70,
        "y": 26
      },
      {
        "x": 21,
        "y": 50
      },
      {
        "x": 52,
        "y": 65
      },
      {
        "x": 13,
        "y": 34
      },
      {
        "x": 84,
        "y": 109
      },
      {
        "x": 15,
        "y": 6
      },
      {
        "x": 95,
        "y": 45
      },
      {
        "x": 44,
        "y": 40
      },
      {
        "x": 54,
        "y": 81
      }
    ]
  },
  {
    "id": "group B",
    "data": [
      {
        "x": 16,
        "y": 20
      },
      {
        "x": 7,
        "y": 109
      },
      {
        "x": 92,
        "y": 61
      },
      {
        "x": 65,
        "y": 7
      },
      {
        "x": 80,
        "y": 72
      },
      {
        "x": 64,
        "y": 44
      },
      {
        "x": 55,
        "y": 96
      },
      {
        "x": 84,
        "y": 45
      },
      {
        "x": 15,
        "y": 77
      },
      {
        "x": 67,
        "y": 30
      },
      {
        "x": 26,
        "y": 98
      },
      {
        "x": 30,
        "y": 98
      },
      {
        "x": 40,
        "y": 88
      },
      {
        "x": 69,
        "y": 36
      },
      {
        "x": 96,
        "y": 27
      },
      {
        "x": 59,
        "y": 0
      },
      {
        "x": 1,
        "y": 55
      },
      {
        "x": 30,
        "y": 43
      },
      {
        "x": 7,
        "y": 47
      },
      {
        "x": 30,
        "y": 27
      },
      {
        "x": 32,
        "y": 9
      },
      {
        "x": 46,
        "y": 22
      },
      {
        "x": 100,
        "y": 13
      },
      {
        "x": 12,
        "y": 107
      },
      {
        "x": 23,
        "y": 95
      },
      {
        "x": 65,
        "y": 56
      },
      {
        "x": 41,
        "y": 21
      },
      {
        "x": 74,
        "y": 119
      },
      {
        "x": 26,
        "y": 37
      },
      {
        "x": 12,
        "y": 99
      },
      {
        "x": 80,
        "y": 91
      },
      {
        "x": 87,
        "y": 92
      },
      {
        "x": 74,
        "y": 72
      },
      {
        "x": 13,
        "y": 38
      },
      {
        "x": 84,
        "y": 107
      },
      {
        "x": 46,
        "y": 79
      },
      {
        "x": 26,
        "y": 120
      },
      {
        "x": 63,
        "y": 75
      },
      {
        "x": 75,
        "y": 120
      },
      {
        "x": 41,
        "y": 72
      },
      {
        "x": 24,
        "y": 54
      },
      {
        "x": 43,
        "y": 67
      },
      {
        "x": 1,
        "y": 33
      },
      {
        "x": 30,
        "y": 70
      },
      {
        "x": 8,
        "y": 50
      },
      {
        "x": 62,
        "y": 74
      },
      {
        "x": 93,
        "y": 79
      },
      {
        "x": 43,
        "y": 42
      },
      {
        "x": 28,
        "y": 4
      },
      {
        "x": 30,
        "y": 18
      }
    ]
  },
  {
    "id": "group C",
    "data": [
      {
        "x": 86,
        "y": 88
      },
      {
        "x": 47,
        "y": 105
      },
      {
        "x": 6,
        "y": 102
      },
      {
        "x": 80,
        "y": 3
      },
      {
        "x": 86,
        "y": 23
      },
      {
        "x": 85,
        "y": 2
      },
      {
        "x": 62,
        "y": 57
      },
      {
        "x": 38,
        "y": 50
      },
      {
        "x": 31,
        "y": 46
      },
      {
        "x": 29,
        "y": 92
      },
      {
        "x": 40,
        "y": 112
      },
      {
        "x": 92,
        "y": 114
      },
      {
        "x": 94,
        "y": 12
      },
      {
        "x": 21,
        "y": 0
      },
      {
        "x": 74,
        "y": 96
      },
      {
        "x": 12,
        "y": 14
      },
      {
        "x": 58,
        "y": 41
      },
      {
        "x": 51,
        "y": 105
      },
      {
        "x": 33,
        "y": 103
      },
      {
        "x": 53,
        "y": 112
      },
      {
        "x": 38,
        "y": 15
      },
      {
        "x": 71,
        "y": 102
      },
      {
        "x": 88,
        "y": 118
      },
      {
        "x": 58,
        "y": 55
      },
      {
        "x": 66,
        "y": 33
      },
      {
        "x": 50,
        "y": 36
      },
      {
        "x": 50,
        "y": 82
      },
      {
        "x": 99,
        "y": 22
      },
      {
        "x": 94,
        "y": 16
      },
      {
        "x": 67,
        "y": 6
      },
      {
        "x": 54,
        "y": 26
      },
      {
        "x": 91,
        "y": 73
      },
      {
        "x": 22,
        "y": 83
      },
      {
        "x": 77,
        "y": 19
      },
      {
        "x": 7,
        "y": 30
      },
      {
        "x": 60,
        "y": 64
      },
      {
        "x": 95,
        "y": 16
      },
      {
        "x": 6,
        "y": 50
      },
      {
        "x": 75,
        "y": 111
      },
      {
        "x": 52,
        "y": 68
      },
      {
        "x": 50,
        "y": 1
      },
      {
        "x": 57,
        "y": 114
      },
      {
        "x": 5,
        "y": 69
      },
      {
        "x": 50,
        "y": 82
      },
      {
        "x": 17,
        "y": 64
      },
      {
        "x": 84,
        "y": 82
      },
      {
        "x": 47,
        "y": 40
      },
      {
        "x": 20,
        "y": 38
      },
      {
        "x": 2,
        "y": 90
      },
      {
        "x": 26,
        "y": 39
      }
    ]
  },
  {
    "id": "group D",
    "data": [
      {
        "x": 56,
        "y": 23
      },
      {
        "x": 38,
        "y": 60
      },
      {
        "x": 52,
        "y": 95
      },
      {
        "x": 19,
        "y": 91
      },
      {
        "x": 88,
        "y": 102
      },
      {
        "x": 40,
        "y": 68
      },
      {
        "x": 52,
        "y": 21
      },
      {
        "x": 45,
        "y": 100
      },
      {
        "x": 14,
        "y": 83
      },
      {
        "x": 94,
        "y": 24
      },
      {
        "x": 69,
        "y": 17
      },
      {
        "x": 85,
        "y": 51
      },
      {
        "x": 69,
        "y": 47
      },
      {
        "x": 58,
        "y": 24
      },
      {
        "x": 86,
        "y": 46
      },
      {
        "x": 47,
        "y": 27
      },
      {
        "x": 91,
        "y": 94
      },
      {
        "x": 73,
        "y": 107
      },
      {
        "x": 17,
        "y": 119
      },
      {
        "x": 81,
        "y": 94
      },
      {
        "x": 64,
        "y": 10
      },
      {
        "x": 70,
        "y": 18
      },
      {
        "x": 8,
        "y": 112
      },
      {
        "x": 66,
        "y": 59
      },
      {
        "x": 16,
        "y": 11
      },
      {
        "x": 53,
        "y": 22
      },
      {
        "x": 25,
        "y": 62
      },
      {
        "x": 86,
        "y": 75
      },
      {
        "x": 2,
        "y": 118
      },
      {
        "x": 56,
        "y": 69
      },
      {
        "x": 15,
        "y": 72
      },
      {
        "x": 56,
        "y": 5
      },
      {
        "x": 92,
        "y": 29
      },
      {
        "x": 72,
        "y": 58
      },
      {
        "x": 74,
        "y": 67
      },
      {
        "x": 39,
        "y": 2
      },
      {
        "x": 52,
        "y": 108
      },
      {
        "x": 30,
        "y": 46
      },
      {
        "x": 4,
        "y": 9
      },
      {
        "x": 3,
        "y": 58
      },
      {
        "x": 1,
        "y": 113
      },
      {
        "x": 50,
        "y": 31
      },
      {
        "x": 81,
        "y": 88
      },
      {
        "x": 55,
        "y": 46
      },
      {
        "x": 76,
        "y": 105
      },
      {
        "x": 95,
        "y": 38
      },
      {
        "x": 76,
        "y": 10
      },
      {
        "x": 56,
        "y": 16
      },
      {
        "x": 8,
        "y": 60
      },
      {
        "x": 66,
        "y": 111
      }
    ]
  },
  {
    "id": "group E",
    "data": [
      {
        "x": 15,
        "y": 82
      },
      {
        "x": 7,
        "y": 62
      },
      {
        "x": 49,
        "y": 98
      },
      {
        "x": 84,
        "y": 0
      },
      {
        "x": 36,
        "y": 15
      },
      {
        "x": 53,
        "y": 109
      },
      {
        "x": 8,
        "y": 12
      },
      {
        "x": 59,
        "y": 116
      },
      {
        "x": 47,
        "y": 29
      },
      {
        "x": 95,
        "y": 79
      },
      {
        "x": 47,
        "y": 11
      },
      {
        "x": 24,
        "y": 87
      },
      {
        "x": 82,
        "y": 52
      },
      {
        "x": 27,
        "y": 55
      },
      {
        "x": 42,
        "y": 27
      },
      {
        "x": 64,
        "y": 30
      },
      {
        "x": 1,
        "y": 13
      },
      {
        "x": 17,
        "y": 119
      },
      {
        "x": 24,
        "y": 117
      },
      {
        "x": 57,
        "y": 1
      },
      {
        "x": 81,
        "y": 95
      },
      {
        "x": 41,
        "y": 44
      },
      {
        "x": 78,
        "y": 22
      },
      {
        "x": 99,
        "y": 76
      },
      {
        "x": 4,
        "y": 75
      },
      {
        "x": 7,
        "y": 79
      },
      {
        "x": 4,
        "y": 49
      },
      {
        "x": 4,
        "y": 57
      },
      {
        "x": 83,
        "y": 67
      },
      {
        "x": 69,
        "y": 61
      },
      {
        "x": 16,
        "y": 86
      },
      {
        "x": 1,
        "y": 92
      },
      {
        "x": 76,
        "y": 105
      },
      {
        "x": 21,
        "y": 66
      },
      {
        "x": 97,
        "y": 79
      },
      {
        "x": 19,
        "y": 8
      },
      {
        "x": 62,
        "y": 31
      },
      {
        "x": 31,
        "y": 28
      },
      {
        "x": 32,
        "y": 76
      },
      {
        "x": 39,
        "y": 19
      },
      {
        "x": 75,
        "y": 83
      },
      {
        "x": 69,
        "y": 44
      },
      {
        "x": 33,
        "y": 23
      },
      {
        "x": 0,
        "y": 47
      },
      {
        "x": 5,
        "y": 41
      },
      {
        "x": 96,
        "y": 6
      },
      {
        "x": 85,
        "y": 74
      },
      {
        "x": 83,
        "y": 76
      },
      {
        "x": 81,
        "y": 18
      },
      {
        "x": 87,
        "y": 42
      }
    ]
  }
];

const data = [
    {
      "id": "Serie 1",
      "data": [
        {
          "x": 2000,
          "y": 12
        },
        {
          "x": 2001,
          "y": 2
        },
        {
          "x": 2002,
          "y": 7
        },
        {
          "x": 2003,
          "y": 8
        },
        {
          "x": 2004,
          "y": 11
        }
      ]
    },
    {
      "id": "Serie 2",
      "data": [
        {
          "x": 2000,
          "y": 12
        },
        {
          "x": 2001,
          "y": 3
        },
        {
          "x": 2002,
          "y": 6
        },
        {
          "x": 2003,
          "y": 1
        },
        {
          "x": 2004,
          "y": 4
        }
      ]
    },
    {
      "id": "Serie 3",
      "data": [
        {
          "x": 2000,
          "y": 3
        },
        {
          "x": 2001,
          "y": 10
        },
        {
          "x": 2002,
          "y": 2
        },
        {
          "x": 2003,
          "y": 3
        },
        {
          "x": 2004,
          "y": 9
        }
      ]
    },
    {
      "id": "Serie 4",
      "data": [
        {
          "x": 2000,
          "y": 9
        },
        {
          "x": 2001,
          "y": 5
        },
        {
          "x": 2002,
          "y": 4
        },
        {
          "x": 2003,
          "y": 12
        },
        {
          "x": 2004,
          "y": 7
        }
      ]
    },
    {
      "id": "Serie 5",
      "data": [
        {
          "x": 2000,
          "y": 5
        },
        {
          "x": 2001,
          "y": 12
        },
        {
          "x": 2002,
          "y": 9
        },
        {
          "x": 2003,
          "y": 6
        },
        {
          "x": 2004,
          "y": 8
        }
      ]
    },
    {
      "id": "Serie 6",
      "data": [
        {
          "x": 2000,
          "y": 10
        },
        {
          "x": 2001,
          "y": 1
        },
        {
          "x": 2002,
          "y": 10
        },
        {
          "x": 2003,
          "y": 11
        },
        {
          "x": 2004,
          "y": 1
        }
      ]
    },
    {
      "id": "Serie 7",
      "data": [
        {
          "x": 2000,
          "y": 7
        },
        {
          "x": 2001,
          "y": 8
        },
        {
          "x": 2002,
          "y": 1
        },
        {
          "x": 2003,
          "y": 7
        },
        {
          "x": 2004,
          "y": 2
        }
      ]
    },
    {
      "id": "Serie 8",
      "data": [
        {
          "x": 2000,
          "y": 1
        },
        {
          "x": 2001,
          "y": 6
        },
        {
          "x": 2002,
          "y": 12
        },
        {
          "x": 2003,
          "y": 4
        },
        {
          "x": 2004,
          "y": 12
        }
      ]
    },
    {
      "id": "Serie 9",
      "data": [
        {
          "x": 2000,
          "y": 8
        },
        {
          "x": 2001,
          "y": 9
        },
        {
          "x": 2002,
          "y": 3
        },
        {
          "x": 2003,
          "y": 2
        },
        {
          "x": 2004,
          "y": 3
        }
      ]
    },
    {
      "id": "Serie 10",
      "data": [
        {
          "x": 2000,
          "y": 6
        },
        {
          "x": 2001,
          "y": 11
        },
        {
          "x": 2002,
          "y": 11
        },
        {
          "x": 2003,
          "y": 5
        },
        {
          "x": 2004,
          "y": 10
        }
      ]
    },
    {
      "id": "Serie 11",
      "data": [
        {
          "x": 2000,
          "y": 11
        },
        {
          "x": 2001,
          "y": 4
        },
        {
          "x": 2002,
          "y": 8
        },
        {
          "x": 2003,
          "y": 10
        },
        {
          "x": 2004,
          "y": 5
        }
      ]
    },
    {
      "id": "Serie 12",
      "data": [
        {
          "x": 2000,
          "y": 2
        },
        {
          "x": 2001,
          "y": 7
        },
        {
          "x": 2002,
          "y": 5
        },
        {
          "x": 2003,
          "y": 9
        },
        {
          "x": 2004,
          "y": 6
        }
      ]
    }
  ];

const data2 = [
    {
      "day": "2017-06-08",
      "value": 306
    },
    {
      "day": "2017-06-01",
      "value": 195
    },
    {
      "day": "2017-06-12",
      "value": 329
    },
    {
      "day": "2016-12-13",
      "value": 278
    },
    {
      "day": "2015-04-14",
      "value": 175
    },
    {
      "day": "2016-10-04",
      "value": 382
    },
    {
      "day": "2018-04-25",
      "value": 117
    },
    {
      "day": "2018-06-15",
      "value": 96
    },
    {
      "day": "2015-08-20",
      "value": 41
    },
    {
      "day": "2015-06-08",
      "value": 152
    },
    {
      "day": "2015-06-16",
      "value": 369
    },
    {
      "day": "2017-06-09",
      "value": 2
    },
    {
      "day": "2018-02-03",
      "value": 6
    },
    {
      "day": "2017-05-09",
      "value": 140
    },
    {
      "day": "2016-04-18",
      "value": 103
    },
    {
      "day": "2017-05-18",
      "value": 184
    },
    {
      "day": "2015-08-24",
      "value": 59
    },
    {
      "day": "2018-08-02",
      "value": 51
    },
    {
      "day": "2017-10-24",
      "value": 29
    },
    {
      "day": "2017-06-23",
      "value": 172
    },
    {
      "day": "2017-02-10",
      "value": 188
    },
    {
      "day": "2017-05-31",
      "value": 150
    },
    {
      "day": "2017-10-31",
      "value": 152
    },
    {
      "day": "2015-08-19",
      "value": 220
    },
    {
      "day": "2018-07-20",
      "value": 387
    },
    {
      "day": "2017-08-03",
      "value": 350
    },
    {
      "day": "2017-04-25",
      "value": 309
    },
    {
      "day": "2015-12-08",
      "value": 47
    },
    {
      "day": "2016-06-16",
      "value": 223
    },
    {
      "day": "2016-11-20",
      "value": 146
    },
    {
      "day": "2016-07-05",
      "value": 35
    },
    {
      "day": "2016-12-09",
      "value": 398
    },
    {
      "day": "2015-08-21",
      "value": 258
    },
    {
      "day": "2016-02-09",
      "value": 24
    },
    {
      "day": "2018-07-15",
      "value": 226
    },
    {
      "day": "2018-03-13",
      "value": 347
    },
    {
      "day": "2015-04-07",
      "value": 322
    },
    {
      "day": "2017-06-02",
      "value": 197
    },
    {
      "day": "2018-02-05",
      "value": 32
    },
    {
      "day": "2017-07-12",
      "value": 78
    },
    {
      "day": "2016-02-20",
      "value": 100
    },
    {
      "day": "2017-02-19",
      "value": 78
    },
    {
      "day": "2016-02-25",
      "value": 179
    },
    {
      "day": "2015-11-29",
      "value": 71
    },
    {
      "day": "2017-11-28",
      "value": 16
    },
    {
      "day": "2017-02-13",
      "value": 125
    },
    {
      "day": "2016-01-12",
      "value": 43
    },
    {
      "day": "2016-03-16",
      "value": 50
    },
    {
      "day": "2017-03-02",
      "value": 399
    },
    {
      "day": "2016-02-17",
      "value": 159
    },
    {
      "day": "2017-05-25",
      "value": 289
    },
    {
      "day": "2015-04-20",
      "value": 154
    },
    {
      "day": "2017-11-20",
      "value": 19
    },
    {
      "day": "2017-08-27",
      "value": 176
    },
    {
      "day": "2015-06-02",
      "value": 342
    },
    {
      "day": "2018-02-27",
      "value": 252
    },
    {
      "day": "2015-04-01",
      "value": 122
    },
    {
      "day": "2017-04-23",
      "value": 221
    },
    {
      "day": "2018-04-10",
      "value": 231
    },
    {
      "day": "2015-08-30",
      "value": 114
    },
    {
      "day": "2018-03-02",
      "value": 8
    },
    {
      "day": "2016-03-18",
      "value": 49
    },
    {
      "day": "2018-08-07",
      "value": 30
    },
    {
      "day": "2017-07-14",
      "value": 145
    },
    {
      "day": "2015-08-02",
      "value": 181
    },
    {
      "day": "2017-12-25",
      "value": 188
    },
    {
      "day": "2018-05-02",
      "value": 332
    },
    {
      "day": "2016-04-22",
      "value": 350
    },
    {
      "day": "2016-11-02",
      "value": 27
    },
    {
      "day": "2017-03-21",
      "value": 349
    },
    {
      "day": "2016-07-14",
      "value": 230
    },
    {
      "day": "2018-07-11",
      "value": 330
    },
    {
      "day": "2018-06-10",
      "value": 118
    },
    {
      "day": "2018-01-30",
      "value": 131
    },
    {
      "day": "2015-10-06",
      "value": 245
    },
    {
      "day": "2015-07-09",
      "value": 218
    },
    {
      "day": "2018-06-09",
      "value": 255
    },
    {
      "day": "2016-08-15",
      "value": 354
    },
    {
      "day": "2015-06-20",
      "value": 83
    },
    {
      "day": "2016-12-11",
      "value": 61
    },
    {
      "day": "2016-08-30",
      "value": 2
    },
    {
      "day": "2017-04-09",
      "value": 322
    },
    {
      "day": "2018-07-27",
      "value": 226
    },
    {
      "day": "2016-10-14",
      "value": 114
    },
    {
      "day": "2015-05-28",
      "value": 211
    },
    {
      "day": "2018-07-17",
      "value": 362
    },
    {
      "day": "2016-06-30",
      "value": 205
    },
    {
      "day": "2018-04-02",
      "value": 192
    },
    {
      "day": "2016-10-13",
      "value": 77
    },
    {
      "day": "2015-05-07",
      "value": 308
    },
    {
      "day": "2017-09-04",
      "value": 86
    },
    {
      "day": "2016-02-12",
      "value": 73
    },
    {
      "day": "2016-12-06",
      "value": 122
    },
    {
      "day": "2015-05-23",
      "value": 231
    },
    {
      "day": "2015-10-25",
      "value": 132
    },
    {
      "day": "2015-06-18",
      "value": 317
    },
    {
      "day": "2015-08-28",
      "value": 287
    },
    {
      "day": "2016-07-01",
      "value": 351
    },
    {
      "day": "2016-04-11",
      "value": 176
    },
    {
      "day": "2016-02-08",
      "value": 156
    },
    {
      "day": "2017-10-13",
      "value": 68
    },
    {
      "day": "2016-04-06",
      "value": 182
    },
    {
      "day": "2017-12-10",
      "value": 316
    },
    {
      "day": "2018-02-07",
      "value": 218
    },
    {
      "day": "2016-08-10",
      "value": 315
    },
    {
      "day": "2016-05-29",
      "value": 345
    },
    {
      "day": "2015-04-19",
      "value": 289
    },
    {
      "day": "2016-03-24",
      "value": 274
    },
    {
      "day": "2016-12-27",
      "value": 44
    },
    {
      "day": "2018-06-05",
      "value": 271
    },
    {
      "day": "2018-05-04",
      "value": 101
    },
    {
      "day": "2015-05-21",
      "value": 363
    },
    {
      "day": "2018-08-09",
      "value": 232
    },
    {
      "day": "2016-09-07",
      "value": 74
    },
    {
      "day": "2017-04-13",
      "value": 273
    },
    {
      "day": "2017-08-25",
      "value": 165
    },
    {
      "day": "2017-02-23",
      "value": 364
    },
    {
      "day": "2015-04-21",
      "value": 301
    },
    {
      "day": "2016-12-05",
      "value": 190
    },
    {
      "day": "2017-03-28",
      "value": 37
    },
    {
      "day": "2018-01-27",
      "value": 311
    },
    {
      "day": "2015-08-06",
      "value": 395
    },
    {
      "day": "2017-07-28",
      "value": 256
    },
    {
      "day": "2015-11-10",
      "value": 270
    },
    {
      "day": "2015-05-04",
      "value": 105
    },
    {
      "day": "2017-08-21",
      "value": 275
    },
    {
      "day": "2016-10-12",
      "value": 262
    },
    {
      "day": "2015-05-13",
      "value": 239
    },
    {
      "day": "2016-01-23",
      "value": 302
    },
    {
      "day": "2017-10-18",
      "value": 381
    },
    {
      "day": "2017-03-25",
      "value": 192
    },
    {
      "day": "2017-01-16",
      "value": 95
    },
    {
      "day": "2018-05-10",
      "value": 380
    },
    {
      "day": "2016-01-28",
      "value": 211
    },
    {
      "day": "2018-07-24",
      "value": 275
    },
    {
      "day": "2015-05-18",
      "value": 153
    },
    {
      "day": "2018-03-26",
      "value": 33
    },
    {
      "day": "2017-07-30",
      "value": 391
    },
    {
      "day": "2017-08-07",
      "value": 324
    },
    {
      "day": "2016-08-04",
      "value": 329
    },
    {
      "day": "2017-10-04",
      "value": 156
    },
    {
      "day": "2018-07-02",
      "value": 88
    },
    {
      "day": "2017-05-04",
      "value": 162
    },
    {
      "day": "2017-05-05",
      "value": 215
    },
    {
      "day": "2017-12-17",
      "value": 9
    },
    {
      "day": "2016-01-29",
      "value": 67
    },
    {
      "day": "2017-09-03",
      "value": 210
    },
    {
      "day": "2016-09-10",
      "value": 389
    },
    {
      "day": "2015-10-07",
      "value": 212
    },
    {
      "day": "2016-05-06",
      "value": 187
    },
    {
      "day": "2017-03-16",
      "value": 57
    },
    {
      "day": "2018-04-20",
      "value": 111
    },
    {
      "day": "2017-01-27",
      "value": 282
    },
    {
      "day": "2018-02-17",
      "value": 131
    },
    {
      "day": "2015-06-07",
      "value": 381
    },
    {
      "day": "2017-08-23",
      "value": 270
    },
    {
      "day": "2017-09-12",
      "value": 124
    },
    {
      "day": "2017-09-06",
      "value": 315
    },
    {
      "day": "2018-07-06",
      "value": 34
    },
    {
      "day": "2016-08-22",
      "value": 168
    },
    {
      "day": "2016-06-20",
      "value": 376
    },
    {
      "day": "2017-08-01",
      "value": 83
    },
    {
      "day": "2016-06-07",
      "value": 233
    },
    {
      "day": "2016-11-22",
      "value": 381
    },
    {
      "day": "2018-04-14",
      "value": 381
    },
    {
      "day": "2017-11-02",
      "value": 275
    },
    {
      "day": "2016-06-28",
      "value": 391
    },
    {
      "day": "2017-04-20",
      "value": 382
    },
    {
      "day": "2017-03-06",
      "value": 250
    },
    {
      "day": "2015-12-24",
      "value": 391
    },
    {
      "day": "2017-01-06",
      "value": 61
    },
    {
      "day": "2015-12-23",
      "value": 181
    },
    {
      "day": "2017-04-22",
      "value": 301
    },
    {
      "day": "2017-10-14",
      "value": 383
    },
    {
      "day": "2017-10-05",
      "value": 259
    },
    {
      "day": "2017-08-29",
      "value": 194
    },
    {
      "day": "2018-08-01",
      "value": 228
    },
    {
      "day": "2017-02-06",
      "value": 147
    },
    {
      "day": "2018-03-27",
      "value": 255
    },
    {
      "day": "2016-05-24",
      "value": 284
    },
    {
      "day": "2018-07-08",
      "value": 28
    },
    {
      "day": "2017-08-16",
      "value": 88
    },
    {
      "day": "2015-06-25",
      "value": 187
    },
    {
      "day": "2016-10-09",
      "value": 339
    },
    {
      "day": "2018-08-03",
      "value": 187
    },
    {
      "day": "2015-11-18",
      "value": 307
    },
    {
      "day": "2017-04-02",
      "value": 220
    },
    {
      "day": "2015-11-14",
      "value": 46
    },
    {
      "day": "2016-01-16",
      "value": 118
    },
    {
      "day": "2016-03-14",
      "value": 337
    },
    {
      "day": "2017-08-09",
      "value": 290
    },
    {
      "day": "2015-09-28",
      "value": 371
    },
    {
      "day": "2016-07-09",
      "value": 358
    },
    {
      "day": "2017-06-06",
      "value": 99
    },
    {
      "day": "2015-04-16",
      "value": 66
    },
    {
      "day": "2018-05-31",
      "value": 273
    },
    {
      "day": "2015-04-18",
      "value": 26
    },
    {
      "day": "2016-12-16",
      "value": 395
    },
    {
      "day": "2015-04-22",
      "value": 242
    },
    {
      "day": "2017-01-13",
      "value": 16
    },
    {
      "day": "2016-11-16",
      "value": 362
    },
    {
      "day": "2018-06-20",
      "value": 76
    },
    {
      "day": "2017-07-15",
      "value": 139
    },
    {
      "day": "2016-12-04",
      "value": 204
    },
    {
      "day": "2016-06-03",
      "value": 269
    },
    {
      "day": "2015-06-03",
      "value": 396
    },
    {
      "day": "2015-06-22",
      "value": 269
    },
    {
      "day": "2017-10-09",
      "value": 11
    },
    {
      "day": "2017-07-21",
      "value": 95
    },
    {
      "day": "2016-10-17",
      "value": 283
    },
    {
      "day": "2016-08-27",
      "value": 309
    },
    {
      "day": "2018-07-31",
      "value": 276
    },
    {
      "day": "2018-05-20",
      "value": 359
    },
    {
      "day": "2015-08-17",
      "value": 124
    },
    {
      "day": "2016-04-15",
      "value": 201
    },
    {
      "day": "2016-02-27",
      "value": 281
    },
    {
      "day": "2018-06-29",
      "value": 146
    },
    {
      "day": "2017-07-08",
      "value": 266
    },
    {
      "day": "2017-02-21",
      "value": 295
    },
    {
      "day": "2017-06-24",
      "value": 358
    },
    {
      "day": "2018-03-30",
      "value": 100
    },
    {
      "day": "2018-01-23",
      "value": 313
    },
    {
      "day": "2017-12-19",
      "value": 399
    },
    {
      "day": "2015-04-05",
      "value": 399
    },
    {
      "day": "2015-09-19",
      "value": 325
    },
    {
      "day": "2015-11-17",
      "value": 242
    },
    {
      "day": "2017-05-29",
      "value": 184
    },
    {
      "day": "2016-09-08",
      "value": 204
    },
    {
      "day": "2015-10-20",
      "value": 96
    },
    {
      "day": "2018-05-12",
      "value": 18
    },
    {
      "day": "2017-09-25",
      "value": 4
    },
    {
      "day": "2016-08-03",
      "value": 185
    },
    {
      "day": "2017-04-27",
      "value": 187
    },
    {
      "day": "2018-01-18",
      "value": 236
    },
    {
      "day": "2015-04-03",
      "value": 40
    },
    {
      "day": "2018-03-11",
      "value": 26
    },
    {
      "day": "2017-01-03",
      "value": 75
    },
    {
      "day": "2017-03-11",
      "value": 251
    },
    {
      "day": "2017-12-28",
      "value": 162
    },
    {
      "day": "2018-04-22",
      "value": 153
    },
    {
      "day": "2015-06-06",
      "value": 300
    },
    {
      "day": "2015-11-20",
      "value": 264
    },
    {
      "day": "2015-06-19",
      "value": 71
    },
    {
      "day": "2017-08-20",
      "value": 231
    },
    {
      "day": "2016-03-19",
      "value": 187
    },
    {
      "day": "2016-05-31",
      "value": 188
    },
    {
      "day": "2016-01-19",
      "value": 177
    },
    {
      "day": "2016-07-03",
      "value": 277
    },
    {
      "day": "2017-02-04",
      "value": 74
    },
    {
      "day": "2017-01-30",
      "value": 365
    },
    {
      "day": "2017-02-01",
      "value": 334
    },
    {
      "day": "2017-06-04",
      "value": 208
    },
    {
      "day": "2017-10-19",
      "value": 79
    },
    {
      "day": "2017-02-26",
      "value": 207
    },
    {
      "day": "2016-07-17",
      "value": 174
    },
    {
      "day": "2016-12-22",
      "value": 255
    },
    {
      "day": "2015-05-15",
      "value": 262
    },
    {
      "day": "2015-04-28",
      "value": 175
    },
    {
      "day": "2018-04-29",
      "value": 235
    },
    {
      "day": "2016-04-26",
      "value": 207
    },
    {
      "day": "2016-10-29",
      "value": 30
    },
    {
      "day": "2017-08-26",
      "value": 268
    },
    {
      "day": "2015-05-30",
      "value": 318
    },
    {
      "day": "2018-07-23",
      "value": 295
    },
    {
      "day": "2017-10-26",
      "value": 5
    },
    {
      "day": "2017-12-21",
      "value": 16
    },
    {
      "day": "2018-05-24",
      "value": 389
    },
    {
      "day": "2015-11-15",
      "value": 60
    },
    {
      "day": "2015-09-01",
      "value": 347
    },
    {
      "day": "2015-07-24",
      "value": 29
    },
    {
      "day": "2017-11-18",
      "value": 305
    },
    {
      "day": "2016-06-19",
      "value": 95
    },
    {
      "day": "2015-11-24",
      "value": 234
    },
    {
      "day": "2017-11-23",
      "value": 200
    },
    {
      "day": "2017-01-07",
      "value": 309
    },
    {
      "day": "2018-01-10",
      "value": 100
    },
    {
      "day": "2016-04-27",
      "value": 196
    },
    {
      "day": "2016-11-19",
      "value": 191
    },
    {
      "day": "2015-06-17",
      "value": 180
    },
    {
      "day": "2016-04-14",
      "value": 180
    },
    {
      "day": "2017-12-03",
      "value": 268
    },
    {
      "day": "2017-07-27",
      "value": 343
    },
    {
      "day": "2016-10-22",
      "value": 260
    },
    {
      "day": "2016-06-23",
      "value": 387
    },
    {
      "day": "2018-01-06",
      "value": 89
    },
    {
      "day": "2018-06-27",
      "value": 374
    },
    {
      "day": "2015-11-16",
      "value": 100
    },
    {
      "day": "2017-08-22",
      "value": 83
    },
    {
      "day": "2015-06-23",
      "value": 314
    },
    {
      "day": "2016-07-07",
      "value": 41
    },
    {
      "day": "2017-12-01",
      "value": 305
    },
    {
      "day": "2018-06-02",
      "value": 88
    },
    {
      "day": "2015-07-15",
      "value": 16
    },
    {
      "day": "2016-11-15",
      "value": 197
    },
    {
      "day": "2015-09-10",
      "value": 267
    },
    {
      "day": "2018-07-30",
      "value": 325
    },
    {
      "day": "2016-11-10",
      "value": 349
    },
    {
      "day": "2017-01-14",
      "value": 31
    },
    {
      "day": "2016-05-22",
      "value": 215
    },
    {
      "day": "2016-02-05",
      "value": 112
    },
    {
      "day": "2017-10-23",
      "value": 393
    },
    {
      "day": "2016-04-09",
      "value": 300
    },
    {
      "day": "2015-08-22",
      "value": 313
    },
    {
      "day": "2016-05-20",
      "value": 40
    },
    {
      "day": "2018-04-03",
      "value": 328
    },
    {
      "day": "2017-11-25",
      "value": 113
    },
    {
      "day": "2016-05-04",
      "value": 355
    },
    {
      "day": "2015-07-01",
      "value": 235
    },
    {
      "day": "2016-04-19",
      "value": 95
    },
    {
      "day": "2016-08-24",
      "value": 44
    },
    {
      "day": "2018-03-18",
      "value": 188
    },
    {
      "day": "2018-04-01",
      "value": 177
    },
    {
      "day": "2017-08-14",
      "value": 244
    },
    {
      "day": "2015-07-14",
      "value": 183
    },
    {
      "day": "2016-04-07",
      "value": 86
    },
    {
      "day": "2017-04-18",
      "value": 198
    },
    {
      "day": "2017-06-11",
      "value": 179
    },
    {
      "day": "2015-05-24",
      "value": 171
    },
    {
      "day": "2016-10-03",
      "value": 44
    },
    {
      "day": "2016-06-17",
      "value": 31
    },
    {
      "day": "2016-09-27",
      "value": 320
    },
    {
      "day": "2016-03-25",
      "value": 295
    },
    {
      "day": "2015-05-20",
      "value": 23
    },
    {
      "day": "2016-08-28",
      "value": 95
    },
    {
      "day": "2015-10-27",
      "value": 365
    },
    {
      "day": "2017-03-14",
      "value": 259
    },
    {
      "day": "2016-05-19",
      "value": 24
    },
    {
      "day": "2015-04-08",
      "value": 100
    },
    {
      "day": "2015-10-28",
      "value": 102
    },
    {
      "day": "2018-03-31",
      "value": 340
    },
    {
      "day": "2015-06-15",
      "value": 270
    },
    {
      "day": "2017-12-05",
      "value": 196
    },
    {
      "day": "2017-03-29",
      "value": 70
    },
    {
      "day": "2015-04-11",
      "value": 214
    },
    {
      "day": "2016-09-14",
      "value": 313
    },
    {
      "day": "2016-11-04",
      "value": 94
    },
    {
      "day": "2018-04-19",
      "value": 376
    },
    {
      "day": "2015-07-31",
      "value": 195
    },
    {
      "day": "2015-11-07",
      "value": 304
    },
    {
      "day": "2018-02-26",
      "value": 318
    },
    {
      "day": "2018-01-28",
      "value": 302
    },
    {
      "day": "2018-06-21",
      "value": 357
    },
    {
      "day": "2018-06-13",
      "value": 280
    },
    {
      "day": "2017-09-22",
      "value": 274
    },
    {
      "day": "2016-11-03",
      "value": 121
    },
    {
      "day": "2017-10-27",
      "value": 312
    },
    {
      "day": "2017-01-09",
      "value": 376
    },
    {
      "day": "2016-03-12",
      "value": 380
    },
    {
      "day": "2017-11-05",
      "value": 152
    },
    {
      "day": "2018-05-07",
      "value": 399
    },
    {
      "day": "2018-01-05",
      "value": 31
    },
    {
      "day": "2017-11-22",
      "value": 290
    },
    {
      "day": "2015-10-08",
      "value": 343
    },
    {
      "day": "2016-12-19",
      "value": 123
    },
    {
      "day": "2015-11-21",
      "value": 317
    },
    {
      "day": "2017-01-19",
      "value": 217
    },
    {
      "day": "2015-10-09",
      "value": 61
    },
    {
      "day": "2018-03-22",
      "value": 391
    },
    {
      "day": "2016-10-24",
      "value": 3
    },
    {
      "day": "2016-09-12",
      "value": 71
    },
    {
      "day": "2018-01-16",
      "value": 260
    },
    {
      "day": "2016-09-03",
      "value": 88
    },
    {
      "day": "2016-08-11",
      "value": 60
    },
    {
      "day": "2015-09-16",
      "value": 310
    },
    {
      "day": "2016-04-13",
      "value": 131
    },
    {
      "day": "2015-07-02",
      "value": 183
    },
    {
      "day": "2015-07-27",
      "value": 213
    },
    {
      "day": "2015-04-17",
      "value": 37
    },
    {
      "day": "2017-09-02",
      "value": 123
    },
    {
      "day": "2018-03-24",
      "value": 278
    },
    {
      "day": "2016-10-26",
      "value": 24
    },
    {
      "day": "2018-01-13",
      "value": 236
    },
    {
      "day": "2016-10-31",
      "value": 313
    },
    {
      "day": "2018-02-08",
      "value": 356
    },
    {
      "day": "2016-03-21",
      "value": 123
    },
    {
      "day": "2015-07-12",
      "value": 247
    },
    {
      "day": "2016-02-14",
      "value": 284
    },
    {
      "day": "2016-02-11",
      "value": 312
    },
    {
      "day": "2017-12-06",
      "value": 185
    },
    {
      "day": "2016-07-23",
      "value": 291
    },
    {
      "day": "2017-12-18",
      "value": 226
    },
    {
      "day": "2015-05-22",
      "value": 293
    },
    {
      "day": "2016-11-06",
      "value": 211
    },
    {
      "day": "2015-11-02",
      "value": 106
    },
    {
      "day": "2018-02-10",
      "value": 85
    },
    {
      "day": "2018-04-21",
      "value": 234
    },
    {
      "day": "2015-10-14",
      "value": 226
    },
    {
      "day": "2015-05-12",
      "value": 83
    },
    {
      "day": "2018-07-14",
      "value": 54
    },
    {
      "day": "2015-12-19",
      "value": 358
    },
    {
      "day": "2017-01-28",
      "value": 240
    },
    {
      "day": "2017-07-20",
      "value": 389
    },
    {
      "day": "2016-11-01",
      "value": 240
    },
    {
      "day": "2017-05-07",
      "value": 35
    },
    {
      "day": "2018-07-07",
      "value": 388
    },
    {
      "day": "2015-11-13",
      "value": 237
    },
    {
      "day": "2017-01-25",
      "value": 175
    },
    {
      "day": "2015-10-21",
      "value": 66
    },
    {
      "day": "2017-06-19",
      "value": 167
    },
    {
      "day": "2015-04-23",
      "value": 64
    },
    {
      "day": "2015-10-11",
      "value": 22
    },
    {
      "day": "2015-09-17",
      "value": 41
    },
    {
      "day": "2016-08-26",
      "value": 377
    },
    {
      "day": "2016-06-15",
      "value": 371
    },
    {
      "day": "2017-04-14",
      "value": 139
    },
    {
      "day": "2015-10-18",
      "value": 187
    },
    {
      "day": "2017-02-18",
      "value": 6
    },
    {
      "day": "2016-02-16",
      "value": 206
    },
    {
      "day": "2017-03-15",
      "value": 366
    },
    {
      "day": "2015-07-22",
      "value": 194
    },
    {
      "day": "2018-06-30",
      "value": 201
    },
    {
      "day": "2015-08-29",
      "value": 32
    },
    {
      "day": "2015-05-06",
      "value": 330
    },
    {
      "day": "2017-08-10",
      "value": 240
    },
    {
      "day": "2016-08-18",
      "value": 74
    },
    {
      "day": "2016-11-21",
      "value": 274
    },
    {
      "day": "2015-05-08",
      "value": 332
    },
    {
      "day": "2018-02-15",
      "value": 312
    },
    {
      "day": "2015-07-29",
      "value": 271
    },
    {
      "day": "2018-07-22",
      "value": 339
    },
    {
      "day": "2017-12-08",
      "value": 11
    },
    {
      "day": "2017-06-15",
      "value": 88
    },
    {
      "day": "2015-04-25",
      "value": 285
    },
    {
      "day": "2016-01-24",
      "value": 374
    },
    {
      "day": "2015-07-17",
      "value": 354
    },
    {
      "day": "2017-04-08",
      "value": 136
    },
    {
      "day": "2016-10-28",
      "value": 59
    },
    {
      "day": "2018-04-06",
      "value": 80
    },
    {
      "day": "2017-08-17",
      "value": 73
    },
    {
      "day": "2015-08-12",
      "value": 64
    },
    {
      "day": "2017-04-29",
      "value": 376
    },
    {
      "day": "2015-04-06",
      "value": 374
    },
    {
      "day": "2016-03-02",
      "value": 371
    },
    {
      "day": "2017-12-30",
      "value": 304
    },
    {
      "day": "2017-04-06",
      "value": 196
    },
    {
      "day": "2015-07-20",
      "value": 127
    },
    {
      "day": "2017-03-17",
      "value": 205
    },
    {
      "day": "2018-04-26",
      "value": 360
    },
    {
      "day": "2016-01-03",
      "value": 363
    },
    {
      "day": "2017-09-24",
      "value": 385
    },
    {
      "day": "2018-01-21",
      "value": 357
    },
    {
      "day": "2018-04-18",
      "value": 275
    },
    {
      "day": "2016-09-22",
      "value": 188
    },
    {
      "day": "2017-03-22",
      "value": 323
    },
    {
      "day": "2016-03-30",
      "value": 187
    },
    {
      "day": "2018-04-12",
      "value": 321
    },
    {
      "day": "2018-03-23",
      "value": 255
    },
    {
      "day": "2018-03-17",
      "value": 334
    },
    {
      "day": "2016-10-19",
      "value": 181
    },
    {
      "day": "2016-07-13",
      "value": 241
    },
    {
      "day": "2015-09-14",
      "value": 221
    },
    {
      "day": "2017-07-04",
      "value": 255
    },
    {
      "day": "2018-01-24",
      "value": 265
    },
    {
      "day": "2015-10-01",
      "value": 23
    },
    {
      "day": "2016-05-08",
      "value": 19
    },
    {
      "day": "2016-06-26",
      "value": 377
    },
    {
      "day": "2018-04-16",
      "value": 207
    },
    {
      "day": "2017-06-26",
      "value": 97
    },
    {
      "day": "2016-06-05",
      "value": 94
    },
    {
      "day": "2018-05-29",
      "value": 384
    },
    {
      "day": "2017-01-18",
      "value": 258
    },
    {
      "day": "2016-05-15",
      "value": 243
    },
    {
      "day": "2016-03-31",
      "value": 243
    },
    {
      "day": "2016-01-09",
      "value": 291
    },
    {
      "day": "2015-08-01",
      "value": 261
    },
    {
      "day": "2016-05-14",
      "value": 263
    },
    {
      "day": "2016-03-05",
      "value": 167
    },
    {
      "day": "2017-11-12",
      "value": 340
    },
    {
      "day": "2017-01-08",
      "value": 7
    },
    {
      "day": "2016-04-03",
      "value": 111
    },
    {
      "day": "2015-07-11",
      "value": 265
    },
    {
      "day": "2016-04-25",
      "value": 263
    },
    {
      "day": "2016-04-23",
      "value": 232
    },
    {
      "day": "2016-05-26",
      "value": 290
    },
    {
      "day": "2017-04-19",
      "value": 161
    },
    {
      "day": "2015-07-04",
      "value": 176
    },
    {
      "day": "2018-03-29",
      "value": 287
    },
    {
      "day": "2018-02-19",
      "value": 103
    },
    {
      "day": "2015-08-13",
      "value": 343
    },
    {
      "day": "2018-05-13",
      "value": 211
    },
    {
      "day": "2018-02-11",
      "value": 370
    },
    {
      "day": "2016-11-29",
      "value": 120
    },
    {
      "day": "2015-06-26",
      "value": 255
    },
    {
      "day": "2018-05-17",
      "value": 51
    },
    {
      "day": "2016-01-05",
      "value": 112
    },
    {
      "day": "2017-08-30",
      "value": 323
    },
    {
      "day": "2015-07-18",
      "value": 193
    },
    {
      "day": "2017-04-11",
      "value": 374
    },
    {
      "day": "2018-05-08",
      "value": 46
    },
    {
      "day": "2017-01-11",
      "value": 71
    },
    {
      "day": "2015-07-16",
      "value": 95
    },
    {
      "day": "2017-12-07",
      "value": 325
    },
    {
      "day": "2016-07-25",
      "value": 233
    },
    {
      "day": "2016-12-14",
      "value": 304
    },
    {
      "day": "2017-10-16",
      "value": 160
    },
    {
      "day": "2018-01-07",
      "value": 24
    },
    {
      "day": "2017-04-26",
      "value": 80
    },
    {
      "day": "2016-05-03",
      "value": 94
    },
    {
      "day": "2015-08-11",
      "value": 92
    },
    {
      "day": "2018-04-07",
      "value": 307
    },
    {
      "day": "2017-12-26",
      "value": 160
    },
    {
      "day": "2018-03-12",
      "value": 139
    },
    {
      "day": "2017-06-22",
      "value": 246
    },
    {
      "day": "2016-02-13",
      "value": 30
    },
    {
      "day": "2018-02-28",
      "value": 60
    },
    {
      "day": "2017-06-25",
      "value": 115
    },
    {
      "day": "2018-06-04",
      "value": 22
    },
    {
      "day": "2018-06-01",
      "value": 12
    },
    {
      "day": "2017-10-11",
      "value": 179
    },
    {
      "day": "2018-01-15",
      "value": 259
    },
    {
      "day": "2018-04-05",
      "value": 26
    },
    {
      "day": "2015-09-08",
      "value": 338
    },
    {
      "day": "2016-04-12",
      "value": 304
    },
    {
      "day": "2018-01-03",
      "value": 116
    },
    {
      "day": "2017-04-15",
      "value": 238
    },
    {
      "day": "2017-11-29",
      "value": 273
    },
    {
      "day": "2015-12-30",
      "value": 374
    },
    {
      "day": "2016-07-29",
      "value": 340
    },
    {
      "day": "2015-12-21",
      "value": 202
    },
    {
      "day": "2016-08-07",
      "value": 81
    },
    {
      "day": "2018-05-25",
      "value": 305
    },
    {
      "day": "2016-04-29",
      "value": 215
    },
    {
      "day": "2017-04-04",
      "value": 119
    },
    {
      "day": "2016-07-02",
      "value": 11
    },
    {
      "day": "2018-02-09",
      "value": 277
    },
    {
      "day": "2017-06-17",
      "value": 328
    },
    {
      "day": "2017-03-30",
      "value": 261
    },
    {
      "day": "2018-05-15",
      "value": 156
    },
    {
      "day": "2017-05-26",
      "value": 50
    },
    {
      "day": "2018-02-06",
      "value": 272
    },
    {
      "day": "2015-12-06",
      "value": 132
    },
    {
      "day": "2015-11-04",
      "value": 368
    },
    {
      "day": "2017-09-29",
      "value": 281
    },
    {
      "day": "2018-04-24",
      "value": 307
    },
    {
      "day": "2015-04-30",
      "value": 333
    },
    {
      "day": "2016-03-08",
      "value": 48
    },
    {
      "day": "2015-04-15",
      "value": 363
    },
    {
      "day": "2018-08-06",
      "value": 89
    },
    {
      "day": "2018-01-02",
      "value": 206
    },
    {
      "day": "2017-06-10",
      "value": 79
    },
    {
      "day": "2016-11-09",
      "value": 352
    },
    {
      "day": "2015-06-21",
      "value": 145
    },
    {
      "day": "2017-07-24",
      "value": 64
    },
    {
      "day": "2017-12-04",
      "value": 362
    },
    {
      "day": "2015-06-12",
      "value": 229
    },
    {
      "day": "2017-01-17",
      "value": 197
    },
    {
      "day": "2018-06-11",
      "value": 30
    },
    {
      "day": "2016-08-21",
      "value": 312
    },
    {
      "day": "2016-05-30",
      "value": 317
    },
    {
      "day": "2015-11-08",
      "value": 373
    },
    {
      "day": "2016-08-13",
      "value": 173
    },
    {
      "day": "2015-05-09",
      "value": 87
    },
    {
      "day": "2016-03-29",
      "value": 311
    },
    {
      "day": "2018-07-13",
      "value": 157
    },
    {
      "day": "2016-03-20",
      "value": 149
    },
    {
      "day": "2016-03-26",
      "value": 339
    },
    {
      "day": "2017-10-29",
      "value": 392
    },
    {
      "day": "2017-10-12",
      "value": 372
    },
    {
      "day": "2015-07-23",
      "value": 262
    },
    {
      "day": "2017-10-28",
      "value": 373
    },
    {
      "day": "2016-06-21",
      "value": 42
    },
    {
      "day": "2018-03-25",
      "value": 283
    },
    {
      "day": "2015-08-05",
      "value": 17
    },
    {
      "day": "2017-09-26",
      "value": 245
    },
    {
      "day": "2017-02-12",
      "value": 150
    },
    {
      "day": "2018-01-19",
      "value": 222
    },
    {
      "day": "2016-02-01",
      "value": 293
    },
    {
      "day": "2017-07-13",
      "value": 303
    },
    {
      "day": "2018-07-01",
      "value": 387
    },
    {
      "day": "2016-01-15",
      "value": 283
    },
    {
      "day": "2016-10-10",
      "value": 312
    },
    {
      "day": "2015-11-03",
      "value": 86
    },
    {
      "day": "2018-05-11",
      "value": 70
    },
    {
      "day": "2016-05-01",
      "value": 141
    },
    {
      "day": "2017-04-24",
      "value": 356
    },
    {
      "day": "2015-10-13",
      "value": 93
    },
    {
      "day": "2015-10-15",
      "value": 368
    },
    {
      "day": "2017-12-24",
      "value": 280
    },
    {
      "day": "2017-10-06",
      "value": 44
    },
    {
      "day": "2015-08-23",
      "value": 130
    },
    {
      "day": "2015-10-17",
      "value": 395
    },
    {
      "day": "2016-01-08",
      "value": 176
    },
    {
      "day": "2018-06-17",
      "value": 140
    },
    {
      "day": "2017-04-12",
      "value": 32
    },
    {
      "day": "2016-09-16",
      "value": 384
    },
    {
      "day": "2016-06-25",
      "value": 369
    },
    {
      "day": "2018-07-26",
      "value": 81
    },
    {
      "day": "2015-12-15",
      "value": 224
    },
    {
      "day": "2018-01-22",
      "value": 265
    },
    {
      "day": "2017-02-07",
      "value": 244
    },
    {
      "day": "2016-03-28",
      "value": 263
    },
    {
      "day": "2016-09-06",
      "value": 243
    },
    {
      "day": "2016-09-04",
      "value": 126
    },
    {
      "day": "2017-08-04",
      "value": 393
    },
    {
      "day": "2016-10-18",
      "value": 272
    },
    {
      "day": "2015-11-05",
      "value": 383
    },
    {
      "day": "2016-03-09",
      "value": 22
    },
    {
      "day": "2016-02-18",
      "value": 291
    },
    {
      "day": "2018-01-04",
      "value": 92
    },
    {
      "day": "2016-04-21",
      "value": 204
    },
    {
      "day": "2017-12-02",
      "value": 96
    },
    {
      "day": "2017-10-20",
      "value": 246
    },
    {
      "day": "2018-02-02",
      "value": 14
    },
    {
      "day": "2016-11-28",
      "value": 123
    },
    {
      "day": "2016-03-01",
      "value": 101
    },
    {
      "day": "2017-05-17",
      "value": 229
    },
    {
      "day": "2015-08-15",
      "value": 293
    },
    {
      "day": "2016-03-10",
      "value": 192
    },
    {
      "day": "2017-05-11",
      "value": 210
    },
    {
      "day": "2016-10-30",
      "value": 264
    },
    {
      "day": "2017-04-03",
      "value": 199
    },
    {
      "day": "2017-03-04",
      "value": 328
    },
    {
      "day": "2016-03-27",
      "value": 320
    },
    {
      "day": "2016-07-27",
      "value": 188
    },
    {
      "day": "2018-02-22",
      "value": 63
    },
    {
      "day": "2018-03-19",
      "value": 378
    },
    {
      "day": "2017-09-11",
      "value": 361
    },
    {
      "day": "2015-12-22",
      "value": 221
    },
    {
      "day": "2016-05-13",
      "value": 217
    },
    {
      "day": "2015-08-10",
      "value": 304
    },
    {
      "day": "2015-06-13",
      "value": 310
    },
    {
      "day": "2017-05-02",
      "value": 172
    },
    {
      "day": "2016-06-06",
      "value": 316
    },
    {
      "day": "2016-01-07",
      "value": 199
    },
    {
      "day": "2018-02-23",
      "value": 252
    },
    {
      "day": "2017-07-05",
      "value": 248
    },
    {
      "day": "2015-10-31",
      "value": 215
    },
    {
      "day": "2016-03-06",
      "value": 236
    },
    {
      "day": "2017-05-19",
      "value": 134
    },
    {
      "day": "2018-03-08",
      "value": 174
    },
    {
      "day": "2018-03-16",
      "value": 94
    },
    {
      "day": "2016-09-17",
      "value": 90
    },
    {
      "day": "2018-08-11",
      "value": 179
    },
    {
      "day": "2016-12-03",
      "value": 56
    },
    {
      "day": "2015-09-02",
      "value": 259
    },
    {
      "day": "2015-11-12",
      "value": 260
    },
    {
      "day": "2017-02-08",
      "value": 266
    },
    {
      "day": "2018-05-19",
      "value": 136
    },
    {
      "day": "2017-09-01",
      "value": 170
    },
    {
      "day": "2015-04-02",
      "value": 400
    },
    {
      "day": "2015-11-09",
      "value": 70
    },
    {
      "day": "2018-07-28",
      "value": 57
    },
    {
      "day": "2017-07-19",
      "value": 81
    },
    {
      "day": "2015-10-16",
      "value": 378
    },
    {
      "day": "2018-04-13",
      "value": 300
    },
    {
      "day": "2018-08-04",
      "value": 233
    },
    {
      "day": "2017-11-24",
      "value": 104
    },
    {
      "day": "2018-06-16",
      "value": 279
    },
    {
      "day": "2017-06-07",
      "value": 143
    },
    {
      "day": "2018-01-11",
      "value": 252
    },
    {
      "day": "2015-06-24",
      "value": 67
    },
    {
      "day": "2015-08-26",
      "value": 345
    },
    {
      "day": "2018-04-27",
      "value": 214
    },
    {
      "day": "2016-02-04",
      "value": 183
    },
    {
      "day": "2017-08-02",
      "value": 138
    },
    {
      "day": "2016-05-27",
      "value": 306
    },
    {
      "day": "2016-12-31",
      "value": 211
    },
    {
      "day": "2016-09-13",
      "value": 242
    },
    {
      "day": "2016-07-28",
      "value": 237
    },
    {
      "day": "2018-07-19",
      "value": 359
    },
    {
      "day": "2015-11-23",
      "value": 315
    },
    {
      "day": "2015-12-14",
      "value": 155
    },
    {
      "day": "2016-09-30",
      "value": 38
    },
    {
      "day": "2016-03-23",
      "value": 340
    },
    {
      "day": "2015-12-18",
      "value": 127
    },
    {
      "day": "2017-12-23",
      "value": 205
    },
    {
      "day": "2016-07-08",
      "value": 137
    },
    {
      "day": "2016-03-17",
      "value": 1
    },
    {
      "day": "2016-07-22",
      "value": 266
    },
    {
      "day": "2015-07-03",
      "value": 118
    },
    {
      "day": "2017-12-22",
      "value": 217
    },
    {
      "day": "2017-04-05",
      "value": 303
    },
    {
      "day": "2016-04-08",
      "value": 265
    },
    {
      "day": "2016-09-24",
      "value": 61
    },
    {
      "day": "2017-02-25",
      "value": 289
    },
    {
      "day": "2018-02-12",
      "value": 273
    },
    {
      "day": "2017-02-02",
      "value": 290
    },
    {
      "day": "2017-05-23",
      "value": 298
    },
    {
      "day": "2016-12-21",
      "value": 350
    },
    {
      "day": "2015-06-27",
      "value": 319
    },
    {
      "day": "2015-07-19",
      "value": 363
    },
    {
      "day": "2016-08-31",
      "value": 73
    },
    {
      "day": "2015-09-30",
      "value": 165
    },
    {
      "day": "2015-09-07",
      "value": 15
    },
    {
      "day": "2017-05-20",
      "value": 239
    },
    {
      "day": "2017-08-28",
      "value": 296
    },
    {
      "day": "2018-07-03",
      "value": 181
    },
    {
      "day": "2015-06-11",
      "value": 44
    },
    {
      "day": "2016-12-28",
      "value": 378
    },
    {
      "day": "2017-05-15",
      "value": 340
    },
    {
      "day": "2018-07-18",
      "value": 278
    },
    {
      "day": "2015-10-26",
      "value": 360
    },
    {
      "day": "2018-03-09",
      "value": 96
    },
    {
      "day": "2016-01-01",
      "value": 58
    },
    {
      "day": "2015-07-30",
      "value": 306
    },
    {
      "day": "2015-11-06",
      "value": 384
    },
    {
      "day": "2017-05-21",
      "value": 166
    },
    {
      "day": "2017-01-12",
      "value": 263
    },
    {
      "day": "2017-12-09",
      "value": 278
    },
    {
      "day": "2017-03-08",
      "value": 222
    },
    {
      "day": "2017-12-11",
      "value": 188
    },
    {
      "day": "2017-09-10",
      "value": 384
    },
    {
      "day": "2016-10-16",
      "value": 332
    },
    {
      "day": "2017-04-21",
      "value": 350
    },
    {
      "day": "2015-08-16",
      "value": 234
    },
    {
      "day": "2018-03-01",
      "value": 359
    },
    {
      "day": "2016-06-27",
      "value": 328
    },
    {
      "day": "2016-02-10",
      "value": 206
    },
    {
      "day": "2017-04-01",
      "value": 116
    },
    {
      "day": "2018-01-12",
      "value": 286
    },
    {
      "day": "2017-01-24",
      "value": 73
    },
    {
      "day": "2017-05-06",
      "value": 77
    },
    {
      "day": "2016-12-23",
      "value": 180
    },
    {
      "day": "2016-08-20",
      "value": 179
    },
    {
      "day": "2017-05-14",
      "value": 201
    },
    {
      "day": "2017-04-10",
      "value": 170
    },
    {
      "day": "2016-02-24",
      "value": 337
    },
    {
      "day": "2015-08-04",
      "value": 146
    },
    {
      "day": "2015-12-12",
      "value": 350
    },
    {
      "day": "2017-01-29",
      "value": 270
    },
    {
      "day": "2016-08-25",
      "value": 82
    },
    {
      "day": "2017-01-21",
      "value": 136
    },
    {
      "day": "2018-07-25",
      "value": 50
    },
    {
      "day": "2017-07-29",
      "value": 55
    },
    {
      "day": "2018-03-06",
      "value": 172
    },
    {
      "day": "2015-06-14",
      "value": 169
    },
    {
      "day": "2018-02-20",
      "value": 66
    },
    {
      "day": "2016-12-07",
      "value": 136
    },
    {
      "day": "2015-05-19",
      "value": 301
    },
    {
      "day": "2016-02-19",
      "value": 187
    },
    {
      "day": "2016-05-21",
      "value": 158
    },
    {
      "day": "2017-08-13",
      "value": 92
    },
    {
      "day": "2017-09-14",
      "value": 163
    },
    {
      "day": "2017-07-01",
      "value": 9
    },
    {
      "day": "2016-12-29",
      "value": 200
    },
    {
      "day": "2016-09-05",
      "value": 319
    },
    {
      "day": "2018-06-12",
      "value": 370
    },
    {
      "day": "2015-12-09",
      "value": 84
    },
    {
      "day": "2017-01-10",
      "value": 356
    },
    {
      "day": "2018-02-13",
      "value": 166
    },
    {
      "day": "2018-02-18",
      "value": 8
    },
    {
      "day": "2017-02-09",
      "value": 304
    },
    {
      "day": "2015-12-07",
      "value": 370
    },
    {
      "day": "2015-05-29",
      "value": 185
    },
    {
      "day": "2015-04-13",
      "value": 324
    },
    {
      "day": "2016-07-19",
      "value": 234
    },
    {
      "day": "2017-09-21",
      "value": 242
    },
    {
      "day": "2016-06-24",
      "value": 176
    },
    {
      "day": "2016-06-01",
      "value": 195
    },
    {
      "day": "2016-03-11",
      "value": 126
    },
    {
      "day": "2016-09-20",
      "value": 281
    },
    {
      "day": "2017-04-28",
      "value": 299
    },
    {
      "day": "2016-09-23",
      "value": 187
    },
    {
      "day": "2016-04-02",
      "value": 24
    },
    {
      "day": "2015-08-08",
      "value": 180
    },
    {
      "day": "2018-06-19",
      "value": 344
    },
    {
      "day": "2016-05-12",
      "value": 162
    },
    {
      "day": "2017-05-28",
      "value": 67
    },
    {
      "day": "2017-05-30",
      "value": 198
    },
    {
      "day": "2016-12-26",
      "value": 222
    },
    {
      "day": "2016-06-18",
      "value": 358
    },
    {
      "day": "2017-03-03",
      "value": 90
    },
    {
      "day": "2016-07-20",
      "value": 346
    },
    {
      "day": "2015-09-05",
      "value": 387
    },
    {
      "day": "2017-10-03",
      "value": 118
    },
    {
      "day": "2015-11-25",
      "value": 172
    },
    {
      "day": "2017-05-16",
      "value": 292
    },
    {
      "day": "2016-11-18",
      "value": 242
    },
    {
      "day": "2016-07-10",
      "value": 269
    },
    {
      "day": "2016-06-11",
      "value": 51
    },
    {
      "day": "2015-09-04",
      "value": 44
    },
    {
      "day": "2017-01-31",
      "value": 182
    },
    {
      "day": "2018-04-17",
      "value": 53
    },
    {
      "day": "2017-09-16",
      "value": 283
    },
    {
      "day": "2015-09-12",
      "value": 297
    },
    {
      "day": "2017-08-24",
      "value": 281
    },
    {
      "day": "2018-04-30",
      "value": 115
    },
    {
      "day": "2018-05-01",
      "value": 189
    },
    {
      "day": "2017-07-11",
      "value": 202
    },
    {
      "day": "2016-11-14",
      "value": 86
    },
    {
      "day": "2017-03-24",
      "value": 235
    },
    {
      "day": "2017-07-07",
      "value": 206
    },
    {
      "day": "2015-09-26",
      "value": 203
    },
    {
      "day": "2016-07-24",
      "value": 396
    },
    {
      "day": "2016-07-18",
      "value": 187
    },
    {
      "day": "2017-12-15",
      "value": 137
    },
    {
      "day": "2016-01-17",
      "value": 399
    },
    {
      "day": "2015-07-25",
      "value": 177
    },
    {
      "day": "2017-06-28",
      "value": 383
    },
    {
      "day": "2015-05-14",
      "value": 325
    },
    {
      "day": "2017-03-01",
      "value": 198
    },
    {
      "day": "2015-12-03",
      "value": 33
    },
    {
      "day": "2016-11-07",
      "value": 364
    },
    {
      "day": "2016-12-18",
      "value": 54
    },
    {
      "day": "2016-06-08",
      "value": 378
    },
    {
      "day": "2016-02-15",
      "value": 305
    },
    {
      "day": "2017-08-08",
      "value": 176
    },
    {
      "day": "2015-07-05",
      "value": 315
    },
    {
      "day": "2016-05-25",
      "value": 328
    },
    {
      "day": "2016-06-02",
      "value": 270
    },
    {
      "day": "2015-12-16",
      "value": 191
    },
    {
      "day": "2018-03-20",
      "value": 206
    },
    {
      "day": "2015-04-24",
      "value": 137
    }
  ];

  const barTheme = {
    themeDark: {
    fontSize: '14px',
    textColor: '#fefefe',
        tooltip:{
            container: {background: "#212121", color: "#fefefe"}
        },      
    },
    themeLight: {
        fontSize: '14px',
        textColor: '#757575',
        tooltip:{
            container: {background: "white", color: "#757575"}
        }         
    },    
};

const gridTheme = {
    themeDark: {
        fontSize: '14px',
        textColor: '#fefefe',
            tooltip:{
                container: {background: "#212121", color: "#fefefe"}
            },  
            grid:{ 
               line:{
                   stroke: "#333", 
                   strokeWidth: 1
                }  
            }          
        },
        themeLight: {
            fontSize: '14px',
            textColor: '#757575',
            tooltip:{
                container: {background: "white", color: "#757575"}
            }    
    },        
}
export default class Dashboard extends Component {

  state = {
    dashboardData : {},
    qaData:[],
    volumeData:{},
    volumeData2:[]
  }

  host = Variable.host;

  getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
  } 

    componentDidMount(){
      // axios.get(this.host+'/ticket/dashboard/'+this.getCookie("auth"), {params:{_token:this.getCookie("auth")}},{withCredentials: true})
      // .then(res => {    
      //   this.setState({dashboardData:res.data})
      // } 
      // )
      // .catch(err=>{
      //   alert("Dashboard loading failed: "+err);
      // })     

      axios.get(this.host+'/api/call/dashboard4', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
      .then(res => {   
        let arr = [];
        for(let i = 0; i < res.data.length;i++){
          let found = false;
          for(let k = 0; k < arr.length;k++){
            if(arr[k].id === res.data[i].username){
              found = true;
              arr[k].data.push({y:res.data[i].total,x:res.data[i].hour})
            }
          }
          if(found === false){
            arr.push({
              "id":res.data[i].username,
              "data":[{y:res.data[i].total,x:res.data[i].hour}]
            })
          }
        } 
        this.setState({qaData:arr})
      } 
      )
      .catch(err=>{
        alert("Dashboard loading failed: "+err);
      })     

      axios.get(this.host+'/api/call/dashboard3', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
      .then(res => {   
        this.setState({volumeData:res.data});
        let arr = [];
        Object.keys(res.data.data).map((key,i)=>{
          let temp = {};
          temp["date"] = key;
          Object.keys(res.data.data[key]).map((key2,i2)=>{
            temp[key2] = res.data.data[key][key2]["total"];
          })
          arr.push(temp);
        })
        this.setState({volumeData2:arr})
        console.log(arr);
      } 
      )
      .catch(err=>{
        alert("Dashboard loading failed: "+err);
      })  
    }

    getTicketPercentage = (type,status) => {
      return 0;
      let total = 0;
      Object.keys(this.state.dashboardData[type]).map((key)=>{
        // console.log(key)
        if(this.state.dashboardData[type][key].total > total){
          total = this.state.dashboardData[type][key].total;
        }
        // console.log(this.state.dashboardData[type][key].total);
      })
      // console.log(0/0);
      return this.state.dashboardData[type][status] ? (this.state.dashboardData[type][status].total === 0 ? 0 : (this.state.dashboardData[type][status].total / total * 100)) : 0;
    }

    getMax = (type) => {
      return 0;
      let total = 0;
      Object.keys(this.state.dashboardData[type]).map((key)=>{
        // console.log(key)
        if(this.state.dashboardData[type][key].total > total){
          total = this.state.dashboardData[type][key].total;
        }
        // console.log(this.state.dashboardData[type][key].total);
      })
      // console.log(0/0);
      return total;
    }
  

    render() {

        return (
            <div className="dashboard-bg overflow-x-hidden" style={{padding:"20px",overflow:"auto",width:"100%"}}>                
                {/* <div className="padding-left-2x padding-right-2x padding-bottom-2x padding-top-2x margin-bottom-2x">

                        <h5 className="bold-text-2 {{color}} emphasize-text" style={{fontSize: "35px"}}>Hi, {this.props.name}</h5>
                        <h6 className={(this.props.theme ? "" : "dark")+" pill"} style={{textTransform : "capitalize"}}>{this.props.plan === 2 ? "Novice" : (this.props.plan === 3 ? "Pro" : (this.props.plan === 4 ? "Expert" : (this.props.plan === 1 ? "Basic" : "Trial")))} Plan</h6>                        
                </div> */}
                {/* <div className="divider margin-bottom-1x"></div> */}

                {(
                  1 ? 
                <div className="row" style={{height:"100%"}}>
                  <div className="col s12 m12 l3">
                    <div className="row">                  
                        <div class={"col s12 card new-shadow "+(this.props.theme ? "grey darken-3" : "")} style={{borderRadius:"15px"}}>
                          <div class="card-content row">
                             <h4 className="bold-text-2">Hi, {this.props.name}</h4>
                             {/* <h5 className="small-font">Click to get started!</h5> */}
                             <h6 className={(this.props.theme ? "" : "dark")+" pill"} style={{textTransform : "capitalize"}}>{this.props.plan === 2 ? "Novice" : (this.props.plan === 3 ? "Pro" : (this.props.plan === 4 ? "Expert" : (this.props.plan === 1 ? "Basic" : "Trial")))} Plan</h6>                        
                            </div>
                          </div>
                          <div class={"col s12 card new-shadow "+(this.props.theme ? "grey darken-3" : "")} style={{borderRadius:"15px"}}>
                          <div class="card-content row">
                             <h5 className="bold-text-2">Latest Updates</h5>
                             <h6>Nothing to see.</h6>
                             {/* <h5 className="small-font">Click to get started!</h5> */}
                            </div>
                          </div>                          
                    </div>
                  </div>
                  {(this.props.userType !== "s" ?
                    <div className="col s12 m12 l9">
              
                      <div className="row">  
                                        
                        <div class={"card new-shadow "+(this.props.theme ? "grey darken-3" : "")} style={{borderRadius:"15px"}}>
                          <div class="card-content row">
                              <h4 className="bold-text-2 margin-left-1x">Today's Summary</h4>

                              <div class="row">
                                <div class="col s12 m12">
                                  <DashboardChart/>
                                </div>
                              <div class="hide col s12 m12 l4">
                                  {/* <h4 className="bold-text-2 margin-left-1x">Today's Summary</h4> */}
                                  <div className="dashboard-ticket-summary-container">                    
                                    <h5 className="dashbord-section-title">Calls</h5>
                                    <div className="dashboard-chart" data-value={this.getMax("ticket")}>
                                      {/* {(
                                      Object.keys(this.state.dashboardData.ticket).map((key,i) =>( */}
                                        <Tippy content={
                                          <div className="tippy-children-content">
                                            <span className="dashboard-bar-title">Calls</span>
                                            <br/>
                                            <span>Total: 0</span>
                                            <br/>
                                            <span>Change from Yesterday: 0</span>
                                          </div>
                                        } placement="right">
                              
                                          <div className="full-width full-height dashboard-bar-holder">
                                          <div className={("dashboard-bar")} style={{backgroundColor:"#000000",height:0+ '%'}}>                                          
                                          </div>
                                          </div>
                                    
                                        
                                      </Tippy>
                                      {/* )
                                      ))} */}
                                      {/* <div className="dashboard-bar-wrapper">
                                        <div className="dashboard-bar-2">

                                        </div>
                                      </div> */}
                                    </div>
                                    
                                  </div>
                                        
                            
                                </div>
                                <div className='hide col s12 m12 l12' style={{height:'300px'}}>
                                <h6>QA Analysis</h6>
                                <ResponsiveScatterPlot
                                  data={this.state.qaData}
                                  margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
                                  xScale={{ type: 'linear', min: 0, max: 'auto' }}
                                  xFormat=">-.2f"
                                  yScale={{ type: 'linear', min: 0, max: 'auto' }}
                                  yFormat=">-.2f"
                                  blendMode="multiply"
                                  axisTop={null}
                                  axisRight={null}
                                  axisBottom={{
                                      orient: 'bottom',
                                      tickSize: 5,
                                      tickPadding: 5,
                                      tickRotation: 0,
                                      legend: 'Time',
                                      legendPosition: 'middle',
                                      legendOffset: 46,
                                      format: e => Math.floor(e) === e && e
                                  }}
                                  axisLeft={{
                                      orient: 'left',
                                      tickSize: 5,
                                      tickPadding: 5,
                                      tickRotation: 0,
                                      legend: 'Score',
                                      legendPosition: 'middle',
                                      legendOffset: -60,
                                      format: e => Math.floor(e) === e && e
                                  }}
                                  legends={[
                                      {
                                          anchor: 'bottom-right',
                                          direction: 'column',
                                          justify: false,
                                          translateX: 130,
                                          translateY: 0,
                                          itemWidth: 100,
                                          itemHeight: 12,
                                          itemsSpacing: 5,
                                          itemDirection: 'left-to-right',
                                          symbolSize: 12,
                                          symbolShape: 'circle',
                                          effects: [
                                              {
                                                  on: 'hover',
                                                  style: {
                                                      itemOpacity: 1
                                                  }
                                              }
                                          ]
                                      }
                                  ]}
                              />
                              </div>
                              <div className='hide col s12 m12 l12' style={{height:'300px'}}>
                                  <div className="full-width-in-mobile-2" style={{height:"100%",width:"100%"}}>

                                  <ResponsiveBar
                                    data={this.state.volumeData2}
                                    // this.state.volumeData.data ? Object.keys(this.state.volumeData.data) : []
                                    keys={this.state.volumeData.username}
                                    indexBy="date"
                                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                    padding={0.3}
                                    groupMode="grouped"
                                    valueScale={{ type: 'linear' }}
                                    indexScale={{ type: 'band', round: true }}
                                    colors={{ scheme: 'nivo' }}
                                    defs={[
                                        {
                                            id: 'dots',
                                            type: 'patternDots',
                                            background: 'inherit',
                                            color: '#38bcb2',
                                            size: 4,
                                            padding: 1,
                                            stagger: true
                                        },
                                        {
                                            id: 'lines',
                                            type: 'patternLines',
                                            background: 'inherit',
                                            color: '#eed312',
                                            rotation: -45,
                                            lineWidth: 6,
                                            spacing: 10
                                        }
                                    ]}
                                    fill={[
                                        {
                                            match: {
                                                id: 'fries'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'sandwich'
                                            },
                                            id: 'lines'
                                        }
                                    ]}
                                    borderColor={{
                                        from: 'color',
                                        modifiers: [
                                            [
                                                'darker',
                                                1.6
                                            ]
                                        ]
                                    }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: 'Date',
                                        legendPosition: 'middle',
                                        legendOffset: 32
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: 'Quantity',
                                        legendPosition: 'middle',
                                        legendOffset: -40
                                    }}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    labelTextColor={{
                                        from: 'color',
                                        modifiers: [
                                            [
                                                'darker',
                                                1.6
                                            ]
                                        ]
                                    }}
                                    legends={[
                                        {
                                            dataFrom: 'keys',
                                            anchor: 'bottom-right',
                                            direction: 'column',
                                            justify: false,
                                            translateX: 120,
                                            translateY: 0,
                                            itemsSpacing: 2,
                                            itemWidth: 100,
                                            itemHeight: 20,
                                            itemDirection: 'left-to-right',
                                            itemOpacity: 0.85,
                                            symbolSize: 20,
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemOpacity: 1
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                    role="application"
                                    ariaLabel="Nivo bar chart demo"
                                    barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
                                />

                                  </div>
                                
                                    <div className="full-width-in-mobile-2 hide" style={{height:"100%",width:"100%"}}>
                                    <ResponsiveBump
                                    data={data}
                                    margin={{ top: 40, right: 100, bottom: 40, left: 60 }}
                                    // colors={{ scheme: 'spectral' }}
                                    lineWidth={3}
                                    activeLineWidth={6}
                                    inactiveLineWidth={3}
                                    inactiveOpacity={0.15}
                                    pointSize={10}
                                    activePointSize={16}
                                    inactivePointSize={0}
                                    pointColor={{ theme: 'background' }}
                                    pointBorderWidth={3}
                                    activePointBorderWidth={3}
                                    pointBorderColor={{ from: 'serie.color' }}
                                    axisTop={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: -36
                                    }}
                                    axisRight={null}
                                    axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: 32
                                    }}
                                    axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'ranking',
                                    legendPosition: 'middle',
                                    legendOffset: -40
                                    }}
                                    theme={(this.props.theme ? barTheme.themeDark : barTheme.themeLight)}
                                    />  
                                    </div>  
                                    <div className="full-width-in-mobile-2 hide" style={{height:"50%",width:"100%"}}>
                                    {/* <h5 className="margin-left-1x bold-text">Tickets Creation Graph</h5> */}
                                    <ResponsiveCalendar
                                    data={data2}
                                    from="2015-03-01"
                                    to="2016-07-12"
                                    emptyColor={(this.props.theme ? "#212121":"#ffffff")}
                                    colors={[ '#61cdbb', '#97e3d5', '#e8c1a0', '#f47560' ]}
                                    margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                                    yearSpacing={40}
                                    monthBorderColor={(this.props.theme ? "#212121":"#ffffff")}
                                    dayBorderWidth={2}
                                    dayBorderColor={(this.props.theme ? "#212121":"#ffffff")}
                                    legends={[
                                    {
                                        anchor: 'bottom-right',
                                        direction: 'row',
                                        translateY: 36,
                                        itemCount: 4,
                                        itemWidth: 42,
                                        itemHeight: 36,
                                        itemsSpacing: 14,
                                        itemDirection: 'right-to-left',
                                        itemTextColor:(this.props.theme ? "#fefefe":"#757575")
                                    }
                                    ]}
                                    theme={(this.props.theme ? gridTheme.themeDark : gridTheme.themeLight)}
                                    />                        
                                    </div> 

                                    </div>
                              </div>
                               
                                <div>
                                    <div className='margin-bottom-2x flex-row customer-score-container'>
                                        <h6 className='bold-text score-text'>CSAT <span className={"label emotion-count score-label "+(this.state.csat > 0 ? "green-text" : (this.state.csat < 0 ? "red-text" : ""))} >{this.state.csat ?? 0}</span></h6>
                                        <h6 className='bold-text score-text'>NPS <span className={"label emotion-count score-label "+(this.state.nps > 0 ? "green-text" : (this.state.nps < 0 ? "red-text" : ""))}>{this.state.nps ?? 0}</span></h6>
                                        <h6 className='bold-text score-text'>CES <span className={"label emotion-count score-label "+(this.state.ces > 0 ? "green-text" : (this.state.ces < 0 ? "red-text" : ""))}>{this.state.ces ?? 0}</span></h6>
                                        <h6 className='bold-text score-text'>VES <span className={"label emotion-count score-label "+(this.state.ves > 0 ? "green-text" : (this.state.ves < 0 ? "red-text" : ""))}>{this.state.ves ?? 0}</span></h6>
                                    </div>    
                                </div>
                              <div className="col s12 m6 limit-width-500">                    
                                <h5 className="bold-text emphasize-text smaller-font">Emotions</h5>
                                <div className="div-table">
                                <Link className="hover-link" to={"/call"}>
                                
                                <div className="dashboard-item-padding">
                                  <h6 className="bold-text">Angry</h6>
                                  <h5 className="summary-number">0</h5>
                                  {(
                                    this.state.dashboardData.ticket !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.ticket.created.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                      {(
                                      this.state.dashboardData.ticket !== undefined ?                          
                                      <React.Fragment>
                                        {(
                                          this.state.dashboardData.ticket.created.rate > 0 ?
                                      <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                        :
                                        (
                                          this.state.dashboardData.ticket.created.rate < 0 ?
                                          <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                          :
                                          <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                        )
                                        )
                                        }
                                        <h6 className={"inline-block change-percent "+(this.state.dashboardData.ticket.created.rate > 0 ? "red-text" : (this.state.dashboardData.ticket.created.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.ticket.created.rate * 100).toFixed(2)}%</h6>
                                      </React.Fragment>
                                      :
                                      null
                                      )}
                                    </div>
                                  </div>
                                </div>
                              
                                </Link>
                                <Link className="hover-link" to={"/call"}>
                                
                                <div className="dashboard-item-padding">
                                  <h6>Happy</h6>
                                  <h5 className="summary-number">0</h5>
                                  {(
                                    this.state.dashboardData.ticket !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.ticket.unassigned.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                      {(
                                        this.state.dashboardData.ticket !== undefined ?                          
                                        <React.Fragment>
                                          {(
                                            this.state.dashboardData.ticket.unassigned.rate > 0 ?
                                        <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                          :
                                          (
                                            this.state.dashboardData.ticket.unassigned.rate < 0 ?
                                            <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                            :
                                            <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                          )
                                          )
                                          }
                                          <h6 className={"inline-block change-percent "+(this.state.dashboardData.ticket.unassigned.rate > 0 ? "red-text" : (this.state.dashboardData.ticket.unassigned.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.ticket.unassigned.rate * 100).toFixed(2)}%</h6>
                                        </React.Fragment>
                                        :
                                        null
                                        )}
                                      </div>
                                  </div>                        
                                </div>
                              
                                </Link>
                                <Link className="hover-link" to={"/call"}>
                                
                                <div className="dashboard-item-padding">
                                  <h6>Sad</h6>
                                  <h5 className="summary-number">0</h5>
                                  {(
                                    this.state.dashboardData.ticket !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.ticket.open.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                      {(
                                        this.state.dashboardData.ticket !== undefined ?                          
                                        <React.Fragment>
                                          {(
                                            this.state.dashboardData.ticket.open.rate > 0 ?
                                        <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                          :
                                          (
                                            this.state.dashboardData.ticket.open.rate < 0 ?
                                            <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                            :
                                            <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                          )
                                          )
                                          }
                                          <h6 className={"inline-block change-percent "+(this.state.dashboardData.ticket.open.rate > 0 ? "red-text" : (this.state.dashboardData.ticket.open.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.ticket.open.rate * 100).toFixed(2)}%</h6>
                                        </React.Fragment>
                                        :
                                        null
                                        )}
                                      </div>
                                  </div>                        
                                </div>
                              
                                </Link>
                                <Link className="hover-link" to={"/call"}>
                                
                                <div className="dashboard-item-padding">
                                  <h6>Concerned</h6>
                                  <h5 className="summary-number">0</h5>
                                  {(
                                    this.state.dashboardData.ticket !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.ticket.solved.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                      {(
                                      this.state.dashboardData.ticket !== undefined ?                          
                                      <React.Fragment>
                                        {(
                                          this.state.dashboardData.ticket.solved.rate > 0 ?
                                      <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                        :
                                        (
                                          this.state.dashboardData.ticket.solved.rate < 0 ?
                                          <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                          :
                                          <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                        )
                                        )
                                        }
                                        <h6 className={"inline-block change-percent "+(this.state.dashboardData.ticket.solved.rate > 0 ? "red-text" : (this.state.dashboardData.ticket.solved.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.ticket.solved.rate * 100).toFixed(2)}%</h6>
                                      </React.Fragment>
                                      :
                                      null
                                      )}
                                    </div>
                                  </div>                        
                                </div>
                              
                                </Link>
                                
                                <div className="dashboard-item-padding">
                                  <h6>Surprised</h6>
                                  <h5 className="summary-number">0</h5>
                                  {(
                                    this.state.dashboardData.ticket !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.ticket.escalated.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                        {(
                                          this.state.dashboardData.ticket !== undefined ?                          
                                          <React.Fragment>
                                            {(
                                              this.state.dashboardData.ticket.escalated.rate > 0 ?
                                          <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                            :
                                            (
                                              this.state.dashboardData.ticket.escalated.rate < 0 ?
                                              <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                              :
                                              <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                            )
                                            )
                                            }
                                            <h6 className={"inline-block change-percent "+(this.state.dashboardData.ticket.escalated.rate > 0 ? "red-text" : (this.state.dashboardData.ticket.escalated.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.ticket.escalated.rate * 100).toFixed(2)}%</h6>
                                          </React.Fragment>
                                          :
                                          null
                                          )}
                                    </div>
                                  </div>                        
                                </div>

                                <div className="dashboard-item-padding">
                                  <h6>Disappointed</h6>
                                  <h5 className="summary-number">0</h5>
                                  {(
                                    this.state.dashboardData.ticket !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.ticket.violated.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                        {(
                                          this.state.dashboardData.ticket !== undefined ?                          
                                          <React.Fragment>
                                            {(
                                              this.state.dashboardData.ticket.violated.rate > 0 ?
                                          <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                            :
                                            (
                                              this.state.dashboardData.ticket.violated.rate < 0 ?
                                              <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                              :
                                              <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                            )
                                            )
                                            }
                                            <h6 className={"inline-block change-percent "+(this.state.dashboardData.ticket.violated.rate > 0 ? "red-text" : (this.state.dashboardData.ticket.violated.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.ticket.violated.rate * 100).toFixed(2)}%</h6>
                                          </React.Fragment>
                                          :
                                          null
                                          )}
                                    </div>
                                  </div>                        
                                </div>                                
                              

                                {/* <h5 className="bold-text">Response Time</h5>
                                <div className="hover-tint-none rounded-corner-10 ticket-summary-block margin-right-2x">
                                  <div className="dashboard-item-padding">
                                    <h6>Average Response Time</h6>
                                    <h5 className="summary-number">0</h5>
                                    <h6>ms</h6>
                                    <div className="inline-block">
                                      <ArrowDropUp className="green-text left"/>
                                      <h6 className="green-text inline-block change-percent">0%</h6>
                                    </div>                         
                                  </div>
                                </div>    
                                <div className="hover-tint-none rounded-corner-10 ticket-summary-block margin-right-2x">
                                  <div className="dashboard-item-padding">
                                    <h6>Fastest Response Time</h6>
                                    <h5 className="summary-number">0</h5>
                                    <h6>ms</h6>
                                    <div className="inline-block">
                                      <ArrowDropUp className="green-text left"/>
                                      <h6 className="green-text inline-block change-percent">0%</h6>
                                    </div>                         
                                  </div>
                                </div> 
                                <div className="hover-tint-none rounded-corner-10 ticket-summary-block margin-right-2x">
                                  <div className="dashboard-item-padding">
                                    <h6>Slowest Response Time</h6>
                                    <h5 className="summary-number">0</h5>
                                    <h6>ms</h6>
                                    <div className="inline-block mid-text">
                                      <ArrowDropUp className="green-text left"/>
                                      <h6 className="green-text inline-block change-percent">0%</h6>
                                    </div>                         
                                  </div>
                                </div>  */}
                                </div>
                              </div>
                              <div className="col s12 m6">
                                
                                <h5 className="bold-text emphasize-text smaller-font">Context</h5>
                                <div className="div-table">
                                <Link className="hover-link" to={"/call"}>
                                
                                <div className="dashboard-item-padding">
                                  <h6>Churn Predictor</h6>
                                  <h5 className="summary-number">0</h5>
                                  {(
                                    this.state.dashboardData.livechat !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.livechat.created.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">                                    
                                      {(
                                      this.state.dashboardData.livechat !== undefined ?                          
                                      <React.Fragment>
                                        {(
                                          this.state.dashboardData.livechat.created.rate > 0 ?
                                      <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                        :
                                        (
                                          this.state.dashboardData.livechat.created.rate < 0 ?
                                          <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                          :
                                          <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                        )
                                        )
                                        }
                                        <h6 className={"inline-block change-percent "+(this.state.dashboardData.livechat.created.rate > 0 ? "red-text" : (this.state.dashboardData.livechat.created.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.livechat.created.rate * 100).toFixed(2)}%</h6>
                                      </React.Fragment>
                                      :
                                      null
                                      )}
                                    </div>
                                  </div>
                                </div>
                              
                                </Link>
                                <Link className="hover-link" to={"/call"}>
                                
                                <div className="dashboard-item-padding">
                                  <h6>Buy Signal</h6>
                                  <h5 className="summary-number">0</h5>
                                  {(
                                    this.state.dashboardData.livechat !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.livechat.unassigned.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                      {(
                                        this.state.dashboardData.livechat !== undefined ?                          
                                        <React.Fragment>
                                          {(
                                            this.state.dashboardData.livechat.unassigned.rate > 0 ?
                                        <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                          :
                                          (
                                            this.state.dashboardData.livechat.unassigned.rate < 0 ?
                                            <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                            :
                                            <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                          )
                                          )
                                          }
                                          <h6 className={"inline-block change-percent "+(this.state.dashboardData.livechat.unassigned.rate > 0 ? "red-text" : (this.state.dashboardData.livechat.unassigned.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.livechat.unassigned.rate * 100).toFixed(2)}%</h6>
                                        </React.Fragment>
                                        :
                                        null
                                        )}
                                    </div>
                                  </div>                        
                                </div>
                              
                                </Link>
                                <Link className="hover-link" to={"/call"}>
                                
                                <div className="dashboard-item-padding">
                                  <h6>Sales Objection</h6>
                                  <h5 className="summary-number">0</h5>
                                  {(
                                    this.state.dashboardData.livechat !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.livechat.open.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                      {(
                                        this.state.dashboardData.livechat !== undefined ?                          
                                        <React.Fragment>
                                          {(
                                            this.state.dashboardData.livechat.open.rate > 0 ?
                                        <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                          :
                                          (
                                            this.state.dashboardData.livechat.open.rate < 0 ?
                                            <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                            :
                                            <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                          )
                                          )
                                          }
                                          <h6 className={"inline-block change-percent "+(this.state.dashboardData.livechat.open.rate > 0 ? "red-text" : (this.state.dashboardData.livechat.open.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.livechat.open.rate * 100).toFixed(2)}%</h6>
                                        </React.Fragment>
                                        :
                                        null
                                        )}
                                    </div>
                                  </div>                        
                                </div>
                              
                                </Link>
                                <Link className="hover-link" to={"/call"}>
                                
                                <div className="dashboard-item-padding">
                                  <h6>Special Threat</h6>
                                  <h5 className="summary-number">0</h5>
                                  {(
                                    this.state.dashboardData.livechat !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.livechat.solved.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                      <div className="valign-wrapper">
                                        {(
                                        this.state.dashboardData.livechat !== undefined ?                          
                                        <React.Fragment>
                                          {(
                                            this.state.dashboardData.livechat.solved.rate > 0 ?
                                        <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                          :
                                          (
                                            this.state.dashboardData.livechat.solved.rate < 0 ?
                                            <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                            :
                                            <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                          )
                                          )
                                          }
                                          <h6 className={"inline-block change-percent "+(this.state.dashboardData.livechat.solved.rate > 0 ? "red-text" : (this.state.dashboardData.livechat.solved.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.livechat.solved.rate * 100).toFixed(2)}%</h6>
                                        </React.Fragment>
                                        :
                                        null
                                        )}
                                      </div>
                                  </div>                        
                                </div>
                              
                                </Link>
                                
                                <div className="dashboard-item-padding">
                                  <h6>Zero Tolerance</h6>
                                  <h5 className="summary-number">0</h5>
                                  {(
                                    this.state.dashboardData.livechat !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.livechat.escalated.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                    {(
                                      this.state.dashboardData.livechat !== undefined ?                          
                                      <React.Fragment>
                                        {(
                                          this.state.dashboardData.livechat.escalated.rate > 0 ?
                                      <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                        :
                                        (
                                          this.state.dashboardData.livechat.escalated.rate < 0 ?
                                          <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                          :
                                          <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                        )
                                        )
                                        }
                                        <h6 className={"inline-block change-percent "+(this.state.dashboardData.livechat.escalated.rate > 0 ? "red-text" : (this.state.dashboardData.livechat.escalated.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.livechat.escalated.rate * 100).toFixed(2)}%</h6>
                                      </React.Fragment>
                                      :
                                      null
                                      )}
                                      </div>
                                  </div>                        
                                </div>
                              
                                {/* <div className="dashboard-item-padding">
                                  <h6>SLA Violated</h6>
                                  {(
                                    this.state.dashboardData.livechat !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.livechat.violated.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                    {(
                                      this.state.dashboardData.livechat !== undefined ?                          
                                      <React.Fragment>
                                        {(
                                          this.state.dashboardData.livechat.violated.rate > 0 ?
                                      <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                        :
                                        (
                                          this.state.dashboardData.livechat.violated.rate < 0 ?
                                          <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                          :
                                          <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                        )
                                        )
                                        }
                                        <h6 className={"inline-block change-percent "+(this.state.dashboardData.livechat.violated.rate > 0 ? "red-text" : (this.state.dashboardData.livechat.violated.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.livechat.violated.rate * 100).toFixed(2)}%</h6>
                                      </React.Fragment>
                                      :
                                      null
                                      )}
                                      </div>
                                  </div>                        
                                </div> */}

                                </div>
                              </div>              
                              <div className="col s12">
                              <div className="divider margin-top-1x"></div>    
                              </div>

                              <div className="col s12 m6">
                                
                                <h5 className="bold-text emphasize-text smaller-font">Personality</h5>
                                <div className="div-table">
                                <Link className="hover-link" to={"/call"}>
                                
                                <div className="dashboard-item-padding">
                                  <h6>Thinker</h6>
                                  <h5 className="summary-number">0</h5>
                                  {(
                                    this.state.dashboardData.livechat !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.livechat.created.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">                                    
                                      {(
                                      this.state.dashboardData.livechat !== undefined ?                          
                                      <React.Fragment>
                                        {(
                                          this.state.dashboardData.livechat.created.rate > 0 ?
                                      <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                        :
                                        (
                                          this.state.dashboardData.livechat.created.rate < 0 ?
                                          <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                          :
                                          <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                        )
                                        )
                                        }
                                        <h6 className={"inline-block change-percent "+(this.state.dashboardData.livechat.created.rate > 0 ? "red-text" : (this.state.dashboardData.livechat.created.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.livechat.created.rate * 100).toFixed(2)}%</h6>
                                      </React.Fragment>
                                      :
                                      null
                                      )}
                                    </div>
                                  </div>
                                </div>
                              
                                </Link>
                                <Link className="hover-link" to={"/call"}>
                                
                                <div className="dashboard-item-padding">
                                  <h6>Feeler</h6>
                                  <h5 className="summary-number">0</h5>
                                  {(
                                    this.state.dashboardData.livechat !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.livechat.unassigned.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                      {(
                                        this.state.dashboardData.livechat !== undefined ?                          
                                        <React.Fragment>
                                          {(
                                            this.state.dashboardData.livechat.unassigned.rate > 0 ?
                                        <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                          :
                                          (
                                            this.state.dashboardData.livechat.unassigned.rate < 0 ?
                                            <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                            :
                                            <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                          )
                                          )
                                          }
                                          <h6 className={"inline-block change-percent "+(this.state.dashboardData.livechat.unassigned.rate > 0 ? "red-text" : (this.state.dashboardData.livechat.unassigned.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.livechat.unassigned.rate * 100).toFixed(2)}%</h6>
                                        </React.Fragment>
                                        :
                                        null
                                        )}
                                    </div>
                                  </div>                        
                                </div>
                              
                                </Link>
                                <Link className="hover-link" to={"/call"}>
                                
                                <div className="dashboard-item-padding">
                                  <h6>Entertainer</h6>
                                  <h5 className="summary-number">0</h5>
                                  {(
                                    this.state.dashboardData.livechat !== undefined ?
                                    <h5 className="summary-number">0</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                      {(
                                        this.state.dashboardData.livechat !== undefined ?                          
                                        <React.Fragment>
                                          {(
                                            this.state.dashboardData.livechat.open.rate > 0 ?
                                        <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                          :
                                          (
                                            this.state.dashboardData.livechat.open.rate < 0 ?
                                            <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                            :
                                            <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                          )
                                          )
                                          }
                                          <h6 className={"inline-block change-percent "+(this.state.dashboardData.livechat.open.rate > 0 ? "red-text" : (this.state.dashboardData.livechat.open.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.livechat.open.rate * 100).toFixed(2)}%</h6>
                                        </React.Fragment>
                                        :
                                        null
                                        )}
                                    </div>
                                  </div>                        
                                </div>
                              
                                </Link>
                                <Link className="hover-link" to={"/call"}>
                                
                                <div className="dashboard-item-padding">
                                  <h6>Controller</h6>
                                  <h5 className="summary-number">0</h5>
                                  {(
                                    this.state.dashboardData.livechat !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.livechat.solved.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                      <div className="valign-wrapper">
                                        {(
                                        this.state.dashboardData.livechat !== undefined ?                          
                                        <React.Fragment>
                                          {(
                                            this.state.dashboardData.livechat.solved.rate > 0 ?
                                        <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                          :
                                          (
                                            this.state.dashboardData.livechat.solved.rate < 0 ?
                                            <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                            :
                                            <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                          )
                                          )
                                          }
                                          <h6 className={"inline-block change-percent "+(this.state.dashboardData.livechat.solved.rate > 0 ? "red-text" : (this.state.dashboardData.livechat.solved.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.livechat.solved.rate * 100).toFixed(2)}%</h6>
                                        </React.Fragment>
                                        :
                                        null
                                        )}
                                      </div>
                                  </div>                        
                                </div>
                              
                                </Link>
                                
                                {/* <div className="dashboard-item-padding">
                                  <h6>Zero Tolerance</h6>
                                  {(
                                    this.state.dashboardData.livechat !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.livechat.escalated.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                    {(
                                      this.state.dashboardData.livechat !== undefined ?                          
                                      <React.Fragment>
                                        {(
                                          this.state.dashboardData.livechat.escalated.rate > 0 ?
                                      <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                        :
                                        (
                                          this.state.dashboardData.livechat.escalated.rate < 0 ?
                                          <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                          :
                                          <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                        )
                                        )
                                        }
                                        <h6 className={"inline-block change-percent "+(this.state.dashboardData.livechat.escalated.rate > 0 ? "red-text" : (this.state.dashboardData.livechat.escalated.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.livechat.escalated.rate * 100).toFixed(2)}%</h6>
                                      </React.Fragment>
                                      :
                                      null
                                      )}
                                      </div>
                                  </div>                        
                                </div> */}
                              
                                {/* <div className="dashboard-item-padding">
                                  <h6>SLA Violated</h6>
                                  {(
                                    this.state.dashboardData.livechat !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.livechat.violated.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                    {(
                                      this.state.dashboardData.livechat !== undefined ?                          
                                      <React.Fragment>
                                        {(
                                          this.state.dashboardData.livechat.violated.rate > 0 ?
                                      <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                        :
                                        (
                                          this.state.dashboardData.livechat.violated.rate < 0 ?
                                          <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                          :
                                          <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                        )
                                        )
                                        }
                                        <h6 className={"inline-block change-percent "+(this.state.dashboardData.livechat.violated.rate > 0 ? "red-text" : (this.state.dashboardData.livechat.violated.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.livechat.violated.rate * 100).toFixed(2)}%</h6>
                                      </React.Fragment>
                                      :
                                      null
                                      )}
                                      </div>
                                  </div>                        
                                </div> */}

                                </div>
                              </div> 

                              {/* <div className="col s12 m12">
                                <h5 className="bold-text smaller-font emphasize-text">Channels</h5>
                                  <div class="full-width">
                                    
                                    <div className="dashboard-item-padding">
                                      <div className="dashboard-icon-container">
                                        <FontAwesomeIcon className="channel-summary-icon" icon={faFacebook}/>
                                        <h6>Facebook</h6>
                                      </div>
                      
                                      {(
                                        this.state.dashboardData.social !== undefined ?
                                        <h5 className="summary-number">{this.state.dashboardData.social.fb.total}</h5>
                                        :
                                        null
                                      )
                                      }
                                      
                                      <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                        <div className="valign-wrapper">
                                          {(
                                            this.state.dashboardData.ticket !== undefined ?                          
                                            <React.Fragment>
                                              {(
                                                this.state.dashboardData.social.fb.rate > 0 ?
                                            <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                              :
                                              (
                                                this.state.dashboardData.social.fb.rate < 0 ?
                                                <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                                :
                                                <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                              )
                                              )
                                              }
                                              <h6 className={"inline-block change-percent "+(this.state.dashboardData.social.fb.rate > 0 ? "red-text" : (this.state.dashboardData.social.fb.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.social.fb.rate * 100).toFixed(2)}%</h6>
                                            </React.Fragment>
                                            :
                                            null
                                            )}
                                          </div>
                                      </div>
                                    </div>
                                     
                                
                                <div className="dashboard-item-padding">
                                <div className="dashboard-icon-container">
                                  <FontAwesomeIcon className="channel-summary-icon" icon={faTwitter}/>
                                  <h6>Twitter</h6>
                                </div>
                                  {(
                                    this.state.dashboardData.social !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.social.tw.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                      {(
                                        this.state.dashboardData.ticket !== undefined ?                          
                                        <React.Fragment>
                                          {(
                                            this.state.dashboardData.social.tw.rate > 0 ?
                                        <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                          :
                                          (
                                            this.state.dashboardData.social.tw.rate < 0 ?
                                            <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                            :
                                            <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                          )
                                          )
                                          }
                                          <h6 className={"inline-block change-percent "+(this.state.dashboardData.social.tw.rate > 0 ? "red-text" : (this.state.dashboardData.social.tw.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.social.tw.rate * 100).toFixed(2)}%</h6>
                                        </React.Fragment>
                                        :
                                        null
                                        )}
                                    </div>
                                  </div>
                                </div>
                               
                                
                                <div className="dashboard-item-padding">
                                <div className="dashboard-icon-container">
                                  <FontAwesomeIcon className="channel-summary-icon" icon={faLine}/>
                                  <h6>Line</h6>
                                </div>
                                  {(
                                    this.state.dashboardData.social !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.social.line.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                      {(
                                        this.state.dashboardData.ticket !== undefined ?                          
                                        <React.Fragment>
                                          {(
                                            this.state.dashboardData.social.line.rate > 0 ?
                                        <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                          :
                                          (
                                            this.state.dashboardData.social.line.rate < 0 ?
                                            <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                            :
                                            <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                          )
                                          )
                                          }
                                          <h6 className={"inline-block change-percent "+(this.state.dashboardData.social.line.rate > 0 ? "red-text" : (this.state.dashboardData.social.line.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.social.line.rate * 100).toFixed(2)}%</h6>
                                        </React.Fragment>
                                        :
                                        null
                                        )}
                                      </div>
                                  </div>
                                </div>
                               
                                
                                <div className="dashboard-item-padding">
                                <div className="dashboard-icon-container">
                                  <FontAwesomeIcon className="channel-summary-icon" icon={faEnvelope}/>
                                  <h6>Email</h6>
                                </div>
                                  {(
                                    this.state.dashboardData.social !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.social.email.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                      {(
                                        this.state.dashboardData.ticket !== undefined ?                          
                                        <React.Fragment>
                                          {(
                                            this.state.dashboardData.social.email.rate > 0 ?
                                        <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                          :
                                          (
                                            this.state.dashboardData.social.email.rate < 0 ?
                                            <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                            :
                                            <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                          )
                                          )
                                          }
                                          <h6 className={"inline-block change-percent "+(this.state.dashboardData.social.email.rate > 0 ? "red-text" : (this.state.dashboardData.social.email.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.social.email.rate * 100).toFixed(2)}%</h6>
                                        </React.Fragment>
                                        :
                                        null
                                        )}
                                    </div>
                                  </div>
                                </div>
                                
                                
                                <div className="dashboard-item-padding">
                                <div className="dashboard-icon-container">
                                  <FontAwesomeIcon className="channel-summary-icon" icon={faComment}/>
                                  <h6>Chat Bot</h6>
                                </div>
                                  {(
                                    this.state.dashboardData.social !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.social.wauchat.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                      {(
                                        this.state.dashboardData.ticket !== undefined ?                          
                                        <React.Fragment>
                                          {(
                                            this.state.dashboardData.social.wauchat.rate > 0 ?
                                        <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                          :
                                          (
                                            this.state.dashboardData.social.wauchat.rate < 0 ?
                                            <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                            :
                                            <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                          )
                                          )
                                          }
                                          <h6 className={"inline-block change-percent "+(this.state.dashboardData.social.wauchat.rate > 0 ? "red-text" : (this.state.dashboardData.social.wauchat.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.social.wauchat.rate * 100).toFixed(2)}%</h6>
                                        </React.Fragment>
                                        :
                                        null
                                        )}
                                      </div>
                                  </div>
                                </div>
                                
                                
                                <div className="dashboard-item-padding">
                                <div className="dashboard-icon-container">
                                  <FontAwesomeIcon className="channel-summary-icon" icon={faCoffee}/>
                                  <h6>Manual</h6>
                                </div>
                                  {(
                                    this.state.dashboardData.social !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.social.manual.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                      {(
                                        this.state.dashboardData.ticket !== undefined ?                          
                                        <React.Fragment>
                                          {(
                                            this.state.dashboardData.social.manual.rate > 0 ?
                                        <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                          :
                                          (
                                            this.state.dashboardData.social.manual.rate < 0 ?
                                            <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                            :
                                            <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                          )
                                          )
                                          }
                                          <h6 className={"inline-block change-percent "+(this.state.dashboardData.social.manual.rate > 0 ? "red-text" : (this.state.dashboardData.social.manual.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.social.manual.rate * 100).toFixed(2)}%</h6>
                                        </React.Fragment>
                                        :
                                        null
                                        )}
                                      </div>
                                  </div>
                                </div>
                                 
                                
                                <div className="dashboard-item-padding">
                                <div className="dashboard-icon-container">
                                  <FontAwesomeIcon className="channel-summary-icon" icon={faHeadset}/>
                                  <h6>Live Chat</h6>
                                </div>
                                  {(
                                    this.state.dashboardData.social !== undefined ?
                                    <h5 className="summary-number">{this.state.dashboardData.social.livechat.total}</h5>
                                    :
                                    null
                                  )
                                  }
                                  <div  data-balloon-blunt aria-label="Compared with yesterday" data-balloon-pos="up" className="dashboard-trend-container">
                                    <div className="valign-wrapper">
                                      {(
                                        this.state.dashboardData.livechat !== undefined ?                          
                                        <React.Fragment>
                                          {(
                                            this.state.dashboardData.social.livechat.rate > 0 ?
                                        <FontAwesomeIcon className={"red-text left"} icon={faCaretUp}/>
                                          :
                                          (
                                            this.state.dashboardData.social.livechat.rate < 0 ?
                                            <FontAwesomeIcon className={"green-text left"} icon={faCaretDown}/>
                                            :
                                            <FontAwesomeIcon className={"grey-text left"} icon={faCircle}/>
                                          )
                                          )
                                          }
                                          <h6 className={"inline-block change-percent "+(this.state.dashboardData.social.livechat.rate > 0 ? "red-text" : (this.state.dashboardData.social.livechat.rate < 0 ? "green-text" : "grey-text"))}>{(this.state.dashboardData.social.livechat.rate * 100).toFixed(2)}%</h6>
                                        </React.Fragment>
                                        :
                                        null
                                        )}
                                      </div>
                                  </div>
                                </div>
                                                                                                                                                                                                     
                              </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                    </div>
                    : null)}

                </div>
                : 
                <React.Fragment>
                  <div className="margin-top-2x">
                  <Circular/>
                  </div>
                </React.Fragment>
                )}
            </div>
        )
    }
}
