import React, { Component } from 'react'


import { ResponsiveBar } from '@nivo/bar'

export default class EmotionChart extends Component {
    render() {
        return (
            <div style={{height:this.props.height+'px',width:'100%',minWidth:'1px'}}>
                <ResponsiveBar
                    data={this.props.data}
                    keys={this.props.chartKey}
                    indexBy={this.props.indexBy}
                    maxValue={this.props.max !== undefined ? this.props.max : 'auto'}
                    margin={{ top: (this.props.marginTop !== undefined ? this.props.marginTop : 50), right: 130, bottom: (this.props.marginBottom !== undefined ? this.props.marginBottom : 100), left: (this.props.marginLeft ? this.props.marginLeft : 60) }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={{ scheme: 'nivo' }}
                    layout={this.props.layout}
                    theme={{"textColor":(this.props.dark ? "#fefefe" : "#333333"),axis:{legend:{text:{fill: this.props.dark ? "#fefefe" : "#333333"}}},tooltip:{container:{color:"#333333",background:this.props.dark ? '#424242' : '#fefefe'}}}}
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: '#38bcb2',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#eed312',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    fill={[
                        // {
                        //     match: {
                        //         id: 'fries'
                        //     },
                        //     id: 'dots'
                        // },
                        // {
                        //     match: {
                        //         id: 'sandwich'
                        //     },
                        //     id: 'lines'
                        // }
                    ]}
                    enableGridX={this.props.enableGridX}
                    enableGridY={this.props.enableGridY}
                    borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    axisRight={null}
                    axisTop={this.props.axisTop !== undefined ? this.props.axisTop : null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 90,
                        legend: this.props.axisBottom,
                        legendPosition: 'left',
                        legendOffset: 32
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: this.props.axisLeft,
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemTextColor:(this.props.dark ? "#fefefe" : "#333333"),
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                />                
            </div>
        )
    }
}
