import React, { useEffect, useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
// import '../css/custom-dropdown.css';
import { useLocation } from 'react-router-dom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export default function BMWCallChartPage() {
  const [formValues, setFormValues] = useState({
    selectedProject: '',
    selectedCampaign: '',
    selectedTeam: '',
    selectedTimeframe: 'Daily', // Default value for timeframe
  });
  const [visible, setVisible] = useState('hide');
  const [data, setData] = useState([]);
  const [label, setLabel] = useState([]);
  const [project, setProject] = useState([]);
  const [showProject, setShowProject] = useState(true);
  const [campaign, setCampaign] = useState([]);
  const [team, setTeam] = useState([]);
  const [defaultCampaign, setDefaultCampaign] = useState('');
  const [timeFrame, setTimeFrame] = useState('Daily');
  const location = useLocation();
//   const token = new URLSearchParams(location.search).get('token');
function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
}    

  const token = getCookie("auth");


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch project data
        const projectResponse = await axios.get('https://supercash.ai:8888/api/getProject?token=' + token);

        if (projectResponse.data === "hide") {
          setVisible(projectResponse.data);
        } 
        // IF PROJECT PERMISSION INVALID GET CAMPAIGN DATA
        else if (projectResponse.data === "Permission Invalid")
        {
          setShowProject(false);

          // Fetch campaign data
          const campaignResponse = await axios.get('https://supercash.ai:8888/api/getCampaign?token='+token);
            
          if (campaignResponse.data === "hide") {
            setVisible(campaignResponse.data);
          } else {
            if(campaignResponse.data === "Permission Invalid"){
              setCampaign([]);
            }
            else{
              setCampaign(campaignResponse.data ?? []);
            }
            setVisible("show");
    
            // Set the default selectedCampaign to the first campaign data
            if (campaignResponse.data.length > 0) {
              // setDefaultCampaign(campaignResponse.data[0].id);
              const firstCampaignId = campaignResponse.data[0].id;

              setFormValues(prevValues => ({
                ...prevValues,
                selectedCampaign: firstCampaignId,
              }));

              // Fetch BWMVolumeDaily data using the default campaign
              const bwmVolumeResponse = await axios.get('https://supercash.ai:8888/api/getBWMVolumeDaily?token='+token+'&campaign_id='+firstCampaignId);

              // Fetch team data using the default campaign
              const teamResponse = await axios.get('https://supercash.ai:8888/api/getTeam?token='+token+'&campaign_id='+firstCampaignId);
      
              if (bwmVolumeResponse.data === "hide") {
                setVisible(bwmVolumeResponse.data);
              } else {
                setLabel(bwmVolumeResponse.data.labels);
                setData(bwmVolumeResponse.data.datasets);
                if(teamResponse.data === "Permission Invalid"){
                  setTeam([]);
                }
                else{
                  setTeam(teamResponse.data ?? []);
                }
                setVisible("show");
              }
            }
          }
        } 
        else 
        {
          setProject(projectResponse.data);
          setVisible("show");

          // Set the default projectResponse to the first project data
          if (projectResponse.data.length > 0) {
            // setDefaultCampaign(projectResponse.data[0].id);
            const firstProjectId = projectResponse.data[0].id;

            setFormValues(prevValues => ({
              ...prevValues,
              selectedProject: firstProjectId,
            }));

            // Fetch campaign data
            const campaignResponse = await axios.get('https://supercash.ai:8888/api/getCampaign?token='+token+'&project_id='+firstProjectId);
            
            if (campaignResponse.data === "hide") {
              setVisible(campaignResponse.data);
            } else {
              if(campaignResponse.data === "Permission Invalid"){
                setCampaign([]);
              }
              else{
                setCampaign(campaignResponse.data ?? []);
              }
              setVisible("show");
      
              // Set the default selectedCampaign to the first campaign data
              if (campaignResponse.data.length > 0) {
                // setDefaultCampaign(campaignResponse.data[0].id);
                const firstCampaignId = campaignResponse.data[0].id;

                setFormValues(prevValues => ({
                  ...prevValues,
                  selectedCampaign: firstCampaignId,
                }));

                // Fetch BWMVolumeDaily data using the default campaign
                const bwmVolumeResponse = await axios.get('https://supercash.ai:8888/api/getBWMVolumeDaily?token='+token+'&campaign_id='+firstCampaignId);

                // Fetch team data using the default campaign
                const teamResponse = await axios.get('https://supercash.ai:8888/api/getTeam?token='+token+'&campaign_id='+firstCampaignId);
        
                if (bwmVolumeResponse.data === "hide") {
                  setVisible(bwmVolumeResponse.data);
                } else {
                  setLabel(bwmVolumeResponse.data.labels);
                  setData(bwmVolumeResponse.data.datasets);
                  if(teamResponse.data === "Permission Invalid"){
                    setTeam([]);
                  }
                  else{
                    setTeam(teamResponse.data ?? []);
                  }
                  setVisible("show");
                }
              }
            }
          }
        }
      } catch (error) {
        // Handle errors
        console.error(error);
      }
    };
  
    fetchData();
  }, [token, defaultCampaign]);

  // useEffect(() => {
  //   // Fetch posts from the backend API
  //   axios.get('https://supercash.ai:8888/api/getCampaign?token='+token)
  //   .then(response => {
  //     if(response.data == "hide")
  //     {
  //       setVisible(response.data);
  //     }
  //     else
  //     {
  //       setCampaign(response.data);
  //       setVisible("show");

  //       // Set the default selectedCampaign to the first campaign data
  //       if (response.data.length > 0) {
  //         setDefaultCampaign(response.data[0].campaign_id);
  //       }
  //     }
      
  //     // console.log(response.data);
  //   })
  //   .catch(error => {
  //     // Handle errors
  //     console.error(error);
  //   });

  // }, []);

  const dataLine = {
    labels:label,
    datasets:data,
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
        ticks: {
          precision: 0, // Set the number of decimal places to 0
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Volume',
        },
        ticks: {
          precision: 0, // Set the number of decimal places to 0
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Call Volume Graph (' + timeFrame + ')', // Set the title for your chart
        font: {
          size: 16, // Adjust the font size as needed
        },
      },
      tooltip: {
        events: "mousemove",
        enabled: false, // Disable default tooltips
        external: (context) => {
          const tooltipEl = document.getElementById('chartjs-tooltip');
          if (!tooltipEl) {
            return;
          }
  
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }
          
          // console.log(tooltipModel);
          // Customize tooltip content
          let innerHTML = '<div>';

          tooltipModel.dataPoints.forEach((dataPoint) => {
            // console.log(dataPoint);
            innerHTML += `
              <div class="mb-3">
                User: ${dataPoint.dataset.label}
                </br>
                Volume: ${dataPoint.raw}
                </br>
                Date: ${dataPoint.label}
                </br>
                Color: <span style="border:1.5px solid ${dataPoint.dataset.borderColor};background-color:${dataPoint.dataset.backgroundColor};width:10px;height:10px;display:inline-block;"></span>
                </br>
              </div>
            `;
          });
      
          innerHTML += '</div>';
      
          tooltipEl.innerHTML = innerHTML;
  
          // Set tooltip position
          const position = tooltipModel.chart.canvas.getBoundingClientRect();
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = 0 + 'px';
          tooltipEl.style.top = 0 + 'px';
          tooltipEl.style.background = "rgba(51, 51, 51, 0.9)";
          tooltipEl.style.color = 'white';
          tooltipEl.style.padding = '5px';
          tooltipEl.style.borderRadius = '0px 5px 5px 5px';
        },
      },
      events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    },
  };

  useEffect(() => {
    const tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    document.body.appendChild(tooltipEl);

    return () => {
      document.body.removeChild(tooltipEl);
    };
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    // console.log("Selected Campaign changed:", value);
    // Define an async function for the API request

    if (name === 'selectedProject') {
      const fetchData = async () => {
        try {
          const campaignResponse = await axios.get('https://supercash.ai:8888/api/getCampaign?token='+token+'&project_id='+value);
          // Process the API response as needed
          if(campaignResponse.data === "Permission Invalid"){
            setCampaign([]);
          }
          else{
            setCampaign(campaignResponse.data ?? []);
          }
          if (campaignResponse.data.length > 0) {
            const campaignId = campaignResponse.data[0].id;
            const teamResponse = await axios.get('https://supercash.ai:8888/api/getTeam?token='+token+'&campaign_id='+campaignId);
            if(teamResponse.data === "Permission Invalid"){
              setTeam([]);
            }
            else{
              setTeam(teamResponse.data ?? []);
            }

            setFormValues(prevValues => ({
              ...prevValues,
              selectedCampaign: campaignId,
            }));
          }
        } catch (error) {
          // Handle API request errors
          console.error('API Request Error:', error);
        }
      };

      // Call the async function immediately
      fetchData();
    }

    if (name === 'selectedCampaign') {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://supercash.ai:8888/api/getTeam?token='+token+'&campaign_id='+value);
          // Process the API response as needed
          setTeam(response.data);
        } catch (error) {
          // Handle API request errors
          console.error('API Request Error:', error);
        }
      };

      // Call the async function immediately
      fetchData();
    }

  };

  const handleSearch = (event) => {
    event.preventDefault();

    const selectedCampaign = formValues.selectedCampaign;
    const selectedTeam = formValues.selectedTeam;
    const selectedTimeframe = formValues.selectedTimeframe;
    setTimeFrame(selectedTimeframe);

    // Depending on the selected timeframe, update your chart data accordingly.
    switch (selectedTimeframe) {
      case 'Daily':
        axios.get('https://supercash.ai:8888/api/getBWMVolumeDaily?token='+token+'&campaign_id='+selectedCampaign+'&team_id='+selectedTeam)
          .then((response) => {
            setLabel(response.data.labels);
            setData(response.data.datasets);
          })
          .catch((error) => {
            console.error(error);
          });
        break;
      case 'Weekly':
        axios.get('https://supercash.ai:8888/api/getBWMVolumeWeekly?token='+token+'&campaign_id='+selectedCampaign+'&team_id='+selectedTeam)
          .then((response) => {
            setLabel(response.data.labels);
            setData(response.data.datasets);
          })
          .catch((error) => {
            console.error(error);
          });
        break;
      case 'Monthly':
        axios.get('https://supercash.ai:8888/api/getBWMVolumeMonthly?token='+token+'&campaign_id='+selectedCampaign+'&team_id='+selectedTeam)
          .then((response) => {
            setLabel(response.data.labels);
            setData(response.data.datasets);
          })
          .catch((error) => {
            console.error(error);
          });
        break;
      default:
        break;
    }
  };

  return (
    <Container className='mt-3'>
      {visible == "show" ? 
      <>
        <Form onSubmit={handleSearch}>
          <Row>
            {showProject && (
              <Col className='col s3' lg={3}>
                <Form.Label>Project</Form.Label>
                <Form.Select
                    className='browser-default'
                  name="selectedProject"
                  onChange={handleInputChange}
                  value={formValues.selectedProject}
                >
                  {project.map(({ id, project_name }) => (
                    <option key={id} value={id}>
                      {project_name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            )}
            <Col className='col s3' lg={3}>
              <Form.Label>Campaign</Form.Label>
              <Form.Select
                className='browser-default'
                name="selectedCampaign"
                onChange={handleInputChange}
                value={formValues.selectedCampaign}
              >
                {/* <option value="">Select A Campaign</option> */}
                {campaign.map(({ campaign_id, campaign_name, id }) => (
                  <option key={id} value={id}>
                    {campaign_id} - {campaign_name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col className='col s3' lg={3}>
              <Form.Label>Team</Form.Label>
              <Form.Select
                className='browser-default'
                name="selectedTeam"
                onChange={handleInputChange}
                value={formValues.selectedTeam}
              >
                {team.map(({ team_id, team_name }) => (
                  <option key={team_id} value={team_id}>
                    {team_name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col className='col s3' lg={3}>
              <Form.Label>Time Frame</Form.Label>
              <Form.Select
                className='browser-default'
                name="selectedTimeframe"
                onChange={handleInputChange}
                value={formValues.selectedTimeframe}
              >
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
              </Form.Select>
            </Col>
            <Col className='col s3' lg={3}>
              <Button variant="primary" type="submit" className="w-100">
                Search
              </Button>
            </Col>
          </Row>

  
          
        </Form>
        {/* <Row>
          <Col lg={6}>
            <Form.Label>Campaign</Form.Label>
            <Form.Select onChange={handleCampaignChange}>
            <option value="">Select A Campaign</option>
              {campaign.map(({ campaign_id, campaign_name, id }) => (
                <option key={id} value={campaign_name}>
                  {campaign_id} - {campaign_name}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col lg={6}>
            <Form.Label>Time Frame</Form.Label>
            <Form.Select onChange={handleTimeframeChange}>
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
            </Form.Select>
          </Col>
        </Row> */}
        <Row>
          <Col lg={12} className='mx-auto mt-3'>
            <Line data={dataLine} options={options} />
            <div id="chartjs-tooltip"></div>
          </Col>
        </Row>
      </>
      :
      <Row>
        <Col lg={2} className='mx-auto'>
          <p>INVALID PERMISSION</p>
        </Col>
      </Row>
      
      }
    </Container>
  );
}