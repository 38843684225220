import React, { Component } from 'react'
import Variable from './../variable';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Moment from 'react-moment';
import moment from 'moment';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown'
import { ResponsiveBar } from '@nivo/bar'
import EmotionChart from './../components/EmotionChart';
import Circular from './../components/Circular';
import {NavigateNext,NavigateBefore} from '@material-ui/icons'

import StackedBarChart from './../components/StackedBarChart';
import { isObject } from 'lodash';
import ServiceTime from './ServiceTime';
import AgentPool from './AgentPool';
import PBX from './PBX';
// import rd3 from 'react-d3-library'

const data = [
    {
      name: "Brand 1",
      type: 1,
      Affiliate: 10,
      Social: 20,
      Media: 30
    },
    {
      name: "Brand 1",
      type: 2,
      Affiliate: 20,
      Social: 40,
      Media: 60
    },
    {
      name: "Brand 2",
      type: 1,
      Affiliate: 30,
      Social: 45,
      Media: 80
    },
    {
      name: "Brand 3",
      type: 1,
      Affiliate: 40,
      Social: 60,
      Media: 100
    },
    {
      name: "Brand 3",
      type: 2,
      Affiliate: 50,
      Social: 80,
      Media: 120
    }
  ];
  
  const allKeys = ["Affiliate", "Social", "Media"];
  
  const colors = {
    Affiliate: "rgba(69, 0, 0, 0.8)",
    Social: "rgba(240, 72, 19, 0.8)",
    Media: "rgba(255, 199, 128, 0.8)"
  };


export default class Configuration extends Component {
    host = Variable.host;

    constructor(props){
        super(props);
        this.state = {
            startDate:(moment()),
            endDate:(moment()),
            reportData : {},
            activityData: [],
            steps:"24",
            mode:"omni",
            chartData:{},
            filterDate:[],
            chartLoading:false,
            statusArray:[],
            tabSelected:"st",
            references:{}
        }
    }

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }
    
    toChartData = (data) => {
        let timeArray = [];
        let chartKeys = [];
        let alldata = {};
        
        Object.keys(data).map((time, index)=> {
            let newObj = {};
            newObj.time = time;
           if( Array.isArray(data[time]["all"])){

            }
            else{
                Object.keys(data[time]).map((channel, index)=> {
                    if( Array.isArray(data[time][channel])){
                    }
                    else{
                        Object.keys(data[time][channel]).map((emotion, index)=> {
                        
                            newObj[emotion] = data[time][channel][emotion];
                            newObj[emotion+"Color"] = "hsl(297, 70%, 50%)";
                            if(chartKeys.indexOf(emotion) === -1){
                                chartKeys.push(emotion);
                            }
                        })
                    }
                })
            }
            timeArray.push(newObj);
        });
        alldata['chartData'] = timeArray;
        alldata['chartKeys'] = chartKeys;
        return alldata;
    }

    tabSwitch = () => {

    }

    toChartData2 = (data) => {
        let timeArray = {};
        let chartKeys = [];
        let alldata = {};

        let max = 0;

        let channelType = [{name : "email",channel:["EMAIL"]},{name:"chatbot",channel:["WAUCHAT"]},{name:"livechat",channel:["LIVECHAT"]},{name:"social media",channel:["FB_PAGE_POST","TW_MENTION"]},{name:"mobile messaging",channel:["FB_PM","TW_DM","LINE_DM","WHATSAPP"]}];
        
        



        Object.keys(data).map((time, index)=> {
            
            let channelArray = [];
           if( Array.isArray(data[time]["all"])){

            }
            else{
                
                for(let i = 0; i < channelType.length ; i++){
                    let tempMax = 0;
                    let newObj = {};
                    newObj.channel = channelType[i].name;
                    Object.keys(data[time]).filter(key => channelType[i].channel.includes(key)).map((channel, index)=> {
                        if( Array.isArray(data[time][channel])){

                        }
                        else{

                            // switch(channelArray[i]){
                            //     case "email":
                            //         if(channel !== "EMAIL"){
                            //             return false;
                            //         }
                            //     break;

                            //     case "chatbot":
                            //         if(channel !== "WAUCHAT"){
                            //             return;
                            //         }
                            //     break;

                            //     case "livechat":
                            //         if(channel !== "LIVECHAT"){
                            //             return;
                            //         }
                            //     break;

                            //     case "social media":
                            //         if(channel !== "FB_PAGE_POST" && channel !== "TW_MENTION"){
                            //             return;
                            //         }
                            //     break;

                            //     case "mobile messaging":
                            //         if(channel !== "FB_PM" && channel !== "TW_DM" && channel !== "LINE_DM" && channel !== "WHATSAPP"){
                            //             return;
                            //         }
                            //     break;
                            // }

                            


                                    

                                        Object.keys(data[time][channel]).map((emotion, index)=> {
                                        
                                            
                                            if(newObj[emotion]){
                                                newObj[emotion] = newObj[emotion] + data[time][channel][emotion];
                                                tempMax = tempMax + data[time][channel][emotion];
                                            }
                                            else{
                                                newObj[emotion] = data[time][channel][emotion];
                                                tempMax = tempMax + data[time][channel][emotion];
                                                newObj[emotion+"Color"] = "hsl(297, 70%, 50%)";
                                            }
                                            

                                            if(chartKeys.indexOf(emotion) === -1){
                                                chartKeys.push(emotion);
                                            }                                    
                                            
                                            // if(chartKeys.indexOf(emotion) === -1){
                                            //     chartKeys.push(emotion);
                                            // }
                                        })
                            
                        }
                    })
                    channelArray.push(newObj);
                    if(tempMax > max){
                        max = tempMax;
                    }
                }
            }
            timeArray[time] = (channelArray);
        });
        alldata['chartData'] = timeArray;
        alldata['chartKeys'] = chartKeys;
        alldata['max'] = max;
        return alldata;
    }    

    toChartData3 = (data) => {
        let timeArray = [];
        let chartKeys = [];
        let alldata = {};
        
        Object.keys(data).map((time, index)=> {
            let tempArray = [];

           if( Array.isArray(data[time]["all"])){

            }
            else{
                Object.keys(data[time]).map((channel, index)=> {
                    
                        let newObj = {};
                        newObj.channel = channel;
                        // newObj[channel] = data[time][channel];

                        Object.keys(data[time][channel]).map((status, index)=> {
                            if(status !== "soa"){
                                if(isObject(data[time][channel][status])){
                                    newObj[status] = data[time][channel][status]["total"];
                                    newObj[status+"Color"] = "hsl(297, 70%, 50%)";
                                    if(chartKeys.indexOf(status) === -1){
                                        chartKeys.push(status);
                                    }   
                                }  
                                else{
                                    newObj[status] = data[time][channel][status];
                                    newObj[status+"Color"] = "hsl(297, 70%, 50%)";
                                    if(chartKeys.indexOf(status) === -1){
                                        chartKeys.push(status);
                                    }                                       
                                }     
                            }
                        });
                                          
 
                    // if( Array.isArray(data[time][channel])){
                    // }
                    // else{
                    //     Object.keys(data[time][channel]).map((emotion, index)=> {
                        
                    //         newObj[emotion] = data[time][channel][emotion];
                    //         newObj[emotion+"Color"] = "hsl(297, 70%, 50%)";
                    //         if(chartKeys.indexOf(emotion) === -1){
                    //             chartKeys.push(emotion);
                    //         }
                    //     })
                    // }

                    tempArray.push(newObj);
                })

                
            }
            timeArray[time] = tempArray;
        });
        alldata['chartData'] = timeArray;
        alldata['chartKeys'] = chartKeys;
        return alldata;
    }    


    todayDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

      getChannelName(name){
          let names = {
              "FB_PM" : "Facebook Messenger",
              "FB_PAGE_POST" : "Facebook Post",
              "TW_DM" : "Twitter Direct Message",
              "TW_MENTION" : "Twitter Mentions",
              "WHATSAPP" : "Whatsapp",
              "LINE_DM" : "Line Direct Message",
              "WAUCHAT" : "Chat Bot",
              "EMAIL" : "Email",
              "LIVECHAT" : "Live Chat",
              "MANUAL" : "Manual"
          }
          return names[name] || "Unknown";
      }
      
      exportExcel = () => {
          let win = window.open("https://ticket-api.hyperceed.com/report/export/"+this.getCookie("auth")+"/facebook/"+this.state.startDate.format('YYYY-MM-DD')+"/"+this.state.endDate.format('YYYY-MM-DD')+"/"+this.state.steps);
      }

      reportType = (type) => {
        //   switch(type){
        //       case "omni":
        //         axios.get(this.host+'/ticket/report/'+this.getCookie("auth")+'/facebook', {params:{_token:this.getCookie("auth"),startDate:this.state.startDate.format('YYYY-MM-DD'),endDate:this.state.endDate.format('YYYY-MM-DD'),steps:this.state.steps}},{withCredentials: true})
        //         .then(res => {
        //             this.setState({reportData:res.data});
        //           console.log(res.data);
        //         })                 

        //         break;

        //         case "interaction":
        //             axios.get(this.host+'/ticket/activity/list', {params:{_token:this.getCookie("auth"),startDate:this.state.startDate.format('YYYY-MM-DD'),endDate:this.state.endDate.format('YYYY-MM-DD'),steps:"1"}},{withCredentials: true})
        //             .then(res => {
        //                 this.setState({activityData:res.data});
        //               console.log(res.data);
        //             })     
        //         break;

        //         case "emotion":

        //         break;
        //   }
          this.setState({mode:type},()=>{
              this.getReport();
          });
      }

      getReport = () => {
        switch(this.state.mode){
            case "omni":
                this.setState({chartLoading:true});
                axios.get(this.host+'/api/report/interval', {params:{_token:this.getCookie("auth"),startDate:this.state.startDate.format('YYYY-MM-DD'),endDate:this.state.endDate.format('YYYY-MM-DD'),interval:this.state.steps}},{withCredentials: true})
                .then(res => {
                    
                    // let tempObj = {};

                    // let chartData = res.data;

                    // Object.keys(chartData).map((date, index)=> {



                    //     // let temp = this.toChartData(res.data[date]);

                        
    
                    //     let temp = this.toChartData3(res.data[date]);

                    //     tempObj[date] = {};
                    //     tempObj[date].chartData = temp.chartData;

                    //     tempObj[date].chartKeys = temp.chartKeys;


                        
                                        
                        
                    // })

                    this.setState({reportData:res.data,chartLoading:false});

                    // console.log(res.data);
                })  
     
            break;
            case "interaction":
                this.setState({chartLoading:true});
                axios.get(this.host+'/api/report/aux', {params:{_token:this.getCookie("auth"),startDate:this.state.startDate.format('YYYY-MM-DD'),endDate:this.state.endDate.format('YYYY-MM-DD')}},{withCredentials: true})
                .then(res => {
                    let statusArray = this.state.statusArray;
                    for(let i = 0; i < res.data.length; i++){
                        Object.keys(res.data[i].value).map((agent,i2)=>{
                            Object.keys(res.data[i].value[agent]).map((status,i3)=>{
                                if(statusArray.indexOf(status) === -1){
                                    statusArray.push(status);
                                }
                            })
                        })
                    }
                    this.setState({activityData:res.data,chartLoading:false,statusArray:statusArray});
                  console.log(res.data);
                })     
            break; 

            case "emotion":
                this.setState({chartLoading:true});
                axios.get(this.host+'/api/report/csat', {params:{_token:this.getCookie("auth"),startDate:this.state.startDate.format('YYYY-MM-DD'),endDate:this.state.endDate.format('YYYY-MM-DD')}},{withCredentials: true})
                .then(res => {
                    
                    this.setState({chartData:res.data,chartLoading:false});
                  console.log(res.data);
                })     
            break; 
        }          
      }

      convertSeconds = (secs) => {
        var sec_num = parseInt(secs, 10)
        var hours   = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60
    
        return [hours,minutes,seconds]
            .map(v => v < 10 ? "0" + v : v)
            .join(":")
      }

      getFilterTitle = (status) => {
        switch(status){
            case "1":
                return "1 hour";
            case "30":
                return "30 minutes";
            case "15":
                return "15 minutes";
            case "24":
                return "Day";
        }
      }

    componentDidMount(){
        // this.getReport();
        axios.get(this.host+'/api/proxy/references', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
        .then(res => {
          this.setState({references:res.data});
        })
    }

    selectFilter = (data) => {
        console.log(data);
        this.setState({steps:data});
    }

    selectDateFilter = (data) => {
        console.log(data);
        this.setState({filterDate:data});
    }    

    getCustomStatusHeader = (obj) => {

        let array = [];
        let count = 0;
        
            Object.keys(obj).map((time,index) => {
                Object.keys(obj[time]).map((key,index) => {

                    Object.keys(obj[time][key]).map((status,index) => {

                        if(!isObject(obj[time][key][status]) && count === 0 && status !== "soa"){
                            array.push(status);
                            
                        }

                        
                    })   
                    count++;
                    return array;
                })
                return;
            });
            
        return array;
        
    }

    render() {
        return (
            <div className="full-width flex-col full-height" style={{minWidth:'1px'}}>
                <iframe frameBorder="0" src={"https://www.superceed.com/superunity?token="+this.getCookie("auth")+"&feature=hyperceed"} width="100%" height="100%"/>
                {/* <div className={" flex-col padding-left-2x padding-right-2x flex-no-shrink header-border-bottom"} style={{verticalAlign:'middle',position:"relative",backgroundColor:this.props.dark ? '#333' :'#ffffff'}}>
                    <span className="bold-text-2 full-width ellipsis-text chat-list-title-text bold-text-2" style={{marginTop:'25px'}}>Call Configurations</span>
                    <div className="custom-tab">
                            <div className={"custom-tab-item unselectable waves-effect "+(this.state.tabSelected === "st" ? "clicked" : "")+(this.props.dark ? " dark" : "")} onClick={()=>{this.setState({tabSelected:"st"});this.tabSwitch()}}>Service Time</div>
                            <div className={"custom-tab-item unselectable waves-effect "+(this.state.tabSelected === "ap" ? "clicked" : "")+(this.props.dark ? " dark" : "")} onClick={()=>{this.setState({tabSelected:"ap"});this.tabSwitch()}}>Agent Pool</div>
                            <div className={"custom-tab-item unselectable waves-effect "+(this.state.tabSelected === "ivr" ? "clicked" : "")+(this.props.dark ? " dark" : "")} onClick={()=>{this.setState({tabSelected:"ivr"});this.tabSwitch()}}>IVR</div>
                            <div className={"custom-tab-item unselectable waves-effect "+(this.state.tabSelected === "ann" ? "clicked" : "")+(this.props.dark ? " dark" : "")} onClick={()=>{this.setState({tabSelected:"ann"});this.tabSwitch()}}>Uploaded announcement</div> 
                                                     
                    </div>                    
                </div>
                <div className="overflow-y">
                    
                    {
                        this.state.tabSelected === "st" ?
                        <ServiceTime/>
                        :
                        (this.state.tabSelected === "ap" ?
                        <AgentPool references={this.state.references}/>
                        :
                        (this.state.tabSelected === "ivr" ?
                        <PBX references={this.state.references}/>
                        :
                        null))
                    }
                    <div style={{padding:"20px",borderBottom:'1px solid rgba(0,0,0,0.1)'}}>

                    </div>
                </div> */}
            </div>
        )
    }
}