import React, { Component,Suspense } from 'react';
import axios from 'axios';
import Variable from './../../variable';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faFacebook,faFacebookMessenger,faTwitterSquare,faWhatsapp, faLine, faTwitter,faTelegram,faInstagram } from '@fortawesome/free-brands-svg-icons'
// import { IconTicket,IconHeadphones,IconPhone, IconPencil,IconGift,IconId,IconSend,IconUnlink,IconAffiliate } from '@tabler/icons-react';

// import { faEnvelope, faComment, faHeadset, faCoffee,faPhone,faRedo,faCrown,faShareSquare,faTicketAlt,faSmileWink,faFlag,faAt } from '@fortawesome/free-solid-svg-icons';

// import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';
// import ProgressBar from 'react-bootstrap/ProgressBar'
import CustomModal from './../../components/CustomModal'

// import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";

import { BrowserRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom';

// import Dropdown from 'react-bootstrap/Dropdown'

// import CaseForm from './../../components/layout/conversation/CaseForm';

// import RichTextEditor,{createValueFromString} from 'react-rte';

// import CustomEditor from './ArticleEditor';

import DOMPurify from 'dompurify'

const CustomEditor = React.lazy(()=> retry(() => import('./ArticleEditor')));

function retry(fn, retriesLeft = 5, interval = 1000) {
    return new Promise((resolve, reject) => {
      fn()
        .then(resolve)
        .catch((error) => {
          setTimeout(() => {
            if (retriesLeft === 1) {
              // reject('maximum retries exceeded');
              reject(error);
              return;
            }
  
            // Passing on "reject" is the important part
            retry(fn, retriesLeft - 1, interval).then(resolve, reject);
          }, interval);
        });
    });
  }

const emotionColor = {
    "neutral" : "#787878",
  "angry" : "#C0392B",
  "fear" : "#F4D03F",
  "frustration" : "#E67E22",
  "threat" : "#A93226",
  "commitment" : "#138D75",
  "satisfaction" : "#94C973",
  "appreciation" : "#DE3163",
  "gratitude" : "#82E0AA",
  "joy" : "#85C1E9",
  "disappointment" : "#90A4AE",
  "dissapointment" : "#90A4AE",
  "sadness" : "#5885AF",
  "dissatisfaction" : "#FAD7A0",
  "sarcasm" : "#F5B7B1",
  "surprise" : "#D2B4DE",
  "concerned" : "#7D6608",
}

const idType = [
    "None",
    "MyKad ID",
    "MyKid ID",
    "PR MyKad",
    "Passport No.",
    "Army ID",
    "Police ID"
]



export default class Question extends Component {

    currentId = "";

    host = Variable.host;

    constructor(props){
        super(props);
        this.state = {
            contactsData:{},
            dataLoading:false,
            editMode:false,
            contactsParam:[],
            editData:{},
            modalTitle:"",
            overflowShow:false,
            linkType:"",
            input:"",
            errorMessage:"",
            basicEditMode:false,
            info:{},
            mandatoryField:[],
            addField:[],
            deleteField:[],
            errorMessage:"",
            overflowTop:0,
            value:"",
            kbValue:"",
            editedData:""
        }
        this.overflowRef = React.createRef();
        this.buttonRef = React.createRef();
    }

    onChange = (value) => {
        this.setState({value:value,kbValue:value.toString("html")});

    }

    setEditedData = (data) => {
        this.setState({editedData:data});
    }

    loadContacts = () => {
        this.setState({dataLoading:true},()=>{
            axios.get(this.host+'/api/kb/category/sub/question', {params:{_token:this.getCookie("auth"),question:this.currentId,type:this.props.match?.params?.type ?? this.props.config.type}},{withCredentials: true})
            .then(res => {
                if(!res.data.result){
                    this.setState({contactsData:res.data[0],dataLoading:false})

                }
                else{
                    this.setState({contactsData:{},dataLoading:false})
                }
            }) 
        });
    }

    componentDidMount(){
        if(this.props.match){
            this.currentId = this.props.match?.params.question ?? this.props.config.id;
        }
        else{
            this.currentId = this.props.config.id;
        }

        this.loadContacts();
    }

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
      }  

    componentDidUpdate(){
        if(this.props.match){
            if(this.currentId !== this.props.match?.params.question ?? this.props.config.id){
                console.log(this.props.match?.params.question ?? this.props.config.id);
                this.currentId = this.props.match?.params.question ?? this.props.config.id;
                this.setState({dataLoading:true},()=>{
                    axios.get(this.host+'/api/kb/category/sub/question', {params:{_token:this.getCookie("auth"),question:this.currentId,type:this.props.match?.params.type ?? this.props.config.type}},{withCredentials: true})
                    .then(res => {
                        if(!res.data.result){
                            this.setState({contactsData:res.data[0],dataLoading:false,editMode:false,basicEditMode:false})
                        }
                        else{
                            this.setState({contactsData:{},dataLoading:false,basicEditMode:false})
                        }
                    }) 
                });          
            }
        }
    }

    setInfoValue = (e,type) => {
        let info = this.state.info;
        info[type] = e.target.value;
        this.setState({info:info});
    }

    setInfoValue2 = (e,type) => {
        let info = this.state.info;
        info[type] = e;
        this.setState({info:info});
    }

    editInfo = () => {
        let dateObj = null;
        if(this.state.contactsData.dob !== ""){
            if(moment(this.state.contactsData.dob).isValid()){
            dateObj =  moment(this.state.contactsData.dob).toDate();
            this.currentDate = dateObj;
            }
            else{
                dateObj = "";
            }
        }
        let info = {salutation:this.state.contactsData.salutation,name: this.state.contactsData.name, email: this.state.contactsData.email,phone:this.state.contactsData.phone,dob:this.state.contactsData.dob,id_number:this.state.contactsData.id_number,id_type:this.state.contactsData.id_type};
        
        this.setState({info:info,basicEditMode:true});

    }

    editContacts = () => {
        if(this.state.contactsParam.length === 0){
            axios.get(this.host+'/api/contacts/fields', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
            .then(res => {

                this.setState({contactsParam:res.data,editMode:true,editData:JSON.parse(JSON.stringify(this.state.contactsData.field))})
                
        
            }) 
        }
        else{
            this.setState({editMode:true,editData:JSON.parse(JSON.stringify(this.state.contactsData.field))})
        }
    }

    getEmotionPercentage3 = (data,obj) => {
        let obj2 = Object.keys(obj);
        return obj[data] / obj2.length * 100;
    }

    checkParam = (name) => {
        let arr = this.state.contactsParam;
        const { length } = arr;
        const id = length + 1;
        const found = arr.some(el => el.fields_param === name);
 
        return found;
    }

    getValue = (name) => {
        let arr = this.state.editData;
        const { length } = arr;
        const id = length + 1;
        const found = arr.find(el => el.contacts_field === name);
 
        return found.contacts_value;
    }

    saveContacts = () => {
        let pendingData = [];

        for(let i = 0; i < this.state.editData.length; i++){
            
            if(this.state.editData[i].original_data){
                if(this.state.editData[i].original_data !== this.state.editData[i].contacts_value){
                    pendingData.push(this.state.editData[i]);
                }
            }

        }

        let missingField = false;
        let missingFieldName = "";
        for(let i = 0; i < this.state.mandatoryField.length; i++){
            if(!this.state.extForm[this.state.mandatoryField[i]]){
                if(missingFieldName === ""){
                    missingFieldName += this.state.mandatoryField[i];
                }
                else{
                    missingFieldName += ", "+this.state.mandatoryField[i];
                }
                missingField = true;

                
            }

            
        }
        if(missingField){
            missingFieldName += " is required";
            this.setState({errorMessage:missingFieldName});
            return false;
        }

        if(pendingData.length > 0 || this.state.addField.length > 0 || this.state.deleteField.length > 0){
            axios.post(this.host+'/api/contacts/fields',{_token:this.getCookie("auth"),id:this.state.contactsData.id,data:pendingData,add:this.state.addField,delete:this.state.deleteField},{withCredentials: true})
            .then(res => {
                let contactsData = this.state.contactsData;
                // setTeam(res.data);
                for(let i = 0; i < this.state.editData.length; i++){
            
                    if(this.state.editData[i].original_data){
                        if(this.state.editData[i].original_data !== this.state.editData[i].contacts_value){
                            this.state.editData[i].original_data = this.state.editData[i].contacts_value;
                        }
                    }
        
                }

                for(let i = 0; i < this.state.deleteField.length;i++){
                    for(let k = 0; k < this.state.editData.length; k++){
            
                        if(this.state.deleteField[i].id === this.state.editData[k].id){
                            this.state.editData.splice(k,1);
                            break;
                        }
            
                    }
                }

                contactsData.field = JSON.parse(JSON.stringify(this.state.editData));

                contactsData.field = contactsData.field.concat(res.data.add)

                this.setState({editMode:false,contactsData:contactsData,editData:{},addField:[],deleteField:[],errorMessage:""})
                
            }).catch(err => {

            }); 
        }
    }

    updateBasicInfo = () => {
        axios.post(this.host+'/api/kb/article',{_token:this.getCookie("auth"),question:this.currentId,article:this.state.editedData},{withCredentials: true})
        .then(res => {
            this.setState({editMode:false});
            this.loadContacts();
        });  
    }

    setEditData = (value,param,i) => {
        let editData = this.state.editData;
        if(!editData[i].original_data){
            editData[i].original_data = editData[i].contacts_value;
        }
        editData[i].contacts_value = value;
        this.setState({editData:editData});
    }

    closeCallback = (res) => {
        console.log("closed");
    }

    inputCallback = (res) => {
        this.setState({input:res.input});
    }

    linkProfile = () => {
        axios.post(this.host+'/api/contacts/link',{_token:this.getCookie("auth"),id:this.state.contactsData.id,type:this.state.linkType,data:this.state.input},{withCredentials: true})
        .then(res => {
 
            // setTeam(res.data);
            this.closeModal();
            this.setState({errorMessage:""});
            this.loadContacts();
            
        }).catch(err => {
            this.setState({errorMessage:err.response.data.description});   
        }); 
    }

    openButton = () => {
        return <button className='button-new' onClick={this.linkProfile}>Add</button>;
    }

    extFormUpdated = (form) => {


        let editData = this.state.editData;
        let deleteField = [];
        let addField = [];

        Object.keys(form).map((key,s) => {
            let found = false;
            for(let i = 0; i < editData.length;i++){
              
                if(editData[i].contacts_field === key){
                    found = true;
                    if(form[key].ts){
                        if(!editData[i].original_data){
                            editData[i].original_data = parseInt(editData[i].contacts_value);
                        }
                        editData[i].contacts_value = form[key].ts;
                    }
                    else if(form[key].value){
                        if(!editData[i].original_data){
                            editData[i].original_data = editData[i].contacts_value;
                        }
                        editData[i].contacts_value = form[key].value;
                    }
                
                }
            }
            if(!found){
                addField.push({name:key,value:{value:form[key].value ? form[key].value : (form[key].ts ? form[key].ts : ""),type:form[key].value ? "text" : (form[key].ts ? "date" : "text")}})
            }
        })
        let found = false;
        for(let i = 0; i < editData.length;i++){
     
            if(form[editData[i].contacts_field]){

  
                found = true;
            }
            else{
                deleteField.push(editData[i]);
            }
        }

        // console.log(addField);
        // console.log(deleteField);


        this.setState({editData:editData,addField:addField,deleteField:deleteField});

        this.setState({extForm:form},()=>{
 
        });
    }

    setReminder = () => {

    }

    mandatoryField = (field) => {
        this.setState({mandatoryField:field})
    }

  render() {
    return (
      <div className={(this.props.scrollbar? 'overflow-y' : '') + ' full-height full-width padding-left-2x padding-right-2x article-scrollbar-2'}>
        <CustomModal inputCallback={this.inputCallback} errorMessage={this.state.errorMessage} closeModal={e=> this.closeModal = e} openModal={e=> this.openModal = e} optionButton={this.openButton()} closeCallback={this.closeCallback} />
        {
            !this.state.dataLoading && this.state.contactsData ?
                <>
                    <div className='case-desc-header' style={{marginTop:(this.props.match ? '100px' : '0px')}}>
                        {this.props.match ? (this.state.contactsData.sub ? <Link to={'/support/'+this.props.match.params.type+'/'+this.props.match.params.lang+'/'+this.state.contactsData.sub.cat.kb_id}>{this.state.contactsData.sub.cat.description}{" > "}{this.state.contactsData.sub.description}</Link> : null) : null}
                        <h5 className='contact-name'>{this.state.contactsData.question ?? "No name"}</h5>
                        <h6>Updated on {moment.utc(this.state.contactsData.updated_at).local().format("D MMMM YYYY kk:mm")}</h6>
                    </div> 
                    <div className='article-body'>
                        <div className='article-container'>
                            {!this.state.editMode ? <div className='article-wrapper' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.contactsData.article ? this.state.contactsData.article.article : "")}}>                       
                            </div> : null}
                            {this.props.match ? (!this.state.editMode && this.props.match.params.type === 'kb' ? <button className='button-new' onClick={()=>{
                                this.setState({editMode:true,editedData: this.state.contactsData.article ? this.state.contactsData.article.article : ""});
                            }}>Edit</button> : null) : null}
                            {/* <RichTextEditor
                                value={this.state.value}
                                onChange={this.onChange}
                            /> */}
                            {this.state.editMode ? 
                                <Suspense fallback={
                                <div className="block-center preload-wrapper">
                                        <div class="typing">
                                            <span class="typing-circle bouncing"></span>
                                            <span class="typing-circle bouncing"></span>
                                            <span class="typing-circle bouncing"></span>
                                        </div>
                                    </div>
                                }>
                                    <CustomEditor setEditedData={this.setEditedData} data={this.state.editedData}/> 
                                </Suspense>
                             : null}
                            {this.state.editMode ?(<button className='button-new margin-right-1x margin-top-2x' onClick={()=>{this.updateBasicInfo()}}>Save</button>) : null}
                            {this.state.editMode ?(<button className='button-new margin-top-2x' onClick={()=>this.setState({editMode:false})}>Cancel</button>) : null}
                        </div>
                                                       
                    </div> 
                </>
            :
            null
        }
    </div>
    );
  }
}
