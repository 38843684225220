import React, { Component } from 'react'

import axios from 'axios';

import Variable from './../variable';

import Pagination from './../components/layout/Pagination';

import Modal from 'react-modal';

// import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment';
import 'moment-timezone';
// import { maxWidth } from '@mui/system';

import SubCategory from './KB/SubCategory';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook,faFacebookMessenger,faTwitterSquare,faWhatsapp, faLine, faTwitter,faTelegram,faInstagram } from '@fortawesome/free-brands-svg-icons'
import { IconTicket,IconHeadphones,IconPhone, IconPencil,IconGift,IconId,IconSend,IconTool,IconX,IconTrash,IconNotebook } from '@tabler/icons-react';

import { faEnvelope, faComment, faHeadset, faCoffee,faPhone,faRedo,faCrown,faShareSquare,faTicketAlt,faSmileWink,faFlag,faAt } from '@fortawesome/free-solid-svg-icons';

import { BrowserRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom';

// import CaseForm from './../components/layout/conversation/CaseForm'

import Article from './KB/Article';

const profileModalStyles = {
    content : {
      top                   : 'auto',
      left                  : 'auto',
      right                 : 'auto',
      bottom                : 'auto',
      width:'500px',
      maxHeight:'100vh',
      border: 'none',
      boxShadow: '0 2px 3px 0 rgba(60,64,67,.3),0 6px 10px 4px rgba(60,64,67,.15)',
      borderRadius : '15px',
      padding : '0px'
    },
    overlay : {
      backgroundColor: ('rgba(0,0,0,0.5)')
    }
};   

export default class Knowledgebase extends Component {

    host = Variable.host;

    dateData = {};

    currentType = "";

    constructor(props){
        super(props);
        this.state = {
            tabSelected:"main",
            customTabs:[],
            totalPage:{},
            pageIndex:{},
            loaded:{},
            loading:[],
            editModalShow:false,
            editObj:null,
            editArray:[],
            search:{},
            mode:"Edit",
            additionalData:{},
            contacts:[],
            extForm:{},
            contactsParam:[],
            additionalData:{},
            profile:"",
            contactCreating:false,
            keyword:"",
            extForm2:{},
            mandatoryField:[],
            errorMessage:"",
            salutation:"",
            filterInit:{},
            totalCount:0,
            currentPage:0,
            dataPerPage:0,
            editMode:false,
            searchResult:[],
            searched:false,
            isDeleted:false,
            uid:"",
            kbLoading:false,
            searching:false,
            kbConfig:{},
            category:"",
            showSearch:false
        }
        this.modalRef = React.createRef();
    }

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
      }  

      searchKB = () => {
            this.setState({searchResult:[],searching:true,searched:this.state.keyword !== ""},()=>{
                axios.get(this.host+'/api/kb/search', {params:{_token:this.getCookie("auth"),keyword:this.state.keyword,type:this.props.match.params.type}},{withCredentials: true})
                .then(res => {
        
                this.setState({searchResult:res.data,searching:false})
                })  
            })
      }

      listContacts = () => {
        this.setState({contacts:[],kbLoading:true},()=>{
            axios.get(this.host+'/api/kb/category', {params:{_token:this.getCookie("auth"),type:this.props.match.params.type}},{withCredentials: true})
            .then(res => {
    
            this.setState({contacts:res.data,kbLoading:false})
            })  
        });
      }

      componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleClickOutside);
      }

    componentDidMount(){
        this.currentType = this.props.match.params.type;
        if(!this.currentType){
            return false;
        }
        axios.get(this.host+'/api/contacts/custom/list', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
        .then(res => {
  
          this.setState({customTabs:res.data})
        })      
        this.searchKB();
        this.listContacts();
        document.addEventListener('mousedown', this.handleClickOutside);
  
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }  

    setWrapperRef2 = (node) => {
        this.wrapperRef2 = node;
    } 

    setWrapperRef3 = (node) => {
        this.wrapperRef3 = node;
    } 

    componentDidUpdate(){
        if(this.state.isDeleted){
            this.setState({isDeleted:false});
        }
        if(this.currentType !== this.props.match.params.type){
            this.currentType = this.props.match.params.type;
            this.listContacts();
            
            this.setState({editMode:false,editModalShow:false,searchResult:[],searched:false,keyword:""},()=>{
                this.searchKB();
            });
        }
    }

    indexChanged = (index) => {
        let pageIndex = this.state.pageIndex;
        pageIndex[this.state.tabSelected] = index;
        this.setState({pageIndex:pageIndex},()=>{
            if(!this.state.loading.includes(this.state.tabSelected) && this.state.loaded[this.state.tabSelected]){
                let loading = this.state.loading;
                loading.push(this.state.tabSelected)
                this.setState({loading:loading},()=>{
                    axios.get(this.host+'/api/contacts/custom', {params:{_token:this.getCookie("auth"),id:this.state.tabSelected,index:this.state.pageIndex[this.state.tabSelected],search:this.state.search}},{withCredentials: true})
                    .then(res => {
                        if(res.data.total){
                            let loading = this.state.loading;
                            let index = loading.indexOf(this.state.tabSelected);
                            loading.splice(index,1);
                            let totalPage = this.state.totalPage;
                            totalPage[this.state.tabSelected] = res.data.total;
                            // let pageIndex = this.state.pageIndex;
                            // pageIndex[type] = 0;
                            let loaded = this.state.loaded;
                            if(res.data.data.total){
                                loaded[this.state.tabSelected] = [];
                            }
                            else{
                                loaded[this.state.tabSelected] = res.data.data;
                            }
                            this.setState({loading:loading,totalPage:totalPage,pageIndex:pageIndex,loaded:loaded});
                        }
                      if(res.data.data.ErrorMessage){
                            alert(res.data.data.ErrorMessage);
                            let loading = this.state.loading;
                            let index = loading.indexOf(this.state.tabSelected);
                            loading.splice(index,1);
                            this.setState({loading:loading})
                        }
                    })                     
                })
            }
        })

    }

    indexChanged2 = (index) => {
        
        this.setState({currentPage:index},()=>{
            this.listContacts();
        })
    }

    tabSwitch = (type,refresh = false) => {
        this.setState({tabSelected:type});
        if((!this.state.loading.includes(type) && !this.state.loaded[type]) || refresh){
            let loading = this.state.loading;
            loading.push(type)
            this.setState({loading:loading},()=>{
                let start = this.state.customTabs.filter(item => item.id === type)[0].start_index;
                axios.get(this.host+'/api/contacts/custom', {params:{_token:this.getCookie("auth"),id:type,index:start,search:this.state.search}},{withCredentials: true})
                .then(res => {
                    if(res.data.total){
                        let loading = this.state.loading;
                        let index = loading.indexOf(type);
                        loading.splice(index,1);
                        let totalPage = this.state.totalPage;
                        totalPage[type] = res.data.total;
                        let pageIndex = this.state.pageIndex;
                        pageIndex[type] = start;
                        let loaded = this.state.loaded;
                        if(res.data.data.total){
                            loaded[this.state.tabSelected] = [];
                        }
                        else{
                            loaded[this.state.tabSelected] = res.data.data;
                        }
                        this.setState({loading:loading,totalPage:totalPage,pageIndex:pageIndex,loaded:loaded});
                    }
                  if(res.data.data.ErrorMessage){
                        alert(res.data.data.ErrorMessage);
                        let loading = this.state.loading;
                        let index = loading.indexOf(this.state.tabSelected);
                        loading.splice(index,1);
                        this.setState({loading:loading})
                    }                  
                })                     
            })
        }
    }


    findPath = function(o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }


    getPageCount = () => {
        // console.log(Math.floor(parseInt(this.state.totalPage[this.state.tabSelected]) / this.state.customTabs[this.state.tabSelected].data_per_page));
        return Math.ceil(parseInt(this.state.totalPage[this.state.tabSelected]) / this.state.customTabs.filter(item => item.id === this.state.tabSelected)[0].data_per_page);
    }

    getPageCount2 = () => {
        // console.log(Math.floor(parseInt(this.state.totalPage[this.state.tabSelected]) / this.state.customTabs[this.state.tabSelected].data_per_page));
        return Math.floor(this.state.totalCount / this.state.dataPerPage);
    }

    afterOpenModal = () => {

    }
    closeModal = () => {
        this.setState({editModalShow:false,errorMessage:"",extForm:{},profile:"",additionalData:{},salutation:""})
    }

    saveContacts = () => {
        if(this.state.mode === "Edit"){
            let value = {};
            for(let i = 0; i < this.state.editArray.length; i++){
                value[this.state.editArray[i].key] = this.state.editArray[i].value;
            }
            axios.post(this.host+'/api/contacts/custom/save', {_token:this.getCookie("auth"),contactsId:this.state.tabSelected,value:value},{withCredentials: true})
            .then(res => {
                this.indexChanged(this.state.pageIndex[this.state.tabSelected]);
                this.setState({editModalShow:false})
            //   this.setState({customTabs:res.data})
            })   
            .catch(err=>{
                alert(JSON.stringify(err.response.data))
            }); 
        }  
        else{

            if(this.state.profile === ""){
                this.setState({errorMessage:"Please fill in the name."})
                this.modalRef.current.scrollTo(0, 0);
                return false;
            }

            let additionalData = this.state.additionalData;
            Object.keys(this.state.extForm).map((obj) => {
                if(this.state.extForm[obj].value){
                    additionalData[obj] = {value:this.state.extForm[obj].value,type:"text"};
                }
                else if(this.state.extForm[obj].ts){
                    additionalData[obj] = {value:this.state.extForm[obj].ts,type:"date"};
                }
                else{
                    additionalData[obj] = {value:this.state.extForm[obj],type:"text"};
                }
            })


            let missingField = false;
            let missingFieldName = "";
            for(let i = 0; i < this.state.mandatoryField.length; i++){
                if(!this.state.extForm[this.state.mandatoryField[i]]){
                    if(missingFieldName === ""){
                        missingFieldName += this.state.mandatoryField[i];
                    }
                    else{
                        missingFieldName += ", "+this.state.mandatoryField[i];
                    }
                    missingField = true;
    
                    
                }
    
                
            }
            if(missingField){
                missingFieldName += " is required";
                this.setState({errorMessage:missingFieldName});
                this.modalRef.current.scrollTo(0, 0);
                return false;
            }

            this.setState({contactCreating:true},()=>{
                axios.post(this.host+'/api/kb/category/add', {_token:this.getCookie("auth"),description:this.state.profile,additionalData:additionalData,sort:this.state.salutation,language:"en"},{withCredentials: true})
                .then(res => {
                    this.listContacts();
                    // this.indexChanged(this.state.pageIndex[this.state.tabSelected]);
                    this.setState({editModalShow:false,contactCreating:false,errorMessage:"",extForm:{},profile:"",additionalData:{}})
                //   this.setState({customTabs:res.data})
                })  
                .catch(err=>{
                    alert(JSON.stringify(err.response.data))
                    this.setState({contactCreating:false})
                }); 
            })
           
            
            // axios.post(this.host+'/api/contacts/custom/save', {_token:this.getCookie("auth"),contactsId:this.state.tabSelected,additionalData:this.state.additionalData},{withCredentials: true})
            // .then(res => {
            //     this.indexChanged(this.state.pageIndex[this.state.tabSelected]);
            //     this.setState({editModalShow:false})
            // //   this.setState({customTabs:res.data})
            // })  
            // .catch(err=>{
            //     alert(JSON.stringify(err.response.data))
            // });    
        }      
    }

    editContacts = (obj,edit) => {
        let editArray = [];

        console.log(edit);
        
        for(let i = 0; i < edit.fields.length; i++){
            let newObj = {};
            newObj.key = edit.fields[i].key;
           newObj.value = this.findPath(obj,edit.fields[i].path);
           if(edit.fields[i].hidden){
               newObj.hidden = edit.fields[i].hidden;
           }
           editArray.push(newObj);
        }
        this.setState({mode:"Edit",editModalShow:true,editArray:editArray})
    }

    setEditValue = (index,value) => {
        let editArray = this.state.editArray;
        console.log(index);
        editArray[index].value = value;
        this.setState({editArray:editArray});
    }

    searchInputValue = (key,value) => {
        let search = this.state.search;
        search[key] = value;
        this.setState({search:search})
    }

    extFormUpdated = (form) => {
        this.setState({extForm:form});
    }

    extFormUpdated2 = (form) => {
        this.setState({extForm2:form,filterInit:form});
    }

    mandatoryField = (field) => {
        this.setState({mandatoryField:field})
    }

    editCategory = () => {

        if(this.state.editDescription === ""){
            this.setState({errorMessage:"Please enter category name."})
            this.modalRef.current.scrollTo(0, 0);
            return false;
        }


        axios.post(this.host+'/api/kb/category/edit',{_token:this.getCookie("auth"),description:this.state.editDescription,id:this.state.editId,sort:this.state.editSort},{withCredentials: true})
        .then(res => {
            if(res.data.result === "success"){
                
                this.closeModal();
                this.listContacts();
            }
        });  
    }

    handleClickOutside = (event) => {
  
        if(this.wrapperRef){
        
            if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.wrapperRef2 && !this.wrapperRef2.contains(event.target) && this.wrapperRef3 && !this.wrapperRef3.contains(event.target) ) {
                this.setState({showSearch:false});
            //this.props.change("emojiPickerShow",false);

            }
        }
        
    }  

    

    deleteCategory = () => {
        axios.delete(this.host+'/api/kb/category/delete',{params:{_token:this.getCookie("auth"),id:this.state.editId}},{withCredentials: true})
        .then(res => {
            if(res.data.result === "success"){
                
                this.closeModal();
                this.listContacts();

                if(this.state.uid === this.props.match.params.id){
                    this.setState({isDeleted:true});
                }
            }
        })
        .catch((err)=>{
            if(err.response.data){
                this.setState({errorMessage:err.response.data.description});
            }
            else{
                this.setState({errorMessage:"Unknown error occured."});
            }
        });  
    }


    render() {

        if(this.state.isDeleted){
            return <Redirect to={'/support/'+this.props.match.params.type+'/en'}/>
        }

        return (
            <div className={(this.state.kbConfig?.id || this.state.category !== "" ? "flex-col" : "  overflow-y-scroll")+" full-height"+(this.props.match.params.id ? " full-width " : "  full-width")} style={{minWidth:'1px'}}>
                    <Modal
                    isOpen={this.state.editModalShow}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={profileModalStyles}
                    contentLabel="Example Modal"
                    ariaHideApp={false} 
                    closeTimeoutMS={200}       
                                                    
                    className={""+(this.props.dark ? "dark" : null)}   

                    
                >
                <div className="flex-col" style={{width:'100%',maxHeight:"100vh"}}>
                    <div className="ng-modal-main-header" style={{paddingTop:"30px",paddingBottom:0}}>
                        <div className="full-width flex-row valign-wrapper margin-bottom-1x">
                            <h5 className="bold-text-2">{this.state.mode}</h5>
                            
                        </div>
                    
                    </div>   
                    <div ref={this.modalRef} className="ng-modal-main-body padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x flex-col full-height">
                        {
                            this.state.mode === "Delete Category" || this.state.mode === "Edit Category" ?
                            (
                                this.state.errorMessage !== "" ? 
                                <h6 className="red lighten-3 callout-box white-text">{this.state.errorMessage}</h6>
                                :
                                null
                            )
                            :
                            null
                            
                        }                        
                        {
                            
                            this.state.mode === "Edit Category" ? 
                            <>
                                <div>
                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <span>Category Name</span>
                                        <input className={"browser-default " + (this.state.invalidName ? "invalid" : "")} type="text" onChange={ e => this.setState({editDescription:e.target.value}) } value={this.state.editDescription}/>
                                    </label>
                                    
                                </div>
                                <div>
                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <span>Sort (Optional)</span>
                                        <input className={"browser-default " + (this.state.invalidName ? "invalid" : "")} type="text" onChange={ e => {this.setState({editSort:e.target.value})} } value={this.state.editSort}/>
                                    </label>
                                </div>
                            </>
                            :
                            null
                        }
                        {
                            this.state.mode === "Add" ? (
                            <>
                            {
                                this.state.errorMessage !== "" ? 
                                <h6 className="red lighten-3 callout-box white-text">{this.state.errorMessage}</h6>
                                :
                                null
                            }
                            {
                            this.state.customTabs.filter(item => item.id === this.state.tabSelected).length > 0 ? (
                                this.state.customTabs.filter(item => item.id === this.state.tabSelected)[0].contacts_param !== "" ?
                                (this.state.customTabs.filter(item => item.id === this.state.tabSelected)[0].contacts_param.map((obj,i)=>(
                                    <div key={i}>
                                        {
                                            obj.type ? 

                                            (
                                                obj.type === "date" ? 
                                                (
                                                    <div>
                                                        <h6>{obj.placeholder}</h6>
                                                        {/* <DatePicker dateFormat="yyyy-MM-dd"  selected={ this.dateData[obj.key] ? this.dateData[obj.key] : ""} onChange={date => {
                                                            let additionalData = this.state.additionalData;
                                                          
                                                            additionalData[obj.key] = moment(date).isValid() ? moment(date).format("YYYY-MM-DD") : "";
                                                            this.dateData[obj.key] = date;
                                                            // console.log(date.getTime());
                                                            this.setState({additionalData:additionalData});
                                                        }}
                                                        popperPlacement="right-start"
                                                        /> */}
                                                    </div>
                                                )
                                                :
                                                null
            
                                            )
                                            :

                                            <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                            <input className={"browser-default "} type="text" onChange={ e => {
                                                let additionalData = this.state.additionalData;
                                                additionalData[obj.key] = e.target.value;
                                                this.setState({additionalData:additionalData});
                                            } } />
                                            <span>{obj.placeholder}</span>
                                            </label>
                                     
                                         }
                                         
                                    </div>
                                    ))
                                )
                                :
                                null
                            ):null
                            }
                            <div>
                                <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                    <span>Category Name</span>
                                    <input className={"browser-default " + (this.state.invalidName ? "invalid" : "")} type="text" onChange={ e => this.setState({profile:e.target.value}) } value={this.state.profile}/>
                                </label>
                                
                            </div>
                            <div>
                                <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                    <span>Sort (Optional)</span>
                                    <input className={"browser-default " + (this.state.invalidName ? "invalid" : "")} type="text" onChange={ e => {this.setState({salutation:e.target.value})} } value={this.state.salutation}/>
                                </label>
                            </div>
                            {(this.state.contactsParam.map((obj,i)=>(
                                    <div key={i}>
                                        {
                                            obj.type === "date" ? 

                                     
                                                    <div>
                                                        <h6 className='input-label'>{obj.field_name}</h6>
                                                        {/* <DatePicker dateFormat="yyyy-MM-dd"  selected={ this.dateData[obj.fields_param] ? new Date(this.dateData[obj.fields_param].value*1000) : ""} onChange={date => {
                                                            let additionalData = this.state.additionalData;
                                                          
                                                            additionalData[obj.fields_param] ={value:date.getTime()/1000,type:obj.type};
                                                            this.dateData[obj.fields_param] = {value:date.getTime()/1000,type:obj.type};
                                                            // console.log(date.getTime());
                                                            this.setState({additionalData:additionalData});
                                                        }}
                                                        popperPlacement="right-start"
                                                        /> */}
                                                    </div>
                                                
                                   
                                            :

                                            <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                            <span>{obj.field_name}</span>
                                            <input className={"browser-default "} type="text" onChange={ e => {
                                                let additionalData = this.state.additionalData;
                                                additionalData[obj.fields_param] = {value:e.target.value,type:obj.type};
                                                this.setState({additionalData:additionalData});
                                            } } />
                                            </label>
                                     
                                         }
                                    </div>
                                    ))
                                )}
                            {/* {this.props.extensions.length > 1 ? <CaseForm mandatoryField={this.mandatoryField} updateForm={this.extFormUpdated} formData={this.props.extensions[1].form.fields}/> : null} */}
                            </>
                     
                            )
                            :
                            null
                        }
                        {/* {
                            this.state.mode === "Filter" ? (
                                <div>
                                    {this.props.extensions[1] ? <CaseForm type="filter" updateForm={this.extFormUpdated2} initValue2={this.state.filterInit} formData={this.props.extensions[1].form.fields}/> : null}
                                </div>
                            )
                            :
                            null
                        } */}
                        </div>  
                    <div className="ng-modal-main-footer right-text">                                          
                        <button className="btn-flat ng-close-btn waves-effect waves-dark" onClick={this.closeModal}>close</button>
                        {(this.state.mode === "Filter" ? <button className="button-new margin-left-1x" onClick={()=>{this.setState({filterInit:{},extForm2:{}})}}>Reset</button> : null)}
                        {(this.state.mode === "Edit Category" ? <button className="btn waves-effect waves-light margin-left-1x" onClick={this.editCategory}>Save</button> : null)}
                        {(this.state.mode === "Delete Category" ? <button className="btn waves-effect waves-light margin-left-1x red" onClick={this.deleteCategory}>Delete</button> : null)}
                        {(this.state.mode === "Edit" ? <button className="btn waves-effect waves-light margin-left-1x" onClick={this.saveContacts}>Save</button> : null)}
                        {(this.state.mode === "Filter" ? <button className="btn waves-effect waves-light margin-left-1x" onClick={()=>{this.setState({currentPage:0},()=>{this.listContacts()});this.closeModal()}}>Filter Now</button> : null)}
                        {(this.state.mode === "Add" ? <button className={"btn waves-effect waves-light margin-left-1x"+(this.state.contactCreating ? " disabled" : "")} onClick={this.saveContacts}>Save</button> : null)}
                    </div>            
                </div>                                           
            </Modal>
                <div className={"kb-header-background flex-col full-width flex-no-shrink"+((this.state.kbConfig.id !== "" && this.state.kbConfig.id !== undefined) || this.state.category !== "" ? " hide" : "")} style={{position:"relative"}}>
                    {/* <span className="bold-text-2 full-width padding-left-2x ellipsis-text chat-list-title-text bold-text-2 margin-top-2x margin-bottom-2x flex-no-shrink">Contacts</span> */}
                                      
                
                <div className="mid-text">
                <div className="relative-content padding-left-2x padding-right-2x padding-top-2x">
                {/* {
                    this.state.customTabs.filter(item => item.id === this.state.tabSelected).length > 0 ?
                   (this.state.customTabs.filter(item => item.id === this.state.tabSelected)[0].contacts_param !== "" ?
                    (this.state.customTabs.filter(item => item.id === this.state.tabSelected)[0].contacts_param.length > 0 ?<button className="button-new" onClick={()=>{this.setState({mode:"Add",editModalShow:true})}}>Add</button> : null)
                    :
                    null
                   ):null
                } */}
                {this.props.match.params.type !== "ticket" && this.props.match.params.type !== "livechat" ? <h5 className="margin-bottom-2x full-width crm-title" style={{marginTop:'100px'}}>{this.props.match.params.type === 'kb' ? "Knowledgebase" : "SuperOne Support"}</h5> : null}
                {this.state.customTabs.length > 0 ?
                    <div className="custom-tab margin-bottom-2x flex-no-shrink">
                            <div className={"custom-tab-item unselectable waves-effect "+(this.state.tabSelected === "main" ? "clicked" : "")+(this.props.dark ? " dark" : "")} onClick={()=>{this.setState({tabSelected:"main"})}}>Main</div>
                            {
                                this.state.customTabs.map((obj,i) => (
                                    <div key={obj.id} className={"custom-tab-item unselectable waves-effect "+(this.state.tabSelected === obj.id ? "clicked" : "")+(this.props.dark ? " dark" : "")} onClick={()=>{this.tabSwitch(obj.id)}}>{obj.name}</div> 
                                ))
                            }
                                                     
                    </div>  : null }  
                {
                    this.state.tabSelected === "main" ?
                    <input ref={this.setWrapperRef2} className="browser-default tinted-input margin-bottom-1x" type="text" style={{maxWidth:'500px'}} value={this.state.keyword} onChange={(e)=>{this.setState({keyword:e.target.value})}} onFocus={()=>{this.setState({showSearch:true})}} placeholder="Search Article" />
                    :
                    null
                }
                {this.state.tabSelected === "main" ? 
                <div className='margin-bottom-2x' ref={this.setWrapperRef3}>    
                    <button className={"button-new "+(this.props.match.params.type === 'kb' ? 'margin-right-1x' : '')} onClick={()=>{this.setState({currentPage:0},()=>{this.searchKB()})}}>Search</button>
                    {this.props.match.params.type === 'kb' ? <button className="button-new margin-right-1x" onClick={()=>{this.setState({mode:"Add",editModalShow:true})}}>Add</button>: null}
                    {/* <button className="button-new" onClick={()=>{this.setState({mode:"Filter",editModalShow:true})}}>Advanced Filter{Object.keys(this.state.extForm2).length > 0 ? " ("+Object.keys(this.state.extForm2).length+")" : ""}</button> */}
                </div> : null}
                <div ref={this.setWrapperRef} className={'kb-search-container'+(this.state.showSearch ? '' : ' hide')}>
                {
                    <h6 className='bold-text-2'>{!this.state.searched ? "Recent Articles" : "Search Result"}</h6>
                }
                {
                    this.state.searching ? 
                        <div className="block-center preload-wrapper">
                            <div className="typing">
                                <span className="typing-circle bouncing"></span>
                                <span className="typing-circle bouncing"></span>
                                <span className="typing-circle bouncing"></span>
                            </div>
                        </div>
                        :
                        null
                }                  
                {
                    this.state.searchResult.length === 0 && !this.state.searching ?
                    <h6>No result</h6>
                    :
                    null
                }
                {
                    this.state.searchResult.map((obj,i)=> (
                        <Link onClick={(e)=>{
                            if(this.props.match.params.type === "ticket" || this.props.match.params.type === "livechat"){
                                e.preventDefault();
                                this.setState({kbConfig:{type:this.props.match.params.type,lang:"en",sub:+obj.sub?.cat.kb_id,id:obj.question_id}});
                            }
                        }} key={obj.id} to={'/support/'+this.props.match.params.type+'/'+this.props.match.params.lang+'/'+obj.sub?.cat.kb_id+'/'+obj.question_id}>
                            <div  className={"contacts-ticket-item"}>
                                <div>
                                    {/* <p className="grey-text bold-text activity-emotion-margin-2 timeline-date"><Moment unix format="D MMMM YYYY kk:mm">{moment.utc(obj.created_at).unix()}</Moment></p> */}
                                    <div className="flex-row activity-name-holder">
                                        {/* <FontAwesomeIcon className="linked margin-left-1x show-tooltip margin-right-1x" style={{fontSize:'20px'}} icon={(obj.channel === "FB_PAGE_POST" ? faFacebook : (obj.channel === "FB_PM" ? faFacebookMessenger : (obj.channel === "TW_DM" || obj.channel === "TW_MENTION" ? faTwitterSquare : (obj.channel === "LINE_DM" ? faLine :  (obj.channel === "EMAIL" ? faEnvelope : (obj.channel === "WAUCHAT" ? faComment : (obj.channel === "LIVECHAT" ? faHeadset : (obj.channel === "MANUAL" ? faCoffee : (obj.channel === "WHATSAPP" ? faWhatsapp : faCoffee)))))))))}/>                             */}
                                        <div className="activity-icon">
                                            <IconNotebook 
                                                size={20} // set custom `width` and `height`
                                                // set `stroke` color
                                                stroke={2}  // set `stroke-width`
                                                strokeLinejoin="miter" // override other SVG props
                                                className=''
                                            />          
                                        </div>                                          
                                        <h6>{obj.question}</h6>
                                        {/* <h6 className="ellipsis-text">{
                                        obj.channel === "FB_PAGE_POST" ? "Facebook Page" : 
                                        (obj.channel === "FB_PM" ? "Facebook Messenger" : 
                                        (obj.channel === "TW_DM" ? "Twitter Direct Message" : 
                                        (obj.channel === "TW_MENTION" ? "Twitter Mentions" : 
                                        (obj.channel === "LINE_DM" ? "Line Direct Messages" : 
                                        (obj.channel === "EMAIL" ? "Email" : 
                                        (obj.channel === "WAUCHAT" ? "Chat Bot" : 
                                        (obj.channel === "LIVECHAT" ? "Live Chat" : 
                                        (obj.channel === "MANUAL" ? "Manual" : 
                                        (obj.channel === "WHATSAPP" ? "WhatsApp" : 
                                        (obj.channel === "TELEGRAM" ? "Telegram" : 
                                        "Other"))))))))))}</h6> */}
                                    </div>
                                </div>
                            </div> 
                        </Link>
                    ))
                }
                </div>
                {/* {this.state.tabSelected === "main" ? <Pagination startIndex={0} indexChanged={this.indexChanged2} index={this.state.currentPage} total={this.getPageCount2()} />:null} */}
                    {this.state.category === ""  ? <div>
                        <h5 className='bold-text-2 inline-block small-font unselectable'>Category</h5>
                        {this.props.match.params.type !== "ticket" && this.props.match.params.type !== "livechat" && !this.state.editMode && this.props.match.params.type === 'kb' ? <div className={"icon-button-hover-5 inline-block  margin-right-1x valign-wrapper"} onClick={()=>{this.setState({editMode:true})}}>
                            <IconTool 
                                size={20} // set custom `width` and `height`
                                // set `stroke` color
                                stroke={2}  // set `stroke-width`
                                strokeLinejoin="miter" // override other SVG props
                                className=''
                            />   
                        </div> : null}
                        {this.props.match.params.type !== "ticket" && this.props.match.params.type !== "livechat" && this.state.editMode ? <div className={"icon-button-hover-5 inline-block  margin-right-1x valign-wrapper"} onClick={()=>{this.setState({editMode:false})}}>
                            <IconX 
                                size={20} // set custom `width` and `height`
                                // set `stroke` color
                                stroke={2}  // set `stroke-width`
                                strokeLinejoin="miter" // override other SVG props
                                className=''
                            />   
                        </div> : null}
                    </div> : null}
                </div>
                {this.state.category === ""  ? <div className='kb-container'>
                    <div className='kb-category-list-container'>
                    {
                        this.state.tabSelected === "main" ? 
                        this.state.contacts.map((obj,i)=>(
                            <>
                                <Link onClick={(e)=>{
                            if(this.props.match.params.type === "call" || this.props.match.params.type === "ticket" || this.props.match.params.type === "livechat"){
                                e.preventDefault();
                                this.setState({category:obj.kb_id});
                            }
                        }}  key={obj.uid} to={'/support/'+this.props.match.params.type+'/'+this.props.match.params.lang+'/'+obj.kb_id}>
                            <div className={'unselectable pill-kb-category linked'+( this.props.match.params.id === obj.kb_id ? ' selected' : '')}>
                            {obj.description}
                            </div>
                                </Link>
                                {this.props.match.params.type !== "livechat" && this.props.match.params.type !== "ticket" && this.state.editMode ? 
                                    <div className='flex-col'>
                                        <div onClick={()=>{this.setState({mode:"Edit Category",editModalShow:true,editId:obj.id,editDescription:obj.description,editSort:obj.sort})}}>
                                            <IconPencil 
                                                size={20} // set custom `width` and `height`
                                                // set `stroke` color
                                                stroke={2}  // set `stroke-width`
                                                strokeLinejoin="miter" // override other SVG props
                                                className='linked unselectable'
                                            />  
                                        </div>
                                        <div onClick={()=>this.setState({mode:"Delete Category",editModalShow:true,editId:obj.id,uid:obj.kb_id})}>
                                            <IconTrash 
                                                size={20} // set custom `width` and `height`
                                                // set `stroke` color
                                                stroke={2}  // set `stroke-width`
                                                strokeLinejoin="miter" // override other SVG props
                                                className='linked unselectable'
                                                
                                            />  
                                        </div>
                                    </div>
                                    : null} 
                            </>
                        ))
                        :
                        null
                    }
                    </div>
                </div> : null}
                    {
                        this.state.kbLoading ? 
                            <div className="block-center preload-wrapper">
                                <div className="typing">
                                    <span className="typing-circle bouncing"></span>
                                    <span className="typing-circle bouncing"></span>
                                    <span className="typing-circle bouncing"></span>
                                </div>
                            </div>
                            :
                            null
                    }  
                    <div className="contacts-search-container">
                    {
                        this.state.customTabs.filter(item => item.id === this.state.tabSelected).length > 0 ?
                        <React.Fragment>
                        {
                            this.state.customTabs.filter(item => item.id === this.state.tabSelected)[0].search_param.map((obj,i) => (
                                <input key={i} className="browser-default tinted-input margin-bottom-1x" type="text" placeholder={obj.placeholder} value={this.state.search[obj.key] || ""} onChange={e=>{this.searchInputValue(obj.key,e.target.value);}}/>
                            ))
                        }
                        {
                            this.state.customTabs.filter(item => item.id === this.state.tabSelected)[0].search_param.length > 0 ?
                            <button className="button-new" onClick={()=>{this.tabSwitch(this.state.tabSelected,true)}}>Search</button>
                            :
                            null
                        }
                        </React.Fragment>
                        :
                        null
                    }
                    </div>    
                    {
                        this.state.customTabs.filter(item => item.id === this.state.tabSelected).length > 0 ? 
                        <Pagination startIndex={this.state.customTabs.filter(item => item.id === this.state.tabSelected)[0].start_index} indexChanged={this.indexChanged} index={this.state.pageIndex[this.state.tabSelected]} total={this.getPageCount()} />
                        :
                        null
                    }
                    {
                        this.state.loaded[this.state.tabSelected] ?
                            this.state.loaded[this.state.tabSelected].map((obj,i)=>(
                                <div className="contacts-item" key={obj.id}>
                                    {
                                        this.state.customTabs.filter(item => item.id === this.state.tabSelected).length > 0 ?
                                        this.state.customTabs.filter(item => item.id === this.state.tabSelected)[0].display.map((obj2,i2)=>(
                                            <h6 key={i2} className={obj2.bold ? "bold-text" : ""}>{this.findPath(obj,obj2.path)}</h6>
                                        ))
                                        :
                                        null
                                    }
                                    {
                                        this.state.customTabs.filter(item => item.id === this.state.tabSelected)[0].edit ?
                                        
                                        // obj.
                                        <div>
                                            <div className="icon-button-hover-2 inline-block" onClick={()=>this.editContacts(obj,this.state.customTabs.filter(item => item.id === this.state.tabSelected)[0].edit)}>
                                                <IconPencil 
                                                size={18} // set custom `width` and `height`
                                                // set `stroke` color
                                                stroke={3}  // set `stroke-width`
                                                strokeLinejoin="miter" // override other SVG props
                                                /> 
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    {/* <h6>{obj.name1}</h6>
                                    <h6>{obj.cell.Mobile}</h6> */}
                                </div>
                            ))
                        :
                        null
                    }
                    {
                        this.state.customTabs.filter(item => item.id === this.state.tabSelected).length > 0 ? 
                        <Pagination startIndex={this.state.customTabs.filter(item => item.id === this.state.tabSelected)[0].start_index} indexChanged={this.indexChanged} index={this.state.pageIndex[this.state.tabSelected]} total={this.getPageCount()} />
                        :
                        null
                    }              
                </div>
                </div>
                {
                    (this.state.kbConfig.id !== "" && this.state.kbConfig.id !== undefined) || this.state.category !== "" ?
                        <div className='kb-action'>
                            <button className='button-new' onClick={()=>{
                                if(this.state.kbConfig.id){
                                    this.setState({kbConfig:{}})
                                }
                                else if(this.state.category !== ""){
                                    this.setState({category:""});
                                }
                            }}>Back</button>
                        </div>
                    :
                    null
                }
                {
                    this.state.category !== "" && !this.state.kbConfig.id ? 
                        <SubCategory openArticle={(id)=>{
                            this.setState({kbConfig:{type:this.props.match.params.type,lang:"en",sub:+this.state.category,id:id}});
                        }} category={this.state.category} type={"ticket"} extensions={this.props.extensions} dark={this.props.dark}/>
                    :
                    null
                }
                <Route exact path={['/support/:type/:lang/:id']} render={props => (
                                <SubCategory {...props} extensions={this.props.extensions} dark={this.props.dark}/>
                            )}/>     
                <Route exact path={['/support/:type/:lang/:id/:question']} render={props => (
                    <Article {...props} extensions={this.props.extensions} dark={this.props.dark}/>
                )}/>  

                {
                    this.state.kbConfig.id !== "" && this.state.kbConfig.id !== undefined  ?
                    <Article scrollbar={true} config={this.state.kbConfig} extensions={this.props.extensions} dark={this.props.dark}/>
                    :
                    null
                }
            </div>
        )
    }
}
