import React, { Component } from 'react'
import Info from '@material-ui/icons/Info';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import { ArrowBack } from '@material-ui/icons';

import Select from 'react-select';

import chroma from 'chroma-js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDesktop } from '@fortawesome/free-solid-svg-icons'

import LabelPicker from './../LabelPicker'
import Color from 'color';
import Title from './Title';
import TranslateButton from './Translate';
import { Translate } from '@material-ui/icons';
import BackButton from './../../BackToHome';
import CustomAudioPlayer from '../CustomAudioPlayer';

import Variable from '../../../variable';

const options = [
  { value: 0, label: 'Open' },
  { value: 1, label: 'Pending' },
  { value: 2, label: 'Resolved' },
];

const dot = (data) => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: data.value === 0 ? '#F44336' : (data.value === 1 ? '#FF9800' : '#4CAF50'),
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const customStyles = {
  menu: styles => ({...styles,margin:'0',borderRadius:'5px',overflow:'hidden'}),
  menuList: styles => ({...styles,backgroundColor:'#fefefe'}),
  valueContainer: styles => ({...styles, height:'30px',color:'#757575'}),
  control: styles => ({ ...styles, backgroundColor: '#fefefe',width:'200px', color:'#757575'}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //console.log(data);
    //const color = chroma(data.color);
    
    const color = chroma('#fefefe');
    return {
      ...styles,

      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#dddddd"
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':hover':{
        ...styles[':hover'],
        backgroundColor: '#eeeeee',
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(1).css()),
      },
    };
  },
  input: (styles, { data }) => ({ ...styles, ...dot(data), height: '30px'}),

  singleValue: (styles, { data }) => ({ ...styles, ...dot(data), color: '#757575' }),
};

const customStylesDark = {
  menu: styles => ({...styles,margin:'0',backgroundColor:'#333333',borderRadius:'5px',overflow:'hidden'}),
  valueContainer: styles => ({...styles, height:'30px',color:'#fefefe'}),
  control: styles => ({ ...styles, backgroundColor: '#333333',width:'200px', color:'#fefefe'}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //console.log(data);
    //const color = chroma(data.color);
    
    const color = chroma('#000000');
    return {
      ...styles,

      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#212121"
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':hover':{
        ...styles[':hover'],
        backgroundColor: '#424242',
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(1).css()),
      },
    };
  },
  input: (styles, { data }) => ({ ...styles, ...dot(data), height: '30px'}),
  dropdownIndicator: (styles, { data }) => ({ ...styles, ':hover':{color:'#fefefe'}}),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data), color: '#fefefe' }),
};

export class ConversationHeader extends Component {

    host = Variable.host;

    state = {
      options:[  { value: 0, label: 'Open' },
      { value: 1, label: 'Pending' },
      { value: 2, label: 'Resolved' }],
      labelPickerShown : false,
      selectedOption: null,
      title : "Select",
        location: [
            {
              id: 0,
              title: 'New York',
              selected: false,
              key: 'location'
            },
            {
              id: 1,
              title: 'Dublin',
              selected: false,
              key: 'location'
            },
            {
              id: 2,
              title: 'Istanbul',
              selected: false,
              key: 'location'
            }
          ],
          status: [
            {
              id: 0,
              title: 'Open',
              selected: false,
              key: 'status'
            },
            {
              id: 1,
              title: 'Pending',
              selected: false,
              key: 'status'
            },
            {
              id: 2,
              title: 'Resolved',
              selected: false,
              key: 'status'
            }
          ]          
    }

    constructor(props){
      super(props);
      this.updateOptions = this.update.bind(this);
      
    }   
    
    componentDidMount(){
      this.props.updateOption(this.updateOptions);

      let status = [  { value: 0, label: 'Open' },
      { value: 1, label: 'Pending' },
      { value: 2, label: 'Resolved' }];

      this.props.customStatus.forEach((item) => {
        let obj = {value: item.status_code, label: item.status_name}
        status.push(obj);
      });
      this.setState({options:status});      
    }

    update = () => {
      let status = [  { value: 0, label: 'Open' },
      { value: 1, label: 'Pending' },
      { value: 2, label: 'Resolved' }];

      this.props.customStatus.forEach((item) => {
        let obj = {value: item.status_code, label: item.status_name}
        status.push(obj);
      });

      this.setState({options:status});
    }

    showPanel = () => {
        this.props.panel(true);
    }

    showLabelPicker = () => {
      if(1){
        this.setState({labelPickerShown:true});
      }
    }


    handleChange = selectedOption => {
      
      this.setState({ selectedOption: selectedOption });
      console.log(`Option selected:`, selectedOption);
      this.props.setTicketStatus(this.props.id,selectedOption.value,selectedOption.label);
    };    

    //single selection dropdown
    resetThenSet = (id, key) => {
      //console.log(id);
        this.props.setTicketStatus(this.props.id,id);
        let temp = JSON.parse(JSON.stringify(this.state[key]));
        temp.forEach(item => item.selected = false);
        temp[id].selected = true;
        this.setState({
          [key]: temp
        });
      }


      //multiple selection dropdown
      toggleSelected = (id, key) => {
        let temp = JSON.parse(JSON.stringify(this.state[key]));
        temp[id].selected = !temp[id].selected;
        this.setState({
          [key]: temp,
          title: temp[id].title
        });
      }    
      
      labelInfo = (id) => {
        let info = this.props.label.filter(label => label.id === id);
        
        if(info.length > 0){
            return info[0];
        }
        else{
            return null;
        }
    }   
    
    saveLabel = (label) => {
      this.setState({labelPickerShown:false});
      this.props.saveLabel(label);
    }

    goBack = () => {
      if(this.props.loadFromHome){
        window.history.back();
      }
    }

    scrollToOffset = (time) => {
      this.props.scrollToOffset2(time)
    }

    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
  }     

    render() {
      const { selectedOption } = this.state;
        return (
          <div className='call-header-wrapper flex-row valign-wrapper'>
          
            <div className={" flex-row call-header z-depth-1 flex-no-shrink"}>
            <div className='relative-content'>
            {this.props.id !== undefined ? <CustomAudioPlayer currentTimeCallBack={this.scrollToOffset} src={this.host+"/api/recording?_token="+this.getCookie("auth")+"&id="+this.props.channelData.id}/> : null}
            </div>
                {/* {(this.props.chatType === "ticket" ? <BackButton loadFromHome={this.props.loadFromHome}/> : null)} */}
                <div data-balloon-pos="down-left" style={{minWidth:"1px"}} className="full-width">
                <Title dark={this.props.dark} title={this.props.name}/>
                
                { 
                0 && this.props.chatType !== "chat" && this.props.channelData.label !== undefined ?
                  (1 ? <div data-balloon-blunt aria-label="Click to manage label" data-balloon-pos="down-left" className="label-holder linked" onClick={this.showLabelPicker} style={{lineHeight:"25px",marginLeft:0}}>
                        {(
                          this.props.channelData.label !== "[]" ?
                            JSON.parse(this.props.channelData.label).map((obj, i) => (
                            (this.labelInfo(obj) !== null ? <span key={i} className="label" style={{color: Color(this.props.dark ? (this.labelInfo(obj)).colorDark :(this.labelInfo(obj)).color).isDark() ? "#fefefe" : "#333333",backgroundColor:(this.props.dark ? (this.labelInfo(obj)).colorDark :(this.labelInfo(obj)).color)}}>{(this.labelInfo(obj)).name}</span> : 
                            <span key={i} className="label">Removed</span>
                            )
                            )
                            )
                            :
                            <span className="label">Unlabeled</span>)}
                      </div> : 
                       <div className="label-holder" style={{lineHeight:"25px",marginLeft:0}}>
                       {(
                         this.props.channelData.label !== "[]" ?
                           JSON.parse(this.props.channelData.label).map((obj, i) => (
                           (this.labelInfo(obj) !== null ? <span key={i} className="label" style={{color: Color(this.props.dark ? (this.labelInfo(obj)).colorDark :(this.labelInfo(obj)).color).isDark() ? "#fefefe" : "#333333",backgroundColor:(this.props.dark ? (this.labelInfo(obj)).colorDark :(this.labelInfo(obj)).color)}}>{(this.labelInfo(obj)).name}</span> : 
                           <span key={i} className="label">Removed</span>
                           )
                           )
                           )
                           :
                           <span className="label">Unlabeled</span>)}
                     </div>
                       )
                    :null}              
                </div>    
                
                {/* <Dropdown
                    title={(this.props.status === 0 ? "Open" : (this.props.status === 1 ? "Pending" : "Closed"))}
                    list={this.state.status}
                    resetThenSet={this.resetThenSet}
                    /> */}
                    
                    {/* <DropdownMultiple
                    titleHelper="Location"
                    title="Select location"
                    list={this.state.location}
                    toggleItem={this.toggleSelected}
                    />                   */}
                {
                  (
                    this.state.labelPickerShown ? 
                      <React.Fragment>
                        <div style={{position:"fixed",left:0,top:0,width:"100%",height:"100%",backgroundColor:"rgba(0,0,0,0.5)",zIndex:999}} onClick={()=>{this.setState({labelPickerShown:false})}}>

                        </div>
                          <div style={{position:"absolute",top:"64px",zIndex:1000}}>
                          <LabelPicker dark={this.props.dark} label={this.props.label} ticketLabel={this.props.channelData.label} saveLabel={this.saveLabel}/>
                          </div>
                      </React.Fragment>
                      :
                      null
                  )
                }
               {(this.props.chatType === "ticket" && this.props.channelData.source_lang !== ""  ? <TranslateButton openTranslate={this.props.openTranslate} currentLang={this.props.channelData.language} sourceLang={ this.props.channelData.source_lang } translateFunction={this.props.translateFunction} dark={this.props.dark}/> : null )} 
                {/* {(this.props.chatType === "chat" && !this.props.onMeeting ?<div data-balloon-blunt aria-label="Meeting" data-balloon-pos="down" className="relative-content icon-button-hover-4">
                    <FontAwesomeIcon className="linked accent-icon-color show-tooltip" icon={faDesktop} onClick={()=>this.props.startMeeting(this.props.id)}/>                      
                </div>  : null )}                     */}
                {(!this.props.onMeeting ? <div onClick={this.showPanel} data-balloon-blunt aria-label="Info" data-balloon-pos="down" className="relative-content icon-button-hover" style={{marginTop:"3px"}}>
                  {
                    this.props.chatInfoShown ?

                    <Info className="linked accent-icon-color show-tooltip"/>                      
                    :
                    <InfoOutlined className="linked accent-icon-color show-tooltip"/>                      
                  }
                </div>  : null)}  
            </div>
          </div>
        )
    }
}

export default ConversationHeader
