import React, { useState,useEffect,useRef } from 'react';
import Variable from './../../variable';
import axios from 'axios';
import BootstrapModal from 'react-bootstrap/Modal';
import { CheckCircleOutline } from '@material-ui/icons';
// import { IconAlertTriangle,IconCopy } from '@tabler/icons';

export default function Security() {

    const host = Variable.host;

    const [agent,setAgent] = useState([]);

    const [modalType,setModalType] = useState("add");

    const [modalOpen,setModalOpen] = useState(false);

    const [agentName,setAgentName] = useState("");

    const [errorMessage,setErrorMessage] = useState("");

    const [agentUsername,setAgentUsername] = useState("");

    const [email,setEmail] = useState("");

    const [code,setcode] = useState("");

    const [activated,setActivated] = useState(0);

    const [usernameError,setUsernameError] = useState("");

    const [passwordError,setPasswordError] = useState("");

    const [showCopied,setShowCopied] = useState(false);

    const [showBackup,setShowBackup] = useState(false);

    const [backupCode,setBackupCode] = useState("");

    const codeRef = useRef(null);

    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    } 
    
    const handleModalClose = () => {
        setModalOpen(false);
    }

    useEffect(()=>{
        axios.get(host+'/api/auth/googleauth/check',{params:{_token:getCookie("auth")}},{withCredentials: true})
        .then(res => {
            if(res.data.result){
                setcode(res.data.token || "");
                setActivated(res.data.activated || 0);
            }
        }); 
    },[]);

    const isAlphaNumeric = (str) => {
        var code, i, len;
      
        for (i = 0, len = str.length; i < len; i++) {
          code = str.charCodeAt(i);
          if (!(code > 47 && code < 58) && // numeric (0-9)
              !(code > 64 && code < 91) && // upper alpha (A-Z)
              !(code > 96 && code < 123) && 
              !(code === 95) &&
              !(code === 46) 
              ) 
               { // lower alpha (a-z)
            return false;
          }
        }
        return true;
      };

      const disableGoogleAuth = () => {
        axios.post(host+'/api/auth/googleauth/disable',{_token:getCookie("auth"),password:agentUsername},{withCredentials: true})
        .then(res => {
            setModalOpen(false);
            if(res.data.result){
                setcode("");
                setActivated(0);
            }
            // setTeam(res.data);
            
        }).catch(err => {
            setPasswordError(err.response.data.description);
        });                   
      }

      const enableGoogleAuth = () => {
        axios.post(host+'/api/auth/googleauth/activate',{_token:getCookie("auth"),password:agentUsername},{withCredentials: true})
        .then(res => {
            setModalOpen(false);
            if(res.data.result){
                if(res.data.result === "success"){
                    setcode(res.data.token);
                    setActivated(res.data.activated);
                }
                else{
                    setPasswordError(res.data.description);
                }
            }
            // setTeam(res.data);
            
        }).catch(err => {
            setPasswordError(err.response.data.description);
        });               
      }

      const getBackupCode = () => {
        axios.post(host+'/api/auth/googleauth/backup',{_token:getCookie("auth"),password:agentUsername},{withCredentials: true})
        .then(res => {
            // setModalOpen(false);
            if(res.data.result){
                if(res.data.result === "success"){
                    // setcode(res.data.token);
                    // setActivated(res.data.activated);
                    setPasswordError("");
                    setBackupCode(res.data.code);
                    setShowBackup(true);
                }
                else{
                    setPasswordError(res.data.description);
                }
            }
            // setTeam(res.data);
            
        }).catch(err => {
            setPasswordError(err.response.data.description);
        });               
      }

      const verifyGoogleAuth = () => {
        axios.post(host+'/api/auth/googleauth/verify',{_token:getCookie("auth"),code:agentName},{withCredentials: true})
        .then(res => {
            setModalOpen(false);
            if(res.data.activated){
                setActivated(res.data.activated);
            }
            // setTeam(res.data);
            
        });               
      }

      const copyCode = () => {
        // codeRef.current.select();
        navigator.clipboard.writeText(backupCode);
        setShowCopied(true);
      }

    const addAgent = () => {
        if(agentName !== "" && agentUsername !== "" && email !== ""){
            if(isAlphaNumeric(agentUsername)){
                axios.post(host+'/api/agent/list/add',{_token:getCookie("auth"),name:agentName,username:agentUsername,email:email},{withCredentials: true})
                .then(res => {
                    // setTeam(res.data);
                    if(res.data.result === "success"){
                        setModalOpen(false);
                        setErrorMessage("");
                        setAgentName("");
                        setAgentUsername("");
                        setEmail("");
                        axios.get(host+'/agent/'+getCookie("auth"),{params:{}},{withCredentials: true})
                        .then(res => {
                            setAgent(res.data);
                        });                     
                    }
                    else{
                        setErrorMessage(res.data.description);
                    }
                    
                });      
            }
            else{
                setUsernameError("Username should contain letter, number, dot, underline only.");
            }      
        }
    }

    return (
        <div className="member-list-container limit-width-500">
            <h5 className="settings-title-text bold-text-2">Two-Factor Authentication</h5>
            <h5 className="settings-title-text bold-text-2">Google Authenticator</h5>
            <h6 className="margin-bottom-2x">Protect your account with an extra layer of security. Once enabled, you'll be required to enter an authentication code from your Google Authenticator App in order to sign in.</h6>
                <BootstrapModal show={modalOpen} onHide={handleModalClose}>
                    {/* <div className="grey account-linking-modal-image-container">

                    </div> */}
                    <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title><h5 className="bold-text-2 smaller-font">{modalType === "add" ? "Enable Two-Factor Authentication" : (modalType === "disable" ? "Disable Two-Factor Authentication" : (modalType === "backup" ? "View Backup Code" : ""))}</h5></BootstrapModal.Title>
                    </BootstrapModal.Header>
                    <BootstrapModal.Body>
                        { errorMessage !== "" ? <h6 className="red lighten-3 callout-box white-text">{errorMessage}</h6> : null }
                        {
                        
                        ( modalType === "add" || modalType === "disable" ? 
                        <div className="full-width full-height">
                                <h6>Enter password to continue.</h6>
                                <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                    <input onChange={(e)=>setAgentUsername(e.target.value)} className="browser-default" type="password" value={agentUsername}/>
                                    <span>Password</span>
                                    {usernameError !== "" ? <p className="red-text">{usernameError}</p> : null}
                                    <p className="red-text">{passwordError}</p>
                                </label>  
                                {/* <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                    <input onChange={(e)=>setAgentName(e.target.value)} className="browser-default" type="text" value={agentName}/>
                                    <span>Name</span>
                                    <p className="red-text hide">This cannot be empty.</p>
                                </label>      
                                <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                    <input onChange={(e)=>setAgentUsername(e.target.value)} className="browser-default" type="text" value={agentUsername}/>
                                    <span>Username</span>
                                    {usernameError !== "" ? <p className="red-text">{usernameError}</p> : null}
                                    <p className="red-text hide">This cannot be empty.</p>
                                </label>   
                                <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                    <input onChange={(e)=>setEmail(e.target.value)} className="browser-default" type="text" value={email}/>
                                    <span>Email</span>
                                    <p className="red-text hide">This cannot be empty.</p>
                                </label>                          */}
                      </div> 
                        :
                        ( modalType === "backup" ? 
                        <div className="full-width full-height">
                               {(
                                   !showBackup ?
                                    <div>
                                        <h6>Enter password to continue.</h6>
                                        <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                            <input onChange={(e)=>setAgentUsername(e.target.value)} className="browser-default" type="password" value={agentUsername}/>
                                            <span>Password</span>
                                            {usernameError !== "" ? <p className="red-text">{usernameError}</p> : null}
                                            <p className="red-text">{passwordError}</p>
                                        </label> 
                                    </div>
                                    :
                                    
                                        <div>
                                            <div className="valign-wrapper">
                                                <h5 className="bold-text backup-code-text" ref={codeRef}>{backupCode}</h5>
                                                <div className="icon-button-hover-2 linked inline-block" onClick={copyCode}>
                                                    COPY
                                                </div>
                                            </div>
                                            {showCopied ? <p className="green-text unselectable">Copied to clipboard</p> : null}
                                            <h6>Keep this code somewhere safe!</h6>
                                        </div>
                                    
                                 )}
                      </div> 
                        :
                        null  
                        )     
                        )   
                                        
                    }                  
                    </BootstrapModal.Body>
                <BootstrapModal.Footer>
                <div>
                <button className="btn-flat ng-close-btn waves-effect waves-dark margin-right-1x" onClick={()=>setModalOpen(false)}>cancel</button>
                    {
                        (modalType === "add" ? 
                        <button className="button-new" onClick={enableGoogleAuth}>Enable</button>
                        :
                            (modalType === "disable" ? 
                            <button className="button-new delete-button" onClick={disableGoogleAuth}>Disable</button>
                            :
                                (modalType === "backup" ? 
                                (!showBackup ? <button className="button-new" onClick={getBackupCode}>Get</button> : null)
                                :
                                null
                                )
                            )
                        )
                    }
                </div>
                </BootstrapModal.Footer>
            </BootstrapModal>             
            <div className="margin-bottom-2x">
                {(code === "" ? <button className="button-new" onClick={()=>{setModalOpen(true);setModalType("add");setAgentUsername("");setPasswordError("")}}>Enable</button> : null)}

                {
                    code !== "" ?
                    <div>
                    {/* {(!activated ?
                        <h5 className="bold-text-2">Almost there!</h5>
                    : null)} */}
                        <div className="valign-wrapper">
                        {
                            activated ?
                            <div className="info-box success">
                                <div className="valign-wrapper">
                                    <CheckCircleOutline/>
                                    <h6 className="margin-left-1x">You have enabled two-factor authentication.</h6>
                                </div>
                            </div>
                            :
                            <div className="info-box warning">
                                <div className="valign-wrapper">
                                    <h6 className="margin-left-1x">Please follow the instruction below for full activation.</h6>
                                </div>
                            </div>
                        }
                        {/* {(!activated ? <button className="button-new" onClick={enableGoogleAuth}>Refresh</button> : null)} */}
                        <button className="button-new delete-button" onClick={()=>{setModalOpen(true);setModalType("disable");setAgentUsername("");setPasswordError("")}}>Disable</button>

                        </div>
                        
                        {
                            !activated ?
                            <h6 className="margin-top-2x">Step 1: Scan the QR code from Google Authenticator App.</h6>
                            :
                            null
                        }             
                        {(!activated ? <img src={code}/> : null)}           
                        {
                            !activated ?
                            <div className="margin-top-2x">
                                <h6>Step 2: Enter authentication code from Google Authenticator App for first time activation.</h6>
                                <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                    <input onChange={(e)=>setAgentName(e.target.value)} className="browser-default" type="password" value={agentName}/>
                                    <span>Code</span>
                                    <p className="red-text hide">This cannot be empty.</p>
                                </label>
                                <button className="button-new" onClick={verifyGoogleAuth}>Activate</button>                            
                            </div>  :
                            null}
                    </div>
                    :
                    null
                }
                {
                    activated ?
                    <div className="margin-top-2x">
                        <h5 className="settings-title-text bold-text-2">Backup code</h5>
                        <h6>In case you lose your account from Google Authenticator, you can use backup code to unlock 2FA access. Using backup code will disable 2FA.</h6>
                        <button className="button-new" onClick={()=>{setModalOpen(true);setModalType("backup");setAgentUsername("");setPasswordError("");setShowBackup(false);setShowCopied(false)}}>View Backup Code</button>
                    </div>
                    :
                    null
                }
            </div>

        </div>
    )
}
