import React, { Component } from 'react'
import Variable from './../variable';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Moment from 'react-moment';
import moment from 'moment';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown'
import { ResponsiveBar } from '@nivo/bar'
import EmotionChart from './../components/EmotionChart';
import Circular from './../components/Circular';
import {NavigateNext,NavigateBefore} from '@material-ui/icons'

import StackedBarChart from './../components/StackedBarChart';
import { isObject } from 'lodash';
// import rd3 from 'react-d3-library'

const data = [
    {
      name: "Brand 1",
      type: 1,
      Affiliate: 10,
      Social: 20,
      Media: 30
    },
    {
      name: "Brand 1",
      type: 2,
      Affiliate: 20,
      Social: 40,
      Media: 60
    },
    {
      name: "Brand 2",
      type: 1,
      Affiliate: 30,
      Social: 45,
      Media: 80
    },
    {
      name: "Brand 3",
      type: 1,
      Affiliate: 40,
      Social: 60,
      Media: 100
    },
    {
      name: "Brand 3",
      type: 2,
      Affiliate: 50,
      Social: 80,
      Media: 120
    }
  ];
  
  const allKeys = ["Affiliate", "Social", "Media"];
  
  const colors = {
    Affiliate: "rgba(69, 0, 0, 0.8)",
    Social: "rgba(240, 72, 19, 0.8)",
    Media: "rgba(255, 199, 128, 0.8)"
  };


export default class OmnichannelReport extends Component {
    host = Variable.host;

    constructor(props){
        super(props);
        this.state = {
            startDate:(moment()),
            endDate:(moment()),
            reportData : {},
            activityData: [],
            steps:"24",
            mode:"omni",
            chartData:{},
            chartData2:[],
            filterDate:[],
            chartLoading:false,
            statusArray:[]
        }
    }

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }
    
    toChartData = (data) => {
        let timeArray = [];
        let chartKeys = [];
        let alldata = {};
        
        Object.keys(data).map((time, index)=> {
            let newObj = {};
            newObj.time = time;
           if( Array.isArray(data[time]["all"])){

            }
            else{
                Object.keys(data[time]).map((channel, index)=> {
                    if( Array.isArray(data[time][channel])){
                    }
                    else{
                        Object.keys(data[time][channel]).map((emotion, index)=> {
                        
                            newObj[emotion] = data[time][channel][emotion];
                            newObj[emotion+"Color"] = "hsl(297, 70%, 50%)";
                            if(chartKeys.indexOf(emotion) === -1){
                                chartKeys.push(emotion);
                            }
                        })
                    }
                })
            }
            timeArray.push(newObj);
        });
        alldata['chartData'] = timeArray;
        alldata['chartKeys'] = chartKeys;
        return alldata;
    }

    toChartData2 = (data) => {
        let timeArray = {};
        let chartKeys = [];
        let alldata = {};

        let max = 0;

        let channelType = [{name : "email",channel:["EMAIL"]},{name:"chatbot",channel:["WAUCHAT"]},{name:"livechat",channel:["LIVECHAT"]},{name:"social media",channel:["FB_PAGE_POST","TW_MENTION"]},{name:"mobile messaging",channel:["FB_PM","TW_DM","LINE_DM","WHATSAPP"]}];
        
        



        Object.keys(data).map((time, index)=> {
            
            let channelArray = [];
           if( Array.isArray(data[time]["all"])){

            }
            else{
                
                for(let i = 0; i < channelType.length ; i++){
                    let tempMax = 0;
                    let newObj = {};
                    newObj.channel = channelType[i].name;
                    Object.keys(data[time]).filter(key => channelType[i].channel.includes(key)).map((channel, index)=> {
                        if( Array.isArray(data[time][channel])){

                        }
                        else{

                            // switch(channelArray[i]){
                            //     case "email":
                            //         if(channel !== "EMAIL"){
                            //             return false;
                            //         }
                            //     break;

                            //     case "chatbot":
                            //         if(channel !== "WAUCHAT"){
                            //             return;
                            //         }
                            //     break;

                            //     case "livechat":
                            //         if(channel !== "LIVECHAT"){
                            //             return;
                            //         }
                            //     break;

                            //     case "social media":
                            //         if(channel !== "FB_PAGE_POST" && channel !== "TW_MENTION"){
                            //             return;
                            //         }
                            //     break;

                            //     case "mobile messaging":
                            //         if(channel !== "FB_PM" && channel !== "TW_DM" && channel !== "LINE_DM" && channel !== "WHATSAPP"){
                            //             return;
                            //         }
                            //     break;
                            // }

                            


                                    

                                        Object.keys(data[time][channel]).map((emotion, index)=> {
                                        
                                            
                                            if(newObj[emotion]){
                                                newObj[emotion] = newObj[emotion] + data[time][channel][emotion];
                                                tempMax = tempMax + data[time][channel][emotion];
                                            }
                                            else{
                                                newObj[emotion] = data[time][channel][emotion];
                                                tempMax = tempMax + data[time][channel][emotion];
                                                newObj[emotion+"Color"] = "hsl(297, 70%, 50%)";
                                            }
                                            

                                            if(chartKeys.indexOf(emotion) === -1){
                                                chartKeys.push(emotion);
                                            }                                    
                                            
                                            // if(chartKeys.indexOf(emotion) === -1){
                                            //     chartKeys.push(emotion);
                                            // }
                                        })
                            
                        }
                    })
                    channelArray.push(newObj);
                    if(tempMax > max){
                        max = tempMax;
                    }
                }
            }
            timeArray[time] = (channelArray);
        });
        alldata['chartData'] = timeArray;
        alldata['chartKeys'] = chartKeys;
        alldata['max'] = max;
        return alldata;
    }    

    toChartData3 = (data) => {
        let timeArray = [];
        let chartKeys = [];
        let alldata = {};
        
        Object.keys(data).map((time, index)=> {
            let tempArray = [];

           if( Array.isArray(data[time]["all"])){

            }
            else{
                Object.keys(data[time]).map((channel, index)=> {
                    
                        let newObj = {};
                        newObj.channel = channel;
                        // newObj[channel] = data[time][channel];

                        Object.keys(data[time][channel]).map((status, index)=> {
                            if(status !== "soa"){
                                if(isObject(data[time][channel][status])){
                                    newObj[status] = data[time][channel][status]["total"];
                                    newObj[status+"Color"] = "hsl(297, 70%, 50%)";
                                    if(chartKeys.indexOf(status) === -1){
                                        chartKeys.push(status);
                                    }   
                                }  
                                else{
                                    newObj[status] = data[time][channel][status];
                                    newObj[status+"Color"] = "hsl(297, 70%, 50%)";
                                    if(chartKeys.indexOf(status) === -1){
                                        chartKeys.push(status);
                                    }                                       
                                }     
                            }
                        });
                                          
 
                    // if( Array.isArray(data[time][channel])){
                    // }
                    // else{
                    //     Object.keys(data[time][channel]).map((emotion, index)=> {
                        
                    //         newObj[emotion] = data[time][channel][emotion];
                    //         newObj[emotion+"Color"] = "hsl(297, 70%, 50%)";
                    //         if(chartKeys.indexOf(emotion) === -1){
                    //             chartKeys.push(emotion);
                    //         }
                    //     })
                    // }

                    tempArray.push(newObj);
                })

                
            }
            timeArray[time] = tempArray;
        });
        alldata['chartData'] = timeArray;
        alldata['chartKeys'] = chartKeys;
        return alldata;
    }    


    todayDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

      getChannelName(name){
          let names = {
              "FB_PM" : "Facebook Messenger",
              "FB_PAGE_POST" : "Facebook Post",
              "TW_DM" : "Twitter Direct Message",
              "TW_MENTION" : "Twitter Mentions",
              "WHATSAPP" : "Whatsapp",
              "LINE_DM" : "Line Direct Message",
              "WAUCHAT" : "Chat Bot",
              "EMAIL" : "Email",
              "LIVECHAT" : "Live Chat",
              "MANUAL" : "Manual"
          }
          return names[name] || "Unknown";
      }
      
      exportExcel = () => {
          let win = window.open("https://ticket-api.hyperceed.com/report/export/"+this.getCookie("auth")+"/facebook/"+this.state.startDate.format('YYYY-MM-DD')+"/"+this.state.endDate.format('YYYY-MM-DD')+"/"+this.state.steps);
      }

      reportType = (type) => {
        //   switch(type){
        //       case "omni":
        //         axios.get(this.host+'/ticket/report/'+this.getCookie("auth")+'/facebook', {params:{_token:this.getCookie("auth"),startDate:this.state.startDate.format('YYYY-MM-DD'),endDate:this.state.endDate.format('YYYY-MM-DD'),steps:this.state.steps}},{withCredentials: true})
        //         .then(res => {
        //             this.setState({reportData:res.data});
        //           console.log(res.data);
        //         })                 

        //         break;

        //         case "interaction":
        //             axios.get(this.host+'/ticket/activity/list', {params:{_token:this.getCookie("auth"),startDate:this.state.startDate.format('YYYY-MM-DD'),endDate:this.state.endDate.format('YYYY-MM-DD'),steps:"1"}},{withCredentials: true})
        //             .then(res => {
        //                 this.setState({activityData:res.data});
        //               console.log(res.data);
        //             })     
        //         break;

        //         case "emotion":

        //         break;
        //   }
          this.setState({mode:type},()=>{
              this.getReport();
          });
      }

      getReport = () => {
        switch(this.state.mode){
            case "omni":
                this.setState({chartLoading:true});
                axios.get(this.host+'/api/report/interval', {params:{_token:this.getCookie("auth"),startDate:this.state.startDate.format('YYYY-MM-DD'),endDate:this.state.endDate.format('YYYY-MM-DD'),interval:this.state.steps}},{withCredentials: true})
                .then(res => {
                    
                    // let tempObj = {};

                    // let chartData = res.data;

                    // Object.keys(chartData).map((date, index)=> {



                    //     // let temp = this.toChartData(res.data[date]);

                        
    
                    //     let temp = this.toChartData3(res.data[date]);

                    //     tempObj[date] = {};
                    //     tempObj[date].chartData = temp.chartData;

                    //     tempObj[date].chartKeys = temp.chartKeys;


                        
                                        
                        
                    // })

                    this.setState({reportData:res.data,chartLoading:false});

                    // console.log(res.data);
                })  
     
            break;
            case "interaction":
                this.setState({chartLoading:true});
                axios.get(this.host+'/api/report/aux', {params:{_token:this.getCookie("auth"),startDate:this.state.startDate.format('YYYY-MM-DD'),endDate:this.state.endDate.format('YYYY-MM-DD')}},{withCredentials: true})
                .then(res => {
                    let statusArray = this.state.statusArray;
                    for(let i = 0; i < res.data.length; i++){
                        Object.keys(res.data[i].value).map((agent,i2)=>{
                            Object.keys(res.data[i].value[agent]).map((status,i3)=>{
                                if(statusArray.indexOf(status) === -1){
                                    statusArray.push(status);
                                }
                            })
                        })
                    }
                    this.setState({activityData:res.data,chartLoading:false,statusArray:statusArray});
                  console.log(res.data);
                })     
            break; 

            case "emotion":
                this.setState({chartLoading:true});
                axios.get(this.host+'/api/report/csat', {params:{_token:this.getCookie("auth"),startDate:this.state.startDate.format('YYYY-MM-DD'),endDate:this.state.endDate.format('YYYY-MM-DD')}},{withCredentials: true})
                .then(res => {
                    
                    this.setState({chartData:res.data,chartLoading:false});
                  console.log(res.data);
                })     
            break; 

            case "ivr":
                this.setState({chartLoading:true});
                axios.get(this.host+'/api/report/ivr', {params:{_token:this.getCookie("auth"),startDate:this.state.startDate.format('YYYY-MM-DD'),endDate:this.state.endDate.format('YYYY-MM-DD')}},{withCredentials: true})
                .then(res => {
                    
                    this.setState({chartData2:res.data,chartLoading:false});
                  console.log(res.data);
                })     
            break; 
            case "talktime":
                this.setState({chartLoading:true});
                axios.get(this.host+'/api/report/talktime', {params:{_token:this.getCookie("auth"),startDate:this.state.startDate.format('YYYY-MM-DD'),endDate:this.state.endDate.format('YYYY-MM-DD')}},{withCredentials: true})
                .then(res => {
                    
                    this.setState({chartData2:res.data,chartLoading:false});
                    console.log(res.data);      
                })  
     
            break;
        }          
      }

      convertSeconds = (secs) => {
        var sec_num = parseInt(secs, 10)
        var hours   = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60
    
        return [hours,minutes,seconds]
            .map(v => v < 10 ? "0" + v : v)
            .join(":")
      }

      getFilterTitle = (status) => {
        switch(status){
            case "1":
                return "1 hour";
            case "30":
                return "30 minutes";
            case "15":
                return "15 minutes";
            case "24":
                return "Day";
        }
      }

    componentDidMount(){
        this.getReport();
    }

    selectFilter = (data) => {
        console.log(data);
        this.setState({steps:data});
    }

    selectDateFilter = (data) => {
        console.log(data);
        this.setState({filterDate:data});
    }    

    getCustomStatusHeader = (obj) => {

        let array = [];
        let count = 0;
        
            Object.keys(obj).map((time,index) => {
                Object.keys(obj[time]).map((key,index) => {

                    Object.keys(obj[time][key]).map((status,index) => {

                        if(!isObject(obj[time][key][status]) && count === 0 && status !== "soa"){
                            array.push(status);
                            
                        }

                        
                    })   
                    count++;
                    return array;
                })
                return;
            });
            
        return array;
        
    }

    render() {
        return (
            <div className="full-width flex-col full-height" style={{minWidth:'1px'}}>
                <div className={" flex-row padding-left-2x padding-right-2x flex-no-shrink header-border-bottom"} style={{height:'64px',alignItems:'center',position:"relative",backgroundColor:this.props.dark ? '#333' :'#ffffff'}}>
                    <span className="bold-text-2full-width ellipsis-text chat-list-title-text bold-text-2">Report</span>
                </div>
                <div className="overflow-y">
                    
                    
                    <div style={{padding:"20px",borderBottom:'1px solid rgba(0,0,0,0.1)'}}>
                        <DateRangePicker
                            startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                            endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
                            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                            hideKeyboardShortcutsPanel={true}
                            noBorder={true}
                            displayFormat="DD-MM-YYYY"
                            minimumNights={0}
                            navNext={<div className="react-date-next icon-button-hover-2"><NavigateNext/></div>}
                            navPrev={<div className="react-date-prev icon-button-hover-2"><NavigateBefore/></div>}
                            customArrowIcon={<NavigateNext/>}                                
                            isOutsideRange={day => (moment().endOf('day').diff(day) < 0)}
                            readOnly={true}
                            />
                            <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {this.getFilterTitle(this.state.steps)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="24" onClick={()=>this.selectFilter("24")}>Day</Dropdown.Item>
                                <Dropdown.Item eventKey="15" onClick={()=>this.selectFilter("15")}>15 min</Dropdown.Item>
                                <Dropdown.Item eventKey="30" onClick={()=>this.selectFilter("30")}>30 min</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={()=>this.selectFilter("1")}>1 hours</Dropdown.Item>
                            </Dropdown.Menu>
                            </Dropdown>                            
                            <div>
                                {/* {(this.state.mode === "omni" ?<button className="button-new white-text teal lighten-1 margin-top-1x margin-right-1x" onClick={this.exportExcel}>Export</button> : null)} */}
                                 <button className="button-new white-text teal lighten-1 margin-top-1x" onClick={this.getReport}>Filter</button>
                            </div>
                            
                        </div>     
                        <div className="padding-left-2x padding-right-2x">
                            <div className="margin-top-2x">
                                <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic-2">
                                    {(this.state.mode === "omni" ? "Interval Report" : 
                                    (this.state.mode === "interaction" ? "Agent Performance Report" : 
                                    (this.state.mode === "emotion" ? "CSAT Report" : 
                                    (this.state.mode === "ivr" ? "IVR Report" : 
                                    (this.state.mode === "att" ? "ATT Report" : 
                                    (this.state.mode === "aht" ? "AHT Report" : 
                                    (this.state.mode === "sla" ? "SLA Report" : 
                                    (this.state.mode === "talktime" ? "Talk Time Report" : 
                                    ""))))))))}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey="omni" onClick={()=>this.reportType("omni")}>Interval Report</Dropdown.Item>
                                    <Dropdown.Item eventKey="interaction" onClick={()=>this.reportType("interaction")}>Agent Performance Report</Dropdown.Item>
                                    <Dropdown.Item eventKey="emotion" onClick={()=>this.reportType("emotion")}>CSAT Report</Dropdown.Item>
                                    <Dropdown.Item eventKey="ivr" onClick={()=>this.reportType("ivr")}>IVR Report</Dropdown.Item>
                                    <Dropdown.Item eventKey="sla" onClick={()=>this.reportType("sla")}>SLA Report</Dropdown.Item>
                                    <Dropdown.Item eventKey="att" onClick={()=>this.reportType("att")}>ATT Report</Dropdown.Item>
                                    <Dropdown.Item eventKey="aht" onClick={()=>this.reportType("aht")}>AHT Report</Dropdown.Item>
                                    <Dropdown.Item eventKey="talktime" onClick={()=>this.reportType("talktime")}>Talk Time Report</Dropdown.Item>
                                    {/* <Dropdown.Item eventKey="interaction" onClick={this.reportType}>Interaction Report</Dropdown.Item>
                                    <Dropdown.Item eventKey="emotion" onClick={this.reportType}>Emotion Report</Dropdown.Item> */}
                                </Dropdown.Menu>
                                </Dropdown>                               
                            </div>         
                            {
                            this.state.mode === "omni" ?    
                            (!this.state.chartLoading ? 
                                <table className="report-table">
                                <thead>
                                    <tr>
                                        <th>Time</th>
                                        <th>Calls Offered</th>
                                        <th>Calls Answered</th>
                                        <th>Calls Answered 20 or less</th>
                                        <th>Calls Answered More than 20</th>
                                        <th>Calls Abandoned</th>
                                        <th>Calls Abandoned 5.5 or less</th>
                                        <th>Calls Abandoned More than 5.5</th>
                                        <th>SL(%)</th>
                                        <th>ATT</th>
                                        <th>AHT</th>
                                        <th>ASOA</th>
                                        <th>LWT</th>
                                        <th>CAR</th>
                                        <th>CABDR</th>
                                    </tr>
                                </thead> 
                                <tbody>
                                {
                                Object.keys(this.state.reportData).map((date, index)=> (
                     

                                    date !== "request" ? 
                                        <React.Fragment key={date}>
 
                                                               
                                                    {/* {(index === 0 ? <thead>
                                                        <tr>
                                                            <th>Calls Offered</th>
                                                            <th>Calls Answered</th>
                                                            <th>Calls Answered 20 or less</th>
                                                            <th>Calls Answered More than 20</th>
                                                            <th>Calls Abandoned</th>
                                                            <th>Calls Abandoned 5.5 or less</th>
                                                            <th>Calls Answered More than 5.5</th>
                                                            <th>SL(%)</th>
                                                            <th>ATT</th>
                                                            <th>ASOA</th>
                                                            <th>LWT</th>
                                                            <th>CAR</th>
                                                            <th>CABDR</th>
                                                        </tr>
                                                    </thead> : null)}   */}
                                                    
                                                        <tr>
                                                            <td>{date}</td>
                                                            <td>{this.state.reportData[date].callOffered}</td>    
                                                            <td>{this.state.reportData[date].callAnswered}</td>
                                                            <td>{this.state.reportData[date].callAnsLess20}</td>
                                                            <td>{this.state.reportData[date].callAnsMore20}</td>
                                                            <td>{this.state.reportData[date].callAbandoned}</td>
                                                            <td>{this.state.reportData[date].callAbandonedLess5}</td>
                                                            <td>{this.state.reportData[date].callAbandonedMore5}</td>                  
                                                            <td>{this.state.reportData[date].SL}</td>
                                                            <td>{this.state.reportData[date].averageTalkTime}</td>
                                                            <td>{this.state.reportData[date].averageHoldTime}</td>
                                                            <td>{this.state.reportData[date].averageSpeedOfAnswer}</td>
                                                            <td>{this.state.reportData[date].longestWaitingTime}</td>
                                                            <td>{this.state.reportData[date].car}</td>
                                                            <td>{this.state.reportData[date].abdr}</td>
                                                        </tr>
                                                    
                                                
                    
                                            </React.Fragment>
                                    :
                                    null
                                            
                                        
                                        
                                        
                                    
                                    
                                )
                                )
                                                    }
                                        </tbody>
                                    </table>
                                :
                                <div className="full-width valign-wrapper">
                                <div className="full-width">
                                <Circular/>
                                <h6 className="mid-text">Shorter intervals or wider date range may take longer to load, please wait.</h6>
                                </div>                             
                                </div>                                 
                            )
                             :
                              null}

                            {
                                this.state.mode === "interaction"?
                                 (   
                                    !this.state.chartLoading ? 
                                    //     Object.keys(this.state.activityData).map((date, index)=> (                    

                                    // ))
                                    <table className="report-table">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Agent</th>
                                                {
                                                    this.state.statusArray.map((status,i) => (
                                                        <th key={status}>{status}</th>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                                {

                                                    this.state.activityData.map((obj,i) => (
                                                       
                                                           
                                                        Object.keys(obj.value).map((agent,i2)=>(
                                                        <tr key={agent+i2}>
                                                            {
                                                                i2 === 0 ?
                                                                <td>{obj.date}</td>
                                                                :
                                                                <td></td>
                                                            }
                                                            <td>{agent}</td>
                                                            {

                                                                this.state.statusArray.map((value,i4)=>{
                                                                    if(obj.value[agent][value]){                                                                     
                                                                        return <td key={agent+value+i4}>{obj.value[agent][value]}</td>
                                                                    }
                                                                    else{
                                                                        return <td></td>;
                                                                    }
                                                                })
                                                            }

                                                        
                                                        </tr>
                                                        ))
           
                                                           
                                                       
                                                    ))
                                                }                                            
                                        </tbody>
                                    </table>
                                    :
                                        <div className="full-width valign-wrapper">
                                        <div className="full-width">
                                        <Circular/>
                                        <h6 className="mid-text">Shorter intervals or wider date range may take longer to load, please wait.</h6>
                                        </div>                             
                                        </div>     
                                    )
                                    :
                                    (this.state.mode === "emotion" ?
                                    (!this.state.chartLoading ? 
                                    //     Object.keys(this.state.activityData).map((date, index)=> (                    

                                    // ))
                                    <table className="report-table">
                                        <thead>
                                            <tr>
                                                <th>Start</th>
                                                <th>End</th>
                                                <th>KeyPress</th>         
                                            </tr>
                                        </thead>
                                        <tbody>
                                                {

                                                    Object.keys(this.state.chartData).map((date,i) => (
                                                      
                                                        this.state.chartData[date].map((obj,i) => (
                                                            <tr>
                                                                <td>{obj.timeSystemIncomingCall}</td>
                                                                <td>{obj.timeSystemHangup}</td>
                                                                <td>{obj.digitsPressed}</td>
                                                            </tr>
                                                        )) 
                                                        
           
                                                           
                                                       
                                                    ))
                                                }                                            
                                        </tbody>
                                    </table>
                                    :
                                        <div className="full-width valign-wrapper">
                                        <div className="full-width">
                                        <Circular/>
                                        <h6 className="mid-text">Shorter intervals or wider date range may take longer to load, please wait.</h6>
                                        </div>                             
                                        </div>     
                                    )
                                    :
                                    (this.state.mode === "ivr" ?
                                    (!this.state.chartLoading ? 
                                    //     Object.keys(this.state.activityData).map((date, index)=> (                    

                                    // ))
                                    <table className="report-table">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Digit Pressed</th>
                                                <th>Count</th>         
                                            </tr>
                                        </thead>
                                        <tbody>
                                                {

                                                    this.state.chartData2.map((obj2,i) => (
                                                      
                                                        obj2.map((obj,i) => (
                                                            <tr>
                                                                <td>{i + 1}</td>
                                                                <td>{obj.digitsPressed}</td>
                                                                <td>{obj.count}</td>
                                                            </tr>
                                                        )) 
                                                        
           
                                                           
                                                       
                                                    ))
                                                }                                            
                                        </tbody>
                                    </table>
                                    :
                                        <div className="full-width valign-wrapper">
                                        <div className="full-width">
                                        <Circular/>
                                        <h6 className="mid-text">Shorter intervals or wider date range may take longer to load, please wait.</h6>
                                        </div>                             
                                        </div>     
                                    )
                                    :
                                    null
                                    )
                                    )
                        }

            {
                            this.state.mode === "att" || this.state.mode === "aht" || this.state.mode === "sla" ?    
                            (!this.state.chartLoading ? 
                                <table className="report-table">
                                <thead>
                                    <tr>
                                        <th>Time</th>

                                        <th>{
                                            this.state.mode === "att" ? "Average Talk Time" :
                                            (this.state.mode === "aht" ? "Average Hold Time" :
                                            (this.state.mode === "sla" ? "SL" :
                                            ""
                                            )
                                            )
                                        }</th>

                                    </tr>
                                </thead> 
                                <tbody>
                                {
                                Object.keys(this.state.reportData).map((date, index)=> (
                     

                                    date !== "request" ? 
                                        <React.Fragment key={date}>
 
                                                               
                                                    {/* {(index === 0 ? <thead>
                                                        <tr>
                                                            <th>Calls Offered</th>
                                                            <th>Calls Answered</th>
                                                            <th>Calls Answered 20 or less</th>
                                                            <th>Calls Answered More than 20</th>
                                                            <th>Calls Abandoned</th>
                                                            <th>Calls Abandoned 5.5 or less</th>
                                                            <th>Calls Answered More than 5.5</th>
                                                            <th>SL(%)</th>
                                                            <th>ATT</th>
                                                            <th>ASOA</th>
                                                            <th>LWT</th>
                                                            <th>CAR</th>
                                                            <th>CABDR</th>
                                                        </tr>
                                                    </thead> : null)}   */}
                                                    
                                                        <tr>
                                                            <td>{date}</td>      
                                                             
                                                            <td>{
                                                                this.state.mode === "att" ? 
                                                                this.state.reportData[date].averageTalkTime
                                                                :
                                                                (
                                                                    this.state.mode === "aht" ? 
                                                                    this.state.reportData[date].averageHoldTime
                                                                    :
                                                                    (
                                                                        this.state.mode === "sla" ? 
                                                                        this.state.reportData[date].SL
                                                                        :
                                                                        ""
                                                                    )
                                                                )
                                                            }</td>
                                                        </tr>
                                                    
                                                
                    
                                            </React.Fragment>
                                    :
                                    null
                                            
                                        
                                        
                                        
                                    
                                    
                                )
                                )
                                                    }
                                        </tbody>
                                    </table>
                                :
                                <div className="full-width valign-wrapper">
                                <div className="full-width">
                                <Circular/>
                                <h6 className="mid-text">Shorter intervals or wider date range may take longer to load, please wait.</h6>
                                </div>                             
                                </div>                                 
                            )
                             :
                              null}
{
    (this.state.mode === "talktime" ?
    (!this.state.chartLoading ? 
    //     Object.keys(this.state.activityData).map((date, index)=> (                    

    // ))
    <table className="report-table">
        <thead>
            <tr>
                <th>No</th>
                <th>Caller</th>
                <th>Talk Time</th>         
            </tr>
        </thead>
        <tbody>
                {

                    this.state.chartData2.map((obj,i) => (
                      
           
                            <tr>
                                <td>{i + 1}</td>
                                <td>{obj.callerID}</td>
                                <td>{obj.talktime}</td>
                            </tr>

                        

                           
                       
                    ))
                }                                            
        </tbody>
    </table>
    :
        <div className="full-width valign-wrapper">
        <div className="full-width">
        <Circular/>
        <h6 className="mid-text">Shorter intervals or wider date range may take longer to load, please wait.</h6>
        </div>                             
        </div>     
    )
    :
    null
    )
}

                        {/* {
                            <StackedBarChart datasets={data} colors={colors} keys={allKeys} />
                        } */}
                    </div>
                </div>
            </div>
        )
    }
}