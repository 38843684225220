import React, { useState,useRef,useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faUsers} from '@fortawesome/free-solid-svg-icons'
import { Scrollbars } from 'react-custom-scrollbars';
import { Lens } from '@material-ui/icons';
import axios from 'axios';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import Variable from './../variable';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';

function useOutsideAlerter(ref,setOpened) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpened(false);
        }
      }
  
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}

function OnlineUser(props){

    const host = Variable.host;

    const [opened,setOpened] = useState(false);

    const [item,setItem] = useState([]);

    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    } 

    function toggleOpen(){
        if(!opened){
            axios.get(host+'/status/get',{params:{_token:getCookie("auth")}},{withCredentials: true})
            .then(res => {
                setItem(res.data);
            }); 
        }
        setOpened(!opened);
    }

    function checkDiff(date,status){
        let today = moment().startOf().diff(moment.unix(date),'minutes');
        // console.log(today);
        if(today >= 1){
            if(today >= 60){
                today = moment().startOf().diff(moment.unix(date),'hours');
            }
            else{
                return today += " minutes ago";
            }
            if(today >= 24){
                today = moment().startOf().diff(moment.unix(date),'days')+ " days ago";
            }
            else{
                return today += " hours ago";
            }
        }
        else{
            if(status === "OFFLINE"){
                return "Offline not long ago";
            }
            else{
                return "Active now";
            }
        }
        return today;
    }

    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef,setOpened);    

    return (
        <React.Fragment>
            <div ref={wrapperRef}>
                {(opened ? 
                <div className={(props.dark ? "grey darken-3" : "white") +" notification-panel"} style={{width:"200px",height:"400px",position:"fixed",top:"50px",left:"70px"}}>
                    <Scrollbars>
                        <div className="user-list-holder full-height">
                            {
                                item.map((obj,i)=>(
                                    (props.userInfo[obj.username] ? 
                                    
                                    <div key={i} className="full-width flex-row valign-wrapper linked hover-tint rounded-corner-10" style={{minWidth:"1px",padding:"10px",marginRight:"5px"}}>
                                        <div className="relative-content flex-no-shrink">
                                        {(props.userInfo[obj.username].photo !== undefined ? 
                                        <img className="margin-right-1x avatar-wrapper mask-bottom-left-5" src={(props.userInfo[obj.username] !== undefined ? (props.userInfo[obj.username].photo !== "" ? (props.userInfo[obj.username].photo.indexOf("www.superceed.com") === -1 ? "https://www.superceed.com" : "")+props.userInfo[obj.username].photo : "https://www.superceed.com/images/system/default.png") : "https://www.superceed.com/images/system/default.png")}/>
                                         : null)}
                                        <div style={{position:'absolute',left:'-2px',bottom:'2px',height:"15px"}}>
                                            <Tippy content={<span>{obj.status}</span>}>
                                                <Lens className={"tiny status-icon "+(obj.status === "ONLINE" ? "green-text" : (obj.status === "OFFLINE" ? "grey-text" : "grey-text"))} style={{fontSize:'15px'}}/>
                                            </Tippy>
                                        </div>                                           
                                         </div>
                                      
                                        <div style={{width:'140px'}}>
                                            
                                                <React.Fragment>
                                                    <Tippy content={props.userInfo[obj.username] ? props.userInfo[obj.username].name : obj.username}>
                                                        <h6 className={"bold-text-2 ellipsis-text"}>{props.userInfo[obj.username] ? props.userInfo[obj.username].name : obj.username}</h6>
                                                    </Tippy>
                                                    <h6>{checkDiff(obj.ts,obj.status)}</h6>
                                                </React.Fragment>
                                            
                                            {/* <h6 className={"ellipsis-text valign-wrapper grey-text"}>{obj.status}</h6>
                                            <h6 className={"ellipsis-text valign-wrapper grey-text"}>{obj.updated_at}</h6> */}
                                        </div>
                                    </div> 
                                    
                                    : null)
                                ))
                                
                            }
                            {
                                    item.length === 0 ?
                                    <span className="full-width full-height flex-col" style={{justifyContent:"center",alignItems:"center"}}>
                                            <FontAwesomeIcon style={{fontSize:"50px"}} icon={faUsers}/>
                                            <h5 className="bold-text-2">No User</h5>
                                        </span>                                    
                                    :
                                    null
                                }                            
                        </div>
                    </Scrollbars>
                </div> : null)}
            </div>      
            <Tippy content="Online User" placement="right">
                <div className="left-panel-icon-container flex-no-shrink hover-tint-4 linked" style={{backgroundColor:(props.dark ? "#212121": "#37474F")}} onClick={toggleOpen}>
                    <div className="flex-no-shrink mid-text" style={{width:'40px'}}>
                        <FontAwesomeIcon icon={faUser}/>
                    </div>
                    <span className="white-text" style={{whiteSpace:'nowrap'}}>Online User</span>    
                </div>       
            </Tippy>         
        </React.Fragment>
    )
}

export default OnlineUser