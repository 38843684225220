import React, { Component } from 'react'
import Variable from './../variable';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Moment from 'react-moment';
import moment from 'moment';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown'
import { ResponsiveBar } from '@nivo/bar'
import EmotionChart from './../components/EmotionChart';
import Circular from './../components/Circular';
import {NavigateNext,NavigateBefore} from '@material-ui/icons'

import StackedBarChart from './../components/StackedBarChart';
import { isObject } from 'lodash';




export default class ServiceTime extends Component {

    host = Variable.host;

    constructor(props){
      super(props);
      this.state = {
        pbx:[],
        references:{},
        agentPool:[]
      }
    }

    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
  }

    componentDidMount(){
      axios.get(this.host+'/api/proxy/pbx', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
      .then(res => {
        this.setState({pbx:res.data});
      })

      axios.get(this.host+'/api/proxy/agent_pool', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
      .then(res => {
        this.setState({agentPool:res.data});
      })
    }

  
    keyPress = (obj) => {
      if(obj !== null){
        return obj.map((item,i)=>(
          <div className='nested-keypress'>
            <div>
              <h6>Option String</h6>
              <p>{item.optionString}</p>
              <h6>Reference</h6>
              <select className='browser-default' value={item.referenceTableID} onChange={(event)=>{
                            
                item.referenceTableID = event.target.value;

                this.setState({pbx:this.state.pbx});
              }}>
                <option value="0">None</option>
                {
                  this.state.agentPool.map((pool,i)=>(
                    <option value={pool.ID}>{pool.description}</option>
                  ))
                }
              </select>
              <h6>Return To menu</h6>
              <select className='browser-default' value={item.returnToMenu} onChange={(event)=>{
                  
                  item.returnToMenu = event.target.value;

                  this.setState({pbx:this.state.pbx});
                }}>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
              </select>
              <h6>Service Code</h6>
              <select className='browser-default' value={item.serviceCode.trim()} onChange={(event)=>{
                            
                  item.serviceCode = event.target.value;

                  this.setState({pbx:this.state.pbx});
                }}>
                  <option value="">None</option>
                  {
                    this.props.references.serviceType.map((service,i)=>(
                      <option value={service.serviceCode.trim()}>{service.description}</option>
                    ))
                  }
                </select>
            </div>
            {this.keyPress(item.children)}
          </div>
        ))
      }
      else{
        return null;
      }
    }




    render(){
        return (
            <div className='padding-left-2x padding-right-2x'>
                       {
                this.state.pbx.map((obj,i)=>(
                  <>
                    <table>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Value</th>
                        </tr>                  
                      </thead>
                      <tbody>
                        <tr>
                          <td>Description</td>
                          <td>
                            <input value={obj.description.trim()} onChange={
                              (e)=>{
                                obj.description = e.target.value
                                this.setState({pbx:this.state.pbx});
                              }
                            }/>
                          </td>
                        </tr>
                        <tr>
                          <td>Invalid Retry Count</td>
                          <td>
                            <input value={obj.invalidRetryCount} onChange={
                              (e)=>{
                                obj.invalidRetryCount = e.target.value
                                this.setState({pbx:this.state.pbx});
                              }
                            }/>                            
                          </td>
                        </tr>
                        <tr>
                          <td>Message Repeat</td>
                          <td>
                            <input value={obj.messageRepeat} onChange={
                              (e)=>{
                                obj.messageRepeat = e.target.value
                                this.setState({pbx:this.state.pbx});
                              }
                            }/>                            
                          </td>
                        </tr>
                        <tr>
                          <td>Timeout Count</td>
                          <td>
                            <input value={obj.timeoutCount} onChange={
                              (e)=>{
                                obj.timeoutCount = e.target.value
                                this.setState({pbx:this.state.pbx});
                              }
                            }/>                            
                          </td>
                        </tr>
                        <tr>
                          <td>Timeout Seconds</td>
                          <td>
                            <input value={obj.timeoutSec} onChange={
                              (e)=>{
                                obj.timeoutSec = e.target.value
                                this.setState({pbx:this.state.pbx});
                              }
                            }/>                            
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {
                      this.keyPress(obj.level)
                    }
                  </>
                ))
              }
            </div>
        );
    }
}