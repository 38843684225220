import React, { Component } from 'react';
import { SettingsOutlined as Settings, InfoOutlined as Info, ArrowUpward, ExitToAppOutlined, ExitToApp } from '@material-ui/icons';
import Logo from './../images/icon-256px.png'
import CustomModal from './../components/CustomModal'
export default class componentName extends Component {

  constructor(props){
    super(props);
    this.state = {
      logoutPending:false
    }
  }

  logout = () => {
    this.openModal({
      title:"Log out",
      description: "Are you sure you want to log out?",
      input:[]
    })
    this.props.toggleUserMenu();
  }

  closeCallback = () => {

  }


  openButton = () => {
    return <button className={'button-new no-border red white-text'+(this.state.logoutPending ? ' disabled' : '')} onClick={()=>{
      this.setState({logoutPending:true},()=>{this.props.logout()})
    }}>Log out</button>;
  }



  render() {
    return (
      <>
        <CustomModal inputCallback={this.inputCallback} errorMessage={""} closeModal={e=> this.closeModal = e} openModal={e=> this.openModal = e} optionButton={this.openButton()} closeCallback={this.closeCallback} />
        <div ref={this.props.wrapper} className={"unselectable overflow-menu-wrapper-2 transform-bottom-left z-depth-1 "+(this.props.show ? "overflow-menu-show" : "overflow-menu-hide")+(this.props.dark ? " dark": "")} style={{left:"20px",bottom:"40px"}}>
            <div className="overflow-menu-title padding-right-2x padding-left-2x">
                <div className="valign-wrapper" style={{justifyContent:"center"}}>{/*<img src={Logo} style={{width:"20px",marginRight:"5px"}}/>*/}<h6 className="bold-text">SuperCall</h6></div>
            </div>
            {/* <div className="divider margin-bottom-5"></div> */}
            <div className="overflow-menu-item" style={{width:"200px"}} onClick={()=>this.props.menuClicked("settings")}>
                <div className='overflow-menu-text linked valign-wrapper' to='/about'><Settings className="tiny left margin-right-1x"/><span className="left-panel-item-text">Preference</span></div>
            </div>  
            {/* <div className="overflow-menu-item" style={{width:"200px"}} onClick={()=>this.props.menuClicked("about")}>
            <div className='overflow-menu-text linked valign-wrapper' to='/about'><Info className="tiny left margin-right-1x"/><span className="left-panel-item-text">About</span></div>
            </div>         */}
            {/* <div className="overflow-menu-item" style={{width:"200px"}} onClick={()=>this.props.menuClicked("upgrade")}>
            <div className='overflow-menu-text linked valign-wrapper' to='/about'><ArrowUpward className="tiny left margin-right-1x"/><span className="left-panel-item-text">Upgrade Plan</span></div>
            </div>      */}
            {/* <div className="overflow-menu-item" style={{width:"200px"}} onClick={()=>this.props.menuClicked("socket")}>
            <div className='overflow-menu-text linked valign-wrapper' to='/about'><ArrowUpward className="tiny left margin-right-1x"/><span className="left-panel-item-text">Debug Socket</span></div>
            </div>   */}
            <div className="overflow-menu-item" style={{width:"200px"}} onClick={this.logout}>
            <div className='overflow-menu-text linked valign-wrapper' to='/about'><ExitToApp className="tiny left margin-right-1x"/><span className="left-panel-item-text">Log Out</span></div>
            </div>                 
      </div> 
      </>
    );
  }
}
