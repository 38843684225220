import React, { Component } from 'react'
//import Img from 'react-image';
import Linkify from 'react-linkify';
import ReactPlayer from 'react-player'
//import { TwitterVideoEmbed} from 'react-twitter-embed';
import {PlayCircleFilledOutlined,OpenInNew} from '@material-ui/icons'
import axios from 'axios';
import VisibilitySensor from 'react-visibility-sensor';
import Variable from './../../../variable';

const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener">
      {text}
    </a>
  );

export default class RichLink extends Component {

    host = Variable.host;

    state = {
        iframe:false,
        videoUrl: "",
        twitchData : null,
        hideText: false,
        imageError: false
    }
    isTwitch = false;
    videoUrl = "";
    twitchLoading = false;
    mounted = false;

    videoWidth = '260px';
    videoHeight = 'auto';

    hideText = false;

    allowPlay = false;

    componentDidMount(){
        this.mounted = true;
        window.addEventListener("focus", this.onFocus);
        window.addEventListener("blur", this.onBlur)
    }

    componentWillUnmount(){
        this.mounted = false;
        window.removeEventListener("focus", this.onFocus);
        window.removeEventListener("blur", this.onBlur)        
    }

    noProtocol = (string) => {
        //let res = (string.substring(0, 7) !== "http://") && (string.substring(0, 8) !== "https://");
        //console.log(string +" "+ res.toString());
        return (string.substring(0, 7) !== "http://") && (string.substring(0, 8) !== "https://")  && (string.substring(0, 2) !== "//")   
    }

    onChange = (isVisible) => {
        if(isVisible){
            this.allowPlay = true;
            this.videoElement.play();
            this.videoElement.setAttribute("autoplay","");
        }
        else{
            this.allowPlay = false;
            this.videoElement.removeAttribute("autoplay","");
            this.videoElement.pause();
        }
        //console.log('Element is now %s', isVisible ? 'visible' : 'hidden');
      }    

      onBlur = () => {
          if(this.videoElement !== undefined){
            this.videoElement.removeAttribute("autoplay","");
            this.videoElement.pause();
          }
      }

      onFocus = () => {
        if(this.videoElement !== undefined && this.allowPlay){
          this.videoElement.play();
          this.videoElement.setAttribute("autoplay","");
        }
      }

    showIframe = () => {
        // if(this.isTwitch){

        // }
        this.videoWidth = '350px';
        this.videoHeight = '200px';
        this.props.forceExpand();        
        this.setState({iframe:true});
    }

    expandIframe = () => {
        // if(this.isTwitch){

        // }
        this.videoWidth = '350px';
        this.videoHeight = '200px';
        this.props.forceExpand();        
    }

    removeQuotes = (string) => {
        if (string.charAt(0) === '“' && string.charAt(string.length -1) === '”')
        {
           return string.substr(1,string.length -2); 
        }
        else{
            return string
        }
    }

    extractUrl = (string) => {
        let urls = string.match(/\bhttps?:\/\/\S+/gi);
        return urls;
    }    

    validTwitterVideo = (string) => {
        let tw = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;
        
        //let split = string.split("/");
        this.videoUrl = string;
        return (string !== undefined ? ((string.match(tw)) ? true : false) : false);
    }

    validVimeo = (string) => {        
        let vm = /http(?:s)?:\/\/(?:www\.)?vimeo\.com\/([a-zA-Z0-9_]+)/;
        //let split = string.split("/");
        let video = string.match(vm);
        if(video !== null){            
            //this.videoUrl = video[0];
            if(this.state.videoUrl !== video[0]){
                this.setState({videoUrl:video[0]})
            }
            //console.log(this.videoUrl);
            return true;
        }
        //this.videoUrl = string;
        
        return false;
    }     
    
    validGif = (string) => {        
        let tenor = /http(?:s)?:\/\/(?:media\.)?tenor\.com\/videos\/([a-zA-Z0-9_]+)/;
        let gfycat = /http(?:s)?:\/\/(?:thumbs\.)?gfycat\.com\/([a-zA-Z0-9_]+)/;
        let giphy = /http(?:s)?:\/\/(?:media(?:[0-9])?\.)?giphy\.com\/media\/([a-zA-Z0-9_]+)/;
        //let split = string.split("/");
        let video = string.match(tenor);
        let video2 = string.match(gfycat);
        let video3 = string.match(giphy);
        //console.log(video3);
        if(video !== null || video2 !== null){            
            this.videoUrl = string;
            if(!this.state.hideText){                
                //console.log(string);
                this.setState({hideText:true});
            }
            return true;            
            // if(this.state.videoUrl !== video[0]){

            // }
            //console.log(this.videoUrl);
            return true;
        }
        if(video3 !== null){
            this.videoUrl = "https://media.giphy.com/media/"+video3[1]+"/giphy.mp4";
            if(!this.state.hideText){                
                this.setState({hideText:true});
            }
            
            return true;
        }

        //this.videoUrl = string;
        //console.log(string+" not a gif")
        return false;
    }    

    validFacebookVideo = (string) => {        
        let fb = /(?:https?:\/\/)?(?:www.|web.|m.)?facebook.com\/(?:video.php\?v=\d+|photo.php\?v=\d+|\?v=\d+)|\S+\/videos\/((\S+)\/(\d+)|(\d+))\/?/;        
        //let split = string.split("/");
        let video = string.match(fb);
        if(video !== null){            
            //this.videoUrl = video[0];
            if(this.state.videoUrl !== video[0]){
                this.setState({videoUrl:video[0]})
            }
            //console.log(this.videoUrl);
            return true;
        }
        //this.videoUrl = string;
        
        return false;
    }    

    validDM = (string) => {
        let dm = /http(?:s)?:\/\/(?:www\.)?dailymotion\.com\/video\/([a-zA-Z0-9_]+)/;
        let video = string.match(dm);
        if(video !== null){            
            //this.videoUrl = video[0];
            this.videoUrl = "https://www.dailymotion.com/embed/video/"+video[1];
            //console.log(this.videoUrl);
            return true;
        }
        //this.videoUrl = string;
        
        return false;        
    }

    validTwitch = (string) => {
        let twitchUser = /http(?:s)?:\/\/(?:www\.)?twitch\.tv\/([a-zA-Z0-9_]+)/;        
        let twitchClip = /(?:https:\/\/)?clips\.twitch\.tv\/(\S+)/i;
        let twitchClip2 = /http(?:s)?:\/\/(?:www\.)?twitch\.tv\/([a-zA-Z0-9_]+)\/clip\/([a-zA-Z0-9_]+)/;
        let username = string.match(twitchUser);
        let clip = string.match(twitchClip);
        let clip2 = string.match(twitchClip2);

        if(clip2 !== null){
            this.isTwitch = true;
            this.twitchUrl = clip2[0];
            this.videoUrl = "https://clips.twitch.tv/embed?clip="+clip2[2]+"&autoplay=1&auto_play=1&parent=app.hyperceed.com";

            if(this.state.twitchData === null && !this.twitchLoading){
                this.twitchLoading = true;
                axios.get(this.host+'/twitch/clip/'+clip2[2],{params:{_token:this.props.token || this.getCookie("auth")}},{withCredentials: true})
                .then(res => {
            
                if(res.data.result === undefined && this.mounted){
                    this.setState({twitchData:res.data});
                }
            
                })
                .catch(err =>{
                console.log(err);
                }); 
            }

            return true;
        }         

        if(clip !== null){
            this.isTwitch = true;
            this.twitchUrl = clip[0];
            this.videoUrl = "https://clips.twitch.tv/embed?clip="+clip[1]+"&autoplay=1&auto_play=1&parent=app.hyperceed.com";

            if(this.state.twitchData === null && !this.twitchLoading){
                this.twitchLoading = true;
                axios.get(this.host+'/twitch/clip/'+clip[1],{params:{_token: this.props.token || this.getCookie("auth")}},{withCredentials: true})
                .then(res => {
            
                if(res.data.result === undefined && this.mounted){
                    this.setState({twitchData:res.data});
                }
            
                })
                .catch(err =>{
                console.log(err);
                }); 
            }

            return true;
        } 

        if(username !== null){
            this.isTwitch = true;
            this.twitchUrl = username[0];
            this.videoUrl = "https://player.twitch.tv/?channel="+username[1]+"&player=facebook&autoplay=1&auto_play=1&parent=app.hyperceed.com";

            if(this.state.twitchData === null && !this.twitchLoading){
                this.twitchLoading = true;
                
                axios.get(this.host+'/twitch/user/'+username[1],{params:{_token: this.props.token || this.getCookie("auth")}},{withCredentials: true})
                .then(res => {
            
                if(res.data.result === undefined && this.mounted){
                    this.setState({twitchData:res.data});
                }
            
                })
                .catch(err =>{
                console.log(err);
                }); 
            }
            return true;
        }

       
        return false
    }

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }    
    
    extractHostname(url3) {
        var url2 = new URL(url3);        

        return 'https://'+url2.hostname;
    
    }

    imageLoad = (res) => {
        if(res.target.width > res.target.height){
            this.setState({limitWidth:false})
        }
        else{
            this.setState({limitWidth:true})
        }
    }

    render() {
        return (
            <div className="rich-link-wrapper inline-block" style={{padding:'10px',borderRadius:'10px'}}>
                {
                    <a className="right" target='_blank' rel="noopener" href={this.props.message}><OpenInNew/></a>
                }
                    {(this.props.meta["og:image"] !== undefined && this.props.meta["og:image:width"] === undefined && !this.validTwitch(this.props.message) ? (this.noProtocol(this.props.meta["og:image"]) ?
                        <div style={{padding:'10px'}}>
                            <img className={"rich-link-img-limit-"+(this.state.limitWidth ? 'width' : 'height')} alt="Failed to load image" onLoad={this.imageLoad} src={this.host+"/image/resize2?size=150&url="+encodeURIComponent(this.extractHostname((this.props.meta['og:url']!==undefined?this.props.meta['og:url']:this.props.message))+this.props.meta['og:image'])}/>
                         </div>
                      : 
                      <div style={{padding:'10px'}}>
                        <img className={"rich-link-img-limit-"+(this.state.limitWidth ? 'width' : 'height')} alt="Failed to load image" onLoad={this.imageLoad} src={this.host+"/image/resize2?size=150&url="+encodeURIComponent(this.props.meta['og:image'])}/>
                        </div>
                      ):null)} 
                <div>
                    {/* {(this.hideText ? <h6>Hide</h6>:<h6>Show</h6>)} */}
                    {(!this.state.hideText ? <h6 className={"rich-link-title bold-text"} style={{maxWidth:this.videoWidth,wordBreak:'break-word'}}>{this.props.meta.title}</h6> : null)}
                    {(this.props.meta["og:description"] !== undefined || this.props.meta["description"] !== undefined && !this.state.hideText ? <h6 className="rich-link-site-des-text grey-text" style={{maxWidth:this.videoWidth,wordBreak:'break-word'}}><Linkify componentDecorator={componentDecorator}>{this.removeQuotes(this.props.meta["og:description"] || this.props.meta["description"] || '')}</Linkify></h6> : null)}
                    {(this.state.twitchData !== null ? <a href={this.twitchUrl} rel="noopener" target="_blank">{this.state.twitchData[0].display_name}</a> : null)}
                    {(this.state.twitchData !== null ? <a href={this.twitchUrl} rel="noopener" target="_blank">{this.state.twitchData[0].title}</a> : null)}
                    {                
                    (this.validGif(this.props.meta["twitter:player:stream"] !== undefined ? this.props.meta["twitter:player:stream"] : (this.props.meta["og:image"] !== undefined ? this.props.meta["og:image"] : (this.props.meta["og:video"] !== undefined ? this.props.meta["og:video"] : ""))) || this.props.meta["og:video:url"] !== undefined || this.props.meta["og:video"] !== undefined || this.validTwitch(this.props.message) || this.validFacebookVideo(this.props.message) || this.validVimeo(this.props.message) || this.validDM(this.props.message) ? (

                        this.validTwitterVideo(this.props.meta["og:video:url"])  || this.validTwitch(this.props.message)  || this.validDM(this.props.message) ?  
                        <div className={"iframe-wrapper black relative-content "+(!this.state.iframe?"video-wrapper" : "")} style={{width:this.videoWidth}}>
                            {(!this.state.iframe ? <div onClick={this.showIframe} className="linked" 
                            style={{
                                display:'flex',
                                alignItems:'center',
                                position:'absolute',
                                left:'0',
                                top:'0',
                                width:'100%',
                                height:'100%',
                                backgroundPosition:'center',
                                backgroundSize:'cover',
                                backgroundImage:'url(\''+this.host+"/image/resize2?size=300&url="+(this.state.twitchData !== null ? (this.state.twitchData[0].profile_image_url !== undefined ? this.state.twitchData[0].profile_image_url : (this.state.twitchData[0].thumbnail_url !== undefined ? this.state.twitchData[0].thumbnail_url : "")) : encodeURIComponent(this.props.meta['og:image']))+'\')'}}>
                                <PlayCircleFilledOutlined className="block-center white-text" style={{fontSize:'50px',borderRadius:'25px',backgroundColor:'#333333'}}/>
                            </div> : null)}
                            {(this.state.iframe? <iframe
                            src={this.videoUrl}
                            allowFullScreen
                            width={this.videoWidth}
                            height={this.videoHeight}
                            frameBorder="0"
                            ></iframe>:null)}
                        </div>
                        :    

                        <div className={ !this.validGif(this.props.meta["twitter:player:stream"] !== undefined ? this.props.meta["twitter:player:stream"] : (this.props.meta["og:video:url"] !== undefined ? this.props.meta["og:video:url"] : (this.props.meta["og:video"] !== undefined ? this.props.meta["og:video"] : (this.props.meta["og:image"] !== undefined ? this.props.meta["og:image"] : "") ))) ? 'video-wrapper' : 'gif-wrapper'} style={{width:this.videoWidth}}>
                        {(this.state.videoUrl !== "" ? <ReactPlayer
                            className="react-player"
                            url={this.state.videoUrl}
                            playing={false}
                            light={true}
                            controls={true}
                            width={this.videoWidth}
                            height="100%"
                            volume={1}
                            muted={false}
                            playing={true}
                            onClick={this.expandIframe}
                            config={{ 
                            youtube:{
                                playerVars: { showinfo: 1 } 
                            }                                
                            }}
                            /> :
                         (   
                            !this.validGif(this.props.meta["twitter:player:stream"] !== undefined ? this.props.meta["twitter:player:stream"] : (this.props.meta["og:video:url"] !== undefined ? this.props.meta["og:video:url"] : (this.props.meta["og:video"] !== undefined ? this.props.meta["og:video"] : (this.props.meta["og:image"] !== undefined ? this.props.meta["og:image"] : "")))) ?  
                           <ReactPlayer
                            className="react-player"
                            url={this.props.meta["og:video:url"] !== undefined ? this.props.meta["og:video:url"] : (this.props.meta["og:video"] !== undefined ? this.props.meta["og:video"] : "" )}
                            playing={false}
                            light={true}
                            controls={true}
                            width={this.videoWidth}
                            height="100%"
                            volume={1}
                            muted={false}
                            onClick={this.expandIframe}
                            playing={true}
                            config={{ 
                            youtube:{
                                playerVars: { showinfo: 1 } 
                            }                                
                            }}
                            />
                            :
                            <VisibilitySensor onChange={this.onChange}>
                                <video ref={e=>this.videoElement = e} style={{position:'absolute',top:0, backgroundColor:'#000000'}} loop width="100%" height="260px" muted>
                                    <source src={this.videoUrl} ></source>
                                </video>
                            </VisibilitySensor>
                         )
                            )}
                        </div> )
                        
                    :(this.props.meta["og:image:width"] !== undefined && this.props.meta["og:image"] !== undefined && !this.state.imageError ? <img onClick={()=>this.props.openViewer(this.props.meta['og:image'])} src={this.host+"/image/resize2?size=300&url="+encodeURIComponent(this.props.meta['og:image'])} style={{cursor:'zoom-in',borderRadius:'5px',maxWidth:"300px"}} alt="Unable to load image" onError={()=>this.setState({imageError : true})}/> : null))}
                    <h6 className="rich-link-site-name-text bold-text-2" style={{maxWidth:this.videoWidth,wordBreak:'break-word'}}>{this.props.meta["og:site_name"]}</h6>
                </div>
            </div>
        )
    }
}
