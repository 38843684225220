import React, {Component} from 'react'
import { Lens, ExitToAppRounded as Exit, HomeOutlined as Home, MoreVert } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import OverflowMenu from './OverflowMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons';
import Notification from './Notification';

//import CloseIcon from '@material-ui/icons/Close';
export class Header extends Component {

  constructor(props){
    super(props);
    this.navigateToURL = this.navigateTo.bind(this);
  }

  state = {
    menuShown : false
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }   

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.setState({menuShown:false});
      //this.props.change("emojiPickerShow",false);
    }
  }  

  menuClicked = (name) => {
    switch(name){
      case "settings":
        this.props.openSettings();
      break;

      case "about":
        this.props.showAbout();
      break;

      case "upgrade":
        this.props.showUpgrade();
      break;

      case "socket":
        if(this.props.socket.connected){
          this.props.socket.disconnect();
        }
        else{
          this.props.socket.connect();
        }
      break;
    }

    this.setState({menuShown:false});
  }

  componentDidMount(){
      document.addEventListener('mousedown', this.handleClickOutside);
      this.props.navigateTo(this.navigateToURL);
  }

  componentWillUnmount(){
      document.removeEventListener('mousedown', this.handleClickOutside);
  }

  navigateTo = (url) => {
    this.props.history.push("/"+url);
  }

  render(){
    return (

        <div className="valign-wrapper flex-row flex-no-shrink header-border-bottom" style={{padding:"11px 20px 11px 20px",position:'relative',height:"64px"}}>
              <div className="inline-block relative-content profile-pic-wrapper">
                <img className="circle" style={{width:'35px', height: '35px', marginRight:'12px'}} src={this.props.profilePhoto} alt="error"/>
                <div data-balloon-blunt aria-label="Online" data-balloon-pos="down" style={{position:'absolute',left:'-2px',bottom:'2px',height:"15px"}}>
                  <Lens className="tiny green-text status-icon" style={{fontSize:'15px'}}/>
                </div>
              </div>
              <div className="account-text-wrapper full-width" style={{minWidth:"1px"}} data-balloon-blunt aria-label={this.props.name + " ("+ this.props.username +")"} data-balloon-pos="down" >
                <h6 style={{margin:'0',display:'inline-block'}} className={(this.props.dark ? "white-text" : "") + " relative-content ellipsis-text full-width bold-text emphasize-text"}>{this.props.name}
                  <br/><span className="grey-text text-lighten-1">{this.props.username}</span>                  
                </h6>
              </div>
              <div className="account-action flex-row">
                  
                  {(this.props.socket !== undefined ? <Notification dark={this.props.dark} socket={this.props.socket} navigateTo={this.navigateTo}/> : null)}
                  <span className="icon-button-hover-2 linked" data-balloon-blunt aria-label="Dashboard" data-balloon-pos="down-right"><Link to="/"><Home className="tiny"></Home></Link></span>
                  {/* <div className="margin-right-2x" onClick={this.props.logout}><span data-balloon-blunt aria-label="Log Out" data-balloon-pos="down-right" ><Exit className="tiny"/></span></div> */}
                  <div data-balloon-blunt aria-label="More" data-balloon-pos="down-right" className={"icon-button-hover-2 linked" + (this.state.menuShown ? " unclickable" : "")} onClick={()=>this.setState({menuShown: !this.state.menuShown})}><span><MoreVert className="tiny"/></span>
                  </div>
                  <OverflowMenu dark={this.props.dark} logout={this.props.logout} wrapper={this.setWrapperRef} menuClicked={this.menuClicked} show={this.state.menuShown}/>
              </div>
              
            {/* <CloseIcon className="material-icons linked hide-on-med-and-up"/> */}

        </div>

    )

  }
}

export default Header
