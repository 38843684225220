import React, { Component } from 'react';
// import Draggable from 'react-draggable';
// import ChatArea from './layout/ChatArea';
import { Close,OpenInNew,OpenInBrowser } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import Knowledgebase from '../pages/Knowledgebase';

export default class DraggableWindow extends Component {


  constructor(props) {
    super(props);
    this.state = {dragging:false,pos: {x: ((window.innerWidth || document.documentElement.clientWidth || 
      document.body.clientWidth) -  420), y: 20},rel:null,id:0,channel:"",allowed:false,showClose:true};
    this.elemRef = React.createRef();
  }

  dragStart = (e) => {
    console.log(e);
    // this.dragged = e.currentTarget;
    // e.dataTransfer.effectAllowed = 'move';
    // e.dataTransfer.setData('text/html', this.dragged);
  };

  componentDidMount(){
    // if(this.props.ticketNumber === this.props.match.params[0]){
    //   this.props.closePopup(this.props.index);
    // }
    // else{
    //   this.setState({pos:{x: 400 + (20 * this.props.index) + 'px',y: 10 + (this.props.index * 20) + 'px'},allowed:true})
    // }
    
  }

  onMouseUp = (e) => {
    this.setState({dragging: false})
    e.stopPropagation();
    e.preventDefault();
    document.removeEventListener('mousemove', this.onMouseMove)
    document.removeEventListener('mouseup', this.onMouseUp)    
  };

    onMouseMove = (e) => {
    if (!this.state.dragging) return
    this.setState({
      pos: {
        x: e.pageX - this.state.rel.x,
        y: e.pageY - this.state.rel.y
      }
    })
    e.stopPropagation()
    e.preventDefault()
  };

  onMouseDown = (e) => {
    // only left mouse button
    if (e.button !== 0) return
    var pos = this.elemRef.current.getBoundingClientRect();
    if(!this.state.dragging){
      document.addEventListener('mousemove', this.onMouseMove)
      document.addEventListener('mouseup', this.onMouseUp)    
    }
    this.setState({
      dragging: true,
      rel: {
        x: e.pageX - pos.left,
        y: e.pageY - pos.top
      }
    })
    e.stopPropagation()
    e.preventDefault()
  };

  setId = (id,channel) => {
    this.setState({id:id,channel:channel});
  }

  showCloseButton = (close) => {
    this.setState({showClose:close})
  }

  componentDidUpdate(){
    if(this.props.match){
      if(this.props.ticketNumber === this.props.match.params[0]){
        this.props.closePopup(this.props.index);
      }
    }
  }

  render() {
    return (
      <div className="draggable-window kb-window" style={{left:this.state.pos.x ,top:this.state.pos.y}}>

        <div className="draggable-header"  ref={this.elemRef} onMouseDown={this.onMouseDown}>
          <h6 className='full-width'>Knowledgebase</h6>
          {(this.state.channel !== "" ? <div className='window-action'>
            <Tippy content={"Open in new tab"}>
              <Link target="_blank" to={(this.state.channel === 'LIVECHAT' ? '/livechat' : (this.state.channel === 'chat' ? '/chat' : "/ticket")) +"/" + this.props.ticketNumber}>
              <OpenInBrowser/>
              </Link>
            </Tippy>
          </div> : null)}
          {/* {(this.state.channel !== "" ? <div className='window-action'>
            <Tippy content={"Open in full"}>
              <Link to={(this.state.channel === 'LIVECHAT' ? '/livechat' : (this.state.channel === 'chat' ? '/chat' : "/ticket")) +"/" + this.props.ticketNumber}>
              <OpenInNew/>
              </Link>
            </Tippy>
          </div> : null)} */}
          {this.state.showClose || this.props.user.type === "admin" ? <Tippy content={"Close"}>
            <div className='window-action linked' onClick={()=>this.props.closePopup()}>
              <Close/>
            </div>
          </Tippy> : null}
        </div>
        <div className='draggable-chat-panel'>
          <Knowledgebase {...this.props}/> 
        </div>
     
      </div>
      );
  }
}
