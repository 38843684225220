import React, { Component } from 'react'

export class ConversationDate extends Component {  
    render() {
      //onsole.log(this.props.message);
      return (<div className="chat-date"><div className="chat-date-wrapper"><h6 className="mid-text bold-text-2">{this.props.date}</h6></div></div>)
    }
}

export default ConversationDate
