import React, { useEffect, useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
// import './css/custom-dropdown.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export default function ScoreChartPage() {
  const [data, setData] = useState([]);
  const [label, setLabel] = useState([]);
  const [timeFrame, setTimeFrame] = useState('Daily');

  useEffect(() => {
    // Fetch posts from the backend API
    axios.get('https://supercash.ai:8888/api/getVolumeDaily?token='+token)
      .then(response => {
        // console.log(response.data)
        // Update the state with the fetched data
        setLabel(response.data.labels);
        setData(response.data.datasets);
      })
      .catch(error => {
        // Handle errors
        console.error(error);
      });
  }, []);

  const dataLine = {
    labels:label,
    datasets:data,
  };

  function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
}   

  const token = getCookie("auth");

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
        ticks: {
          precision: 0, // Set the number of decimal places to 0
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Volume',
        },
        ticks: {
          precision: 0, // Set the number of decimal places to 0
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Call Volume Graph (' + timeFrame + ')', // Set the title for your chart
        font: {
          size: 16, // Adjust the font size as needed
        },
      },
      tooltip: {
        events: "mousemove",
        enabled: false, // Disable default tooltips
        external: (context) => {
          const tooltipEl = document.getElementById('chartjs-tooltip');
          if (!tooltipEl) {
            return;
          }
  
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }
          
          // console.log(tooltipModel);
          // Customize tooltip content
          let innerHTML = '<div>';

          tooltipModel.dataPoints.forEach((dataPoint) => {
            innerHTML += `
              <div class="mb-3">
                User: ${dataPoint.dataset.label}
                </br>
                Volume: ${dataPoint.raw}
                </br>
                Date: ${dataPoint.label}
                </br>
                Color: <span style="border:1.5px solid ${dataPoint.dataset.borderColor};background-color:${dataPoint.dataset.backgroundColor};width:10px;height:10px;display:inline-block;"></span>
                </br>
              </div>
            `;
          });
      
          innerHTML += '</div>';
      
          tooltipEl.innerHTML = innerHTML;
  
          // Set tooltip position
          const chartArea = tooltipModel.chart.chartArea || {};
          const position = tooltipModel.chart.canvas.getBoundingClientRect();
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = chartArea.left + tooltipModel.caretX - 40 + 'px';
          tooltipEl.style.top = chartArea.bottom + tooltipModel.caretY + 80 + 'px';
          // tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          // tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.background = "rgba(51, 51, 51, 0.9)";
          tooltipEl.style.color = 'white';
          tooltipEl.style.padding = '5px';
          tooltipEl.style.borderRadius = '0px 5px 5px 5px';
          tooltipEl.style.zIndex = '9999';
        },
      },
      events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    },
  };

  useEffect(() => {
    const tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    document.body.appendChild(tooltipEl);

    return () => {
      document.body.removeChild(tooltipEl);
    };
  }, []);

  const handleTimeframeChange = (event) => {
    const selectedTimeframe = event.target.value;
    setTimeFrame(selectedTimeframe);

    // Depending on the selected timeframe, update your chart data accordingly.
    switch (selectedTimeframe) {
      case 'Daily':
        axios.get('https://supercash.ai:8888/api/getVolumeDaily?token='+token)
          .then((response) => {
            setLabel(response.data.labels);
            setData(response.data.datasets);
          })
          .catch((error) => {
            console.error(error);
          });
        break;
      case 'Weekly':
        axios.get('https://supercash.ai:8888/api/getVolumeWeekly?token='+token)
          .then((response) => {
            setLabel(response.data.labels);
            setData(response.data.datasets);
          })
          .catch((error) => {
            console.error(error);
          });
        break;
      case 'Monthly':
        axios.get('https://supercash.ai:8888/api/getVolumeMonthly?token='+token)
          .then((response) => {
            setLabel(response.data.labels);
            setData(response.data.datasets);
          })
          .catch((error) => {
            console.error(error);
          });
        break;
      default:
        break;
    }
  }

  return (
    // <Container className='mt-3' style={{width:"900px", height:"500px"}}>
    //   <Row>
    //     <Col lg={12} className='mx-auto'>
    <>
      <select className="custom-dropdown" onChange={handleTimeframeChange}>
        <option value="Daily">Daily</option>
        <option value="Weekly">Weekly</option>
        <option value="Monthly">Monthly</option>
      </select>
      <Line data={dataLine} options={options} />
      <div id="chartjs-tooltip"></div>
    </>
    //     </Col>
    //   </Row>
    // </Container>
  );
}
