import React, { Component, useState } from 'react'

import Modal from 'react-modal';

import { Scrollbars } from 'react-custom-scrollbars';

import axios from 'axios';

import { DeleteOutline,EditOutlined } from '@material-ui/icons';

import Variable from './../../../variable';

function Note(props){

    const host = Variable.host;

    const [open,setOpen] = useState(false);

    const [text,setText] = useState("");

    const profileModalStyles = {
        content : {
          top                   : 'auto',
          left                  : 'auto',
          right                 : 'auto',
          bottom                : 'auto',
          width:'500px',
          maxHeight:'100vh',
          border: 'none',
          boxShadow: '0 2px 3px 0 rgba(60,64,67,.3),0 6px 10px 4px rgba(60,64,67,.15)',
          borderRadius : '15px',
          padding : '0px'
        },
        overlay : {
          backgroundColor: ('rgba(0,0,0,0.5)')
        }
    };    

    const openModal = () => {
        props.closeOverflowMenu();
        setOpen(true);
    }

    const closeModal = () => {
        setOpen(false);
    }

    const afterOpenModal = () => {

    }

    const textChanged = (e) => {
        setText(e.target.value);
        
    }

    const saveNote = () => {
        axios.post(host+'/note', {_token:getCookie("auth"),message:text,id:props.id,ticketId:props.ticketId},{withCredentials: true})
        .then(res => {
            setOpen(false);
            setText("");
        })
    }
    
    
    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }     

    return (
        <React.Fragment>
        <Modal
        isOpen={open}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={profileModalStyles}
        contentLabel="Example Modal"
        ariaHideApp={false} 
        closeTimeoutMS={200}       
                                        
        className={""+(props.dark ? "dark" : null)}   

        
      >

        <div className="flex-col" style={{width:'100%',maxHeight:"100vh"}}>
            <div className="ng-modal-main-header" style={{paddingTop:"30px",paddingBottom:0}}>
                <div className="full-width flex-row valign-wrapper margin-bottom-1x">
                    <h5 className="bold-text-2">Note</h5>
                </div>
            
            </div>    
            {/* <Scrollbars autoHeight autoHeightMin={200}> */}
                <div className="ng-modal-main-body padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x flex-row full-height">
                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                        <input className={"browser-default "} value={text} type="text" onChange={textChanged}/>
                        <span>Text</span>
                        <p className="red-text hide">This cannot be empty.</p>
                    </label>                    
                </div>
            {/* </Scrollbars> */}
            
            <div className="ng-modal-main-footer right-text">                      
                <button className="btn-flat ng-close-btn waves-effect waves-dark margin-right-1x" onClick={closeModal}>close</button>
                <button className={"btn "+(props.buttonClicked ? "disabled" : "")} onClick={saveNote}>Create</button>
            </div>            
        </div>          
        </Modal> 
            <div className="overflow-menu-item" style={{width:'200px'}} onClick={openModal}>
                <div className="valign-wrapper">
                    <EditOutlined/>
                    <span className="valign-wrapper overflow-menu-text bold-text unselectable margin-left-1x">Create a note</span>
                </div>
            </div>   
        </React.Fragment>     
    )
}
export default Note;