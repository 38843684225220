import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { LabelOutlined, CheckCircleOutline, AccessTime, Lens,AccountCircleOutlined,ExpandMore,ExpandLess,Call } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee,faComment,faEnvelope,faCheck } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faLine, faFacebookMessenger,faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';
import Color from 'color';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { IconPhone} from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

const emotionColor = {
    "neutral" : "#787878",
    "angry" : "#C0392B",
    "fear" : "#F4D03F",
    "frustration" : "#E67E22",
    "threat" : "#A93226",
    "commitment" : "#138D75",
    "satisfaction" : "#94C973",
    "appreciation" : "#DE3163",
    "gratitude" : "#82E0AA",
    "joy" : "#85C1E9",
    "disappointment" : "#90A4AE",
    "disappointed" : "#90A4AE",
    "sadness" : "#5885AF",
    "dissatisfaction" : "#FAD7A0",
    "sarcasm" : "#F5B7B1",
    "surprise" : "#D2B4DE",
    "concerned" : "#7D6608",
    "sad":"#455A64",
    "happy" : "#85C1E9",
}


export class ChatListItem extends Component {
    
    refBarArr = [];

    constructor(props){
        super(props);
       
    }

    getStyle = () => {
        if(this.props.channel.read){
            return itemStyle
        }
        else{
            return {
                backgroundColor: '#dddddd'
            }   
        }
    }

    selectedTicket = null;

    componentDidMount(){

    }
    

    componentDidUpdate(){
        
        // if(this.props.match.params["0"] !== this.selectedTicket){
        //     this.selectedTicket = this.props.match.params["0"];
        //     console.log("updated");
        // }
    }

    getSeverityColor = (letter) => {
        switch(letter){
            case "H":
                return "red-text";
            case "M":
                return "orange-text";
            case "L":
                return "green-text";
            case "default":
                    return "";
        }
    }    
    
    getSeverityFull = (letter) => {
        switch(letter){
            case "H":
                return "High";
            case "M":
                return "Medium";
            case "L":
                return "Low";
            case "default":
                    return "Unknown";
        }
    }    

    onItemClick = (id,ticket) => {
        //console.log("clicked on "+id);
        // this.props.loadChat(id,ticket);
        if(this.props.setClicked){
            this.props.setClicked();
        }
    };

    formatDate = (date) => {
        
        return new Date(date * 1000);
    }

    checkDayDiff = (date) => {
        let today = moment().startOf('day').diff(moment.unix(date),'days',true);
        // console.log(today);
        return today;
    }

    labelInfo = (id) => {
        let info = this.props.label.filter(label => label.id === id);
        
        if(info.length > 0){
            return info[0];
        }
        else{
            return null;
        }
    }

    getEmotionPercentage = (data,emotionString) => {
        let emotion = JSON.parse(emotionString);
            let total = emotion.anger + emotion.fear + emotion.happy + emotion.love + emotion.sad + emotion.surprise;
            if(emotion.curious){
                total = total + emotion.curious;
            }
            return emotion[data] === 0 ? 0 : Math.round(emotion[data] / total * 100);
    }

    getEmotionPercentage2 = (data) => {
        let total = 0;

        if(!Array.isArray(this.props.channel.emotion_2)){
            Object.keys(this.props.channel.emotion_2).map((key, index)=> {
                total = total + this.props.channel.emotion_2[key];
            })
            return this.props.channel.emotion_2[data] === 0 ? 0 : Math.round(this.props.channel.emotion_2[data] / total * 100);
        }
        else{
            return 0;
        }
    }

    
    getEmotionPercentage3 = (data,obj) => {
        let total = 0;

        if(!Array.isArray(obj)){
            Object.keys(obj.emotion_2).map((key, index)=> {
                total = total + obj.emotion_2[key];
            })
            return obj.emotion_2[data] === 0 ? 0 : Math.round(obj.emotion_2[data] / total * 100);
        }
        else{
            return 0;
        }
    }

    render() {
        //console.log(this.props.match.params["0"]);
        //console.log(this.props.channel.id);
        //const { id, title } = this.props.channel;
        return(
            (
                this.props.type === "ticket" ? 
                <Link
                    // onClick={(e)=>{this.onItemClick(this.props.channel.id,this.props.channel.ticket_number);this.props.setSelectedId(this.props);this.props.setSelectedId(this.props.channel.id)}}
                    className={'full-width ticket-item table-row left-panel-item linked ' + (this.props.match.params["0"] === this.props.channel.ticket_number ? 'selected' : '')+(this.props.channel.read ?  '' : ' unread')}
                    to={'/ticket/'+this.props.channel.ticket_number}>
                    <div className="table-cell ticket-cell channel-cell">
                        <FontAwesomeIcon className={"type-icon"} style={{fontSize:"18px",color : (this.props.channel.channel === 'FB_PAGE_POST' || this.props.channel.channel === 'FB_PM' ? "#1778f2" :(this.props.channel.channel === 'TW_DM' || this.props.channel.channel === 'TW_MENTION' ? "#00acee" : (this.props.channel.channel === "LINE_DM" ? "#00c300" : (this.props.channel.channel === "EMAIL" ? (this.props.dark ? "#9575CD":"#673AB7") : (this.props.channel.channel === "WAUCHAT" ? "#00BCD4" : (this.props.channel.channel === "MANUAL" ? "#78909C" :  (this.props.channel.channel === "WHATSAPP" ? "#075e54" : "#757575" ) ) )))) )}} icon={(this.props.channel.channel === 'FB_PAGE_POST' ? faFacebook :(this.props.channel.channel === 'TW_DM' || this.props.channel.channel === 'TW_MENTION' ? faTwitter : (this.props.channel.channel === "LINE_DM" ? faLine : (this.props.channel.channel === "EMAIL" ? faEnvelope :  (this.props.channel.channel === "WAUCHAT" ? faComment : (this.props.channel.channel === "FB_PM" ? faFacebookMessenger : (this.props.channel.channel === "WHATSAPP" ? faWhatsapp : faCoffee) ) ) ))) )} />
                    </div>
                    {/* <div className="ticket-avatar-container margin-right-1x flex-no-shrink table-cell">
                        {(this.props.channel.closed === 0 ? <LabelOutlined className="tiny left"/> : (this.props.channel.closed === 1 ? <AccessTime className="tiny left"/> : <FontAwesomeIcon icon={faCheck} className="tiny left"/>))}
                    </div> */}
                    <div className="table-cell ticket-cell body-cell"><span className="left-panel-ticket-number-text bold-text block">#{this.props.channel.ticket_number}</span></div>
                    <div className="table-cell ticket-cell body-cell ellipsis-text" style={{maxWidth:"300px"}}><span className="left-panel-item-text grey-text">{this.props.channel.title}</span></div>
                    <div className="full-width table-cell ticket-cell body-cell" style={{minWidth:"1px",maxWidth:"200px"}}>
                        
                            <div className="label-holder">
                                {(
                                    JSON.parse(this.props.channel.label).map((obj, i) => (
                                    (this.labelInfo(obj) !== null ? <span key={i} className="label" style={{color: Color(this.props.dark ? (this.labelInfo(obj)).colorDark :(this.labelInfo(obj)).color).isDark() ? "#fefefe" : "#333333",backgroundColor:(this.props.dark ? (this.labelInfo(obj)).colorDark :(this.labelInfo(obj)).color)}}>{(this.labelInfo(obj)).name}</span> : 
                                    <span key={i} className="label">Removed</span>
                                    )
                                    )
                                    )
                                    
                                    )}
                            </div>


                    </div>
                    <div className="table-cell ticket-cell body-cell">
                    {
                    
                    !Array.isArray(this.props.channel.emotion_2) ?

                    <ProgressBar className="browser-default margin-bottom-1x left-panel-progress">    
                        { Object.keys(this.props.channel.emotion_2).map((key, index) => (
                            <ProgressBar now={this.getEmotionPercentage2(key)}  key={index} ref={this.refBar} style={{backgroundColor:emotionColor[key]}}/>
                        )) 
                        }                                   
                    </ProgressBar>

                    : (  this.props.channel.emotion !== ""  ? <div>
                            {(
                            Array.isArray(this.props.channel.emotion_2) ?
                            <ProgressBar className="browser-default margin-bottom-1x left-panel-progress">                                            
                                <ProgressBar  className="red" now={this.getEmotionPercentage("anger",this.props.channel.emotion)} key={1} ref={this.refBar}/>
     

                                <ProgressBar className="yellow" now={this.getEmotionPercentage("fear",this.props.channel.emotion)} key={2} ref={this.refBar} />


                                
                                <ProgressBar className="green" now={this.getEmotionPercentage("happy",this.props.channel.emotion)} key={3}  ref={this.refBar}/>


                                
                                <ProgressBar className="pink" now={this.getEmotionPercentage("love",this.props.channel.emotion)} key={4}  ref={this.refBar}/>


                                
                                <ProgressBar className="blue" now={this.getEmotionPercentage("sad",this.props.channel.emotion)} key={5}  ref={this.refBar}/>

                                
                                <ProgressBar className="purple" now={this.getEmotionPercentage("surprise",this.props.channel.emotion)} key={6}  ref={this.refBar}/>


                                <ProgressBar className="brown" now={this.getEmotionPercentage("curious",this.props.channel.emotion)} key={7}  ref={this.refBar}/>
 
                            </ProgressBar> 
                            :
                            null
                            )}                          
                        </div> : null)}                        
                    </div>
                    <div className="table-cell ticket-cell body-cell">
                        <span>{this.props.channel.case_type}</span>
                    </div>
                    <div className="table-cell ticket-cell body-cell">
                        <span className={"bold-text "+this.getSeverityColor(this.props.channel.severity)}>{this.getSeverityFull(this.props.channel.severity)}</span>
                    </div>
                    <div className="table-cell ticket-cell body-cell">
                        <div className="valign-wrapper">                            
                            <span className="ticket-timestamp" style={{fontSize:'12px'}}>{(this.checkDayDiff(this.props.channel.ts) < 0 && this.checkDayDiff(this.props.channel.ts) > -1  ? <Moment unix format="kk:mm">{this.props.channel.ts}</Moment> :(this.checkDayDiff(this.props.channel.ts) >= 0 && this.checkDayDiff(this.props.channel.ts) < 1 ? "Yesterday" : <Moment unix format="D/M/YYYY">{this.props.channel.ts}</Moment>))}</span>
                            {/* {(!this.props.channel.read ?                
                                <Lens className="orange-text text-lighten-2 type-icon" style={{fontSize:'15px',marginLeft:'5px'}}/>
                            : null)}                               */}
                        </div>
                    </div>
    
                 {/* </React.Fragment>               */}
                    </Link>
                    :
                (this.props.type === "livechat" ? 
                    this.props.channel.map((obj,i) => (
                        <React.Fragment key={i}>
                        <div className="left-panel-list-item">
                        <Link
                            // onClick={(e)=>{this.onItemClick(obj.livechats[0].id,obj.livechats[0].ticket_number);}}
                            className={'left-panel-item linked ' + (this.props.match.params["0"] === obj.livechats[0].ticket_number ? 'selected' : '')+(obj.livechats[0].read ?  '' : ' unread')} to={'/livechat/'+obj.livechats[0].ticket_number}>
                                <AccountCircleOutlined className="margin-right-2x"/>
                                {/* <FontAwesomeIcon className="margin-right-2x" icon={faComment} /> */}
                            <div className="flex-col full-width live-chat-item-padding">
                                    <div className="valign-wrapper full-width">
                                    <span className="left-panel-item-text bold-text">{obj.session_name}</span>
                                    <span className="chat-timestamp" style={{fontSize:'12px'}}>{(this.checkDayDiff(obj.livechats[0].ts) < 0 && this.checkDayDiff(obj.livechats[0].ts) > -1  ? <Moment unix format="kk:mm">{obj.livechats[0].ts}</Moment> :(this.checkDayDiff(obj.livechats[0].ts) >= 0 && this.checkDayDiff(obj.livechats[0].ts) < 1 ? "Yesterday" : <Moment unix format="D/M/YYYY">{obj.livechats[0].ts}</Moment>))}</span>
                                    {
                                       obj.collapsed ? 
                                        <div onClick={(e)=>{e.preventDefault();this.props.toggleCollapse(i)}}>
                                            <ExpandMore/>
                                        </div>
                                        :
                                        <div onClick={(e)=>{e.preventDefault();this.props.toggleCollapse(i)}}>
                                            <ExpandLess/>
                                        </div>
                                    }

                                    {/* {(!this.props.channel.read ? 
                                    <Lens className="orange-text text-lighten-2" style={{fontSize:'15px',marginLeft:'5px'}}/>
                                    
                                : null)}                           */}
                                </div>
                                    <div className="label-holder live-chat-label-margin">
                                                {(obj.livechats[0].label !== undefined ? 
                                                    JSON.parse(obj.livechats[0].label).map((obj2, k) => (
                                                    (this.labelInfo(obj2) !== null ? <span key={k} className="label" style={{color: Color(this.props.dark ? (this.labelInfo(obj2)).colorDark :(this.labelInfo(obj2)).color).isDark() ? "#fefefe" : "#333333",backgroundColor:(this.props.dark ? (this.labelInfo(obj2)).colorDark :(this.labelInfo(obj2)).color)}}>{(this.labelInfo(obj2)).name}</span> : 
                                                    <span key={k} className="label">Removed</span>
                                                    )
                                                    )
                                                    )
                                                    
                                                    : null)}
                                    </div> 
                                    {/* {this.props.emotion && !Array.isArray(this.props.channel.emotion_2) ? 
                                    
                                    <ProgressBar className="browser-default margin-bottom-1x left-panel-progress">    
                                    { Object.keys(this.props.channel.emotion_2).map((key, index) => (
                                        <ProgressBar now={this.getEmotionPercentage2(key)}  key={index} ref={this.refBar} style={{backgroundColor:emotionColor[key]}}/>
                                    )) 
                                    }                                   
                                    </ProgressBar>

                                    : (this.props.channel.emotion !== ""  && this.props.emotion ? <div>
                                    <ProgressBar className="browser-default margin-bottom-1x left-panel-progress margin-top-1x">                                            
                                        <ProgressBar  className="red" now={this.getEmotionPercentage("anger",this.props.channel.emotion)} key={1} ref={this.refBar}/>
            

                                        <ProgressBar className="yellow" now={this.getEmotionPercentage("fear",this.props.channel.emotion)} key={2} ref={this.refBar} />


                                        
                                        <ProgressBar className="green" now={this.getEmotionPercentage("happy",this.props.channel.emotion)} key={3}  ref={this.refBar}/>


                                        
                                        <ProgressBar className="pink" now={this.getEmotionPercentage("love",this.props.channel.emotion)} key={4}  ref={this.refBar}/>


                                        
                                        <ProgressBar className="blue" now={this.getEmotionPercentage("sad",this.props.channel.emotion)} key={5}  ref={this.refBar}/>

                                        
                                        <ProgressBar className="purple" now={this.getEmotionPercentage("surprise",this.props.channel.emotion)} key={6}  ref={this.refBar}/>


                                        <ProgressBar className="brown" now={this.getEmotionPercentage("curious",this.props.channel.emotion)} key={7}  ref={this.refBar}/>
        
                                    </ProgressBar>                            
                                </div> : null)}                             */}
                            </div>                
                            </Link>

                            </div>
                            <div className="live-chat-children-container">
                                {
                                    !obj.collapsed ?
                                        obj.livechats.map((obj,j)=>(
                                            <div className="left-panel-list-item relative-content live-chat-children-margin" key={j}>
                                            <Link
                                                // onClick={(e)=>{this.onItemClick(obj.id,obj.ticket_number);this.props.setSelectedId(obj.id)}}
                                                className={'left-panel-item-2 linked ' + (this.props.match.params["0"] === obj.ticket_number ? 'selected-2' : '')+(obj.read ?  '' : ' unread')} to={'/livechat/'+obj.ticket_number}><FontAwesomeIcon className="margin-right-2x" icon={faComment} />
                                                <div className="flex-col full-width live-chat-item-padding live-chat-session-item">
                                                        <div className="valign-wrapper full-width">
                                                        <span className="live-chat-session-text left-panel-item-text">{obj.title}</span>
                                                        <span className="chat-timestamp" style={{fontSize:'12px'}}>{(this.checkDayDiff(obj.ts) < 0 && this.checkDayDiff(obj.ts) > -1  ? <Moment unix format="kk:mm">{obj.ts}</Moment> :(this.checkDayDiff(obj.ts) >= 0 && this.checkDayDiff(obj.ts) < 1 ? "Yesterday" : <Moment unix format="D/M/YYYY">{obj.ts}</Moment>))}</span>
                                                        {/* {(!this.props.channel.read ? 
                                                        <Lens className="orange-text text-lighten-2" style={{fontSize:'15px',marginLeft:'5px'}}/>
                                                        
                                                    : null)}                           */}
                                                    </div>
                                                        <div className={"label-holder live-chat-label-margin"}>
                                                                    {(obj.label !== undefined ? 
                                                                        JSON.parse(obj.label).map((obj2, m) => (
                                                                        (this.labelInfo(obj2) !== null ? <span key={m} className="label" style={{color: Color(this.props.dark ? (this.labelInfo(obj2)).colorDark :(this.labelInfo(obj2)).color).isDark() ? "#fefefe" : "#333333",backgroundColor:(this.props.dark ? (this.labelInfo(obj2)).colorDark :(this.labelInfo(obj2)).color)}}>{(this.labelInfo(obj2)).name}</span> : 
                                                                        <span key={m} className="label">Removed</span>
                                                                        )
                                                                        )
                                                                        )
                                                                        
                                                                        : null)}
                                                        </div> 
                                                        {this.props.emotion && !Array.isArray(obj.emotion_2) ? 
                                                        
                                                        <ProgressBar className="browser-default margin-bottom-1x left-panel-progress new-emotion">    
                                                        { Object.keys(obj.emotion_2).map((key, index) => (
                                                            <ProgressBar now={this.getEmotionPercentage3(key,obj)}  key={index} ref={this.refBar} style={{backgroundColor:emotionColor[key]}}/>
                                                        )) 
                                                        }                                   
                                                        </ProgressBar>
                    
                                                        : (obj.emotion !== ""  && this.props.emotion ? <div>
                                                        <ProgressBar className="browser-default margin-bottom-1x left-panel-progress margin-top-1x">                                            
                                                            <ProgressBar  className="red" now={this.getEmotionPercentage("anger",obj.emotion)} key={1} ref={this.refBar}/>
                                
                    
                                                            <ProgressBar className="yellow" now={this.getEmotionPercentage("fear",obj.emotion)} key={2} ref={this.refBar} />
                    
                    
                                                            
                                                            <ProgressBar className="green" now={this.getEmotionPercentage("happy",obj.emotion)} key={3}  ref={this.refBar}/>
                    
                    
                                                            
                                                            <ProgressBar className="pink" now={this.getEmotionPercentage("love",obj.emotion)} key={4}  ref={this.refBar}/>
                    
                    
                                                            
                                                            <ProgressBar className="blue" now={this.getEmotionPercentage("sad",obj.emotion)} key={5}  ref={this.refBar}/>
                    
                                                            
                                                            <ProgressBar className="purple" now={this.getEmotionPercentage("surprise",obj.emotion)} key={6}  ref={this.refBar}/>
                    
                    
                                                            <ProgressBar className="brown" now={this.getEmotionPercentage("curious",obj.emotion)} key={7}  ref={this.refBar}/>
                            
                                                        </ProgressBar>                            
                                                    </div> : null)}                            
                                                </div>                
                                                </Link>
                    
                                                </div>                                        
                                        ))
                                    :
                                    null
                                }

                                    {/* {
                                        !obj.collapsed ? 
                                        <div className="divider margin-top-1x margin-bottom-1x flex-no-shrink"></div>
                                        :
                                        null
                                    }                                                             */}
                            </div>
                            </React.Fragment>
                        ))
                    :
                    <div className="left-panel-list-item">
                        <Link
                        // onClick={(e)=>{this.onItemClick(this.props.channel.id,this.props.channel.ticket_number);this.props.setSelectedId(this.props.channel.id)}}
                        className={'left-panel-item linked chat-item-padding ' + (this.props.match.params["0"] ? (this.props.match.params["0"].toString() === this.props.channel.ticket_number.toString() ? 'z-depth-1 selected' : '') : '')+(this.props.channel.read ?  '' : ' ')} to={'/call/'+this.props.channel.ticket_number.trim()}>                        
                        <IconPhone 
                            size={24} // set custom `width` and `height`
                            color={"#78909C"} // set `stroke` color
                            stroke={2}  // set `stroke-width`
                            strokeLinejoin="miter" // override other SVG props
                            className="margin-right-2x"
                        />  
                        <div className="full-width">
                            <div className="flex-row">
                                <span className="left-panel-item-text">{this.props.channel.title}</span>
                                <span className="chat-timestamp" style={{fontSize:'10px'}}>{(this.checkDayDiff(this.props.channel.ts) < 0 && this.checkDayDiff(this.props.channel.ts) > -1  ? <Moment unix format="kk:mm">{this.props.channel.ts}</Moment> :(this.checkDayDiff(this.props.channel.ts) >= 0 && this.checkDayDiff(this.props.channel.ts) < 1 ? "Yesterday" : <Moment unix format="D/M/YYYY">{this.props.channel.ts}</Moment>))}</span>
                            </div>
                            <div>
                                {!Array.isArray(this.props.channel.emotion_2) ? 
                                            
                                            <ProgressBar className="browser-default margin-bottom-1x left-panel-progress">    
                                            { Object.keys(this.props.channel.emotion_2).map((key, index) => (
                                                
                                                    <ProgressBar now={this.getEmotionPercentage2(key)}  key={index} ref={e=>this.refBarArr[index]=e} style={{backgroundColor:emotionColor[key.toLowerCase()]}}/>                                                        
                                                    
                                                    
                                             
                                            )) 
                                            }    
                                            { Object.keys(this.props.channel.emotion_2).map((key, index) => (
                                                    <Tippy  content={key} reference={this.refBarArr[index]}></Tippy>                       
                                            ))
                                            }
                                            </ProgressBar>
                                     

                                                            // <div>
                                                            //     {
                                                            //         Object.keys(this.props.channel.emotion_2).map((key,i)=>(
                                                            //             <span key={i} className="label emotion-count" style={{backgroundColor: emotionColor[key] ? emotionColor[key] : "#000000"}}>{this.props.channel.emotion_2[key]}</span>                                                                    
                                                            //         ))
                                                            //     }
                                                            // </div>
                                    :
                                    null
                                }                            
                            </div>
                        </div>
                        </Link>
                    </div>
                    )
                
                // {/* <input type="checkbox" className="filled-in material" onChange={this.props.checkRead.bind(this,this.props.channel.id)} id={ this.props.channel.id }/> */}
                // {/* <label className="material" htmlFor={ id }>Check Read</label> */}
                // {/* <p>{ title }</p> */}
                // {/* <button onClick={this.props.deleteChat.bind(this,id)} className="btn red waves-effect waves-light">Delete</button> */}
            )
        );
    }           
}

const itemStyle = {
    backgroundColor: '#eeeeee'
}

// ChatListItem.propTypes = {
//     channel: PropTypes.object.isRequired
// }

export default ChatListItem
