import React, { Component } from 'react';
import { BlockPicker } from 'react-color';
import { Delete } from '@material-ui/icons'
import axios from 'axios';
import {store} from 'react-notifications-component'

import { LabelOutlined, CheckCircleOutline, AccessTime, Lens } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee,faComment,faEnvelope,faCheck } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faLine } from '@fortawesome/free-brands-svg-icons'
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';
import Color from 'color';
import { Scrollbars } from 'react-custom-scrollbars';

import Select from 'react-select';

import chroma from 'chroma-js';

import ChatListItem from './../components/ChatListItem'

import Modal from 'react-modal';

import Variable from './../variable';

const options = [
    { value: 0, label: 'Close Ticket' },
    { value: 1, label: 'Delete Immediately' },
  ];

  const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
  
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });  

const selectStyles = {
    menu: styles => ({...styles,margin:'0',borderRadius:'5px',overflow:'hidden',zIndex:999,width:"300px"}),
    menuList: styles => ({...styles,backgroundColor:'#fefefe'}),
    valueContainer: styles => ({...styles, height:'30px',color:'#757575'}),
    control: styles => ({ ...styles, backgroundColor: '#fefefe',width:'300px', color:'#757575'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //console.log(data);
      //const color = chroma(data.color);
      
      const color = chroma('#fefefe');
      return {
        ...styles,
  
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#dddddd"
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':hover':{
          ...styles[':hover'],
          backgroundColor: '#eeeeee',
        },
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(1).css()),
        },
      };
    },
    input: (styles, { data }) => ({ ...styles, ...dot(data), height: '30px'}),
  
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data), color: '#757575' }),
  };
  
  const selectStylesDark = {
    menu: styles => ({...styles,margin:'0',backgroundColor:'#333333',borderRadius:'5px',overflow:'hidden',zIndex:999,width:"300px"}),
    valueContainer: styles => ({...styles, height:'30px',color:'#fefefe'}),
    control: styles => ({ ...styles, backgroundColor: '#333333',width:'300px', color:'#fefefe'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //console.log(data);
      //const color = chroma(data.color);
      
      const color = chroma('#000000');
      return {
        ...styles,
  
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#212121"
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':hover':{
          ...styles[':hover'],
          backgroundColor: '#424242',
        },
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(1).css()),
        },
      };
    },
    input: (styles, { data }) => ({ ...styles, ...dot(data), height: '30px'}),
    dropdownIndicator: (styles, { data }) => ({ ...styles, ':hover':{color:'#fefefe'}}),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data), color: '#fefefe' }),
  };

export default class componentName extends Component {

  host = Variable.host;

    state = {
        selectedSection : 0,
        labelList: [

        ],
        deleteList: [

        ],
        displayName: false,
        selectedOption:"Close Ticket",
        selectedValue: 0,    
        tabSelected:"ticket",
        ticket:[],
        livechat:[],
        loadMore: false,
        loadTicketInit:false,
        loadLiveChatInit:false,
        loadMoreLiveChat:false,
        loading:false
    }

    componentDidMount(){


      axios.get(this.host+'/api/ticket/user/list', {params:{type:this.state.tabSelected,_token:this.getCookie("auth"),token:this.getCookie("auth"),username:this.props.username,offset:0}},{withCredentials: true})
      .then(res => {      

        
        this.setState({ticket:res.data,loadMore:(res.data.length >= 10),loadTicketInit:true});
      })        


    }

    load = () => {
      if(this.state.tabSelected === "ticket" && !this.state.loadTicketInit){
        this.setState({loadTicketInit:true});
        axios.get(this.host+'/api/ticket/user/list', {params:{type:this.state.tabSelected,_token:this.getCookie("auth"),token:this.getCookie("auth"),username:this.props.username,offset:this.state.ticket.length}},{withCredentials: true})
        .then(res => {      
          let ticket = this.state.ticket;
          ticket = ticket.concat(res.data);
          this.setState({ticket:ticket,loadMore:(res.data.length >= 10)});
        })      
      }
      if(this.state.tabSelected === "livechat" && !this.state.loadLiveChatInit){
        this.setState({loadLiveChatInit:true});
        axios.get(this.host+'/api/ticket/user/list', {params:{type:this.state.tabSelected,_token:this.getCookie("auth"),token:this.getCookie("auth"),username:this.props.username,offset:this.state.livechat.length}},{withCredentials: true})
        .then(res => {      
          let ticket = this.state.livechat;
          ticket = ticket.concat(res.data);
          this.setState({livechat:ticket,loadMoreLiveChat:(res.data.length >= 10)});
        })  
      }
    }

    loadMore = () => {
      if(!this.state.loading){
        this.setState({loading:true},()=>{
          axios.get(this.host+'/api/ticket/user/list', {params:{type:this.state.tabSelected,_token:this.getCookie("auth"),token:this.getCookie("auth"),username:this.props.username,offset:this.state[this.state.tabSelected].length}},{withCredentials: true})
          .then(res => {      
            let ticket = this.state[this.state.tabSelected];
            ticket = ticket.concat(res.data);
            this.setState({[this.state.tabSelected]:ticket,loading:false});
            if(this.state.tabSelected === "ticket"){
              this.setState({
                loadMore:(res.data.length >= 10)
              })
            }
            else{
              this.setState({
                loadMoreLiveChat:(res.data.length >= 10)
              })
            }
          })    
        })
  
      }
    }    

    showPicker = (i) => {
        let labels = this.state.labelList;
        if(labels[i].picker){
            labels[i].picker = !labels[i].picker;  
        }
        else{
            labels[i].picker = true
        }
        
        this.setState({labelList:labels});
    }

    showPicker2 = (i) => {
        let labels = this.state.labelList;
        if(labels[i].pickerDark){
            labels[i].pickerDark = !labels[i].pickerDark;  
        }
        else{
            labels[i].pickerDark = true
        } 
        
        this.setState({labelList:labels});
    }    

    handleChange = (e,i,key) =>{ 
        let labels = this.state.labelList;
        labels[i][key] = e.target.value;

        labels.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return parseInt(b.level) -  parseInt(a.level);
          });    

        this.setState({labelList:labels});
    } 
    
    handleChangeComplete = (color,i) => {
        let labels = this.state.labelList;
        labels[i]["color"] = color;
        this.setState({labelList:labels});
      };   
      
      handleChangeCompleteDark = (color,i) => {
        let labels = this.state.labelList;
        labels[i]["colorDark"] = color;
        this.setState({labelList:labels});
      };       

    addLabel = () => {
        let labels = this.state.labelList;
        labels.push(            {
            "id":0,
            "name":"New Label",
            "color": "#000000",
            "colorDark": "#000000",
            "level":"0",
            "sla":"30",
            "isNew": true
        });
        this.setState({labelList:labels});
    }

    deleteLabel = (i) => {
        let labels = this.state.labelList;
        if(labels.isNew === true){
            labels.splice(i,1);
            this.setState({labelList:labels});
        }
        else{
            let deleteList = this.state.deleteList;
            deleteList.push({id : this.state.labelList[i].id});
            labels.splice(i,1);
            this.setState({labelList:labels,deleteList:deleteList});
        }
    }

    toggleDisplayName = () => {
        if(this.getCookie("display_name") !== undefined){
          var display  =!this.state.displayName
          this.setState({displayName: display });
    
          var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
          var expires = '; expires=' + date.toGMTString();
          document.cookie = 'display_name= '+ display + expires + '; path=/';    
          this.props.setDisplayName(display);        
        }
        else{
          this.setState({displayName: true });
          var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
          var expires = '; expires=' + date.toGMTString();
          document.cookie = 'display_name= '+ true + expires + '; path=/';     
          this.props.setDisplayName(true);
        }
        
      }    

      formatDate = (date) => {
        
        return new Date(date * 1000);
    }

    checkDayDiff = (date) => {
        let today = moment().diff(moment.unix(date),'days');
        return today;
    }      

      toggleAlwaysDisplay = () => {
        if(this.getCookie("always_display") !== undefined){
          var display  =!this.state.alwaysDisplay
          this.setState({alwaysDisplay: display });
    
          var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
          var expires = '; expires=' + date.toGMTString();
          document.cookie = 'always_display= '+ display + expires + '; path=/';    
          this.props.setAlwaysDisplay(display);        
        }
        else{
          this.setState({alwaysDisplay: true });
          var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
          var expires = '; expires=' + date.toGMTString();
          document.cookie = 'always_display= '+ true + expires + '; path=/';     
          this.props.setAlwaysDisplay(true);
        }
        
      }         

      handleSelectChange = selectedOption => {
      
        this.setState({ selectedOption: selectedOption.label,selectedValue:selectedOption.value });
        console.log(`Option selected:`, selectedOption);
        //this.props.setTicketStatus(this.props.id,selectedOption.value);
      };  
      
      saveDuration = () => {
          if(this.state.day !== undefined && this.state.day !== ""){
            axios.post(this.host+'/api/settings/update', {_token:this.getCookie("auth"),action:this.state.selectedValue,day:this.state.day},{withCredentials: true})
            .then(res => {
                store.addNotification({
                    title: "Success",
                    message: "Settings updated",
                    type: "success",
                    insert: "top",
                    container: "bottom-left",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                    duration: 3000,
                    onScreen: true
                    }
                });  
            }   
            ) 
        }      
      }

    updateLabel = () => {
        var regExp = new RegExp(/^#[0-9A-F]{6}$/i);
        let label = this.state.labelList;
        let error = false;
        let errorMsg = "";
        label.forEach(l => {
            if (l.name === "" || l.color ==="" || l.colorDark === "" || l.level === "" || l.sla === "") {
                errorMsg = "Please check all the fields are not empty.";
              error = true;
            }
            else if(isNaN(parseInt(l.sla)) || isNaN(parseInt(l.level))){
                errorMsg = "Priority level and SLA must be whole number.";
                error = true;
            }
            else if(!regExp.test(l.color.trim()) || !regExp.test(l.colorDark.trim())){
                errorMsg = "Color format is not correct";
                error = true;                
            }
          });
      
          if(error){
            store.addNotification({
                title: "Error",
                message: errorMsg,
                type: "danger",
                insert: "top",
                container: "bottom-left",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true
                }
              });                 
              return false;
          }
        axios.post(this.host+'/api/label/update', {_token:this.getCookie("auth"),labelArray:JSON.stringify(this.state.labelList),delete:JSON.stringify(this.state.deleteList)},{withCredentials: true})
        .then(res => {

            store.addNotification({
                title: "Success",
                message: "Label updated.",
                type: "success",
                insert: "top",
                container: "bottom-left",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true
                }
              });             
          //console.log(res);
          //console.log(res.data);
            //console.log(res);
            let labels = res.data;

            labels.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return parseInt(b.level) -  parseInt(a.level);
             });              
            this.setState({labelList:labels,deleteList:[]});
            this.props.updateLabel(res.data);
        }
        )
        .catch(err => {
            store.addNotification({
                title: "Error",
                message: "Please check the input.",
                type: "danger",
                insert: "top",
                container: "bottom-left",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true
                }
              });   
        })  ;      
    };

    updateDay = (e) => {
        this.setState({day:e.target.value})
    }

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }    

    labelInfo = (id) => {
      let info = this.props.label.filter(label => label.id === id);
      
      if(info.length > 0){
          return info[0];
      }
      else{
          return null;
      }
  }    

  render() {
    return (
        <div className="flex-col" style={{width:'100%',maxHeight:"100vh"}}>
        <div className="ng-modal-main-header" style={{paddingTop:"30px",paddingBottom:0}}>
          <div className="full-width flex-row valign-wrapper margin-bottom-1x">
              <img className="margin-right-1x avatar-wrapper" src={(this.props.userInfo[this.props.username] !== undefined ? (this.props.userInfo[this.props.username].photo !== "" ? (this.props.userInfo[this.props.username].photo.indexOf("www.superceed.com") === -1 ? "https://www.superceed.com" : "")+this.props.userInfo[this.props.username].photo : "https://www.superceed.com/images/system/default.png") : "https://www.superceed.com/images/system/default.png")}/>
              <h4 className="bold-text-2">{this.props.userInfo[this.props.username].name}</h4>
              {/* <p>{obj.email}</p> */}
          </div>
          <div className="custom-tab">
                            <div className={"custom-tab-item unselectable waves-effect "+(this.state.tabSelected === "ticket" ? "clicked" : "")+(this.props.dark ? " dark" : "")} onClick={()=>{this.setState({tabSelected:"ticket"},()=>{this.load()});}}>Tickets</div> 
                            <div className={"custom-tab-item unselectable waves-effect "+(this.state.tabSelected === "livechat" ? "clicked" : "")+(this.props.dark ? " dark" : "")} onClick={()=>{this.setState({tabSelected:"livechat"},()=>{this.load()});}}>Live Chat</div>                        
          </div>          
        </div>
        <div className="divider"></div>

              <Scrollbars autoHeight>
                <div className="ng-modal-main-body padding-top-2x padding-bottom-2x flex-row full-height">

                <div className="flex-col full-width">
                {(this.state[this.state.tabSelected].map((obj) => (
                    <div className="full-width">
                    { this.state.tabSelected === "ticket" ? 
                    <Link
                        onClick={this.props.closeModal}
                        // onClick={(e)=>this.onItemClick(obj.id,obj.ticket_number)}
                        className={'ticket-item left-panel-item linked '}
                        to={'/ticket/'+obj.ticket_number}>
                        <div className="ticket-avatar-container margin-right-1x flex-no-shrink">
                          <FontAwesomeIcon className={"type-icon"} style={{color : (obj.channel === 'FB_PAGE_POST' || obj.channel === 'FB_PM' ? "#1778f2" :(obj.channel === 'TW_DM' || obj.channel === 'TW_MENTION' ? "#00acee" : (obj.channel === "LINE_DM" ? "#00c300" : (obj.channel === "EMAIL" ? (this.props.dark ? "#9575CD":"#673AB7") : (obj.channel === "WAUCHAT" ? "#00BCD4" : (obj.channel === "MANUAL" ? "#78909C" : "#757575" ) )))) )}} icon={(obj.channel === 'FB_PAGE_POST' || obj.channel === 'FB_PM' ? faFacebook :(obj.channel === 'TW_DM' || obj.channel === 'TW_MENTION' ? faTwitter : (obj.channel === "LINE_DM" ? faLine : (obj.channel === "EMAIL" ? faEnvelope :  (obj.channel === "WAUCHAT" ? faComment : faCoffee ) ))) )} />
                          {/* {(obj.closed === 0 ? <LabelOutlined className="tiny left"/> : (obj.closed === 1 ? <AccessTime className="tiny left"></AccessTime> : <FontAwesomeIcon icon={faCheck} className="tiny left"/>))} */}
                        </div>
                        <div className="full-width" style={{minWidth:"1px"}}>
                            <span className="left-panel-ticket-number-text block bold-text">#{obj.ticket_number}
                                <div className="label-holder">
                                    {(
                                        JSON.parse(obj.label).map((obj, i) => (
                                        (this.labelInfo(obj) !== null ? <span key={i} className="label" style={{color: Color(this.props.dark ? (this.labelInfo(obj)).colorDark :(this.labelInfo(obj)).color).isDark() ? "#fefefe" : "#333333",backgroundColor:(this.props.dark ? (this.labelInfo(obj)).colorDark :(this.labelInfo(obj)).color)}}>{(this.labelInfo(obj)).name}</span> : 
                                        <span key={i} className="label">Removed</span>
                                        )
                                        )
                                        )
                                        
                                        )}
                                </div>
                            </span>
                            <div className="flex-row">
                                <span className="left-panel-item-text grey-text">{obj.title}</span>
                                
                            </div>
                            
                        </div>
                        
                        <div className="flex-row valign-wrapper">
                        <span className="ticket-timestamp no-margin" style={{fontSize:'12px'}}>{(this.checkDayDiff(obj.ts) === 0 ? <Moment unix format="kk:mm">{obj.ts}</Moment> :(this.checkDayDiff(obj.ts) === 1 ? "Yesterday" : <Moment unix format="D/M/YYYY">{obj.ts}</Moment>))}</span>
                          {(!obj.read ? 
                          <Lens className="orange-text text-lighten-2 type-icon" style={{fontSize:'15px',marginLeft:'5px'}}/>
                        
                    : null)}                    
                      </div>
                        </Link> :
                    (this.state.tabSelected === "livechat" ? 
                    <Link
                        // onClick={(e)=>this.onItemClick(obj.id,obj.ticket_number)}
                        onClick={this.props.closeModal}
                        className={'left-panel-item linked ' } to={'/livechat/'+obj.ticket_number}>
                          <FontAwesomeIcon className="margin-right-2x" icon={faComment} />
                          <div className="live-chat-item-padding flex-row full-width">
                          
                            <div className="valign-wrapper">
                              <span className="left-panel-item-text margin-right-2x" style={{minWidth:'1px'}}>{obj.name}</span> 
                          </div>
                          <div className="label-holder no-margin full-width">
                                    {(
                                        JSON.parse(obj.label).map((obj, i) => (
                                        (this.labelInfo(obj) !== null ? <span key={i} className="label" style={{color: Color(this.props.dark ? (this.labelInfo(obj)).colorDark :(this.labelInfo(obj)).color).isDark() ? "#fefefe" : "#333333",backgroundColor:(this.props.dark ? (this.labelInfo(obj)).colorDark :(this.labelInfo(obj)).color)}}>{(this.labelInfo(obj)).name}</span> : 
                                        <span key={i} className="label">Removed</span>
                                        )
                                        )
                                        )
                                        
                                        )}
                          </div>    
                          <div className="flex-row valign-wrapper">
                            <span className="chat-timestamp" style={{fontSize:'12px'}}>{(this.checkDayDiff(obj.ts) === 0 ? <Moment unix format="kk:mm">{obj.ts}</Moment> :(this.checkDayDiff(obj.ts) === 1 ? "Yesterday" : <Moment unix format="D/M/YYYY">{obj.ts}</Moment>))}</span>
                                {(!obj.read ? 
                                <Lens className="orange-text text-lighten-2" style={{fontSize:'15px',marginLeft:'5px'}}/>
                            : null)}                               
                            </div>                      
                        </div>   
                                           
                        </Link>
                        :
                        <Link
                        // onClick={(e)=>this.onItemClick(obj.id,obj.ticket_number)}
                        onClick={this.props.closeModal}
                        className={'left-panel-item linked ' } to={'/chat/'+obj.ticket_number}><FontAwesomeIcon className="margin-right-2x" icon={faComment} /><span className="left-panel-item-text">{obj.title}</span>
                        <span className="chat-timestamp" style={{fontSize:'12px'}}>{(this.checkDayDiff(obj.ts) === 0 ? <Moment unix format="kk:mm">{obj.ts}</Moment> :(this.checkDayDiff(obj.ts) === 1 ? "Yesterday" : <Moment unix format="D/M/YYYY">{obj.ts}</Moment>))}</span>
                        {(!obj.read ? 
                        <Lens className="orange-text text-lighten-2" style={{fontSize:'15px',marginLeft:'5px'}}/>
                    : null)}                          
                        </Link>
                        )
                    }
                    {/* <input type="checkbox" className="filled-in material" onChange={this.props.checkRead.bind(this,obj.id)} id={ obj.id }/> */}
                    {/* <label className="material" htmlFor={ id }>Check Read</label> */}
                    {/* <p>{ title }</p> */}
                    {/* <button onClick={this.props.deleteChat.bind(this,id)} className="btn red waves-effect waves-light">Delete</button> */}
                </div>                  
                )
                ))}  
                {((this.state.loadMore && this.state.tabSelected === "ticket" ) || (this.state.loadMoreLiveChat && this.state.tabSelected === "livechat" ) ? <button className={"button-new accent-button margin-top-2x margin-left-2x margin-right-2x"+(this.state.loading ? " disabled" : "")} onClick={this.loadMore}>Load More</button> : null)}
                </div>           
                </div>
                    </Scrollbars>
        <div className="ng-modal-main-footer right-text">                      
          <button className="btn-flat ng-close-btn waves-effect waves-dark" onClick={this.props.closeModal}>close</button>
          {/* <button className="btn waves-effect waves-light margin-left-1x" onClick={this.closeModal}>OK</button> */}
        </div>
      </div>
    );
  }
}
