import React,{useState,useEffect,useRef} from 'react'
import { Close,Menu,AssessmentOutlined,Lens,HomeOutlined,RadioOutlined,LibraryMusicOutlined,CallRounded,SettingsOutlined,Visibility } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell,faCheckDouble,faSearch,faUser,faComment, faTicketAlt, faHeadset,faCoffee,faEnvelope,faVectorSquare,faCloudUploadAlt,faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import Search from './Search';
import OnlineUser from './OnlineUser';
import { Link } from 'react-router-dom';
import OverflowMenu from './OverflowMenu';
import Notification from './Notification';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { IconDeviceAudioTape, IconLanguage, IconPhone } from '@tabler/icons-react';

// import {  } from '@fortawesome/free-solid-svg-icons'

function useOutsideAlerter(ref,setOpened) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpened(false);
        }
      }
  
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}

function useOutsideAlerter2(ref,setOpened) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpened(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}


export default function LeftPanel(props) {

    const [opened,setOpened] = useState(false);
    const [userMenuOpened,setUserMenuOpened] = useState(false);

    let navigateToURL = navigateTo.bind(this);

    useEffect(() => {
      props.navigateTo(navigateToURL);
    }, []);

    function toggleOpen(){
        setOpened(!opened);
        
    }

    function toggleUserMenu(){
      setUserMenuOpened(!userMenuOpened);
    }

    function menuClicked(name) {
      switch(name){
        case "settings":
          props.openSettings();
        break;
  
        case "about":
          props.showAbout();
        break;
  
        case "upgrade":
          props.showUpgrade();
        break;
  
        case "socket":
          if(props.socket.connected){
            props.socket.disconnect();
          }
          else{
            props.socket.connect();
          }
        break;
      }
  
      setUserMenuOpened(false);
    }
    
    function navigateTo(url) {
      props.history.push("/"+url);
    }    

    const wrapperRef = useRef(null);

    const wrapperRef2 = useRef(null);

    useOutsideAlerter(wrapperRef,setOpened);

    useOutsideAlerter2(wrapperRef2,setUserMenuOpened);


    return (
        <React.Fragment>
            <div ref={wrapperRef} className={"hyperceed-left-panel flex-col "+(opened ? "left-panel-opened" : "left-panel-closed")} style={{paddingLeft:"10px",paddingRight:"10px",paddingTop:"10px",backgroundColor:(props.dark ? "#212121": "#37474F")}}>
                <div onClick={toggleOpen} className="left-panel-icon-container flex-no-shrink hover-tint-4 linked" style={{backgroundColor:(props.dark ? "#212121": "#37474F"),width:'40px'}}>
                    <div className="flex-no-shrink mid-text" style={{width:'40px'}}>
                      <Menu/>
                    </div>
                </div>   
                <Link to={'/'}>
                  {/* <div className="valign-wrapper"> */}
                  <Tippy content="Home" placement="right">
                      <div className="left-panel-icon-container hover-tint-4 linked flex-no-shrink valign-wrapper" style={{backgroundColor:(props.dark ? "#212121": "#37474F")}}>
                          <div className="flex-no-shrink mid-text" style={{width:'40px'}}>
                            <HomeOutlined />           
                          </div>
                          <span className="white-text">Home</span>           
                      </div>
                    </Tippy>
                             
                    {/* </div>      */}
                </Link>                 
                {/* <Search dark={props.dark} getCookie={props.getCookie} label={props.label}/>        */}
                <OnlineUser userInfo={props.userInfo} dark={props.dark}/>    
                <Link to={'/report/omnichannel'}>
                  <Tippy content="Report" placement="right">
                    <div className="left-panel-icon-container hover-tint-4 linked" style={{backgroundColor:(props.dark ? "#212121": "#37474F")}}>
                      <div className="flex-no-shrink mid-text" style={{width:'40px'}}>
                        <AssessmentOutlined />                       
                      </div>
                      <span className="white-text">Report</span>  
                    </div>    
                  </Tippy>        
                </Link>        
                {/* <Link to={'/ticket'}>
                  <Tippy content="Tickets" placement="right">
                    <div className="left-panel-icon-container hover-tint-4 linked" style={{backgroundColor:(props.dark ? "#212121": "#37474F")}}>
                      <div className={"flex-no-shrink mid-text " + (props.ticketUnread ? "mask-top-right-2" : "")} style={{width:'40px'}}>
                        <FontAwesomeIcon style={{fontSize:"20px"}} icon={faTicketAlt}/>
                      </div>
                      <span className="white-text">Tickets</span>   
                      {(props.ticketUnread ? <span className="icon-badge" style={{top:"7px",left:"25px"}}></span> : null)}      
                    </div>
                  </Tippy>
                  </Link>  */}
                  {/* <Link to={'/chat'}>
                    <Tippy content="Chats" placement="right">
                      <div className="left-panel-icon-container hover-tint-4 linked" style={{backgroundColor:(props.dark ? "#212121": "#37474F")}}>
                        <div className={"flex-no-shrink mid-text" + (props.chatUnread ? " mask-top-right-2" : "")} style={{width:'40px'}}>
                          <FontAwesomeIcon style={{fontSize:"20px"}} icon={faComment}/>
                        </div>
                        <span className="white-text">Chats</span>   
                        {(props.chatUnread ? <span className="icon-badge" style={{top:"7px",left:"25px"}}></span> : null)}
                      </div>
                    </Tippy>
                  </Link>  */}
                  {/* <Link to={'/interactions'}>
                    <Tippy content="Interactions" placement="right">
                      <div className="left-panel-icon-container hover-tint-4 linked" style={{backgroundColor:(props.dark ? "#212121": "#37474F")}}>
                        <div className={"flex-no-shrink mid-text" + (props.chatUnread ? " mask-top-right-2" : "")} style={{width:'40px'}}>
                          <FontAwesomeIcon style={{fontSize:"20px"}} icon={faVectorSquare}/>
                        </div>
                        <span className="white-text">Interactions</span>   
                        {(props.chatUnread ? <span className="icon-badge" style={{top:"7px",left:"25px"}}></span> : null)}
                      </div>
                    </Tippy>
                  </Link>     */}
                  {/* <Link to={'/upload'}>
                    <Tippy content="Upload" placement="right">
                      <div className="left-panel-icon-container hover-tint-4 linked" style={{backgroundColor:(props.dark ? "#212121": "#37474F")}}>
                        <div className={"flex-no-shrink mid-text" + (props.chatUnread ? " mask-top-right-2" : "")} style={{width:'40px'}}>
                          <FontAwesomeIcon style={{fontSize:"20px"}} icon={faCloudUploadAlt}/>
                        </div>
                        <span className="white-text">Upload</span>   
                        {(props.chatUnread ? <span className="icon-badge" style={{top:"7px",left:"25px"}}></span> : null)}
                      </div>
                    </Tippy>
                  </Link>      */}
                  <Link to={'/call'}>
                    <Tippy content="Call" placement="right">
                      <div className="left-panel-icon-container hover-tint-4 linked" style={{backgroundColor:(props.dark ? "#212121": "#37474F")}}>
                        <div className={"flex-no-shrink mid-text" + (props.chatUnread ? " mask-top-right-2" : "")} style={{width:'40px'}}>
                          {/* <FontAwesomeIcon style={{fontSize:"20px"}} icon={faPhoneAlt}/> */}
                              <IconPhone
                                  size={24} 
                                  color="white" 
                                  stroke={2} 
                                  strokeLinejoin="miter"
                              /> 
                        </div>
                        <span className="white-text">Call</span>   
                        {(props.chatUnread ? <span className="icon-badge" style={{top:"7px",left:"25px"}}></span> : null)}
                      </div>
                    </Tippy>
                  </Link>          
                  <Link to={'/recording'}>
                    <Tippy content="Recordings" placement="right">
                      <div className="left-panel-icon-container hover-tint-4 linked" style={{backgroundColor:(props.dark ? "#212121": "#37474F")}}>
                        <div className={"flex-no-shrink mid-text" + (props.chatUnread ? " mask-top-right-2" : "")} style={{width:'40px'}}>
                          {/* <FontAwesomeIcon style={{fontSize:"20px"}} icon={faPhoneAlt}/> */}
                          <LibraryMusicOutlined/>
                        </div>
                        <span className="white-text">Recordings</span>   
                        {(props.chatUnread ? <span className="icon-badge" style={{top:"7px",left:"25px"}}></span> : null)}
                      </div>
                    </Tippy>
                  </Link>  
                  <Link to={'/call_config'}>
                    <Tippy content="Configurations" placement="right">
                      <div className="left-panel-icon-container hover-tint-4 linked" style={{backgroundColor:(props.dark ? "#212121": "#37474F")}}>
                        <div className={"flex-no-shrink mid-text" + (props.chatUnread ? " mask-top-right-2" : "")} style={{width:'40px'}}>
                          {/* <FontAwesomeIcon style={{fontSize:"20px"}} icon={faPhoneAlt}/> */}
                          <SettingsOutlined/>
                        </div>
                        <span className="white-text">Configurations</span>   
                        {(props.chatUnread ? <span className="icon-badge" style={{top:"7px",left:"25px"}}></span> : null)}
                      </div>
                    </Tippy>
                  </Link>    
                  <Link to={'/supervisory'}>
                    <Tippy content="Supervisory" placement="right">
                      <div className="left-panel-icon-container hover-tint-4 linked" style={{backgroundColor:(props.dark ? "#212121": "#37474F")}}>
                        <div className={"flex-no-shrink mid-text" + (props.chatUnread ? " mask-top-right-2" : "")} style={{width:'40px'}}>
                          {/* <FontAwesomeIcon style={{fontSize:"20px"}} icon={faPhoneAlt}/> */}
                          <Visibility/>
                        </div>
                        <span className="white-text">Supervisory</span>   
                        {(props.chatUnread ? <span className="icon-badge" style={{top:"7px",left:"25px"}}></span> : null)}
                      </div>
                    </Tippy>
                  </Link>   
                  <Link to={'/voice_generation'}>
                    <Tippy content="Voice Generator" placement="right">
                      <div className="left-panel-icon-container hover-tint-4 linked" style={{backgroundColor:(props.dark ? "#212121": "#37474F")}}>
                        <div className={"flex-no-shrink mid-text" + (props.chatUnread ? " mask-top-right-2" : "")} style={{width:'40px'}}>
                          {/* <FontAwesomeIcon style={{fontSize:"20px"}} icon={faPhoneAlt}/> */}
                          <IconDeviceAudioTape
                                  size={24} 
                                  color="white" 
                                  stroke={2} 
                                  strokeLinejoin="miter"
                              /> 
                        </div>
                        <span className="white-text">Voice Generator</span>   
                        {/* {(props.chatUnread ? <span className="icon-badge" style={{top:"7px",left:"25px"}}></span> : null)} */}
                      </div>
                    </Tippy>
                  </Link>                                       
                {/* <div className="left-panel-icon-container linked">
                    <Close/>
                </div> */}
                <div className="full-height">

                </div>
                {/* {(props.socket !== undefined ? <Notification dark={props.dark} socket={props.socket} navigateTo={navigateTo}/> : null)} */}
              <div ref={wrapperRef2} className="inline-block flex-no-shrink relative-content profile-pic-wrapper margin-bottom-1x linked" style={{width:'35px'}} onClick={toggleUserMenu}>
                <img className="circle mask-top-right" style={{width:'35px', height: '35px'}} src={props.profilePhoto}/>
                <div>
                  <div  style={{position:'absolute',right:'-2px',top:'-8px',height:"15px"}}>
                    <Lens className="tiny green-text status-icon" style={{fontSize:'15px'}}/>
                  </div>
                </div>
              </div>     
              <OverflowMenu dark={props.dark} toggleUserMenu={toggleUserMenu} logout={props.logout} menuClicked={menuClicked} wrapper={wrapperRef2} show={userMenuOpened}/>           
            </div>
            {/* {(opened ? <div className="flex-no-shrink hyperceed-left-panel" style={{width:"60px"}}></div> : null)} */}
        </React.Fragment>
    )
}
