import React, { Component } from 'react'
import {PlayCircleFilledOutlined, PauseCircleOutline,VolumeMuteRound, VolumeUpRounded} from '@material-ui/icons'
import { IconVolume,IconVolume3,IconPlayerPlay,IconPlayerPause } from '@tabler/icons-react';

export default class CustomAudioPlayer extends Component {

    state = {
        src : "",
        currentTime : 0,
        duration : 0,
        play: false,
        canPlay: false,
        hoverLeft:0,
        hoverTime:"",
        volume: 0.2,
        hovered:false,
        playHeadPressed:false,
        error:false
    }

    startPosition = 0;
    trackLength = 0;
    onPlayHead = false;

    updateInterval;

    currentTime = 0;

    

    constructor(props){
        super(props);
        this.audioElem = React.createRef();
    }

    errorEvent = () => {
        this.setState({error:true});
    }

    componentDidMount(){
        //window.addEventListener("mouseup",this.windowMouseUp);
        this.audioPlayer.volume = 0.2;
        this.audioPlayer.addEventListener("timeupdate", this.timeUpdate);
        this.audioPlayer.addEventListener("ended", this.audioEnded);
        this.audioPlayer.addEventListener("loadedmetadata",this.loadData);
        this.audioPlayer.addEventListener("error",this.errorEvent);

          this.audioPlayer.addEventListener('canplaythrough',(e)=>{
            // console.log("ha");
            // this.updateInterval = setInterval(this.timeUpdate2,100);
            // requestAnimationFrame(this.timeUpdate2)
            if(this.state.play){
                cancelAnimationFrame(this.updateInterval)
                this.updateInterval = requestAnimationFrame(this.timeUpdate2)
            }
            this.setState({
                currentTime: e.target.currentTime,
                canPlay: true
              });
          });           
    }

    componentDidUpdate(){
        if(this.props.src !== this.state.src && this.state.src !== ""){
            this.setState({canPlay:false,src:"",play:false,error:false});
            this.audioPlayer.pause();
        }
    }

    toHHMMSS = (duration) => {
        let sec_num = parseInt(duration, 10);
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - hours * 3600) / 60);
        let seconds = sec_num - hours * 3600 - minutes * 60;
    
        if (hours < 10) {
          hours = "0" + hours;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
    
        return hours + ":" + minutes + ":" + seconds;
    };

    timeUpdate = (e) => {
        this.trackLength = e.target.duration;
        this.setState({
          currentTime: e.target.currentTime,
        });

        if(this.props.percentageCallBack){
            this.props.percentageCallBack(this.state.currentTime/this.state.duration*100);
        }
        if(this.props.currentTimeCallBack){
            this.props.currentTimeCallBack(this.state.currentTime);
        }
      }

      timeUpdate2 = () => {
        // console.log(this.audioPlayer.currentTime);
        this.setState({
          currentTime: this.audioPlayer.currentTime,
        });
        this.updateInterval = requestAnimationFrame(this.timeUpdate2);
      }

      loadData = (e) => {
          //console.log(e.target.duration);
        this.setState({
            duration : e.target.duration
          });
      }

    componentWillUnmount() {
       // window.addEventListener("mouseup",this.windowMouseUp);
        this.audioPlayer.removeEventListener("timeupdate", this.timeUpdate);
        this.audioPlayer.removeEventListener("ended", this.audioEnded);
        this.audioPlayer.removeEventListener("canplaythrough", () => {});
        this.audioPlayer.removeEventListener("loadedmetadata",this.loadData);
        this.audioPlayer.removeEventListener("error",this.errorEvent);
        cancelAnimationFrame(this.updateInterval)
    }    

    audioEnded = () => {
        // console.log("ended");
        cancelAnimationFrame(this.updateInterval)
        this.setState({play:false,ended:true})
    }

    windowMouseUp = () => {
        //console.log("mouse up")
        //this.audioPlayer.removeEventListener("timeupdate", this.timeUpdate);    
        this.audioPlayer.currentTime = this.state.currentTime;
        window.removeEventListener("mouseup",this.windowMouseUp);
        window.removeEventListener("mousemove",this.mouseMove);        
        this.audioPlayer.addEventListener("timeupdate", this.timeUpdate);   
        if((this.state.play && !this.onPlayHead) || (this.state.play && window.navigator.platform.match(/^Mac/) && /^((?!chrome|android).)*safari/i.test(navigator.userAgent))){    
            this.updateInterval = requestAnimationFrame(this.timeUpdate2);
        }
        else{
            cancelAnimationFrame(this.updateInterval);
        }
        setTimeout(()=>{
            this.onPlayHead = false;
        },100)
        
    }

    secToTime = (time) => {
        let minutes = Math.floor(time / 60);
        let seconds = time - minutes * 60;
        //console.log(this.str_pad_left(minutes,'0',2)+':'+this.str_pad_left(seconds,'0',2))
        return  this.str_pad_left(minutes,'0',2)+':'+this.str_pad_left(seconds,'0',2);    
    }   
    
    str_pad_left = (string,pad,length) => {
        return (new Array(length+1).join(pad)+string).slice(-length);
    }      

    mouseMove = (e) => {
        //this.trackLength = this.trackElem.offsetWidth;
        
        let finalPosition = this.currentPosition + e.pageX - this.startPosition;

        if(finalPosition > this.trackLength){
            finalPosition = this.trackLength;
            this.setState({
                currentTime: this.state.duration
            });
        }
        else if (finalPosition < 0){
            finalPosition = 0;
            this.setState({
                currentTime: 0
            });            
        }
        else{
            //console.log(finalPosition+" "+this.trackLength);
            this.setState({
                currentTime: this.state.hovered ? this.state.hoverTime ?? (this.state.duration * (finalPosition / this.trackLength)) : (this.state.duration * (finalPosition / this.trackLength))
            });            
            //$("#currentduration").html($scope.sectotime(Math.floor($scope.duration * (finalposition / tracklength) )));
        }  
       // console.log(this.state.currentTime);      
    }

    hoverMouseMove = (e) => {

        // console.log(this.audioElem.current.offsetLeft )

        this.trackLength = this.trackElem.offsetWidth;
      
        let finalPosition = e.pageX - this.trackElem.getBoundingClientRect().left;
      
        // console.log(finalPosition+" "+this.trackLength);
      
        if(finalPosition > this.trackLength){
            finalPosition = this.trackLength;
            this.setState({
                hoverTime: this.state.duration,
                hoverLeft:finalPosition - 20 < this.trackElem.offsetWidth - 50 ? finalPosition - 20 + this.audioElem.current.offsetLeft : this.trackElem.offsetWidth - 50 + this.audioElem.current.offsetLeft
            });
        }
        else if (finalPosition < 0){
            finalPosition = 0;
            this.setState({
              hoverTime: 0,
              hoverLeft:finalPosition - 20 < this.trackElem.offsetWidth - 50 ? finalPosition - 20 + this.audioElem.current.offsetLeft : this.trackElem.offsetWidth - 50 + this.audioElem.current.offsetLeft
            });            
        }
        else{
      
            this.setState({
              hoverTime: (this.state.duration * (finalPosition / this.trackLength)),
              hoverLeft:finalPosition - 20 < this.trackElem.offsetWidth - 50 ? finalPosition - 20 + this.audioElem.current.offsetLeft : this.trackElem.offsetWidth - 50 + this.audioElem.current.offsetLeft
            });            
            //$("#currentduration").html($scope.sectotime(Math.floor($scope.duration * (finalposition / tracklength) )));
        }  
       // console.log(this.state.currentTime);      
    }

    startAudio = () => {
        this.audioPlayer.src = this.state.src;
        this.audioPlayer.play();
    }

    // playAudio = () => {
    //     if(this.state.play){
    //         this.audioPlayer.pause();
    //     }
    //     else{
    //         if(this.state.src === ""){
    //             // this.setState({src:"https://proxy.superceed.com/api/recordings/file?path="+encodeURIComponent(this.props.src)+"&type="+this.props.type},this.startAudio)
    //             this.setState({src:this.props.src},this.startAudio)
    //         }
    //         else{
    //             this.audioPlayer.play();
    //         }
            
    //     }
    //     this.setState({play:!this.state.play})
        
    // }
    

    playAudio = () => {
        if(this.state.play){
            this.audioPlayer.pause();
            cancelAnimationFrame(this.updateInterval)
        }
        else{
            if(this.state.src === ""){
                this.setState({src:this.props.src},this.startAudio)
            }
            else{
                console.log(this.state.ended);
                if(!this.state.ended || (this.state.ended === true && window.navigator.platform.match(/^Mac/) && /^((?!chrome|android).)*safari/i.test(navigator.userAgent))){
                
                    this.updateInterval = requestAnimationFrame(this.timeUpdate2);
                }
                this.audioPlayer.play();
                
            }
            
        }
        this.setState({play:!this.state.play,ended:false})
        
        let speed = ((this.state.duration - this.audioPlayer.currentTime)  / this.state.duration) * this.state.duration
        console.log(speed);
    }
    

    playHeadDown = (e) => {
        e.preventDefault();
        this.onPlayHead = true;
        this.trackLength = this.trackElem.offsetWidth;
        this.currentPosition = this.playHeadElem.offsetLeft + 10;
        this.startPosition = e.pageX;
        this.setState({currentTime:this.state.hoverTime})
        this.audioPlayer.removeEventListener("timeupdate", this.timeUpdate);
        cancelAnimationFrame(this.updateInterval);
        window.addEventListener("mouseup",this.windowMouseUp);
        window.addEventListener("mousemove",this.mouseMove);
        //console.log(this.currentPosition);
        //console.log(this.trackLength);
    }

    getLastPart = (url) => {
        let split = url.split("/");
        return window.decodeURIComponent(split[split.length - 1]);
    }

    posClick = (e) => {
        // console.log(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
        this.trackLength = this.trackElem.offsetWidth;
        if(!this.onPlayHead){
            //console.log("called");
            this.audioPlayer.removeEventListener("timeupdate", this.timeUpdate);
            cancelAnimationFrame(this.updateInterval);
            //$scope.audioPlayer.pause();
            //console.log(e.nativeEvent.offsetX+" "+this.trackElem.offsetWidth);
            
            let distance = e.nativeEvent.offsetX;
            
            //console.log(e.pageX - this.trackElem.offsetLeft+" "+this.trackElem.offsetWidth);
            //console.log(this.state.duration * (distance / this.trackElem.offsetWidth));
            //$scope.audioPlayer.currentTime = $scope.duration * (distance / tracklength);
            this.setState({
                currentTime: (this.state.duration * (distance / this.trackElem.offsetWidth)),
                ended:false
            });            
            this.audioPlayer.currentTime = (this.state.duration * (distance / this.trackElem.offsetWidth));   
            this.audioPlayer.addEventListener("timeupdate", this.timeUpdate);
            if(this.state.play && window.navigator.platform.match(/^Mac/) && /^((?!chrome|android).)*safari/i.test(navigator.userAgent)){
                this.updateInterval = requestAnimationFrame(this.timeUpdate2);
            }
            //$scope.audioPlayer.play();
        }        

    }

    posClick2 = (e) => {
        this.trackLength2 = this.trackElem2.offsetWidth;
        if(!this.onPlayHead2){
            //console.log("called");
            // this.audioPlayer.removeEventListener("timeupdate", this.timeUpdated);
            //$scope.audioPlayer.pause();
            //console.log(e.nativeEvent.offsetX+" "+this.trackElem.offsetWidth);
            
            let distance = e.nativeEvent.offsetX;
            
            //console.log(e.pageX - this.trackElem.offsetLeft+" "+this.trackElem.offsetWidth);
            //console.log(this.state.duration * (distance / this.trackElem.offsetWidth));
            //$scope.audioPlayer.currentTime = $scope.duration * (distance / tracklength);
            this.setState({
                volume: (1 * (distance / this.trackElem2.offsetWidth)),
                tempVolume: (1 * (distance / this.trackElem2.offsetWidth)),
                muted: (1 * (distance / this.trackElem2.offsetWidth)) === 0
            });            
            this.audioPlayer.volume = (1 * (distance / this.trackElem2.offsetWidth));   
            // this.audioPlayer.addEventListener("timeupdate", this.timeUpdated);
            //$scope.audioPlayer.play();
        }        
      
      }

      playHeadDown2 = (e) => {
        e.preventDefault();
        this.onPlayHead2 = true;
        this.trackLength2 = this.trackElem2.offsetWidth;
        this.currentPosition2 = this.playHeadElem2.offsetLeft + 10;
        this.startPosition2 = e.pageX;
        // this.audioPlayer.removeEventListener("timeupdate", this.timeUpdated);
        this.setState({playHeadPressed:true});
        window.addEventListener("mouseup",this.windowMouseUp2);
        window.addEventListener("mousemove",this.mouseMove2);
        //console.log(this.currentPosition);
        //console.log(this.trackLength);
      }

      windowMouseUp2 = () => {
        //console.log("mouse up")
        //this.audioPlayer.removeEventListener("timeupdate", this.timeUpdate);    
          this.audioPlayer.volume = this.state.volume;
          window.removeEventListener("mouseup",this.windowMouseUp2);
          window.removeEventListener("mousemove",this.mouseMove2);        
          // this.audioPlayer.addEventListener("timeupdate", this.timeUpdated);    
          this.setState({playHeadPressed:false}); 
          
          setTimeout(()=>{
              this.onPlayHead2 = false;
          },100)
        
      }

      mouseMove2 = (e) => {
        //this.trackLength = this.trackElem.offsetWidth;
        let finalPosition = this.currentPosition2 + e.pageX - this.startPosition2;
      
        if(finalPosition > this.trackLength2){
            finalPosition = this.trackLength2;
            this.setState({
                volume: 1          
            });
            this.audioPlayer.volume = 1;
            if(this.audioPlayer.muted){
              this.audioPlayer.muted = false;
            }
        }
        else if (finalPosition < 0){
            finalPosition = 0;
            this.setState({
                volume: 0,
                tempVolume:"0",
                muted:true
            });            
            this.audioPlayer.volume = 0;
            if(!this.audioPlayer.muted){
              this.audioPlayer.muted = false;
            }      
        }
        else{
            //console.log(finalPosition+" "+this.trackLength);
            this.audioPlayer.volume = (1 * (finalPosition / this.trackLength2));
            this.setState({
                volume: (1 * (finalPosition / this.trackLength2)),
                tempVolume:(1 * (finalPosition / this.trackLength2)),
                muted :(1 * (finalPosition / this.trackLength2)) === 0
            });      
            
              this.audioPlayer.muted = (1 * (finalPosition / this.trackLength2)) === 0;
               
            //$("#currentduration").html($scope.sectotime(Math.floor($scope.duration * (finalposition / tracklength) )));
        }  
       // console.log(this.state.currentTime);      
      }


      mute = () =>  {
        // this.audioPlayer.muted = true;
    
        // this.setState({
        //   muted: true
        // });
    
        if (this.state.muted == true) {
          this.audioPlayer.muted = false;
          if(this.state.tempVolume === "0"){
            this.audioPlayer.volume = 0.1;
            this.setState({ 
              muted: false,
              volume:0.1
            });        
          }
          else{
            this.setState({
              muted: false,
              volume:this.state.tempVolume
            });  
          }
    
        } else {
          this.audioPlayer.muted = true;
          this.setState({
            muted: true,tempVolume:this.state.volume,volume:0
          });
        }
      }


    render() {
        return (
            <div className="flex-row custom-audio-player-container" ref={this.audioElem}>
                <audio ref={e=>this.audioPlayer=e} controls className="player hide" preload="false">
                    <source src={this.state.src} />
                </audio>     
                {!this.state.error ?
                <>           
                <div className="player-controls-container margin-right-1x">
                    {(!this.state.play ? 
                    <IconPlayerPlay
                        onClick={this.playAudio}
                        className='linked white-text'
                        size={20} // set custom `width` and `height`
                        stroke={2}  // set `stroke-width`
                        strokeLinejoin="miter" // override other SVG props
                    />  
               
                     : 
                     <IconPlayerPause
                        onClick={this.playAudio}
                        className='linked white-text'
                        size={20} // set custom `width` and `height`
                        stroke={2}  // set `stroke-width`
                        strokeLinejoin="miter" // override other SVG props
                    />  
                    )}
                </div>
                <div className={"custom-controls full-width "+(!this.state.canPlay ? "hide":"")}>
                    <div onMouseLeave={()=>{this.setState({hovered:false})}} onMouseEnter={()=>{this.setState({hovered:true})}} onMouseMove={(e)=>{this.hoverMouseMove(e)}}  ref={e=>this.trackElem=e} onClick={e=>this.posClick(e)} className="track-progress" style={{position: 'relative', height: '8px'}}>
                    <div onMouseDown={e=>this.playHeadDown(e)} ref={e=>this.playHeadElem=e} className="track-rail-2 linked" style={{position: 'absolute', left: 'calc('+(this.state.currentTime/this.state.duration*100)+'% - 8px)', zIndex: 1}} />
                    <div className="track-progress-bar" style={{width: (this.state.currentTime/this.state.duration*100)+'%'}} />
                
                    </div>
                    <div className='track-hover-timeline' style={{left:(this.state.hoverLeft+40)+'px',top:'50px'}}>
                        <span>{this.toHHMMSS(this.state.hoverTime)}</span>
                      </div>
                    <h6 className="mid-text white-text unselectable audio-time-text">{this.secToTime(Math.round(this.state.currentTime))} / {this.secToTime(Math.round(this.state.duration))}</h6>

                </div>
                <div className={"custom-controls full-width overflow-hidden "+(this.state.canPlay ? "hide":null)}>
                    <h6 className="ellipsis-text white-text" style={{whiteSpace:'normal'}}>Click Play</h6>
                </div>
                <div className={'margin-left-1x volume-control-container'+(!this.state.canPlay ? " hide":"")}>
                        <div onClick={this.mute} className="video-control-button">
                        {
                            this.state.muted ? 
                            <IconVolume3
                                className='linked white-text'
                                size={20} // set custom `width` and `height`
                                stroke={2}  // set `stroke-width`
                                strokeLinejoin="miter" // override other SVG props
                            />  
                            :
                            <IconVolume
                                className='linked white-text'
                                size={20} // set custom `width` and `height`
                                stroke={2}  // set `stroke-width`
                                strokeLinejoin="miter" // override other SVG props
                            />  
                        }
                  
                        </div>
                        <div className={"custom-volume-control-container floating"+(this.state.playHeadPressed ? " show" : "")} style={{left:this.audioElem.current?.offsetLeft + 120 + 'px'}}>
                                    <div className={"custom-controls full-width "}>
                                        <div ref={e=>this.trackElem2=e} onClick={e=>this.posClick2(e)} className="track-progress" style={{position: 'relative', height: '8px'}}>
                                        <div draggable="false" onMouseDown={e=>this.playHeadDown2(e)} ref={e=>this.playHeadElem2=e} className={"track-rail-2 linked"} style={{position: 'absolute', left: 'calc('+(this.state.volume/1*100)+'% - 8px)', zIndex: 1}} />
                                        <div className="track-progress-bar" style={{transformOrigin:'left',width: ''+(this.state.volume* 100)+'%'}} >
                                            
                                        </div>
                                        </div>
                                    </div>
                            </div>
                </div>
                </>
                :
                <div className={"custom-controls full-width overflow-hidden "}>
                    <h6 className="ellipsis-text white-text margin-left-2x" style={{whiteSpace:'normal'}}>This audio cannot be played.</h6>
                </div>            
            }

            </div>
        )
    }
}
