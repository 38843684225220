import React, { Component } from 'react'
import { ChevronLeft,ChevronRight } from '@material-ui/icons';

export default class Pagination extends Component {

    componentDidMount(){
        this.getPaginationCount();
    }

    currentIndex = -1;

    total = 0;

    componentDidUpdate(){
        // console.log(this.props.total +" "+ this.total);
        if(this.currentIndex !== this.props.index || (this.props.total !== this.total && !isNaN(this.props.total))){
            this.getPaginationCount();
            this.currentIndex = this.props.index;
            this.total = this.props.total;
        }

    }

    

    constructor(props){
        super(props);
        this.state = {
            paginationArray:[]
        }
    }

    getPaginationCount = () => {
        let paginationArray = [];
        let count = 0;
        let index = this.props.index - this.props.startIndex;
        
        for(let i = index - 2; i < this.props.total;i++){
            
            if(i < 1){
            }
            else if(this.props.total !== (i + this.props.startIndex)){
                
                paginationArray.push({display: i + 1, index: i + this.props.startIndex});
                count++;
            }

            if(count === 5){
                break;
            }
        }

        this.setState({paginationArray:paginationArray})
           
    }

    addIndex = () => {
        if(this.props.index < this.props.total){
            let index = this.props.index + 1;
            this.props.indexChanged(index);
        }
    }

    minusIndex = () => {
        if(this.props.index > 0){
            let index = this.props.index - 1;
            this.props.indexChanged(index);
        }
    }

    indexChanged = (index) => {
        console.log(index);
        if(this.props.index !== index){
            // let temp = index - this.props.startIndex;
            // let startIndex = this.props.startIndex;
            this.props.indexChanged(index);
        }
    }

    render() {
        return (
            <div className="pagination-container">
                <div className={"pagination-arrow-button"+(this.props.index - this.props.startIndex === 0 ? " disabled" : "")+(this.props.disabled ? " disabled" : "")} onClick={this.minusIndex}>
                    <ChevronLeft/>
                </div>
                <span  onClick={()=>this.indexChanged(this.props.startIndex)} className={"pagination-number-button"+( this.props.index === this.props.startIndex ? " selected":""+(this.props.disabled ? " disabled" : ""))}>1</span>
                {(this.state.paginationArray.length > 0 ? (this.state.paginationArray[0].display !==  2 ? <span> ... </span> : null) : null)}
                {/* <span>{this.props.index}</span> */}
                {
                    this.state.paginationArray.map((obj,i)=>(
                        <span className={"pagination-number-button"+(this.props.index === obj.index ? " selected" : "")+(this.props.disabled ? " disabled" : "")} onClick={()=>this.indexChanged(obj.index)} key={i}>{obj.display}</span>
                    ))
                }
                {(this.state.paginationArray.length > 0 ? (this.state.paginationArray[this.state.paginationArray.length - 1].display !==  this.props.total ? <span> ... </span> : null) : null)}
                {(!isNaN(this.props.total) ? <span  onClick={()=>this.indexChanged(this.props.total)} className={"pagination-number-button"+( this.props.index === this.props.total ? " selected":"")+(this.props.disabled ? " disabled" : "")}>{this.props.total + 1}</span> : null)}
                <div className={"pagination-arrow-button"+(this.props.index === this.props.total ? " disabled" : "")+(this.props.disabled ? " disabled" : "")} onClick={this.addIndex}>
                    <ChevronRight/>
                </div>
            </div>
        )
    }
}
