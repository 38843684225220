import React, { Component } from 'react'
import Variable from './../variable';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Moment from 'react-moment';
import moment from 'moment';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown'
import { ResponsiveBar } from '@nivo/bar'
import EmotionChart from './../components/EmotionChart';
import Circular from './../components/Circular';
import {NavigateNext,NavigateBefore} from '@material-ui/icons'

import StackedBarChart from './../components/StackedBarChart';
import { isObject } from 'lodash';


const colorGroup = {"CALLDIRECTIN" : "blue","HANGUP": "red","CALLPBX":"green","FAXDIRECTIN":"purple","INPUTDTMF":"orange","VERIFYDTMF":"teal","VOICEMAIL":"cyan","NOTHING":"brown"};

const colorGroupDark = {"CALLDIRECTIN" : "blue darken-4","HANGUP": "pink darken-4","CALLPBX":"light-green darken-4","FAXDIRECTIN":"purple darken-4","INPUTDTMF":"orange darken-4","VERIFYDTMF":"teal darken-4","VOICEMAIL":"cyan darken-4","NOTHING":"brown darken-4"};

export default class ServiceTime extends Component {

    host = Variable.host;

    constructor(props){
      super(props);
      this.state = {
        serviceData:[]
      }
    }

    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
  }

    componentDidMount(){
      axios.get(this.host+'/api/proxy/servicetime', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
      .then(res => {
        this.setState({serviceData:res.data});
      })
    }

    convertToDate = (time) => {
        return new Date("01/01/2007 " + time);
    };



    setPosition = (from,to) => {

        var difference = this.convertToDate(to) - this.convertToDate(from);
     
        var percent = difference / 1000 / 864 + '%';
     
        var parts = from.split(':');
        var seconds = (+parts[0]) * 60 * 60 + (+parts[1]) * 60 + (+parts[2]);
     
        var startpercent = seconds / 864 + '%';
        //console.log(percent);
     
        return { left: startpercent, width: percent,top:0};
     
    };




    render(){
        return (
            <div>
            <table className="{{color}}">
            <colgroup>
                <col width="50"/>
                <col width="1500"/>
            </colgroup>
          <thead>
            <tr>
             <th className="padding-left-1x">Day</th>
              <th><span className="valign-wrapper"><span className="block">Time</span><a className="btn waves-effect waves-light right" ng-click="addServiceTime()">Add</a></span></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="padding-left-1x st-day">Monday</td>
              <td className="time-area-1 st-holder">
                {this.state.serviceData.filter( item => item.dayFrom === "1").map(obj => (<div className={colorGroup[obj.serviceCode]+" st-block linked"} style={this.setPosition(obj.timeFrom,obj.timeTo)} >
                    <div className="padding-left-1x padding-top-1x padding-bottom-1x padding-right-1x">
                      <h6 className="white-text" style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden'}}>{obj.timeFrom} - {obj.timeTo}</h6>
                    </div>
                  </div>
                  ))
                  }
              </td>
            </tr>
            <tr>
              <td className="padding-left-1x st-day">Tuesday</td>
              <td className="time-area-1 st-holder">
              {this.state.serviceData.filter( item => item.dayFrom === "2").map(obj => (<div className={colorGroup[obj.serviceCode]+" st-block linked"} style={this.setPosition(obj.timeFrom,obj.timeTo)} >
                    <div className="padding-left-1x padding-top-1x padding-bottom-1x padding-right-1x">
                      <h6 className="white-text" style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden'}}>{obj.timeFrom} - {obj.timeTo}</h6>
                    </div>
                  </div>
                  ))
                  }
              </td>
            </tr>
            <tr>
              <td className="padding-left-1x st-day">Wednesday</td>
              <td className="time-area-1 st-holder">
              {this.state.serviceData.filter( item => item.dayFrom === "3").map(obj => (<div className={colorGroup[obj.serviceCode]+" st-block linked"} style={this.setPosition(obj.timeFrom,obj.timeTo)} >
                    <div className="padding-left-1x padding-top-1x padding-bottom-1x padding-right-1x">
                      <h6 className="white-text" style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden'}}>{obj.timeFrom} - {obj.timeTo}</h6>
                    </div>
                  </div>
                  ))
                  }
              </td>
            </tr>
            <tr>
              <td className="padding-left-1x st-day">Thursday</td>
              <td className="time-area-1 st-holder">
              {this.state.serviceData.filter( item => item.dayFrom === "4").map(obj => (<div className={colorGroup[obj.serviceCode]+" st-block linked"} style={this.setPosition(obj.timeFrom,obj.timeTo)} >
                    <div className="padding-left-1x padding-top-1x padding-bottom-1x padding-right-1x">
                      <h6 className="white-text" style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden'}}>{obj.timeFrom} - {obj.timeTo}</h6>
                    </div>
                  </div>
                  ))
                  }
              </td>
            </tr>
            <tr>
              <td className="padding-left-1x st-day">Friday</td>
              <td className="time-area-1 st-holder">
              {this.state.serviceData.filter( item => item.dayFrom === "5").map(obj => (<div className={colorGroup[obj.serviceCode]+" st-block linked"} style={this.setPosition(obj.timeFrom,obj.timeTo)} >
                    <div className="padding-left-1x padding-top-1x padding-bottom-1x padding-right-1x">
                      <h6 className="white-text" style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden'}}>{obj.timeFrom} - {obj.timeTo}</h6>
                    </div>
                  </div>
                  ))
                  }
              </td>
            </tr>
            <tr>
              <td className="padding-left-1x st-day">Saturday</td>
              <td className="time-area-1 st-holder">
              {this.state.serviceData.filter( item => item.dayFrom === "6").map(obj => (<div className={colorGroup[obj.serviceCode]+" st-block linked"} style={this.setPosition(obj.timeFrom,obj.timeTo)} >
                    <div className="padding-left-1x padding-top-1x padding-bottom-1x padding-right-1x">
                      <h6 className="white-text" style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden'}}>{obj.timeFrom} - {obj.timeTo}</h6>
                    </div>
                  </div>
                  ))
                  }
              </td>
            </tr>
            <tr>
              <td className="padding-left-1x st-day">Sunday</td>
              <td className="time-area-1 st-holder">
              {this.state.serviceData.filter( item => item.dayFrom === "7").map(obj => (<div className={colorGroup[obj.serviceCode]+" st-block linked"} style={this.setPosition(obj.timeFrom,obj.timeTo)} >
                    <div className="padding-left-1x padding-top-1x padding-bottom-1x padding-right-1x">
                      <h6 className="white-text" style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden'}}>{obj.timeFrom} - {obj.timeTo}</h6>
                    </div>
                  </div>
                  ))
                  }
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        );
    }
}