import React from 'react'

export default function About() {
    return (
            <React.Fragment>
                <div className="padding-left-2x padding-right-2x">
                    <h1>About</h1>
                    <p>Made by Koh</p>
                </div>
            </React.Fragment>
    )
}

