import firebase from 'firebase';

export const initializeFirebase = () => {
    firebase.initializeApp({
      messagingSenderId: "907195883261"
    });
  }

  export const checkBrowserSupport = () => {
    if(!firebase.messaging.isSupported()){
        return false;
    }
    else{
        return true;
    }
  }

  export const askForPermissioToReceiveNotifications = async () => {
    try {
      const messaging = firebase.messaging();
      if(!firebase.messaging.isSupported()){
          return false;
      }
      await messaging.requestPermission();
      const token = await messaging.getToken();
      //console.log('token do usuário:', token);
      
      return token;
    } catch (error) {
      console.error(error);
    }
  }