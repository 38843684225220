import React from 'react'

export default function NotFound() {
    return (
            <React.Fragment>
                <div className="padding-left-2x padding-right-2x">
                    <h1>Error 404</h1>
                    <p>The page you have requested was not found.</p>
                </div>
            </React.Fragment>
    )
}

