import React, { Component } from 'react';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Moment from 'react-moment';
import moment from 'moment';
import axios from 'axios';
import Color from 'color';
import {NavigateNext,NavigateBefore} from '@material-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee,faComment,faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faLine,faFacebookMessenger } from '@fortawesome/free-brands-svg-icons'
import Variable from './../variable'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

export default class componentName extends Component {

  host = Variable.host;

    state = {
        startDate:(moment()),
        endDate:(moment()),
        reportData:{},
        offset:0,
        loadMore:false
    }

    loading = false;

    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    }      

    componentDidMount(){
      axios.get(this.host+'/report/generate', {params:{token:this.getCookie("auth"),type:this.props.match.params.type,status:this.props.match.params.status,offset:this.state.offset,startDate:this.state.startDate.format('YYYY-MM-DD'),endDate:this.state.endDate.format('YYYY-MM-DD')}},{withCredentials: true})
      .then(res => {
        if(res.data.result === "success"){
          this.setState({reportData:res.data.data,offset:res.data.count,loadMore:(res.data.count >= 30)});
        }
      })
    }

    loadMore = () => {
        if(!this.loading){
            this.loading = true;
            axios.get(this.host+'/report/generate', {params:{token:this.getCookie("auth"),type:this.props.match.params.type,status:this.props.match.params.status,offset:this.state.offset,startDate:this.state.startDate.format('YYYY-MM-DD'),endDate:this.state.endDate.format('YYYY-MM-DD')}},{withCredentials: true})
            .then(res => {
                this.loading = false;
            if(res.data.result === "success"){
                console.log("loaded");
                let reportData = Object.assign({}, this.state.reportData, res.data.data);
                let count = this.state.offset;
                count = count + res.data.count;
                this.setState({reportData:reportData,offset:count,loadMore:(res.data.count >= 30)});
            }
            })    
            .catch(err=>{
                this.loading = false;
            })
        }   
    }

    filterReport = () => {
      axios.get(this.host+'/report/generate', {params:{token:this.getCookie("auth"),type:this.props.match.params.type,status:this.props.match.params.status,offset:0,startDate:this.state.startDate.format('YYYY-MM-DD'),endDate:this.state.endDate.format('YYYY-MM-DD')}},{withCredentials: true})
      .then(res => {
        if(res.data.result === "success"){
          this.setState({reportData:res.data.data,offset:res.data.count,loadMore:(res.data.count >= 30)});
        }
      })      
    }

    labelInfo = (id) => {
      let info = this.props.label.filter(label => label.id === id);
      
      if(info.length > 0){
          return info[0];
      }
      else{
          return null;
      }
  }


  render() {
    return (
      <div className="full-width flex-col" style={{minWidth:"1px"}}>
        <div className={(this.props.dark ? "grey darken-3" : "white")+" flex-row padding-left-2x padding-right-2x flex-no-shrink full-width"} style={{height:'64px',alignItems:'center',position:"relative",borderBottom:'1px solid rgba(0,0,0,0.1)'}}>
          <h6 className="bold-text-2 emphasize-text conversation-header-text chat-list-title-text">Summary</h6>        
        </div>
        <div className="flex-col full-height" style={{minHeight:"1px"}}>
        <div style={{padding:"20px",borderBottom:'1px solid rgba(0,0,0,0.1)'}}>
          <DateRangePicker
              startDate={this.state.startDate} // momentPropTypes.momentObj or null,
              startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
              endDate={this.state.endDate} // momentPropTypes.momentObj or null,
              endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
              focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
              hideKeyboardShortcutsPanel={true}
              noBorder={true}
              displayFormat="DD-MM-YYYY"
              minimumNights={0}
              navNext={<div className="react-date-next icon-button-hover-2"><NavigateNext/></div>}
              navPrev={<div className="react-date-prev icon-button-hover-2"><NavigateBefore/></div>}
              customArrowIcon={<NavigateNext/>}              
              isOutsideRange={day => (moment().endOf('day').diff(day) < 0)}
              readOnly={true}
              />
              <div>
                <button className="button-new white-text teal lighten-1" onClick={this.filterReport}>Search</button>
              </div>
              
          </div> 
          
          <div className="overflow-y" style={{padding:"20px"}}>

            {
              Object.keys(this.state.reportData).map((key)=>(
                <React.Fragment key={key}>
                <h5 className="bold-text-2">{key}</h5>
                <table className={(this.props.dark ? "dark" : "")+" no-border round-card z-depth-1 material-table"}>
                      <thead>
                        <tr>
                          <th style={{paddingLeft:"20px",paddingRight:"20px"}}>Ticket No.</th>
                          <th style={{width:"100px"}}>Title</th>
                          <th>Channel</th>
                          <th style={{width:"100px"}}>Label</th>
                          <th>Status</th>
                          <th>Assignee</th>
                          <th>Department</th>
                          <th>Time Created</th>
                          <th>Time Updated</th>
                          <th>SLA</th>
                          {
                              this.props.match.params.type === "livechat" ?
                              <React.Fragment>
                                  <th>Rating</th>
                                  <th>Reason</th>
                              </React.Fragment>
                              :
                              null
                          }
                        </tr>
                      </thead>
                      <tbody>                
                        {
                          (
                            this.state.reportData[key].map((obj,i) => (

                                <tr key={obj.id}>
                                  <td style={{paddingLeft:"20px",paddingRight:"20px"}}>{obj.ticket_number}</td>
                                  <td>{obj.title}</td>
                                  <td><FontAwesomeIcon className={"type-icon"} style={{fontSize:"18px",color : (obj.channel === 'FB_PAGE_POST' || obj.channel === 'FB_PM' ? "#1778f2" :(obj.channel === 'TW_DM' || obj.channel === 'TW_MENTION' ? "#00acee" : (obj.channel === "LINE_DM" ? "#00c300" : (obj.channel === "EMAIL" ? (this.props.dark ? "#9575CD":"#673AB7") : (obj.channel === "WAUCHAT" ? "#00BCD4" : (obj.channel === "MANUAL" ? "#78909C" : "#757575" ) )))) )}} icon={(obj.channel === 'FB_PAGE_POST' || obj.channel === 'FB_PM' ? faFacebook :(obj.channel === 'TW_DM' || obj.channel === 'TW_MENTION' ? faTwitter : (obj.channel === "LINE_DM" ? faLine : (obj.channel === "EMAIL" ? faEnvelope :  (obj.channel === "WAUCHAT" ? faComment : faCoffee ) ))) )} /></td>
                                  <td>
                                    <div className="label-holder" style={{lineHeight:"25px"}}>
                                      {(
                                          JSON.parse(obj.label).map((obj, i) => (
                                          (this.labelInfo(obj) !== null ? <span key={i} className="label" style={{color: Color(this.props.dark ? (this.labelInfo(obj)).colorDark :(this.labelInfo(obj)).color).isDark() ? "#fefefe" : "#333333",backgroundColor:(this.props.dark ? (this.labelInfo(obj)).colorDark :(this.labelInfo(obj)).color)}}>{(this.labelInfo(obj)).name}</span> : 
                                          <span key={i} className="label">Removed</span>
                                          )
                                          )
                                          )
                                          
                                          )}
                                  </div>
                                  </td>
                                  <td>{(obj.closed === 0 ? "Open":(obj.closed === 1 ? "Pending":(obj.closed === 2 ? "Resolved":(obj.closed === 3 ? "Closed" : "?"))))}</td>
                                  <td><Tippy content={obj.assignee.split(",").map((value,index)=>( <React.Fragment><span>{value}</span><br/></React.Fragment> ))}><div className="avatars">{obj.assignee.split(",").map((value,index)=>(                                    
                                      (value !== "" && index < 4 ? <span className="avatar" key={index}>  
                                                                           
                                            <img className="margin-right-1x avatar-wrapper" src={(this.props.userInfo[value] !== undefined ? (this.props.userInfo[value].photo !== "" ? (this.props.userInfo[value].photo.indexOf("www.superceed.com") === -1 ? "https://www.superceed.com" : "")+this.props.userInfo[value].photo : "https://www.superceed.com/images/system/default.png") : "https://www.superceed.com/images/system/default.png")}/>
                                        
                                            {/* <h6>{(this.props.userInfo[value] === undefined ? value : this.props.userInfo[value].name)}</h6>                                     */}
                                            </span> : null)
                                  ))}
                                    {obj.assignee.split(",").map((value,index)=>(                                    
                                      (value !== "" ? null                         
                                            : <h6 key={value}>None</h6>)
                                  ))                                  
                                  }</div></Tippy></td>
                                  <td>{(this.props.department.filter(dep => dep.id === obj.department_id).length > 0 ? this.props.department.filter(dep => dep.id === obj.department_id)[0].name : "None")}</td>
                                  <td>{obj.created_at}</td>
                                  <td>{obj.updated_at}</td>
                                  <td>{obj.sla}</td>
                                  {
                                        this.props.match.params.type === "livechat" ?
                                        <React.Fragment>
                                            <td>{obj.rating}</td>
                                            <td>{obj.reason}</td>
                                        </React.Fragment>
                                        :
                                        null
                                    }                                  
                                </tr>

                            ))
                          )
                        }
                      </tbody>
                    </table>                 
                </React.Fragment>
              ))
            }
            {
                this.state.loadMore ?

                <button className={("btn-flat margin-top-2x grey full-width" + (this.props.dark ? " darken-3" : " lighten-2"))} onClick={this.loadMore}>Load More</button>

                :
                null
            }

           
            {/* <h5 className="mid-text">No data.</h5> */}
          </div>
        </div>
      </div>
    );
  }
}
