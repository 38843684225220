import React, { Component } from 'react'
import {ChatBubbleOutline,CallEnd,ScreenShareOutlined,Mic,MicOff,Videocam,VideocamOff,PersonOutline,FiberManualRecord} from '@material-ui/icons';
import MediaStreamMixer from 'multistreamsmixer'
import MediaRecorder from 'recordrtc'

var activeBox = -1;  // nothing selected
var aspectRatio = 4/3;  // standard definition video aspect ratio
var maxCALLERS = 10;
var numVideoOBJS = maxCALLERS+1;
var layout;

export default class VideoArea extends Component {

    state = {
        selectedCaller : 0,
        micEnabled: true,
        videoEnabled : true,
        screenShare: false,
        list: [],
        recording: false
    }

    caller = [];

    connectCount = 0;

    screenShareCount = 0;

    webCamCount = 0;

    ssAudioStream = null;

    ssStream = null;

    mixer;

    recorder;

    constructor(props){
        super(props);
        for(var i = 0; i < 10; i++){
            this.caller.push("caller-"+i);
        }
    }    

    called = false;

    list = [];

    newStream = null;

    callSuccess = (position) => {
        // this.connectCount++;
        // if( this.connectCount < maxCALLERS && position > 0) {
        //     this.establishConnection(position-1);
        // }
        //console.log("called success on "+position);
    }
    callFailure = (position,errorCode, errorText) => {
        window.easyrtc.showError(errorCode, errorText);
        // if( this.connectCount < maxCALLERS && position > 0) {
        //     this.establishConnection(position-1);
        // }
    }    

    establishConnection = (position) => {

        window.easyrtc.call(this.state.list[position], ()=>{this.callSuccess(position)}, (errorCode, errorText)=>{this.callFailure(position,errorCode, errorText)});

    }    
    
    loggedInListener = (roomName, otherPeople) => {

        //window.easyrtc.setRoomOccupantListener(null); // so we're only called once.
        let oldList = this.state.list.length;
        let list = [];
        console.log(otherPeople);
        
        for(var easyrtcid in otherPeople ) {
            console.log(easyrtcid);
            list.push(easyrtcid);
        }


        this.setState({list:list},()=>{
            if(this.state.recording){
                if(oldList > this.state.list.length){
                    this.resetRecordStream();
                }
            }            
            if(!this.called){
                this.called = true;
                if( this.state.list.length > 0) {
                    for(var i = 0; i < this.state.list.length; i++){
                        this.establishConnection(i);
                    }   
                }
            }            
        });

        //
        // Connect in reverse order. Latter arriving people are more likely to have
        // empty slots.
        //

    }    

    streamAdded = (stream) => {
        console.log(stream);
        if(this.state.recording){
            this.stopRecording();
            // this.mixer.appendStreams([stream]);
            // this.mixedStream = this.mixer.getMixedStream();
        }        
    }

    componentDidMount(){
        window.easyrtc.setRoomOccupantListener( this.loggedInListener);
        window.easyrtc.joinRoom("meeting-"+this.props.id, null, function () {
            
        },   
        function (err){
            console.error(err);
        })    
        window.easyrtc.setStreamAddedListener(this.streamAdded);          
        console.log("joined room meeting-"+this.props.id);
        window.easyrtc.enableAudio(true);
        window.easyrtc.enableVideo(true);
        window.easyrtc.easyApp("hyperceed_meeting", "self", this.caller,
            function(myId) {
               console.log("My easyrtcid is " + myId);
            },
            function(errorCode, errorText) {
                console.log(errorText);
            }
        );        
    }

    hasUserMedia = () => { 
        //check if the browser supports the WebRTC 
        return !!(navigator.getUserMedia || navigator.webkitGetUserMedia || 
           navigator.mozGetUserMedia); 
     }     

    screenShare = () => {
        if(!this.hasUserMedia){
            return false;
        }

        if(!this.state.screenShare){
        var streamName = "screen"+this.screenShareCount;

        window.easyrtc.initDesktopStream(
            (stream) => {               

                this.setState({screenShare:true});

                //this.ssStream = stream;
                //let videoTrack = stream.getVideoTracks()[0];
                //window.easyrtc.getLocalStream().addTrack(videoTrack);


                //start another stream to add audio track into screen share stream
                //console.log("creating audio source");

                // navigator.getUserMedia({ video: true, audio: true },  (tempStream) => { 
                //     // var video = document.querySelector('video'); 
                //         this.ssAudioStream = tempStream;
                //         this.ssAudioStream.getVideoTracks()[0].stop();
                //         this.ssAudioStream.getAudioTracks()[0].enabled = this.state.micEnabled;
                //         this.ssStream.addTrack(this.ssAudioStream.getAudioTracks()[0]);                      
                //     //inserting our stream to the video tag     
                //     //video.src = window.URL.createObjectURL(stream); 
                //  }, function (err) {}); 


                // window.easyrtc.initMediaSource(
                //     (tempStream) => {  
                //         this.ssAudioStream = tempStream;
                //         this.ssAudioStream.getVideoTracks()[0].stop();
                //         this.ssAudioStream.getAudioTracks()[0].enabled = this.state.micEnabled;
                //         this.ssStream.addTrack(this.ssAudioStream.getAudioTracks()[0]);
                //     },(errorCode, errorText) => {
                //         console.error(errorText);
                        
                //     })

                
                //screen share ended listener
                stream.getVideoTracks()[0].onended =  () => {
                    // doWhatYouNeedToDo();
                    // if(this.ssAudioStream !== null){
                    //     this.ssAudioStream.getTracks().forEach(track => track.stop());
                    //     //console.log(temp.getTracks());
                    //     this.ssAudioStream = null;
                    // }
                    // if(this.ssStream !== null){
                    //     this.ssStream.getTracks().forEach(track => track.stop());
                    //     this.ssStream = null;
                    // }
                    //start a new stream after screen share has finished
                    window.easyrtc.initMediaSource(
                    (mediastream) => {     
                        if(this.newStream !== null){
                            this.newStream.getTracks().forEach(track => track.stop());
                        }                                      
                        this.newStream = mediastream;
                        this.newStream.getAudioTracks()[0].stop();
                        this.newStream.getVideoTracks()[0].enabled = this.state.videoEnabled;
                        window.easyrtc.setVideoObjectSrc( document.getElementById("self"), mediastream);  
                        // window.easyrtc.connect("hyperceed_meeting", ()=>{}, (err)=>{
                        //     console.error(err);
                        // });    
                        console.log("webCam"+this.webCamCount);
                        for( var i = 0 ; i < this.state.list.length ; i++ ){
                            window.easyrtc.replaceTrackToPeerConnection(this.state.list[i],mediastream);
                            //window.easyrtc.addStreamToCall(this.state.list[i]);
                        }                     
                        this.webCamCount++;            
                    },
                    (errorCode, errorText) => {
                            window.easyrtc.showError(errorCode, errorText);
                            
                    },"webCam"+this.webCamCount);                                     
                    this.setState({screenShare:false});
                };

                var elem = window.document.getElementById("self");
                // var senders = new RTCPeerConnection().getSenders();
                // senders.map(function(sender) {
                //     sender.replaceTrack(videoTrack)
                // });
                if (elem) {
                    if (typeof elem.srcObject === 'object') {
                        elem.srcObject = stream;
                    } else {
                        elem.src = window.URL.createObjectURL(stream);
                    }
                }                
                //window.RTCPeerConnection.getSenders()
                //window.RTCRtpSender.prototype.replaceTrack(videoTrack);
                //createLocalVideo(stream, streamName);
                // if (otherEasyrtcid) {
                //     window.easyrtc.addStreamToCall(otherEasyrtcid, streamName);
                // }
                //var streamName = "screen" + numScreens;
                //window.easyrtc.setScreenCapture( true );
                //window.easyrtc.hangupAll();
                console.log(streamName);
                console.log(this.state.list);
                for( var i = 0 ; i < this.state.list.length ; i++ ){
                    window.easyrtc.replaceTrackToPeerConnection(this.state.list[i],stream);
                    //window.easyrtc.addStreamToCall(this.state.list[i]);
                }

                this.screenShareCount++;
            },
            (errCode, errText) => {
                window.easyrtc.showError(errCode, errText);
            },
            streamName);
        }
        //window.easyrtc.call(this.list[position], ()=>{}, ()=>{});
    }

    componentWillUnmount(){
        if(this.state.recording){
            this.stopRecording();
        }
        this.props.leaveMeeting(this.props.id);
        window.easyrtc.hangupAll();
        window.easyrtc.leaveRoom("meeting-"+this.props.id, function(){

        }, function(err){
            console.error(err);
        });
        window.easyrtc.disconnect();
        window.easyrtc.closeLocalMediaStream();
        //window.easyrtc.getLocalStream().getTracks().forEach(track => track.stop());
        if(this.newStream !== null){
            this.newStream.getTracks().forEach(track => track.stop());
        }
        if(this.ssAudioStream !== null){
            this.ssAudioStream.getTracks().forEach(track => track.stop());
            //this.ssAudioStream = null;
            //console.log(this.ssAudioStream.getTracks());
        }
        if(this.ssStream !== null){
            //console.log(this.ssStream.getTracks());
            this.ssStream.getTracks().forEach(track => track.stop());
            //this.ssStream = null;
        }        
    }

    toggleMic = () => { 
        this.setState({micEnabled : !this.state.micEnabled},()=>{
            console.log(window.easyrtc.getLocalStream().getAudioTracks());
            window.easyrtc.getLocalStream().getAudioTracks()[0].enabled = this.state.micEnabled;
            if(this.newStream !== null){
                this.newStream.getAudioTracks()[0].enabled = this.state.micEnabled;
            }      
            if(this.ssAudioStream !== null){
                this.ssAudioStream.getAudioTracks()[0].enabled = this.state.micEnabled;
            }                  
            //window.easyrtc.enableAudio(this.state.micEnabled);
        })
    }

    toggleVideo = () => {
        this.setState({videoEnabled : !this.state.videoEnabled},()=>{
           // window.easyrtc.enableVideo(false);
            window.easyrtc.getLocalStream().getVideoTracks()[0].enabled = this.state.videoEnabled;
            if(this.newStream !== null){
                this.newStream.getVideoTracks()[0].enabled = this.state.videoEnabled;
            }                
        })        
    }

    checkMedia = () => {
        console.log(window.easyrtc.getLocalStream().getTracks());
    }

    stopRecording = () => {
        this.recorder.stopRecording(() => {
            this.setState({recording:false});   
            this.mixer.releaseStreams();
            let blob = this.recorder.getBlob();
            //invokeSaveAsDialog(blob);
            var url = window.URL.createObjectURL(blob);
            var a = window.document.createElement('a');
            window.document.body.appendChild(a);
            a.style = 'display: none';
            a.href = url;
            a.download = 'hyperceed_meeting_'+this.props.id+'_'+Math.floor(Date.now() / 1000)+'.webm';
            a.click();
            window.URL.revokeObjectURL(url);            
        });        
             
    }

    resetRecordStream = () => {
        let streams = [];
        streams.push(window.easyrtc.getLocalStream());


        for(var i = 0; i < this.state.list.length; i++){
            let remote = window.easyrtc.getAllRemoteStreams(this.state.list[i]);
            streams = streams.concat(remote);
        }

        this.mixedStream = this.mixer.resetVideoStreams(streams);
                
    }

    recordStream = () => {
        var options;
        if (window.MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
          options = {mimeType: 'video/webm; codecs=vp9'};
        } else {
          // ...
          alert("Not supported!");
          return false;
        }

        this.setState({recording:true});

            let streams = [];
            streams.push(window.easyrtc.getLocalStream());


            for(var i = 0; i < this.state.list.length; i++){
                let remote = window.easyrtc.getAllRemoteStreams(this.state.list[i]);
                streams = streams.concat(remote);
            }
            
            console.log("Total streams: "+streams.length);
            
            this.mixer = new MediaStreamMixer(streams);
            this.mixer.startDrawingFrames();
            this.mixedStream = this.mixer.getMixedStream()
            this.recorder = MediaRecorder(this.mixedStream, {
                type: 'video',
                mimeType: 'video/webm',
            });
            this.recorder.startRecording();
            //let mediaRecorder = new window.MediaRecorder(mixer.getMixedStream(), options);

    }

    render() {
        return (
            <div className="overflow-hidden full-width relative-content flex-col black" style={{}}>
                <video style={{position:"absolute",right:"20px",bottom:"20px",zIndex:10}} id="self" width="300" height="200" muted="muted" autoPlay="autoplay" playsInline="playsinline"></video> 
                <div style={{overflowX:"auto",display:"flex"}}>
                    {(
                        this.caller.map((obj,i)=>(
                            <video onClick={()=>{this.setState({selectedCaller:i})}} key={i} style={(this.state.selectedCaller === i ? {position:"absolute",width:"100%",height:"100%",top:0,left:0,pointerEvents:"none"} : {width:"300px",height:"200px",border:"red 2px solid red",whiteSpace:"nowrap",margin:10,zIndex:9})} id={"caller-"+i} width="100%" height="100%" autoPlay="autoplay" playsInline="playsinline"></video>
                        ))
                    )}
                    
                </div>                             
                {/* <h6 class="white-text mid-text">Could not connect to meeting server.</h6> */}
                <div className="bottom-panel-wrapper" style={{height:"300px",width:"100%"}}>
                    <div className="flex-row" style={{padding:'20px',alignItems:"center",position:"absolute",bottom:0,left:0,width:"100%"}}>
                        <div className="flex-row">
                            <div onClick={this.props.toggleChat} className="linked white-text flex-row" style={{width:'50px',height:'50px',alignItems:"center",justifyContent:"center"}}><ChatBubbleOutline/></div>
                            <div onClick={this.screenShare} className="linked white-text flex-row" style={{width:'50px',height:'50px',alignItems:"center",justifyContent:"center"}}><ScreenShareOutlined/></div>
                            <div onClick={this.toggleMic} className="linked white-text flex-row" style={{width:'50px',height:'50px',alignItems:"center",justifyContent:"center"}}>{(this.state.micEnabled ? <Mic/> : <MicOff/>)}</div>
                            <div onClick={this.toggleVideo} className={"linked white-text flex-row"+(this.state.screenShare ? " disabled":"")} style={{width:'50px',height:'50px',alignItems:"center",justifyContent:"center"}}>{(this.state.videoEnabled ? <Videocam/> : <VideocamOff/>)}</div>
                            <div onClick={()=>{if(!this.state.recording){this.recordStream()}else{this.stopRecording()}}} className={"linked flex-row "+(this.state.recording ? "red-text" : "white-text") } style={{width:'50px',height:'50px',alignItems:"center",justifyContent:"center"}}><FiberManualRecord/></div>
                            <div onClick={this.checkMedia} className="linked white-text flex-row" style={{width:'50px',height:'50px',alignItems:"center",justifyContent:"center"}}><PersonOutline/>{this.state.list.length + 1}</div>
                            
                        </div>
                        
                    </div>
                    <div className="flex-row" style={{padding:'20px',alignItems:"center",position:"absolute",bottom:0,left:0,width:"100%",justifyContent:"center",pointerEvents:"none"}}>
                        <div className="flex-row" style={{pointerEvents:"auto"}}>
                            <button onClick={()=>this.props.stopMeeting(this.props.id)} className={"btn-floating red"}><CallEnd style={{marginTop:'8px'}}/></button>
                        </div>
                        
                    </div>  
                </div>              
            </div>
        )
    }
}
