import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell,faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { Close,WarningRounded } from '@material-ui/icons';
import { Scrollbars } from 'react-custom-scrollbars';
import Moment from 'react-moment';
import 'moment-timezone';

export class EmotionTrigger extends Component {

    timeoutArray = [];

    l = {
        0:"Customer is agitated. \nShow some empathy",
        1:"You are speaking too slow. \nSpeak faster",
        2:"Customer is happy,\nbuild rapport",
        3:"Your energy is too low.\nRaise your energy",
        4:"You are interrupting the customer.",
        5:"emotion5",
    }
    
    // {
    //     id:1,
    //     message:this.l[0]
    // },{
    //     id:2,
    //     message:this.l[1]
    // },{
    //     id:3,
    //     message:this.l[2]
    // }

    constructor(props){
        super(props);
        this.state = {
            messageQueueList : [],
            notification: [
    // {
    //     id:1,
    //     message:this.l[0],
    //     ts: Math.floor(Date.now() / 1000)
    // },{
    //     id:2,
    //     message:this.l[1],
    //     ts: Math.floor(Date.now() / 1000)
    // },{
    //     id:3,
    //     message:this.l[2],
    //     ts: Math.floor(Date.now() / 1000)
    // }
                
            ]
        }
        this.trigger = this.triggerNotification1.bind(this);
    }

    componentDidMount() {
        this.props.trigger(this.trigger);
    }


    triggerNotification1 = (message,color = "") => {
        let messageQueueList = this.state.messageQueueList;
        let newObj = {};
        newObj.id = 0;

        newObj.ts = Math.floor(Date.now() / 1000);

        newObj.color = color

        if(messageQueueList.length === 0){
            newObj.id = 0;
        }
        else{
            let temp = messageQueueList[0].id;
            temp++;
            newObj.id = temp;
        }

        
        newObj.message = message;
        messageQueueList.unshift(newObj);

        this.setState({messageQueueList:messageQueueList});
    
        let timeoutArray = setTimeout(()=> {
            let newMessageQueueList = this.state.messageQueueList;
            for(let i = 0; i < newMessageQueueList.length;i++){
                if(newMessageQueueList[i].id === newObj.id){
                    this.appendNotification(newMessageQueueList[i]);
                    newMessageQueueList.splice(i,1);
                }
            }
            this.setState({messageQueueList:newMessageQueueList});
            // alert(newObj.id);
        },2000)
    }

     appendNotification = (obj) => {
        let notification = this.state.notification;
        notification.unshift(obj);
        this.setState({notification:notification});

    }



    render() {
        return (
            <Scrollbars max={500}>
            <div className="flex-no-shrink" >
                {/* <button className="button-new" onClick={()=>{this.props.socket.emit("sendTranscriptionApi",{source:"customer","extension_id":"123","transcription":"testing"});this.triggerNotification1(this.l[Math.floor(Math.random() * 5)])}}>trigger</button>
                <button className="button-new" onClick={()=>this.setState({messageQueueList:[],notification:[]})}>clear all</button> */}
                {
                    this.state.messageQueueList.map((obj,i) => (
                        <div className="emotion-notification-item" key={i}>
                            <div className="flex-col">
                                <div className={("avatar-container flex-no-shrink ")+obj.color}>
                                    {/* <FontAwesomeIcon icon={faCheckDouble}/> */}
                                    <WarningRounded/>
                                </div>
                                <span className="chat-timestamp"><Moment unix format="kk:mm">{obj.ts}</Moment></span>
                            </div>                           
                            <h6 className={"margin-left-1x "+obj.color+"-text"} style={{whiteSpace:'pre-wrap'}}>{obj.message}</h6>
                        </div>
                    ))
                }
                {
                    this.state.notification.map((obj,i) => (
                        i < 5 ? <div className="emotion-notification-item" key={i}>
                            <div className="flex-col">
                                <div className={("avatar-container flex-no-shrink grey")}>
                                    {/* <FontAwesomeIcon icon={faCheckDouble}/> */}
                                    <WarningRounded/>
                                </div>
                                <span className="chat-timestamp"><Moment unix format="kk:mm">{obj.ts}</Moment></span>
                            </div>
                            <h6 className="margin-left-1x" style={{whiteSpace:'pre-wrap'}}>{obj.message}</h6>
                            
                        </div>
                        :
                        null
                    ))
                }
                
            </div>
            </Scrollbars>
        )
    }
}

export default EmotionTrigger
