import React, { Component, useState } from 'react'

import Modal from 'react-modal';

import { Scrollbars } from 'react-custom-scrollbars';

function Owner(props){

    const [open,setOpen] = useState(false);

    const profileModalStyles = {
        content : {
          top                   : 'auto',
          left                  : 'auto',
          right                 : 'auto',
          bottom                : 'auto',
          width:'500px',
          maxHeight:'100vh',
          border: 'none',
          boxShadow: '0 2px 3px 0 rgba(60,64,67,.3),0 6px 10px 4px rgba(60,64,67,.15)',
          borderRadius : '10px',
          padding : '0px'
        },
        overlay : {
          backgroundColor: ('rgba(0,0,0,0.5)')
        }
    };    

    const openModal = () => {
        setOpen(true);
    }

    const closeModal = () => {
        setOpen(false);
    }

    const afterOpenModal = () => {

    }
    

    return (
        <React.Fragment>
        <Modal
        isOpen={open}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={profileModalStyles}
        contentLabel="Example Modal"
        ariaHideApp={false} 
        closeTimeoutMS={200}       
                                        
        className={""+(props.dark ? "dark" : null)}   

        
      >

        <div className="flex-col" style={{width:'100%',maxHeight:"100vh"}}>
            {/* <div className="ng-modal-main-header" style={{paddingTop:"30px",paddingBottom:0}}>
                <div className="full-width flex-row valign-wrapper margin-bottom-1x">
                    
                </div>
            
            </div>     */}
            <Scrollbars autoHeight autoHeightMin={200}>
                <div className="ng-modal-main-body padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x flex-row full-height">
                    <h5 className="bold-text-2">{props.title}</h5>
                </div>
            </Scrollbars>
            
            <div className="ng-modal-main-footer right-text">                      
                <button className="btn-flat ng-close-btn waves-effect waves-dark" onClick={closeModal}>close</button>
            </div>            
        </div>          
        </Modal> 
            <div data-balloon-blunt aria-label="Click to view full text" data-balloon-pos="down-left" onClick={()=>openModal()}>
                <p className="mid-text full-width margin-right-2x ellipsis-text bold-text emphasize-text conversation-header-text no-margin">{props.title}</p>
            </div>
        </React.Fragment>     
    )
}
export default Owner;