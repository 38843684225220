import './bootstrap.css';
import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom';
import './materialize.css';
import './style-1.css';
import './balloon.css';
import 'react-dates/initialize';
import About from './pages/About';
import HomePage from './pages/HomePage';
import Dashboard from './pages/Dashboard';
import Report from './pages/Report';
import NotFound from './pages/404';
import SettingsPage from './pages/SettingsPage';
import LiveChat from './pages/LiveChat';
import LiveChatMain from './pages/LiveChatMain';
import ChatList from './components/ChatList';
import EmotionTrigger from './components/EmotionTrigger';
import Header from './components/ChatHeader';
import ChatListTitle2 from './components/layout/ChatListTitle';
import ChatArea from './components/layout/CallArea';
import ChatInfo from './components/layout/CallInfo';
import PricingTable from './components/PricingTable';
import axios from 'axios';
import bubble from './audio/bubble.mp3';
import Modal from 'react-modal';
import openSocket from 'socket.io-client';
import ImgsViewer from 'react-images-viewer'
import VideoArea from './components/layout/VideoArea'
import { Helmet } from 'react-helmet'
import { initializeFirebase,askForPermissioToReceiveNotifications,checkBrowserSupport } from './PushNotifications';
import 'react-notifications-component/dist/theme.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faTicketAlt, faHeadset,faCoffee,faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faLine, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import ProfilePage from './pages/ProfilePage'
import Button from '@material-ui/core/Button';
import { Scrollbars } from 'react-custom-scrollbars';
import Circular from './components/Circular';
import moment from 'moment';
import Chatbot from './components/Chatbot';
import ping from './audio/ping.mp3';

import Variable from './variable';
// import { withStyles } from '@material-ui/core/styles';
// import Snackbar from '@material-ui/core/Snackbar';

import { withSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import { Close,CancelOutlined } from '@material-ui/icons'

import LeftPanel from './components/LeftPanel'

import NewVersion from './components/NewVersion'

import FilterModal from './components/Filter'

import Omnichannel from './pages/OmnichannelReport'
import Configuration from './pages/Configuration'
import FilterPanel from './components/layout/CallFilterPanel';
import VoiceAnalysis from './pages/VoiceAnalysis';
import { join } from 'path';

import SIPml from './SIPml';

import RecordingList from './components/RecordingList';

import { connect } from 'react-redux';

import Supervisory from './pages/Supervisory';

import Voicebot from './pages/Voicebot';

import DraggableWindow2 from './components/DraggableWindow2';

// import { useSelector } from 'react-redux'

// import './sip/src/tinySIP/src/tsip_api.js';
// import window.SIPml from './sip/window.SIPml.js';

// const threshold = useSelector(state => state.threshold);

const snackbarStyles = theme => ({
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
  },
});


const modalStyles = {
  content : {
    top                   : 'auto',
    left                  : 'auto',
    right                 : 'auto',
    bottom                : 'auto',
    maxWidth:'500px',
    border: 'none',
    boxShadow: '0 2px 3px 0 rgba(60,64,67,.3),0 6px 10px 4px rgba(60,64,67,.15)',
    borderRadius : '15px',
    padding : '0px'
  },
  overlay : {
    backgroundColor: ('rgba(0,0,0,0.5)')
  }
};

const profileModalStyles = {
  content : {
    top                   : 'auto',
    left                  : 'auto',
    right                 : 'auto',
    bottom                : 'auto',
    width:'500px',
    maxHeight:'100vh',
    border: 'none',
    boxShadow: '0 2px 3px 0 rgba(60,64,67,.3),0 6px 10px 4px rgba(60,64,67,.15)',
    borderRadius : '15px',
    padding : '0px'
  },
  overlay : {
    backgroundColor: ('rgba(0,0,0,0.5)')
  }
};

const settingsModalStyles = {
  content : {
    top                   : 'auto',
    left                  : 'auto',
    right                 : 'auto',
    bottom                : 'auto',
    width:'90%',
    height: '90%',
    border: 'none',
    boxShadow: '0 2px 3px 0 rgba(60,64,67,.3),0 6px 10px 4px rgba(60,64,67,.15)',
    borderRadius : '15px',
    padding : '0px',
    overflow:"hidden"
  },
  overlay : {
    backgroundColor: ('rgba(0,0,0,0.5)')
  }
};


const modalStyles2 = {
  content : {
    top                   : 'auto',
    left                  : 'auto',
    right                 : 'auto',
    bottom                : 'auto',
    maxWidth:'1000px',
    border: 'none',
    boxShadow: '0 2px 3px 0 rgba(60,64,67,.3),0 6px 10px 4px rgba(60,64,67,.15)',
    borderRadius : '15px',
    padding : '0px'
  },
  overlay : {
    backgroundColor: ('rgba(0,0,0,0.5)')
  }
};




const audio = new Audio(bubble);
const audio2 = new Audio(ping);
audio2.loop = true;

class App extends React.Component{
  

  userTimeout = [];

  host = Variable.host;
  
  socket;

  authInterval;

  typingTimeout = {};

  OneSignal;

  retryCount = 0;

  listLoading = false;
  
  botMessageCount = 0;

  reconnectInterval;

  hookTest = () => {
    const [people,setPeople] = useState([
      {
        name: "Hello!"
      }
    ]);    
  }

  selectedId = 0;



  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      recordings:[
      ],
      labelTicket:[],
      labelTicketLoaded:false,      
      departmentTicket:[],
      departmentTicketLoaded:false,
      agentDepartment : [],
      isUpdateAvailable:false,
      isRestartRequired: false,
      liveChatQueue:[],
      networkErrorText:"Error",
      networkError: false,
      livechat:[
        
      ],
      mainTabViewMode: "",
      department: [],
      ticketTabViewMode: 'all',
      label:[],
      customStatus:[],
      forceToHome: false,
      loginError : true,
      onPayment : false,
      plan : 99,
      ticketLoadMore: false,
      chatLoadMore: false,
      liveChatLoadMore: false,
      channelData : {},
      focused: true,
      meeting:{},
      showNotificationPrompt: false,
      loadMoreChanged : false,
      loadMore : false,
      imgSet : [{src:"https://www.superceed.com/images/system/default.png"}],
      richLink : true,
      imgViewOpen : false,
      socketError : false,
      asteriskError:false,
      selectedChat : 0,
      darkTheme: false,
      emojiPickerShow: false,
      chatLoading: true,
      ticketNotFoundError: false,
      modalIsOpen: false,
      expModalIsOpen: false,
      upgradeModalIsOpen: false,
      profileModalOpen: false,
      removeModalOpen: false,
      loginValid : true,
      chatInfoShown : true,
      chatDataLoading: true,
      newMessage: false,
      uploadState: false,
      uploadPercent: 0,
      filter:0,
      onMeeting: false,
      showChatonMeeting: false,
      emojiSet: {},
       user:{
        name: "-",
        username: "-",
        userId: "-",
        profilePhoto: "https://www.superceed.com/images/system/default.png",
        type:""
       },
       tickets: [
      
       ],
       chats:[]
       ,
       conversation:{
          "call":[
            {count:0}
          ]
       },
       participant: [],
       userInfo:{},
       settings:{},
       lastMessageId:0,
       dashboardShown: false,
       emotionVisible: false,
       filterModalVisible: false,
       viewTranslated:false,
       labelFilter:[],
       depFilter:[],
       emotionFilter:[],
       startDateFilter:"",
       endDateFilter:"",
       loadFromHome: false,
       sip:[],
       kbData:[],
       pace:50,
       energy:50,
       pace_customer:50,
       energy_customer:50,
       idMap:{"ongoing":"call"},
       recordingLoading:false,
       logging:false,
       goToCall:false,
       showKB:false
    }
  }

  action = (key,url) => (
    <React.Fragment>
      <Button className="white-text" onClick={()=>{this.navigateTo(url);this.props.closeSnackbar(key)}}>View</Button>
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={()=>{this.props.closeSnackbar(key)}}
      >
        <Close />
      </IconButton>
    </React.Fragment>
  )  

  action2 = (key) => (
    <React.Fragment>
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={()=>{this.props.closeSnackbar(key)}}
      >
        <Close />
      </IconButton>
    </React.Fragment>
  )  

  toggleEmotion = () => {
    this.setState({emotionVisible: !this.state.emotionVisible})
  }

  toggleFilterModal = (value) => {
    this.setState({filterModalVisible: value});
  }

  setEmotionFilter = (emotionFilter) => {
    this.setState({emotionFilter:emotionFilter});
  }

  setLabelFilter = (labelFilter) => {
    this.setState({labelFilter:labelFilter});
  }

  setDepFilter = (depFilter) => {
    this.setState({depFilter:depFilter});
  }

  setStartDateFilter = (startDateFilter) => {
    this.setState({startDateFilter:startDateFilter});
  }

  setEndDateFilter = (endDateFilter) => {
    this.setState({endDateFilter:endDateFilter});
  }

  translate = (lang) => {
    console.log("translating");
    if((this.state.channelData.language !== lang && lang !== "")){
      let pendingLang = [];
        for(let i = 0; i < this.state.conversation[this.state.selectedChat].length; i++){
          if(!this.state.conversation[this.state.selectedChat][i].count){
            for(let k = 0; k < this.state.conversation[this.state.selectedChat][i]["data"].length; k++){
              if( this.state.conversation[this.state.selectedChat][i]["data"][k].type === "text" &&  this.state.conversation[this.state.selectedChat][i]["data"][k].message !== ""){
                if(this.state.conversation[this.state.selectedChat][i]["data"][k].translated_text[lang] === undefined){
                    let newObj = {};
                    newObj.id = this.state.conversation[this.state.selectedChat][i]["data"][k].id;
                    newObj.message = this.state.conversation[this.state.selectedChat][i]["data"][k].message;
                    pendingLang.push(newObj);
                }
              }
            }
          }
      }
      // console.log(pendingLang);
      axios.post(this.host+'/api/translate2', {_token:this.getCookie("auth"),ticketId:this.state.channelData.id,text:pendingLang,target:lang,source:this.state.channelData.source_lang},{withCredentials: true})
      .then(res => {
      //console.log(res);
        let allMessage = this.state.conversation;
        let messages = this.state.conversation[this.state.selectedChat];

        for(let i = 0 ; i < messages.length; i++){
          if(!messages[i]["count"]){
            
            messages[i]["data"].map((obj,index) => {
              if(!obj.count){
                for(let k = 0; k < res.data.text.length; k++){
                  if(res.data.text[k].id === obj.id){
                    obj.translated_text[lang] = res.data.text[k].text;
                  }
                }


              // let newObj = {};
              // newObj.id = obj.id;
              // newObj.message = obj.message;
              // pendingLang.push(newObj);
            }
            })
          }
        }

        allMessage[this.state.selectedChat] = messages;

        let channelData = this.state.channelData;

        channelData.language = lang; 

        this.setState({conversation:allMessage,viewTranslated:true,channelData:channelData},()=>{
          this.updateInfo();
        });
            
      })
    }
    else{
      let channelData = this.state.channelData;

      channelData.language = lang; 

      axios.post(this.host+'/api/language/set2', {_token:this.getCookie("auth"),ticketId:this.state.channelData.id,lang:lang},{withCredentials: true})
      .then(res => {
        this.setState({viewTranslated:false});
        this.updateInfo();
      });
    }
  }

  upgradePlan = (plan) => {
    let popup = window.open("https://www.superceed.com/payment3/6/"+this.state.user.userId+"/"+plan+"/1",'popUpWindow','height=500,width=400,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
    this.setState({onPayment:true});
    var timer = setInterval(() => { 
        if(popup.closed) {
            clearInterval(timer);
            axios.post(this.host+'/api/auth/check', {_token:this.getCookie("auth"),refresh_token:this.getCookie("refresh_token")},{withCredentials: true})
            .then(res => {
              //console.log(res);
              //console.log(res.data);
              
      
              if(res.data.result === "success"){
                this.setState({sipIp:res.data.sipIp,plan:res.data.plan,onPayment:false,settings:res.data.settings[0],eventId:res.data.event_id,apiKey:res.data.api_key});
              }
            }
            ) 
            .catch(err=>{
              //this.setState({networkError:true,networkErrorText:err.toString()});
            })                       
            //this.props.oauth(this.oauthToken);
            //alert('closed');
        }
    }, 1000);      
  }
  
  openModal() {
    this.setState({modalIsOpen: true});
  }
  
  openExpModal = (section = 0) => {
    this.setState({expModalIsOpen : true,settingsSection:section});   
  }

  openProfileModal = (username) => {
    this.setState({profileModalOpen : true,userProfile:username});   
  }  

  openUpgradeModal = () => {
    this.setState({upgradeModalIsOpen : true});
  }

  loadDepartmentTicket = () => {
    if(!this.state.departmentTicketLoaded){
      let status = "";

      if(this.state.filter === 0){
        status = "open";
      }
      else if(this.state.filter === 1){
        status = "pending";
      }
      else if(this.state.filter === 2){
        status = "solved";
      }
      else if(this.state.filter === 3){
        status = "closed";
      }      
      else if(this.state.filter === 4){
        status = "all";
      }          
      axios.get(this.host+'/ticket/load2/department/'+status+'/ticket/0/0', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
      .then(res => {

        this.setState({departmentTicketLoaded:true,departmentTicket:res.data})
      })
    }
  }

  loadMoreDepartmentTicket = (i,id) => {

      let status = "";

      if(this.state.filter === 0){
        status = "open";
      }
      else if(this.state.filter === 1){
        status = "pending";
      }
      else if(this.state.filter === 2){
        status = "solved";
      }
      else if(this.state.filter === 3){
        status = "closed";
      }    
      else if(this.state.filter === 4){
        status = "all";
      }        
      axios.get(this.host+'/ticket/load2/department/'+status+'/ticket/'+this.state.departmentTicket[i].data.length+'/'+id, {params:{_token:this.getCookie("auth")}},{withCredentials: true})
      .then(res => {
        let departmentTicket = this.state.departmentTicket;
        if(res.data.length > 0){
            if(res.data[0].data.length > 0){
              departmentTicket[i].data = departmentTicket[i].data.concat(res.data[0].data);
                if(res.data[0].data.length >= 10){
                  departmentTicket[i].loadMore = true;
                }
                else{
                  departmentTicket[i].loadMore = false;
                }
            }
            else{
              departmentTicket[i].loadMore = false;
            }
        }
        else{
          departmentTicket[i].loadMore = false;
        }
        this.setState({departmentTicket:departmentTicket});
      })
    
  }  

  loadLabelTicket = () => {
    if(!this.state.labelTicketLoaded){
      let status = "";

      if(this.state.filter === 0){
        status = "open";
      }
      else if(this.state.filter === 1){
        status = "pending";
      }
      else if(this.state.filter === 2){
        status = "solved";
      }
      else if(this.state.filter === 3){
        status = "closed";
      }    
      else if(this.state.filter === 4){
        status = "all";
      }       
      axios.get(this.host+'/ticket/load2/label/'+status+'/ticket/0/0', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
      .then(res => {

        this.setState({labelTicketLoaded:true,labelTicket:res.data})
      })
    }
  }

  loadMoreLabelTicket = (i,id) => {

      let status = "";

      if(this.state.filter === 0){
        status = "open";
      }
      else if(this.state.filter === 1){
        status = "pending";
      }
      else if(this.state.filter === 2){
        status = "solved";
      }
      else if(this.state.filter === 3){
        status = "closed";
      }    
      else if(this.state.filter === 4){
        status = "all";
      }       
      axios.get(this.host+'/ticket/load2/label/'+status+'/ticket/'+this.state.labelTicket[i].data.length+'/'+id, {params:{_token:this.getCookie("auth")}},{withCredentials: true})
      .then(res => {
        let labelTicket = this.state.labelTicket;
        if(res.data.length > 0){
            if(res.data[0].data.length > 0){
              labelTicket[i].data = labelTicket[i].data.concat(res.data[0].data);
                if(res.data[0].data.length >= 10){
                  labelTicket[i].loadMore = true;
                }
                else{
                  labelTicket[i].loadMore = false;
                }
            }
            else{
              labelTicket[i].loadMore = false;
            }
        }
        else{
          labelTicket[i].loadMore = false;
        }
        this.setState({labelTicket:labelTicket});
      })
    
  }    

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // if(this.state.darkTheme){
    //   this.subtitle.style.color = '#fefefe';
    // }
    // else{
    //   this.subtitle.style.color = '#f00';
    // }
  }

  toggleExpand = (id) => {
    let departmentTicket = this.state.departmentTicket;
    if(departmentTicket[id].expanded){
      departmentTicket[id].expanded = false
    }
    else{
      departmentTicket[id].expanded = true
    }
    this.setState({departmentTicket:departmentTicket});
  }

  toggleExpand2 = (id) => {
    let labelTicket = this.state.labelTicket;
    if(labelTicket[id].expanded){
      labelTicket[id].expanded = false
    }
    else{
      labelTicket[id].expanded = true
    }
    this.setState({labelTicket:labelTicket});
  }  

  closeModal() {
    this.setState({modalIsOpen: false});
  }  

  closeViewer = () => {
    this.setState({imgViewOpen : false});
  }

  setParticipant = (participant) => {
    let userInfo = this.state.userInfo;
    for(var i = 0; i < participant.length; i++){
      userInfo[participant[i].username] = {name: participant[i].name,photo:participant[i].photo};
    }
    this.setState({participant:participant})
  }

  getInfo = (username) => {
    if(this.state.userInfo[username] === undefined){
      axios.get(this.host+'/user/profile/info', {params:{token:this.getCookie("auth"),username:username}},{withCredentials: true})
      .then(res => {
          this.setInfo(res.data);
      });
    }    
  }

  setInfo = (info) => {
    let userInfo = this.state.userInfo;
    for(var i = 0; i < info.length; i++){
      userInfo[info[i].username] = {name: info[i].name,photo:info[i].photo};
    }    
    this.setState({userInfo:userInfo})
  }

  openViewer = (string) => {
    //console.log(string);
    this.setState({imgViewOpen : true , imgSet:[{src:string}]});
  }

  closeExpModal = () => {
    this.setState({expModalIsOpen : false});
  }

  closeProfileModal = () => {
    this.setState({profileModalOpen : false});
  }  

  closeUpgradeModal = () => {
    this.setState({upgradeModalIsOpen : false});
  }  

  closeRemoveModal = () => {
    this.setState({removeModalOpen : false});
  }


  pageFocus = () => {
    
    this.setState({focused : true});
    //console.log("focused");
    if(!audio2.paused){
      audio2.pause();
      audio2.currentTime = 0;
    }
    // console.log(this.socket.connected);
    if(this.socket !== undefined){
      if(this.socket.connected){ 
        
        if(!this.state.channelData.read){
          this.sendReadReceipt(this.state.channelData.id); 
        }
      }
    }
  }

  pageBlur = () => {
    this.setState({focused : false});
    //console.log("blurred");
  }

  removeConversation = (id) => {

    if(!this.state.focused){
      if ('Notification' in window) {

        if(Notification.permission === "granted"){
          var notification = new Notification('You got removed!', {
            icon: '/icon-256px.png',
            body: "You're not longer a participant in this conversation.",
          });                      
        }
      }            
    } 

    let tickets = this.state.tickets.filter(ticket => ticket.id !== id);

    this.setState({tickets:tickets});

    let chats = this.state.chats.filter(ticket => ticket.id !== id);

    this.setState({chats:chats});    

    let livechat = this.state.livechat.filter(ticket => ticket.id !== id);

    this.setState({livechat:livechat});     
  }

  updateTicketTimeWithLabel = (id,label) => {
    let labelTicket = this.state.labelTicket;
    let copyObj = null;
    let readyToInsert = [];
    
    for(let i = 0; i < labelTicket.length; i++){
      if(label.indexOf(labelTicket[i].id) !== -1){
        let ticketFound = false;
        labelTicket[i].data.forEach(item => {
          if(item.id === parseInt(id)){
            
            if(label !== undefined){
              item.label = JSON.stringify(label);
            }

            ticketFound = true;
            item.ts = Math.floor(Date.now() / 1000);
            if(window.location.pathname.indexOf("/") !== -1){
              //var split = window.location.pathname.split("/");
              var cid = this.state.channelData.id;
              if(parseInt(id) !== cid || (!this.state.focused && parseInt(id) === cid) ){
                console.log("not read");
                item.read = false;
              }
              else{
                console.log(cid);
                this.sendReadReceipt(cid);
              }
            }
            else{
              item.read = false;
            }   
            copyObj = item;         
          }
        })

        if(!ticketFound){
     
            readyToInsert.push(i);
          
        }
        else{
          labelTicket[i].data.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.ts) - new Date(a.ts);
          });              
        }
      }
      else{
        for(let j = 0; j < labelTicket[i].data.length; j++){
          if(labelTicket[i].data[j].id === parseInt(id)){
            labelTicket[i].data.splice(j,1);
          }
        }
      }
    }
    
    if(readyToInsert.length > 0){
      if(copyObj === null){
        axios.get(this.host+'/ticket/info/'+this.getCookie("auth")+'/'+id, {params:{_token:this.getCookie("auth")}},{withCredentials: true})
        .then(res => {      

          var obj = res.data[0];
          //obj.closed = 0;
          obj.typing = [];
          if(obj.reader.indexOf(this.state.user.username) !== -1){
            obj.read = true;
          }
          else{
            obj.read = false;
          }
          obj.ts = Math.floor(Date.now() / 1000);
          
          // if(obj.closed === this.state.filter || this.state.filter === 3){
          //   console.log(labelTicket[i]);
          //   labelTicket[i].data.unshift(obj);
          // }

          copyObj = obj;
          for(let s = 0; s < readyToInsert.length; s++){
            labelTicket[readyToInsert[s]].data.unshift(copyObj);
          }
          this.setState({labelTicket:labelTicket});
        })         
      }
      else{
        for(let s = 0; s < readyToInsert.length; s++){
          labelTicket[readyToInsert[s]].data.unshift(copyObj);
        }
        this.setState({labelTicket:labelTicket});        
      }

    }
    else{
      this.setState({labelTicket:labelTicket});
    }


    
  }

  updateTicketTimeWithLabelSync = (objArray,label) => {
    let labelTicket = this.state.labelTicket;
    let readyToInsert = [];
    
    for(let g = 0; g < objArray.length; g++){
          for(let i = 0; i < labelTicket.length; i++){
            if(label.indexOf(labelTicket[i].id) !== -1){
              let ticketFound = false;
              labelTicket[i].data.forEach(item => {
                if(item.id === parseInt(objArray[g].id)){
                  
                  if(label !== undefined){
                    item.label = JSON.stringify(label);
                  }

                  ticketFound = true;
                  item.ts = Math.floor(Date.now() / 1000);
                  if(window.location.pathname.indexOf("/") !== -1){
                    //var split = window.location.pathname.split("/");
                    var cid = this.state.channelData.id;
                    if(parseInt(objArray[g].id) !== cid || (!this.state.focused && parseInt(objArray[g].id) === cid) ){
                      console.log("not read");
                      item.read = false;
                    }
                    else{
                      console.log(cid);
                      this.sendReadReceipt(cid);
                    }
                  }
                  else{
                    item.read = false;
                  }      
                }
              })

              if(!ticketFound){
          
                  readyToInsert.push(i);
                
              }
              else{
                labelTicket[i].data.sort(function(a,b){
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(b.ts) - new Date(a.ts);
                });              
              }
            }
            else{
              for(let j = 0; j < labelTicket[i].data.length; j++){
                if(labelTicket[i].data[j].id === parseInt(objArray[g].id)){
                  labelTicket[i].data.splice(j,1);
                }
              }
            }
          }
          
          if(readyToInsert.length > 0){

              for(let s = 0; s < readyToInsert.length; s++){
                labelTicket[readyToInsert[s]].data.unshift(objArray);
              }
     
            

          }
    }

    this.setState({labelTicket:labelTicket});
    
  }

  updateTicketTime = (id,departmentId) => {
    //console.log("updated ticket");
    let tickets = this.state.tickets;
    let ticketFound = false;
    let departmentTicketFound = false;
    let isTicket = false;
    let copyObj = null;

    tickets.forEach(item => {
      
      if(item.id === parseInt(id)){
        //console.log(item.id);
        ticketFound = true;
        isTicket = true;
        item.ts = Math.floor(Date.now() / 1000);
        if(window.location.pathname.indexOf("/") !== -1){
          //var split = window.location.pathname.split("/");
          var cid = this.state.channelData.id;
          if(parseInt(id) !== cid || (!this.state.focused && parseInt(id) === cid) ){
            console.log("not read");
            item.read = false;
          }
          else{
            console.log(cid);
            this.sendReadReceipt(cid);
          }
        }
        else{
          item.read = false;
        }
        copyObj = item;
      }
    });

    tickets.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.ts) - new Date(a.ts);
    });    
    this.setState({tickets:tickets});    

    let chats = this.state.chats;
    chats.forEach(item => {
      
      if(item.id === parseInt(id)){
        //console.log(item.id);
        ticketFound = true;
        item.ts = Math.floor(Date.now() / 1000);
        if(window.location.pathname.indexOf("/") !== -1){
          //var split = window.location.pathname.split("/");
          var cid = this.state.channelData.id;
          if(parseInt(id) !== cid || (!this.state.focused && parseInt(id) === cid) ){
            item.read = false;
          }
          else{
            this.sendReadReceipt(cid);
          }
        }
        else{
          item.read = false;
        }
      }
    });

    chats.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.ts) - new Date(a.ts);
    });    

    this.setState({chats:chats}); 

    let livechat = this.state.livechat;
    livechat.forEach(item2 => {
      item2.livechats.forEach(item => {
          if(item.id === parseInt(id)){
            //console.log(item.id);
            ticketFound = true;
            item2.ts = Math.floor(Date.now() / 1000);
            item.ts = Math.floor(Date.now() / 1000);
            if(window.location.pathname.indexOf("/") !== -1){
              //var split = window.location.pathname.split("/");
              var cid = this.state.channelData.id;
              if(parseInt(id) !== cid || (!this.state.focused && parseInt(id) === cid) ){
                item.read = false;
              }
              else{
                this.sendReadReceipt(cid);
              }
            }
            else{
              item.read = false;
            }
          }
      });
    });

    livechat.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.ts) - new Date(a.ts);
    });       
    
    this.setState({livechat:livechat});     
    
    if(this.state.departmentTicketLoaded){
      let departmentTicket = this.state.departmentTicket;

      for(let j = 0; j < departmentTicket.length; j++){
        departmentTicket[j].data.forEach(item => {
        
          if(item.id === parseInt(id)){
            //console.log(item.id);
              if(departmentId){
                if(departmentId !== item.department_id){
                  item.department_id = departmentId;
                  item.ts = Math.floor(Date.now() / 1000);
                  if(window.location.pathname.indexOf("/") !== -1){
                    //var split = window.location.pathname.split("/");
                    var cid = this.state.channelData.id;
                    if(parseInt(id) !== cid || (!this.state.focused && parseInt(id) === cid) ){
                      item.read = false;
                    }
                    else{
                      this.sendReadReceipt(cid);
                    }
                  }
                  else{
                    item.read = false;
                  }                  
                  copyObj = item;
                  departmentTicket[j].data.splice( departmentTicket[j].data.indexOf(item), 1);
                }
                else{
                  departmentTicketFound = true;
                  item.ts = Math.floor(Date.now() / 1000);
                  if(window.location.pathname.indexOf("/") !== -1){
                    //var split = window.location.pathname.split("/");
                    var cid = this.state.channelData.id;
                    if(parseInt(id) !== cid || (!this.state.focused && parseInt(id) === cid) ){
                      item.read = false;
                    }
                    else{
                      this.sendReadReceipt(cid);
                    }
                  }
                  else{
                    item.read = false;
                  }
  
                  departmentTicket[j].data.sort(function(a,b){
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.ts) - new Date(a.ts);
                  });                   
                }
              }
              else{
                departmentTicketFound = true;
                item.ts = Math.floor(Date.now() / 1000);
                if(window.location.pathname.indexOf("/") !== -1){
                  //var split = window.location.pathname.split("/");
                  var cid = this.state.channelData.id;
                  if(parseInt(id) !== cid || (!this.state.focused && parseInt(id) === cid) ){
                    item.read = false;
                  }
                  else{
                    this.sendReadReceipt(cid);
                  }
                }
                else{
                  item.read = false;
                }

                departmentTicket[j].data.sort(function(a,b){
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(b.ts) - new Date(a.ts);
                });    
              }
          }
        });      
      }
    }
    else{
      departmentTicketFound = true;
    }

    if(!ticketFound || !departmentTicketFound){
      if(isTicket){
        if(copyObj !== null){
          if(!departmentTicketFound && this.state.departmentTicketLoaded){
            let departmentTicket = this.state.departmentTicket;
            for(var k = 0; k < departmentTicket.length; k++){
              if(departmentTicket[k].id === copyObj.department_id){
                departmentTicket[k].unshift(copyObj);
              }
            }
            this.setState({departmentTicket:departmentTicket})
          }
        }
      }
      else{
        if(!ticketFound){
            axios.get(this.host+'/ticket/info/'+this.getCookie("auth")+'/'+id, {params:{_token:this.getCookie("auth")}},{withCredentials: true})
            .then(res => {      
              var obj = res.data[0];
              obj.closed = 0;
              obj.typing = [];
              if(obj.reader.indexOf(this.state.user.username) !== -1){
                obj.read = true;
              }
              else{
                obj.read = false;
              }
              obj.ts = Math.floor(Date.now() / 1000);
              //console.log(obj);
              if(obj.closed === this.state.filter || this.state.filter === 3){
                

                  if(!ticketFound){

                    if(obj.channel === "LIVECHAT"){
                      var joined = this.state.livechat;
                      //joined.unshift(obj);
                      //console.log(joined)
                      joined.forEach(item2 => {
                        if(item2.session_id === res.data[0].group_id){
                          item2.livechats.unshift(obj);
                        }
                        // item2.livechats.forEach(item => {

                        // });
                      });
                      this.setState({ livechat: joined })  
                    }       
                    else if(obj.channel !== "chat"){
                      var joined = this.state.tickets;
                      joined.unshift(obj);
                      //console.log(joined)
                      this.setState({ tickets: joined })  
                    }                     
                    else{

                      var joined = this.state.chats;
                      joined.unshift(obj);
                      //console.log(joined)
                      this.setState({ chats: joined })  
                    }          
                  }
                  if(!departmentTicketFound && this.state.departmentTicketLoaded){
                    let departmentTicket = this.state.departmentTicket;
                    for(var k = 0; k < departmentTicket.length; k++){
                      if(departmentTicket[k].id === obj.department_id){
                        departmentTicket[k].unshift(obj);
                      }
                    }
                    this.setState({departmentTicket:departmentTicket})
                  }
                
              }
            })   
          }
        }
    }

    this.setState({ticketTs:Math.floor(Date.now() / 1000)})
  }


  updateTicketTimeSync = (objArray) => {
    //console.log("updated ticket");
    let tickets = this.state.tickets;
    let ticketFound = false;
    let departmentTicketFound = false;
    let isTicket = false;
    //let copyObj = null;

        for(let x = 0; x < objArray.length; x++){
          let id = objArray[x].id;
          tickets.forEach(item => {
            
            if(item.id === parseInt(id)){
              //console.log(item.id);
              ticketFound = true;
              isTicket = true;
              item.ts = objArray[x].ts;
              if(window.location.pathname.indexOf("/") !== -1){
                //var split = window.location.pathname.split("/");
                var cid = this.state.channelData.id;
                if(parseInt(id) !== cid || (!this.state.focused && parseInt(id) === cid) ){
                  console.log("not read");
                  item.read = objArray[x].read;
                }
                else{
                  console.log(cid);
                  item.read = objArray[x].read;
                }
              }
              else{
                item.read = false;
              }

            }
          });

          tickets.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.ts) - new Date(a.ts);
          });    
          this.setState({tickets:tickets});    

          let chats = this.state.chats;
          chats.forEach(item => {
            
            if(item.id === parseInt(id)){
              //console.log(item.id);
              ticketFound = true;
              item.ts = objArray[x].ts;
              if(window.location.pathname.indexOf("/") !== -1){
                //var split = window.location.pathname.split("/");
                var cid = this.state.channelData.id;
                if(parseInt(id) !== cid || (!this.state.focused && parseInt(id) === cid) ){
                  item.read = objArray[x].read;
                }
                else{
                  this.sendReadReceipt(cid);
                }
              }
              else{
                item.read = objArray[x].read;
              }
            }
          });

          chats.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.ts) - new Date(a.ts);
          });    

          this.setState({chats:chats}); 

          let livechat = this.state.livechat;
          livechat.forEach(item2 => {
            item2.livechats.forEach(item => {
              if(item.id === parseInt(id)){
                //console.log(item.id);
                ticketFound = true;
                item.ts = objArray[x].ts;
                if(window.location.pathname.indexOf("/") !== -1){
                  //var split = window.location.pathname.split("/");
                  var cid = this.state.channelData.id;
                  if(parseInt(id) !== cid || (!this.state.focused && parseInt(id) === cid) ){
                    item.read = objArray[x].read;
                  }
                  else{
                    this.sendReadReceipt(cid);
                  }
                }
                else{
                  item.read = objArray[x].read;
                }
              }
            })
          });

          livechat.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.ts) - new Date(a.ts);
          });       
          
          this.setState({livechat:livechat});     
          
          if(this.state.departmentTicketLoaded){
            let departmentTicket = this.state.departmentTicket;

            for(let j = 0; j < departmentTicket.length; j++){
              departmentTicket[j].data.forEach(item => {
              
                if(item.id === parseInt(id)){
                  //console.log(item.id);
                  if(item.department_id === objArray[x].department_id){
                    departmentTicketFound = true;
                    item.ts = objArray[x].ts;
                    if(window.location.pathname.indexOf("/") !== -1){
                      //var split = window.location.pathname.split("/");
                      var cid = this.state.channelData.id;
                      if(parseInt(id) !== cid || (!this.state.focused && parseInt(id) === cid) ){
                        item.read = objArray[x].read;
                      }
                      else{
                        this.sendReadReceipt(cid);
                      }
                    }
                    else{
                      item.read = objArray[x].read;
                    }

                    departmentTicket[j].data.sort(function(a,b){
                      // Turn your strings into dates, and then subtract them
                      // to get a value that is either negative, positive, or zero.
                      return new Date(b.ts) - new Date(a.ts);
                    });    
                  }
                  else{
                    departmentTicket[j].data.splice(departmentTicket[j].data.index(item),1);
                  }
                }
              });      
            }
          }
          else{
            departmentTicketFound = true;
          }

          if(!ticketFound || !departmentTicketFound){
            if(isTicket){

                if(!departmentTicketFound && this.state.departmentTicketLoaded){
                  let departmentTicket = this.state.departmentTicket;
                  for(var k = 0; k < departmentTicket.length; k++){
                    if(departmentTicket[k].id === objArray[x].department_id){
                      departmentTicket[k].push(objArray[x]);
                      
                    }
                    departmentTicket[k].data.sort(function(a,b){
                      // Turn your strings into dates, and then subtract them
                      // to get a value that is either negative, positive, or zero.
                      return new Date(b.ts) - new Date(a.ts);
                    });                        
                  }
                  this.setState({departmentTicket:departmentTicket})
                }
              
            }
            else{
              if(!ticketFound){
    
                    // var obj = res.data[0];
                    // obj.closed = 0;
                    // obj.typing = [];
                    // if(obj.reader.indexOf(this.state.user.username) !== -1){
                    //   obj.read = true;
                    // }
                    // else{
                    //   obj.read = false;
                    // }
                    // obj.ts = Math.floor(Date.now() / 1000);
                    //console.log(obj);
                    if(objArray[x].closed === this.state.filter || this.state.filter === 3){
                      

                        if(!ticketFound){

                          if(objArray[x].channel === "LIVECHAT"){
                            var joined = this.state.livechat;
                            objArray[x].typing = [];
                            livechat.forEach(item2 => {
                              item2.livechats.forEach(item => {
                                if(item.session_id === objArray[x].session_id){
                                  item.livechats.unshift(objArray[x]);
                                }
                              })
                            });
                            //console.log(joined)
                            joined.sort(function(a,b){
                              // Turn your strings into dates, and then subtract them
                              // to get a value that is either negative, positive, or zero.
                              return new Date(b.ts) - new Date(a.ts);
                            });                            
                            this.setState({ livechat: joined })  
                          }       
                          else if(objArray[x].channel !== "chat"){
                            var joined = this.state.tickets;
                            objArray[x].typing = [];
                            joined.unshift(objArray[x]);
                            //console.log(joined)
                            joined.sort(function(a,b){
                              // Turn your strings into dates, and then subtract them
                              // to get a value that is either negative, positive, or zero.
                              return new Date(b.ts) - new Date(a.ts);
                            });                            
                            this.setState({ tickets: joined })  
                          }                     
                          else{

                            var joined = this.state.chats;
                            objArray[x].typing = [];
                            joined.unshift(objArray[x]);
                            //console.log(joined)
                            joined.sort(function(a,b){
                              // Turn your strings into dates, and then subtract them
                              // to get a value that is either negative, positive, or zero.
                              return new Date(b.ts) - new Date(a.ts);
                            });                             
                            this.setState({ chats: joined })  
                          }          
                        }
                        if(!departmentTicketFound && this.state.departmentTicketLoaded){
                          let departmentTicket = this.state.departmentTicket;
                          objArray[x].typing = [];
                          for(var k = 0; k < departmentTicket.length; k++){
                            if(departmentTicket[k].id === objArray[x].department_id){
                              departmentTicket[k].unshift(objArray[x]);
                            }
                            departmentTicket[k].data.sort(function(a,b){
                              // Turn your strings into dates, and then subtract them
                              // to get a value that is either negative, positive, or zero.
                              return new Date(b.ts) - new Date(a.ts);
                            });                              
                          }
                          this.setState({departmentTicket:departmentTicket})
                        }
                      
                    }
                  
                }
              }
          }
        }
    
  }  

  sendReadReceipt = (id) => {
    if(id !== undefined){

      let sendRead = false;
      let tickets = this.state.tickets;
      tickets.forEach(item => {
        
        if(item.id === id){
          //console.log(item.id);
          if(!item.read){
            item.read = true;
            sendRead = true;
          }

        }
      });
      this.setState({tickets:tickets});
      let chats = this.state.chats;
      chats.forEach(item => {
        
        if(item.id === id){
          //console.log(item.id);
          if(!item.read){
            item.read = true;
            sendRead = true;
          }

        }
      });
      this.setState({chats:chats});    
      let livechat = this.state.livechat;
      livechat.forEach(item2 => {
        item2.livechats.forEach(item => {
          if(item.id === id){
            // console.log(item.id);
            if(!item.read){
              item.read = true;
              sendRead = true;
            }

          }
        })
      });
      this.setState({livechat:livechat});     

      let departmentTicket = this.state.departmentTicket;

      for(var i = 0; i < departmentTicket.length; i++){
        departmentTicket[i].data.forEach(item => {
          if(item.id === id){
            //console.log(item.id);
            if(!item.read){
              item.read = true;
              sendRead = true;
            }
  
          }          
        })
      }

      this.setState({departmentTicket:departmentTicket})

      let labelTicket = this.state.labelTicket;

      for(var i = 0; i < labelTicket.length; i++){
        labelTicket[i].data.forEach(item => {
          if(item.id === id){
            //console.log(item.id);
            if(!item.read){
              item.read = true;
              sendRead = true;
            }
  
          }          
        })
      }

      this.setState({labelTicket:labelTicket})      


      // if(sendRead){
      //   this.socket.emit("readMessage",{ticketId:id,username:this.state.user.username})
      // }
      // console.log("sent read to "+id+" "+this.state.user.username);
      this.socket.emit("readMessage",{ticketId:id,username:this.state.user.username})
    }
  }  

  loadMoreTicketList = () => {
    if(!this.listLoading){
        this.listLoading = true;
        let status = "";

        if(this.state.filter === 0){
          status = "open";
        }
        else if(this.state.filter === 1){
          status = "pending";
        }
        else if(this.state.filter === 2){
          status = "solved";
        }
        else if(this.state.filter === 3){
          status = "closed";
        }        
        else if(this.state.filter === 4){
          status = "all";
        }

        let count = this.state.tickets.length;
        axios.get(this.host+'/ticket/load/'+this.getCookie("auth")+"/"+this.state.filter+"/ticket/"+count, {params:{startDate:this.state.startDateFilter,endDate:this.state.endDateFilter,labelFilter:this.state.labelFilter,depFilter:this.state.depFilter}})
        .then(res => {
          var item = res.data;
          let chatId = [];
          for(var i = 0; i < item.length; i++){
            if(item[i].channel === "chat"){
              chatId.push(item[i].id);
            }
            
            //{url:"https://www.superceed.com/images/svg/blue_icon.svg",name:"Koh",id:0},{url:"https://www.superceed.com/images/svg/blue_icon.svg",name:"Lee",id:1}
            //item[i].typing = [{url:"https://www.superceed.com/images/svg/blue_icon.svg",name:"Koh",id:0},{url:"https://www.superceed.com/images/svg/blue_icon.svg",name:"Lee",id:1}];
            item[i].typing = [];
          }
          
          this.setState({tickets : this.state.tickets.concat(res.data),ticketLoadMore:(res.data.length >= 30)},()=>{
            this.listLoading = false;
          });
        }).catch(err=>{
          console.log(err);
          this.listLoading = false;
        });
      }     
  }

  loadMoreLiveChatList = () => {
    if(!this.listLoading){
        this.listLoading = true;
        let status = "";

        if(this.state.filter === 0){
          status = "open";
        }
        else if(this.state.filter === 1){
          status = "pending";
        }
        else if(this.state.filter === 2){
          status = "solved";
        }
        else if(this.state.filter === 3){
          status = "closed";
        }        
        else if(this.state.filter === 4){
          status = "all";
        }

        let count = this.state.livechat.length;
        axios.get(this.host+'/ticket/load/'+this.getCookie("auth")+"/"+status+"/livechat/"+count)
        .then(res => {
          var item = res.data;
          let chatId = [];
          for(var i = 0; i < item.length; i++){
            if(item[i].channel === "chat"){
              chatId.push(item[i].id);
            }
            
            //{url:"https://www.superceed.com/images/svg/blue_icon.svg",name:"Koh",id:0},{url:"https://www.superceed.com/images/svg/blue_icon.svg",name:"Lee",id:1}
            //item[i].typing = [{url:"https://www.superceed.com/images/svg/blue_icon.svg",name:"Koh",id:0},{url:"https://www.superceed.com/images/svg/blue_icon.svg",name:"Lee",id:1}];
            item[i].typing = [];
          }
          
          this.setState({livechat : this.state.livechat.concat(res.data),liveChatLoadMore:(res.data.length >= 30)},()=>{
            this.listLoading = false;
          });
        }).catch(err=>{
          console.log(err);
          this.listLoading = false;
        });
      }     
  }  

  loadMoreChatList = () => {
    if(!this.listLoading){
        this.listLoading = true;
        let status = "";

        if(this.state.filter === 0){
          status = "open";
        }
        else if(this.state.filter === 1){
          status = "pending";
        }
        else if(this.state.filter === 2){
          status = "closed";
        }
        else if(this.state.filter === 3){
          status = "all";
        }

        let count = this.state.chats.length;
        axios.get(this.host+'/ticket/load/'+this.getCookie("auth")+"/"+status+"/chat/"+count)
        .then(res => {

          var item = res.data;
          let chatId = [];
          for(var i = 0; i < item.length; i++){
            if(item[i].channel === "chat"){
              chatId.push(item[i].id);
            }
            
            //{url:"https://www.superceed.com/images/svg/blue_icon.svg",name:"Koh",id:0},{url:"https://www.superceed.com/images/svg/blue_icon.svg",name:"Lee",id:1}
            //item[i].typing = [{url:"https://www.superceed.com/images/svg/blue_icon.svg",name:"Koh",id:0},{url:"https://www.superceed.com/images/svg/blue_icon.svg",name:"Lee",id:1}];
            item[i].typing = [];
          }
          
          // const script = document.createElement("script");
          // script.src = "https://cdn.onesignal.com/sdks/OneSignalSDK.js";
          // script.async = true;
          // script.onload = () => this.scriptLoaded(user.type,tempClient,chatId);
          // document.body.appendChild(script);

          this.socket.emit("joinChat",{chatId:chatId})
          //console.log("joining "+chatId);
          this.setState({chats : this.state.chats.concat(res.data),chatLoadMore:(res.data.length >= 30)},()=>{
            this.listLoading = false;
          });
        });     
    }
  }  

  switchFilter = (type) => {

    let status = "";

    if(type === 0){
      status = "open";
    }
    else if(type === 1){
      status = "pending";
    }
    else if(type === 2){
      status = "solved";
    }
    else if(type === 3){
      status = "closed";
    }
    else if(type === 4){
      status = "all";
    }
  
    this.setState({
      filter:type,
      labelTicketLoaded:false,
      departmentTicketLoaded:false
    },()=>{
      console.log(type);
      if(this.state.ticketTabViewMode === 'department'){
        this.loadDepartmentTicket()
      }
      else if(this.state.ticketTabViewMode === 'label'){
        this.loadLabelTicket()
      }
      this.loadTicket();
    })
  }

  loadTicket = () => {
    this.setState({
      recordingLoading:true
    },()=>{
      axios.get('https://proxy.superceed.com/api/recordings', {params:{type:"imc",emotionFilter:this.state.emotionFilter}},{withCredentials: true})
      .then(res => {
        this.setState({recordings:res.data,recordingLoading:false});
      }) 
      .catch(err=>{
        console.error(err);
        this.setState({recordingLoading:false});
      })
    })
     
  }

  deleteMessage = (messageId,ticketId) => {

    axios.post(this.host+'/api/ticket/message/delete',{_token:this.getCookie("auth"),message_id:messageId,ticket_id:ticketId},{withCredentials: true})
    .then(res => {

      let conversation = this.state.conversation;
      
      let loop = this.state.conversation;

      (loop[ticketId]).map((obj, index)=> {
        if(!obj.count){
          for(let i = 0; i < obj["data"].length; i++){
            if(obj["data"][i].id === messageId){
              obj["data"].splice(i,1);
              if(obj["data"].length === 0){
                 conversation[ticketId].splice(index,1);
              }
              break;
            }
          }
        }
      })
      conversation[ticketId][0]['count']--;

      //let chats = this.state.conversation[ticketId];
      //console.log(chats.filter(message => message.id !== messageId));
      //chats = chats.filter(message => message.id !== messageId);
      //conversation[ticketId].chats = chats;
      this.setState({conversation:conversation});
      this.deleteFile(messageId);

      this.socket.emit("sendSyncDelete",{ticketId:ticketId,messageId:messageId});
    })
    .catch(err =>{
      this.setState({ticketNotFoundError: true,chatLoading: false});
      console.log(err);
    });       
    //console.log("called"+messageId);
  }
  

  loadMoreChat = (id,offset) => {
    let currentOffset = (this.state.conversation[id][0]["count"]);
    if(!this.state.chatDataLoading){
    this.setState({chatDataLoading:true});
    axios.get(this.host+'/api/ticket/data3/'+id+'/'+currentOffset, {params:{_token:this.getCookie("auth")}},{withCredentials: true})
    .then(res => {
      if(res.data.length >= 30){
        this.setState({loadMore:true,loadMoreChanged:true});
      }      
      else if (res.data.length > 0){
        this.setState({loadMore:false,loadMoreChanged: true});
      }
      else{
        this.setState({loadMore:false,loadMoreChanged : false});
      }
      var height = this.getHeight();
      console.log(height);
      var tempChat = this.state.conversation;

      for(let i = res.data.length - 1; i >= 0; i--){
        let tempDate = moment.unix(res.data[i].ts).format('DD/MM/YYYY');
        let found = false;
        for(let k = 0; k < tempChat[id].length; k++){
          if(tempChat[id][k]["date"] === tempDate ){
            tempChat[id][k]["data"].unshift(res.data[i]);
            found = true;
          }

        }
        if (!found){
          let newObj = {};
          newObj["date"] = tempDate;
          newObj["data"] = [];
          newObj["data"].push(res.data[i]);
  
          tempChat[id].push(newObj);
  
          
        }            
      }

      //tempChat[id]['count'] = tempChat[id]['count'] + res.data.length;

      var orderedDates = {};
      var tempCount = tempChat[id][0]["count"] + res.data.length;

      (tempChat[id]).sort((a, b) => {
        // console.log(moment(a, 'DD/MM/YYYY').toDate() > moment(b, 'DD/MM/YYYY').toDate());
        if (a.count)
        return -1;        
        if (moment(a.date, 'DD/MM/YYYY').valueOf() > moment(b.date, 'DD/MM/YYYY').valueOf() )
        return 1;
        if (moment(a.date, 'DD/MM/YYYY').valueOf() < moment(b.date, 'DD/MM/YYYY').valueOf() )
        return -1;

        
        return 0;
      });
      console.log(tempChat[id]);
      //tempChat[id] = orderedDates;
      tempChat[id][0]["count"] = tempCount;

      //console.log(orderedDates);

      //temp[id].chats = res.data.concat(temp[id].chats);
      // this.state.conversation[id] = {};
      // this.state.conversation[id].chats = res.data;
      this.setState({        
        conversation : tempChat,
        ticketNotFoundError: false,
        chatLoading: false,
        selectedChat: id,
        chatDataLoading: false
      },()=>{
     
      }) 
      
      var newHeight = this.getHeight();
      
      var diff = newHeight - height;

      console.log(newHeight);

      //console.log(height+" - "+newHeight+" - "+diff);
      if(this.scrollToPos){
        this.scrollToPos(diff);   
      }

    })
    .catch(err =>{
      this.setState({ticketNotFoundError: true,chatLoading: false});
      console.log(err);
    });    
    } 
  }

  scriptLoaded = (type,client,chatId) => {
    this.OneSignal = window.OneSignal || [];



    this.OneSignal.getTags().then((tags) => {
      // All the tags stored on the current webpage visitor
      let objTag = tags;
      Object.keys(objTag).map( key => {
          
          objTag[key] = "";
      })

 
        objTag["type"] = type;
        objTag["client"] = client;

      for(var i = 0; i < chatId.length; i++){
        objTag["chat-"+chatId[i]] = "chat";
      }

      console.log(objTag);
      window.OneSignal.sendTags(objTag);      

    });

    this.OneSignal.push(() => {
      this.OneSignal.init({
        appId: "8bc55264-2578-4aa6-8d91-d35a05c59ef2",
        //autoResubscribe: true,
        notificationClickHandlerMatch: 'origin',
        notificationClickHandlerAction: 'focus',        
      });
      this.OneSignal.setDefaultNotificationUrl("http://www.hyperceed.com");
      this.OneSignal.setDefaultTitle("Hyperceed");
      this.OneSignal.push(["getNotificationPermission", (permission) => {
        console.log("Site Notification Permission:", permission);
        // (Output) Site Notification Permission: default
        if (permission === "default"){
          this.setState({showNotificationPrompt: true});
        }
      }]);

   
      this.OneSignal.on('notificationPermissionChange', (permissionChange) => {
        var currentPermission = permissionChange.to;
        console.log('New permission state:', currentPermission);
        if(currentPermission === "denied" || currentPermission === "granted"){
          this.setState({showNotificationPrompt: false});
        }
      });
  

      this.OneSignal.getUserId().then((userId) => {
        console.log("OneSignal User ID:", userId);
        if(userId === null){
          
        }
        else{
          //window.OneSignal.setSubscription(false)
        }
        // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316    
      });        
      this.OneSignal.on('subscriptionChange',  (isSubscribed) => {
        console.log("The user's subscription state is now:", isSubscribed);
        window.OneSignal.getUserId().then((userId) => {
          console.log("OneSignal User ID:", userId);
          // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316    
        });        
      });      
    });
  }

  enableNotifications = () => {
    this.requestNotification();
    //window.OneSignal.showNativePrompt();
  }

  setTicketData = (data) => {
    this.selectedId = data.id;
    let idMap = this.state.idMap;
    idMap[data.ticket_number] = data.id ;
    this.setState({channelData:data,selectedChat:data.id ?? "ongoing",idMap:idMap},()=>{
      // this.updateInfo();
      console.log(data.source_lang)
      if(data.source_lang === ""){
        axios.post(this.host+'/api/language/detect2',{_token:this.getCookie("auth"),ticketId:data.id},{withCredentials: true})
        .then(res => {
          let channelData = this.state.channelData;
          channelData.source_lang = res.data.languageCode;
          this.setState({channelData:channelData},()=>{
            this.updateInfo();
          });
        })
        .catch(err =>{
          this.setState({showNotificationPrompt:false});
          //console.log(err);
        });   
      }
      else{
        this.updateInfo();
      }
    });


    // alert("yest");

 

    this.setState({viewTranslated:(data.language !== "")});
    
    // console.log("set to " + data.language !== "");
  }
  

  requestNotification = async () => {
    const token = await askForPermissioToReceiveNotifications();
    if(token){
        axios.post(this.host+'/api/fcm/token',{_token:this.getCookie("auth"),token:token,type:"web"},{withCredentials: true})
        .then(res => {
          this.setState({showNotificationPrompt:false});
        })
        .catch(err =>{
          this.setState({showNotificationPrompt:false});
          //console.log(err);
        });                
    }
    else{
      this.setState({showNotificationPrompt:false});
    }

  }

  componentWilUnmount() {
    window.removeEventListener("focus", this.pageFocus);
    window.removeEventListener("blur", this.pageBlur);
}

  componentDidUpdate(){
    // let count = (this.state.tickets.filter(ticket=>ticket.read === false).length + this.state.chats.filter(ticket=>ticket.read === false).length + this.state.livechat.filter(ticket=>ticket.read === false).length);
    // if(count > 0){
    //   if (navigator.setExperimentalAppBadge) {
    //     navigator.setExperimentalAppBadge(count);
    //   } else if (window.ExperimentalBadge) {
    //     window.ExperimentalBadge.set(count);
    //   }
    // }
    // else{
    //   if (navigator.clearExperimentalAppBadge) {
    //     navigator.clearExperimentalAppBadge();
    //   } else if (window.ExperimentalBadge) {
    //     window.ExperimentalBadge.clear();
    //   }     
    // }
  }

  getBestAction = (emotion) => {
    switch(emotion){
      case "angry":
        return {text:"Customer is angry.\nEmpathize and solve.",color:"red"};
      case "happy":
        return {text:"Customer is happy.\nIntroduce new offer.",color:"green"};
      case "frustration":
        return {text:"Customer is frustrated.\nEmpathize and solve.",color:"red"};
    }

    return "";
  }

  // contextDescription = (context) => {
  //   switch(context){
  //     case "BuySignal_Price":
  //       return "Asking for price.\nClose deal quickly.";
  //       case "BuySignal_Happy":
  //         return "Asking for price.\nClose deal quickly.";
  //     case "BuySignal_Interested":
  //       return "Interested.\nClose deal quickly.";                  
  //     case "BuySignal_Curiosity":
  //         return "Curious\nExplain product benefits.";  
  //     case "BuySignal_Curiosity":
  //       return "Curious\nExplain product benefits.";                           
  //     case "SalesObjection_Expensive":
  //       return "Expensive\nExplain uniqueness.";
  //     case "SalesObjection_Disappointment":
  //         return "Disappointed.\nAsk when and experience." 
  //     case "SalesObjection_NotRequired":
  //       return "Not required.\nExplain benefits."  
  //     case "Rejection_NotInterested":
  //       return "Not interested.\nExplain benefits."    
  //     case "Blacklist":
  //       return "Blacklisting.\nApologise."        
  //     case "GuidePath_Configuration":
  //       return "Configuration Issue.\nAsk what & provide how to solve."   
  //     case "GuidePath_Guidance":
  //       return "Request Guidance.\nProvide solution."           
  //     case "AppropriateDisclosure_Technical Issue":
  //       return "Technical Issue.\nProvide solution."  
  //     case "SpecialThreat_HigherAuthority":
  //       return "Special Threat.\nApologies & initiate investigation." 
  //     case "ChurnPredictor_Unsubscription":
  //       return "Request unsubscribe.\nApologies & convince."     
  //     case "ChurnPredictor_Bad Service":
  //       return "Dissatisfied.\nAsk what & when."   
  //       case "Collaborate in customer learning":
  //         return "Collaborate in customer learning.\nIt's fairly straight forward. To purchase the credit assessment report, you will need to go through eKYC and liveness detection."                                                                          
  //   }

  //   return "";
  // }

  contextDescription = (context) => {
    let obj = {
       "BuySignal_Price":
        { 
          "result": "Asking for price.\nClose deal quickly."
        },
         "BuySignal_Happy":
          { "result": "Asking for price.\nClose deal quickly."},
       "BuySignal_Interested":
        { "result": "Interested.\nClose deal quickly."},                  
       "BuySignal_Curiosity":
          { "result": "Curious\nExplain product benefits."},  
       "BuySignal_Curiosity":
        { "result": "Curious\nExplain product benefits."},                           
       "SalesObjection_Expensive":
        { 
          "result": "Customer thinks the price is expensive.",
          "suggestion": "Entice customer to entry level package."
        },
       "SalesObjection_Disappointment":
          { 
            "result": "Experience wasn't great.",
            "suggestion":"Ask customer when did he last subscribe."
        }, 
       "SalesObjection_NotRequired":
        { "result": "Not required.\nExplain benefits." }, 
       "Rejection_NotInterested":
        { "result": "Not interested.\nExplain benefits."  },  
       "Blacklist":
        { "result": "Blacklisting.\nApologise."   },     
       "GuidePath_Configuration":
        { "result": "Configuration Issue.\nAsk what & provide how to solve."   },
       "GuidePath_Guidance":
        { "result": "Request Guidance.\nProvide solution."},           
       "AppropriateDisclosure_Technical Issue":
        { "result": "Technical Issue.\nProvide solution." }, 
       "SpecialThreat_HigherAuthority":
        { "result": "Special Threat.\nApologies & initiate investigation."}, 
       "ChurnPredictor_Unsubscription":
        { "result": "Request unsubscribe.\nApologies & convince."},     
       "ChurnPredictor_Bad Service":
        { "result": "Dissatisfied.\nAsk what & when."},   
         "Collaborate in customer learning":
          { 
            "result": "Collaborate in customer learning.\nIt's fairly straight forward. To purchase the credit assessment report, you will need to go through eKYC and liveness detection.",
            "suggestion": "Ask customer to read more details in this document, eKYC.pdf"
        },
        "Sales closure":
        { 
          "result": "Customer is interested",
          "suggestion": "Offer to send sales form to customer."
      },
    }
    
    return obj[context] || "";
  }


  debugMessage = () => {
      if(this.state.conversation["call"]){
        let tempChat = this.state.conversation;

        var obj = {};
        obj.id = 1;
        obj.context = [];
        obj.type = "text";
        obj.message = "Great service. Thank you";
        obj.notes = [];
        obj.sender = "customer";
        obj.emotion_2 = {"result":"Angry"};
        obj.has_meta = 0;
        obj.isSender = true;
        obj.hasKb = true;
        obj.context = {};
        obj.emotion = {};

        // axios.get(this.host+'/api/wauchat/kb/search', {params:{text:data.transcription,_token:this.getCookie("auth")}},{withCredentials: true})
        // .then(res => {
        //     let kbData = this.state.kbData;
        //     let kbObj = res.data;
        //     kbObj["id"] = data.agi_id;
        //     kbData.push(kbObj);
        //     this.setState({kbData:kbData});
        // })   
      let kbData = this.state.kbData;
        let kbObj = {"id":1,"distance": 6,
        "qa": {
            "q": {
                "en": "What is your name?",
                "zh": "你叫什么名字"
            },
            "a": {
                "en": "My name is Chow",
                "zh": "我是chow"
            }
        }};        
        obj.kbData = kbObj;
            kbData.push(kbObj);
            this.setState({kbData:kbData});

        // if(data.text_emotion){
        //   this.trigger("Customer is "+data.text_emotion);
        // }
        // else if(data.voice_emotion){
        //   this.trigger("Customer is "+data.voice_emotion);
        // }

        // if(data.text_context){
        //   this.trigger(data.text_context);
        // }

        // if(!this.state.focused){
        //   let tempData = this.state.channelData;
        //   obj.unread = true;
        //   tempData.read = false;
        //   this.setState({channelData:tempData});
        // }                 
        // else{
        //   if(this.state.selectedChat !== data.ticketId){
        //     obj.unread = true;
        //   }
        // }  

        
        // if(obj.label !== undefined){
        //   if(obj.channel !== 'chat' && obj.channel !== 'LIVECHAT'){
        //     this.updateTicketTimeWithLabel(data.ticketId,JSON.parse(obj.label));
        //   }
        // }
        obj.ts = Math.floor(Date.now() / 1000);


        let tempDate = moment.unix(obj.ts).format('DD/MM/YYYY');


        let found = false;

        for(let k = 0; k < tempChat["call"].length; k++){
          if(tempChat["call"][k]["date"] === tempDate ){
            tempChat["call"][k]["data"].push(obj);
            found = true;
          }

        }
        if (!found){
          let newObj = {};
          newObj["date"] = tempDate;
          newObj["data"] = [];
          newObj["data"].push(obj);

          tempChat["call"].push(newObj);

          
        }  




        // if(tempChat[data.ticketId][tempDate] !== undefined ){
        //   tempChat[data.ticketId][tempDate].push(obj);
        // }
        // else{
        //   tempChat[data.ticketId][tempDate] = [];
        //   tempChat[data.ticketId][tempDate].push(obj);
        // }
      if(tempChat["call"][0]['count'] !== undefined){
        tempChat["call"][0]['count']++;
      }  
      else{
        tempChat["call"][0]['count'] = 1;
      } 


      
      this.setState({conversation:tempChat},()=>{
        this.updateTranscriptInfo({"source":"customer","transcription":obj.message},obj);
        if(this.flagScroll){
          
          this.flagScroll(1);
        }
      });
    }     
  }

  kbDataHighlight = (id) => {
    let kbData = this.state.kbData;

    for( let i = 0; i < kbData.length; i++){
      if(kbData[i].id === id){
        kbData[i].highlighted = true;
        
      }
      else{
        kbData[i].highlighted = false;
      }
    }
    this.setState({kbData:kbData});
  }

  debugEmit = () => {
    // this.socket.emit("sendEmotionApi",{
    //   "source" : "customer",
    //   "agi_id" : "12345",
    //   "extension_id" : "1005",
    //   "text_data" : {
    //     "text_emotion" : "angry", 
    //     "text_emotion_confidence" : 0.8,
    //     "text_context": "BuySignal_Happy",
    //     "text_context_confidence" : 0.8,
    //   },
    //   "voice_data" : null
    // })

    this.socket.emit("sendTranscriptionApi",{
      "source" : "agent",
      "agi_id" : "1664869816.14411",
      "data_id":"123",
      "extension_id":"123",
      "transcription":"hello",
      "interim": false

    })
  }

  refreshCallHistory = () =>{
    axios.get(this.host+'/ticket/load/'+this.getCookie("auth")+'/open/call/0', {_token:this.getCookie("auth")},{withCredentials: true})
    .then(res => {
      this.setState({chats:res.data});
    });
  }

  updateTranscriptInfo = (data,callObj) => {

    let tempChat = this.state.conversation

    // let tempDate = moment.unix(tempChat["call"][empChat["call"]]["date"]).format('DD/MM/YYYY');


    let found = false;

    let obj = null;

    obj = callObj;

    if(data.source !== "agent3"){
      // axios.get(this.host+'/api/wauchat/kb/search', {params:{text:data.transcription,_token:this.getCookie("auth")}},{withCredentials: true})
      // .then(res => {
      //   if(res.data.qa !== null){
      //     if(res.data.confidence >= parseFloat(this.props.value.threshold)){
      //       obj.hasKb = true;
            
      //         let kbData = this.state.kbData;
      //         let kbObj = res.data;
      //         kbObj["id"] = data.agi_id;
      //         kbData.unshift(kbObj);
      //         this.setState({kbData:kbData});
      //         obj.kbData = kbObj;

      //         this.setState({conversation:tempChat},()=>{
      //           if(this.flagScroll){
      //             this.flagScroll(1); 
      //           }               
                
      //         });
      //     }
      //   }
      // })   
      if(this.state.selectedChat === "ongoing"){
      axios.get(this.host+'/api/wauchat/recommendation', {params:{text:data.transcription,_token:this.getCookie("auth"),messageId:data.id,"channel":"supercall"}},{withCredentials: true})
      .then(res => {
        if(res.data.classification || res.data.what_to_say){
          // if((res.data.confidence >= parseFloat(this.props.value.threshold5)) && data.source === "customer"){

          if(res.data.classification){
            let split = res.data.classification.split(": ");

            obj.personality = {};
            obj.personality.result = split[1];

            if(localStorage.getItem((split[1]).toLowerCase()+"_Recommendation") === "no"){
              return false;
            }

            if(split.length > 1){
              obj.classification = split[0];
            }
          }
          else{
            obj.personality = {};
            obj.personality.result = res.data.guidance_label;
            obj.classification = res.data.context;
          }
            obj.keywin = "";
            obj.traits = "";
            obj.handle = res.data.recommendation;
            obj.rec_confidence = res.data.confidence ?? 1;
            if(res.data.what_to_do !== ""){
              obj.what_to_do = res.data.what_to_do;
            }
            if(res.data.what_to_say){
              obj.what_to_say = res.data.what_to_say;
            }

            if(res.data.how_to_say){
              obj.how_to_say = res.data.how_to_say;
            }
        
        
              // if(this.trigger){
              //   this.trigger(context,"red");
              // }
              this.setState({conversation:tempChat},()=>{
                if(this.flagScroll){
                  this.flagScroll(1); 
                }               
                
              });
          // }
          // if(split[0] === "CONTEXT"){
          //   if(split[1] !== "Buy Signal"){
     
          //     obj.context = {"context_name":split[1],"text":split[1],"color":"","suggestion": res.data.recommendation ?? ""};
          //     // if(this.trigger){
          //     //   this.trigger(context,"red");
          //     // }
          //     this.setState({conversation:tempChat},()=>{
          //       if(this.flagScroll){
          //         this.flagScroll(1); 
          //       }               
                
          //     });

          //     this.socket.emit("sendContextApi",{context:{context:split[1] ?? "",extension_id:this.state.sip.length > 0 ? this.state.sip[0].sip_number : ""},id:this.state.user.userId});
          //   }
          // }
          // else if(split[0] === "PERSONALITY"){
          //   obj.personality = {};
          //   obj.personality.result = split[1];

          //   obj.keywin = "";
          //   obj.traits = "";
          //   obj.handle = res.data.recommendation;
         
        
          //     // if(this.trigger){
          //     //   this.trigger(context,"red");
          //     // }
          //     this.setState({conversation:tempChat},()=>{
          //       if(this.flagScroll){
          //         this.flagScroll(1); 
          //       }               
                
          //     });

          //     this.socket.emit("sendContextApi",{personality:{personality:split[1] ?? "",extension_id:this.state.sip.length > 0 ? this.state.sip[0].sip_number : ""},id:this.state.user.userId});
            
          // }
          // else if(split[0] === "EMOTION"){
          //   let action = this.getBestAction(split[1].toLowerCase())
          //   obj.emotion_2 = {
          //     result: split[1],
          //     confidence: res.data.confidence,
          //     action:{action:split[1],"text":res.data.recommendation,color:action.color}
          //   };

          //   this.socket.emit("sendContextApi",{emotion:{emotion:split[1] ?? "",extension_id:this.state.sip.length > 0 ? this.state.sip[0].sip_number : ""},id:this.state.user.userId});

          //   this.setState({conversation:tempChat},()=>{
          //     if(this.flagScroll){
          //       this.flagScroll(1); 
          //     }               
              
          //   });
          // }
          // console.log(res.data.result);
          
        }
      })  

    }

      // axios.get(this.host+'/api/wauchat/context', {params:{message:data.transcription,_token:this.getCookie("auth")}},{withCredentials: true})
      // .then(res => {
      //   if(res.data.result){
      //     if(res.data.confidence >= parseFloat(this.props.value.threshold2)){
      //       let context = this.contextDescription(res.data.result);
      //       if(context !== ""){
      //         obj.context = {"context_name":res.data.result,"text":context.result,"color":"red","suggestion":context.suggestion || ""};
      //         // if(this.trigger){
      //         //   this.trigger(context,"red");
      //         // }
      //         this.setState({conversation:tempChat},()=>{
      //           if(this.flagScroll){
      //             this.flagScroll(1); 
      //           }               
                
      //         });
      //       }
      //     }
      //     console.log(res.data.result);
          
      //   }
      // })    
               
      
      // axios.get(this.host+'/api/wauchat', {params:{type:"personality",message:data.transcription,_token:this.getCookie("auth")}},{withCredentials: true})
      // .then(res => {
      //   if(res.data.result){
      //     obj.personality = res.data

      //     if(res.data.result === "Feeler"){
      //       obj.keywin = "Understand how he/she feels and take a sympathetic approach to resolving the issue.";
      //       obj.traits = "•Cooperative\n•Sensitive\n•Patient";
      //       obj.handle = "•Invite their opinion\n•Provide assurance\n•Show personal involvement";
      //     }
      //     else if(res.data.result === "Entertainer"){
      //       obj.keywin = "Treat him/her like a person. Doesn't like generic answers.";
      //       obj.traits = "•Outgoing\n•Enthusiastic\n•Spontaneous";
      //       obj.handle = "•Maintain an informal tone\n•Mention personal information\n•Focus first on the big picture";
      //     }
      //     else if(res.data.result === "Thinker"){
      //       obj.keywin = "Take time to fully explain resolution steps and allow customer to talk.";
      //       obj.traits = "•Analytical\n•Thorough\n•Serious";
      //       obj.handle = "•Do not interrupt\n•Explain processes\n•Slow down conversation";
      //     }
      //     else if(res.data.result === "Controller"){
      //       obj.keywin = "Don't spend time on unnecessary details; just resolve the issue.";
      //       obj.traits = "•Independent\n•Candid\n•Determined";
      //       obj.handle = "•Directly address issue\n•Speed up pace of cconversation\n•Provide clear timeline for result";
      //     }


      //     this.setState({conversation:tempChat},()=>{
      //       if(this.flagScroll){
      //         this.flagScroll(1); 
      //       }               
            
      //     });
      //   }
      // })  
      
      // axios.get(this.host+'/api/wauchat', {params:{type:"alternative",message:data.transcription,_token:this.getCookie("auth")}},{withCredentials: true})
      // .then(res => {
      //   if(res.data.data){
      //     if(res.data.confidence >= parseFloat(this.props.value.threshold5)){
      //       obj.alternative = res.data
      //     }
      //     this.setState({conversation:tempChat},()=>{
      //       if(this.flagScroll){
      //         this.flagScroll(1); 
      //       }               
            
      //     });
      //   }
      // })  
    }
    else{
      // axios.get(this.host+'/api/wauchat', {params:{type:"negative",message:data.transcription,_token:this.getCookie("auth")}},{withCredentials: true})
      // .then(res => {
      //   if(res.data.pair){
      //     if(res.data.confidence >= parseFloat(this.props.value.threshold4)){
      //       obj.negative = res.data
      //     }
      //     this.setState({conversation:tempChat},()=>{
      //       if(this.flagScroll){
      //         this.flagScroll(1); 
      //       }               
            
      //     });
      //   }
      // }) 
      // axios.get(this.host+'/api/wauchat', {params:{type:"advocacy",message:data.transcription,_token:this.getCookie("auth")}},{withCredentials: true})
      //   .then(res => {
      //     if(res.data.result){
      //       obj.advocacy = res.data
      //       this.setState({conversation:tempChat},()=>{
      //         this.flagScroll(1);
      //       });
      //     }
      //   })  

      //   axios.get(this.host+'/api/wauchat', {params:{type:"forwardresolution",message:data.transcription,_token:this.getCookie("auth")}},{withCredentials: true})
      //   .then(res => {
      //     if(res.data.result){
      //       obj.forwardres = res.data
      //       this.setState({conversation:tempChat},()=>{
      //         this.flagScroll(1);
      //       });
      //     }
      //   })
        
      //   axios.get(this.host+'/api/wauchat', {params:{type:"anchor",message:data.transcription,_token:this.getCookie("auth")}},{withCredentials: true})
      //   .then(res => {
      //     if(res.data.result){
      //       obj.anchor = res.data
      //       this.setState({conversation:tempChat},()=>{
      //         this.flagScroll(1);
      //       });
      //     }
      //   })        
    }
  }

  transcriptFunction = data => {
    console.log(data);
      if(this.state.conversation["call"]){
        let tempChat = this.state.conversation;

        var obj = {};
        obj.id = data.id;
        obj.context = [];
        obj.type = data.type ?? "text";
        obj.message =  data.transcription;
        obj.notes = [];
        obj.sender = data.source;
        obj.has_meta = 0;
        obj.emotion_2 = {};
        obj.negative = {};
        obj.alternative = {};
        obj.personality = {};
        obj.isSender = data.source === "agent" ? true : false;
        obj.energy = data.energy;
        obj.pace = data.pace;
        obj.transcript_id = data.data_id;

        if(data.translation_enabled){
          obj.translated_transcription = data.translated_transcription;
        }

        
        let pos;

        obj.ts = Math.floor(Date.now() / 1000);


        let tempDate = moment.unix(obj.ts).format('DD/MM/YYYY');


        let found = false;

        for(let k = 0; k < tempChat["call"].length; k++){
          if(tempChat["call"][k]["date"] === tempDate ){
            let messageFound = false;
            for(let i = 0 ; i < tempChat["call"][k]["data"].length;i++){
              if(tempChat["call"][k]["data"][i].transcript_id === data.data_id){
                tempChat["call"][k]["data"][i] = obj;
                messageFound = true;
                pos = tempChat["call"][k]["data"][i];
                break;
              }
            }

            if(!messageFound){
              tempChat["call"][k]["data"].push(obj);
              pos = tempChat["call"][k]["data"][tempChat["call"][k]["data"].length - 1];
            }

            found = true;
          }

        }
        if (!found){
          let newObj = {};
          newObj["date"] = tempDate;
          newObj["data"] = [];
          newObj["data"].push(obj);
  
          tempChat["call"].push(newObj);

          let lastDate = tempChat["call"].length - 1;
          pos = tempChat["call"][lastDate]["data"][tempChat["call"][lastDate]["data"].length - 1];

        }  




        // if(tempChat[data.ticketId][tempDate] !== undefined ){
        //   tempChat[data.ticketId][tempDate].push(obj);
        // }
        // else{
        //   tempChat[data.ticketId][tempDate] = [];
        //   tempChat[data.ticketId][tempDate].push(obj);
        // }
      if(tempChat["call"][0]['count'] !== undefined){
        tempChat["call"][0]['count']++;
      }  
      else{
        tempChat["call"][0]['count'] = 1;
      } 

      
      
      this.setState({conversation:tempChat},()=>{
        if(this.flagScroll){
          this.flagScroll(1); 
        }     
        if(data.interim === true){          
          this.updateTranscriptInfo(data,pos);
        }
      });
    }            
  }

  checkAllFontLoaded = () => {
    if(this.state.circularLoaded && this.state.circular2Loaded && this.state.circular3Loaded && this.state.circular4Loaded && this.state.neueFontLoaded && this.state.neueFont2Loaded){
      if(document.getElementById("app-preload") !== null){
        setTimeout(()=>{
          document.getElementById("app-preload").remove();
        },1000);
        
      }
    }
  }

  loadCall = (emotion,energy,pace,personality) => {
    axios.get(this.host+'/ticket/load/'+this.getCookie("auth")+'/open/call/0', {_token:this.getCookie("auth"),params:{emotion:emotion,energy:energy,pace:pace,personality:personality}},{withCredentials: true})
    .then(res => {
      this.setState({chats:res.data});
    });
  }


  componentDidMount(){
    // setInterval(()=>{
    //   this.debugMessage();
    // },1000)
      // this.debugMessage();
    // let sip = new SIPml("2323","2323","sip:2323@192.168.8.15","2323","192.168.8.15");

    // sip.sipRegister();

    // setInterval(()=>{
    //   console.log(this.props.value);
    // },5000);
    
    // const sip = document.createElement("script");
    // sip.src = "/sip/window.SIPml-api.js";
    // sip.async = true;

    // document.body.appendChild(sip);


    // console.log(threshold);
    document.fonts.load("12px Circular").then(() => {
      this.setState({
        circularLoaded:true,
      },()=>{
        this.checkAllFontLoaded()
        var elem =  document.getElementById("preload-product-name");
        if(elem){
          elem.innerHTML = 'Please wait...';
        }
      })

    });

    document.fonts.load("12px Circular Bold").then(() => {
      this.setState({
        circular2Loaded:true
      },()=>{
        this.checkAllFontLoaded()
      })

    });

    document.fonts.load("12px Circular Book").then(() => {
      this.setState({
        circular3Loaded:true
      },()=>{
        this.checkAllFontLoaded()
      })

    });

    document.fonts.load("12px Circular Medium").then(() => {
      this.setState({
        circular4Loaded:true
      },()=>{
        this.checkAllFontLoaded()
      })

    });
    
    document.fonts.load("12px NeueHaasDisplay Bold").then(() => {
      this.setState({
        neueFontLoaded:true
      },()=>{
        this.checkAllFontLoaded()
      })

    });

    document.fonts.load("12px NeueHaasGrotTextRound Roman Web").then(() => {
      this.setState({
        neueFont2Loaded:true
      },()=>{
        this.checkAllFontLoaded()
      })

    });


    

    if(this.getCookie("display_name") !== undefined){
      var display  =(this.getCookie("display_name") === 'true');
      this.setState({displayName: display });            
    }   

    if(this.getCookie("always_display") !== undefined){
      var display  =(this.getCookie("always_display") === 'true');
      this.setState({alwaysDisplay: display });            
    }       

    var res = this.getCookie("dark");

    var loginStatus = this.getCookie("auth");
    //var rich = this.getCookie("rich");
    //console.log(res);
    if(res !== undefined && loginStatus !== undefined){
      if(res === "true"){
        this.setState({darkTheme: true});
        document.body.classList.add('dark');
      }
      else{
        this.setState({darkTheme: false});
        document.body.classList.remove('dark');
      }

        var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
        var expires = '; expires=' + date.toGMTString();
        document.cookie = 'dark= '+ res + expires + '; path=/';       
                 
    }

    window.addEventListener("focus", this.pageFocus);
    window.addEventListener("blur", this.pageBlur);
    this.setState({focused: document.hasFocus()});

    if(this.getCookie("auth") !== undefined){

      axios.post(this.host+'/api/auth/check', {_token:this.getCookie("auth"),refresh_token:this.getCookie("refresh_token")},{withCredentials: true})
      .then(res => {

      
        if(res.data.result === "success"){

          var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
          var expires = '; expires=' + date.toGMTString();
          document.cookie = 'auth='+res.data.token+' ' + expires + '; path=/';    

          if(res.data.refresh_token){
            var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
            var expires = '; expires=' + date.toGMTString();
            document.cookie = 'refresh_token='+res.data.refresh_token+' ' + expires + '; path=/';   
          }
          
          if(this.getCookie("tab") !== undefined){
            this.setState({mainTabViewMode:this.getCookie("tab")});
          }
          else{
  
            this.setState({mainTabViewMode:"livechat"});
          }

          this.setState({sipIp:res.data.sipIp,sip:res.data.sip,plan:res.data.plan,settings:res.data.settings[0],eventId:res.data.event_id,apiKey:res.data.api_key,sip:res.data.sip});
          this.authInterval = setInterval(this.checkInterval,300000);
          var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
          var expires = '; expires=' + date.toGMTString();
          document.cookie = 'auth='+res.data.token+' ' + expires + '; path=/';  

          if ('Notification' in window) {
            initializeFirebase();
            if(Notification.permission !== "granted" && Notification.permission !== "denied" && checkBrowserSupport()){
              this.setState({showNotificationPrompt:true});
            }
            else if(Notification.permission === "granted" && checkBrowserSupport()){
                this.requestNotification();
            }
          }
          let user = this.state.user;
          user.name = res.data.name;
          user.username = res.data.username;
          user.userId = res.data.client;
          user.profilePhoto = res.data.photo;
          user.type = res.data.type;

          let userInfo = this.state.userInfo;
          userInfo[res.data.username] = {name:res.data.name,photo:res.data.photo};

          this.setState({user:user,userInfo:userInfo,adminPhoto:res.data.adminUrl});

          this.socket = openSocket("https://ticket-api.superceed.com:9000",{
            withCredentials:true,
            reconnection:false,
            extraHeaders: {
              Authorization: this.getCookie("auth")
            }
          });


          this.socket.on("connect", ()=>{
            // alert("connected");
            this.setState({ socketError: false })
            this.socket.emit("tellOnline",{token:this.getCookie("auth")});
            // this.debugEmit();
          });

          // axios.get('https://proxy.superceed.com/api/recordings', {params:{type:"imc",emotionFilter:this.state.emotionFilter}},{withCredentials: true})
          // .then(res => {
          //   this.setState({recordings:res.data});
          // })      
          
          axios.get(this.host+'/ticket/load/'+this.getCookie("auth")+'/open/call/0', {_token:this.getCookie("auth")},{withCredentials: true})
          .then(res => {
            this.setState({chats:res.data});
          });

          this.socket.on("disconnect", ()=>{
            clearInterval(this.authInterval);
            this.setState({ socketError: true })
            clearTimeout(this.reconnectInterval);
            this.reconnectInterval = setTimeout(this.retryConnect,5000);
          });

          this.socket.on("connect_failed", ()=>{
            clearInterval(this.authInterval);
            this.setState({ socketError: true })
            clearTimeout(this.reconnectInterval);
            this.reconnectInterval = setTimeout(this.retryConnect,5000);
          }); 

          this.socket.on("connect_error", ()=>{
            clearInterval(this.authInterval);
            this.setState({ socketError: true })
            clearTimeout(this.reconnectInterval);
            this.reconnectInterval = setTimeout(this.retryConnect,5000);
          }); 

          // It's difficult to tell what's happening over the phone. You should just bring it into one of our certified repair shops to have it looked at.
          //Well, you can't transfer funds from this online acccount to a non-authorized account. There isn't much I can do until you authorize other account. You need to go back to the account management tab and under that you'll see the authorzation menu. First click on
          // setTimeout(()=>{
          //   this.transcriptFunction({agi_id:"12030230320",id:11491,transcription:"Is the signup process complex?",source:"customer",interim:true})
          // },1000)
          

          this.socket.on("textEmotionApi", data => {
            console.log(data);
         
              if(data.text_emotion_confidence >= parseFloat(this.props.value.threshold3) && data.source === "customer"){
                // if(this.state.selectedChat === "ongoing"){
        
      
                //   this.socket.emit("sendContextApi",{emotion:{emotion:data.text_emotion ?? "",extension_id:this.state.sip.length > 0 ? this.state.sip[0].sip_number : ""},id:this.state.user.userId});
      
                //   this.setState({conversation:tempChat},()=>{
                //     if(this.flagScroll){
                //       this.flagScroll(1); 
                //     }               
                    
                //   });
                // }
                // let action = this.getBestAction(data.text_emotion.toLowerCase());
                // if(action !== ""){
                //   console.log("yes!");
                //   let tempChat = this.state.conversation;

                //   let ts = Math.floor(Date.now() / 1000);

                //   let tempDate = moment.unix(ts).format('DD/MM/YYYY');
                //   for(let k = 0; k < tempChat["call"].length; k++){
                //     if(tempChat["call"][k]["date"] === tempDate ){
                //       let messageFound = false;
                //       for(let i = 0 ; i < tempChat["call"][k]["data"].length;i++){
                //         if(tempChat["call"][k]["data"][i].transcript_id === data.data_id){
                //           tempChat["call"][k]["data"][i].emotion_2 = {
                //                   result: data.text_emotion,
                //                   confidence: data.text_emotion_confidence,
                //                   action:action
                //                 };
                //           messageFound = true;
                //           // pos = tempChat["call"][k]["data"][i];
                //           break;
                //         }
                //       }
          
                //       // if(!messageFound){
                //       //   tempChat["call"][k]["data"].push(obj);
                //       //   pos = tempChat["call"][k]["data"][tempChat["call"][k]["data"].length - 1];
                //       // }

                //     }
          
                //   }

                //   // if(!tempChat["call"][tempChat["call"].length - 1]["count"]){
                //   //   console.log("yes!");
                //   //   let last = tempChat["call"][tempChat["call"].length - 1]["data"] ? tempChat["call"][tempChat["call"].length - 1]["data"].length - 1 : -1;
                //   //   console.log(last);
                //   //   if(last >= 0){
                //   //     tempChat["call"][tempChat["call"].length - 1]["data"][last].emotion_2 = {
                //   //       result: data.text_emotion,
                //   //       confidence: data.text_emotion_confidence,
                //   //       action:action
                //   //     }

                //   //     if(data.voice_data){
                //   //       if(data.voice_data.voice_amplitude){
                //   //         if(data.voice_data.voice_amplitude === "loud"){
                //   //           tempChat["call"][tempChat["call"].length - 1]["data"][last].energy = 100;
                //   //         } 
                //   //         else if(data.voice_data.voice_amplitude === "soft"){
                //   //           tempChat["call"][tempChat["call"].length - 1]["data"][last].energy = 0;
                //   //         } 
                //   //         else if(data.voice_data.voice_amplitude === "normal"){
                //   //           tempChat["call"][tempChat["call"].length - 1]["data"][last].energy = 50;
                //   //         } 
                //   //       }
          
                //   //       if(data.voice_data.voice_pace){
                //   //         if(data.voice_data.voice_pace === "fast"){
                //   //           tempChat["call"][tempChat["call"].length - 1]["data"][last].pace = 100;
                //   //         } 
                //   //         else if(data.voice_data.voice_pace === "slow"){
                //   //           tempChat["call"][tempChat["call"].length - 1]["data"][last].energy = 0;
                //   //         } 
                //   //         else if(data.voice_data.voice_pace === "normal"){
                //   //           tempChat["call"][tempChat["call"].length - 1]["data"][last].energy = 50;
                //   //         } 
                //   //       }
                //   //     }
                //   //   }
                //   // }
                //   this.setState({conversation:tempChat});
                // }
              }
            
            if(data.voice_data){
              if(data.voice_data.voice_amplitude){
                if(data.voice_data.voice_amplitude === "loud"){
                  this.setState({energy:100});
                } 
                else if(data.voice_data.voice_amplitude === "soft"){
                  this.setState({energy:0});
                } 
                else if(data.voice_data.voice_amplitude === "normal"){
                  this.setState({energy:50});
                } 
              }

              if(data.voice_data.voice_pace){
                if(data.voice_data.voice_pace === "fast"){
                  this.setState({pace:100});
                } 
                else if(data.voice_data.voice_pace === "slow"){
                  this.setState({pace:0});
                } 
                else if(data.voice_data.voice_pace === "normal"){
                  this.setState({pace:50});
                } 
              }
            }
            // else if(data.text_emotion !== ""){
            //   if(data.confidence >= parseFloat(this.props.value.threshold3)){
            //     let action = this.getBestAction(data.text_emotion);
            //     if(action !== ""){
            //       if(this.trigger){
            //         this.trigger(action.text,action.color);
            //       }
            //     }
            //   }
            // }            
          })          

          this.socket.on("textEmotionApi2", data => {
            console.log(data);
            if(data.text_emotion !== ""){
              let action = this.getBestAction(data.text_emotion);
              if(action !== ""){
                if(this.trigger){
                  this.trigger(action.text,action.color);
                }
              }
            }

          })

          this.socket.on("voiceEmotionApi", data => {
            // console.log(data);
            // if(data.voice_emotion !== ""){
            //   let action = this.getBestAction(data.voice_emotion);
            //   if(action !== ""){
            //     if(this.trigger){
            //       this.trigger(action.text,action.color);
            //     }
            //   }
            // }
            if(data.source === "agent"){
      
              if(data.voice_amplitude){
                if(data.voice_amplitude === "super loud"){
                  this.setState({energy:100});
                } 
                else if(data.voice_amplitude === "loud"){
                  this.setState({energy:75});
                } 
                else if(data.voice_amplitude === "soft"){
                  this.setState({energy:25});
                } 
                else if(data.voice_amplitude === "super soft"){
                  this.setState({energy:0});
                } 
                else if(data.voice_amplitude === "normal"){
                  this.setState({energy:50});
                } 
              }

              if(data.voice_pace){
                if(data.voice_pace === "super fast"){
                  this.setState({pace:100});
                } 
                else if(data.voice_pace === "fast"){
                  this.setState({pace:75});
                } 
                else if(data.voice_pace === "slow"){
                  this.setState({pace:25});
                } 
                else if(data.voice_pace === "normal"){
                  this.setState({pace:50});
                } 
                else if(data.voice_pace === "super slow"){
                  this.setState({pace:0});
                } 
              }
            }
            else{
              if(data.voice_amplitude){
                if(data.voice_amplitude === "super loud"){
                  this.setState({energy_customer:100});
                } 
                else if(data.voice_amplitude === "loud"){
                  this.setState({energy_customer:75});
                } 
                else if(data.voice_amplitude === "soft"){
                  this.setState({energy_customer:25});
                } 
                else if(data.voice_amplitude === "super soft"){
                  this.setState({energy_customer:0});
                } 
                else if(data.voice_amplitude === "normal"){
                  this.setState({energy_customer:50});
                } 
              }

              if(data.voice_pace){
                if(data.voice_pace === "super fast"){
                  this.setState({pace_customer:100});
                } 
                else if(data.voice_pace === "fast"){
                  this.setState({pace_customer:75});
                } 
                else if(data.voice_pace === "slow"){
                  this.setState({pace_customer:25});
                } 
                else if(data.voice_pace === "normal"){
                  this.setState({pace_customer:50});
                } 
                else if(data.voice_pace === "super slow"){
                  this.setState({pace_customer:0});
                } 
              }
            }
          })

          this.socket.on("transcriptionApi", (data)=>this.transcriptFunction(data))

          
          var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
          var expires = '; expires=' + date.toGMTString();
          document.cookie = 'auth='+res.data.token+' ' + expires + '; path=/';    

          this.setState({dashboardShown : true});
          
          axios.get(this.host + '/agent/'+this.getCookie("auth"))
          .then(res => {
              this.setInfo(res.data);
          })             
          
          axios.get(this.host+'/emoji/custom', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
          .then(res => {
            this.setState({emojiSet:res.data});
          });

          axios.get(this.host+'/department/'+this.state.user.userId)
          .then(res => {
            this.setState({department:res.data});
          })     

          axios.get(this.host+'/department2/'+this.getCookie("auth"))
          .then(res => {
            this.setState({agentDepartment:res.data});
          })            

          axios.get(this.host+'/api/label/get', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
          .then(res => {   
            this.setState({label:res.data});
          } 
          )         

          axios.get(this.host+'/api/customstatus/get', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
          .then(res => {   
            this.setState({customStatus:res.data},()=>{
              if(this.updateOption2){
                this.updateOption2();
              }
            });
          } 
          )     


  


        }
        else{
          var date = new Date();date.setTime(date.getTime() - (365 * 24 * 60 * 60 *1000));
          var expires = '; expires=' + date.toGMTString();
          document.cookie = 'auth= ' + expires + '; path=/';    
          window.location.reload();           
        }
      })      
      .catch(err => {
            this.setState({networkError:true,networkErrorText:err.toString()});
        //console.log(err);
        // var date = new Date();date.setTime(date.getTime() - (365 * 24 * 60 * 60 *1000));
        // var expires = '; expires=' + date.toGMTString();
        // document.cookie = 'auth= ' + expires + '; path=/';    
        // window.location.reload();            
      });



      }
      else{
        //console.log("authed");
      }
  }

  getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
  }  

  setDisplayName = (display) => {
    this.setState({displayName:display})
  }

  setAlwaysDisplay = (display) => {
    this.setState({alwaysDisplay:display})
  }  

  retryConnect = () => {
    clearTimeout(this.reconnectInterval);
    axios.post(this.host+'/api/auth/check', {_token:this.getCookie("auth"),refresh_token:this.getCookie("refresh_token")},{withCredentials: true})
    .then(res => {
      if(res.data.result === "success"){
        this.retryCount = 0;
        this.authInterval = setInterval(this.checkInterval,300000);
        var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
        var expires = '; expires=' + date.toGMTString();
        document.cookie = 'auth='+res.data.token+' ' + expires + '; path=/';    

        if(res.data.refresh_token){
          var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
          var expires = '; expires=' + date.toGMTString();
          document.cookie = 'refresh_token='+res.data.refresh_token+' ' + expires + '; path=/';   
        }

        this.setState({sipIp:res.data.sipIp,plan:res.data.plan,settings:res.data.settings[0],apiKey:res.data.api_key},()=>{

        axios.get(this.host+'/ticket/data/sync', {params:{_token:this.getCookie("auth"),ts:this.state.ticketTs}},{withCredentials: true})
        .then(res => {
          this.updateTicketTimeSync(res.data);
          if(this.state.labelTicketLoaded){
            this.updateTicketTimeWithLabelSync(res.data);
          }
          this.checkEvent();
          this.setState({ticketTs:Math.floor(Date.now() / 1000),socketError:false})

          let chatId = [];

          for(let i = 0; i < this.state.tickets; i++){
            chatId.push(this.state.tickets[i].id);
          }

          for(let i = 0; i < this.state.chats; i++){
            chatId.push(this.state.chats[i].id);
          }

          for(let i = 0; i < this.state.livechat; i++){
            chatId.push(this.state.livechat[i].id);
          }          

          this.socket.emit("joinChat",{chatId:chatId})

        }); 


        });
        if(!this.socket.connected){
          this.socket.io.opts.extraHeaders = {"Authorization":res.data.token};
          console.log(this.socket);
          this.socket.connect()
        }
      }
      else{
        window.location.reload();
      }
    })
    .catch(err=>{
      if(err.response?.status === 403 || err.response?.status === 500){
        this.retryCount++;
      }
      if(this.retryCount > 5){
        this.setState({networkError:true,networkErrorText:err.toString()});
      }
      else{
        clearTimeout(this.reconnectInterval);
        setTimeout(this.retryConnect,5000);
      }   
    })    
  }

  checkInterval = () => {
    if(!this.state.socketError){
        axios.post(this.host+'/api/auth/check', {_token:this.getCookie("auth"),refresh_token:this.getCookie("refresh_token")},{withCredentials: true})
        .then(res => {
          if(res.data.result === "success"){
            
            var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
            var expires = '; expires=' + date.toGMTString();
            document.cookie = 'auth='+res.data.token+' ' + expires + '; path=/';    
            this.setState({sipIp:res.data.sipIp,plan:res.data.plan,settings:res.data.settings[0],eventId:res.data.event_id,apiKey:res.data.api_key});

            if(res.data.refresh_token){
              var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
              var expires = '; expires=' + date.toGMTString();
              document.cookie = 'refresh_token='+res.data.refresh_token+' ' + expires + '; path=/';   
            }
          }
          else{
            window.location.reload();
          }
        })
        .catch(err=>{
          clearInterval(this.authInterval);
          setTimeout(this.retryConnect,5000);
        })
      }
  }

  addMesage = (messages,ticketId) => {
    // if(this.state.selectedChat !== 0){
    //   if(this.state.selectedChat === ticketId){
        audio.play();

        let tempChat = this.state.conversation;
        //var chats = this.state.tempChat[data.ticketId].chats;
        for(let i = 0; i < messages.length; i++){
                let obj = messages[i];
                //obj.has_meta = 0;
                //obj.isSender = false;

                if(obj.type !== "text" && obj.type !== "system"){
                  if(obj.type.indexOf("image") !== -1){
                    let fileObj = {"id":obj.id,"message":obj.message,"src":obj.message};
                    this.sendImageToManager(fileObj);
                  }
                  else{
                    let splitUrl =  obj.message.split("/");
                    let fileObj = {"id":obj.id,"message":obj.message,"src":obj.message,"fileName":splitUrl[splitUrl.length - 1],"fileType":obj.type};
                    this.sendFileToManager(fileObj);
                  }
                }                
                // this.updateTicketTime(data.ticketId);
                // if(obj.label !== undefined){
                //   if(obj.channel !== 'chat' && obj.channel !== 'LIVECHAT'){
                //     this.updateTicketTimeWithLabel(obj.ticketId,JSON.parse(obj.label));
                //   }
                // }
                // obj.ts = Math.floor(Date.now() / 1000);
                //obj.sender = "customer";



                //let tempChat = conversation[id];
                if(tempChat[ticketId]){
                  let tempDate = moment.unix(obj.ts).format('DD/MM/YYYY');

                  let found = false;

                  for(let k = 0; k < tempChat[ticketId].length; k++){
                    if(tempChat[ticketId][k]["date"] === tempDate ){
                      tempChat[ticketId][k]["data"].push(obj);
                      found = true;
                    }
          
                  }
                  if (!found){
                    let newObj = {};
                    newObj["date"] = tempDate;
                    newObj["data"] = [];
                    newObj["data"].push(obj);
            
                    tempChat[ticketId].push(newObj);
            
                    
                  }   

                if(tempChat[ticketId][0]['count'] !== undefined){
                  tempChat[ticketId][0]['count']++;
                }  
                else{
                  tempChat[ticketId][0]['count'] = 1;
                } 


                var orderedDates = {};
                //var tempCount = tempChat[obj.ticketId]['count'] + res.data.length;
          
                (tempChat[ticketId]).sort((a, b) => {
                  // console.log(moment(a, 'DD/MM/YYYY').toDate() > moment(b, 'DD/MM/YYYY').toDate());
                  if (a.count)
                  return -1;        
                  if (moment(a.date, 'DD/MM/YYYY').valueOf() > moment(b.date, 'DD/MM/YYYY').valueOf() )
                  return 1;
                  if (moment(a.date, 'DD/MM/YYYY').valueOf() < moment(b.date, 'DD/MM/YYYY').valueOf() )
                  return -1;
          
                  
                  return 0;
                });
          
                // tempChat[ticketId] = orderedDates;
                //tempChat[id]['count'] = tempCount;
              }

                
        }

        if(!this.state.focused){
          let tempData = this.state.channelData;
          tempData.read = false;
          this.setState({channelData:tempData});
        }

        //conversation[data.ticketId].chats = chats;
        this.setState({                      
          newMessage : true,  
          conversation : tempChat,
          lastMessageId: (messages.length > 0 ? messages[messages.length - 1].id : 0)
        },()=>{
          var height = this.getHeight();
          this.flagScroll(1);
        })   
                        
    //   }
    // }    
  }

  checkNewMessage = (ticketId) => {
    if(this.state.conversation[ticketId]){
      if(Object.keys(this.state.conversation[ticketId]).length > 1){
          // var orderedDates = {};
          // Object.keys(this.state.conversation[ticketId]).sort((a, b) => {
          //   // console.log(b);
          //   return a !== 'count' ? moment(a, 'DD/MM/YYYY').toDate() - moment(b, 'DD/MM/YYYY').toDate() : -1;
          // }).forEach((key) => {
          //   //console.log(key);
          //   orderedDates[key] = this.state.conversation[ticketId][key];
          // })

          // // console.log(Object.keys(orderedDates)[Object.keys(orderedDates).length - 1]);

          // let lastKey = null;

          // if(Object.keys(orderedDates)[Object.keys(orderedDates).length - 1] !== "count"){

          //   lastKey = orderedDates[Object.keys(orderedDates)[Object.keys(orderedDates).length - 1]];
          // }
          // else{
          //   lastKey = orderedDates[Object.keys(orderedDates)[Object.keys(orderedDates).length - 2]];
          // }

          let lastDate = this.state.conversation[ticketId][this.state.conversation[ticketId].length - 1]["data"];



          // console.log(lastKey);
          let messageLength = lastDate.length;
          //console.log(Object.keys(this.state.conversation[ticketId])[Object.keys(this.state.conversation[ticketId]).length - 2]);
          // return;

          let messageId = lastDate[messageLength - 1].id;
          // console.log(Object.keys(this.state.conversation[ticketId])[Object.keys(this.state.conversation[ticketId]).length - 2]);
          // let messageId = this.state.lastMessageId;
          axios.post(this.host+"/api/ticket/data/check/"+ticketId+"/"+messageId,{_token:this.getCookie("auth"),ts:this.state.ticketTs},{withCredentials: true})
          .then(
              res => {
                  if(res.data.length > 0){
                    //add message
                    this.addMesage(res.data,ticketId);
                    // this.setState({messages:res.data.reverse().concat(this.state.messages)})
                  }

              }
          )
          .catch(err=>{
              console.error("Sync error. Please close the app and try again.");
              //alert("Failed to load https://ticket-api.hyperceed.com/ticket/load/"+this.props.token);
          }) 
        }  
    }    
  }

  checkEvent = () => {
    axios.get(this.host+'/event/'+this.state.eventId,{params:{_token:this.getCookie("auth")}})
    .then(res => {
        if(res.data.result !== undefined){
            this.checkNewMessage(this.state.channelData.id);          
            this.setState({eventId:res.data.id});
        }
        else if(res.data.length > 0){
            let messages = this.state.messages;
            let conversation = this.state.conversation;
            for(let i = 0; i < res.data.length; i++){
                if(res.data[i].action === "delete_message"){

                  //remove message

                  // if(this.state.selectedChat !== null){
                  //   if(this.state.selectedChat === parseInt(res.data[i].ticket_id)){
                    for(let k = 0; k < conversation[res.data[i].ticket_id].length; k++){
                      if(!conversation[res.data[i].ticket_id][k].count){
                        for(let i = 0; i < conversation[res.data[i].ticket_id][k]["data"].length; i++){
                          if(conversation[res.data[i].ticket_id][k]["data"][i].id === res.data[i].ref_id){
                            conversation[res.data[i].ticket_id][k]["data"].splice(i,1);
                            conversation[res.data[i].ticket_id][0]['count']--;
                            if(conversation[res.data[i].ticket_id][k]["data"].length === 0){
                              conversation[res.data[i].ticket_id].splice(k,1);
                              break;
                            }                          
                          }
                        }
                      }
                    }
      
                      // let chats = this.state.conversation[data.ticketId].chats;
                      // //console.log(chats.filter(message => message.id !== messageId));
                      // chats = chats.filter(message => message.id !== data.messageId);
                      // conversation[data.ticketId].chats = chats;
                      
                      this.deleteFile(res.data[i].ticket_id);
                //     }
                // }


                    // messages = messages.filter(message => message.id !== parseInt(res.data[i].ref_id));
                }
            }
            this.setState({conversation:conversation});
            this.setState({eventId:res.data.id});
            this.setState({messages:messages},()=>{
              this.checkNewMessage(this.state.channelData.id);               
            });           
        }
    })
    .catch(err =>{
        console.log(err);
    });      
  }

  toggleDark = () => {
    if(this.getCookie("dark") !== undefined){
      var theme  =!this.state.darkTheme
      
      this.setState({darkTheme: theme });
      if(theme === true){
        document.body.classList.add('dark');
      }
      else{
        document.body.classList.remove('dark');
      }
      var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
      var expires = '; expires=' + date.toGMTString();
      document.cookie = 'dark= '+ theme + expires + '; path=/';            
    }
    else{
      document.body.classList.remove('dark');
      this.setState({darkTheme: true });
      var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
      var expires = '; expires=' + date.toGMTString();
      document.cookie = 'dark= '+ true + expires + '; path=/';     
    }
  }

  toggleRich = () => {
    if(this.getCookie("rich") !== undefined){
      var rich  =!this.state.richLink
      this.setState({richLink: rich });

      var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
      var expires = '; expires=' + date.toGMTString();
      document.cookie = 'rich= '+ rich + expires + '; path=/';            
    }
    else{
      this.setState({richLink: true });
      var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
      var expires = '; expires=' + date.toGMTString();
      document.cookie = 'rich= '+ rich + expires + '; path=/';    
    }
  }  

  checkRead = (id) => {
    //console.log(id);
    this.setState({ tickets: this.state.tickets.map( channel => {
      if(channel.id === id){
        channel.read = !channel.read
      }
      return channel;
    }) })
  }

  setTyping = (id) => {
    //console.log(id);
    //console.log(id);
      this.setState({ tickets: this.state.tickets.map( channel => {
        if(channel.id.toString() === id){
          channel.typing = []
        }
        return channel;
      }) })

      this.setState({ chats: this.state.chats.map( channel => {
        if(channel.id.toString() === id){
          channel.typing = []
        }
        return channel;
      }) })    

      this.userTimeout[id] = setTimeout(() => {
        this.setState({ tickets: this.state.tickets.map( channel => {
          if(channel.id.toString() === id){
            channel.typing = []
          }
          return channel;
        }) })
  
        this.setState({ chats: this.state.chats.map( channel => {
          if(channel.id.toString() === id){
            channel.typing = []
          }
          return channel;
        }) })            
      },2000)
  }  

  uploadFiles = (files,id) => {
    this.setState({uploadState:true,uploadPercent:0});
    const formData = new FormData();
    for(var i = 0; i < files.length; i++){
      formData.append('attachment[]',files[i]);
    }
    formData.append('_token',this.getCookie("auth"));
    formData.append('ticket_id',id);
    const config = {
      headers: {
          'Content-Type': 'multipart/form-data'
      },
      withCredentials: true,
      onUploadProgress: progressEvent => {
        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
        //console.log("onUploadProgress", totalLength);
        if (totalLength !== null) {
            let percent = Math.round( (progressEvent.loaded * 100) / totalLength );
            this.setState({uploadPercent:percent});
        }
      }
    }   
    axios.post(this.host+'/api/upload',formData , config)
    .then(res => {
      this.setState({uploadState:false});
      //console.log(res);
      //console.log(res.data);
      if(res.data.result === "success"){
        for(var i = 0; i < res.data.file.length; i++){
          if(res.data.fileType[i].includes("image/")){
            this.sendChat("https://ticket-cdn.superceeduniverse.com/attachment/v101/"+res.data.file[i],id,"image");
          }
          else{
            this.sendChat("https://ticket-cdn.superceeduniverse.com/attachment/v101/"+res.data.file[i],id,res.data.fileType[i],res.data.fileName[i]);
          }
        }
      }
    })     
  }

  emailAttachment = (message,id,type,files) => {
    this.setState({uploadState:true,uploadPercent:0});
    const formData = new FormData();
    for(var i = 0; i < files.length; i++){
      formData.append('attachment[]',files[i]);
    }
    formData.append('_token',this.getCookie("auth"));
    formData.append('message',message);
    formData.append('ticket_id',id);
    formData.append('closed',"0");
    formData.append('sender',this.state.user.username);
    formData.append('type',type);
    const config = {
      headers: {
          'Content-Type': 'multipart/form-data'
      },
      withCredentials: true,
      onUploadProgress: progressEvent => {
        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
        //console.log("onUploadProgress", totalLength);
        if (totalLength !== null) {
            let percent = Math.round( (progressEvent.loaded * 100) / totalLength );
            this.setState({uploadPercent:percent});
        }
      }
    }   
    axios.post(this.host+'/api/ticket/message/reply', formData,config)
    .then(res => {
      this.setState({uploadState:false,uploadPercent:0});

      for(var i = 0; i < res.data.file.length; i++){
        if(res.data.fileType[i].includes("image/")){
          this.sendChat("https://ticket-cdn.superceeduniverse.com/attachment/v101/"+res.data.file[i],id,"image");
        }
        else{
          this.sendChat("https://ticket-cdn.superceeduniverse.com/attachment/v101/"+res.data.file[i],id,res.data.fileType[i],res.data.fileName[i]);
        }
      }

      // if(type !== "text"){
      //   if(type === "image"){
      //     let obj = {"id":res.data.id,"message":message,"src":message};
      //     this.sendImageToManager(obj);
      //   }
      //   else{
      //     let obj = {"id":res.data.id,"message":message,"src":message,"fileName":fileName,"fileType":type};
      //     this.sendFileToManager(obj);
      //   }
      // }
      //console.log(res);
      //console.log(res.data);
      const newObj = {
        id: res.data.id,
        message: message,
        date: "07-06-2019",
        time: "11:00",
        type: type,
        isSender: true,
        has_meta: 0,        
        sender: this.state.user.username,
        ts: Math.floor(Date.now() / 1000),
        notes: []
      }

      let tempChat = this.state.conversation;

      //let tempChat = conversation[id];

        let tempDate = moment().format('DD/MM/YYYY');
        // if(tempChat[id][tempDate] !== undefined ){
        //   tempChat[id][tempDate].push(newObj);
        // }
        // else{
        //   tempChat[id][tempDate] = [];
        //   tempChat[id][tempDate].push(newObj);
        // }




        let found = false;
        for(let k = 0; k < tempChat[id].length; k++){
          if(tempChat[id][k]["date"] === tempDate ){
            tempChat[id][k]["data"].push(newObj);
            found = true;
          }

        }
        if (!found){
          let newObj2 = {};
          newObj2["date"] = tempDate;
          newObj2["data"] = [];
          newObj2["data"].push(newObj);
  
          tempChat[id].push(newObj2);
  
          
        }             

      

      
  
      // Object.keys(this.state.conversation).map( key => {
          
      //   if(parseInt(key) === id){
      //     conversation[key].chats.push(newObj)
      //   }
      // })
        if(tempChat[id][0]['count'] !== undefined){
          tempChat[id][0]['count']++;      
        }
        else{
          tempChat[id][0]['count'] = 1;   
        }
  
      this.setState({        
        lastMessageId: res.data.id,
        conversation : tempChat
      })
      //this.setTyping(id);      
      this.scrollChat(1);
    })
    .catch(err => {
      // console.error(err);
      alert(JSON.stringify(err.response.data));
    })  
  }  

  sendChat = (message,id,type,fileName) => {
    //var split = window.location.pathname.split("/");
    
    if(this.state.channelData.channel !== "chat" && this.state.channelData.channel !== "LIVECHAT"){
    axios.post(this.host+'/api/ticket/message/reply', {ticket_id:id,message:message,closed:"0",sender:this.state.user.username,_token:this.getCookie("auth"),type:type},{withCredentials: true})
    .then(res => {
      if(type !== "text"){
        if(type === "image"){
          let obj = {"id":res.data.id,"message":message,"src":message};
          this.sendImageToManager(obj);
        }
        else{
          let obj = {"id":res.data.id,"message":message,"src":message,"fileName":fileName,"fileType":type};
          this.sendFileToManager(obj);
        }
      }
      //console.log(res);
      //console.log(res.data);
      const newObj = {
        id: res.data.id,
        message: message,
        date: "07-06-2019",
        time: "11:00",
        type: type,
        isSender: true,
        has_meta: 0,        
        sender: this.state.user.username,
        ts: Math.floor(Date.now() / 1000),
        notes: []
      }

      let tempChat = this.state.conversation;

      //let tempChat = conversation[id];

      

        let tempDate = moment().format('DD/MM/YYYY');



          let found = false;
          for(let k = 0; k < tempChat[id].length; k++){
            if(tempChat[id][k]["date"] === tempDate ){
              tempChat[id][k]["data"].push(newObj);
              found = true;
            }
  
          }
          if (!found){
            let newObj2 = {};
            newObj2["date"] = tempDate;
            newObj2["data"] = [];
            newObj2["data"].push(newObj);
    
            tempChat[id].push(newObj2);
    
            
          }            
        



        // if(tempChat[id][tempDate] !== undefined ){
        //   tempChat[id][tempDate].push(newObj);
        // }
        // else{
        //   tempChat[id][tempDate] = [];
        //   tempChat[id][tempDate].push(newObj);
        // }
      

      
  
      // Object.keys(this.state.conversation).map( key => {
          
      //   if(parseInt(key) === id){
      //     conversation[key].chats.push(newObj)
      //   }
      // })
        if(tempChat[id][0]['count'] !== undefined){
          tempChat[id][0]['count']++;      
        }
        else{
          tempChat[id][0]['count'] = 1;   
        }
  
      this.setState({        
        lastMessageId: res.data.id,
        conversation : tempChat
      })
      //this.setTyping(id);      
      this.scrollChat(1);
    })
    .catch(err => {
      // console.error(err);
      alert(JSON.stringify(err.response.data));
    })
    }
    else{
      //console.log("sending to "+id);
      let title = this.state.channelData.title;     
      this.socket.emit("newChatMessage",{message:message,ticketId:this.state.channelData.id,type:type,sender:this.state.user.username,name:this.state.user.name,title:title,client:this.state.user.userId})
    }


  }

  acceptLiveChat = () => {
    this.socket.emit("acceptLiveChat",{token:this.getCookie("auth")});
  }

  deleteChat = (id) => {
    this.setState({ tickets: [...this.state.tickets.filter(
      channel => channel.id !== id
    )] })
  }

  stateChange = (item,value) => {
    this.setState({
      [item]: value,
  });    
  }

  panel = (status) => {
    this.setState({
        chatInfoShown: !this.state.chatInfoShown,
    });
  }  

  loadChat = (id) => {
    this.socket.emit("getMeetingCount",{id:id});
    this.setState({chatDataLoading:true,newMessage:false,mainDataLoading:true},()=>{

    });
 
    // if(this.scrollChat !== undefined){
    //   this.scrollChat();
    // }


    // let tickets = this.state.tickets;
    // for(var i = 0; i < this.state.tickets.length; i++){
    //     if(this.state.tickets[i].id === id){
    //         tickets[i].read = true;
    //         break;
    //     }
    // }

    // let chats = this.state.chats;
    // for(var i = 0; i < this.state.chats.length; i++){
    //     if(this.state.chats[i].id === id){
    //         chats[i].read = true;
    //         break;
    //     }
    // }

    // let livechat = this.state.livechat;
    // for(var i = 0; i < this.state.livechat.length; i++){
    //   for(var k = 0; k < this.state.livechat[i].livechats.length; k++){
    //     if(this.state.livechat[i].livechats[k].id === id){
    //       livechat[i].livechats[k].read = true;
    //         break;
    //     }
    //   }
    // }    


    axios.get(this.host+'/api/ticket/data3/'+id+'/0', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
    .then(res => {
      
      if(res.data.length >= 30){
        this.setState({loadMore:true});
      }
      else{
        this.setState({loadMore:false});
      }      
      var tempChat = this.state.conversation;
      if(tempChat[id] === undefined){
        tempChat[id] = {};
      }
      tempChat[id] = [];

      for(let i = 0; i < res.data.length; i++){

      
        if(res.data[i].recommendation !== null && res.data[i].recommendation !== undefined){
          let recData = JSON.parse(res.data[i].recommendation.rec_data);
          let split;
          if(recData.classification){
            split = recData.classification.split(": ");
            res.data[i].personality = {};
            res.data[i].personality.result = split[1];
            res.data[i].classification = split[0];
  
          }

   
          res.data[i].keywin = "";
          res.data[i].traits = "";
          res.data[i].handle = recData.recommendation;
          if(recData.what_to_do){
            res.data[i].what_to_do = recData.what_to_do;
          }
          if(recData.how_to_say){
            res.data[i].how_to_say = recData.how_to_say;
          }
          if(recData.what_to_say){
            res.data[i].what_to_say = recData.what_to_say;
          }
          res.data[i].rec_confidence = recData.confidence ?? 1;
       
      
            // if(this.trigger){
            //   this.trigger(context,"red");
            // }
            // this.setState({conversation:tempChat},()=>{
            //   if(this.flagScroll){
            //     this.flagScroll(1); 
            //   }               
              
            // });
          // if(s

          // if(split[0] === "CONTEXT"){
          //   if(split[1] !== "Buy Signal"){
     
          //     res.data[i].context = {"context_name":split[1],"text":split[1],"color":"","suggestion": recData.recommendation ?? ""};
          //     // if(this.trigger){
          //     //   this.trigger(context,"red");
          //     // }


          //     // this.socket.emit("sendContextApi",{context:{context:split[1] ?? "",extension_id:this.state.sip.length > 0 ? this.state.sip[0].sip_number : ""},id:this.state.user.userId});
          //   }
          // }
          // else if(split[0] === "PERSONALITY"){
          //   res.data[i].personality = {};
          //   res.data[i].personality.result = split[1];

          //   res.data[i].keywin = "";
          //   res.data[i].traits = "";
          //   res.data[i].handle = recData.recommendation;
         
        
          //     // if(this.trigger){
          //     //   this.trigger(context,"red");
          //     // }

          //     // this.socket.emit("sendContextApi",{personality:{personality:split[1] ?? "",extension_id:this.state.sip.length > 0 ? this.state.sip[0].sip_number : ""},id:this.state.user.userId});
            
          // }
          // else if(split[0] === "EMOTION"){
          //   let action = this.getBestAction(split[1].toLowerCase())
          //   res.data[i].emotion_2 = {
          //     result: split[1],
          //     confidence: recData.confidence,
          //     action:{action:split[1],"text":recData.recommendation,color:action.color}
          //   };

          //   // this.socket.emit("sendContextApi",{emotion:{emotion:split[1] ?? "",extension_id:this.state.sip.length > 0 ? this.state.sip[0].sip_number : ""},id:this.state.user.userId});


          // }
        }




        
        let tempDate = moment.unix(res.data[i].ts).format('DD/MM/YYYY');
        let found = false;
        for(let k = 0; k < tempChat[id].length; k++){
          if(tempChat[id][k]["date"] === tempDate ){
            tempChat[id][k]["data"].push(res.data[i]);
            found = true;
          }

        }

        if (!found){
          let newObj = {};
          newObj["date"] = tempDate;
          newObj["data"] = [];
          newObj["data"].push(res.data[i]);
  
          tempChat[id].push(newObj);
  
          
        }        


      }

      tempChat[id].unshift({"count":res.data.length});

      //tempChat[id].chats = res.data;
      this.setState({     
        ticketNotFoundError : false,
        conversation : tempChat,
        chatLoading: false,
        chatDataLoading: false,
        selectedChat: id ?? "ongoing",
        mainDataLoading: false,
        ticketTs: Math.floor(Date.now() / 1000),
        lastMessageId: (res.data.length > 0 ? res.data[res.data.length - 1].id : 0)
      })
      this.scrollChat();
    })
    .catch(err => {
      this.setState({ticketNotFoundError: true,chatLoading: false});
      console.log(err);      
    });    
  }

  sendTyping = (id) => {
    //console.log(id);
    //{url:"https://www.superceed.com/images/svg/blue_icon.svg",name:"Koh",id:0},{url:"https://www.superceed.com/images/svg/blue_icon.svg",name:"Lee",id:1}
    this.socket.emit("sendTyping",{ticketId:id,url:this.state.user.profilePhoto,name:this.state.user.name,username:this.state.user.username});
  }

  removeTyping = (id) => {
    this.socket.emit("removeTyping",{ticketId:id,username:this.state.user.username});
  }

  setTicketStatus = (id,status,label) => {

    let tickets = this.state.tickets;
    tickets.forEach(item => {
      
      if(item.id === parseInt(id)){

        if(item.closed !== status){

          item.closed = status;  
           
        }
      }
    });

    let livechat = this.state.livechat;
    livechat.forEach(item => {
      
      if(item.id === parseInt(id)){

        if(item.closed !== status){

          item.closed = status;  
           
        }
      }
    });    
    let message = this.state.user.name+" has set status to "+label+".";
    // let message = (status === 0 ? this.state.user.name+" has set status to open." : (status === 1 ? this.state.user.name+" has set status to pending." : this.state.user.name+" has set status to resolved."));

    axios.post(this.host+'/api/ticket/message',{_token:this.getCookie("auth"),ticket_id:this.state.channelData.id,message:message,sender:"server",type:"system",status:status},{withCredentials: true})
    .then(res => {

    })
    .catch(err => {
      let channelData = this.state.channelData;
      channelData.closed = status;
          
      this.setState({ticketNotFoundError: true,chatLoading: false,channelData:channelData});
      console.log(err);      
    });

    this.setState({tickets:tickets,livechat:livechat});
  }

  startMeeting = (id) => {
    this.socket.emit("joinMeeting",{id:parseInt(id)});
    this.setState({onMeeting : true});
  }

  stopMeeting = (id) => {
    this.setState({onMeeting : false});
  }

  leaveMeeting = (id) => {
    this.setState({onMeeting : false});
    this.socket.emit("leaveMeeting",{id:parseInt(id)});
  }  

  toggleChat = () => {
    this.setState({showChatonMeeting: !this.state.showChatonMeeting});
  }

  toggleCollapse = (i) => {
    let livechat = this.state.livechat;
    livechat[i].collapsed = !livechat[i].collapsed;
    this.setState({livechat:livechat});
  }

  setToFalse = () => {
    this.setState({loadMoreChanged:false});
  }

  updateLabel = (label) => {
    this.setState({label:label});
  }

  updateStatus = (status) => {
    this.setState({customStatus:status},()=>{
      if(this.updateOption){
        this.updateOption();
        
      }

      if(this.updateOption2){
        this.updateOption2();
      }
    });
  }

  oauth = (token) => {
    axios.post(this.host+'/api/oauth/verify', {token:token}, {withCredentials: true})
    .then(res => {
      if(res.data.result === "success"){
        var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
        var expires = '; expires=' + date.toGMTString();
        document.cookie = 'auth='+res.data.token+' ' + expires + '; path=/';  
        this.setState({        
          loginValid : true
        })                
        window.location.reload();
      }
      else{
        this.setState({        
          loginValid : false
        })  
        this.enableOAuth();
      }
    })
    .catch(err => {
      console.error(err);
      this.enableOAuth();
    })
  }

  setTabCookie = (tab) => {
    var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
    var expires = '; expires=' + date.toGMTString();
    document.cookie = 'tab='+tab+' ' + expires + '; path=/';      
  }


  auth = (username,password,admin,tfa) => {
    var error = false;
    if(username === ""){
      this.setState({loginValid : false,loginError:"Username is required."});
      error = true;
    }
    else if(password === ""){
      this.setState({loginValid : false,loginError:"Password is required."});
      error = true;
    }    
      if(!error){
        this.setState({logging:true,loginValid:true,loginError:""},()=>{
          axios.post(this.host+'/api/auth2', {client:username,password:password,type:admin?"admin":"agent",tfa:tfa}, {withCredentials: true})
          .then(res => {

            if(res.data.result === "success"){
              var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
              var expires = '; expires=' + date.toGMTString();
              document.cookie = 'auth='+res.data.token+' ' + expires + '; path=/';  
              this.setState({        
                loginValid : true
              })                
              window.location.reload();
            }
            else{
                this.setState({       
                  logging:false, 
                  loginValid : false,
                  loginError: res.data.description,
                  tfa:(res.data.description === "2FA required.")
                },()=>{
                  this.clearField();
                })  
            }
        })
        .catch((err)=>{
          this.setState({        
            loginValid : false,
            loginError: "Failed",
            logging:false
          },()=>{
            this.clearField();
          })  
        });
      })
    }
  }

  logout = () => {
    axios.delete(this.host+'/api/user/signout',{params:{_token:this.getCookie("auth"),current:"true"}},{withCredentials: true})
    .then(res => {
      var date = new Date();date.setTime(date.getTime() - (365 * 24 * 60 * 60 *1000));
      var expires = '; expires=' + date.toGMTString();
      document.cookie = 'auth= ' + expires + '; path=/';   
      localStorage.removeItem('theme');     
      window.location.reload();   
        
    }).catch(err => {
      var date = new Date();date.setTime(date.getTime() - (365 * 24 * 60 * 60 *1000));
      var expires = '; expires=' + date.toGMTString();
      document.cookie = 'auth= ' + expires + '; path=/';   
      localStorage.removeItem('theme');     
      window.location.reload();   
    });       
  }

  updateSettings = (settings) => {
    this.setState({settings:settings});
  }

  setClicked = () => {
    // alert("ok");
    // this.selectedId = id;
    this.setState({loadFromHome:true});
  }

  setSelectedId = (id) => {
    // alert("ok");
    this.selectedId = id;
    
    let tickets = this.state.tickets;
    for(var i = 0; i < this.state.tickets.length; i++){
        if(this.state.tickets[i].id === id){
            tickets[i].read = true;
            break;
        }
    }

    let chats = this.state.chats;
    for(var i = 0; i < this.state.chats.length; i++){
        if(this.state.chats[i].id === id){
            chats[i].read = true;
            break;
        }
    }

    let livechat = this.state.livechat;
    for(var i = 0; i < this.state.livechat.length; i++){
      for(var k = 0; k < this.state.livechat[i].livechats.length; k++){
        if(this.state.livechat[i].livechats[k].id === id){
          livechat[i].livechats[k].read = true;
            break;
        }
      }
    }      

    if(this.state.conversation[id]){
      this.socket.emit("readMessage",{ticketId:id,username:this.state.user.username})      
    }


    let tempChat = this.state.conversation;
    if(tempChat[id]){

      // console.log(messageCount);
      if(tempChat[id][0].count >= 30){
        this.setState({loadMore:true});
      }
      else{
        this.setState({loadMore:false});
      }
      // this.setState({loadMore:false});
    }
    else{
      this.setState({loadMore:false});
    }

    this.checkNewMessage(id);    
    
  }

  render() {
    //const newVersion = useSelector(state => state.newVersion);
      if(this.getCookie("auth") === undefined){
        return (
          <Router>
            <Switch>
                <Route exact path={['/ticket/*','/chat/*','/livechat/*','/login','/report/*','/chat','/ticket','/upload','/call','/call/*','/call_config','/supervisory','/voice_generation']}>
                  <Redirect to='/'/>
                </Route>
                <Route exact path={['/end/livechat']} render={props => (
                  <React.Fragment>
                    <div className="margin-left-2x margin-right-2x margin-top-2x">
                      <a href='/' target='_blank'>
                          <div className="valign-wrapper">
                          <img style={{width:"30px"}} src={process.env.PUBLIC_URL + '/icon-256px.png'} /> 
                          <h5 className="bold-text-2">Hyperceed</h5>
                          </div>
                      </a>                      
                      <h6>Live Chat Session Ended. Thanks for coming!</h6>
                      <button className="btn" onClick={()=>{window.location.reload()}}>Close</button>
                    </div>
                  </React.Fragment>
                )}/>
                <Route exact path="/register/livechat/:token" component={LiveChat} />
                <Route exact path="/start/livechat/:token" render={props=> (<LiveChatMain {...props} focused={this.state.focused}/>)} />
                <Route exact path={["/",'/pricing','/contact']} render={props => (   
                  <HomePage {...props} logging={this.state.logging} change={this.stateChange} login={this.auth} clear={e=>this.clearField=e}  tfa={this.state.tfa} loginError={this.state.loginError} loginValid={this.state.loginValid} dark={this.state.darkTheme} oauth={this.oauth} enableOAuth={e=>this.enableOAuth=e}/>
                 )}/>
 
                <Route exact path="/about" component={About} />
                <Route path="*" component={NotFound} />    

                <Modal
                      isOpen={this.state.modalIsOpen}
                      onAfterOpen={this.afterOpenModal}
                      onRequestClose={this.closeModal}
                      style={modalStyles}
                      contentLabel="Example Modal"
                      ariaHideApp={false} 
                      closeTimeoutMS={200}       
                                                       
                      className={""+(this.state.darkTheme ? "dark" : null)}   
 
                      
                    >
                      <div style={{width:'500px'}}>
                        <div className="ng-modal-main-header">
                          <h2>About</h2>
                        </div>
                        <div className="ng-modal-main-body padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x">
                          <p className="">Superceed</p>
                        </div>
                        <div className="ng-modal-main-footer right-text">                      
                          <button className="btn-flat ng-close-btn waves-effect waves-dark" onClick={this.closeModal}>close</button>
                          {/* <button className="btn waves-effect waves-light margin-left-1x" onClick={this.closeModal}>OK</button> */}
                        </div>
                      </div>
                    </Modal>                        
              </Switch>                                 
          </Router>
        );
      }
      else{
          return (  
            <Router>
              <Switch>
                {( !this.state.networkError ? <Route exact path={['/livechat/*','/','/ticket','/chat','/ticket/*','/chat/*',"/report","/report/*","/interactions","/upload","/call","/recording","/call/*","/call_config","/supervisory","/voice_generation"]} render={props => (   
                  
                  <div className="App flex-col full-height">
                    <Helmet defer={false}>                      
                      <title>{((this.state.tickets.filter(ticket=>ticket.read === false).length + this.state.chats.filter(ticket=>ticket.read === false).length + this.state.livechat.filter(ticket=>ticket.read === false).length) > 0  ? "("+( this.state.tickets.filter(ticket=>ticket.read === false).length + this.state.chats.filter(ticket=>ticket.read === false).length).toString() + ") " : "")}SuperCall</title>
                    </Helmet> 

                    {(this.state.sip.length > 0 ? <SIPml sipIp={this.state.sipIp} refreshCallHistory={this.refreshCallHistory} identity={this.state.sip[0].sip_number} password={this.state.sip[0].password} focused={this.state.focused} stateChange={this.stateChange} reconnect={e => this.reconnect = e} callNumber={e => this.callNumber = e} setMute={e=> this.mute = e} hangUp={e => this.hangUp = e}/> : null)}
                    <FilterModal toggleFilterModal={this.toggleFilterModal} open={this.state.filterModalVisible}/> 
                    {/* {(this.state.apiKey !== undefined && this.state.apiKey !== ""  ? <Chatbot apiKey={this.state.apiKey} getCookie={this.getCookie}/> : null)} */}
                    {
                      (
                        this.state.forceToHome || this.state.ticketNotFoundError ?
                        this.setState({forceToHome:false,ticketNotFoundError: false},()=>{
                          props.history.replace('/') 
                        })
                        : null
                      )
                    }   
                    {
                      (
                        this.state.goToCall ?
                        this.setState({goToCall:false},()=>{
                          props.history.push('/call/ongoing') 
                        })
                        : null
                      )
                    }           
                    <div className="notification-holder">
                      {(this.state.socketError ? <div className="notification-bar-2 red">
                        <h6 className="white-text" style={{lineHeight:'0'}}>No connection</h6>
                      </div> : null)}
                      {/* {(this.state.asteriskError ? <div className="notification-bar-2 red">
                        <h6 className="white-text full-width" style={{lineHeight:'0'}}>Failed to establish connection to Superceed</h6>
                        <button className="button-new" onClick={this.reconnect}>Reconnect</button>
                      </div> : null)} */}
                      {(this.state.showNotificationPrompt ? <div className="notification-bar-2 grey linked" onClick={this.enableNotifications}>
                        <h6 className="white-text" style={{lineHeight:'0'}}>Click here to enable desktop notifications.</h6>
                      </div> : null)}
                      {this.state.liveChatQueue.length > 0 ? <div className="notification-bar-2 linked green valign-wrapper">
                                  <FontAwesomeIcon
                                  style={{fontSize:"20px"}}
                                  color="#fefefe"
                                  icon={faHeadset}/>                        
                                  <h6 className="white-text margin-left-1x">{this.state.liveChatQueue.length} incoming live chat{this.state.liveChatQueue.length > 1 ? "s":""}</h6>
                                  <button className="green button-new white-text darken-1 margin-left-2x" onClick={this.acceptLiveChat}>Take one</button>
                                </div> : null}                      
                      {(this.state.plan === 0 ? 
                      null
                      // <div className="notification-bar-2 orange">
                      //   <h6 className="white-text" style={{lineHeight:'0'}}>Trial plan</h6>
                      // </div> 
                      : null)}  
                      <NewVersion/>                    
                    </div>
                    <div className={"main-window flex-row full-height overflow-y overflow-x-hidden "+(this.state.darkTheme ? "dark" : "")}>
                      <LeftPanel {...props} navigateTo={e=>this.navigateTo=e} ticketUnread={this.state.tickets.filter(ticket=>ticket.read === false).length > 0 } chatUnread={false} openSettings={this.openExpModal} showUpgrade={this.openUpgradeModal} showAbout={this.openModal} logout={this.logout} profilePhoto={this.state.user.profilePhoto} name={this.state.user.name} username={this.state.user.username} socket={this.socket} userInfo={this.state.userInfo} getCookie={this.getCookie} label={this.state.label} dark={this.state.darkTheme}/>
                    <div className="flex-row flex-no-shrink view-window" style={{width:'calc(100% - 60px)'}}>
                      <Route exact path={['/recording']} render={props => (
                        <React.Fragment>
                              <div className={"flex-col chat-list-background  "+(this.state.onMeeting ? "hide" : "")} style={{min:'1px',width:'100%'}}>
                                
                                  <div className="flex-col full-height">
                                  {(this.state.user.userId !== "-" ? <ChatListTitle2 customStatus={this.state.customStatus} updateOption={e => this.updateOption2 = e} toggleFilterModal={this.toggleFilterModal} emotionVisible={this.state.emotionVisible} toggleEmotion={false}  userId={this.state.user.userId} showAddButton={true} title="Recordings" showFilterButton="true" dark={this.state.darkTheme} filter={this.state.filter} switchFilter={this.switchFilter} extraClass="chat-list-title-text bold-text-2"/> : null)}
                                        <div className="margin-top-2x"></div>
                                        <div className="left-panel-title-wrapper hide" style={{marginTop:0}}>
                                          {/* <div className={"custom-tab unselectable"} style={{textAlign: "center",margin: "0 auto"}}>
                                            <div className={"custom-tab-item"+(this.state.ticketTabViewMode === 'all' ? ' clicked' : '')+(this.state.darkTheme ? " dark" : "")} onClick={()=>this.setState({ticketTabViewMode:'all'})}>All</div>
                                            <div className={"custom-tab-item"+(this.state.ticketTabViewMode === 'department' ? ' clicked' : '')+(this.state.darkTheme ? " dark" : "")} onClick={()=>{this.setState({ticketTabViewMode:'department'});this.loadDepartmentTicket()}}>Department</div>
                                            <div className={"custom-tab-item"+(this.state.ticketTabViewMode === 'label' ? ' clicked' : '')+(this.state.darkTheme ? " dark" : "")} onClick={()=>{this.setState({ticketTabViewMode:'label'});this.loadLabelTicket()}}>Label</div>
                                          </div> */}
                                          <div className="divider"></div>
                                        </div>
                                        <Scrollbars>
                                          <div className='full-height'>
                                            <div className="recording-list-container">
                                              <iframe frameBorder="0" src={"https://www.superceed.com/recordings?token="+this.getCookie("auth")+"&feature=hyperceed"} width="100%" height="100%"/>
                                              {/* {(this.state.recordings.length > 0  ? 
                                              <RecordingList recordings={this.state.recordings}/> : null)} */}

                                            </div> 
                                          </div>
                                          <div>
                                              {(
                                                this.state.ticketLoadMore && this.state.ticketTabViewMode === 'all' ?
                                                <div className={"rounded-corner-10 hover-tint-2 linked"} style={{paddingTop:"5px",paddingBottom:"5px"}} onClick={this.loadMoreTicketList}>
                                                  <h6 className="mid-text bold-text">Show More...</h6>
                                              </div>
                                                : null
                                              )}                                            
                                            </div>                                    
                                        </Scrollbars>                                                            
                                  </div>        
                              </div>
                              {/* <FilterPanel recordingLoading={this.state.recordingLoading} loadTicket={this.loadTicket} setEmotionFilter={this.setEmotionFilter} setLabelFilter={this.setLabelFilter} setDepFilter={this.setDepFilter} setStartDateFilter={this.setStartDateFilter} setEndDateFilter={this.setEndDateFilter} dark={this.state.darkTheme} emotionFilter={this.state.emotionFilter} department={this.state.department} label={this.state.label}/> */}
                        </React.Fragment>
                       )}/>
                      <Route exact path={['/livechat/*','/chat/*','/chat',"/call","/call/*"]} render={props => ( 
                      <div className={"flex-col chat-list-background "+(this.state.onMeeting ? "hide" : "")} style={{min:'1px',flexBasis:('300px'),flexShrink:0,paddingRight:'40px',marginRight:'-40px'}}>
                          {/* <Header {...props} navigateTo={e=>this.navigateTo=e} dark={this.state.darkTheme} openSettings={this.openExpModal} showUpgrade={this.openUpgradeModal} showAbout={this.openModal} logout={this.logout} profilePhoto={this.state.user.profilePhoto} name={this.state.user.name} username={this.state.user.username} socket={this.socket}/> */}
                          {( this.state.mainTabViewMode === 'livechat' ? <ChatListTitle2 loadCall={this.loadCall} emotionVisible={this.state.emotionVisible} toggleEmotion={this.toggleEmotion} dark={this.state.darkTheme} showAddButton={true} title="Calls" showFilterButton="false" extraClass="bold-text-2 chat-list-title-text"/>
                         :
                          (this.state.mainTabViewMode === 'chat' ? <ChatListTitle2 dark={this.state.darkTheme} showAddButton={false} title="Calls" showFilterButton="false" extraClass="bold-text-2 chat-list-title-text"/>: null))}                          
                          {/* <div className="margin-top-2x"></div> */}
                          <div className="divider"></div>
                          <Scrollbars 
                            // renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
                            >
                         
                          <div className="flex-col full-height">
                          {/* <div className="left-panel-list-item"><Link className={'left-panel-item linked margin-top-1x'} to="/"><Home className="tiny left margin-right-2x"></Home><span className="left-panel-item-text">Home</span></Link></div>
                          <div className="left-panel-list-item"><h6 className={'left-panel-item linked '}><Exit className="tiny left margin-right-2x"></Exit><span className="left-panel-item-text" onClick={this.logout}>Log out</span></h6></div> */}
                            {(
                              this.state.mainTabViewMode === 'ticket' ?
                            <React.Fragment>
                              {(this.state.user.userId !== "-" ? <ChatListTitle2 toggleFilterModal={this.toggleFilterModal}  emotionVisible={this.state.emotionVisible} toggleEmotion={this.toggleEmotion}  userId={this.state.user.userId} showAddButton={true} title="Tickets" showFilterButton="true" dark={this.state.darkTheme} filter={this.state.filter} switchFilter={this.switchFilter} extraClass="chat-list-title-text bold-text-2"/> : null)}
                              <div className="left-panel-title-wrapper" style={{marginTop:0}}>
                                <div className={"custom-tab unselectable"} style={{textAlign: "center",margin: "0 auto"}}>
                                  <div className={"custom-tab-item"+(this.state.ticketTabViewMode === 'all' ? ' clicked' : '')+(this.state.darkTheme ? " dark" : "")} onClick={()=>this.setState({ticketTabViewMode:'all'})}>All</div>
                                  <div className={"custom-tab-item"+(this.state.ticketTabViewMode === 'department' ? ' clicked' : '')+(this.state.darkTheme ? " dark" : "")} onClick={()=>{this.setState({ticketTabViewMode:'department'});this.loadDepartmentTicket()}}>Department</div>
                                  <div className={"custom-tab-item"+(this.state.ticketTabViewMode === 'label' ? ' clicked' : '')+(this.state.darkTheme ? " dark" : "")} onClick={()=>{this.setState({ticketTabViewMode:'label'});this.loadLabelTicket()}}>Label</div>
                                </div>
                                <div className="divider"></div>
                              </div>
                              <ChatList {...props} setSelectedId={this.setSelectedId} emotion={this.state.emotionVisible} loadMoreDepartmentTicket={this.loadMoreDepartmentTicket} loadMoreLabelTicket={this.loadMoreLabelTicket} toggleExpand={this.toggleExpand} toggleExpand2={this.toggleExpand2} labelTicket={this.state.labelTicket} departmentTicket={this.state.departmentTicket} department={this.state.agentDepartment} viewMode={this.state.ticketTabViewMode} type="ticket" label={this.state.label} dark={this.state.darkTheme} channel={this.state.tickets} checkRead={this.checkRead} deleteChat={this.deleteChat} loadChat={this.loadChat} filter={this.state.filter}/>
                              {(
                                this.state.ticketLoadMore && this.state.ticketTabViewMode === 'all' ?
                                <div className={"rounded-corner-10 hover-tint-2 linked"} style={{paddingTop:"5px",paddingBottom:"5px"}} onClick={this.loadMoreTicketList}>
                                  <h6 className="mid-text bold-text">Show More...</h6>
                              </div>
                                : null
                              )}
                            </React.Fragment>
                            :
                            null
                            )}
                            {(
                              this.state.mainTabViewMode === 'livechat' ?
                              <React.Fragment>
                                

                                <ChatList {...props} setSelectedId={this.setSelectedId} emotion={this.state.emotionVisible} toggleCollapse={this.toggleCollapse}  type="call" dark={this.state.darkTheme} channel={this.state.chats} checkRead={this.checkRead} deleteChat={this.deleteChat} loadChat={this.loadChat} label={this.state.label}/>
                                {(
                                  this.state.liveChatLoadMore ?
                                  <div className={"rounded-corner-10 hover-tint-2 linked"} style={{paddingTop:"5px",paddingBottom:"5px"}} onClick={this.loadMoreLiveChatList}>
                                    <h6 className="mid-text bold-text">Show More...</h6>
                                  </div>
                                   : null
                                )} 
                                
                              </React.Fragment>
                              :
                              null
                            )}                             
                            {(
                              this.state.mainTabViewMode === 'chat' ?
                              <React.Fragment>
                                
                                <ChatList {...props} type="call" setSelectedId={this.setSelectedId} dark={this.state.darkTheme} channel={this.state.chats} checkRead={this.checkRead} deleteChat={this.deleteChat} loadChat={this.loadChat}/>
                                {(
                                  this.state.chatLoadMore ?
                                  <div className={"rounded-corner-10 hover-tint-2 linked"} style={{paddingTop:"5px",paddingBottom:"5px"}} onClick={this.loadMoreChatList}>
                                    <h6 className="mid-text bold-text">Show More...</h6>
                                  </div>
                                  : null
                                )} 
                                
                              </React.Fragment>
                              :
                              null
                            )}                                      
                          </div>
                          </Scrollbars>
                          {/* <EmotionTrigger socket={this.socket} trigger={e => this.trigger = e}/> */}
                          {/* <div style={{borderTop:"1px solid "+(this.state.darkTheme ? "#545454" : "#E0E0E0")}}>
                              <div className={"custom-tab unselectable"} style={{textAlign: "center",margin: "0 auto"}}> */}
                                {/* <div className={" custom-tab-item-bottom"+(this.state.mainTabViewMode === 'ticket' ? ' clicked' : '')+(this.state.darkTheme ? " dark" : "")} onClick={()=>{this.setState({mainTabViewMode:'ticket'});this.setTabCookie('ticket')}}><div className="flex-col" style={{alignItems:"center"}}><FontAwesomeIcon style={{fontSize:"20px"}} icon={faTicketAlt}/><span>Tickets</span></div>{(this.state.tickets.filter(ticket=>ticket.read === false).length > 0 ? <span className="icon-badge" style={{top:"20px",right:"15px"}}></span> : null)}</div> */}
                                {/* <div className={" custom-tab-item-bottom"+(this.state.mainTabViewMode === 'livechat' ? ' clicked' : '')+(this.state.darkTheme ? " dark" : "")} onClick={()=>{this.setState({mainTabViewMode:'livechat'});this.setTabCookie('livechat')}}><div className="flex-col" style={{alignItems:"center"}}><div className={"" + (this.state.livechat.filter(ticket=>ticket.read === false).length > 0 || this.state.liveChatQueue.length ? "mask-bottom-right-4" : "")}><FontAwesomeIcon style={{fontSize:"20px"}} icon={faHeadset}/></div><span>Live Chats</span></div>{(this.state.livechat.filter(ticket=>ticket.read === false).length > 0 || this.state.liveChatQueue.length > 0 ? <span className="icon-badge" style={{top:"31px",right:"59px"}}></span> : null)}</div>
                                <div className={"custom-tab-item-bottom"+(this.state.mainTabViewMode === 'chat' ? ' clicked' : '')+(this.state.darkTheme ? " dark" : "")} onClick={()=>{this.setState({mainTabViewMode:'chat'});this.setTabCookie('chat')}}><div className="flex-col" style={{alignItems:"center"}}><div className={""+(this.state.chats.filter(ticket=>ticket.read === false).length > 0 ? "mask-bottom-right-4" : "")}><FontAwesomeIcon style={{fontSize:"20px"}} icon={faComment}/></div><span>Chats</span></div>{(this.state.chats.filter(ticket=>ticket.read === false).length > 0 ? <span className="icon-badge" style={{top:"31px",right:"63px"}}></span> : null)}</div> */}
                              {/* </div>
                          </div> */}
                      </div>
                      )}/>
                      <Route exact path={['/']} render={props => (   
                        ( this.state.dashboardShown ? <Dashboard userType={this.state.user.type} theme={this.state.darkTheme} plan={this.state.plan} name={this.state.user.name}/> : null)
                      )}/>  
                      <Route exact path={['/upload']} render={props => (   
                        <VoiceAnalysis dark={this.state.darkTheme}/>
                      )}/>  
                      <Route exact path={['/report/:type/:status']} render={props => (   
                        <Report {...props} dark={this.state.darkTheme} label={this.state.label} department={this.state.department} userInfo={this.state.userInfo}/>
                      )}/>   
                      <Route exact path={['/report/omnichannel']} render={props => (   
                        <Omnichannel {...props} dark={this.state.darkTheme} label={this.state.label} department={this.state.department} userInfo={this.state.userInfo}/>
                      )}/>            

                      <Route exact path={['/call_config']} render={props => (   
                        <Configuration {...props} dark={this.state.darkTheme} label={this.state.label} department={this.state.department} userInfo={this.state.userInfo}/>
                      )}/>     
                      <Route exact path={['/supervisory']} render={props => (   
                        <>
                          {this.socket ? <Supervisory socket={this.socket}/> : null}
                          {/* <iframe frameBorder="0" src={"https://www.superceed.com/supervisory2?token="+this.getCookie("auth")+"&feature=hyperceed"} width="100%" height="100%"/> */}
                        </>
                      )}/>     
                      <Route exact path={['/voice_generation']} render={props => (   
                        <>
                          {this.socket ? <Voicebot  openSettings={this.openExpModal} socket={this.socket}/> : null}
                          {/* <iframe frameBorder="0" src={"https://www.superceed.com/supervisory2?token="+this.getCookie("auth")+"&feature=hyperceed"} width="100%" height="100%"/> */}
                        </>
                      )}/>                              
                      <Route path={['/livechat/:id','/chat/:id','/ticket/:id','/call/:id','/call']} render={props => (
                        <React.Fragment>
                          {/* {this.state.chatLoading ? <div className="full-width" style={{display:'flex',alignItems:'center'}}><h6 className="mid-text full-width grey-text">Loading...</h6></div> : null} */}
                          {this.state.ticketNotFoundError && !this.state.chatLoading ? <div className="full-width" style={{display:'flex',alignItems:'center'}}><h6 className="mid-text full-width grey-text">Ops! The chat has crashed unexpectedly.</h6></div> : null}
                          
                          {this.socket !== undefined ? 
                          
                            <ChatArea {...props}
                              showKB={()=>{this.setState({showKB:true})}}
                              goToCall={()=>{this.setState({goToCall:true})}}
                              pace={this.state.pace}
                              energy={this.state.energy}
                              pace_customer={this.state.pace_customer}
                              energy_customer={this.state.energy_customer}
                              idMap={this.state.idMap}
                              kbDataHighlight={this.kbDataHighlight}
                              callNumber={this.callNumber}
                              hangUp={this.hangUp}
                              mute={this.mute}
                              selectedChat={this.selectedId}
                              setSelectedId={this.setSelectedId}
                              updateOption={e => this.updateOption = e}
                              customStatus={this.state.customStatus}
                              getInfo={this.getInfo}
                              loadFromHome={this.state.loadFromHome}
                              viewTranslated={this.state.viewTranslated}
                              translate={this.translate}
                              chatType={this.state.channelData.channel === "chat" ? "chat" : (this.state.channelData.channel === "LIVECHAT" ? "livechat" : "ticket")}
                              // id={this.state.tickets.filter(ticket => ticket.ticket_number === )}
                              participant={this.state.participant}
                              name={this.state.user.name}
                              id={this.state.channelData.id}   
                              setTicketData={this.setTicketData}                           
                              emojiSet={this.state.emojiSet}
                              username={this.state.user.username}
                              adminPhoto={this.state.adminPhoto}
                              profilePhoto={this.state.user.profilePhoto}
                              setTicketStatus={this.setTicketStatus}
                              chatDataLoading={this.state.chatDataLoading}
                              channelData={this.state.channelData}                            
                              channel={this.state.channelData.channel === "chat" ? this.state.chats : (this.state.channelData.channel === "LIVECHAT" ? this.state.livechat : this.state.tickets)} setToFalse={this.setToFalse}
                              selectedChatTitle={this.state.channelData.title}
                              loadMoreChanged={this.state.loadMoreChanged}
                              loadMoreChat={this.loadMoreChat}
                              loadMore={this.state.loadMore}
                              conversation={this.state.conversation}
                              sendChat={this.sendChat}
                              emailAttachment={this.emailAttachment}
                              upload={this.uploadFiles}
                              panel={this.panel}
                              show={this.state.chatInfoShown}
                              newMessage={this.state.newMessage}
                              deleteMessage={this.deleteMessage}
                              change={this.stateChange}
                              emoji={this.state.emojiPickerShow}
                              dark={this.state.darkTheme}
                              imgViewer={this.openViewer}
                              flagScroll={e=>this.flagScroll=e}
                              scroll={e=>this.scrollChat=e}
                              getHeight={e=>this.getHeight=e}
                              scrollToPos={e=>this.scrollToPos=e}
                              rich={this.state.richLink}
                              uploadState={this.state.uploadState}
                              uploadPercent={this.state.uploadPercent}                              
                              sendTyping={this.sendTyping}
                              removeTyping={this.removeTyping}  
                              userType={this.state.user.type}     
                              sendReadReceipt={this.sendReadReceipt}   
                              onMeeting={this.state.onMeeting} 
                              showChatonMeeting={this.state.showChatonMeeting} 
                              startMeeting={this.startMeeting}   
                              status={this.state.channelData.closed}   
                              meeting={this.state.meeting} 
                              loadChat={this.loadChat}   
                              label={this.state.label}    
                              userInfo={this.state.userInfo} 
                              chatInfoShown={this.state.chatInfoShown}     
                              displayName={this.state.displayName}        
                              alwaysDisplay={this.state.alwaysDisplay}
                              mainDataLoading={this.state.mainDataLoading}   
                              reconnect={this.reconnect}                           
                              asteriskError={this.state.asteriskError}
                              />                      

                           : <div className="full-width valign-wrapper">
                              <div className="full-width">
                              <Circular/>
                              </div>                             
                             </div>}                        
                        </React.Fragment>
                      )}/>
                              <React.Fragment>
                              
                                  <Route exact path={['/:type/*','/:type']} render={props => (     
                                    <React.Fragment>          
                                      {((props.match.params.type === 'chat' || props.match.params.type === 'livechat' || props.match.params.type === 'call') ?
                                      <ChatInfo 
                                      {...props} 
                                      pace={this.state.pace}
                                      energy={this.state.energy}
                                      kbData={this.state.kbData}
                                      department={this.state.department}
                                      openProfileModal={this.openProfileModal}
                                      username={this.state.user.username}
                                      setParticipant={this.setParticipant}
                                      setInfo={this.setInfo}
                                      participant={this.state.participant}
                                      userInfo={this.state.userInfo}
                                      loadChat={this.loadChat}
                                      client={this.state.user.userId} socket={this.socket}
                                      updateTicketTime={this.updateTicketTime}
                                      userType={this.state.user.type}
                                      refresh={e => this.refreshInfo = e}
                                      onMeeting={this.state.onMeeting}
                                      chatType={props.match.params.type}
                                      deleteFile={e=>this.deleteFile=e}
                                      receiveFile={e=>this.sendFileToManager=e}
                                      receiveImage={e=>this.sendImageToManager=e}
                                      show={this.state.chatInfoShown}
                                      panel={this.panel}
                                      dark={this.state.darkTheme}
                                      updateInfo={e => this.updateInfo = e}
                                      channelData={this.state.channelData}/> : null)}
                                      {(this.state.onMeeting && props.match.params.type === "chat" ?
                                      <VideoArea {...props} id={this.state.channelData.id} toggleChat={this.toggleChat} leaveMeeting={this.leaveMeeting} stopMeeting={this.stopMeeting}/> : null
                                      )}
                                      {(props.match.params.type === "ticket" || props.match.params.type === "call") && this.state.showKB  ? <DraggableWindow2 {...props} closePopup={()=>{this.setState({showKB:false})}} dark={this.state.darkTheme} extensions={this.state.extensions} userInfo={this.state.userInfo}/>                    : null}
                                    </React.Fragment>
                                  )}/>                      
                              </React.Fragment>
         

                            <Modal
                              isOpen={this.state.modalIsOpen}
                              onAfterOpen={this.afterOpenModal}
                              onRequestClose={this.closeModal}
                              style={modalStyles}
                              contentLabel="Example Modal"
                              ariaHideApp={false} 
                              closeTimeoutMS={200}       
                                                              
                              className={""+(this.state.darkTheme ? "dark" : null)}   
                              
                            >
                              <div style={{width:'100%'}}>
                                <div className="ng-modal-main-header">
                                  <h5 className="bold-text smaller-font">About</h5>
                                </div>
                                <div className="ng-modal-main-body padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x">
                                  <h5 className="bold-text-2">Hyperceed</h5>
                                  <h6 className="modal-bottom-margin">Superceed all rights reserved</h6>
                                </div>
                                <div className="ng-modal-main-footer right-text">                      
                                  <button className="btn-flat ng-close-btn waves-effect waves-dark" onClick={this.closeModal}>close</button>
                                  {/* <button className="btn waves-effect waves-light margin-left-1x" onClick={this.closeModal}>OK</button> */}
                                </div>
                              </div>
                            </Modal>   

                            <Modal
                      isOpen={this.state.expModalIsOpen}
                      onAfterOpen={this.afterOpenModal}
                      onRequestClose={this.closeExpModal}
                      style={settingsModalStyles}
                      contentLabel="Example Modal"
                      ariaHideApp={false} 
                      closeTimeoutMS={200}       
                                                       
                      className={""+(this.state.darkTheme ? "dark" : null)}   
 
                      
                    >
                              <SettingsPage
                                label={this.state.label}
                                settingsSection={this.state.settingsSection}
                                department={this.state.department}
                                setAlwaysDisplay={this.setAlwaysDisplay}
                                setDisplayName={this.setDisplayName}
                                userType={this.state.user.type}
                                updateLabel={this.updateLabel}
                                updateStatus={this.updateStatus}
                                dark={this.state.darkTheme}
                                toggleDark={this.toggleDark}
                                closeModal={this.closeExpModal}
                                updateSettings={this.updateSettings}
                                settings={this.state.settings}
                                customStatus={this.state.customStatus}
                                sip={this.state.sip}
                                />
                      </Modal>
                            <Modal
                              isOpen={this.state.profileModalOpen}
                              onAfterOpen={this.afterOpenModal}
                              onRequestClose={this.closeProfileModal}
                              style={profileModalStyles}
                              contentLabel="Example Modal"
                              ariaHideApp={false} 
                              closeTimeoutMS={200}       
                                                              
                              className={""+(this.state.darkTheme ? "dark" : null)}   
        
                              
                            >
                              <ProfilePage dark={this.state.darkTheme} label={this.state.label} closeModal={this.closeProfileModal} username={this.state.userProfile} userInfo={this.state.userInfo}/>
                            </Modal>   

                            <Modal
                              isOpen={this.state.upgradeModalIsOpen}
                              onAfterOpen={this.afterOpenModal}
                              onRequestClose={this.closeUpgradeModal}
                              style={modalStyles2}
                              contentLabel="Example Modal"
                              ariaHideApp={false} 
                              closeTimeoutMS={200}       
                                                              
                              className={""+(this.state.darkTheme ? "dark" : null)}   
        
                              
                            >
                              <div style={{width:'100%'}}>
                                <div className="ng-modal-main-header">
                                  <h5 className="bold-text-2 smaller-font">Upgrade Plan</h5>
                                </div>
                                <div className="ng-modal-main-body padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x">
                                  <PricingTable showButton={true} onPayment={this.state.onPayment} upgradePlan={this.upgradePlan} dark={this.state.darkTheme} plan={this.state.plan}/>
                                </div>
                                <div className="ng-modal-main-footer right-text">                      
                                  <button className="btn-flat ng-close-btn waves-effect waves-dark" onClick={this.closeUpgradeModal}>close</button>
                                  {/* <button className="btn waves-effect waves-light margin-left-1x" onClick={this.closeModal}>OK</button> */}
                                </div>
                              </div>
                            </Modal>                       

                            <Modal
                              isOpen={this.state.removeModalOpen}
                              onAfterOpen={this.afterOpenModal}
                              onRequestClose={()=>{}}
                              style={modalStyles}
                              contentLabel="Example Modal"
                              ariaHideApp={false} 
                              closeTimeoutMS={200}       
                                                              
                              className={""+(this.state.darkTheme ? "dark" : null)}   
        
                              
                            >
                              <div style={{width:'100%'}}>
                                <div className="ng-modal-main-header">
                                  <h5 className="bold-text-2 smaller-font">You've got removed!</h5>
                                </div>
                                <div className="ng-modal-main-body padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x">
                                  <h6>You are not longer a participant in this conversation.</h6>
                                </div>
                                <div className="ng-modal-main-footer right-text">                      
                                  <Link className="btn-flat ng-close-btn waves-effect waves-dark" onClick={this.closeRemoveModal} to={"/"}>close</Link>
                                  {/* <button className="btn waves-effect waves-light margin-left-1x" onClick={this.closeModal}>OK</button> */}
                                </div>
                              </div>
                            </Modal>                         

                            <ImgsViewer
                              imgs={this.state.imgSet}
                              currImg={0}
                              isOpen={this.state.imgViewOpen}
                              onClose={this.closeViewer}
                            />
                            </div>
                      </div>
                    </div>

                  )}/> : 
                  <div className="full-width full-height" style={{display:'flex',alignItems:'center',position:"fixed"}}>
                    <div className="flex-col block-center">
                      <h5 className="mid-text full-width grey-text bold-text-2 emphasize-text">Error</h5>
                      <h6 className="mid-text full-width grey-text">{this.state.networkErrorText}</h6>
                      <button className="button-new grey white-text margin-bottom-2x" onClick={()=>{window.location.reload()}}>Reload</button>
                      <button className="button-new red white-text" onClick={()=>{this.logout()}}>Log Out</button>
                    </div>
                    </div> )}
                <Route exact path={['/pricing','/contact','/login']} render={props => (    
                  <Redirect to='/'/>
                )}/>              
                
                <Route exact path="/about" component={About} />
                <Route path="*" component={NotFound} />       
              </Switch>
            </Router>
            
          );
      }
  }
}

const mapDispatchToProps = dispatch => ( {
  thresholdChanged: (value) => 
  {
      dispatch({type: 'threshold',value:value});
  }
} )
const mapStateToProps = ( state ) => ( {
  value: state
} )


export default connect(mapStateToProps,mapDispatchToProps)(withSnackbar(App));
