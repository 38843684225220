import React, { useEffect, useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
// import '../css/score.css';
// import '../css/custom-dropdown.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export default function CategoryChartPage() {
  const [data, setData] = useState([]);
  const [label, setLabel] = useState([]);
  const [timeFrame, setTimeFrame] = useState('All');

  useEffect(() => {
    // Fetch posts from the backend API
    axios.get('https://supercash.ai:8888/api/getStackedBar')
      .then(response => {
        // console.log(response.data)
        // Update the state with the fetched data
        setLabel(response.data.labels);
        setData(response.data.datasets);
      })
      .catch(error => {
        // Handle errors
        console.error(error);
      });
  }, []);

  const dataStackedBar = {
    labels:label,
    datasets:data,
  };

    // const data = {
    //     labels: ['Category 1', 'Category 2', 'Category 3', 'Category 4'],
    //     datasets: [
    //     {
    //         label: 'Dataset 1',
    //         backgroundColor: 'rgba(75, 192, 192, 0.6)',
    //         data: [30, 40, 20, 10],
    //     },
    //     {
    //         label: 'Dataset 2',
    //         backgroundColor: 'rgba(255, 99, 132, 0.6)',
    //         data: [15, 25, 35, 45],
    //     },
    //     {
    //         label: 'Dataset 3',
    //         backgroundColor: 'rgba(255, 206, 86, 0.6)',
    //         data: [10, 30, 25, 30],
    //     },
    //     ],
    // };
    
  const options = {
      indexAxis: 'y',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
        },
        title: {
          display: true,
          text: 'Category Scoring by Agents (' + timeFrame + ')',
        },
      },
      scales: {
          x: {
            stacked: true,
            title: {
              display: true,
              text: 'Cumulative Percentage',
            },
            ticks: {
              precision: 0, // Set the number of decimal places to 0
            },
          },
          y: {
            stacked: true,
            title: {
              display: true,
              text: 'Agents',
            },
          },
        },
    };

    const handleTimeframeChange = (event) => {
      const selectedTimeframe = event.target.value;
      setTimeFrame(selectedTimeframe);
  
      // Depending on the selected timeframe, update your chart data accordingly.
      switch (selectedTimeframe) {
        case 'All':
          axios.get('https://supercash.ai:8888/api/getStackedBar')
            .then((response) => {
              setLabel(response.data.labels);
              setData(response.data.datasets);
            })
            .catch((error) => {
              console.error(error);
            });
          break;
        case 'Daily':
          axios.get('https://supercash.ai:8888/api/getStackedBarDaily')
            .then((response) => {
              setLabel(response.data.labels);
              setData(response.data.datasets);
            })
            .catch((error) => {
              console.error(error);
            });
          break;
        case 'Last 7 Days':
          axios.get('https://supercash.ai:8888/api/getStackedBarLast7Days')
            .then((response) => {
              setLabel(response.data.labels);
              setData(response.data.datasets);
            })
            .catch((error) => {
              console.error(error);
            });
          break;
        case 'Weekly':
          axios.get('https://supercash.ai:8888/api/getStackedBarWeekly')
            .then((response) => {
              setLabel(response.data.labels);
              setData(response.data.datasets);
            })
            .catch((error) => {
              console.error(error);
            });
          break;
        case 'Monthly':
          axios.get('https://supercash.ai:8888/api/getStackedBarMonthly')
            .then((response) => {
              setLabel(response.data.labels);
              setData(response.data.datasets);
            })
            .catch((error) => {
              console.error(error);
            });
          break;
        default:
          break;
      }
    }

  return (
    // <div style={containerStyle}>
    // <Container className='mt-3'>
    //   <Row>
    //     <Col lg={12} className='mx-auto'>
    <>
      <select className="custom-dropdown" onChange={handleTimeframeChange}>
        <option value="All">All</option>
        <option value="Daily">Daily</option>
        <option value="Last 7 Days">Last 7 days</option>
        <option value="Weekly">Weekly</option>
        <option value="Monthly">Monthly</option>
      </select>
      <Bar data={dataStackedBar} options={options} />
    </>
    //     </Col>
    //   </Row>
    // </Container>
  );
}