import React, { Component } from 'react';
import { SketchPicker } from 'react-color';
import { Delete,Lens,CancelOutlined,Close,ExpandMore,ExpandLess,Add,SaveOutlined,OpenInNew,CheckBox,CheckBoxOutlineBlank } from '@material-ui/icons'
import axios from 'axios';

import Moment from 'react-moment';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import Color from 'color';
import Snackbar from '@material-ui/core/Snackbar';

import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';

import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';

import Select from 'react-select';

import chroma from 'chroma-js';

import Modal from 'react-modal';

import Variable from './../variable';

import Security from './Settings/Security';

import QA from './Settings/QA';

import Notification from './Settings/Notification';

import Voicebot from './Settings/Voicebot';

import Dropdown2  from 'react-bootstrap/Dropdown'

const lang = [
  {name:"English",code:"en-GB"},
  {name:"Chinese (Mandarin)",code:"zh"},
  {name:"Chinese (Cantonese)",code:"yue-Hant-HK"},
  {name:"Malay",code:"ms-MY"},
  {name:"Vietnamese",code:"vi-VN"},
  {name:"Finnish",code:"fi-FI"},
  {name:"French",code:"fr-FR"},
  {name:"German",code:"de-DE"},
  {name:"Indonesian",code:"id-ID"},
  {name:"Italian",code:"it-IT"},
  {name:"Japanese",code:"ja-JP"},
  {name:"Korean",code:"ko-KR"},
  {name:"Portugese",code:"pt-PR"},
  {name:"Spanish",code:"es-US"},
  {name:"Swedish",code:"sv-SE"},
  {name:"Turkish",code:"tr-TR"},
  {name:"Thai",code:"th-TH"},
  {name:"Swahili (Tanzania)",code:"sw-TZ"},
  {name:"Swahili (Kenya)",code:"sw-KE"},
  {name: "Bengali",code:"bn-IN"},
  {name: "Tamil (India)",code:"ta-IN"},
  {name: "Telugu (India)",code:"te-IN"},
];

const lang2 = [
  {name:"English (United States)",code:"en-US"},
  {name:"English (Australia)",code:"en-AU"},
  {name:"English (Singapore)",code:"en-SG"},
  {name:"English (Kenya)",code:"en-KE"},
  {name:"English (Tanzania)",code:"en-TZ"},
  {name:"Chinese (Taiwan)",code:"zh-TW"},
  {name:"Chinese (Hong Kong)",code:"zh-HK"},
  {name:"Chinese (China)",code:"zh-CN"},
  {name:"Swahili (Tanzania)",code:"sw-TZ"},
  {name:"Swahili (Kenya)",code:"sw-KE"},
  {name:"Thai",code:"th-TH"},
  {name:"Japanese",code:"ja-JP"},
  {name:"Korean",code:"ko-KR"},
  {name:"Malay",code:"ms-MY"},
  {name:"Vietnamese",code:"vi-VN"},
  {name:"Finnish",code:"fi-FI"},
  {name:"French",code:"fr-FR"},
  {name:"German",code:"de-DE"},
  {name:"Indonesian",code:"id-ID"},
  {name:"Finnish",code:"fi-FI"},
  {name:"French",code:"fr-FR"},
  {name:"German",code:"de-DE"},
  {name:"Indonesian",code:"id-ID"},
  {name:"Italian",code:"it-IT"},
  {name:"Spanish",code:"es-ES"},
  {name: "Bengali",code:"bn-IN"},
  {name: "Tamil (India)",code:"ta-IN"},
  {name: "Telugu (India)",code:"te-IN"},
]

const options = [
    { value: 0, label: 'Close Ticket' },
    { value: 1, label: 'Delete Immediately' },
  ];

const slaOptions = [
    { value: 0, label: 'Do Nothing' },
    // { value: 1, label: 'Close Ticket' },
    { value: 1, label: 'Escalate To Department' },
    { value: 2, label: 'Escalate according to Label' },
  ];  

  const Menu = props => {
    const shadow = 'hsla(218, 50%, 10%, 0.1)';
    return (
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 4,
          boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
          marginTop: 8,
          position: 'absolute',
          top:0,
          zIndex: 2,
        }}
        {...props}
      />
    );
  };

  const Blanket = props => (
    <div
      style={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: 'fixed',
        zIndex: 1,
      }}
      {...props}
    />
  );  
      

  const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
  
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });  

const selectStyles = {
    menu: styles => ({...styles,margin:'0',borderRadius:'5px',overflow:'hidden',zIndex:999,width:"300px"}),
    menuList: styles => ({...styles,backgroundColor:'#fefefe'}),
    valueContainer: styles => ({...styles, height:'30px',color:'#757575'}),
    control: styles => ({ ...styles, backgroundColor: '#fefefe',width:'300px', color:'#757575'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //console.log(data);
      //const color = chroma(data.color);
      
      const color = chroma('#fefefe');
      return {
        ...styles,
  
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#dddddd"
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':hover':{
          ...styles[':hover'],
          backgroundColor: '#eeeeee',
        },
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(1).css()),
        },
      };
    },
    input: (styles, { data }) => ({ ...styles, ...dot(data), height: '30px'}),
  
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data), color: '#757575' }),
  };
  
  const selectStylesDark = {
    menu: styles => ({...styles,margin:'0',backgroundColor:'#333333',borderRadius:'5px',overflow:'hidden',zIndex:999,width:"300px"}),
    valueContainer: styles => ({...styles, height:'30px',color:'#fefefe'}),
    control: styles => ({ ...styles, backgroundColor: '#333333',width:'300px', color:'#fefefe'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //console.log(data);
      //const color = chroma(data.color);
      
      const color = chroma('#000000');
      return {
        ...styles,
  
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#212121"
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':hover':{
          ...styles[':hover'],
          backgroundColor: '#424242',
        },
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(1).css()),
        },
      };
    },
    input: (styles, { data }) => ({ ...styles, ...dot(data), height: '30px'}),
    dropdownIndicator: (styles, { data }) => ({ ...styles, ':hover':{color:'#fefefe'}}),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data), color: '#fefefe' }),
  };


  const Dropdown = ({ children, isOpen, target, onClose }) => (
    <div css={{ position: 'relative' }}>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
  );  

  const snackbarStyles = theme => ({
    close: {
      width: theme.spacing.unit * 4,
      height: theme.spacing.unit * 4,
    },
  });  

class componentName extends Component {

    host = Variable.host;

    state = {
        selectedSection : 0,
        labelList: [

        ],
        statusList:[],
        deleteList: [

        ],
        deleteList2:[],
        displayName: false,
        selectedOption:"Close Ticket",
        selectedValue: 0,
        activity:[],
        date:(moment()),     
        focused: false,
        defaultSelectedOption:"Do Nothing",
        defaultSelectedValue: 0,
        defaultChecked:false,
        defaultMinute:"1",
        selectedDep:"Please Select",
        selectedDepValue:0,
        defaultLabel:[],
        menuOpen: false,
        labelOption:[],
        departmentSelect:[],
        snackbarOpen:false,
        message:"Updated",
        settings:{},
        identity:"",
        sipPassword:"",
        sip:[],
        kbUser:"",
        kbPassword:"",
        selectedLang:-1,
        selectedTargetLang:-1,
        selectedLang2: [],
        selectedModal:0
    }
  
    openSnackbar = (msg) => {
      this.setState({ snackbarOpen: true, message: msg });
    };
  
    closeSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      this.setState({ snackbarOpen: false });
    };    

    toggleLang = (code) => {
      let lang = this.state.selectedLang2;
      let index = lang.indexOf(code);
      if(index === -1){
        lang.push(code);
      }
      else{
        lang.splice(index,1);
      }
      this.setState({selectedLang2:lang});
    }

    switchSection = (section) => {
      this.setState({selectedSection:section},()=>{
        
      });
    }

    componentDidMount(){

      let labelOption = [];

      this.props.label.forEach(l => {
        let obj = { value: l.id, label: l.name}
        labelOption.push(obj);
      });


      if(this.props.settingsSection !== 0){
        this.switchSection(this.props.settingsSection);
      }
      

      this.setState({sip:this.props.sip,labelOption:labelOption,settings:this.props.settings || {chat_color:"#000000",link_color:"#2196F3"}});

      axios.get(this.host+'/api/eagi',{params:{_token:this.getCookie("auth"),sip:this.props.sip[0]?.sip_number}},{withCredentials: true})
      .then(res=>{
        this.setState({eagi:res.data.eagi})
        if(res.data.eagi.campaigns[0]){
          if(res.data.eagi.campaigns[0].inbound.google.in_use !== undefined){
            if(res.data.eagi.campaigns[0].inbound.google.in_use){
              this.setState({selectedModal:0})
            }
            for(let i = 0; i < lang.length; i++){
              if(lang[i].code === res.data.eagi.campaigns[0].inbound.google.language_code ){
                this.setState({selectedLang:i})
              }
              if(res.data.eagi.campaigns[0].translation.target !== ""){
                if(lang[i].code.indexOf(res.data.eagi.campaigns[0].translation.target) !== -1 ){
                  this.setState({selectedTargetLang:i})
                }
              }
            }
            
          }
          if(res.data.eagi.campaigns[0].inbound.azure.in_use !== undefined){
            if(res.data.eagi.campaigns[0].inbound.azure.in_use){
              this.setState({selectedModal:1})
            }
            this.setState({selectedLang2:res.data.eagi.campaigns[0].inbound.azure.language_code})
          }
        }
      })      

        var optionArray = [];
        var departmentLabel = [];
        var departmentSelect = [];
        for(var i = 0; i < this.props.department.length;i++){
          
          var obj = {value: this.props.department[i].id, label: this.props.department[i].name,defaultMinute:this.props.department[i].respond, selectedOption:slaOptions[this.props.department[i].action].label,selectedValue:this.props.department[i].action,email:this.props.department[i].email,selectedDep:(this.props.department.filter((department) => (department.id === this.props.department[i].department)).length > 0 ? this.props.department.filter((department) => (department.id === this.props.department[i].department))[0].name : "Please Select"),selectedDepValue:this.props.department[i].department,label_override:this.props.department[i].label_override ? 1 : 0};
          departmentLabel.push(this.props.department[i].label_dep);
          optionArray.push(obj);
          departmentSelect[i] = false;
          
        }

        this.setState({departmentOptions:optionArray,departmentLabel:departmentLabel,departmentSelect:departmentSelect});

        if(this.getCookie("display_name") !== undefined){
            var display  =(this.getCookie("display_name") === 'true');
            this.setState({displayName: display });            
        }
        if(this.getCookie("always_display") !== undefined){
            var display  =(this.getCookie("always_display") === 'true');
            this.setState({alwaysDisplay: display });            
        }

        let labels = this.props.label;

        labels.forEach((label)=>{
          label.expanded = false;
        })

        labels.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return parseInt(b.level) -  parseInt(a.level);
         });   

        this.setState({labelList:labels});

        let statusList = this.props.customStatus;
        statusList.forEach((status)=>{
          status.name = status.status_name;
          status.expanded = false;
          status.color = "#000000";
          status.colorDark = "#000000";
          status.level = "0";
          status.sla = "30";          
        })
        this.setState({statusList:statusList});

         
    }

    loadActivity = () => {
      axios.get(this.host+'/ticket/activity/list', {params:{_token:this.getCookie("auth"),startDate:this.state.date.format('YYYY-MM-DD')}},{withCredentials: true})
      .then(res => {
        this.setState({activity:res.data});
      }   
      )
    }

    toggleOpen = () => {
      this.setState(state => ({ isOpen: !state.isOpen }));
    };    

    toggleOpen2 = (i) => {
      let departmentSelect = this.state.departmentSelect;
      departmentSelect[i] = !departmentSelect[i];
      this.setState(state => ({ departmentSelect: departmentSelect }));
    };        

    labelSelected = value => {
      this.toggleOpen();
      let defaultLabel = this.state.defaultLabel;
      let obj = {label:value.value,department:0};
      defaultLabel.push(obj);
      this.setState({defaultLabel:defaultLabel});
      //this.setState({ value });
      //this.props.switchFilter(value.value);
  }; 

  labelSelected2 = (i,value) => {
  
    this.toggleOpen2(i);
    let departmentLabel = this.state.departmentLabel;
    let obj = {label:value.value,department:0};
    departmentLabel[i].push(obj);
    this.setState({departmentLabel:departmentLabel});
    //this.setState({ value });
    //this.props.switchFilter(value.value);
};   

removeDefaultLabel = (i) => {
  let defaultLabel = this.state.defaultLabel;
  defaultLabel.splice(i,1);
  this.setState({defaultLabel:defaultLabel});
}

removeDepartmentLabel = (i,k) => {
  let departmentLabel = this.state.departmentLabel;
  departmentLabel[i].splice(k,1);
  this.setState({departmentLabel:departmentLabel});
}

    showPicker = (i,e) => {
        let labels = this.state.labelList;
        if(labels[i].picker){            
            labels[i].picker = !labels[i].picker;  
        }
        else{
            labels[i].picker = true
        }
        labels[i].locationX = e.clientX - 60;
        labels[i].locationY = e.clientY;        
        
        this.setState({labelList:labels});
    }

    showPicker2 = (i,e) => {
        let labels = this.state.labelList;
        if(labels[i].pickerDark){
            labels[i].pickerDark = !labels[i].pickerDark;  
        }
        else{
            labels[i].pickerDark = true
        } 

        labels[i].locationX = e.clientX - 60;
        labels[i].locationY = e.clientY;          
      
        this.setState({labelList:labels});
    }    

    handleChange = (e,i,key) =>{ 
        let labels = this.state.labelList;
        labels[i][key] = e.target.value;

        labels.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return parseInt(b.level) -  parseInt(a.level);
          });    

        this.setState({labelList:labels});
    } 

    handleChange2 = (e,i,key) =>{ 
      let status = this.state.statusList;
      status[i][key] = e.target.value;

      status.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return parseInt(b.level) -  parseInt(a.level);
        });    

      this.setState({statusList:status});
  } 
    
    handleChangeComplete = (color,i) => {
        let labels = this.state.labelList;
        labels[i]["color"] = color;
        this.setState({labelList:labels});
      };   
      
      handleChangeCompleteDark = (color,i) => {
        let labels = this.state.labelList;
        labels[i]["colorDark"] = color;
        this.setState({labelList:labels});
      };       

    addLabel = () => {
        let labels = this.state.labelList;
        labels.push(            {
            "id":0,
            "name":"New Label",
            "color": "#000000",
            "colorDark": "#000000",
            "level":"0",
            "sla":"30",
            "isNew": true,
            "expanded": false
        });
        this.setState({labelList:labels},()=>{
          this.scrollRef.scrollToBottom();
        });
    }

    addStatus = () => {
      let status = this.state.statusList;
      status.push(            {
          "id":0,
          "name":"New Status",
          "color": "#000000",
          "colorDark": "#000000",
          "level":"0",
          "sla":"30",
          "isNew": true,
          "expanded": false
      });
      this.setState({statusList:status},()=>{
        this.scrollRef.scrollToBottom();
      });
  }    

    deleteLabel = (i) => {
        let labels = this.state.labelList;
        if(labels.isNew === true){
            labels.splice(i,1);
            this.setState({labelList:labels});
        }
        else{
            let deleteList = this.state.deleteList;
            deleteList.push({id : this.state.labelList[i].id});
            labels.splice(i,1);
            this.setState({labelList:labels,deleteList:deleteList});
        }
    }

    deleteStatus = (i) => {
      let status = this.state.statusList;
      if(status.isNew === true){
          status.splice(i,1);
          this.setState({statusList:status});
      }
      else{
          let deleteList2 = this.state.deleteList2;
          deleteList2.push({id : this.state.statusList[i].id});
          status.splice(i,1);
          this.setState({statusList:status,deleteList2:deleteList2});
      }
  }

    expandLabel = (i) => {
      let labels = this.state.labelList;
      labels[i].expanded = !labels[i].expanded;
      this.setState({labelList:labels});
  }    

  expandStatus = (i) => {
    let status = this.state.statusList;
    status[i].expanded = !status[i].expanded;
    this.setState({statusList:status});
}    

    toggleDisplayName = () => {
        if(this.getCookie("display_name") !== undefined){
          var display  =!this.state.displayName
          this.setState({displayName: display });
    
          var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
          var expires = '; expires=' + date.toGMTString();
          document.cookie = 'display_name= '+ display + expires + '; path=/';    
          this.props.setDisplayName(display);        
        }
        else{
          this.setState({displayName: true });
          var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
          var expires = '; expires=' + date.toGMTString();
          document.cookie = 'display_name= '+ true + expires + '; path=/';     
          this.props.setDisplayName(true);
        }
        
      }    

      toggleDefaultChecked = () => {
        this.setState({defaultChecked:!this.state.defaultChecked});
      }

      toggleAlwaysDisplay = () => {
        if(this.getCookie("always_display") !== undefined){
          var display  =!this.state.alwaysDisplay
          this.setState({alwaysDisplay: display });
    
          var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
          var expires = '; expires=' + date.toGMTString();
          document.cookie = 'always_display= '+ display + expires + '; path=/';    
          this.props.setAlwaysDisplay(display);        
        }
        else{
          this.setState({alwaysDisplay: true });
          var date = new Date();date.setTime(date.getTime() + (365 * 24 * 60 * 60 *1000));
          var expires = '; expires=' + date.toGMTString();
          document.cookie = 'always_display= '+ true + expires + '; path=/';     
          this.props.setAlwaysDisplay(true);
        }
        
      }         

      handleSelectChange = selectedOption => {
      
        this.setState({ selectedOption: selectedOption.label,selectedValue:selectedOption.value });
        console.log(`Option selected:`, selectedOption);
        //this.props.setTicketStatus(this.props.id,selectedOption.value);
      };  

      handleDefaultSlaChange = selectedOption => {
      
        this.setState({ defaultSelectedOption: selectedOption.label,defaultSelectedValue:selectedOption.value });
        console.log(`Option selected:`, selectedOption);
        //this.props.setTicketStatus(this.props.id,selectedOption.value);
      };        

      handleDefaultSlaChange2 = (selectedOption,index) => {
        let departmentOptions = this.state.departmentOptions;
        departmentOptions[index].selectedOption = selectedOption.label;
        departmentOptions[index].selectedValue = selectedOption.value;
        this.setState({ departmentOptions: departmentOptions });
        console.log(`Option selected:`, selectedOption);
        //this.props.setTicketStatus(this.props.id,selectedOption.value);
      };    
      
      saveWauchat = () => {
        axios.post(this.host+'/api/wauchat/register',{_token:this.getCookie("auth"),api:this.state.apiKey},{withCredentials: true})
        .then(res=>{
          console.log(res.data);
          this.openSnackbar("API key updated. Please reload the app to see changes.");         
        })
      }

      saveSIP = () => {
        axios.post(this.host+'/api/sip/register',{_token:this.getCookie("auth"),sip:this.state.identity,password:this.state.sipPassword},{withCredentials: true})
        .then(res=>{
          console.log(res.data);
          this.setState({sip:[{id:res.data.id,sip_number:this.state.identity}]});
          this.openSnackbar("SIP account registered. Please reload the app to see changes.");         
        })
        .catch(err=>{
          this.openSnackbar(err.response.data.description);  
        })
      }

      saveWauchatAccount = () => {
        axios.post(this.host+'/api/wauchat/account',{_token:this.getCookie("auth"),username:this.state.kbUser,password:this.state.kbPassword},{withCredentials: true})
        .then(res=>{
          console.log(res.data);
          this.setState({kbUser:"",kbPassword:""});
          this.openSnackbar("Wauchat account registered.");         
        })
        .catch(err=>{
          this.openSnackbar(err.response.data.description);  
        })
      }

      removeSIP = (id) => {
        axios.post(this.host+'/api/sip/remove',{_token:this.getCookie("auth"),id:id},{withCredentials: true})
        .then(res=>{
          console.log(res.data);
          this.setState({sip:[]});
          this.openSnackbar("SIP account removed. Please reload the app to see changes.");         
        })
        .catch(err=>{
          this.openSnackbar(err.response.data.description);  
        })
      }

      setChildrenModalOpened = () => {
        
      }

      changeLanguage = () => {

        if(this.state.selectedLang === -1){
          this.openSnackbar("Please select main language"); 
          return false;
        }

        let req = {_token:this.getCookie("auth"),lang:lang[this.state.selectedLang].code}
        if(this.state.selectedTargetLang !== -1){
          req.target_lang = lang[this.state.selectedTargetLang].code;
        }
        axios.post(this.host+'/api/eagi/update',req,{withCredentials: true})
        .then(res=>{
          console.log(res.data);
         
          this.openSnackbar("Language Changed");         
        })
        .catch(err=>{
          this.openSnackbar(err.response.data.description);  
        })
      }

      changeLanguage2 = () => {

  
        let req = {_token:this.getCookie("auth"),languages:this.state.selectedLang2,type:"azure"}
        if(this.state.selectedTargetLang !== -1){
          req.target_lang = lang[this.state.selectedTargetLang].code;
        }
        axios.post(this.host+'/api/eagi/update',req,{withCredentials: true})
        .then(res=>{
          console.log(res.data);
          this.openSnackbar("Language Changed");         
        })
        .catch(err=>{
          this.openSnackbar(err.response.data.description);  
        })
      }

      updateDefaltSla = () => {
        axios.post(this.host+'/api/sla/default/update',{_token:this.getCookie("auth"),respond:parseInt(this.state.defaultMinute),email:this.state.defaultChecked ? 1 : 0,department:this.state.selectedDepValue,action:this.state.defaultSelectedValue,label:this.state.defaultLabel},{withCredentials: true})
        .then(res=>{
          console.log(res.data);
          this.openSnackbar("Default SLA updated.");         
        })
      }      
      
      updateDepartmentSla = (i) => {
        axios.post(this.host+'/api/sla/update',{_token:this.getCookie("auth"),id:this.props.department[i].id,respond:parseInt(this.state.departmentOptions[i].defaultMinute),label:this.state.departmentOptions[i].label_override ? 1 : 0,email:this.state.departmentOptions[i].email ? 1 : 0,escalate:this.state.departmentOptions[i].selectedDepValue,action:this.state.departmentOptions[i].selectedValue,label_dep:JSON.stringify(this.state.departmentLabel[i])},{withCredentials: true})
        .then(res=>{
          console.log(res.data);
          this.openSnackbar("Department SLA updated.");         
        })
      }
      
      saveDuration = () => {
          if(this.state.day !== undefined && this.state.day !== ""){
            axios.post(this.host+'/api/settings/update', {_token:this.getCookie("auth"),action:this.state.selectedValue,day:this.state.day},{withCredentials: true})
            .then(res => {
                this.openSnackbar("Settings updated.");         
                // store.addNotification({
                //     title: "Success",
                //     message: "Settings updated",
                //     type: "success",
                //     insert: "top",
                //     container: "bottom-left",
                //     animationIn: ["animated", "fadeIn"],
                //     animationOut: ["animated", "fadeOut"],
                //     dismiss: {
                //     duration: 3000,
                //     onScreen: true
                //     }
                // });  
            }   
            ) 
        }      
      }

      colorTest = (color) => {
        var regExp = new RegExp(/^#[0-9A-F]{6}$/i);
        return regExp.test(color) ? color : "#000000";
      }

    updateLabel = () => {
        var regExp = new RegExp(/^#[0-9A-F]{6}$/i);
        let label = this.state.labelList;
        let error = false;
        let errorMsg = "";
        label.forEach(l => {
            if (l.name === "" || l.color ==="" || l.colorDark === "" || l.level === "" || l.sla === "") {
                errorMsg = "Please check all the fields are not empty.";
              error = true;
            }
            else if(isNaN(parseInt(l.sla)) || isNaN(parseInt(l.level))){
                errorMsg = "Priority level and SLA must be whole number.";
                error = true;
            }
            else if(!regExp.test(l.color.trim()) || !regExp.test(l.colorDark.trim())){
                errorMsg = "Color format is not correct";
                error = true;                
            }
          });
      
          if(error){
            // store.addNotification({
            //     title: "Error",
            //     message: errorMsg,
            //     type: "danger",
            //     insert: "top",
            //     container: "bottom-left",
            //     animationIn: ["animated", "fadeIn"],
            //     animationOut: ["animated", "fadeOut"],
            //     dismiss: {
            //       duration: 3000,
            //       onScreen: true
            //     }
            //   });         
              this.openSnackbar("Error");         
              return false;
          }
        axios.post(this.host+'/api/label/update', {_token:this.getCookie("auth"),labelArray:JSON.stringify(this.state.labelList),delete:JSON.stringify(this.state.deleteList)},{withCredentials: true})
        .then(res => {

            // store.addNotification({
            //     title: "Success",
            //     message: "Label updated.",
            //     type: "success",
            //     insert: "top",
            //     container: "bottom-left",
            //     animationIn: ["animated", "fadeIn"],
            //     animationOut: ["animated", "fadeOut"],
            //     dismiss: {
            //       duration: 3000,
            //       onScreen: true
            //     }
            //   });    
            this.openSnackbar("Label updated.");         
          //console.log(res);
          //console.log(res.data);
            //console.log(res);
            let labels = res.data;

            labels.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return parseInt(b.level) -  parseInt(a.level);
             });    
             
             
             labels.forEach(l => {
              if (l.name === "" || l.color ==="" || l.colorDark === "" || l.level === "" || l.sla === "") {
                  errorMsg = "Please check all the fields are not empty.";
                error = true;
              }
              else if(isNaN(parseInt(l.sla)) || isNaN(parseInt(l.level))){
                  errorMsg = "Priority level and SLA must be whole number.";
                  error = true;
              }
              else if(!regExp.test(l.color.trim()) || !regExp.test(l.colorDark.trim())){
                  errorMsg = "Color format is not correct";
                  error = true;                
              }
            });

            let labelOption = [];

            labels.forEach(l => {
              let obj = { value: l.id, label: l.name}
              labelOption.push(obj);
            });          

            this.setState({labelList:labels,deleteList:[],labelOption:labelOption});
            this.props.updateLabel(res.data);
            

  
        }
        )
        .catch(err => {
            // store.addNotification({
            //     title: "Error",
            //     message: "Please check the input.",
            //     type: "danger",
            //     insert: "top",
            //     container: "bottom-left",
            //     animationIn: ["animated", "fadeIn"],
            //     animationOut: ["animated", "fadeOut"],
            //     dismiss: {
            //       duration: 3000,
            //       onScreen: true
            //     }
            //   });   
            this.openSnackbar("Please check the input.");         
        })  ;      
    };


    updateStatus = () => {
      var regExp = new RegExp(/^#[0-9A-F]{6}$/i);
      let status = this.state.statusList;
      let error = false;
      let errorMsg = "";
      status.forEach(l => {
          if (l.name === "") {
              errorMsg = "Please check all the fields are not empty.";
            error = true;
          }
          // else if(isNaN(parseInt(l.sla)) || isNaN(parseInt(l.level))){
          //     errorMsg = "Priority level and SLA must be whole number.";
          //     error = true;
          // }
          // else if(!regExp.test(l.color.trim()) || !regExp.test(l.colorDark.trim())){
          //     errorMsg = "Color format is not correct";
          //     error = true;                
          // }
        });
    
        if(error){
          // store.addNotification({
          //     title: "Error",
          //     message: errorMsg,
          //     type: "danger",
          //     insert: "top",
          //     container: "bottom-left",
          //     animationIn: ["animated", "fadeIn"],
          //     animationOut: ["animated", "fadeOut"],
          //     dismiss: {
          //       duration: 3000,
          //       onScreen: true
          //     }
          //   });         
            this.openSnackbar("Error");         
            return false;
        }
      axios.post(this.host+'/api/status/update', {_token:this.getCookie("auth"),labelArray:JSON.stringify(this.state.statusList),delete:JSON.stringify(this.state.deleteList2)},{withCredentials: true})
      .then(res => {

          // store.addNotification({
          //     title: "Success",
          //     message: "Label updated.",
          //     type: "success",
          //     insert: "top",
          //     container: "bottom-left",
          //     animationIn: ["animated", "fadeIn"],
          //     animationOut: ["animated", "fadeOut"],
          //     dismiss: {
          //       duration: 3000,
          //       onScreen: true
          //     }
          //   });    
          this.openSnackbar("Status updated.");         
        //console.log(res);
        //console.log(res.data);
          //console.log(res);
          let status = res.data;

          status.sort(function(a,b){
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return parseInt(b.level) -  parseInt(a.level);
           });    
           
           
           status.forEach(l => {
            if (l.name === "") {
                errorMsg = "Please check the name is not empty.";
              error = true;
            }
            l.name = l.status_name;
            // else if(isNaN(parseInt(l.sla)) || isNaN(parseInt(l.level))){
            //     errorMsg = "Priority level and SLA must be whole number.";
            //     error = true;
            // }
            // else if(!regExp.test(l.color.trim()) || !regExp.test(l.colorDark.trim())){
            //     errorMsg = "Color format is not correct";
            //     error = true;                
            // }
          });

          let labelOption = [];

          status.forEach(l => {
            let obj = { value: l.id, label: l.name}
            labelOption.push(obj);
          });          

          this.setState({statusList:status,deleteList:[]});
          this.props.updateStatus(res.data);
          


      }
      )
      .catch(err => {
          // store.addNotification({
          //     title: "Error",
          //     message: "Please check the input.",
          //     type: "danger",
          //     insert: "top",
          //     container: "bottom-left",
          //     animationIn: ["animated", "fadeIn"],
          //     animationOut: ["animated", "fadeOut"],
          //     dismiss: {
          //       duration: 3000,
          //       onScreen: true
          //     }
          //   });   
          this.openSnackbar("Please check the input.");         
      })  ;      
  };    

    updateSettings = () => {
      axios.post(this.host+'/api/settings/update/color',{_token:this.getCookie("auth"),chat:this.state.settings.chat_color,link:this.state.settings.link_color},{withCredentials: true})
      .then(res=>{
        console.log(res.data);
        this.props.updateSettings(this.state.settings);
        this.openSnackbar("Settings updated.");         
      })      
    }

    updateSettings2 = () => {
      let settings = this.state.settings;
      settings.public_gpt = settings.public_gpt === 1 ? 0 : 1;
      axios.post(this.host+'/api/settings/gpt',{_token:this.getCookie("auth"),gpt : settings.public_gpt},{withCredentials: true})
      .then(res=>{
        this.setState({settings:settings},()=>{
          this.props.updateSettings(settings);
        });        
      })   
    
      
    }

    updateDay = (e) => {
        this.setState({day:e.target.value})
    }

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }    

    handleDefaultMinute = (e) => {
      this.setState({defaultMinute:e.target.value});
    }

    handleDefaultMinute2 = (e,i) => {
      let departmentOptions = this.state.departmentOptions;
      departmentOptions[i].defaultMinute = e.target.value;
      this.setState({departmentOptions: departmentOptions});
    }    

    handleDepartmentChange = selectedOption => {
      
      this.setState({ selectedDep: selectedOption.label,selectedDepValue:selectedOption.value });
      console.log(`Option selected:`, selectedOption);
      //this.props.setTicketStatus(this.props.id,selectedOption.value);
    };  

    handleLabelDepartmentChange = (i,selectedOption) => {
      let defaultLabel = this.state.defaultLabel;
      defaultLabel[i].department = selectedOption.value;
      this.setState({ defaultLabel: defaultLabel });
      console.log(`Option selected:`, selectedOption);
      //this.props.setTicketStatus(this.props.id,selectedOption.value);
    };      

    handleLabelDepartmentChange2 = (i,k,selectedOption) => {
      let departmentLabel = this.state.departmentLabel;
      departmentLabel[i][k].department = selectedOption.value;
      this.setState({ departmentLabel: departmentLabel });
      console.log(`Option selected:`, selectedOption);
      //this.props.setTicketStatus(this.props.id,selectedOption.value);
    };     

    handleDepartmentChange2 = (selectedOption,i) => {
      let departmentOptions = this.state.departmentOptions;
      departmentOptions[i].selectedDep = selectedOption.label;      
      departmentOptions[i].selectedDepValue = selectedOption.value;      
      this.setState({ departmentOptions: departmentOptions });
      console.log(`Option selected:`, selectedOption);
      //this.props.setTicketStatus(this.props.id,selectedOption.value);
    };   
    
    toggleOverride = (i) => {
      let departmentOptions = this.state.departmentOptions;
      departmentOptions[i].label_override = !this.state.departmentOptions[i].label_override;     
      this.setState({departmentOptions:departmentOptions});
    }

    toggleEmail = (i) => {
      let departmentOptions = this.state.departmentOptions;
      departmentOptions[i].email = !this.state.departmentOptions[i].email;     
      this.setState({departmentOptions:departmentOptions});      
    }

    labelInfo = (id) => {
      let info = this.props.label.filter(label => label.id === id);
      
      if(info.length > 0){
          return info[0];
      }
      else{
          return null;
      }
  }
  
  updateColor = (e) => {
    let settings = this.state.settings;
    settings.chat_color = e.target.value;
    this.setState({settings:settings});
  }

  updateLinkColor = (e) => {
    let settings = this.state.settings;
    settings.link_color = e.target.value;
    this.setState({settings:settings});
  }  
  

  openAgentLink = () => {
    let popup = window.open("https://www.superceed.com/agents?feature=hyperceed&token="+this.getCookie("auth"),'popUpWindow','height=500,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
  }

  openSocialLink = () => {
    let popup = window.open("https://www.superceed.com/settings?feature=hyperceed&token="+this.getCookie("auth"),'popUpWindow','height=500,width=400,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
  }
  
  render() {
    const { classes } = this.props;
    return (
        <div className="flex-col" style={{width:'100%',height:"100%"}}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          autoHideDuration={3000}
          open={this.state.snackbarOpen}
          onClose={this.closeSnackbar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.message}</span>}
          action={[
            // <Button key="undo" color="secondary" size="small" onClick={this.handleClose}>
            //   UNDO
            // </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.closeSnackbar}
            >
              <Close />
            </IconButton>,
          ]}
        />

                <div className="ng-modal-main-body flex-row full-height">


                <div className={"flex-col "} style={{paddingTop:'20px',paddingRight:'20px',paddingLeft:'20px',paddingBottom:'20px',width: '300px',maxWidth:'300px',min:'1px',flexBasis:'300px',flexShrink:0,backgroundColor:this.props.dark ? '#333' :'#fefefe',borderRight:(this.props.dark ? '1px solid #424242' : '1px solid #ededed')}}>
                    <div className={"settings-panel-item"+(this.state.selectedSection === 0 ? " selected" : "")} onClick={()=>{this.setState({selectedSection:0})}}>
                        <span>Main</span>
                    </div>
                    <div className={"settings-panel-item"+(this.state.selectedSection === 6 ? " selected" : "")} onClick={()=>{this.setState({selectedSection:6})}}>
                        <span>SIP Account</span>
                    </div> 
                    <div className={"settings-panel-item"+(this.state.selectedSection === 7 ? " selected" : "")} onClick={()=>{this.setState({selectedSection:7})}}>
                        <span>Call Recommendations</span>
                    </div> 
                    <div className={"settings-panel-item"+(this.state.selectedSection === 15 ? " selected" : "")} onClick={()=>{this.setState({selectedSection:15})}}>
                        <span>QA</span>
                    </div>
                    <div className={"settings-panel-item"+(this.state.selectedSection === 14 ? " selected" : "")} onClick={()=>{this.setState({selectedSection:14})}}>
                        <span>Security</span>
                    </div> 
                    <div className={"settings-panel-item"+(this.state.selectedSection === 16 ? " selected" : "")} onClick={()=>{this.setState({selectedSection:16})}}>
                        <span>Voice Generation</span>
                    </div> 
                     
                    {/*
                    {(this.props.userType === 'admin' ? <div className={"settings-panel-item"+(this.state.selectedSection === 2 ? " selected" : "")} onClick={()=>{this.setState({selectedSection:2})}}>
                        <span className="bold-text">Ticket</span>
                    </div> : null)}   
                    {(this.props.userType === 'admin' ? <div className={"settings-panel-item"+(this.state.selectedSection === 3 ? " selected" : "")} onClick={()=>{this.setState({selectedSection:3})}}>
                        <span className="bold-text">Log</span>
                    </div> : null)}     
                    {(this.props.userType === 'admin' ? <div className={"settings-panel-item"+(this.state.selectedSection === 4 ? " selected" : "")} onClick={()=>{this.setState({selectedSection:4})}}>
                        <span className="bold-text">SLA Rules</span>
                    </div> : null)}  
                    {(this.props.userType === 'admin' ? <div className={"settings-panel-item"+(this.state.selectedSection === 9 ? " selected" : "")} onClick={()=>{this.setState({selectedSection:9})}}>
                        <span className="bold-text">Custom Status</span>
                    </div> : null)}    
                    {(this.props.userType === 'admin' ? <div className={"settings-panel-item"+(this.state.selectedSection === 5 ? " selected" : "")} onClick={()=>{this.setState({selectedSection:5})}}>
                        <span className="bold-text">Live Chat</span>
                    </div> : null)}   */}
                    {/* {(this.props.userType === 'admin' ? <div className={"settings-panel-item"+(this.state.selectedSection === 6 ? " selected" : "")} onClick={()=>{this.setState({selectedSection:6})}}>
                        <span className="bold-text">Wauchat Account</span>
                    </div> : null)}    */}
                    {/*
                    {(this.props.userType === 'admin' ? <div className={"settings-panel-item"+(this.state.selectedSection === 7 ? " selected" : "")} onClick={()=>{this.openAgentLink()}}>
                        <span className="bold-text valign-wrapper">Agent / Department<OpenInNew className="grey-text settings-small-icon margin-left-1x"/></span>
                    </div> : null)}   
                    {(this.props.userType === 'admin' ? <div className={"settings-panel-item"+(this.state.selectedSection === 8 ? " selected" : "")} onClick={()=>{this.openSocialLink()}}>
                        <span className="bold-text valign-wrapper">Accounts <OpenInNew className="grey-text settings-small-icon margin-left-1x"/></span>
                    </div> : null)}                                                     */}
                </div>
                <div className="flex-col full-width">
                    <div className="valign-wrapper" style={{padding:'20px'}}>
                      <h5 className="bold-text-2 smaller-font full-width">
                        {
                          (this.state.selectedSection === 0 ? "Main" : (this.state.selectedSection === 1 ? "Customize Label" : (this.state.selectedSection === 2 ? "Ticket" : (this.state.selectedSection === 3 ? "Log" : (this.state.selectedSection === 4 ? "SLA Rules" : (this.state.selectedSection === 14 ? "Security" : 
                          ""))))))
                        }
                        {
                          
                        }
                      </h5>
                      {
                        (this.state.selectedSection === 1 ? 
                            <React.Fragment>
                              <button className="btn margin-right-1x" onClick={this.addLabel}>Add</button>
                              <button className="btn green margin-right-2x" onClick={this.updateLabel}>Save</button>
                            </React.Fragment>
                            :
                            null
                          )
                      }
                      {
                        (this.state.selectedSection === 9 ? 
                            <React.Fragment>
                              <button className="btn margin-right-1x" onClick={this.addStatus}>Add</button>
                              <button className="btn green margin-right-2x" onClick={this.updateStatus}>Save</button>
                            </React.Fragment>
                            :
                            null
                          )
                      }                      
                     {
                        (this.state.selectedSection === 5 ? 
                            <React.Fragment>
                              <button className="btn green margin-right-2x" onClick={this.updateSettings}>Save</button>
                            </React.Fragment>
                            :
                            null
                          )
                      }                      
                      <div className="icon-button-hover linked" onClick={this.props.closeModal}><Close/></div>
                    </div>
                    <div className="divider"></div>
                    <Scrollbars className='perfect-scrollbar' ref={e => this.scrollRef = e}>
                    <div className="full-width settings-section limit-width-950" style={{paddingLeft:"20px",paddingRight:"20px",marginTop:"20px",marginBottom:"20px"}}>
                        {(this.state.selectedSection === 0 ?
                        <div className="settings-section-1">
                            <h5 className="bold-text-2 smaller-font margin-bottom-2x">User Interface</h5>
                              <div className="div-table margin-left-2x">
                                  <div className="table-row">
                                      <div className="table-cell">
                                        <h6 className="bold-text margin-top-2x">Dark Mode</h6>
                                        <p>Turn on to change light surfaces into dark.</p>
                                      </div>
                                      <div className="ios-toggle">
                                          <input className="ios" type="checkbox" id="switch" checked={this.props.dark} onChange={this.props.toggleDark}/><label className="ios" htmlFor="switch">Toggle</label>
                                      </div> 
                                  </div>
                                  {/* <div className="clearfix"></div>  */}
                                  {/* <div className="table-row"> 
                                    <div className="table-cell">
                                      <h6 className="bold-text margin-top-2x">Display Name</h6>
                                      <p>Display name above each conversation.</p>
                                    </div>
                                    <div className="ios-toggle">
                                        <input disabled={this.state.alwaysDisplay} className="ios" type="checkbox" id="switch-2" checked={this.state.displayName} onChange={this.toggleDisplayName}/><label className="ios" htmlFor="switch-2">Toggle</label>
                                    </div> 
                                  </div> */}
                                  {/* <div className="clearfix"></div>  */}
                                  {/* <div className="table-row">
                                      <div className="table-cell">
                                        <h6 className="bold-text margin-top-2x">Always display name and photo</h6>
                                        <p>Keep displaying name and profile photo on each message bubble.</p>
                                      </div>
                                    <div className="ios-toggle">
                                        <input className="ios" type="checkbox" id="switch-3" checked={this.state.alwaysDisplay} onChange={this.toggleAlwaysDisplay}/><label className="ios" htmlFor="switch-3">Toggle</label>
                                    </div>                        
                                  </div>  */}
                                </div>
                            {/* <h6>Rich Link Preview</h6>  
                            <p>Display links with detailed information. Some of the links may not display content properly.</p>
                            <div className="ios-toggle right">
                                <input className="ios" type="checkbox" id="switch2" checked={this.state.richLink} onChange={this.toggleRich}/><label className="ios" htmlFor="switch2">Toggle</label>
                            </div>                                */}
                        </div>
                        :null)}
                        {(this.state.selectedSection === 1 ?
                        <div className="settings-section-1 relative-content">
                            {this.state.labelList.length > 0 ?
                            <div className="label-table">
                                {/* <div className="label-item">
                                    <div className="label-table-head">
                                        <span>No</span>
                                    </div>
                                    <div className="label-table-head">
                                        <span>Name</span>
                                    </div>  
                                    <div className="label-table-head">
                                        <span>Color (Light Theme)</span>
                                    </div>          
                                    <div className="label-table-head">
                                        <span>Color (Dark Theme)</span>
                                    </div>                                                         
                                    <div className="label-table-head">
                                        <span>Priority Level</span>
                                    </div>   
                                    <div className="label-table-head">
                                        <span>Resolve Within (Minute)</span>
                                    </div> 
                                    <div className="label-table-head">
                                        <span>Action</span>
                                    </div>                                 
                                </div>   */}
                                { this.state.labelList.map((obj,i)=>(
                                <div key={i} className={"full-width overflow-hidden margin-right-1x label-item card new-shadow "} style={{backgroundColor:(this.props.dark ? "#282828" : "#fefefe")}}>
                                  <div className="valign-wrapper" style={{padding:"5px 20px",backgroundColor:""+(this.props.dark ? obj.colorDark : obj.color)}}>
                                    <h5 className={"full-width bold-text-2 small-font "+(Color(this.props.dark ? this.colorTest(obj.colorDark) :this.colorTest(obj.color)).isDark() ? "white-text" : "grey-text text-darken-3")}>{obj.name}</h5>                                
                                    <div className={"linked icon-button-hover-3 "+(Color(this.props.dark ? this.colorTest(obj.colorDark) :this.colorTest(obj.color)).isDark() ? "dark-mode" : "")} onClick={()=>{this.expandLabel(i)}}>
                                      {(obj.expanded ? 
                                      <ExpandLess className={" "+(Color(this.props.dark ? this.colorTest(obj.colorDark) :this.colorTest(obj.color)).isDark() ? "white-text" : "grey-text text-darken-3")}/>
                                      :
                                      <ExpandMore className={" "+(Color(this.props.dark ? this.colorTest(obj.colorDark) :this.colorTest(obj.color)).isDark() ? "white-text" : "grey-text text-darken-3")}/>
                                      )}
                                    </div>
                                    <div className={"linked icon-button-hover-3 "+(Color(this.props.dark ? this.colorTest(obj.colorDark) :this.colorTest(obj.color)).isDark() ? "dark-mode" : "")} onClick={()=>{this.deleteLabel(i)}}>
                                      <Delete className={" "+(Color(this.props.dark ? this.colorTest(obj.colorDark) :this.colorTest(obj.color)).isDark() ? "white-text" : "grey-text text-darken-3")}/>
                                    </div>                                
                                    
                                  </div>
                                  <div className={"card-content flex"+(obj.expanded ? "":" hide")}>
                                        {/* <div className="label-item-cell" style={{verticalAlign:"middle"}}>
                                            {i + 1}
                                        </div> */}
                                        <div className="label-item-cell">
                                            <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                                <input className="browser-default" type="text" value={obj.name} onChange={(e)=>{this.handleChange(e,i,"name")}}/>
                                                <span>Name</span>
                                                <p className="red-text hide">This cannot be empty.</p>
                                            </label>
                                        </div>
                                        <div className="label-item-cell">
                                            <div className="flex-row" style={{alignItems:"center"}}>
                                                <Lens style={{color:obj.color}} className="linked" onClick={(e)=>this.showPicker(i,e)}/>
                                                <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                                    <input className="browser-default" type="text" value={obj.color} onChange={(e)=>{this.handleChange(e,i,"color")}}/>
                                                    <span>Hex Color</span>
                                                    <p className="red-text hide">This cannot be empty.</p>
                                                </label>
                                                {(obj.picker ? <div className="z-depth-1 color-picker" style={{position:"fixed",left:obj.locationX+"px",borderRadius:"5px",top:obj.locationY+"px",zIndex:9}}>
                                                    <SketchPicker color={ obj.color } onChangeComplete={(color)=> this.handleChangeComplete(color.hex,i)}/>
                                                </div> : null)}
                                            </div>
                                        </div>     
                                        <div className="label-item-cell">
                                            <div className="flex-row" style={{alignItems:"center"}}>
                                                <Lens style={{color:obj.colorDark}} className="linked" onClick={(e)=>this.showPicker2(i,e)}/>
                                                <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                                        <input className="browser-default" type="text" value={obj.colorDark} onChange={(e)=>{this.handleChange(e,i,"colorDark")}}/>
                                                        <span>Hex Color (Dark Mode)</span>
                                                        <p className="red-text hide">This cannot be empty.</p>
                                                    </label>
                                                    {(obj.pickerDark ? <div className="z-depth-1 color-picker" style={{position:"fixed",left:obj.locationX+"px",borderRadius:"5px",top:obj.locationY+"px",zIndex:9}}>
                                                    <SketchPicker color={ obj.colorDark } onChangeComplete={(color)=> this.handleChangeCompleteDark(color.hex,i)}/>
                                                    </div> : null)}
                                            </div>
                                        </div>                                                            
                                        <div className="label-item-cell">
                                            <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                                <input className="browser-default" type="text" value={obj.level} onChange={(e)=>{this.handleChange(e,i,"level")}}/>
                                                <span>Priority Level</span>
                                                <p className="red-text hide">This cannot be empty.</p>
                                            </label>
                                        </div>
                                        <div className="label-item-cell">
                                            <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                                    <input className="browser-default" type="text" value={obj.sla} onChange={(e)=>{this.handleChange(e,i,"sla")}}/>
                                                    <span>SLA (Minute)</span>
                                                    <p className="red-text hide">This cannot be empty.</p>
                                            </label>  
                                        </div>  
                                        <div className="label-item-cell">
                                            
                                        </div>
                                      </div>                                                                                              
                                </div>  
                                )) }     
                                                                                                    
                            </div> : null}                      
                            {/* <button className={"btn-flat margin-right-1x ng-close-btn"} onClick={this.addLabel}>Add</button>
                            <button className="btn green" onClick={this.updateLabel}>Save</button> */}
                        </div>
                        :
                        (this.state.selectedSection === 2 ?
                            <div className="settings-section-1">
                                <h5 className="smaller-font bold-text-2">Action after resolved ticket / live chat</h5>
                                <h6>After</h6>
                                <div className="valign-wrapper">
                                <label className="pure-material-textfield-outlined margin-bottom-1x margin-right-2x">
                                            <input className="browser-default" type="text" value={this.state.day} onChange={this.updateDay}/>
                                            <span>Days</span>                                      
                                            <p className="red-text hide">This cannot be empty.</p>
                                </label>                            
                                <Select
                                                classNamePrefix="react-select"
                                                styles={this.props.dark ? selectStylesDark : selectStyles}
                                                value={{label : this.state.selectedOption,value : this.state.selectedValue}}
                                                onChange={this.handleSelectChange}
                                                options={options}
                                                isSearchable={false}
                                            />   
                                </div>
                                <button className="btn waves-effect waves-light" onClick={this.saveDuration}>Save</button>
                            </div>
                                :
                                (this.state.selectedSection === 3 ?
                                  <div className="settings-section-1">
                                  <SingleDatePicker
                                      date={this.state.date} // momentPropTypes.momentObj or null,
                                      id="your_unique_start_date_id" // PropTypes.string.isRequired,

                                      onDateChange={( date ) => {console.log(date);this.setState({ date })}} // PropTypes.func.isRequired,
                                      focused={this.state.focused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                      onFocusChange={({focused}) => this.setState({ focused })} // PropTypes.func.isRequired,
                                      noBorder={true}
                                      displayFormat="DD-MM-YYYY"
                                      isOutsideRange={day => (moment().diff(day) < 0)}
                                      />           
                                      <button className="btn" onClick={this.loadActivity}>Show</button>                     
                                    {this.state.activity.map((obj,i)=>(
                                      <div key={obj.id}>
                                        <h6>{obj.updated_at}</h6>
                                        <h6>{obj.action}</h6>
                                        <h6>{obj.description}</h6>
                                      </div>
                                    ))
                                    }
                                    </div>
                                    :
                                    (this.state.selectedSection === 4 ?
                                      <React.Fragment>
                                        <h5 className="bold-text-2 smaller-font">Default Ticket Rules</h5>
                                        <div className="flex-row">
                                          <div>
                                            <h6>Resolve within</h6>
                                            <div className="valign-wrapper">
                                              <label className="pure-material-textfield-outlined margin-bottom-1x margin-right-2x">
                                                          <input className="browser-default" type="text" value={this.state.defaultMinute} onChange={(e)=>{this.handleDefaultMinute(e)}}/>
                                                          <span>Minutes</span>                                      
                                                          <p className="red-text hide">This cannot be empty.</p>
                                              </label>  
                                                                          
                                              </div>
                                            </div>
                                        <div>
                                          <div>
                                            <h6>If unresolved within given time, action will be taken below</h6>
                                          <Select
                                                  classNamePrefix="react-select"
                                                  styles={this.props.dark ? selectStylesDark : selectStyles}
                                                  value={{label : this.state.defaultSelectedOption,value : this.state.defaultSelectedValue}}
                                                  onChange={this.handleDefaultSlaChange}
                                                  options={slaOptions}
                                                  isSearchable={false}
                                              />   
                                              {(this.state.defaultSelectedValue === 1 ?
                                              <Select
                                                  classNamePrefix="react-select"
                                                  styles={this.props.dark ? selectStylesDark : selectStyles}
                                                  value={{label : this.state.selectedDep,value : this.state.selectedDepValue}}
                                                  onChange={this.handleDepartmentChange}
                                                  options={this.state.departmentOptions}
                                                  isSearchable={false}
                                              /> : (this.state.defaultSelectedValue === 2 ?
                                                <React.Fragment>
                                                <div className="relative-content">

                                              <Dropdown
                                                    isOpen={this.state.isOpen}
                                                    onClose={this.toggleOpen}
                                                    target={
                                                        <React.Fragment>
                                                          <Button className={(this.props.dark ? "white-text" : "")} onClick={this.toggleOpen}>Add a label</Button>
                                                        {/* <FilterList className="linked" onClick={this.toggleOpen}/> */}
                                                        </React.Fragment>
                                                    //   <Button
                                                    //     // iconAfter={<ChevronDown />}
                                                    //     onClick={this.toggleOpen}
                                                    //     isSelected={isOpen}
                                                    //   >
                                                    //     {/* {value ? `State: ${value.label}` : 'Select a State'} */}
                                                    //   </Button>
                                                    }
                                                >
                                                    <Select
                                                        autoFocus
                                                        menuIsOpen
                                                        classNamePrefix="react-select"
                                                        styles={(this.props.dark ? selectStylesDark : selectStyles)}
                                                        value={{label : "Select",value : 0}}
                                                        onChange={this.labelSelected}
                                                        options={this.state.labelOption}
                                                        isSearchable={false}
                                                        components={{ DropdownIndicator: null, IndicatorSeparator: null }}
                                                    />     
                                                </Dropdown> 

                                                  
                                                  {
                                                    this.state.defaultLabel.map((obj,i)=>(
                                                      <div key={i}>
                                                        <div className="valign-wrapper">
                                                        {(this.labelInfo(obj.label) !== null ? <span className="label" style={{color: Color(this.props.dark ? (this.labelInfo(obj.label)).colorDark :(this.labelInfo(obj.label)).color).isDark() ? "#fefefe" : "#333333",backgroundColor:(this.props.dark ? (this.labelInfo(obj.label)).colorDark :(this.labelInfo(obj.label)).color)}}>{(this.labelInfo(obj.label)).name}</span> : 
                                                        <span className="label">Removed</span>
                                                        )}
                                                          <CancelOutlined className="red-text linked" onClick={()=>this.removeDefaultLabel(i)}/>
                                                        </div>
                                                      <Select
                                                        classNamePrefix="react-select"
                                                        styles={this.props.dark ? selectStylesDark : selectStyles}
                                                        value={{label :  (this.props.department.filter(item => item.id === obj.department).length > 0 ? (this.props.department.filter(item => item.id === obj.department)[0].name) : "Select"),value : obj.department}}
                                                        onChange={(option)=>this.handleLabelDepartmentChange(i,option)}
                                                        options={this.state.departmentOptions}
                                                        isSearchable={false}
                                                    />

                                                      </div>
                                                    ))
                                                  }    
                                                  
                                                  </div>                                         
                                                </React.Fragment>
                                                : null))}                                                                              
                                          </div>
                                        </div>
                                        <div className="margin-left-2x">
                                        <h6>Email</h6>
                                            <div className="ios-toggle right">
                                              <input className="ios" type="checkbox" id="switch" checked={this.state.defaultChecked} onChange={this.toggleDefaultChecked}/><label className="ios" htmlFor="switch">Toggle</label>
                                          </div>                                         
                                        </div>
                                        
                                      </div>
                                      <button className="btn margin-bottom-2x" onClick={this.updateDefaltSla}>Save</button>  
                                      
                                      <h5 className="bold-text-2 smaller-font">Department Rules</h5>
                                        {this.props.department.map((obj,i) => ( 
                                          <React.Fragment key={i}>
                                            <h6 className="bold-text">{obj.name}</h6>

                                            <div className="flex-row">
                                              <div>
                                                <h6>Resolve within</h6>
                                                <div className="valign-wrapper">
                                                  <label className="pure-material-textfield-outlined margin-bottom-1x margin-right-2x">
                                                              <input className="browser-default" type="text" value={this.state.departmentOptions[i].defaultMinute} onChange={(e)=>{this.handleDefaultMinute2(e,i)}}/>
                                                              <span>Minutes</span>                                      
                                                              <p className="red-text hide">This cannot be empty.</p>
                                                  </label>  
                                                                        
                                                  </div>
                                                </div>
                                            <div>
                                              <div>
                                              <h6>If unresolved within given time, action will be taken below</h6>
                                              <Select
                                                      classNamePrefix="react-select"
                                                      styles={this.props.dark ? selectStylesDark : selectStyles}
                                                      value={{label : this.state.departmentOptions[i].selectedOption,value : this.state.departmentOptions[i].selectedValue}}
                                                      onChange={(selectedOption)=>this.handleDefaultSlaChange2(selectedOption,i)}
                                                      options={slaOptions}
                                                      isSearchable={false}
                                                  />  
                                                  {(this.state.departmentOptions[i].selectedValue === 1 ?<Select
                                                  classNamePrefix="react-select"
                                                  styles={this.props.dark ? selectStylesDark : selectStyles}
                                                  value={{label : this.state.departmentOptions[i].selectedDep,value : this.state.departmentOptions[i].selectedDepValue}}
                                                  onChange={(selectedOption)=>this.handleDepartmentChange2(selectedOption,i)}
                                                  options={this.state.departmentOptions}
                                                  isSearchable={false}
                                              /> :( 
                                                this.state.departmentOptions[i].selectedValue === 2 ? 

                                                <React.Fragment>

                                                <div className="relative-content">
                                                <Dropdown
                                                      isOpen={this.state.departmentSelect[i]}
                                                      onClose={()=>this.toggleOpen2(i)}
                                                      target={
                                                          <React.Fragment>
                                                            <Button className={(this.props.dark ? "white-text" : "")} onClick={()=>this.toggleOpen2(i)}>Add a label</Button>
                                                          {/* <FilterList className="linked" onClick={this.toggleOpen}/> */}
                                                          </React.Fragment>
                                                      //   <Button
                                                      //     // iconAfter={<ChevronDown />}
                                                      //     onClick={this.toggleOpen}
                                                      //     isSelected={isOpen}
                                                      //   >
                                                      //     {/* {value ? `State: ${value.label}` : 'Select a State'} */}
                                                      //   </Button>
                                                      }
                                                  >
                                                      <Select
                                                          autoFocus
                                                          menuIsOpen
                                                          classNamePrefix="react-select"
                                                          styles={(this.props.dark ? selectStylesDark : selectStyles)}
                                                          value={{label : "Select",value : 0}}
                                                          onChange={(option) => this.labelSelected2(i,option)}
                                                          options={this.state.labelOption}
                                                          isSearchable={false}
                                                          components={{ DropdownIndicator: null, IndicatorSeparator: null }}
                                                      />     
                                                  </Dropdown> 
      
                                                    
                                                    {
                                                      this.state.departmentLabel[i].map((obj,k)=>(
                                                        <div key={k}>
                                                          <div className="valign-wrapper">
                                                          {(this.labelInfo(obj.label) !== null ? <span className="label" style={{color: Color(this.props.dark ? (this.labelInfo(obj.label)).colorDark :(this.labelInfo(obj.label)).color).isDark() ? "#fefefe" : "#333333",backgroundColor:(this.props.dark ? (this.labelInfo(obj.label)).colorDark :(this.labelInfo(obj.label)).color)}}>{(this.labelInfo(obj.label)).name}</span> : 
                                                          <span className="label">Removed</span>
                                                          )}
                                                          <CancelOutlined className="red-text linked" onClick={()=>this.removeDepartmentLabel(i,k)}/>
                                                          </div>
                                                        <Select
                                                          classNamePrefix="react-select"
                                                          styles={this.props.dark ? selectStylesDark : selectStyles}
                                                          value={{label :  (this.props.department.filter(item => item.id === obj.department).length > 0 ? (this.props.department.filter(item => item.id === obj.department)[0].name) : "Select"),value : obj.department}}
                                                          onChange={(option)=>this.handleLabelDepartmentChange2(i,k,option)}
                                                          options={this.state.departmentOptions}
                                                          isSearchable={false}
                                                      />
      
                                                        </div>
                                                      ))
                                                    }    
                                                    </div>                                          
                                                  </React.Fragment>


                                              :null))}                                       
                                              </div>
                                            </div>
                                            <div className="margin-left-2x">
                                            <h6>Allow Label Override SLA</h6>
                                                <div className="ios-toggle right">
                                                  <input className="ios" type="checkbox" id={"switch-1-"+i} checked={this.state.departmentOptions[i].label_override} onChange={()=>this.toggleOverride(i)}/><label className="ios" htmlFor={"switch-1-"+i}>Toggle</label>
                                              </div>                                         
                                            </div>                                        
                                            <div className="margin-left-2x">
                                            <h6>Email</h6>
                                                <div className="ios-toggle right">
                                                  <input className="ios" type="checkbox" id={"switch-2-"+i} checked={this.state.departmentOptions[i].email} onChange={()=>this.toggleEmail(i)}/><label className="ios" htmlFor={"switch-2-"+i}>Toggle</label>
                                              </div>                                         
                                            </div>
                                          </div>
                                          <button className="btn margin-bottom-2x" onClick={()=>{this.updateDepartmentSla(i)}}>Save</button>  
                                          
                                          </React.Fragment>
                                        ))}                
                                                          
                                      </React.Fragment>
                                      :
                                      (this.state.selectedSection === 5 ? 
                                        <div>
                                          <h5 className="bold-text-2 smaller-font">Chat Color</h5>
                                          <h6>Color for sender message bubble</h6>
                                        <div className="limit-width-500">
                                              <div class="message-box" style={{marginBottom:'5px'}}>
                                                <div class="msgbox-detail-holder-right">
                                                  <div data-balloon-blunt="true" aria-label="Superceed" data-balloon-pos="up-right" class="flex-col user-img-bubble-right">
                                                    <img src="https://www.superceed.com/images/system/default.png" alt="none"/>
                                                      <span class="timebox"><time datetime="1592443177000">09:19</time></span>
                                                  </div>
                                                  <div class="main-message-wrapper ">
                                                    <div class="msg-bubble-right" style={{backgroundColor:this.state.settings.chat_color,color:Color(this.colorTest(this.state.settings.chat_color)).isDark() ? "#fefefe" : "#545454" }}>
                                                    <div class="message-wrapper">
                                                      <div class="hidden-div"></div>
                                                      <div class="text-wrapper">
                                                        <span>Hyperceed</span>
                                                      </div>
                                                    </div>
                                                    </div>
                                                  </div>
                                                  <div class="clearfix"></div>
                                                </div>

                                              </div>
                                              <div class="message-box">
                                                <div class="msgbox-detail-holder-right">
                                                  <div data-balloon-blunt="true" aria-label="Superceed" data-balloon-pos="up-right" class="flex-col user-img-bubble-right">
                                                    <img src="https://www.superceed.com/images/system/default.png" alt="none"/>
                                                      <span class="timebox"><time datetime="1592443177000">09:19</time></span>
                                                  </div>
                                                  <div class="main-message-wrapper ">
                                                    <div class="msg-bubble-right" style={{backgroundColor:this.state.settings.chat_color}}>
                                                    <div class="message-wrapper">
                                                      <div class="hidden-div"></div>
                                                      <div class="text-wrapper">
                                                        <span>
                                                          <a className="linked" style={{color:this.state.settings.link_color}}>https://www.hyperceed.com</a>
                                                        </span>
                                                      </div>
                                                    </div>
                                                    </div>
                                                  </div>
                                                  <div class="clearfix"></div>
                                                </div>

                                              </div>
                                          </div>   
                                          <div>
                                            <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                                  <input className="browser-default" type="text"
                                                  value={this.state.settings.chat_color} 
                                                  onChange={this.updateColor}
                                                  // onChange={(e)=>{this.handleChange(e,i,"name")}}
                                                  />
                                                  <span>Chat Bubble Color (Hex)</span>
                                                  <p className="red-text hide">This cannot be empty.</p>
                                              </label>   
                                              <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                                  <input className="browser-default" type="text" 
                                                  value={this.state.settings.link_color} 
                                                  onChange={this.updateLinkColor}
                                                  // onChange={(e)=>{this.handleChange(e,i,"name")}}
                                                  />
                                                  <span>Link Colour (Hex)</span>
                                                  <p className="red-text hide">This cannot be empty.</p>
                                              </label>                                          
                                          </div>                                       
                                        </div>
                                      : 
                                        this.state.selectedSection === 6 ? 
                                        <div>{

                                        (this.state.sip.length === 0 ?<div>
                                        <h5 className="bold-text-2 smaller-font">Register Account</h5>
                                        <h6>Enter Credentials to register</h6>
                                          <div className="limit-width-500">
                                            <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                                <input className="browser-default" type="text" value={this.state.identity} onChange={(e)=>{this.setState({identity : e.target.value})}}/>
                                                <span>Identity</span>
                                                <p className="red-text hide">This cannot be empty.</p>
                                            </label>  
                                            <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                                <input className="browser-default" type="password" value={this.state.sipPassword} onChange={(e)=>{this.setState({sipPassword : e.target.value})}}/>
                                                <span>Password</span>
                                                <p className="red-text hide">This cannot be empty.</p>
                                            </label>                                              
                                            <button className="btn waves-effect waves-light" onClick={this.saveSIP}>Save</button>                                          
                                        </div>                                         
                                      </div>
                                      :
                                      <div>
                                        <h5 className="bold-text-2 smaller-font">Registered Account</h5>
                                        <h6>{this.state.sip[0]?.sip_number}</h6>
                                        <div>
                                          <h6>Speech to Text</h6>
                                          <h6>Select Model</h6>
                                          <Dropdown2>
                                          <Dropdown2.Toggle variant="success" id="dropdown-basic">
                                              {
                                                this.state.selectedModal === 0 ?
                                                "Model 1"
                                                :
                                                (this.state.selectedModal === 1 ? "Model 2" : "")
                                              }
                                          </Dropdown2.Toggle>

                                          <Dropdown2.Menu>
                                              <Dropdown2.Item eventKey={0} onClick={()=>{
                                                  this.setState({selectedModal:0})
                                                }}>Model 1</Dropdown2.Item>
                                                <Dropdown2.Item eventKey={1} onClick={()=>{
                                                  // alert(1);
                                                  this.setState({selectedModal:1})
                                                }}>Model 2</Dropdown2.Item>
                                          </Dropdown2.Menu>
                                          </Dropdown2>
                                          {this.state.selectedModal === 0 ? <><h6>Model 1</h6>                                          
                                          <Dropdown2>
                                          <Dropdown2.Toggle variant="success" id="dropdown-basic">
                                              {
                                                this.state.selectedLang === -1 ?
                                                "Select Language"
                                                :
                                                lang[this.state.selectedLang].name
                                              }
                                          </Dropdown2.Toggle>

                                          <Dropdown2.Menu>
                                            {
                                              lang.map((obj,i)=>(
                                                <Dropdown2.Item key={obj.code} eventKey={obj.code} onClick={()=>{
                                                  this.setState({selectedLang:i})
                                                }}>{obj.name}</Dropdown2.Item>
                                              ))
                                            }
                                          </Dropdown2.Menu>
                                          </Dropdown2></> : null}
                                          {this.state.selectedModal === 1 ? <>
                                          <h6>Model 2</h6> 
                                          {
                                              lang2.map((obj,i)=>(
                                                <div>
                                                  <div onClick={()=>{this.toggleLang(obj.code)}} className="inline-block">{(this.state.selectedLang2.indexOf(obj.code) === -1 ? <CheckBoxOutlineBlank className="margin-right-2x checkbox-unchecked"/> : <CheckBox className="margin-right-2x checkbox-checked agent-select-checkbox"/>)}</div>
                                                  <span>{obj.name}</span>
                                              </div>
                                              
                                              ))
                                            }
                                            </>
                                            : null}
                                          <h6>Translation</h6>
                                          <Dropdown2>
                                          <Dropdown2.Toggle variant="success" id="dropdown-basic">
                                              {
                                                this.state.selectedTargetLang === -1 ?
                                                "Select Language"
                                                :
                                                lang[this.state.selectedTargetLang].name
                                              }
                                          </Dropdown2.Toggle>

                                          <Dropdown2.Menu>
                                          <Dropdown2.Item eventKey={-1} onClick={()=>{
                                                  this.setState({selectedTargetLang:-1})
                                                }}>Dont target</Dropdown2.Item>
                                            {
                                              lang.map((obj,i)=>(
                                                <Dropdown2.Item key={obj.code} eventKey={obj.code} onClick={()=>{
                                                  // alert(i);
                                                  this.setState({selectedTargetLang:i})
                                                }}>{obj.name}</Dropdown2.Item>
                                              ))
                                            }
                                          </Dropdown2.Menu>
                                          </Dropdown2> 
                                        </div>
                                        <button className="button-new margin-top-2x" onClick={()=>{if(this.state.selectedModal === 0){this.changeLanguage()}else if(this.state.selectedModal === 1){this.changeLanguage2()}}}>Save</button>
                                        {/* <button className="button-new margin-top-2x" onClick={()=>this.changeLanguage2()}>Set Language (Model 2)</button> */}
                                        <button className="button-new margin-top-2x" onClick={()=>this.removeSIP(this.state.sip[0].id)}>Remove This Account</button>
                                      </div>
                                      )}
                                      {/* <div>
                                      <h5 className="bold-text-2 smaller-font">Wauchat Account</h5>
                                            <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                                <input className="browser-default" type="text" value={this.state.kbUser} onChange={(e)=>{this.setState({kbUser : e.target.value})}}/>
                                                <span>Username</span>
                                                <p className="red-text hide">This cannot be empty.</p>
                                            </label>  
                                            <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                                <input className="browser-default" type="password" value={this.state.kbPassword} onChange={(e)=>{this.setState({kbPassword : e.target.value})}}/>
                                                <span>Password</span>
                                                <p className="red-text hide">This cannot be empty.</p>
                                            </label>     
                                            <button className="btn waves-effect waves-light" onClick={this.saveWauchatAccount}>Save</button>                                           
                                      </div> */}
                                      </div>
                                      :
                                      this.state.selectedSection === 9 ?

                                       this.state.statusList.map((obj,i)=>(
                                        <div key={i} className={"full-width overflow-hidden margin-right-1x label-item card new-shadow "} style={{backgroundColor:(this.props.dark ? "#282828" : "#fefefe")}}>
                                            <div className="valign-wrapper" style={{padding:"5px 20px",backgroundColor:(this.props.dark ? "#212121" : "#f5f5f5")}}>
                                            <h5 className={"full-width bold-text-2 small-font "+(this.props.dark ? "" : "grey-text text-darken-3")}>{obj.name}</h5>                                
                                            <div className={"linked icon-button-hover-3 "+ (this.props.dark ? "dark-mode" : "")} onClick={()=>{this.expandStatus(i)}}>
                                          {/* <div className="valign-wrapper" style={{padding:"5px 20px",backgroundColor:""+(this.props.dark ? obj.colorDark : obj.color)}}>
                                            <h5 className={"full-width bold-text-2 small-font "+(Color(this.props.dark ? this.colorTest(obj.colorDark) :this.colorTest(obj.color)).isDark() ? "white-text" : "grey-text text-darken-3")}>{obj.name}</h5>                                
                                            <div className={"linked icon-button-hover-3 "+(Color(this.props.dark ? this.colorTest(obj.colorDark) :this.colorTest(obj.color)).isDark() ? "dark-mode" : "")} onClick={()=>{this.expandStatus(i)}}> */}
                                              {/* {(obj.expanded ? 
                                              <ExpandLess className={" "+(Color(this.props.dark ? this.colorTest(obj.colorDark) :this.colorTest(obj.color)).isDark() ? "white-text" : "grey-text text-darken-3")}/>
                                              :
                                              <ExpandMore className={" "+(Color(this.props.dark ? this.colorTest(obj.colorDark) :this.colorTest(obj.color)).isDark() ? "white-text" : "grey-text text-darken-3")}/>
                                              )} */}
                                              {(obj.expanded ? 
                                              <ExpandLess className={" "+(this.props.dark ? "white-text" : "grey-text text-darken-3")}/>
                                              :
                                              <ExpandMore className={" "+(this.props.dark ? "white-text" : "grey-text text-darken-3")}/>
                                              )}
                                            </div>
                                            <div className={"linked icon-button-hover-3 "+(this.props.dark ? "dark-mode" : "")} onClick={()=>{this.deleteStatus(i)}}>
                                              <Delete className={" "+(this.props.dark ? "white-text" : "grey-text text-darken-3")}/>
                                              {/* <Delete className={" "+(Color(this.props.dark ? this.colorTest(obj.colorDark) :this.colorTest(obj.color)).isDark() ? "white-text" : "grey-text text-darken-3")}/> */}
                                            </div>                                
                                            
                                          </div>
                                          <div className={"card-content flex"+(obj.expanded ? "":" hide")}>
                                                {/* <div className="label-item-cell" style={{verticalAlign:"middle"}}>
                                                    {i + 1}
                                                </div> */}
                                                <div className="label-item-cell">
                                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                                        <input className="browser-default" type="text" value={obj.name} onChange={(e)=>{this.handleChange2(e,i,"name")}}/>
                                                        <span>Name</span>
                                                        <p className="red-text hide">This cannot be empty.</p>
                                                    </label>
                                                </div>
                                                {/* <div className="label-item-cell">
                                                    <div className="flex-row" style={{alignItems:"center"}}>
                                                        <Lens style={{color:obj.color}} className="linked" onClick={(e)=>this.showPicker(i,e)}/>
                                                        <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                                            <input className="browser-default" type="text" value={obj.color} onChange={(e)=>{this.handleChange(e,i,"color")}}/>
                                                            <span>Hex Color</span>
                                                            <p className="red-text hide">This cannot be empty.</p>
                                                        </label>
                                                        {(obj.picker ? <div className="z-depth-1 color-picker" style={{position:"fixed",left:obj.locationX+"px",borderRadius:"5px",top:obj.locationY+"px",zIndex:9}}>
                                                            <SketchPicker color={ obj.color } onChangeComplete={(color)=> this.handleChangeComplete(color.hex,i)}/>
                                                        </div> : null)}
                                                    </div>
                                                </div>     
                                                <div className="label-item-cell">
                                                    <div className="flex-row" style={{alignItems:"center"}}>
                                                        <Lens style={{color:obj.colorDark}} className="linked" onClick={(e)=>this.showPicker2(i,e)}/>
                                                        <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                                                <input className="browser-default" type="text" value={obj.colorDark} onChange={(e)=>{this.handleChange(e,i,"colorDark")}}/>
                                                                <span>Hex Color (Dark Mode)</span>
                                                                <p className="red-text hide">This cannot be empty.</p>
                                                            </label>
                                                            {(obj.pickerDark ? <div className="z-depth-1 color-picker" style={{position:"fixed",left:obj.locationX+"px",borderRadius:"5px",top:obj.locationY+"px",zIndex:9}}>
                                                            <SketchPicker color={ obj.colorDark } onChangeComplete={(color)=> this.handleChangeCompleteDark(color.hex,i)}/>
                                                            </div> : null)}
                                                    </div>
                                                </div>                                                            
                                                <div className="label-item-cell">
                                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                                        <input className="browser-default" type="text" value={obj.level} onChange={(e)=>{this.handleChange(e,i,"level")}}/>
                                                        <span>Priority Level</span>
                                                        <p className="red-text hide">This cannot be empty.</p>
                                                    </label>
                                                </div>
                                                <div className="label-item-cell">
                                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                                            <input className="browser-default" type="text" value={obj.sla} onChange={(e)=>{this.handleChange(e,i,"sla")}}/>
                                                            <span>SLA (Minute)</span>
                                                            <p className="red-text hide">This cannot be empty.</p>
                                                    </label>  
                                                </div>   */}
                                                <div className="label-item-cell">
                                                    
                                                </div>
                                              </div>                                                                                              
                                        </div>  
                                        ))                                      
                                      :
                                      (this.state.selectedSection === 14 ?
                                        <Security/>
                                         : 
                                         (this.state.selectedSection === 15 ?
                                          <QA/>
                                           : (this.state.selectedSection === 16 ?
                                            <Voicebot setChildrenModalOpened={this.setChildrenModalOpened} dark={this.props.dark} openSnackbar={this.openSnackbar} stateChange={this.props.stateChange}/>
                                             : null))
                                         ))
                                    )
                                )
                        )
                        
                        )}
                        {
                          this.state.selectedSection === 7 ?
                          <Notification updateSettings={this.updateSettings2} settings={this.props.settings} userType={this.props.userType} closeModal={this.props.closeModal} requestNotification={this.props.requestNotification} setChildrenModalOpened={this.setChildrenModalOpened} dark={this.props.dark} userType={this.props.userType} department={this.props.department} openSnackbar={this.openSnackbar} stateChange={this.props.stateChange}/>
                          :
                          null
                        }                    
                    </div>
                    </Scrollbars>
                </div>
              </div>

          {/* <div className="ng-modal-main-footer right-text">                      

          </div> */}
        </div>
    );
  }
}
export default withStyles(snackbarStyles)(componentName);
