import React, { Component } from 'react'
import axios from 'axios';
import Variable from './../variable';
import VoiceAnalysisChart from './../components/VoiceAnalysisChart';
// import { line, area } from "d3-shape";

// const Area = ({ bars, xScale, yScale, height }) => {
//     const areaGenerator = area()
//       .x(bar => xScale(bar.data.index) + bar.width / 2)
//       .y0(() => height)
//       .y1(bar => yScale(bar.data.data.v2));
  
//     return (
//       <path
//         d={areaGenerator(bars)}
//         fill={areaColor}
//         style={{ mixBlendMode: "multiply", pointerEvents: "none" }}
//         opacity={0.5}
//       />
//     );
//   };

export class VoiceAnalysis extends Component {

    host = Variable.host;

    constructor(){
        super();
        this.state = {
            voiceData: "",
            voiceString: "",
            data:[{
                "chunk":"1",
                "Sad":-20,
                "SadColor": "hsl(298, 70%, 50%)",
            }],
            chartKey:[
            ],
            pieData:[],
            tempoData:[]
        }
        
    }

    toChartData = (obj) => {
        let chartKey = [];
        let data = [];
        let tempoData = [];
        let pieData = [];
        Object.keys(obj.percentage[0]).map((emotion,i)=>{
            chartKey.push(emotion);
            let percent = {};
            percent.id = emotion;
            percent.label = emotion;
            percent.value = obj.percentage[0][emotion];
            percent.color = "hsl(188, 70%, 50%)";
            pieData.push(percent);
        })

        for(let i = 0; i < obj.emotion.length; i++){
            let newObj = {};
            newObj.chunk = i;
            newObj[obj.emotion[i]] = obj.amplitude[i];
            newObj[obj.emotion[i]+"Color"]  = "hsl(298, 70%, 50%)";
            newObj["tempo"] = obj.tempo[i];
            if(chartKey.indexOf("tempo") === -1){
                chartKey.push("tempo");
            }
            newObj["v2"] = obj.tempo[i];
            data.push(newObj);

        }

        for(let i = 0; i < obj.emotion.length; i++){
            let newObj = {};
            newObj.chunk = i;
            newObj[obj.emotion[i]] = obj.tempo[i];
            newObj[obj.emotion[i]+"Color"]  = "hsl(298, 70%, 50%)";
            newObj["v2"] = obj.tempo[i];
            tempoData.push(newObj);

        }
        this.setState({chartKey:chartKey,data:data,pieData:pieData,tempoData:tempoData})
    }

    fileUpload = () => {
        if(this.fileUploader.files.length > 0){
                const formData = new FormData();
                // for(var i = 0; i < files.length; i++){
                //   formData.append('attachment[]',files[i]);
                // }
                formData.append('attachment',this.fileUploader.files[0]);
                // formData.append('_token',this.getCookie("auth"));
                // formData.append('message',message);
                // formData.append('ticket_id',id);
                // formData.append('closed',"0");
                // formData.append('sender',this.state.user.username);
                // formData.append('type',type);
                const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true,
                //   onUploadProgress: progressEvent => {
                //     const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                //     //console.log("onUploadProgress", totalLength);
                //     if (totalLength !== null) {
                //         let percent = Math.round( (progressEvent.loaded * 100) / totalLength );
                //         this.setState({uploadPercent:percent});
                //     }
                //   }
                }   
                axios.post(this.host+'/api/upload/voice', formData,config)
                .then(res => {
                    this.toChartData(res.data);
                    this.setState({voiceData:res.data,voiceString:JSON.stringify(res.data, null, "\t")});
                })  
            }      
    }


    render() {
        return (
            <div className="full-width flex-col full-height">
                    <div className={" flex-row padding-left-2x padding-right-2x flex-no-shrink header-border-bottom"} style={{height:'64px',alignItems:'center',position:"relative",backgroundColor:this.props.dark ? '#333' :'#ffffff'}}>
                        <span className="bold-text-2full-width ellipsis-text chat-list-title-text bold-text-2">Upload</span>
                    </div>
                    <div className="overflow-y">
                        <div style={{padding:'20px'}}>
                            <input ref={el =>this.fileUploader = el} onChange={this.fileUpload} type="file" accept="audio/*" id="file-input"/>
                            <pre>{this.state.voiceString}</pre>
                        </div>
                        <div>
                        <VoiceAnalysisChart 
                            chartType="bar"
                            min={-50}
                            max={200}
                            data={this.state.data}
                            chartKey={this.state.chartKey}
                            dark={this.props.dark}
                            enableGridX={true}
                            enableGridY={false}
                            axisTop={{ tickSize: 5, tickPadding: 5, tickRotation: 0, legend: '', legendOffset: 36 }}
                            height={500} marginTop={ 50} marginBottom={0} marginLeft={100} axisBottom="emotion" axisLeft="" indexBy="chunk" layout="vertical" /> 
                        <VoiceAnalysisChart 
                            chartType="pie"
                            min={-50}
                            max={100}
                            data={this.state.pieData}
                            chartKey={this.state.chartKey}
                            dark={this.props.dark}
                            enableGridX={true}
                            enableGridY={false}
                            axisTop={{ tickSize: 5, tickPadding: 5, tickRotation: 0, legend: '', legendOffset: 36 }}
                            height={500} marginTop={ 50} marginBottom={0} marginLeft={100} axisBottom="emotion" axisLeft="" indexBy="chunk" layout="vertical" />    
                        {/* <VoiceAnalysisChart 
                            chartType="bar"
                            min={0}
                            max={200}
                            data={this.state.tempoData}
                            chartKey={this.state.chartKey}
                            dark={this.props.dark}
                            enableGridX={true}
                            enableGridY={false}
                            axisTop={{ tickSize: 5, tickPadding: 5, tickRotation: 0, legend: '', legendOffset: 36 }}
                            height={500} marginTop={ 50} marginBottom={0} marginLeft={100} axisBottom="emotion" axisLeft="" indexBy="chunk" layout="vertical" />                                                       */}
                        </div>
                    </div>
            </div>
        )
    }
}

export default VoiceAnalysis
