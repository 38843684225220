import React, {Component} from 'react'
import axios from 'axios';
import Logo from './../images/logo_superceed_compressed.png';
import Variable from './../variable'
import Modal from 'react-modal';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

const modalStyles = {
    content : {
      top                   : 'auto',
      left                  : 'auto',
      right                 : 'auto',
      bottom                : 'auto',
      maxWidth:'500px',
      border: 'none',
      boxShadow: '0 2px 3px 0 rgba(60,64,67,.3),0 6px 10px 4px rgba(60,64,67,.15)',
      borderRadius : '15px',
      padding : '0px'
    },
    overlay : {
      backgroundColor: ('rgba(0,0,0,0.5)')
    }
  };

export class Login extends Component {

    host = Variable.host;

    state = {
        username : "",
        password : "",
        isAdmin : true,
        oauthStarted : true,
        tfa:"",
        tfaModalOpen:false,
        tooltipVisible:false
    }    

    constructor(props){
        super(props);
        this.wrapperRef = React.createRef();
        this.clearField = this.clearPassword.bind(this);
        this.enableButton = this.enableOAuth.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    enableOAuth = () => {
        this.setState({oauthStarted:false});
    }
    // return (
    //     <Router>

    //     </Router>
    // );
    setUsername(e){
        this.setState({ username: e.target.value });
    }

    handleClickOutside(event) {
        if(this.wrapperRef){
            if (this.wrapperRef.current){
                // alert('You clicked outside of me!');
                if(!this.wrapperRef.current.contains(event.target)) {
                    this.setState({tooltipVisible:false});
                }
            }
        }
    }

    setPassword(e){
        this.setState({ password: e.target.value });
    }

    set2fa(e){
        this.setState({ tfa: e.target.value });
    }

    clearPassword = () => {
        console.log("clear");
        if(this.state.tfa !== ""){
            this.setState({ password: "", tfa: "" },()=>{
                this.passwordInput.focus(); 
            });
        }
        else{
            this.setState({tfa: "" },()=>{
                if(this.tfaInput){
                    this.tfaInput.focus(); 
                }
            });
        }
    }

    login = () => {
        //console.log(this.state.username+" "+this.state.password);
        this.props.login(this.state.username,this.state.password,this.state.isAdmin,this.state.tfa);
    }

    oauth = () => {
        this.setState({oauthStarted:true});

        let popup = window.open('https://www.superceed.com/oauth?token='+this.oauthToken,'popUpWindow','height=500,width=400,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
        var timer = setInterval(() => { 
            if(popup.closed) {
                clearInterval(timer);
                this.props.oauth(this.oauthToken);
                //alert('closed');
            }
        }, 1000);  


    

        
    }

    closeModal = () => {
        // this.clearPassword();
        this.setState({"tfa":"","password":""});
        this.passwordInput.focus(); 
        this.props.change("tfa",false);
    }

    componentDidMount(){
        this.nameInput.focus(); 
        this.props.clear(this.clearField);
        document.addEventListener('mousedown', this.handleClickOutside);
        // this.props.enableOAuth(this.enableButton);

        // axios.get(this.host+'/oauth/generate',{withCredentials: true})
        // .then(res => {
        //     if(res.data.result === "success"){
        //         this.oauthToken = res.data.token;
        //         this.setState({oauthStarted:false});
        //     }
        // }
        // )
        // .catch(err=>{console.error(err)});        
     }

     componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

     loginKeypress = (e) => {
        if(e.keyCode === 13){
            this.props.login(this.state.username,this.state.password,this.state.isAdmin,this.state.tfa);
        }
     }

    render(){
        return (
                <React.Fragment>
                    <Modal
                        isOpen={this.props.tfa}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeTfaModal}
                        style={modalStyles}
                        contentLabel="Example Modal"
                        ariaHideApp={false} 
                        closeTimeoutMS={200}       
                                                        
                        className={""+(this.props.dark ? "dark" : null)}>
                        <div className="flex-col" style={{width:'300px',maxHeight:'100vh'}}>
                          <div className={"ng-modal-main-header"}>
                            <h5 className="bold-text-2 smaller-font">Two-factor Authentication</h5>
                            </div>
                            <div className="ng-modal-main-body padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x">
                                    {
                                        this.props.tfa ?
                                        <label className="pure-material-textfield-outlined full-width margin-bottom-1x">                                      
                                            <input ref={(input) => { this.tfaInput = input; }} value={this.state.tfa} onKeyDown={(e) => {if(this.state.tfa.length > 0){this.loginKeypress(e)}}} className="browser-default" type="password" onChange={ e => this.set2fa(e) }/>
                                            <span>Auth Code</span>                              
                                            <p className="red-text hide">This cannot be empty.</p>  
                                        </label>    
                                        :
                                        null
                                    }   
                        {this.state.tfa.length >= 16 ? 
                            <div className="info-box warning">
                                <div className="valign-wrapper">
                                   
                                    <h6 className="margin-left-1x">Looks like you are using a backup code. Using backup code will disable 2FA after successful login.</h6>
                                </div>
                            </div>    
                        : null}    
                            <Tippy content={"You can use backup code to access your account, but 2FA will be disabled."} trigger="MANUAL2" visible={this.state.tooltipVisible}>
                                <a  ref={this.wrapperRef} onClick={()=>{this.setState({tooltipVisible:true})}} className="blue-text linked unselectable">Don't have auth code?</a>                             
                            </Tippy>
                          </div>
                            <div className="ng-modal-main-footer right-text"> 
                                <button className="btn-flat ng-close-btn waves-effect waves-dark margin-right-1x" onClick={this.closeModal}>cancel</button>
                                <button className={"btn-flat green white-text margin-right-1x hyperceed-btn-bold"+(this.state.tfa.length === 0 ? " disabled" : "")} onClick={ this.login }>Login</button>                           
                            </div>
                        </div>
                    </Modal> 
                    <div className={"login-bg full-height login-form full-width mid-text "+(this.props.dark ? "" : "")} style={{top:0,zIndex:20,alignItems:'middle',position:"fixed",width:"100%",height:"100%"}}>
                        <div className={"login-form-wrapper"}>
                            <div className={(this.props.dark ? "grey darken-3 " : "")+"card z-depth-0 padding-left-2x padding-right-2x padding-bottom-2x block-center"}>
                                <div className="login-content">
                                {/* <FontAwesomeIcon onClick={this.props.closeLogin} className="homepage-channel-icon linked right" icon={faTimes} style={{marginRight:"20px"}}/>
                                <div className="clearfix"></div> */}

                            <div className="valign-wrapper">
                                        <div className="logo-section">
                                            <div>
                                            
                                                    <div className="valign-wrapper">
                                                    <img style={{width:"30px"}} src={Logo} /> 
                                                    <h5 className="bold-text-2 hyperceed-bold no-margin">SuperCall</h5>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>                            
                                    <h4 className="bold-text-2 left-text hyperceed-bold margin-top-2x margin-bottom-2x">Login</h4>
                { !this.props.loginValid ? <h6 className="red lighten-3 callout-box white-text">{this.props.loginError}</h6> : null }
                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">                                      
                                            <input ref={(input) => { this.nameInput = input; }}  onKeyDown={(e) => this.loginKeypress(e)} className="browser-default"  type="text" onChange={ e => this.setUsername(e) }/>
                                            <span>Username</span>
                                            <p className="red-text hide">This cannot be empty.</p>                         
                                    </label>                          
                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">                                      
                                        <input ref={(input) => { this.passwordInput = input; }} value={this.state.password} onKeyDown={(e) => this.loginKeypress(e)} className="browser-default" type="password" onChange={ e => this.setPassword(e) }/>
                                        <span>Password</span>                              
                                        <p className="red-text hide">This cannot be empty.</p>  
                                    </label>     
                                    {/* <div className="type-select full-width">
                                        <div className="margin-left-1x" style={{display:"inline-block"}}>
                                            <input
                                                type="radio"
                                                id="switch"
                                                name="userType"
                                                checked={this.state.isAdmin} onChange={()=>{this.setState({isAdmin:true})}}/><label className="material" htmlFor="switch">Admin</label>
                                        </div>
                                        <div className="margin-left-1x" style={{display:"inline-block"}}>
                                            <input                                
                                                type="radio"
                                                id="switch-2"
                                                name="userType"
                                                checked={!this.state.isAdmin} onChange={()=>{this.setState({isAdmin:false})}}/><label className="material" htmlFor="switch-2">Agent</label>                                 
                                        </div>
                                    </div>                              */}
                                    <div className="button-holder margin-top-2x margin-bottom-2x">
                                        
                                        <button className={"btn-flat full-width green white-text margin-bottom-1x nowrap"+(this.props.logging ? " disabled" : "")} onClick={ this.login }>Login</button>   
                                        <button className="button-new full-width margin-bottom-1x " onClick={()=>{window.location.href = "https://www.superceed.com/signup/superunity"}}>Sign up</button>                
                                    </div>     
                                    {/* <div>
                                        <h5 className="bold-text-2">OR</h5>
                                    </div>
                                    <div className="button-holder margin-top-2x">
                                        <button className={"btn margin-right-1x grey-text grey lighten-4 text-darken-2 bold-text "+(this.state.oauthStarted ? "disabled" : "")} onClick={this.oauth}><img className="left" src={Logo} style={{width:"24px"}}></img>Login from superceed.com</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <h6 className='hyperceed-bold white-text'>©2024 SUPERCEED Sdn Bhd.</h6>
                        <div className="ocean">
                            <div className="wave"></div>
                        </div> 
                    </div>
                </React.Fragment>
        );
    }
}

export default Login