import React, { Component } from 'react'
import Viewer from 'react-viewer';
import ImgViewer from 'react-images-viewer'
import 'react-viewer/dist/index.css';


export default class ImageViewer extends Component {
    state = {
        visible: false,
        imageList: [],
        index:0
    };    

    constructor(props){
        super(props);
        this.showImage = this.passToViewer.bind(this);

        this.showImage2 = this.passToViewer2.bind(this);

        this.addImages = this.addImagesFunction.bind(this);

    }

    passToViewer = (array) => {
        //console.log("called");
        this.setState({visible : true, imageList: array});
    }

    passToViewer2 = (array,index) => {
        //console.log("called");
        this.setState({visible : true, imageList: array, index: index});
        if(index >= array.length - 3){
            if(this.props.callToLoadMore !== undefined){
                this.props.callToLoadMore()
            }
        }
    }  
    
    addImagesFunction = (array) => {
        let temp = this.state.imageList;
        temp = temp.concat(array);
        this.setState({imageList:temp });
    }

    componentDidMount(){
        if(this.props.passToViewer !== undefined){
            this.props.passToViewer(this.showImage);
        }
        if(this.props.passToViewer2 !== undefined){
            this.props.passToViewer2(this.showImage2);
        }
        if(this.props.addImages !== undefined){
            this.props.addImages(this.addImages);
        }
    }

    imageChanged = (obj,i) => {
        //console.log(i);
        if(this.props.callToLoadMore !== undefined){
            if(i >= this.state.imageList.length - 3){
                this.props.callToLoadMore();
            }
        }
    }

    closeImgsViewer = () => {
        this.setState({visible:false})
    }

    gotoNextImg = (e) => {
        if(this.props.callToLoadMore !== undefined){
            var i = this.state.index;
            if(i === this.state.imageList.length){
                return false;
            }            
            i++;
            if(i >= this.state.imageList.length - 3){
                this.props.callToLoadMore();
            }
            this.setState({index:i})          
        }
    }

    gotoPrevImg = (e) => {
        if(this.props.callToLoadMore !== undefined){
            var i = this.state.index;
            if(i === 0){
                return false;
            } 
            i--;
            this.setState({index:i})          
        }
    }

    clickedThumbnail = (i) => {
        this.setState({index:i});
    }
    
    render() {
        return (
            <ImgViewer
            imgs={this.state.imageList}
            isOpen={this.state.visible}
            currImg={this.state.index}
            onClickPrev={this.gotoPrevImg}
            onClickNext={this.gotoNextImg}
            onClickThumbnail={this.clickedThumbnail}
            onClose={this.closeImgsViewer}
            showThumbnails={true}
            closeBtnTitle="Close"
            leftArrowTitle="Previous"
            rightArrowTitle="Next"
          />
            // <Viewer
            //     transition={false}
            //     visible={this.state.visible}
            //     onClose={() => { this.setState({ visible: false }); } }
            //     activeIndex={this.state.index}
            //     images={this.state.imageList}
            //     onChange={this.imageChanged}
            //     loop={false}
            // />
 
        )
    }
}
