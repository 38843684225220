import React, { useEffect, useState } from 'react';
// import Layout from './layout/Layout.js';
import ScoreChartPage from './ScoreChartPage';
import VolumnChartPage from './VolumnChartPage';
// import CallRecordingPage from './CallRecordingPage';
import CategoryChartPage from './CategoryChartPage';
import BMWCallChartPage from './BMWCallChartPage';
// import DonutChartPage from './DonutChartPage';

function DashboardChart() {
  return (
    <div>
          <ScoreChartPage /> 
          <VolumnChartPage />
        {/* <CallRecordingPage /> */}
          <CategoryChartPage />
          <BMWCallChartPage/>
        {/* <DonutChartPage />  */}
    </div>
  );
}

export default DashboardChart;