import React, { Component } from 'react'
import axios from 'axios';
import Img from 'react-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt,faFile } from '@fortawesome/free-solid-svg-icons'
import ImageViewer from './../ImageViewer';
import { InsertDriveFileOutlined } from '@material-ui/icons';
import Variable from './../../../variable';

export default class FileManager extends Component {

    host = Variable.host;

    state = {
        files: [],
        id: null,
        loadMore : false
    }

    loading=false;

    constructor(props){
        super(props);
        this.loadFiles = this.loadFilesFunction.bind(this);
        this.loadMoreFiles = this.loadMoreFunction.bind(this);
        this.receiveImage = this.receiveImageFromParent.bind(this);
        this.receiveFile = this.receiveFileFromParent.bind(this);
        this.deleteFile = this.removeFile.bind(this);
    }

    showImages = (array,index) => {
        this.passToViewer(array,index);
    } 
    
    removeFile = (id) => {
        let tempFiles = this.state.files;
        tempFiles = tempFiles.filter(file => file.id !== id);

        this.setState({files:tempFiles});
    }

    componentDidMount(){
        //console.log(this.props.id);
        this.props.loadFiles(this.loadFiles);
        this.props.loadMore(this.loadMoreFiles);
        this.props.receiveFile(this.receiveFile);
        this.props.receiveImage(this.receiveImage);
        this.props.deleteFile(this.deleteFile);
        
        if(this.props.type === "image"){
            axios.get(this.host+'/attachment/images/'+this.props.id+'/0')
            .then(res => {
                let result = false;
                if(res.data.length >= 10){
                    result = true;
                }
                this.setState({files:res.data,id:this.props.id,loadMore:result})
            });
        }
        else{
            axios.get(this.host+'/attachment/files/'+this.props.id+'/0')
            .then(res => {
                let result = false;
                if(res.data.length >= 30){
                    result = true;
                }                
                this.setState({files:res.data,id:this.props.id,loadMore:result})
            });            
        }
    }

    receiveImageFromParent = (obj) => {
        //console.log(obj);
        if(this.props.type === "image"){
            let temp = this.state.files;
            temp.unshift(obj);
            this.setState({files: temp})
        }
    }

    receiveFileFromParent = (obj) => {
        if(this.props.type === "file"){
            let temp = this.state.files;
            temp.unshift(obj);
            this.setState({files: temp})
        }
    }    

    loadFilesFunction = (type) => {
        this.setState({files:[]})
        if(type === "image"){
            axios.get(this.host+'/attachment/images/'+this.props.id+'/0')
            .then(res => {
                let result = false;
                if(res.data.length >= 10){
                    result = true;
                }
                this.setState({files:res.data,id:this.props.id,loadMore:result})
            });
        } 
        else{
            axios.get(this.host+'/attachment/files/'+this.props.id+'/0')
            .then(res => {
                let result = false;
                if(res.data.length >= 30){
                    result = true;
                }                
                this.setState({files:res.data,id:this.props.id,loadMore:result})
            });          
        }              
    }

    loadMoreFunction = () => {
        let type = this.props.type;
        if(this.state.loadMore && !this.loading){
            this.loading = true;
            if(type === "image"){
                axios.get(this.host+'/attachment/images/'+this.props.id+'/'+this.state.files.length)
                .then(res => {
                    this.loading=false;
                    let result = false;
                    if(res.data.length >= 10){
                        result = true;
                    }
                    let temp = this.state.files;
                    temp = temp.concat(res.data);
                    this.setState({files:temp,id:this.props.id,loadMore:result})
                    this.addImages(res.data);
                });
            } 
            else{
                axios.get(this.host+'/attachment/files/'+this.props.id+'/'+this.state.files.length)
                .then(res => {
                    this.loading=false;
                    let result = false;
                    if(res.data.length >= 30){
                        result = true;
                    }                
                    let temp = this.state.files;
                    temp = temp.concat(res.data);                
                    this.setState({files:temp,id:this.props.id,loadMore:result})
                });          
            }    
        }          
    }    

    render() {
        return (
            <React.Fragment>
                <div className="flex">
                    <ImageViewer callToLoadMore={()=>this.loadMoreFunction("image")} passToViewer2={e=>this.passToViewer=e} addImages={e=>this.addImages=e}/> 
                {(this.props.type === "image" ? this.state.files.map((obj,i) => (                           
                    <div onClick={()=>{this.showImages(this.state.files,i)}} className="image-block linked hover-opacity" key={obj.id} style={{backgroundImage:(obj.message.indexOf("ticket-cdn.superceeduniverse.com/attachment") !== -1  || obj.message.indexOf("ticket-api.superceed.com/attachment") !== -1 || obj.message.indexOf("ticket-api.superceeduniverse.com/attachment") !== -1 || obj.message.indexOf("ticket-api.hyperceed.com/attachment") !== -1 ? 'url(\''+obj.message+'/resize\')' : 'url(\'https://ticket-api.superceed.com/image/resize?url='+encodeURIComponent(obj.message)+'\')'),backgroundPosition:'center center',backgroundSize:'cover'}}>
                        {/* <Img src={[obj.message]} style={{backgroundColor:'#ffffff',width:'100%'}} unloader={
                        null
                        }/>                 */}
                    </div>
                )) :
                this.state.files.map((obj,i) => ( 
                    <a key={obj.id} href={obj.message} target="_blank" className="full-width">
                        <div className="flex-row hover-tint rounded-corner-10" style={{alignItems:'center',padding:'10px 5px 10px 5px',marginTop:'5px',marginBottom:'5px',backgroundColor:this.props.dark ? 'rgba(255,255,255,0.1)':'rgba(0,0,0,0.05)'}}>
                            <InsertDriveFileOutlined className="linked margin-left-1x show-tooltip margin-right-1x" style={{fontSize:'20px'}}/>
                            <h6 className="ellipsis-text bold-text">{obj.fileName}</h6>
                        </div>
                    </a>                          
                ))            
                )}  
                {
                    (this.state.files.length === 0 ? 
                    <div className="mid-text full-width">
                        <h6 className="grey-text bold-text" style={{fontSize:'22px',marginBottom:'25px',marginTop:'25px'}}>No {this.props.type} found.</h6>
                        {/* <h6 className="grey-text" style={{fontSize:'12px'}}>You can upload via:<br/>Drag a file to the window.<br/>Click the <FontAwesomeIcon style={{fontSize:'20px'}} icon={faFile}/> icon.<br/>{(this.props.type === "image" ? "Paste an image to the chatbox." : null)}</h6> */}
                    </div>
                    : null)
                }                 
                </div>
                {(this.state.loadMore ? <div className="rounded-corner-10 hover-tint-2 linked margin-top-1x margin-bottom-1x" style={{paddingTop:"5px",paddingBottom:"5px",marginLeft:"10px",marginRight:"10px"}} onClick={this.loadMoreFunction}>
                    <h6 className="mid-text">Show More...</h6>
                </div> : null )}                
            </React.Fragment>
        )
    }
}
