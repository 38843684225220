import React, { Component } from 'react'
import { FilterList, Add, ExpandMore,ExpandLess, SentimentSatisfiedAlt } from '@material-ui/icons';

import Select from 'react-select';

import chroma from 'chroma-js';

import Modal from 'react-modal';

import axios from 'axios';

import { Scrollbars } from 'react-custom-scrollbars';

import Variable from './../../variable';

const modalStyles = {
  content : {
    top                   : 'auto',
    left                  : 'auto',
    right                 : 'auto',
    bottom                : 'auto',
    maxWidth:'500px',
    border: 'none',
    boxShadow: '0 1px 5px rgba(0, 0, 0, 0.15)',
    borderRadius : '15px',
    padding : '0px'
  },
  overlay : {
    backgroundColor: ('rgba(0,0,0,0.5)')
  }
};



const options = [
    { value: 0, label: 'Open' },
    { value: 1, label: 'Pending' },
    { value: 2, label: 'Resolved' },
    { value: 3, label: 'Closed' },
    { value: 4, label: 'All' }
  ];
  
  const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
  
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });


  const selectStyles = {
    menu: styles => ({...styles,margin:'0',borderRadius:'5px',overflow:'hidden'}),
    menuList: styles => ({...styles,backgroundColor:'#fefefe'}),
    valueContainer: styles => ({...styles, height:'30px',color:'#757575'}),
    control: styles => ({ ...styles, backgroundColor: '#fefefe',width:'100%', color:'#757575'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //console.log(data);
      //const color = chroma(data.color);
      
      const color = chroma('#fefefe');
      return {
        ...styles,
  
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#dddddd"
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':hover':{
          ...styles[':hover'],
          backgroundColor: '#eeeeee',
        },
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(1).css()),
        },
      };
    },
    input: (styles, { data }) => ({ ...styles, ...dot(data), height: '30px'}),
  
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data), color: '#757575' }),
  };
  
  const selectStylesDark = {
    menu: styles => ({...styles,margin:'0',backgroundColor:'#333333',borderRadius:'5px',overflow:'hidden'}),
    valueContainer: styles => ({...styles, height:'30px',color:'#fefefe'}),
    control: styles => ({ ...styles, backgroundColor: '#333333',width:'100%', color:'#fefefe'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //console.log(data);
      //const color = chroma(data.color);
      
      const color = chroma('#000000');
      return {
        ...styles,
  
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#212121"
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':hover':{
          ...styles[':hover'],
          backgroundColor: '#424242',
        },
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(1).css()),
        },
      };
    },
    input: (styles, { data }) => ({ ...styles, ...dot(data), height: '30px'}),
    dropdownIndicator: (styles, { data }) => ({ ...styles, ':hover':{color:'#fefefe'}}),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data), color: '#fefefe' }),
  };


  
  const customStyles = {
    menu: styles => ({margin:'0'}),
    container: styles => ({...styles,position:'absolute',width:'200px',right:'0',borderRadius:'5px',overflow:'hidden',boxShadow:"0 1px 5px rgba(0, 0, 0, 0.15)"}),
    menuList: styles => ({...styles,backgroundColor:'#fefefe',maxHeight:'300px',overflow:'auto'}),
    valueContainer: styles => ({...styles, display:'none'}),
    control: styles => ({ backgroundColor: '#fefefe',width:'100%', color:'#757575',border:'none'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //console.log(data);
      //const color = chroma(data.color);
      
      const color = chroma('#fefefe');
      return {
        ...styles,
  
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#dddddd"
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : '#757575',
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':hover':{
          ...styles[':hover'],
          backgroundColor: '#eeeeee',
        },
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(1).css()),
        },
      };
    },
    input: styles => ({ ...styles, ...dot(), height: '30px'}),
  
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color), color: '#757575' }),
  };
  
  const customStylesDark = {
    menu: styles => ({margin:'0'}),
    container: styles => ({...styles,position:'absolute',width:'200px',right:'0',borderRadius:'5px',overflow:'hidden',boxShadow:"0 1px 5px rgba(0, 0, 0, 0.15)"}),
    menuList: styles => ({backgroundColor:'#333',maxHeight:'300px',overflow:'auto'}),
    valueContainer: styles => ({...styles, display:'none'}),
    control: styles => ({ backgroundColor: '#333333',width:'100%', color:'#fefefe'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //console.log(data);
      //const color = chroma(data.color);
      
      const color = chroma('#000000');
      return {
        ...styles,
  
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#212121"
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':hover':{
          ...styles[':hover'],
          backgroundColor: '#424242',
        },
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(1).css()),
        },
      };
    },
    input: styles => ({ ...styles, ...dot(), height: '30px'}),
  
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color), color: '#fefefe' }),
  };

  const DropdownIndicator = () => (
    null
  );

  const Menu = props => {
    const shadow = 'hsla(218, 50%, 10%, 0.1)';
    return (
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 4,
          boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
          marginTop: 8,
          position: 'absolute',
          zIndex: 2,
        }}
        {...props}
      />
    );
  };
  const Blanket = props => (
    <div
      style={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: 'fixed',
        zIndex: 1,
        cursor:"auto"
      }}
      {...props}
    />
  );
  const Dropdown = ({ children, isOpen, target, onClose }) => (
    <div css={{ position: 'relative' }}>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
  );
  const Svg = p => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      focusable="false"
      role="presentation"
      {...p}
    />
  );
//   const DropdownIndicator = () => (
//     <div css={{ color: colors.neutral20, height: 24, width: 32 }}>
//       <Svg>
//         <path
//           d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
//           fill="currentColor"
//           fillRule="evenodd"
//         />
//       </Svg>
//     </div>
//   );
//   const ChevronDown = () => (
//     <Svg style={{ marginRight: -6 }}>
//       <path
//         d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
//         fill="currentColor"
//         fillRule="evenodd"
//       />
//     </Svg>
//   );  


class ChatListTitle extends Component {

  host = Variable.host;

    state = { 
      options:[    { value: 0, label: 'Open' },
      { value: 1, label: 'Pending' },
      { value: 2, label: 'Resolved' },
      { value: 3, label: 'Closed' },
      { value: 999999, label: 'All' }],
      selectedOption: null,
      isOpen: false,
      value: undefined,
      addModalOpen: false,
      ticketTitle: "",
      phone: "",
      email: "",
      description: "",
      name: "",
      titleError: false,
      phoneError: false,
      emailError: false,
      desError: false,
      nameError: false,
      selectedOption:"None",
      selectedValue: "",
      selectedEnergy:"None",
      selectedEnergyValue:"",
      selectedPace:"None",
      selectedPaceValue:"",
      selectedPersonality:"None",
      selectedPersonalityValue:"",
      selectOptions: [
        {value:"",label:"None"},
        {value:"Happy",label:"Happy"},
        {value:"Angry",label:"Angry"},
        {value:"Sad",label:"Sad"},
        {value:"Disappointment",label:"Disappointment"},
        {value:"Concerned",label:"Concerned"},
        {value:"Scared",label:"Scared"}

      ],
      selectOptions2: [
        {value:"",label:"None"},
        {value:"loud",label:"Loud"},
        {value:"normal",label:"Normal"},
        {value:"soft",label:"Soft"},

      ],
      selectOptions3: [
        {value:"",label:"None"},
        {value:"fast",label:"Fast"},
        {value:"normal",label:"Normal"},
        {value:"slow",label:"Slow"},

      ],
      selectOptions4: [
        {value:"",label:"None"},
        {value:"Feeler",label:"Feeler"},
      ],
      clicked: false
    };


    constructor(props){
      super(props);
      this.updateOptions = this.update.bind(this);
      
    }   
    
    update = () => {
      let status = [{ value: 0, label: 'Open' },
      { value: 1, label: 'Pending' },
      { value: 2, label: 'Resolved' },
      { value: 3, label: 'Closed' },
      { value: 999999, label: 'All' }];

      this.props.customStatus.forEach((item) => {
        let obj = {value: item.status_code, label: item.status_name}
        status.push(obj);
      });

      this.setState({options:status});
    }    

    toggleOpen = () => {
        this.setState(state => ({ isOpen: !this.state.isOpen }));
      };

      openAddModal = () => {
        this.setState({addModalOpen: true});
      }


    handleChange = value => {

        this.toggleOpen();

        this.setState({ value });
        this.props.switchFilter(value.value);

        // if(value.value !== 5){
        //   this.setState({ value });
        //   this.props.switchFilter(value.value);
        // }
        // else{
        //   this.props.toggleFilterModal(true);
        // }
    };    

    handleSelectChange = selectedOption => {
      
      this.setState({ selectedOption: selectedOption.label,selectedValue:selectedOption.value });
      console.log(`Option selected:`, selectedOption);
      //this.props.setTicketStatus(this.props.id,selectedOption.value);
    };     
    
    handleSelectChange2 = selectedOption => {
      
      this.setState({ selectedEnergy: selectedOption.label,selectedEnergyValue:selectedOption.value });
      console.log(`Option selected:`, selectedOption);
      //this.props.setTicketStatus(this.props.id,selectedOption.value);
    };    

    handleSelectChange3 = selectedOption => {
      
      this.setState({ selectedPace: selectedOption.label,selectedPaceValue:selectedOption.value });
      console.log(`Option selected:`, selectedOption);
      //this.props.setTicketStatus(this.props.id,selectedOption.value);
    };    

    handleSelectChange4 = selectedOption => {
      
      this.setState({ selectedPersonality: selectedOption.label,selectedPersonalityValue:selectedOption.value });
      console.log(`Option selected:`, selectedOption);
      //this.props.setTicketStatus(this.props.id,selectedOption.value);
    };    

    filter = () => {
      this.setState({addModalOpen:false});
      this.props.loadCall(this.state.selectedValue,this.state.selectedEnergyValue,this.state.selectedPaceValue,this.state.selectedPersonalityValue);
    }

    closeAddModal = () => {
      this.setState({addModalOpen : false});
    }

    afterOpenModal = () => {

    }

    ticketTitleChanged = (e) => {
      this.setState({ticketTitle:e.target.value});
    }

    phoneChanged = (e) => {
      this.setState({phone:e.target.value});
    }

    emailChanged = (e) => {
      this.setState({email:e.target.value});
    }
    
    descriptionChanged = (e) => {
      this.setState({description:e.target.value});
    }    

    nameChanged = (e) => {
      this.setState({name:e.target.value});
    }      

    createTicket = () => {
      var error = false;
      if(this.state.ticketTitle === ""){
        this.setState({titleError : true});
        error = true;
      }
      else{
        this.setState({titleError : false});
      }
      if(this.state.phone === ""){
        this.setState({phoneError : true});
        error = true;
      }
      else{
        this.setState({phoneError : false});
      }     
      if(this.state.email === ""){
        this.setState({emailError : true});
        error = true;
      }
      else{
        this.setState({emailError : false});
      }      
      if(this.state.description === ""){
        this.setState({desError : true});
        error = true;
      }
      else{
        this.setState({desError : false});
      }     
      if(this.state.name === ""){
        this.setState({nameError : true});
        error = true;
      }
      else{
        this.setState({nameError : false});
      }              

      if(!error){
        this.setState({clicked:true});
        axios.post(this.host+'/api/ticket/create', {_token:this.getCookie("auth"),title:this.state.ticketTitle,channel:"MANUAL",email:this.state.email,ref:this.state.email,phone:this.state.phone,userId:this.state.email,name:this.state.name,des:this.state.description,department:this.state.selectedValue},{withCredentials: true})
        .then(res => {
          //console.log(res);
          //console.log(res.data);
          
  
          if(res.data.result === "success"){
            this.setState({clicked:false,addModalOpen:false});
          }
        }
        )         
      }
    }
      
    componentDidMount(){
      if(this.props.userId !== undefined){
        let obj = this.state.selectOptions;
        // axios.get(this.host+'/department/'+this.props.userId)
        // .then(res => {
        //     for(let i = 0; i < res.data.length; i++){
        //       obj.push({ value: res.data[i].id, label: res.data[i].name });
        //     }
        //     //console.log(obj);
        //     this.setState({selectOptions:obj})
        // })     
      }

      if(this.props.updateOption){
        this.props.updateOption(this.updateOptions);
      }
      

      let status = [{ value: 0, label: 'Open' },
      { value: 1, label: 'Pending' },
      { value: 2, label: 'Resolved' },
      { value: 3, label: 'Closed' },
      { value: 999999, label: 'All' }];
      if(this.props.customStatus){
        this.props.customStatus.forEach((item) => {
          let obj = {value: item.status_code, label: item.status_name}
          status.push(obj);
        });

        this.setState({options:status});        
      }
    }

    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    }    

    render() {
        const { isOpen, value } = this.state;
        return (
            <React.Fragment>
            <div className="left-panel-title-wrapper">
                <div className='left-panel-title full-width valign-wrapper'><span className={"full-width ellipsis-text "+(this.props.extraClass || "")} style={(this.props.dark ? {color: 'rgba(255,255,255,1)',minWidth:'1px'}: {color: '#000000',minWidth:'1px'})}>{this.props.title}</span>
                {
                  this.props.showCollapse ?
                  <div className="icon-button-hover-2 linked" data-balloon-pos="down-right" onClick={()=>{this.props.toggleExpand(this.props.index)}}>
                    {
                      this.props.expanded
                      ?
                        <ExpandLess />
                        :
                        <ExpandMore/>
                    }
                  </div>
                  :
                  null              
                }
                {/* {
                  this.props.toggleEmotion ?
                    <div className={"icon-button-hover-2 "+ (this.props.emotionVisible ? "focused" : "")} data-balloon-blunt aria-label="View Emotion Summary" data-balloon-pos="down-right" onClick={this.props.toggleEmotion}>
                      <SentimentSatisfiedAlt />
                    </div>
                  :
                  null
                }                 */}
                {
                  this.props.showAddButton ?
                  <div className="icon-button-hover-2" data-balloon-blunt aria-label="Filter" data-balloon-pos="down-right" onClick={this.openAddModal}>
                    <FilterList />
                  </div>
                  :
                  null
                }
                { this.props.showFilterButton === "true" ? 
                <React.Fragment>
                <div className={"icon-button-hover-2 hide "} data-balloon-blunt aria-label="Filter" data-balloon-pos="down-right" onClick={this.toggleOpen}>
                  <FilterList className="linked"/>
                  </div>
                  <Dropdown
                      isOpen={isOpen}
                      onClose={this.toggleOpen}
                      target={
                          <React.Fragment>
                          
                          </React.Fragment>
                      //   <Button
                      //     // iconAfter={<ChevronDown />}
                      //     onClick={this.toggleOpen}
                      //     isSelected={isOpen}
                      //   >
                      //     {/* {value ? `State: ${value.label}` : 'Select a State'} */}
                      //   </Button>
                      }
                  >
                    <Select
                        autoFocus
                        menuIsOpen
                        classNamePrefix="react-select"
                        styles={(this.props.dark ? customStylesDark : customStyles)}
                        value={this.state.options.filter((obj) => obj.value === this.props.filter)[0]}
                        onChange={this.handleChange}
                        options={this.state.options}
                        isSearchable={false}
                        components={{ DropdownIndicator, IndicatorSeparator: null }}
                    />     
                </Dropdown>                                     
                </React.Fragment>
                 : null}                
                
                </div>

            </div>
                    {
                      this.props.showAddButton ? 
                      <Modal
                      isOpen={this.state.addModalOpen}
                      onAfterOpen={this.afterOpenModal}
                      onRequestClose={this.closeAddModal}
                      style={modalStyles}
                      contentLabel="Example Modal"
                      ariaHideApp={false} 
                      closeTimeoutMS={200}       
                                                      
                      className={""+(this.props.dark ? "dark" : null)}   

                      
                    >
                      <div className="flex-col" style={{width:'100%',maxHeight:"100vh"}}>
                        <div className={"ng-modal-main-header"+(this.state.modalScrolled ? " scrolled" : "")}>
                          <h5 className="bold-text-2 smaller-font">Filter</h5>
                        </div>
                        <Scrollbars className='perfect-scrollbar' onScrollFrame={(e)=>{this.setState({modalScrolled:(e.top > 0)})}} style={{width:'500px',height:'500px'}}>
                        <div className="ng-modal-main-body padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x full-height">
                          
                          <h6>Emotion</h6>
                          <Select
                            classNamePrefix="react-select"
                            styles={this.props.dark ? selectStylesDark : selectStyles}
                            value={{label : this.state.selectedOption,value : this.state.selectedValue}}
                            onChange={this.handleSelectChange}
                            options={this.state.selectOptions}
                            isSearchable={false}
                          />                                                        
                          <h6>Energy</h6>
                          <Select
                            classNamePrefix="react-select"
                            styles={this.props.dark ? selectStylesDark : selectStyles}
                            value={{label : this.state.selectedEnergy,value : this.state.selectedEnergyValue}}
                            onChange={this.handleSelectChange2}
                            options={this.state.selectOptions2}
                            isSearchable={false}
                          />   
                          <h6>Pace</h6>
                          <Select
                            classNamePrefix="react-select"
                            styles={this.props.dark ? selectStylesDark : selectStyles}
                            value={{label : this.state.selectedPace,value : this.state.selectedPaceValue}}
                            onChange={this.handleSelectChange3}
                            options={this.state.selectOptions3}
                            isSearchable={false}
                          />  
                          <h6>Personality</h6>
                          <Select
                            classNamePrefix="react-select"
                            styles={this.props.dark ? selectStylesDark : selectStyles}
                            value={{label : this.state.selectedPersonality,value : this.state.selectedPersonalityValue}}
                            onChange={this.handleSelectChange4}
                            options={this.state.selectOptions4}
                            isSearchable={false}
                          />                                   
                        </div>
                        </Scrollbars>
                        <div className="ng-modal-main-footer right-text">                      
                          <button className="btn-flat ng-close-btn waves-effect waves-dark margin-right-1x" onClick={this.closeAddModal}>close</button>
                          <a className={"btn "+(this.state.clicked ? "disabled" : "")} onClick={this.filter}>Filter</a>
                          {/* <button className="btn waves-effect waves-light margin-left-1x" onClick={this.closeModal}>OK</button> */}
                        </div>
                      </div>
                    </Modal>

                      :
                      null
                    }
            </React.Fragment>
        )
    }
}

export default ChatListTitle
