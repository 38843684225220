import React from 'react'

import { Close,ForwardOutlined } from '@material-ui/icons';

export default function ForwardBox(props) {
    return (
        <div className="forward-box">
            <div className="forward-cancel-container">
                <div className="close-forward-icon icon-button-hover-2" onClick={props.closeForward}>
                    <Close/>
                </div>
            </div>
            <div className="forward-info-container full-width">
                <span>{props.selectedMessage.length} selected</span>
            </div>
            <div className="forward-action-container">
                <div className={("forward-icon icon-button-hover-2" + (props.selectedMessage.length === 0 ? " disabled" : ""))} onClick={props.openForward}>
                    <ForwardOutlined/>
                </div>
            </div>
        </div>
    )
}
