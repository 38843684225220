import React, { useEffect, useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import axios from 'axios';
// import '../css/score.css';
import './css/custom-dropdown.css'; 
// import { useLocation } from 'react-router-dom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export default function ScoreChartPage() {
  const [formValues, setFormValues] = useState({
    selectedProject: '',
    selectedCampaign: '',
    selectedTeam: '',
    selectedTimeframe: 'All', // Default value for timeframe
  });
  const [visible, setVisible] = useState('hide');
  const [data, setData] = useState([]);
  const [project, setProject] = useState([]);
  const [showProject, setShowProject] = useState(true);
  const [campaign, setCampaign] = useState([]);
  const [team, setTeam] = useState([]);
  const [defaultCampaign, setDefaultCampaign] = useState('');
  const [timeFrame, setTimeFrame] = useState('All');
  // const location = useLocation();


  function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
}    

  const token = getCookie("auth");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch project data
        const projectResponse = await axios.get('https://supercash.ai:8888/api/getProject?token=' + token);

        if (projectResponse.data === "hide") {
          setVisible(projectResponse.data);
        } 
        // IF PROJECT PERMISSION INVALID GET CAMPAIGN DATA
        else if (projectResponse.data === "Permission Invalid")
        {
          setShowProject(false);

          // Fetch campaign data
          const campaignResponse = await axios.get('https://supercash.ai:8888/api/getCampaign?token='+token);
            
          if (campaignResponse.data === "hide") {
            setVisible(campaignResponse.data);
          } else if(campaignResponse.data){
            console.log(campaignResponse.data);
            
            if(campaignResponse.data === "Permission Invalid"){
              setCampaign([]);
            }
            else{
              setCampaign(campaignResponse.data ?? []);
            }
            

            // return false;
            setVisible("show");
    
            // Set the default selectedCampaign to the first campaign data
            if (campaignResponse.data.length > 0) {
              // setDefaultCampaign(campaignResponse.data[0].id);
              const firstCampaignId = campaignResponse.data[0].id;

              setFormValues(prevValues => ({
                ...prevValues,
                selectedCampaign: firstCampaignId,
              }));

              // Fetch BWMVolumeDaily data using the default campaign
              const scoreResponse = await axios.get('https://supercash.ai:8888/api/getScore?token='+token+'&campaign_id='+firstCampaignId);

              // Fetch team data using the default campaign
              const teamResponse = await axios.get('https://supercash.ai:8888/api/getTeam?token='+token+'&campaign_id='+firstCampaignId);
              if(scoreResponse.data){
                if (scoreResponse.data === "hide") {
                  setVisible(scoreResponse.data);
                } else {
                  setData(scoreResponse.data);
                  setTeam(teamResponse.data);
                  setVisible("show");
                }
              }
            }
          }
        } 
        else 
        {
          setProject(projectResponse.data);
          setVisible("show");

          // Set the default projectResponse to the first project data
          if (projectResponse.data.length > 0) {
            // setDefaultCampaign(projectResponse.data[0].id);
            const firstProjectId = projectResponse.data[0].id;

            setFormValues(prevValues => ({
              ...prevValues,
              selectedProject: firstProjectId,
            }));

            // Fetch campaign data
            const campaignResponse = await axios.get('https://supercash.ai:8888/api/getCampaign?token='+token+'&project_id='+firstProjectId);
            
            if (campaignResponse.data === "hide") {
              setVisible(campaignResponse.data);
            } else {
              if(campaignResponse.data === "Permission Invalid"){
                setCampaign([]);
              }
              else{
                setCampaign(campaignResponse.data ?? []);
              }
              setVisible("show");
      
              // Set the default selectedCampaign to the first campaign data
              if (campaignResponse.data.length > 0) {
                // setDefaultCampaign(campaignResponse.data[0].id);
                const firstCampaignId = campaignResponse.data[0].id;

                setFormValues(prevValues => ({
                  ...prevValues,
                  selectedCampaign: firstCampaignId,
                }));

                // Fetch BWMVolumeDaily data using the default campaign
                const scoreResponse = await axios.get('https://supercash.ai:8888/api/getScore?token='+token+'&campaign_id='+firstCampaignId);

                // Fetch team data using the default campaign
                const teamResponse = await axios.get('https://supercash.ai:8888/api/getTeam?token='+token+'&campaign_id='+firstCampaignId);
        
                if (scoreResponse.data === "hide") {
                  setVisible(scoreResponse.data);
                } else {
                  setData(scoreResponse.data.datasets);
                  setTeam(teamResponse.data);
                  setVisible("show");
                }
              }
            }
          }
        }
      } catch (error) {
        // Handle errors
        console.error(error);
      }
    };
  
    fetchData();
  }, [token, defaultCampaign]);

  // useEffect(() => {
  //   // Fetch posts from the backend API
  //   axios.get('https://supercash.ai:8888/api/getScore')
  //     .then(response => {
  //       // console.log(response)
  //       // Update the state with the fetched data
  //       setData(response.data);
  //     })
  //     .catch(error => {
  //       // Handle errors
  //       console.error(error);
  //     });
  // }, []);

  const dataScatter = {
    datasets:data,
  };

  const options = {
    
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        title: {
          display: true,
          text: 'Time (Hour)',
        },
        ticks: {
          precision: 0, // Set the number of decimal places to 0
          maxTicksLimit: 24,
        },
      },
      y: {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Score',
        },
        ticks: {
          precision: 0, // Set the number of decimal places to 0
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'QA Scoring Plot (' + timeFrame + ')', // Set the title for your chart
        font: {
          size: 16, // Adjust the font size as needed
        },
      },
      tooltip: {
        
        events: "click",
        enabled: false, // Disable default tooltips
        external: (context) => {
          const tooltipEl = document.getElementById('chartjs-tooltip');
          if (!tooltipEl) {
            return;
          }
  
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }
          
          // console.log(tooltipModel);
          // Customize tooltip content
          let innerHTML = '<div>';

          tooltipModel.dataPoints.forEach((dataPoint) => {
            innerHTML += `
              <div class="mb-3">
                User: ${dataPoint.dataset.label}
                </br>
                Score: ${dataPoint.raw.y}
                </br>
                Time: ${dataPoint.raw.x}
                </br>
                Color: <span style="border:1.5px solid ${dataPoint.dataset.pointBorderColor};background-color:${dataPoint.dataset.backgroundColor};width:10px;height:10px;display:inline-block;"></span>
                </br>
                <a href="https://ticket-api.superceed.com/api/call/get_info?id=${dataPoint.raw.ticket_id}" target="_blank" style="color:white">Link</a>
              </div>
            `;
          });
      
          innerHTML += '</div>';
      
          tooltipEl.innerHTML = innerHTML;
  
          // Set tooltip position
          const position = tooltipModel.chart.canvas.getBoundingClientRect();
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = 0 + 'px';
          tooltipEl.style.top = 0 + 'px';
          tooltipEl.style.background = "rgba(51, 51, 51, 0.9)";
          tooltipEl.style.color = 'white';
          tooltipEl.style.padding = '5px';
          tooltipEl.style.borderRadius = '0px 5px 5px 5px';
        },
      },
      events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    },
  };

  useEffect(() => {
    const tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    document.body.appendChild(tooltipEl);

    return () => {
      document.body.removeChild(tooltipEl);
    };
  }, []);
  
  // const handleTimeframeChange = (event) => {
  //   const selectedTimeframe = event.target.value;
  //   setTimeFrame(selectedTimeframe);

  //   // Depending on the selected timeframe, update your chart data accordingly.
  //   switch (selectedTimeframe) {
  //     case 'All':
  //       axios.get('https://supercash.ai:8888/api/getScore')
  //         .then((response) => {
  //           setData(response.data);
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //         });
  //       break;
  //     case 'Daily':
  //       axios.get('https://supercash.ai:8888/api/getScoreDaily')
  //         .then((response) => {
  //           setData(response.data);
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //         });
  //       break;
  //     case 'Last 7 Days':
  //       axios.get('https://supercash.ai:8888/api/getScoreLast7Days')
  //         .then((response) => {
  //           setData(response.data);
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //         });
  //       break;
  //     case 'Weekly':
  //       axios.get('https://supercash.ai:8888/api/getScoreWeekly')
  //         .then((response) => {
  //           setData(response.data);
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //         });
  //       break;
  //     case 'Monthly':
  //       axios.get('https://supercash.ai:8888/api/getScoreMonthly')
  //         .then((response) => {
  //           setData(response.data);
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //         });
  //       break;
  //     default:
  //       break;
  //   }
  // }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    // console.log("Selected Campaign changed:", value);
    // Define an async function for the API request

    if (name === 'selectedProject') {
      const fetchData = async () => {
        try {
          const campaignResponse = await axios.get('https://supercash.ai:8888/api/getCampaign?token='+token+'&project_id='+value);
          // Process the API response as needed
          if(campaignResponse.data === "Permission Invalid"){
            setCampaign([]);
          }
          else{
            setCampaign(campaignResponse.data ?? []);
          }
          if (campaignResponse.data.length > 0) {
            const campaignId = campaignResponse.data[0].id;
            const teamResponse = await axios.get('https://supercash.ai:8888/api/getTeam?token='+token+'&campaign_id='+campaignId);
            setTeam(teamResponse.data);

            setFormValues(prevValues => ({
              ...prevValues,
              selectedCampaign: campaignId,
            }));
          }
        } catch (error) {
          // Handle API request errors
          console.error('API Request Error:', error);
        }
      };

      // Call the async function immediately
      fetchData();
    }

    if (name === 'selectedCampaign') {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://supercash.ai:8888/api/getTeam?token='+token+'&campaign_id='+value);
          // Process the API response as needed
          setTeam(response.data);
        } catch (error) {
          // Handle API request errors
          console.error('API Request Error:', error);
        }
      };

      // Call the async function immediately
      fetchData();
    }

  };

  const handleSearch = (event) => {
    event.preventDefault();

    const selectedCampaign = formValues.selectedCampaign;
    const selectedTeam = formValues.selectedTeam;
    const selectedTimeframe = formValues.selectedTimeframe;
    setTimeFrame(selectedTimeframe);

    // Depending on the selected timeframe, update your chart data accordingly.
    switch (selectedTimeframe) {
      case 'All':
        axios.get('https://supercash.ai:8888/api/getScore?token='+token+'&campaign_id='+selectedCampaign+'&team_id='+selectedTeam)
          .then((response) => {
            setData(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
        break;
      case 'Daily':
        axios.get('https://supercash.ai:8888/api/getScoreDaily?token='+token+'&campaign_id='+selectedCampaign+'&team_id='+selectedTeam)
          .then((response) => {
            setData(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
        break;
      case 'Last 7 Days':
        axios.get('https://supercash.ai:8888/api/getScoreLast7Days?token='+token+'&campaign_id='+selectedCampaign+'&team_id='+selectedTeam)
          .then((response) => {
            setData(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
        break;
      case 'Weekly':
        axios.get('https://supercash.ai:8888/api/getScoreWeekly?token='+token+'&campaign_id='+selectedCampaign+'&team_id='+selectedTeam)
          .then((response) => {
            setData(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
        break;
      case 'Monthly':
        axios.get('https://supercash.ai:8888/api/getScoreMonthly?token='+token+'&campaign_id='+selectedCampaign+'&team_id='+selectedTeam)
          .then((response) => {
            setData(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
        break;
      default:
        break;
    }
  };

  return (
    // <div style={containerStyle}>
    <Container className='mt-3'>
      {visible === "show" ? 
      <>
      <Form onSubmit={handleSearch}>
          <Row>
            {showProject && (
              <Col className='col s3' lg={3}>
                <Form.Label>Project</Form.Label>
                <Form.Select
                  className='browser-default'
                  name="selectedProject"
                  onChange={handleInputChange}
                  value={formValues.selectedProject ?? ''}
                >
                  {project.map(({ id, project_name }) => (
                    <option key={id} value={id}>
                      {project_name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            )}
            <Row>
            <Col className='col s3' lg={3}>
              <Form.Label>Campaign</Form.Label>
              <Form.Select
                className='browser-default'
                name="selectedCampaign"
                onChange={handleInputChange}
                value={formValues.selectedCampaign ?? ''}
              >
                {/* <option value="">Select A Campaign</option> */}
                {campaign.map(({ campaign_id, campaign_name, id }) => (
                  <option key={id} value={id}>
                    {campaign_id} - {campaign_name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col className='col s3' lg={3}>
              <Form.Label>Team</Form.Label>
              <Form.Select
                className='browser-default'
                name="selectedTeam"
                onChange={handleInputChange}
                value={formValues.selectedTeam ?? ''}
              >
                {team.map(({ team_id, team_name }) => (
                  <option key={team_id} value={team_id}>
                    {team_name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col className='col s3' lg={3}>
              <Form.Label>Time Frame</Form.Label>
              <Form.Select
                className='browser-default'
                name="selectedTimeframe"
                onChange={handleInputChange}
                value={formValues.selectedTimeframe ?? "All"}
              >
                <option value="All">All</option>
                <option value="Daily">Daily</option>
                <option value="Last 7 Days">Last 7 days</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
              </Form.Select>
            </Col>
            <Col className='col s3' lg={3}>
              <Button variant="primary" type="submit" className="w-100">
                Search
              </Button>
            </Col>
          </Row>
          </Row>
        </Form>
        <Row>
          <Col lg={12} className='mx-auto'>
              <Scatter data={dataScatter} options={options} />
              <div id="chartjs-tooltip"></div>
            {/* </div> */}
          </Col>
        </Row>
      </>
      :
      <Row>
        <Col lg={2} className='mx-auto'>
          <p>INVALID PERMISSION</p>
        </Col>
      </Row>
      
      }
    </Container>
  );
}