import React, { Component } from 'react'
import Variable from './../variable';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Moment from 'react-moment';
import moment from 'moment';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown'
import { ResponsiveBar } from '@nivo/bar'
import EmotionChart from './../components/EmotionChart';
import Circular from './../components/Circular';
import {NavigateNext,NavigateBefore} from '@material-ui/icons'

import StackedBarChart from './../components/StackedBarChart';
import { isObject } from 'lodash';
import emoji from 'react-easy-emoji'


const emotionEmoji = {
  "Angry" : "😡",
  "Happy" : "😀",
  "Sad":"😢",
  "Surprised":"😲",
  "Scared":"😨",
  "Disgust":"🤮",
  "Calm":"🙂",
  "Disappointment":"😞",

}

export default class Supervisory extends Component {

    host = Variable.host;

    constructor(props){
      super(props);
      this.state = {
        pbx:[],
        references:{},
        agentPool:[],
        sip:[],
        emotionState:{},
        contextState:{},
        personalityState:{},
      }
    }

    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
  }

    componentDidMount(){
      axios.get(this.host+'/api/sip/users', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
      .then(res => {
        this.setState({sip:res.data});
      })

      // this.props.socket.on("textEmotionApi", this.emotionApi);

      this.props.socket.on("contextApi", this.contextApi);

      // this.props.socket.on("personalityApi", this.personalityApi);

      // setTimeout(()=>{
      //   console.log("emmited");
      //   this.props.socket.emit("sendContextApi",{context:{context:"Testing",extension_id:"3232"},id:2});
      // },2000)
      

      // axios.get(this.host+'/api/proxy/agent_pool', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
      // .then(res => {
      //   this.setState({agentPool:res.data});
      // })
    }

    componentWillUnmount(){
      // this.props.socket.off("textEmotionApi", this.emotionApi);
      this.props.socket.off("contextApi", this.contextApi);
      // this.props.socket.off("personalityApi", this.personalityApi);
    }

    emotionApi = (data) => {
      if(data.source === "agent"){
        let emotionState = this.state.emotionState;
        emotionState[data.extension_id] = data.text_emotion;
        this.setState({emotionState:emotionState});
      }
    }

    contextApi = (data) => {
      console.log(data);
      if(data.context){
        let contextState = this.state.contextState;
        contextState[data.context.extension_id] = data.context.context;
        this.setState({contextState:contextState});
      }
      else if (data.emotion){
        let emotionState = this.state.emotionState;
        emotionState[data.emotion.extension_id] = data.emotion.emotion;
        this.setState({emotionState:emotionState});
      }
      else if (data.personality){
        let personalityState = this.state.personalityState;
        personalityState[data.personality.extension_id] = data.personality.personality;
        this.setState({personalityState:personalityState});
      }

    }

    personalityApi = (data) => {
      console.log(data);
      let personalityState = this.state.personalityState;
      personalityState[data.personality.extension_id] = data.personality;
      this.setState({personalityState:personalityState});

    }

  
    keyPress = (obj) => {
      if(obj !== null){
        return obj.map((item,i)=>(
          <div className='nested-keypress'>
            <div>
              <h6>Option String</h6>
              <p>{item.optionString}</p>
              <h6>Reference</h6>
              <select className='browser-default' value={item.referenceTableID} onChange={(event)=>{
                            
                item.referenceTableID = event.target.value;

                this.setState({pbx:this.state.pbx});
              }}>
                <option value="0">None</option>
                {
                  this.state.agentPool.map((pool,i)=>(
                    <option value={pool.ID}>{pool.description}</option>
                  ))
                }
              </select>
              <h6>Return To menu</h6>
              <select className='browser-default' value={item.returnToMenu} onChange={(event)=>{
                  
                  item.returnToMenu = event.target.value;

                  this.setState({pbx:this.state.pbx});
                }}>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
              </select>
              <h6>Service Code</h6>
              <select className='browser-default' value={item.serviceCode.trim()} onChange={(event)=>{
                            
                  item.serviceCode = event.target.value;

                  this.setState({pbx:this.state.pbx});
                }}>
                  <option value="">None</option>
                  {
                    this.props.references.serviceType.map((service,i)=>(
                      <option value={service.serviceCode.trim()}>{service.description}</option>
                    ))
                  }
                </select>
            </div>
            {this.keyPress(item.children)}
          </div>
        ))
      }
      else{
        return null;
      }
    }

getEmoji = (text) => {
  return emoji(text,{
    props:{class:"emoji-image"}
  });      
}


    render(){
        return (
            <div className='full-width flex-col'>
              <div className={" flex-row padding-left-2x padding-right-2x flex-no-shrink header-border-bottom"} style={{height:'64px',alignItems:'center',position:"relative",backgroundColor:this.props.dark ? '#333' :'#ffffff'}}>
                <h5 className='bold-text-2'>Supervisory</h5>
              </div>
                       {
<div className=' padding-top-2x full-width padding-right-2x padding-left-2x overflow-x padding-bottom-2x'>
<table className='no-border round-card z-depth-1 material-table supervisory-table'>
<thead>
  <tr>
    <th className='padding-left-2x padding-right-2x'>ID</th>
    <th className='padding-left-2x padding-right-2x'>User</th>
    <th className='padding-left-2x padding-right-2x'>SIP</th>
    <th className='padding-left-2x padding-right-2x'>Emotion</th>
    <th className='padding-left-2x padding-right-2x'>Context</th>
    <th className='padding-left-2x padding-right-2x'>Personality</th>
    {/* <th className='padding-left-2x padding-right-2x'>Action</th> */}
  </tr>                  
</thead>
<tbody>
{
                this.state.sip.map((obj,i)=>(
                  // <>

                        <tr className={(this.state.emotionState[obj.sip_number]  === "Angry" ? "red lighten-3" : "")}>
                          <td className='padding-left-2x paddding-right-2x'>{i + 1}</td>
                          <td>{obj.name}</td>
                          <td>{obj.sip_number}</td>
                          <td>{this.state.emotionState[obj.sip_number] ?  this.getEmoji(emotionEmoji[this.state.emotionState[obj.sip_number]] ?? "") :"" }</td>
                          <td>{this.state.contextState[obj.sip_number] ?  this.state.contextState[obj.sip_number] :"" }</td>
                          <td>{this.state.personalityState[obj.sip_number] ?  this.state.personalityState[obj.sip_number] :"" }</td>
                          {/* <td>
                            <button className='button-new'>Spy</button>
                            <button className='button-new'>Whisper</button>
                            <button className='button-new'>Barge</button>
                          </td> */}
                        </tr>
                  // </>
                ))
                  }
                </tbody>
              </table>
              </div>
              }
            </div>
        );
    }
}