import React, { Component } from 'react'
import axios from 'axios';
import {Search} from '@material-ui/icons'
import GiphyIcon from './../../../images/Poweredby_100px-Black_VertText.png';
//import GiphyIconDark from './../../../images/Poweredby_100px-White_VertText.png';
//import VisibilitySensor from 'react-visibility-sensor';
import Variable from './../../../variable';

import Form from 'react-bootstrap/Form'

import { connect } from 'react-redux';


export class CustomEmojiPicker extends Component {

    host = Variable.host;

    state = {
        categories : [],
        search : [],
        keyword : "",
        trendingImage : "",
        firstLoad: true,
        giphy:[],
        mode: "tenor",
        giphyTrending: [],
        emoji:{},
        placeholder:"Search",
        value:0,
        value2:0,
        value3:0
    }

    mounted = false;
    searchTimeout;

    constructor(props){
        super(props);
        this.load = this.loadCategory.bind(this);
    }

    componentDidMount(){
        this.mounted = true;
        //this.props.loadCategory(this.load);
        if(this.mounted){
            let filter = Object.entries(this.props.emoji).filter(([key,value])=>key.indexOf("") !== -1);
            this.setState({emoji:filter});
        }
        
        //this.loadCategory();
    }

    componentWillUnmount(){
        this.mounted = false;     
    }

    valueChanged = (e) => {
        this.props.thresholdChanged(e.target.value);
        this.setState({value:e.target.value})
    }

    valueChanged2 = (e) => {
        this.props.thresholdChanged2(e.target.value);
        this.setState({value2:e.target.value})
    }

    valueChanged3 = (e) => {
        this.props.thresholdChanged3(e.target.value);
        this.setState({value3:e.target.value})
    }

    valueChanged4 = (e) => {
        this.props.thresholdChanged4(e.target.value);
        this.setState({value4:e.target.value})
    }

    valueChanged5 = (e) => {
        this.props.thresholdChanged5(e.target.value);
        this.setState({value5:e.target.value})
    }

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }    

    loadCategory = () => {
        if(this.state.firstLoad){
            this.setState({firstLoad:false})
            axios.get('https://api.tenor.com/v1/categories?key=1QITS94KC2SS')
            .then(res => {
        
            if(this.mounted){
                this.setState({categories:res.data.tags});
            }
        
            })
            .catch(err =>{
            console.log(err);
            });   
            
            axios.get('https://api.tenor.com/v1/trending?key=1QITS94KC2SS&limit=1&locale=en_US&media_filter=minimal')
            .then(res => {
        
            if(this.mounted){
                this.setState({trendingImage:res.data.results[0].media[0].gif.url});
            }
        
            })
            .catch(err =>{
            console.log(err);
            });     
        }     
    }

    search = (text) => {
        let filter = Object.entries(this.props.emoji).filter(([key,value])=>key.indexOf(text) !== -1);
        console.log(filter);
        this.setState({emoji:filter})
    } 
    
    textboxChaged = (e) => {
        this.setState({keyword:e.target.value})
        clearTimeout(this.searchTimeout);
        if(e.target.value.length > 0){
            this.searchTimeout = setTimeout(()=>{
                console.log(this.state.keyword);
                this.search(this.state.keyword);
            },50);
        }
        else{
            let filter = Object.entries(this.props.emoji).filter(([key,value])=>key.indexOf("") !== -1);
            this.setState({emoji:filter,placeholder:"Search"});
        }
    }

    jumpToCategory = (value) => {
        this.setState({search:[],giphy:[]});
        this.searchBox.value = value;
        this.setState({keyword:value});
        this.search(value);
    }

    switchMode = (mode) => {
        this.setState({search:[],giphy:[],mode:mode});
        this.searchBox.value = ""; 
        this.setState({keyword:""});  
        if(mode === "giphy"){
            if(this.state.giphyTrending.length === 0){
                setTimeout(()=>{
                    this.search("trending");
                },100)
            
            }
            else{
                this.setState({giphy:this.state.giphyTrending});
            }
            
        }     
    }

    render() {
        return (
            <div className={"call-settings-holder flex-col z-depth-1 "+(this.props.dark ? "grey darken-3" : "white")} style={{borderRadius:'5px'}}>
                <h6>{this.state.value5}</h6>
                <Form>
                    <Form.Group controlId="formBasicRange">
                        <Form.Label>Recommendation</Form.Label>
                        <Form.Control min={0} step={0.1} max={1} value={this.props.value.threshold5} type="range" onChange={this.valueChanged5}/>
                    </Form.Group>
                </Form> 
                {/* <h6>{this.state.value4}</h6> */}
                {/* <Form>
                    <Form.Group controlId="formBasicRange">
                        <Form.Label>Negative</Form.Label>
                        <Form.Control min={0} step={0.1} max={1} value={this.props.value.threshold4} type="range" onChange={this.valueChanged4}/>
                    </Form.Group>
                </Form> 
                <h6>{this.state.value3}</h6>
                <Form>
                    <Form.Group controlId="formBasicRange">
                        <Form.Label>Emotion</Form.Label>
                        <Form.Control min={0} step={0.1} max={1} value={this.props.value.threshold3} type="range" onChange={this.valueChanged3}/>
                    </Form.Group>
                </Form>                  
                <h6>{this.state.value2}</h6>
                <Form>
                    <Form.Group controlId="formBasicRange">
                        <Form.Label>Context</Form.Label>
                        <Form.Control min={0} step={0.1} max={1} value={this.props.value.threshold2} type="range" onChange={this.valueChanged2}/>
                    </Form.Group>
                </Form>                
                <h6>{this.state.value}</h6>
                <Form>
                    <Form.Group controlId="formBasicRange">
                        <Form.Label>KB</Form.Label>
                        <Form.Control min={0} step={0.1} max={1} value={this.props.value.threshold} type="range" onChange={this.valueChanged}/>
                    </Form.Group>
                </Form> */}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ( {
    thresholdChanged: (value) => 
    {
        dispatch({type: 'threshold',value:value});
    },
    thresholdChanged2: (value) => 
    {
        dispatch({type: 'threshold2',value:value});
    },
    thresholdChanged3: (value) => 
    {
        dispatch({type: 'threshold3',value:value});
    }
    ,
    thresholdChanged4: (value) => 
    {
        dispatch({type: 'threshold4',value:value});
    }
    ,
    thresholdChanged5: (value) => 
    {
        dispatch({type: 'threshold5',value:value});
    }
  } )
  const mapStateToProps = ( state ) => ( {
    value: state
} )


export default connect(mapStateToProps,mapDispatchToProps)(CustomEmojiPicker)