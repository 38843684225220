
import React, { useState,useEffect,useRef } from 'react'
import { useSelector } from 'react-redux'

function NewVersion(props){
    const newVersion = useSelector(state => state.newVersion);
    //console.log( useSelector(state => state));
    return newVersion ? 
        <div className="notification-bar-3 new-version-bar valign-wrapper">
            <h6 className="white-text full-width margin-left-1x" style={{lineHeight:'0'}}>New version of Hyperceed is available.</h6>
            <a className="margin-right-1x btn waves-effect waves-light indigo lighten-1 notification-bar-btn" onClick={()=>{window.location.reload()}}>Refresh</a>
        </div>
    :
    null
}

export default NewVersion;