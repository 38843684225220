import React, { Component } from 'react'
import SendIcon from '@material-ui/icons/SendRounded'
import FileIcon from '@material-ui/icons/InsertDriveFileOutlined'
import CloseIcon from '@material-ui/icons/Cancel'
import ArrowUp from '@material-ui/icons/KeyboardArrowDown'
import { BrushOutlined,FormatBold,FormatItalic,FormatUnderlined,CallOutlined,CallEndOutlined,GridOn  } from '@material-ui/icons'
// import EmojiPicker from 'emoji-picker-react';
import { Emoji } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css'
// import { Picker } from 'emoji-mart'
import CustomEmojiPicker from './Slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSmile,faFile,faStickyNote,faPencilRuler,faPhoneAlt,faPhoneSlash,faTh,faSlidersH } from '@fortawesome/free-solid-svg-icons'
//import { file } from '@babel/types';
import Img from 'react-image';
import axios from 'axios';
import { renderToString } from 'react-dom/server';
import Test from './Test'
//import CustomEmoji from './../conversation/CustomEmoji2';
import stringReplace from 'react-string-replace';
// import ContentEditable from 'react-contenteditable'
import Editor,{createEditorStateWithText} from 'draft-js-plugins-editor';
import {EditorState,convertToRaw,ContentState,ContentBlock,Modifier,SelectionState,getDefaultKeyBinding,convertFromHTML,create,KeyBindingUtil,RichUtils} from 'draft-js';
import {convertToHTML} from 'draft-convert'
import Immutable from 'immutable'
// import {stateFromHTML} from 'draft-js-import-html';
// import {stateToHTML} from 'draft-js-export-html';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import { ItalicButton, BoldButton, UnderlineButton } from "draft-js-buttons";
import createEmojiPlugin from 'draft-js-emoji-plugin';
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
import 'draft-js-emoji-plugin/lib/plugin.css';
import 'draft-js-mention-plugin/lib/plugin.css';
import 'draft-js-inline-toolbar-plugin/lib/plugin.css';
import createEmojiMartPlugin from 'draft-js-emoji-mart-plugin';
//import CaretPositioning from './EditCaretPosition'
import Variable from './../../../variable';
import data from 'emoji-mart/data/emojione.json';
import EmojiConverter from 'emoji-js';
import DialPad from './DialPad';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IconAdjustmentsHorizontal,IconDialpad,IconPhone,IconPhoneOff,IconMicrophone,IconMicrophoneOff, IconNotebook } from '@tabler/icons-react';
import ProgressBar from 'react-bootstrap/ProgressBar'


class CustomActionButton extends Component {
    // When using a click event inside overridden content, mouse down
    // events needs to be prevented so the focus stays in the editor
    // and the toolbar remains visible  onMouseDown = (event) => event.preventDefault()
    onMouseDown = (event) => {
        event.preventDefault()
    }
  
    onClick = (e) => {
        this.props.action(this.props.type)
    }

    getComponent = (type) => {
        switch(type){
            case "highlight":

            return <BrushOutlined/>
            case "bold":

            return <FormatBold/>
            case "underline":

            return <FormatUnderlined/>         
            case "italic":

            return <FormatItalic/>   
        }
    }

    render() {
        return (
          <div className="inline-block" onMouseDown={this.onMouseDown}>
            <button className="inline-toolbar-button icon-button-hover-2" onClick={this.onClick}>
              {this.getComponent(this.props.type)}
            </button>
          </div>
        );
      }
    }    

const inlineToolbarPlugin = createInlineToolbarPlugin(
    {structure: [    
        BoldButton,
        ItalicButton,
        UnderlineButton,
        CustomActionButton
    ]
});



//import DropFile from './DropFile';


const styleMap = {
    'highlight': {
      'backgroundColor': '#faed27',
      'color':'#545454'
    },
    'strikethrough': {
        'textDecoration': 'line-through',
    },
    'bold': {
        'fontWeight': 'bold',
    }
    ,
    'underline': {
        'textDecoration': 'underline',
    },
    'italic': {
        'fontStyle': 'italic',
    }
};

let convertOptions = {
    inlineStyles: {
      // Override default element (`strong`).
      bold: {element: 'b'},
      italic: {
        // Add custom attributes. You can also use React-style `className`.
        // attributes: {class: 'foo'},
        // Use camel-case. Units (`px`) will be added where necessary.
        style: {fontStyle: 'italic'}
      },
      // Use a custom inline style. Default element is `span`.
      underline:{style: {textDecoration: 'underline'}},
      red: {style: {color: '#900'}},
      highlight: 
        {style: {backgroundColor: '#faed27',color:'#545454'},
      }
    },
  };


const blockRenderMap = Immutable.Map({
    'emoji-span': {
      element: 'span',
    },
    'unstyled': {
        element: 'span'
    }    
});

const template = [
    "Good day\n\n \n\nBelow are a few templates & actual format (attached):-\n\n \n\nTemplate :\n\nPatient: (Contact)\n\nDOB: (DOB)\n\nNRIC: (NRIC)\n\n \n\nScheduled with (Doctor)\n\nAppointment Date : (AppointmentDate)\n\nAppointment Time : (AppointmentTime)\n\n \n\nThank you.\n\n \n\nRegards\n\nPHKL Support Centre\n\nPantai Hospital Kuala Lumpur\n\nTel:+0603 2296 0888\n\nwww.pantai.com.my\/kuala-lumpur",
    "Dear (Contact),\n\n \n\nKindly refer to the following as a gentle reminder for your appointment scheduled with Pantai Hospital Kuala Lumpur.\n\n \n\nAppointment Date : (AppointmentDate)\n\nAppointment Time : (AppointmentTime)\n\nHSC Package : {Case{HSCAppointment\n\n \n\nRegards\n\nPHKL Support Centre\n\nPantai Hospital Kuala Lumpur\n\nTel:+0603 2296 0888\n\nwww.pantai.com.my\/kuala-lumpur",
    "Dear (Contact),\n\nYou are scheduled with (Doctor)\n\nAppointment Date : (Appointment Date) as First Come First Basis\n\nFor any assistance, please contact us at +603-2296 0888.\n\nThank you. \n\nBest Regards,\n\nPantai Hospital Kuala Lumpur\n\nTel:+0603 2296 0888\n\nwww.pantai.com.my\/kuala-lumpur",
    "Dear Valuable Customer,\n\n \n\nKindly click the link below for a quick Pantai Hospital Kuala Lumpur customer service survey.\n\n \n\nThanking you in advance\n\n \n\nClick Me for survey\n\n \n\n \n\nBest Regards,\n\nPHKL Support Centre\n\nPantai Hospital Kuala Lumpur\n\nTel:+0603 2296 0888\n\nwww.pantai.com.my\/kuala-lumpur",
    "Dear <NAME>,\n\nThank you for choosing Pantai Hospital Kuala Lumpur for your healthcare needs.Before we make an appointment for you, we would like to know your present medical condition as its a requirement by all of our O&G consultants.\n\nThank you and awaiting for your reply. \n\nRegards,\n\n(Name)\n\nPHKL Support Centre\n\nPantai Hospital Kuala Lumpur\n\nTel:+0603 2296 0888\n\nwww.pantai.com.my\/kuala-lumpur",
    "Dear <NAME>,\n\nThank you for choosing Pantai Hospital Kuala Lumpur for your healthcare needs.\n\nWe regret to inform you that <NAME> is on leave until XXth April 2019. We have selected another earliest date for you. If its not suitable, kindly revert.\n\nWe are pleased to confirm your earliest outpatient consultation per below details.\n\nConsultant\n\nSuite No.\n\nDate\n\nTime\n\nContact No\n\n Note:\n\n1)You may bring along any x-rays or reports for discussion with the doctor.\n\n2) Patients using insurance coverage are to bring their Referral Letter from a panel clinic\/Guarantee Letter from their insurance  provider.\n\n3) Should you require urgent medical attention or your current medical symptoms are causing you discomfort kindly proceed to     our Accident & Emergency Unit which is open 24hrs.\n\nPlease let us know if you require further information \/ assistance. Thank you. \n\nRegards,\n\n(Name)\n\nPHKL Support Centre\n\nPantai Hospital Kuala Lumpur\n\nParkway Pantai\n\nTel:+0603 2296 0888\n\nwww.pantai.com.my\/kuala-lumpur",
    "Dear \n\nWe would like to send a reminder in regards to the initial appointment inquiry \/request.Please advise if we can proceed to book an appointment for you?\n\nHope to hear from you soon.Feel free to contact us for further assistance. \n\nThank you for choosing Pantai Hospital Kuala Lumpur.\n\nRegards,\n\nPHKL Support Centre\n\nPantai Hospital Kuala Lumpur\n\nTel:+0603 2296 0888\n\nwww.pantai.com.my\/kuala-lumpur"

];


const emojiMartPlugin = createEmojiMartPlugin({
    sheetSize:64,
    data,
    set: 'emojione'
  });

const emojiPlugin = createEmojiPlugin(
    {
        useNativeArt: false,
        positionSuggestions: (settings) => {
          return {
            left: settings.decoratorRect.left + 'px',
            bottom: 0 + 'px', // change this value (40) for manage the distance between cursor and bottom edge of popover
            display: 'block',
            transform: 'scale(1)', // transition popover on the value of its height
            zIndex: 11,
          };
        }    
    });

const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

const { Picker } = emojiMartPlugin;

const { InlineToolbar } = inlineToolbarPlugin;
const plugins = [inlineToolbarPlugin,emojiMartPlugin];

export class ChatBox extends Component {

    host = Variable.host;

    setEmojiText = () => {

    }
    


    constructor(props){
        super(props);
        this.emoji = this.props.emojiSet;
        this.state = {
            mute:false,
            callStatus:"ready",
            editorState: createEditorStateWithText(''),
            emojiLoaded:false,
            emojiSet:[],
            drag:false,
            emojiPickerShow:false,
            customEmojiPickerShow:false,
            gifPickerShow:false,
            blobs: [],
            upload : [],
            cursorPos : 0,
            cursorPosEnd : 0,
            sendIconDisabled : true,
            inputValue : "",
            location: [
                {
                  id: 0,
                  title: 'New York',
                  selected: false,
                  key: 'location'
                },
                {
                  id: 1,
                  title: 'Dublin',
                  selected: false,
                  key: 'location'
                },
                {
                  id: 2,
                  title: 'Istanbul',
                  selected: false,
                  key: 'location'
                }
              ],
              fruit: [
                {
                  id: 0,
                  title: 'Apple',
                  selected: false,
                  key: 'fruit'
                },
                {
                  id: 1,
                  title: 'Orange',
                  selected: false,
                  key: 'fruit'
                },
                {
                  id: 2,
                  title: 'Strawberry',
                  selected: false,
                  key: 'fruit'
                }
              ]     ,
              suggestions: [
                {
                  name: 'Matthew Russell',
                  link: 'https://twitter.com/mrussell247',
                  avatar: 'https://pbs.twimg.com/profile_images/517863945/mattsailing_400x400.jpg',
                },
                {
                  name: 'Julian Krispel-Samsel',
                  link: 'https://twitter.com/juliandoesstuff',
                  avatar: 'https://avatars2.githubusercontent.com/u/1188186?v=3&s=400',
                },
                {
                  name: 'Jyoti Puri',
                  link: 'https://twitter.com/jyopur',
                  avatar: 'https://avatars0.githubusercontent.com/u/2182307?v=3&s=400',
                },
                {
                  name: 'Max Stoiber',
                  link: 'https://twitter.com/mxstbr',
                  avatar: 'https://pbs.twimg.com/profile_images/763033229993574400/6frGyDyA_400x400.jpg',
                },
                {
                  name: 'Nik Graf',
                  link: 'https://twitter.com/nikgraf',
                  avatar: 'https://avatars0.githubusercontent.com/u/223045?v=3&s=400',
                },
                {
                  name: 'Pascal Brandt',
                  link: 'https://twitter.com/psbrandt',
                  avatar: 'https://pbs.twimg.com/profile_images/688487813025640448/E6O6I011_400x400.png',
                },
              ],
              phoneNumber:""   
        }
        this.mentionPlugin = createMentionPlugin(
            {
                positionSuggestions: (settings) => {
                  return {
                    left: settings.decoratorRect.left + 'px',
                    bottom: 0 + 'px', // change this value (40) for manage the distance between cursor and bottom edge of popover
                    display: 'block',
                    transform: 'scale(1)', // transition popover on the value of its height
                    zIndex: 11,
                  };
                }    
            }            
        );
        
    }

    timeout;

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
      }  

      onAddMention = () => {
        // get the mention object selected
      }      

    textFieldChanged = editorState => {

        // const html = convertToHTML(editorState.getCurrentContent());

        // console.log(html);

        // let replacedText = html.replace( /(:[^\s:]+(?:::skin-tone-[2-6])?:)/g,(m)=>{
        //     return  Emoji({
        //         html: true,
        //         set: 'apple',
        //         emoji: m.replace(/:/g,''),
        //         size: 24
        //       });
            //return "<img src='https://www.superceed.com/images/system/default.png'/>";
            //return renderToString(<CustomEmoji emojiSet={this.props.emojiSet} data={m}/>)
            // if(this.emoji.hasOwnProperty(m.replace(/:/g,''))){
            //     return "<img data='"+m+"'/>";
            // }
            // else{
            //     return m;
            // }
        // });

        // console.log(replacedText);

        // let contentState = stateFromHTML(replacedText);

    //    const blocksFromHTML = convertFromHTML(replacedText);

    //    console.log(blocksFromHTML);

    //     const state = ContentState.createFromBlockArray(
    //         blocksFromHTML.contentBlocks,
    //         blocksFromHTML.entityMap,
    //     );

    const currentContentTextLength = this.state.editorState.getCurrentContent().getPlainText().length;
    const newContentTextLength = editorState.getCurrentContent().getPlainText().length;    


        const blocks = convertToRaw(editorState.getCurrentContent()).blocks;        
        const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
        if(value.trim().length > 0){
            this.props.sendTyping(this.props.id);            
        }
        else{
            this.props.removeTyping(this.props.id);
        }        
        this.setState( { sendIconDisabled : value.trim() === "" && this.state.blobs.length === 0 } )

        // const newState = EditorState.createWithContent(contentState)
        // console.log(convertToRaw(newState.getCurrentContent()).blocks);
        let finalEditorState = editorState;
        if (currentContentTextLength === 0 && newContentTextLength === 1) {
            // WORKAROUND: listens to input changes and focuses/moves cursor to back after typing in first character
            finalEditorState = EditorState.moveFocusToEnd(editorState);
        }

        this.setState({editorState:finalEditorState})
    };

    setEditorContent = (text) => {
        const contentState = ContentState.createFromText(text);
        const currentSelection = this.state.editorState.getSelection();   
        let editorState = EditorState.push(this.state.editorState, contentState,'remove-range');
        editorState = EditorState.moveSelectionToEnd(editorState);
        // const stateWithContentAndSelection = EditorState.forceSelection(contentState, currentSelection);

        this.setState({ editorState: editorState });  
    }    

    setCursorPos = (pos) => {
        // const editorState = EditorState.createEmpty();
        // this.setState({editorState:EditorState.moveSelectionToEnd(editorState)})
    
    // this.setState({editorState:editorState});        
    }

    sendFile = (files) => {
        //console.log(files);
        let fileList = this.state.upload
        let blobs = this.state.blobs;
        for (var i = 0; i < files.length; i++) {
            if(files[i].type !== ""){
                if(files[i])
                blobs.push(URL.createObjectURL(files[i]));                
                fileList.push(files[i]);
            }
          }        
        
        //fileList.push(files);
        this.setState({upload:fileList,blobs:blobs});
        this.props.scrollBottom();
    }    

    //single selection dropdown
    resetThenSet = (id, key) => {
        let temp = JSON.parse(JSON.stringify(this.state[key]));
        temp.forEach(item => item.selected = false);
        temp[id].selected = true;
        this.setState({
          [key]: temp
        });
      }

      //multiple selection dropdown
      toggleSelected = (id, key) => {
        let temp = JSON.parse(JSON.stringify(this.state[key]));
        temp[id].selected = !temp[id].selected;
        this.setState({
          [key]: temp
        });
      }  
      
      handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
      }
      handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
          this.setState({drag: true})
        }
      }
      handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter--
        if (this.dragCounter === 0) {
          this.setState({drag: false})
        }
      }
      handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({drag: false})
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
          this.sendFile(e.dataTransfer.files)
          
          this.dragCounter = 0    
            if(this.state.inputValue.length > 0 || this.state.upload.length > 0){
                this.setState( { sendIconDisabled : false } )
            //
            }
            else{
                this.setState( { sendIconDisabled : true } )
                
            }             
        }
      }      

      componentDidUpdate(){

      }

    componentDidMount(){
        console.log(this.ChatBoxHeight);
        //this.ChatBoxHeight.style.height = "auto";
        //this.ChatBoxHeight.style.height =  "33px";
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('mousedown', this.handleClickOutside2);
        document.addEventListener('mousedown', this.handleClickOutside3);
        document.addEventListener('mouseup', this.checkSelection);
        document.addEventListener('dragenter', this.handleDragIn)
        document.addEventListener('dragleave', this.handleDragOut)
        document.addEventListener('dragover', this.handleDrag)
        document.addEventListener('drop', this.handleDrop)  
        document.addEventListener('dragstart',this.clearFiles);  
        document.addEventListener('dragend',this.dragFinished);      

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('mousedown', this.handleClickOutside2);
        document.removeEventListener('mouseup', this.checkSelection);
        document.removeEventListener('dragenter', this.handleDragIn)
        document.removeEventListener('dragleave', this.handleDragOut)
        document.removeEventListener('dragover', this.handleDrag)
        document.removeEventListener('drop', this.handleDrop)     
        document.removeEventListener('dragstart',this.clearFiles);     
        document.removeEventListener('dragstart',this.dragFinished);  
      } 
      
      dragFinished = (e) => {
        this.dragCounter = 0 ;
        this.setState({drag: false})
      }

      clearFiles = (e) => {
        e.dataTransfer.clearData(); 
      }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }    

    setWrapperRef2 = (node) => {
        this.wrapperRef2 = node;
    }    
    
    setWrapperRef3 = (node) => {
        this.wrapperRef3 = node;
    }     

    setPhoneNumber = (value) => {
        this.props.setPhoneNumber(value);
    }

    showEmojiPicker = () => {
        this.setState({emojiPickerShow:true});  

        if(this.props.liveChatToken){
            axios.get(this.host+'/emoji/custom2', {params:{token:this.props.liveChatToken}},{withCredentials: true})
            .then(res => {
                let emojiSet = [];
                for (let [key, value] of Object.entries(res.data)) {
                    let obj = {
                        name: key,
                        short_names: [key],
                        text: '',
                        emoticons: [],
                        keywords: [key],
                        imageUrl: value,
                    }

                    
                    emojiSet.push(obj);

                    //console.log(key, value);
                }

            this.setState({emojiSet:emojiSet,emojiLoaded:true});
            }); 
        } 
        else{
            axios.get(this.host+'/emoji/custom', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
            .then(res => {
                let emojiSet = [];
                for (let [key, value] of Object.entries(res.data)) {
                    let obj = {
                        name: key,
                        short_names: [key],
                        text: '',
                        emoticons: [],
                        keywords: [key],
                        imageUrl: value,
                    }

                    
                    emojiSet.push(obj);

                    //console.log(key, value);
                }

            this.setState({emojiSet:emojiSet,emojiLoaded:true});
            });   
        }           
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({emojiPickerShow:false});
          //this.props.change("emojiPickerShow",false);
        }
    }

    handleClickOutside2 = (event) => {
        if (this.wrapperRef2 && !this.wrapperRef2.contains(event.target)) {
            this.setState({gifPickerShow:false});
          //this.props.change("emojiPickerShow",false);
        }
    }    

    handleClickOutside3 = (event) => {
        if (this.wrapperRef3 && !this.wrapperRef3.contains(event.target)) {
            this.setState({sliderShow:false});
          //this.props.change("emojiPickerShow",false);
        }
    }      

    checkSelection = (event) => {

        // event.preventDefault();

        // let savedCaretPosition = CaretPositioning.saveSelection(event.currentTarget);

        // console.log(savedCaretPosition);
        // this.setState( { caretPosition: savedCaretPosition ,cursorPos : savedCaretPosition.offsetStart , cursorPosEnd : savedCaretPosition.offsetEnd},()=>{
        //     CaretPositioning.restoreSelection(document.getElementById("chat-input"), this.state.caretPosition)            
        // })
        
        //this.setState( { cursorPosEnd : this.ChatBoxHeight.selectionEnd })
        //console.log(this.ChatBoxHeight.selectionStart);
        //this.setState( { cursorPos : this.ChatBoxHeight.selectionStart })
        //this.setState( { cursorPosEnd : this.ChatBoxHeight.selectionEnd })
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
          //this.props.change("emojiPickerShow",false);
        }
    }    
    

    updateInputValue = (e) =>{
        //console.log(e.target.value);
        //this.ChatBoxHeight.innerText;
        //console.log(e.target.value);

        
        
        let targetValue =  e.target.value;

        if(e.target.value.length > 0 || this.state.upload.length > 0){
            if(this.state.sendIconDisabled){
                this.props.sendTyping(this.props.id);
            }            
            this.setState( { sendIconDisabled : false } )
            this.setState( { inputValue : e.target.value}, () => {
                // let savedCaretPosition = CaretPositioning.saveSelection(e.currentTarget);
                // this.setState({"caretPosition" : savedCaretPosition, cursorPos : savedCaretPosition.offsetStart, cursorPosEnd: savedCaretPosition.offsetStart },()=>{
                //     CaretPositioning.restoreSelection(document.getElementById("chat-input"), this.state.caretPosition)
                // })
                
            })

        }
        else{
            this.props.removeTyping(this.props.id);
            this.setState( { sendIconDisabled : true } )
            
            this.setState( { inputValue : e.target.value}, () => {
                // let savedCaretPosition = CaretPositioning.saveSelection(e.currentTarget);
                // this.setState({"caretPosition" : savedCaretPosition, cursorPos : savedCaretPosition.offsetStart, cursorPosEnd: savedCaretPosition.offsetStart },()=>{
                //     CaretPositioning.restoreSelection(document.getElementById("chat-input"), this.state.caretPosition)
                // })
                
            })
        }
        //console.log(this.ChatBoxHeight);
        //console.log(this.ChatBoxHeight.current.scrollHeight);
        //this.ChatBoxHeight.current.style.height = "auto";
        //console.log(e.target.value.split(/\s/).length);
        
            //this.ChatBoxHeight.current.style.height =  (e.target.value.split(/\r\n|\r|\n/).length*21) +"px";
        this.ChatBoxHeight.style.height =  "33px";
        this.ChatBoxHeight.style.height =  this.ChatBoxHeight.scrollHeight +"px";
        
        


        clearTimeout(this.timeout)

        this.timeout = setTimeout(() => {
            this.props.scrollBottom();
        },0)
        
        
    }

    appendEmoji = (e,obj,event) => {
        // console.log(e);
        // console.log(obj);
        // console.log(event);

        let textBeforeCursor = this.state.inputValue.substring(0,this.state.cursorPos);
        let textAfterCursor = this.state.inputValue.substring(this.state.cursorPos, this.state.inputValue.length - (this.state.cursorPosEnd - this.state.cursorPos));
        let tempPos = this.state.cursorPos;

            this.setState( { sendIconDisabled : false } )
            this.setState( { inputValue : textBeforeCursor+String.fromCodePoint(parseInt (e, 16))+ textAfterCursor } )
            this.setState( {cursorPos :tempPos + 1})
            this.ChatBoxHeight.focus();  
      
    }

    appendTemplate = (index) => {
        const contentState = this.state.editorState.getCurrentContent();
        const selection = this.state.editorState.getSelection();

        let editorState = EditorState.createEmpty();

        // const nextOffSet = selection.getFocusOffset() + ("WTF").length;

        if (selection.isCollapsed()) {
            //let string = renderToString(<CustomEmoji emojiSet={this.props.emojiSet} data={emoji.colons}/>);
            const nextContentState = Modifier.insertText(contentState, selection, template[index]+" ");
            //let html = "<img className=\"show-tooltip\" style='max-width:100px' src='"+this.emoji[emoji.colons.replace(/:/g,'')]+"'/>";
            editorState = EditorState.push(this.state.editorState, nextContentState,'insert-characters');
            // const newSelection = editorState.getSelection().merge({
            //     focusOffset: nextOffSet,
            //     anchorOffset: nextOffSet
            //   })           
            // editorState = EditorState.acceptSelection(editorState, newSelection); 
        }
        else{
            //let string = renderToString(<CustomEmoji emojiSet={this.props.emojiSet} data={emoji.colons}/>).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
            const nextContentState = Modifier.replaceText(contentState, selection, template[index]+" ");
            
            editorState = EditorState.push(this.state.editorState, nextContentState,'insert-characters');
            // const newSelection = editorState.getSelection().merge({
            //     focusOffset: nextOffSet,
            //     anchorOffset: nextOffSet
            //   })
            // editorState = EditorState.acceptSelection(editorState, newSelection);           
        }
        this.textFieldChanged(editorState);        
    }

    appendEmoji3 = (emoji) => {


        // let replacedText;
        // replacedText = stringReplace(this.props.message.message, /(:[^\s:]+(?:::skin-tone-[2-6])?:)/g, (match, i) => (
        //   (
        //     JSON.stringify(this.props.emojiSet) !== JSON.stringify({}) ?
        //   <CustomEmoji emojiSet={this.props.emojiSet} key={match + i} data={match}/>
        //   :
        //   null
        //   )
        // ));
        

        console.log(emoji);
        const contentState = this.state.editorState.getCurrentContent();
        const selection = this.state.editorState.getSelection();

        let editorState = EditorState.createEmpty();

        const nextOffSet = selection.getFocusOffset() + emoji.colons.length;

        if (selection.isCollapsed()) {
            //let string = renderToString(<CustomEmoji emojiSet={this.props.emojiSet} data={emoji.colons}/>);
            const nextContentState = Modifier.insertText(contentState, selection, emoji.colons+" ");
            //let html = "<img className=\"show-tooltip\" style='max-width:100px' src='"+this.emoji[emoji.colons.replace(/:/g,'')]+"'/>";
            editorState = EditorState.push(this.state.editorState, nextContentState,'insert-characters');
            // const newSelection = editorState.getSelection().merge({
            //     focusOffset: nextOffSet,
            //     anchorOffset: nextOffSet
            //   })           
            // editorState = EditorState.acceptSelection(editorState, newSelection); 
        }
        else{
            //let string = renderToString(<CustomEmoji emojiSet={this.props.emojiSet} data={emoji.colons}/>).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
            const nextContentState = Modifier.replaceText(contentState, selection, emoji.colons+" ");
            
            editorState = EditorState.push(this.state.editorState, nextContentState,'insert-characters');
            // const newSelection = editorState.getSelection().merge({
            //     focusOffset: nextOffSet,
            //     anchorOffset: nextOffSet
            //   })
            // editorState = EditorState.acceptSelection(editorState, newSelection);           
        }
        this.textFieldChanged(editorState);
        
        // this.setState({editorState:editorState});
    }

    appendEmoji2 = (emoji,event) => {
        
        let textBeforeCursor = this.state.inputValue.substring(0,this.state.cursorPos);

        console.log(textBeforeCursor);
        
        console.log(this.state.inputValue);

        

        let textAfterCursor = this.state.inputValue.substring( this.state.cursorPosEnd , this.state.inputValue.length );


        console.log(this.state.cursorPosEnd - this.state.cursorPos + textBeforeCursor.length);

        let tempPos = this.state.cursorPos;

        // let myPos = this.state.caretPosition;

        // myPos.start = tempPos + emoji.colons.length;
        // myPos.end = tempPos + emoji.colons.length;
        // myPos.offsetStart = tempPos + emoji.colons.length;
        // myPos.offsetEnd = tempPos + emoji.colons.length;

            this.setState( { sendIconDisabled : false } )
            this.setState( { inputValue : textBeforeCursor+emoji.colons+" "+ textAfterCursor } )
            this.setState( {cursorPos :tempPos + emoji.colons.length , cursorPosEnd: tempPos + emoji.colons.length},()=> {
                // CaretPositioning.restoreSelection(document.getElementById("chat-input"), this.state.caretPosition);
            })
            this.ChatBoxHeight.focus();  
    }

    appendCustomEmoji = (text) => {
        // console.log(e);
        // console.log(obj);
        // console.log(event);

        let textBeforeCursor = this.state.inputValue.substring(0,this.state.cursorPos);
        let textAfterCursor = this.state.inputValue.substring(this.state.cursorPos, this.state.inputValue.length - (this.state.cursorPosEnd - this.state.cursorPos));
        let tempPos = this.state.cursorPos;

            this.setState( { sendIconDisabled : false } )
            this.setState( { inputValue : textBeforeCursor+":"+text+": "+ textAfterCursor } )
            this.setState( {cursorPos :tempPos + 1})
            this.ChatBoxHeight.focus();  
      
    }    

    onSearchChange = () => {
        
    }

    onAddMention = () => {

    }

    showEmoji = () => {
        this.props.change("emojiPickerShow",true);
    }

    hidePicker = () => {
        this.setState({gifPickerShow: false})
    }

    sendGif = (text) => {
        this.props.sendChat(text,this.props.id,"text");
    }

    sendIconClick = (props) => {
        const blocks = convertToRaw(this.state.editorState.getCurrentContent()).blocks;      
        // console.log(blocks);  
        const str = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

        const newState = this.state.editorState;
        let html = null;
        // let html =  stateToHTML(newState.getCurrentContent(),convertOptions);
        console.log(html);
        this.editor.focus();
        // console.log(str);
        if(str.trim().length > 0){
            this.props.removeTyping(this.props.id);


            if(this.props.channel !== "EMAIL" && this.props.channel !== "MANUAL"){
                props.sendChat((this.props.channel !== "EMAIL" && this.props.channel !== "MANUAL" ? str : html.replace(/(\r\n|\n|\r)/gm, "")),this.props.id,(this.props.channel !== "EMAIL" && this.props.channel !== "MANUAL" ? "text" : "text/html"));
            }
            else{
                if(this.state.upload.length > 0){
                    const temp = this.state.upload;
                    this.props.emailAttachment(html,this.props.id,"text/html",temp);
                    // props.upload(temp,this.props.id);
                    this.setState({upload : [],blobs:[]});
                    this.setState( { sendIconDisabled : true } )
                }        
                else{
                    props.sendChat((this.props.channel !== "EMAIL" && this.props.channel !== "MANUAL" ? str : html.replace(/(\r\n|\n|\r)/gm, "")),this.props.id,(this.props.channel !== "EMAIL" && this.props.channel !== "MANUAL" ? "text" : "text/html"));
                }          
            }

            this.setState( { inputValue : "" } )
            this.setState( { sendIconDisabled : true } )
            //this.setState( { cursorPos : this.ChatBoxHeight.selectionStart, cursorPosEnd : this.ChatBoxHeight.selectionEnd })
            props.setScrollFlag()
            //props.forceScroll() 
            //this.ChatBoxHeight.style.height = "auto";
            //this.ChatBoxHeight.style.height =  "32px";

            this.setEditorContent('');
            this.setCursorPos(0);
            

            //this.ChatBoxHeight.focus();  
                
        } 
        if(this.props.channel !== "EMAIL" && this.props.channel !== "MANUAL"){
            if(this.state.upload.length > 0){
                const temp = this.state.upload;
                props.upload(temp,this.props.id);
                this.setState({upload : [],blobs:[]});
                this.setState( { sendIconDisabled : true } )
            }    
        }    
        
    }

    keyPress = (e,props) =>{
        
        
        // let savedCaretPosition = CaretPositioning.saveSelection(e.currentTarget);

        // console.log(savedCaretPosition);
        // this.setState( { caretPosition: savedCaretPosition ,cursorPos : savedCaretPosition.offsetStart , cursorPosEnd : savedCaretPosition.offsetEnd},()=>{
        //     CaretPositioning.restoreSelection(document.getElementById("chat-input"), this.state.caretPosition)            
        // })
        

        if(e.keyCode === 13){
            if(e.shiftKey){
                
                return false;
            }   
            else{
                
                const blocks = convertToRaw(this.state.editorState.getCurrentContent()).blocks;        
                const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
                if(value.trim().length > 0){
                    this.props.removeTyping(this.props.id);
                    props.sendChat(this.state.inputValue,this.props.id,"text");
                    this.setState( { inputValue : "" } )
                    this.setState( { sendIconDisabled : true } )
                    //this.setState( { cursorPos : this.ChatBoxHeight.selectionStart, cursorPosEnd : this.ChatBoxHeight.selectionEnd })
                    props.setScrollFlag()
                    //props.forceScroll() 
                    this.ChatBoxHeight.style.height = "auto";
                    this.ChatBoxHeight.style.height =  "32px";
                }
                //console.log(props);
                if(this.state.upload.length > 0){
                    const temp = this.state.upload;
                    props.upload(temp,this.props.id);
                    this.setState({upload : [],blobs:[], sendIconDisabled: true});
                }
                //this.props.sendChat("Test",this.props.id)
                e.preventDefault();
                
                return false;
            }         
        }
        else{
            //this.setState( { cursorPos : this.ChatBoxHeight.selectionStart, cursorPosEnd : this.ChatBoxHeight.selectionEnd })
        }
    }

    pasteEvent = (e) => {
        //var previousText = this.state.inputValue;
        //console.log(e.clipboardData.items);
        for (var i = 0; i < e.clipboardData.items.length ; i++ ){
            if(e.clipboardData.items[i].getAsFile() !== null){
                var files = this.state.upload;
                files.push(e.clipboardData.items[i].getAsFile());
                var blobs = this.state.blobs;
                blobs.push(URL.createObjectURL(e.clipboardData.items[i].getAsFile()));
                this.setState({upload:files,blobs:blobs});
                console.log(e.clipboardData.items[i].getAsFile());
                this.props.scrollBottom();
                if(this.state.inputValue.length > 0 || this.state.upload.length > 0){
                    this.setState( { sendIconDisabled : false } )
                    //
                }
                else{
                    this.setState( { sendIconDisabled : true } )
                    
                }                
                e.preventDefault();
            }
        }

    }

    pastedFiles = (blobs) => {
        //console.log(blobs)
        //
        if(blobs.length > 0){
            var files = this.state.upload;

            var blobArray = this.state.blobs;

            for(var i = 0; i < blobs.length; i++){
                //files.push(blobs);
                blobArray.push(URL.createObjectURL(blobs[i]));
            }

            var newBlob = files.concat(blobs);
            this.setState({upload:newBlob,blobs:blobArray,sendIconDisabled:false});
            
            const blocks = convertToRaw(this.state.editorState.getCurrentContent()).blocks;        
            const str = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');


            
            return 'handled';
        }
    }

    removeUpload = (i) => {
        //console.log(i);
        var item = this.state.upload;
        var blobs = this.state.blobs

        item.splice(i,1);
        blobs.splice(i,1);

        this.setState({upload:item,blobs:blobs});

        const blocks = convertToRaw(this.state.editorState.getCurrentContent()).blocks;        
        const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');        

        if(value.trim().length > 0 || this.state.upload.length > 0){
            this.setState( { sendIconDisabled : false } )
            //
        }
        else{
            this.setState( { sendIconDisabled : true } )
            
        }        
    }

    action = (action) => {
        
        let editorState = RichUtils.toggleInlineStyle(this.state.editorState, action);
        this.setState({editorState:editorState});
    }      

    handlePastedText = (string,string2) => {
        let emoji = new EmojiConverter();
        emoji.init_env(); // else auto-detection will trigger when we first convert
        emoji.replace_mode = 'unified';
        emoji.allow_native = true;
        let output2 = emoji.replace_colons(string);

        const selection = this.state.editorState.getSelection();
        const pastedBlocks = ContentState.createFromText(output2).blockMap;
        const newState = Modifier.replaceWithFragment(
            this.state.editorState.getCurrentContent(),
            this.state.editorState.getSelection(),
            pastedBlocks,
        );
        const newEditorState = EditorState.push(this.state.editorState, newState, "insert-fragment");
        this.textFieldChanged(newEditorState);
          
          return "handled";
    }

    fileUploaded = (e) => {
        //this.fileUploader.files[0];
        //console.log("file upload detected");
        //console.log(this.fileUploader.files[0]);
        var files = this.state.upload;
        files.push(this.fileUploader.files[0]);
        var blobs = this.state.blobs;
        blobs.push(URL.createObjectURL(this.fileUploader.files[0]));     
        this.setState({upload:files,blobs:blobs});   
        if(this.state.inputValue.length > 0 || this.state.upload.length > 0){
            this.setState( { sendIconDisabled : false } )
            this.props.scrollBottom();
            //
        }
        else{
            this.setState( { sendIconDisabled : true } )
            
        }          
    }

    // highlight = () => {
        
    //     let editorState = RichUtils.toggleInlineStyle(this.state.editorState, 'HIGHLIGHT');
    //     this.setState({editorState:editorState});
    // }

    handleEnter = (command) => {
        if (command === 'highlight') {
            let editorState = RichUtils.toggleInlineStyle(this.state.editorState, 'highlight');
            this.setState({editorState:editorState});
        }    

        if (command === 'template1') {
            this.appendTemplate(0);
        }           

        if (command === 'template2') {
            this.appendTemplate(1);
        }    
        
        if (command === 'template3') {
            this.appendTemplate(2);
        }           
        
        if (command === 'template4') {
            this.appendTemplate(3);
        }           

        if (command === 'template5') {
            this.appendTemplate(4);
        }    
        
        if (command === 'template6') {
            this.appendTemplate(5);
        }          
        
        if (command === 'template7') {
            this.appendTemplate(6);
        }            
        
        if (command === 'strikethrough') {
            let editorState = RichUtils.toggleInlineStyle(this.state.editorState, 'STRIKETHROUGH');
            this.setState({editorState:editorState});
        }

        if(command === "send"){
            const blocks = convertToRaw(this.state.editorState.getCurrentContent()).blocks;        
            const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
            if(value.trim().length > 0){
                

                this.props.removeTyping(this.props.id);
                this.props.sendChat(value,this.props.id,"text");                
                this.setState( { sendIconDisabled : true } )
                //this.setState( { cursorPos : this.ChatBoxHeight.selectionStart, cursorPosEnd : this.ChatBoxHeight.selectionEnd })
                this.props.setScrollFlag();
                setTimeout(()=>{
                    this.setEditorContent('');
                    this.setCursorPos(0);
                },0)
                
                //props.forceScroll() 
            }
            //console.log(props);
            if(this.state.upload.length > 0){
                const temp = this.state.upload;
                this.props.upload(temp,this.props.id);
                this.setState({upload : [],blobs:[], sendIconDisabled: true});
            }            
        }
        //console.log(command);
        //console.log(e.shiftKey);
        // if(e.shiftKey){
            
            
        // }   
        // else{
        //     const blocks = convertToRaw(this.state.editorState.getCurrentContent()).blocks;        
        //     const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
        //     if(value.trim().length > 0){
                

        //         this.props.removeTyping(this.props.id);
        //         this.props.sendChat(value,this.props.id,"text");                
        //         this.setState( { sendIconDisabled : true } )
        //         //this.setState( { cursorPos : this.ChatBoxHeight.selectionStart, cursorPosEnd : this.ChatBoxHeight.selectionEnd })
        //         this.props.setScrollFlag();
        //         setTimeout(()=>{
        //             this.setEditorContent('');
        //         },0)
                
        //         //props.forceScroll() 
        //     }
        //     //console.log(props);
        //     if(this.state.upload.length > 0){
        //         const temp = this.state.upload;
        //         this.props.upload(temp,this.props.id);
        //         this.setState({upload : [],blobs:[], sendIconDisabled: true});
        //     }
        //     //this.props.sendChat("Test",this.props.id)
        //     e.preventDefault();         
        // }   
    }

    pickup = () => {
        
            if(this.props.value.callStatus !== "Ready"){
                if(this.props.callNumber){
                    this.props.callNumber('call-audio');
                }
            }
            else if(!this.state.gifPickerShow){
                this.setState({gifPickerShow:true});
            }
            else{
                if(this.props.callNumber){
                    this.props.callNumber('call-audio');
                }
                this.setState({gifPickerShow:false});
            }
        // this.setState({callStatus:"engaged"});
        
    }

    hangup = () => {
        this.setState({callStatus:"ready"});
        this.props.hangUp();
    }

    mute = () => {
        // this.setState({mute:!this.state.mute});
        this.props.mute();
    }

    keyBindingFn = (e) => {
        // console.log(e.key);
        if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && (e.key).toLowerCase() === 'h') {
            // alert("highlight");
            console.log("highlight");
            return 'highlight';
        }       
        
        if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && (e.key).toLowerCase() === 'x') {
            console.log("strikethrough");
            return 'strikethrough';
        }        


        if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && ((e.key).toLowerCase() === '1' || e.key.toLowerCase() === '!')) {

            return 'template1';
        }       
        
        if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && ((e.key).toLowerCase() === '2' || e.key.toLowerCase() === '@')) {

            return 'template2';
        }    

        if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && ((e.key).toLowerCase() === '3' || e.key.toLowerCase() === '#')) {

            return 'template3';
        }    

        if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && ((e.key).toLowerCase() === '4' || e.key.toLowerCase() === '$')) {

            return 'template4';
        }    

        if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && ((e.key).toLowerCase() === '5' || e.key.toLowerCase() === '%')) {

            return 'template5';
        }    

        if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && ((e.key).toLowerCase() === '6' || e.key.toLowerCase() === '^')) {

            return 'template6';
        }    

        if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && ((e.key).toLowerCase() === '7' || e.key.toLowerCase() === '&')) {

            return 'template7';
        }    

        //console.log(e);
        else if (e.keyCode === 13) {
          if(e.shiftKey){
              return 'split-block'
          }
          else{
            if(this.props.channel === 'EMAIL' || this.props.channel === 'MANUAL'){
                return 'split-block'
            }
            else{
              return 'send'
            }
          }
        }
      
        // This wasn't the delete key, so we return Draft's default command for this key
        return getDefaultKeyBinding(e)
      }  
      
      progressBarColor = (percent) => {
        if(percent > 75){
            return "#F44336"
        }
        else if(percent >= 55){
            return "#EF6C00"
        }
        else if(percent >= 45 && percent < 55){
            return "#4CAF50"
        }
        else if(percent > 10 && percent < 45){
            return "#FFC107"
        }
        else if(percent <= 10 ){
            return "#FF9800"
        }
        else{
            return "#000000"
        }
      }

    

    render() {

        const { MentionSuggestions } = this.mentionPlugin;
        //console.log(this.props);
        return (
            <div className='margin-left-1x margin-right-1x'>
            <div className={("chat-box-background  z-depth-1 rounded-corner-10 margin-right-2x")+" flex-col relative-content flex-no-shrink "+(this.props.additionalClass)}>
                <button onClick={()=>this.props.forceScroll()} className={"btn-floating grey "+(this.props.goToBottom ? "" : "invisible")} style={{position:'absolute',top:'-50px',left:'10px'}}><ArrowUp style={{zIndex:5}}/></button> 
                <button onClick={()=>{this.props.setScrollFlag();this.props.forceScroll()}} className={"btn red "+(this.props.showNewMessageBtn ? "" : "invisible")} style={{position:'absolute',top:'-50px',left:'40%',borderRadius:'25px',zIndex:5}}>New message</button> 

                {
                                this.props.match.params.id === "ongoing" ? 
                                <div className='flex-row'>
                                    <div className='full-width'>
                                        <h6 className='margin-left-2x'>Customer</h6>
                                        <div className={"meter-container padding-left-2x padding-right-2x "+(this.props.energy_customer < 5 || this.props.energy_customer > 75 ? 'flashing-border-2' : (this.props.energy_customer < 45 || this.props.energy_customer >= 55 ? 'flashing-border' : ''))}>
                                            <h6>Energy</h6>
                                            <ProgressBar  className="browser-default margin-bottom-1x">
                                                <ProgressBar now={this.props.energy_customer === 0 ? 5 : this.props.energy_customer} style={{backgroundColor: this.progressBarColor(this.props.energy_customer)}}/>
                                            </ProgressBar>
                                        </div>
                                        <div className={"meter-container padding-left-2x padding-right-2x "+ (this.props.pace_customer < 5 || this.props.pace_customer > 75 ? 'flashing-border-2' : (this.props.pace_customer < 45 || this.props.pace_customer >= 55 ? 'flashing-border' : ''))}>
                                            <h6>Pace</h6>
                                            <ProgressBar  className="browser-default margin-bottom-1x">
                                                <ProgressBar now={this.props.pace_customer === 0 ? 5 : this.props.pace_customer} style={{backgroundColor: this.progressBarColor(this.props.pace_customer)}}/>
                                            </ProgressBar>
                                        </div>
                                    </div>      
                                    <div className='full-width'> 
                                        <h6 className='margin-left-2x'>Agent</h6>                             
                                        <div className={"meter-container padding-left-2x padding-right-2x "+(this.props.energy < 5 || this.props.energy > 75 ? 'flashing-border-2' : (this.props.energy < 45 || this.props.energy >= 55 ? 'flashing-border' : ''))}>
                                            <h6>Energy</h6>
                                            <ProgressBar  className="browser-default margin-bottom-1x">
                                                <ProgressBar now={this.props.energy === 0 ? 5 : this.props.energy} style={{backgroundColor: this.progressBarColor(this.props.energy)}}/>
                                            </ProgressBar>
                                        </div>
                                        <div className={"meter-container padding-left-2x padding-right-2x "+ (this.props.pace < 5 || this.props.pace > 75 ? 'flashing-border-2' : (this.props.pace < 45 || this.props.pace >= 55 ? 'flashing-border' : ''))}>
                                            <h6>Pace</h6>
                                            <ProgressBar  className="browser-default margin-bottom-1x">
                                                <ProgressBar now={this.props.pace === 0 ? 5 : this.props.pace} style={{backgroundColor: this.progressBarColor(this.props.pace)}}/>
                                            </ProgressBar>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                }

                {/* <Dropdown
                    title="Select fruit"
                    list={this.state.fruit}
                    resetThenSet={this.resetThenSet}
                    />
                     */}
                    {/* <DropdownMultiple
                    titleHelper="Location"
                    title="Select location"
                    list={this.state.location}
                    toggleItem={this.toggleSelected}
                    />                       */}
                   {(this.state.drag ? <div style={{width:'100%',height:'100%',position:'fixed',top:0,left:0,pointerEvents:'none',backgroundColor:'rgba(0,0,0,0.5)',zIndex:'9999',display:'flex',alignItems:'center'}}>
                       <h5 className="white-text bold-text mid-text full-width">Drop your file here</h5>
                   </div> : null)}
                   {(this.props.uploadState ? <div className="upload-progress-wrapper">
                       <h6 className="bold-text mid-text">Uploading...</h6>
                       <div className="progress chatbox-upload-progress">
                            <div className="determinate" style={{width: this.props.uploadPercent+'%'}}></div>
                        </div>                       
                   </div> : null)}
                   <div className={"file-upload-container overflow-x full-width "+(this.state.blobs.length > 0 ? "" : 'hide')} style={{overflowY:'hidden'}}>
                        <div className="margin-bottom-1x relative-content" style={{whiteSpace:'nowrap'}}>
                        {/* <DropFile handleDrop={this.handleDrop}>
                            <div style={{position:'fixed',height: '100%', width: '100%',top:0,left:0}}>

                            </div>
                        </DropFile> */}
                            {
                                this.state.blobs.map((obj,i) => (   
                                    
                                    <div key={i} className="relative-content file-object">
                                        <div className="relative-content flex-row" style={{width:'auto',height:'100px',overflow:'hidden',borderRadius:'10px',border:'2px solid #757575',marginRight:'5px',alignItems:'center'}}> 
                                            <CloseIcon className="red-text linked" style={{position:'absolute',right:'10px',top:'5px',border:'1px solid #fefefe',borderRadius:'25px',backgroundColor:'#fefefe'}} onClick={()=>{this.removeUpload(i)}}/>                       
                                            <Img src={[obj]} unloader={<div className="margin-left-1x"><FileIcon style={{fontSize:'40px'}} /></div>/*any valid react element */} style={{height:'100%',width:'auto'}}/>
                                            {/* <span>{this.state.upload[i].name}</span> */}
                                            {(!this.state.upload[i].type.includes("image/") ? <span className="margin-right-2x margin-left-1x">{this.state.upload[i].name}</span> : null)}
                                        </div>                                      
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                <div className="flex-row valign-wrapper relative-content flex-no-shrink" style={{height:'45px'}}>   
                    <div className="call-info-holder margin-left-2x full-width">
                        {
                        (this.props.asteriskError ? <h6 className='red-text inline-block'>Failed to establish connection to Superceed</h6> : 
                        (this.props.value.callStatus === "Engaged" ? <h6>On call with {this.props.value.callerNumber}</h6> : 
                        (this.props.value.callStatus === "Ready" ? <h6>Ready</h6>  : 
                        (this.props.value.callStatus === "Incoming" ? <h6>Incoming call from {this.props.value.callerNumber}</h6>  : 
                        (this.props.value.callStatus === "Dialing" ? <h6>Dialing {this.props.value.phoneNumber}...</h6>  : <h6>{this.props.value.callStatus}</h6> )
                        )
                        )
                        )
                        )}
                        {
                            this.props.asteriskError ?
                            <button className='button-new margin-left-1x' onClick={this.props.reconnect}>
                                Reconnect
                            </button>
                            :
                            null
                        }
                        {
                            this.props.asteriskError ?
                            <a className='button-new margin-left-1x' href="https://192.168.8.9:8089" target="_blank" rel="noopener">
                                Authenticate
                            </a>
                            :
                            null
                        }
                    </div>
                    <div className={"padding-right-2x flex-row padding-top-1x padding-bottom-1x"+(this.props.asteriskError ? " hide" : "")}>
                    <input type="file" id="file" ref={el =>this.fileUploader = el} style={{display: "none"}} onChange={(e)=>this.fileUploaded(e)} onClick={(event)=> { event.target.value = null}}/>           

                        {/* <InlineToolbar>
                        {
                            externalProps => (
                                <React.Fragment>
                                    <CustomActionButton {...externalProps} type="bold" action={this.action}/>
                                    <CustomActionButton {...externalProps} type="italic" action={this.action}/>
                                    <CustomActionButton {...externalProps} type="underline" action={this.action}/>
                                    <CustomActionButton {...externalProps} type="highlight" action={this.action}/>
                                </React.Fragment>
                            )
                        }
                        </InlineToolbar>       */}
                        {(this.state.emojiLoaded && this.state.emojiPickerShow ?   
                            <div ref={this.setWrapperRef}        >
                                <Picker
                                sheetSize={64}
                                emojiSize={24}
                                perLine={7}
                                showPreview={false}
                                title="Pick Emoji" set='emojione' darkMode={this.props.dark}
                                custom={this.state.emojiSet}
                                style={{position:'absolute',bottom:0,right:0,zIndex:999}}
                                />
                            </div>
                        :
                        null                      
                        )}  
                        {/* <EmojiSuggestions />
                        <MentionSuggestions
                            onSearchChange={this.onSearchChange}
                            suggestions={this.state.suggestions}
                            onAddMention={this.onAddMention}
                            /> */}
                    {/* <textarea 
                        id="chat-input"
                        ref={el =>this.ChatBoxHeight = el}
                        // html={this.state.inputValue} 
                        value={this.state.inputValue}
                        onKeyDown={(e) => this.keyPress(e,this.props)}
                        className={"full-width no-default-highlight white no-margin chat-box " + (this.props.dark ? "grey darken-2" : "")}
                        style={{border: 'none',borderRadius:'6px',paddingLeft:'10px',paddingRight:'10px',resize:'none',overflow:'auto',height:'33px',maxHeight:'100px',paddingTop:'5px',paddingBottom:'5px'}}
                        placeholder={this.props.status !== 3 ? "Enter your message here" : "You cannot reply to this conversation."} onChange={this.updateInputValue}
                        onPaste={e=>this.pasteEvent(e)}
                        disabled={this.props.status === 3 ? true : false}
                        /> */}
                    {/* <div className={(this.props.status === 3 ? "icon-disabled " : "") + "relative-content margin-left-1x"} data-balloon-blunt aria-label="Forward" data-balloon-pos="up">
                        <ForwardRounded className={"chatbox-function-icon linked"} style={{fontSize:'25px'}} onClick={()=>this.fileUploader.click()}/>                             
                    </div>    */}
                    {
                        this.props.channel !== "chat" && this.props.showKB  ?
                        <div className={(this.props.status === 3 ? " " : "") + "relative-content margin-left-1x"} data-balloon-blunt aria-label="Knowledgebase" data-balloon-pos="up" onClick={this.props.showKB}>
                            <IconNotebook 
                                className="linked chatbox-function-icon"
                                size={22} // set custom `width` and `height`
                                // set `stroke` color
                                stroke={2}  // set `stroke-width`
                                strokeLinejoin="miter" // override other SVG props                            
                            />                           
                        </div>   
                        :
                        null
                    }
                    {(1 ? <div className={(this.props.status === 3 ? "icon-disabled " : "") + "relative-content margin-left-1x"} data-balloon-blunt aria-label="Slider" data-balloon-pos="up"onClick={()=>{this.setState({sliderShow:true})}}>
                        <IconAdjustmentsHorizontal 
                            className="linked chatbox-function-icon"
                            size={22} // set custom `width` and `height`
                            // set `stroke` color
                            stroke={2}  // set `stroke-width`
                            strokeLinejoin="miter" // override other SVG props                            
                        />                             
                    </div>  : null)}                      
                    {(this.props.value.callStatus === "Ready" ? <div className={(this.props.status === 3 ? "icon-disabled " : "") + "relative-content margin-left-1x"} data-balloon-blunt aria-label="Dialpad" data-balloon-pos="up"onClick={()=>{this.setState({gifPickerShow:true});}}>
                        <IconDialpad 
                            className="linked chatbox-function-icon"
                            size={22} // set custom `width` and `height`
                            // set `stroke` color
                            stroke={2}  // set `stroke-width`
                            strokeLinejoin="miter" // override other SVG props                            
                        /> 
                    </div>  : null)}             
                    <Link onClick={()=>{}} to={"/call/ongoing"}>
                    <div className={(this.props.status === 3 ? "icon-disabled " : "") + "relative-content margin-left-1x"} data-balloon-blunt aria-label="Call" data-balloon-pos="up" onClick={this.pickup}>
                        <IconPhone 
                            className="linked chatbox-function-icon"
                            size={22} // set custom `width` and `height`
                            // set `stroke` color
                            stroke={2}  // set `stroke-width`
                            strokeLinejoin="miter" // override other SVG props                            
                        /> 
                    </div>    
                    </Link>      
                    <div className={"red-text "+(this.props.status === 3 ? "icon-disabled " : "") + "relative-content margin-left-1x"} data-balloon-blunt aria-label="Hangup" data-balloon-pos="up" onClick={this.hangup}>
                        <IconPhoneOff 
                            className="linked chatbox-function-icon"
                            size={22} // set custom `width` and `height`
                            // set `stroke` color
                            stroke={2}  // set `stroke-width`
                            strokeLinejoin="miter" // override other SVG props                            
                        /> 
                    </div>  
                    <div className={(this.props.value.mute === true ? "red-text" : "")+" "+(this.props.status === 3 ? "icon-disabled " : "") + "relative-content margin-left-1x"} data-balloon-blunt aria-label="Mute" data-balloon-pos="up" onClick={this.mute}>
                        {this.props.value.mute === true ? <IconMicrophoneOff
                            className="linked chatbox-function-icon"
                            size={22} // set custom `width` and `height`
                            // set `stroke` color
                            stroke={2}  // set `stroke-width`
                            strokeLinejoin="miter" // override other SVG props                            
                        /> : 
                        <IconMicrophone 
                            className="linked chatbox-function-icon"
                            size={22} // set custom `width` and `height`
                            // set `stroke` color
                            stroke={2}  // set `stroke-width`
                            strokeLinejoin="miter" // override other SVG props                            
                        />
                        }
                    </div>  
                    {/* <div className={(this.props.status === 3 ? "icon-disabled " : "") + "relative-content margin-left-1x"} data-balloon-blunt aria-label="Template" data-balloon-pos="up">
                        <FontAwesomeIcon className="chatbox-function-icon linked" style={{fontSize:'20px'}} icon={faPencilRuler} onClick={()=>{this.appendTemplate()}}/>                              
                    </div>                       */}
                    {/* <div className={(this.props.status === 3 ? "icon-disabled " : "") + "relative-content margin-left-1x"} data-balloon-blunt aria-label="Custom Emoji" data-balloon-pos="up">
                        <FontAwesomeIcon className="linked chatbox-function-icon" style={{fontSize:'20px'}} icon={faStickyNote} onClick={()=>{this.setState({customEmojiPickerShow:true})}}/>                              
                    </div>                       */}


                    <div ref={this.setWrapperRef2} style={{position:'absolute',bottom:'8px',right:'50px',zIndex:5}} className={'gif-picker-holder '+(!this.state.gifPickerShow || this.props.value.callStatus !== "Ready" ? 'invisible' : null)}>
                        {/* <EmojiPicker onEmojiClick={this.appendEmoji} emojiResolution={64}/>                     */}
                        <DialPad pickup={this.pickup} goToCall={this.props.goToCall} setPhoneNumber={this.setPhoneNumber} hideGiphy={this.props.hideGiphy} loadCategory={e=>this.loadCategory=e} hidePicker={this.hidePicker} sendGif={this.sendGif} gifPickerShow={this.state.gifPickerShow} dark={this.props.dark}/>
                    </div>     
                    {(this.state.sliderShow ? <div ref={this.setWrapperRef3} style={{position:'absolute',bottom:'8px',right:'50px',zIndex:5}} className={'slider-holder '}>
                        {/* <EmojiPicker onEmojiClick={this.appendEmoji} emojiResolution={64}/>                     */}
                        <CustomEmojiPicker appendCustomEmoji={this.appendCustomEmoji} emojiPickerShow={this.state.customEmojiPickerShow} emoji={this.props.emojiSet} onEmojiClick={this.appendCustomEmoji} dark={this.props.dark}/>
                    </div> : null)}                                                          
                    {/* <div ref={this.setWrapperRef3} style={{position:'absolute',bottom:'8px',right:'100px'}} className={'custom-emoji-picker-holder '+(!this.state.customEmojiPickerShow ? 'invisible' : null)}>
                        
                        <CustomEmojiPicker appendCustomEmoji={this.appendCustomEmoji} emojiPickerShow={this.state.customEmojiPickerShow} emoji={this.props.emojiSet} onEmojiClick={this.appendCustomEmoji} dark={this.props.dark}/>
                    </div>                                      */}
                    {/* <SendIcon ref={e => this.sendButton = e} onClick={(e) => this.sendIconClick(this.props)} className={"margin-left-1x margin-right-1x linked send-icon green-text " + (this.state.sendIconDisabled === true || this.props.status === 3 ? 'send-disabled' : null)}/> */}
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ( {
    emotionChanged: () => 
    {
        dispatch({type: 'emotion' });
    },
    setPhoneNumber: (value) => 
    {
        dispatch({type: 'phoneNumber',value : value});
    },
  } )
  const mapStateToProps = ( state ) => ( {
    value: state
} )

export default connect(mapStateToProps,mapDispatchToProps)(ChatBox)
