import React, { Component } from 'react'
import Variable from '../variable';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Moment from 'react-moment';
import moment from 'moment';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown'
import { ResponsiveBar } from '@nivo/bar'
import EmotionChart from '../components/EmotionChart';
import Circular from '../components/Circular';
import {NavigateNext,NavigateBefore} from '@material-ui/icons'

import StackedBarChart from '../components/StackedBarChart';
import { isObject } from 'lodash';
import emoji from 'react-easy-emoji'
import CustomAudioPlayer from '../components/layout/CustomAudioPlayer';


const emotionEmoji = {
  "Angry" : "😡",
  "Happy" : "😀",
  "Sad":"😢",
  "Surprised":"😲",
  "Scared":"😨",
  "Disgust":"🤮",
  "Calm":"🙂",
  "Disappointment":"😞",

}

export default class Voicebot extends Component {

    host = Variable.host;

    constructor(props){
      super(props);
      this.state = {
        pbx:[],
        references:{},
        agentPool:[],
        sip:[],
        emotionState:{},
        contextState:{},
        personalityState:{},
        selectedVoice:-1,
        voice:[],
        text:"",
        generating:false,
        audioURL:"",
        audio:[]
      }
    }

    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
  }

  getTTS = () => {
    axios.get(this.host+'/api/get_tts', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
    .then(res => {
      this.setState({audio:res.data});
    })
  }

    componentDidMount(){
      axios.get(this.host+'/api/get_voice', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
      .then(res => {
        this.setState({voice:res.data});
      })

      this.getTTS();

      // this.props.socket.on("textEmotionApi", this.emotionApi);

      // this.props.socket.on("contextApi", this.contextApi);

      // this.props.socket.on("personalityApi", this.personalityApi);

      // setTimeout(()=>{
      //   console.log("emmited");
      //   this.props.socket.emit("sendContextApi",{context:{context:"Testing",extension_id:"3232"},id:2});
      // },2000)
      

      // axios.get(this.host+'/api/proxy/agent_pool', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
      // .then(res => {
      //   this.setState({agentPool:res.data});
      // })
    }

    componentWillUnmount(){
      // this.props.socket.off("textEmotionApi", this.emotionApi);
      // this.props.socket.off("contextApi", this.contextApi);
      // this.props.socket.off("personalityApi", this.personalityApi);
    }

    generateVoice = () => {
      if(this.state.text !== "" && this.state.selectedVoice !== -1){
        this.setState({generating:true},()=>{
          axios.post(this.host+'/api/text_to_speech', {_token:this.getCookie("auth"),text:this.state.text,voice_id:this.state.voice[this.state.selectedVoice].voice_id,id:this.state.voice[this.state.selectedVoice].id},{withCredentials: true})
          .then(res => {
            this.setState({generating:false},()=>{
              this.getTTS();
            });
            
          })
          .catch((err)=>{
            this.state.setState({generating:false});
          })

        })
      }
    }

    emotionApi = (data) => {
      if(data.source === "agent"){
        let emotionState = this.state.emotionState;
        emotionState[data.extension_id] = data.text_emotion;
        this.setState({emotionState:emotionState});
      }
    }

    contextApi = (data) => {
      console.log(data);
      if(data.context){
        let contextState = this.state.contextState;
        contextState[data.context.extension_id] = data.context.context;
        this.setState({contextState:contextState});
      }
      else if (data.emotion){
        let emotionState = this.state.emotionState;
        emotionState[data.emotion.extension_id] = data.emotion.emotion;
        this.setState({emotionState:emotionState});
      }
      else if (data.personality){
        let personalityState = this.state.personalityState;
        personalityState[data.personality.extension_id] = data.personality.personality;
        this.setState({personalityState:personalityState});
      }

    }

    personalityApi = (data) => {
      console.log(data);
      let personalityState = this.state.personalityState;
      personalityState[data.personality.extension_id] = data.personality;
      this.setState({personalityState:personalityState});

    }

  
    keyPress = (obj) => {
      if(obj !== null){
        return obj.map((item,i)=>(
          <div className='nested-keypress'>
            <div>
              <h6>Option String</h6>
              <p>{item.optionString}</p>
              <h6>Reference</h6>
              <select className='browser-default' value={item.referenceTableID} onChange={(event)=>{
                            
                item.referenceTableID = event.target.value;

                this.setState({pbx:this.state.pbx});
              }}>
                <option value="0">None</option>
                {
                  this.state.agentPool.map((pool,i)=>(
                    <option value={pool.ID}>{pool.description}</option>
                  ))
                }
              </select>
              <h6>Return To menu</h6>
              <select className='browser-default' value={item.returnToMenu} onChange={(event)=>{
                  
                  item.returnToMenu = event.target.value;

                  this.setState({pbx:this.state.pbx});
                }}>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
              </select>
              <h6>Service Code</h6>
              <select className='browser-default' value={item.serviceCode.trim()} onChange={(event)=>{
                            
                  item.serviceCode = event.target.value;

                  this.setState({pbx:this.state.pbx});
                }}>
                  <option value="">None</option>
                  {
                    this.props.references.serviceType.map((service,i)=>(
                      <option value={service.serviceCode.trim()}>{service.description}</option>
                    ))
                  }
                </select>
            </div>
            {this.keyPress(item.children)}
          </div>
        ))
      }
      else{
        return null;
      }
    }

getEmoji = (text) => {
  return emoji(text,{
    props:{class:"emoji-image"}
  });      
}


    render(){
        return (
            <div className='full-width flex-col'>
              <div className={" flex-row padding-left-2x padding-right-2x flex-no-shrink header-border-bottom"} style={{height:'64px',alignItems:'center',position:"relative",backgroundColor:this.props.dark ? '#333' :'#ffffff'}}>
                <h5 className='bold-text-2'>Voice Generator</h5>
              </div>
              {
              <div className='full-height padding-top-2x full-width padding-right-2x padding-left-2x overflow-x padding-bottom-2x'>
                <h6>Voice Model</h6>
                 <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {this.state.selectedVoice !== -1 ? this.state.voice[this.state.selectedVoice].name  : "Select Voice"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                        <Dropdown.Item  eventKey={-1} onClick={()=>{this.setState({selectedVoice:-1})}}>All</Dropdown.Item>
                            {
                                this.state.voice.map((obj,i)=>(
                                    <Dropdown.Item key={i} eventKey={i} onClick={()=>{this.setState({selectedVoice:i})}}>{obj.name}</Dropdown.Item>
                                ))
                            }
                
                        </Dropdown.Menu>
                    </Dropdown>  
                    
                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                        <span>Text</span>
                        <textarea onChange={(e)=>{this.setState({text:e.target.value});}} className="browser-default" type="text" value={this.state.text}/>
                    </label>
                    <button className={"margin-bottom-2x button-new"+(this.state.generating ? " disabled" : "")} onClick={this.generateVoice}>{this.state.generating? "Generating..." : "Generate"}</button>
                    <button className='button-new margin-left-1x' onClick={()=>{ this.props.openSettings(16);}}>Settings</button>
                    <div className='relative-content'>
                    {this.state.audioURL !== "" && !this.state.generating ? <CustomAudioPlayer currentTimeCallBack={()=>{}} src={this.state.audioURL}/> : null}
                    {
                      this.state.audio.filter((item)=>{
                        if(this.state.selectedVoice !== -1){
                          if(this.state.voice[this.state.selectedVoice].id === item.voice_id){
                            return true;
                          }
                          else{
                            return false;
                          }
                        }
                        else{
                          return true;
                        }
                      }).map((obj,i)=>(
                        <div className='relative-content' key={i}>
                          <h6>
                          {
                            this.state.voice.filter((item)=>item.id === obj.voice_id)[0]?.name
                          }
                          </h6>
                          <h6>{obj.transcript}</h6>
                          <CustomAudioPlayer currentTimeCallBack={()=>{}} src={obj.file_url}/>
                        </div>
                      ))
                    }
                    </div>
                    
                    
              </div>
              }
            </div>
        );
    }
}