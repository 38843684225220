import React, { Component } from 'react';
import { Search } from '@material-ui/icons'
import Color from 'color';
import { Scrollbars } from 'react-custom-scrollbars';
import Variable from './../../variable';

export default class componentName extends Component {

    host = Variable.host;
    
    state = {
        keyword : "",
        label:[],
        ticketLabel:[]
    }

    textboxChanged = (e) => {
        this.setState({keyword:e.target.value})
    }

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }    

    componentDidMount(){
        this.setState({label:this.props.label,ticketLabel:JSON.parse(this.props.ticketLabel).sort(),original:JSON.parse(this.props.ticketLabel).sort()})
    }

    labelInfo = (id) => {
        let info = this.props.label.filter(label => label.id === id);
        
        if(info.length > 0){
            return info[0];
        }
        else{
            return null;
        }
    }  

    removeLabel = (i) => {
        let label = this.state.ticketLabel;
        label.splice(i,1);
        label.sort();
        this.setState({ticketLabel:label});
    }

    addLabel = (i) => {
        let label = this.state.ticketLabel;
        label.push(i);
        label.sort();
        this.setState({ticketLabel:label});
    }

  render() {
    return (
        <div className={"flex-col z-depth-1 "+(this.props.dark ? "grey darken-3" : "white")} style={{borderRadius:'10px'}}>
            <div className="overflow-hidden">
                {(1 ? <input value={this.state.keyword} ref={e => this.searchBox = e} onChange={e => this.textboxChanged(e)} placeholder="Search label" type="text" className="no-default-highlight no-margin" style={{padding:'5px 10px',width:'230px'}}/>: null)}
                {(this.state.keyword.length === 0 ? <Search style={{position:'absolute',right:'10px',top:'15px'}}/> : null)}
                {/* <div className={"custom-tab "}>
                    <div onClick={()=>this.switchMode('tenor')} className={"custom-tab-item unselectable "+(this.state.mode === "tenor" ? "clicked" : "")+(this.props.dark ? " dark" : "")}>Tenor</div>
                    <div onClick={()=>this.switchMode('giphy')} className={"custom-tab-item unselectable "+(this.state.mode === "giphy" ? "clicked" : "")+(this.props.dark ? " dark" : "")}>GIPHY</div>
                    {(this.state.mode === "giphy" ? <img src={GiphyIcon} style={{position:'absolute',bottom:'10px',right:'25px',width:'60px',pointerEvents:'none'}}/>:null)}
                </div> */}
            </div>
            <Scrollbars style={{width:'250px',height:'150px'}}>
                <div style={{margin:'10px'}}>
                    <h6 className="grey-text" style={{fontSize:"13px"}}>Assigned Label</h6>
                {
                   this.state.ticketLabel.map((obj,i)=>
                        (
                            this.labelInfo(obj) !== null ?
                            <div key={i} onClick={()=>this.removeLabel(i)} className="label-2 linked unselectable" style={{color: Color(this.props.dark ? (this.labelInfo(obj)).colorDark :(this.labelInfo(obj)).color).isDark() ? "#fefefe" : "#333333",backgroundColor:(this.props.dark ? (this.labelInfo(obj)).colorDark :(this.labelInfo(obj)).color)}}>{(this.labelInfo(obj)).name}</div>
                            :
                            <span key={i} className="label linked unselectable" onClick={()=>this.removeLabel(i)}>Removed</span>
                        )
                    )

                    
                }   
                {
                    this.state.ticketLabel.length === 0 ? <p className="margin-top-1x" style={{fontSize:"11px"}}>No label assigned.</p> : null
                }   
                </div>
                <div style={{margin:'20px 10px 10px 10px'}}>
                    <h6 className="grey-text" style={{fontSize:"13px"}}>Available Label</h6>
                {
                    this.state.label.filter(labelItem => {return labelItem.name.toLowerCase().indexOf(this.state.keyword.toLowerCase()) !== -1 &&  !this.state.ticketLabel.includes(labelItem.id)}).map((obj,i)=>
                        (
                            <div key={i} className="label-2 linked unselectable" onClick={()=>{this.addLabel(obj.id)}} style={{color: Color(this.props.dark ? obj.colorDark :obj.color).isDark() ? "#fefefe" : "#333333",backgroundColor:(this.props.dark ? obj.colorDark :obj.color)}}>{obj.name}</div>
                        )
                    )
                }
                {
                    this.state.label.filter(labelItem => {return labelItem.name.toLowerCase().indexOf(this.state.keyword.toLowerCase()) !== -1 &&  !this.state.ticketLabel.includes(labelItem.id)}).length === 0 ? <p className="margin-top-1x" style={{fontSize:"11px"}}>No label found.</p> : null
                }                 
                </div>
            </Scrollbars>
            <div style={{padding:"10px",borderTop:(this.props.dark ? '1px solid #545454' : '1px solid #ededed')}}>
                <button className={"btn green "+ (JSON.stringify(this.state.ticketLabel) === JSON.stringify(this.state.original) ? "disabled" : "")} onClick={()=>this.props.saveLabel(this.state.ticketLabel)}>Update</button>
            </div>
        </div>
    );
  }
}
