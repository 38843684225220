import React, { useState,useEffect,useRef } from 'react';
import Variable from './../../variable';
import axios from 'axios';
import 'react-dates/lib/css/_datepicker.css';
import BootstrapModal from 'react-bootstrap/Modal';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { CheckCircleOutline, Error, Warning } from '@material-ui/icons';

const channelArr =[
    {
        name:"AI Guidance",
        channel:"AI Guidance"
    },  
    {
        name:"What to say",
        channel:"What to say"
    },     
    {
        name:"What to do",
        channel:"What to do"
    },   
    {
        name:"How to say",
        channel:"How to say"
    },      
    {
        name:"Emotion",
        channel:"Emotion"
    },     
    {
        name:"Personality",
        channel:"Personality"
    },  
    {
        name:"Context",
        channel:"Context"
    },       
    {
        name:"Insights",
        channel:"Insights"
    },              
    
];

export default function Notification(props) {

    const host = Variable.host;

    const [canned,setCanned] = useState([]);

    const [modalOpen,setModalOpen] = useState(false);


    const [modalType,setModalType] = useState("add");

    const [loading,setLoading] = useState(false);

    const [title,setTitle] = useState("");

    const [message,setMessage] = useState("");

    const [origin,setOrigin] = useState("");

    const [errorMessage,setErrorMessage] = useState("");

    const [editTitle,setEditTitle] = useState("");

    const [editMessage,setEditMessage] = useState("");

    const [editOrigin,setEditOrigin] = useState("");

    const [selectedId,setSelectedId] = useState(-1);

    const [account,setAccount] = useState([]);

    const [handleAccount,setHandleAccount] = useState([]);

    const [attachment,setAttachment] = useState([]);

    const fileUploader = useRef(null);

    const [uploaded,setUploaded] = useState([]);

    const [readyToDelete,setReadyToDelete] = useState(false);

    const [ticket,setTicket] = useState(localStorage.getItem('ticketNotification') === "yes" ? true : false);

    const [ticketMessage,setTicketMessage] = useState({});

    const [browser,setBrowser] = useState(window.Notification.permission);

    let removeTimeout;

    const toggleAccount = (acc)=>{      

        let temp = [...handleAccount];

        if(temp.indexOf(acc) !== -1){
            let index = temp.indexOf(acc);
            temp.splice(index,1);
            setHandleAccount(temp);
        }
        else{
            temp.push(acc);
            setHandleAccount(temp);
        }

    }

    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    } 

    const loadCanned = () => {
        axios.get(host+'/api/message/canned', {params:{_token:getCookie("auth")}},{withCredentials: true})
        .then(res => {
            setCanned(res.data);
        })  
    }

    useEffect(()=>{
        let temp = {};
        for(let i = 0 ; i < channelArr.length; i++){
            if(localStorage.getItem((channelArr[i].channel).toLowerCase()+"_Recommendation") === "yes" || !localStorage.getItem((channelArr[i].channel).toLowerCase()+"_Recommendation")){
                temp[channelArr[i].channel] = true;
            }
            else{
                temp[channelArr[i].channel] = false;
            }
        }
        setTicketMessage(temp);
    },[]);

    const addCannedResponse = () => {
        if(title === "" || message === ""){
            setErrorMessage("Please fill");
            return false;
        }
        else{
            setErrorMessage("");
        }
        axios.post(host+'/api/message/canned',{_token:getCookie("auth"),title:title,message:message,origin:handleAccount.join(",")},{withCredentials: true})
        .then(res => {
            setModalOpen(false);
            props.setChildrenModalOpened(false);
            loadCanned();
            
        }).catch(err => {
            setErrorMessage(err.response.data.description);
        });  
    }

    const editCannedResponse = () =>{
        const formData = new FormData();
        for(var i = 0; i < attachment.length; i++){
            formData.append('attachment[]',attachment[i]);
        }
        formData.append('_token',getCookie("auth"));
        formData.append('message',editMessage);
        formData.append('title',editTitle);
        formData.append('id',selectedId);
        formData.append('origin',handleAccount.join(","));
        axios.post(host+'/api/message/canned/update',formData,{headers:{'Content-Type':'multipart/form-data'},withCredentials: true})
        .then(res => {
            setModalOpen(false);
            props.setChildrenModalOpened(false);
            loadCanned();
            setAttachment([]);
            
        }).catch(err => {
            setErrorMessage(err.response.data.description);
        });  
    }

    const fileUploaded = (e) => {
        //this.fileUploader.files[0];
        //console.log("file upload detected");
        //console.log(this.fileUploader.files[0]);
        var files = [...attachment];
        files.push(fileUploader.current.files[0]);
        setAttachment(files);        
    }

    const deleteCannedResponse = () => {
        axios.delete(host+'/api/message/canned',{params:{_token:getCookie("auth"),id:selectedId}},{withCredentials: true})
        .then(res => {
            setModalOpen(false);
            props.setChildrenModalOpened(false);
            loadCanned();
            
        }).catch(err => {
            setErrorMessage(err.response.data.description);
        });  
    }

    const deleteAll = () => {
        if(readyToDelete){
            clearTimeout(removeTimeout);
            axios.delete(host+'/api/message/canned/attachment',{params:{_token:getCookie("auth"),id:selectedId}},{withCredentials: true})
            .then(res => {
                // setModalOpen(false);
                // props.setChildrenModalOpened(false);
                // loadCanned();
                setUploaded([]);
                setReadyToDelete(false);
                
                
            }).catch(err => {
                setErrorMessage(err.response.data.description);
                setReadyToDelete(false);
            });  
        }
        else{
            setReadyToDelete(true);
            clearTimeout(removeTimeout);
            removeTimeout = setTimeout(()=>{
                setReadyToDelete(false);
            },3000);
        }
    }

    const handleModalClose = () => {
        setModalOpen(false);
        props.setChildrenModalOpened(false);
        setAttachment([]);
    }


    const edit = (i) => {
        setEditTitle(canned[i].title);
        setEditMessage(canned[i].response_message);
        setHandleAccount(canned[i].origin.split(","));
        if(canned[i].attachment !== null && canned[i].attachment !== ""){
            setUploaded(JSON.parse(canned[i].attachment));
        }
        else{
            setUploaded([]);
        }
        setModalType("edit");
        setModalOpen(true);
        props.setChildrenModalOpened(true);
        setSelectedId(canned[i].id);
    }


    const setTicketMessageFn = (res,channel) => {
        let message = {...ticketMessage};
        message[channel] = res;
        localStorage.setItem(channel.toLowerCase()+'_Recommendation',res ? "yes" : "no");
        if(channel === "EMAIL"){
            localStorage.setItem("GRAPH_EMAIL"+'_Recommendation',res ? "yes" : "no");
        }
        setTicketMessage(message);
    }


    useEffect(()=>{
        localStorage.setItem('ticketNotification',ticket ? "yes" : "no");
    },[ticket])

    useEffect(()=>{
        localStorage.setItem('ticketMessageNotification',ticketMessage ? "yes" : "no");
    },[ticketMessage])

    const removeAnnoucement = () => {
        axios.get(host+'/api/announcement2', {params:{_token:getCookie("auth")}},{withCredentials: true})
        .then(res => {

        })  
    }

    return (
        <div className="settings-section-1 full-height">
            <BootstrapModal show={modalOpen} onHide={handleModalClose}>
                    <BootstrapModal.Header closeButton>
                        <BootstrapModal.Title><h5 className="bold-text-2">{modalType === "add" ? "Add Canned Response" : (modalType === "edit" ? "Edit Canned Response" : (modalType === "delete" ? "Delete Canned Response" : ""))}</h5></BootstrapModal.Title>
                    </BootstrapModal.Header>
                    <BootstrapModal.Body>
                        { errorMessage !== "" ? <h6 className="red lighten-3 callout-box white-text">{errorMessage}</h6> : null }
                        {
                            modalType === "add" ?
                                <div className="full-width full-height">
                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <span>Title</span>
                                        <input onChange={(e)=>setTitle(e.target.value)} className="browser-default" type="text" value={title}/>
                                    </label> 
                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <span>Message</span>
                                        <textarea onChange={(e)=>setMessage(e.target.value)} className="browser-default" type="text" value={message}/>
                                    </label> 
                                    {/* <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <span>Origin Email (Optional)</span>
                                        <input onChange={(e)=>setOrigin(e.target.value)} className="browser-default" type="text" value={origin}/>
                                    </label>  */}
                                </div>

                            :
                            (
                                modalType === "edit" ?
                                <div className="full-width full-height">
                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <span>Title</span>
                                        <input onChange={(e)=>setEditTitle(e.target.value)} className="browser-default" type="text" value={editTitle}/>
                                    </label>
                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <span>Message</span>
                                        <textarea onChange={(e)=>setEditMessage(e.target.value)} className="browser-default" type="text" value={editMessage}/>
                                    </label> 
                                    <span className='input-label'>Attachment</span>
                                    {
                                        uploaded.map((obj,i)=>(
                                            <h6>{obj}</h6>
                                        ))
                                    }
                                    {readyToDelete ? <h6 className='red-text'>Click again to confirm delete</h6> : null}
                                    <button className='button-new' onClick={deleteAll}>Delete All Uploaded</button>
                                    <button className='button-new margin-left-1x' onClick={()=>{fileUploader.current.click()}}>Add File</button>
                                    <button className={'button-new margin-left-1x'+(attachment.length > 0 ? '' : ' disabled')} onClick={()=>{setAttachment([])}}>Remove Attached</button>
                                    <h6>{attachment.length} file attached.</h6>
                                    {/* <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <span>Origin</span>
                                        <textarea onChange={(e)=>setEditOrigin(e.target.value)} className="browser-default" type="text" value={editOrigin}/>
                                    </label>  */}
                                    
                                    <input type="file" id="file" ref={fileUploader} style={{display:'none'}} onChange={(e)=>fileUploaded(e)} onClick={(event)=> { event.target.value = null}}/>           
                                </div>

                            :
                            null
                            )
                        }
                        <span className='input-label margin-top-2x'>Origin</span>
                         {
                            account.map((obj,i)=>(
                                <div className="team-channel-list" key={obj.account_name}>
                                    <input className="material filled-in" type="checkbox" id={"switch-account-"+obj.account_name} checked={handleAccount.indexOf(obj.account_name) !== -1 ? true : false} onChange={()=>toggleAccount(obj.account_name)}/><label className="material" htmlFor={"switch-account-"+obj.account_name}>{obj.account_user ? obj.account_user : obj.account_name}</label>                             
                                </div>
                            ))
                        }   
                    </BootstrapModal.Body>
                    <BootstrapModal.Footer>
                    <div>
                    <button className="btn-flat ng-close-btn waves-effect waves-dark margin-right-1x" onClick={()=>{setModalOpen(false);props.setChildrenModalOpened(true);setAttachment([]);}}>cancel</button>
                        {
                            !loading ?
                            (modalType === "add" ? 
                            <button className="button-new" onClick={addCannedResponse}>Add</button>
                            :
                                (modalType === "delete" ? 
                                <button className="button-new delete-button" onClick={deleteCannedResponse}>Delete</button>
                                :
                                    (modalType === "edit" ? 
                                    <button className="button-new" onClick={editCannedResponse}>Edit</button>
                                    :
                                    null
                                    )
                                )
                            )
                            :
                            null
                        }
                    </div>
                    </BootstrapModal.Footer>
            </BootstrapModal>
        
            <h5 className='settings-title-text bold-text-2'></h5>
                {/* <button className='button-new margin-bottom-2x' onClick={()=>{setModalType("add");setModalOpen(true);props.setChildrenModalOpened(true);setHandleAccount([])}}>Add</button> */}
                <div className='div-table limit-width-500 '>
                    {/* <div className="table-row">
                        <div className="table-cell" style={{width:'100%',paddingRight:'20px'}}>
                            <h6 className="bold-text margin-top-2x circular-font settings-toggle-text">New Ticket</h6>
                
                            </div>
                            <div className="ios-toggle">
                                <input className="ios" type="checkbox" id="switch-3" checked={ticket} onChange={()=>{
                                    setTicket(!ticket);
                        
                                }}/><label className="ios" htmlFor="switch-3">Toggle</label>
                            </div>                        
                    </div>  */}
                  <div className="table-cell" style={{width:'100%',paddingRight:'20px'}}>
                        <h6 className="bold-text margin-top-2x circular-font settings-toggle-text">Public GPT</h6>
                        {/* <p>Keep displaying name and avatar on each message bubble.</p> */}
                        </div>
                        <div className="ios-toggle">
                            <input className="ios" type="checkbox" id={"switch-channel-gpt"} checked={props.settings.public_gpt === 1} onChange={()=>{
                                props.updateSettings();
                                }}/><label className="ios" htmlFor={"switch-channel-gpt"}>Toggle</label>
                        </div>  
                    {channelArr.map((obj,i)=>(<div className="table-row" key={i}>
                        <div className="table-cell" style={{width:'100%',paddingRight:'20px'}}>
                        <h6 className="bold-text margin-top-2x circular-font settings-toggle-text">{obj.name}</h6>
                        {/* <p>Keep displaying name and avatar on each message bubble.</p> */}
                        </div>
                        <div className="ios-toggle">
                            <input className="ios" type="checkbox" id={"switch-channel-"+i} checked={ticketMessage[obj.channel]} onChange={()=>{
                                    setTicketMessageFn(!ticketMessage[obj.channel],obj.channel);
                                    
                                }}/><label className="ios" htmlFor={"switch-channel-"+i}>Toggle</label>
                        </div>                        
                    </div>)) }

                </div>
                {
                    canned.map((obj,i)=>(
                        <div className={"margin-right-1x status-item "} key={i}>
                            <div className='valign-wrapper'>
                                <div className='full-width margin-left-2x' style={{minWidth:'1px'}}>
                                    <h5 className={"bold-text-2 small-font label-item-title ellipsis-text "+(props.dark ? "" : "grey-text text-darken-3")}>{obj.title}</h5>  
                                    <h6>{obj.origin}</h6>
                                </div>
                                <div className={"linked icon-button-hover-3 "+ (props.dark ? "dark-mode" : "")} onClick={()=>{edit(i)}}>
                                    <IconPencil 
                                        size={20} // set custom `width` and `height`
                                        // set `stroke` color
                                        stroke={2}  // set `stroke-width`
                                        strokeLinejoin="miter" // override other SVG props
                                    /> 
                                </div>
                                <div className={"linked icon-button-hover-3 "+ (props.dark ? "dark-mode" : "")} onClick={()=>{setSelectedId(obj.id);setModalType("delete");setModalOpen(true);props.setChildrenModalOpened(true)}}>
                                    <IconTrash 
                                        size={20} // set custom `width` and `height`
                                        // set `stroke` color
                                        stroke={2}  // set `stroke-width`
                                        strokeLinejoin="miter" // override other SVG props
                                    /> 
                                </div>
                            </div>
                        </div>
                    ))
                }
        </div>
    )
}