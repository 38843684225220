import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import ConversationDate from './ConversationDate'
import Linkify from 'react-linkify';
import Img from 'react-image';
import ReactResizeDetector from 'react-resize-detector';
import {MoreHoriz,Language,EditOutlined,NoteOutlined,DeleteOutline,ExpandLess,ExpandMore,Close,EmojiObjects} from '@material-ui/icons';
import FileInfo from './FileInfo';
import ReactPlayer from 'react-player'
import CustomAudioPlayer from './../CustomAudioPlayer';
import RichLink from './RichLink';
import Moment from 'react-moment';
import 'moment-timezone';
import axios from 'axios';
import Iframe from './../../Iframe';
import stringReplace from 'react-string-replace';
import CustomEmoji from './CustomEmoji';
import Color from 'color';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import Variable from './../../../variable';
import parse from 'html-react-parser';
import data from 'emoji-mart/data/emojione.json';
import ControllerImg from './../../../images/controller02.png'
import EntertainerImg from './../../../images/entertainer.png'
import FeelerImg from './../../../images/feeler.png'
import ThinkerImg from './../../../images/thinker.png'
import { connect } from 'react-redux';

import emoji from 'react-easy-emoji'


const emotionColor = {
  "neutral" : "#787878",
  "angry" : "#C0392B",
  "fear" : "#F4D03F",
  "frustration" : "#E67E22",
  "threat" : "#A93226",
  "commitment" : "#138D75",
  "satisfaction" : "#94C973",
  "appreciation" : "#DE3163",
  "gratitude" : "#82E0AA",
  "joy" : "#85C1E9",
  "disappointment" : "#90A4AE",
  "disappointed" : "#90A4AE",
  "sadness" : "#5885AF",
  "dissatisfaction" : "#FAD7A0",
  "sarcasm" : "#F5B7B1",
  "surprise" : "#D2B4DE",
  "concerned" : "#7D6608",
  "sad":"#455A64",
  "happy" : "#85C1E9",
}


const componentDecorator = (href, text, key,linkColor) => (
  <div key={key} style={{display:"inline-block"}}>
    <a href={href} target="_blank" rel="noopener" style={{color:linkColor || ""}}>
      {text}
    </a>
  </div>
);

export class ConversationItem extends Component {  

  host = Variable.host;

    state = {
      isLink : false,
      currentHeight: null,
      metaData: [],
      forceExpand: false,
      frameHeight : 0,
      showDate: false,
      showName: false,
      isVisible: true,
      links:[],
      noteExpanded:false,
      noteToRemove:[],
      showIframe: false,
      showCollapse:false
    }

    showCollapseMessageButton = false;

    mounted = false;

    frameHeight = 0;

    constructor(props){
      super(props);
      this.messageBox = React.createRef();       
    }

    currentHeight = null;

    resizeHeight = (height) => {
      if(height <= 0 ){
        this.frameHeight = 1000;
      }
      else{
        this.frameHeight = height + 20;
      }
    }

    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    }     
    
    extractUrl = (string) => {
      let urls = string.match(/\bhttps?:\/\/\S+/gi);
      this.setState({urls:urls});
      return urls;
    }

    forceExpand = () => {
      this.setState({forceExpand:true});
    }

    colorTest = (color) => {
      var regExp = new RegExp(/^#[0-9A-F]{6}$/i);
      return regExp.test(color) ? color : "#000000";
    }

    convertHTML2 = (el) => {
      let text = "";
      // console.log(el);
      if(el.childNodes.length > 0){
        let array = Array.from(el.childNodes);
        console.log(array);
        for(let i = 0; i < array.length; i++){

          if(array[i].childNodes.length === 0){
            if(array[i].innerText){
              text += array[i].innerText;
            }
            else if(array[i].textContent){
              text += array[i].textContent;
            }
            if(array[i].nodeName === "P" || array[i].nodeName.indexOf("H") !== -1 || array[i].nodeName === "BR" || el.nodeName === "DIV"){
              text += "\n";
            }            
          }
          else{
            if(array[i].nodeName === "DIV"){
              text += this.convertHTML2(array[i]);
            }
            else{
              text += array[i].innerText;

              if(array[i].nodeName === "P" || array[i].nodeName.indexOf("H") !== -1 || array[i].nodeName === "BR" || el.nodeName === "DIV"){

                text += "\n";
              }
            }
          }
        }

        if(el.nodeName === "P" || el.nodeName.indexOf("H") !== -1 || el.nodeName === "BR" || el.nodeName === "DIV"){

          text += "\n";
        }          
        // console.log(text);
        return text;
      }
      else{


        text += el.innerText;

        if(el.nodeName === "P" || el.nodeName.indexOf("H") !== -1 || el.nodeName === "BR" || el.nodeName === "DIV"){
          console.log("yes");
          text += "\n";
        }
            
        return text;
      }    
    }

    convertHTML = (html) => {
      let el = document.createElement('html');
      el.innerHTML = html;

      let array = Array.from(el.childNodes);

      let text = "";

      for(let i = 0; i < array.length; i++){
        if(array[i].nodeName === "BODY"){
          let child = Array.from(array[i].childNodes);
          
          for(let i = 0; i < child.length; i++){
            // console.log(child[i]);
            if(child[i].nodeType === 1){
              text += this.convertHTML2(child[i]);
            }
            else if(child[i].nodeType === 3){
              text += child[i].textContent;
            }
          }          
        }
      }

      // console.log(text);
      let finalText = text.replace(/(^[ \t]*\n)/gm,"");
      this.showCollapseMessageButton = finalText.split(/\r\n|\r|\n/).length > 10;
      let replacedText = emoji(finalText.replace(/(^[ \t]*\n)/gm,""),{
        baseUrl: '//cdnjs.cloudflare.com/ajax/libs/emojione/2.2.5/assets/png/',
        size: '',
        props:{class:"emoji-image"}
      });      
      return replacedText;

      //console.log(array);
    }

    getBodyHTML = (html) => {
      let el = document.createElement('html');
      el.innerHTML = html;

      let array = Array.from(el.childNodes);

      let text = "";

      for(let i = 0; i < array.length; i++){
        if(array[i].nodeName === "BODY"){
          return array[i].innerHTML;
          // let child = Array.from(array[i].childNodes);
          
          // for(let i = 0; i < child.length; i++){
          //   // console.log(child[i]);
          //   if(child[i].nodeType === 1){
          //     text += this.convertHTML2(child[i]);
          //   }
          //   else if(child[i].nodeType === 3){
          //     text += child[i].textContent;
          //   }
          // }          
        }
      }

      // console.log(text);
      return text.replace(/(^[ \t]*\n)/gm,"");

      //console.log(array);
    }


    componentDidMount(){

      this.setState({showDate:false,showName:this.props.currentUser(this.props.message.sender)});

      if(this.height === undefined && this.mainFragment !== undefined){
        
        this.height = this.mainFragment.clientHeight;
      }      
      this.mounted = true;
      //this.setState({currentHeight:this.messageBox.clientHeight});
      if(this.props.rich){
        if(this.extractUrl(this.props.text) !== null && this.props.message.has_meta === 0){
          let urlArray = this.extractUrl(this.props.text);

          for(var i = 0; i < urlArray.length; i++){
            urlArray[i] = encodeURIComponent(urlArray[i]);
          }

            axios.post(this.host+'/api/cors',{_token:this.props.token||this.getCookie("auth"),url:JSON.stringify(urlArray),message_id:this.props.message.id},{withCredentials: true})
            .then(res => {
        
              if(res.data.result === undefined && this.mounted){
                //let metaData = this.state.metaData;
                //metaData.push(res.data);
                this.setState({metaData:res.data});
              }
        
            })
            .catch(err =>{
              console.log(err);
            });   
    

        }
        else if (this.props.message.has_meta === 1){
          this.setState({metaData:this.props.message.metaData});
        }
      }
    }

    componentWillUnmount(){
      this.mounted = false;
      
    }

    hideLink = () => {
      this.setState({isLink:true});
    }

    notImage = () => {
      //console.log("called");
      //this.setState({isLink : true});
    }

    alert = () => {
      alert("Hello!");
    }

    openViewer = (url) => {
      //console.log("opening image");
      var images = [{src:url,alt:'none'}];
      this.props.passFromChild(images);
      //this.props.imgViewer(url);
    }

    validYT = (url) => {
      var f = /^http(?:s?):\/\/(?:www\.|web\.|m\.)?facebook\.com\/([A-z0-9\.]+)\/videos(?:\/[0-9A-z].+)?\/(\d+)(?:.+)?$/gm;
      var p = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm;
       return (url.match(p)) ? RegExp.$1 : ((url.match(f)) ? RegExp.$1 : false);
    }    

    scrollFunction = (e) => {
      //console.log(e);
      this.props.scroll();
    }

    renderText = (text) => {
      let parts = text.split(/\s/g) // re is a matching regular expression
      for (let i = 1; i < parts.length; i ++) {
        if(this.validURL(parts[i].replace(/\s/g,""))){
          return parts[i];
        }
        //parts[i] = parts[i]>parts[i]
      }
      //return parts
      return (parts[0]);
    }

    validURL = (str) => {
      // var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      //   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      //   '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      //   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      //   '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      //   '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      // return !!pattern.test(str);
      var split = str.split("\n");

      var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
      return regexp.test(split[0].replace(/\s/g,''));      
    }  
    
    properURL = (url) =>{
      //console.log(this.renderText(url));
      //var split = url.split("\n");

      //var encoded = (encodeURIComponent(split[0].replace(/\s/g, '')));
      //console.log(decodeURIComponent(encoded));
      //return decodeURIComponent(encoded);
      var res = this.renderText(url);
      //console.log(res);
      return res;
    }

    showOverflowMenu = () => {
      let top = ReactDOM.findDOMNode(this.overflowMenuButton).getBoundingClientRect().y;
      let left = ReactDOM.findDOMNode(this.overflowMenuButton).getBoundingClientRect().x;
      //console.log( ReactDOM.findDOMNode(this.overflowMenuButton).getBoundingClientRect());
      (this.props.username === this.props.message.sender || this.props.message.sender === "agent" || this.props.message.sender === "chatbot")  && this.props.channel !== "EMAIL" && this.props.channel !== "MANUAL" ? this.props.showOverflowMenu(top,left - 180,'right',this.props.message.id, (this.props.username === this.props.message.sender || this.props.message.sender === "admin"),this.props.message.type) : this.props.showOverflowMenu(top,left,'left',this.props.message.id,(this.props.username === this.props.message.sender || this.props.userType === "admin"),this.props.message.type)
      
    }

    // onResize = () => {
    //   console.log("resize Deteced");
    // }

    componentDidUpdate(){

      if(this.props.refCallback){
        this.props.refCallback(this.mainFragment);
      }

      if(this.height === null){
        
        this.height = this.mainFragment.clientHeight;
      }
    }

    expandNote = () => {
      this.setState({noteExpanded:!this.state.noteExpanded});
    }

    getEmotionColor = (data) => {
      let res = Object.keys(data).reduce(function(a, b){ return data[a] > data[b] ? a : b });
      switch(res){
        case "anger":
          return "chat-border-bottom angry-border";
          case "fear":
            return "chat-border-bottom fear-border";   
            case "happy":
              return "chat-border-bottom happy-border";     
              case "love":
                return "chat-border-bottom love-border";      
                case "sadness":
                  return "chat-border-bottom sad-border";    
                  case "surprise":
                    return "chat-border-bottom surprise-border";       
                      case "curious":
                      return "chat-border-bottom curious-border";                                    
      }
      return "";
    }

    getEmotionPercentage = (data) => {
      let res = Object.keys(data).reduce(function(a, b){ return data[a] > data[b] ? a : b });

      return res+" "+(data[res] * 100).toFixed(2);
    }    
    

    mainChanged = () => {
      //console.log(this.mainFragment.clientHeight);
      this.setState({frameHeight:this.mainFragment.clientHeight});
      //console.log(this.height);
      if(this.height <= this.mainFragment.clientHeight){
        var diff = this.mainFragment.clientHeight - this.height;
        this.props.offsetScroll(diff);
        this.height = this.mainFragment.clientHeight;
        //console.log(diff);
      }
      //console.log(e);
    }

    removeNote = (id) => {
      if(this.state.noteToRemove.includes(id)){
          axios.post(this.host+'/api/note/delete',{_token:this.props.token||this.getCookie("auth"),id:id,messageId:this.props.message.id,ticketId:this.props.ticketId},{withCredentials: true})
          .then(res => {
            let noteToRemove = this.state.noteToRemove;
            noteToRemove.splice(this.state.noteToRemove.indexOf(id),1);
            this.setState({noteToRemove:noteToRemove});
          })
          .catch(err =>{
            console.log(err);
          }); 
        }    
        else{
          let noteToRemove = this.state.noteToRemove;
          noteToRemove.push(id);
          this.setState({noteToRemove:noteToRemove});
          setTimeout(()=>{
            let noteToRemove = this.state.noteToRemove;
            noteToRemove.splice(this.state.noteToRemove.indexOf(id),1);
            this.setState({noteToRemove:noteToRemove});
          },3000);
        }    
    }

        
    str_pad_left = (string,pad,length) => {
      return (new Array(length+1).join(pad)+string).slice(-length);
  }     

    secToTime = (time) => {
      let minutes = Math.floor(time / 60);
      let seconds = time - minutes * 60;
      //console.log(this.str_pad_left(minutes,'0',2)+':'+this.str_pad_left(seconds,'0',2))
      return  this.str_pad_left(minutes,'0',2)+':'+this.str_pad_left(seconds,'0',2);    
  }   

    showHeight = (height) => {
      //console.log(this.messageBox.clientHeight);
      if(this.currentHeight !== null){
        if(this.currentHeight !== height){
          //console.log(this.messageBox);
          var oldHeight = this.currentHeight;
          var diff = height - oldHeight;
          //console.log(oldHeight+ " "+this.messageBox.clientHeight +" = "+diff);
          if(diff > 0){
            //console.log("ready to scroll");
            this.props.offsetScroll(diff);
          }
        }
        this.currentHeight = height
      }
      else{
        this.currentHeight = height
      }
    }

    render() {

      let replacedText = emoji(this.props.text,{
        baseUrl: '//cdnjs.cloudflare.com/ajax/libs/emojione/2.2.5/assets/png/',
        size: '',
        props:{class:"emoji-image"}
      });

      replacedText = stringReplace(replacedText, /(:[^\s:!\/]+(?:::skin-tone-[2-6])?:)/g, (match, i) => (
        (
          JSON.stringify(this.props.emojiSet) !== JSON.stringify({}) ?
        <CustomEmoji emojiSet={this.props.emojiSet} key={match + i} data={match}/>
        :
        null
        )
      ));


      if(this.props.message.type === "system"){
        return (
          <React.Fragment>  
            <div className="mid-text margin-top-2x margin-bottom-2x limit-width-500 block-center">
            { this.state.showDate ? <ConversationDate date={<Moment unix format="D MMMM YYYY">{this.props.message.ts}</Moment>}/> : null }
              <h6 className="bold-text-2 system-timestamp-margin"><Moment unix format="kk:mm">{this.props.message.ts}</Moment></h6>
              <div className="system-message-wrapper margin-bottom-2x inline-block">
                <span className='system-message-label'><Linkify componentDecorator={componentDecorator}>{this.props.text}</Linkify></span>
              </div>
            </div>
          </React.Fragment>
        )
      }
      else {
        return (

          <div className={"message-box-wrapper "+(this.props.currentMessageId === this.props.message.id ? "highlight" : "")} ref={e=>this.mainFragment=e} style={{marginTop:'auto',visibility:(this.state.isVisible ? 'visible' : 'hidden')}}>
            <ReactResizeDetector handleWidth handleHeight onResize={(e)=>this.mainChanged(e)}/>  

              <React.Fragment>
                {(this.props.forwardMode ? <div className="valign-wrapper">
                    <input className="material filled-in" type="checkbox" id={"forward-"+(this.props.message.id)} checked={this.props.selectedMessage.includes(this.props.message.id)} onChange={()=>this.props.setSelectedMessage(this.props.message.id)}/>
                    <label className="material" htmlFor={"forward-"+(this.props.message.id)}></label>
                </div>  : null)}               
                {this.state.showDate ? <ConversationDate date={<Moment unix format="D MMMM YYYY">{this.props.message.ts}</Moment>}/> : null }
            <div className="message-box" ref={this.messageBox}>
              {
                this.state.showName && !this.props.firstMessage ? 
                <div style={{height:'30px'}}></div>    
                :
                null
              }

              {/* <div className="special-strikethrough grey-text">
                  <span className="special-strikethrough-text bold-text">0</span>
              </div> */}

              {(((this.state.showName || this.state.showDate || this.props.firstMessage) && (this.props.displayName || this.props.channel === "EMAIL" || this.props.channel === "MANUAL")) || this.props.alwaysDisplay  ? <h6 className={" msg-display-name "+(((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (this.props.message.sender === "chatbot" && !this.props.reversed)) && this.props.channel !== "EMAIL" && this.props.channel !== "MANUAL") ? "right-text" : "left-text")}><span className="bold-text-2 display-name-text">{( this.props.userInfo[this.props.message.sender] !== undefined ? this.props.userInfo[this.props.message.sender].name : (this.props.message.sender === "visitor" && this.props.visitorName ? this.props.visitorName : this.props.message.sender))}</span>  {(this.props.message.ts ? <Moment unix format="kk:mm">{this.props.message.ts}</Moment> : null)}</h6> : null)}

              { ((this.props.firstMessage && (this.state.showName && !this.props.displayName && !this.props.alwaysDisplay)) || (this.props.firstMessage && (!this.state.showName && !this.props.displayName && !this.props.alwaysDisplay)) || (!this.props.firstMessage && (this.state.showName && !this.props.displayName && !this.props.alwaysDisplay)))  && this.props.channel !== "EMAIL" && this.props.channel !== "MANUAL" ? <h6 className={"msg-display-name "+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || this.props.message.sender === "chatbot") && this.props.channel !== "EMAIL" && this.props.channel !== "MANUAL"? "right-text" : "left-text")}>{(this.props.message.ts ? this.secToTime(this.props.message.ts - this.props.ts) : null)}</h6> : null}

              <div className={"msgbox-detail-holder-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL" && this.props.channel !== "MANUAL" ? "right" : "left")}>
                <div data-balloon-blunt aria-label={( this.props.userInfo[this.props.message.sender] !== undefined ? this.props.userInfo[this.props.message.sender].name : (this.props.message.sender === "visitor" && this.props.visitorName ? this.props.visitorName : this.props.message.sender))} data-balloon-pos={"up-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || this.props.message.sender === "chatbot")  && this.props.channel !== "EMAIL" && this.props.channel !== "MANUAL" ? "right" : "left")} className={"flex-col user-img-bubble-"+(this.props.username === this.props.message.sender || this.props.message.sender === "agent"|| (!this.props.reversed && this.props.message.sender === "chatbot") ? "right" : "left")}>

                  {this.props.message.sender !== "bot" ? 
                  <img crossOrigin="anonymous" src={(this.props.userInfo[this.props.message.sender] !== undefined ? (this.props.userInfo[this.props.message.sender].photo !== "" ? (this.props.userInfo[this.props.message.sender].photo.indexOf("www.superceed.com") === -1 ? "https://www.superceed.com" : "")+this.props.userInfo[this.props.message.sender].photo : ("https://www.superceed.com/images/system/default_4.png")) : (this.props.liveChatPhoto && this.props.message.sender !== "visitor" ? this.props.liveChatPhoto : (this.props.isChatbot ? this.props.profilePhoto : "https://www.superceed.com/images/system/default_4.png")))} alt="none" className={(!this.state.showName && !this.state.showDate && !this.props.alwaysDisplay && !this.props.firstMessage ? " hidden no-height" : "")}/>
                  :
                  <img crossOrigin="anonymouse" src="https://www.superceed.com/images/system/chatbotSM01.png"/>
                }
                  {!this.state.showName && !this.state.showDate && !this.props.alwaysDisplay && !this.props.firstMessage && this.props.message.ts  ? <span className="timebox">{this.secToTime(this.props.message.ts - this.props.ts)}</span> : null}
                </div>
                <div className={(this.props.channel === "EMAIL" || this.props.channel === "MANUAL" ? "email-message-bubble " : "")+"main-message-wrapper "+(this.props.message.type === "text/html" && this.props.iframeId.includes(this.props.message.id) ? "full-width" : "")}>
                <div style={(this.props.message.type === "text/html" && this.props.iframeId.includes(this.props.message.id) ? {width:"100%",maxWidth:"100%",minHeight:"1px"}: {backgroundColor:(this.props.username === this.props.message.sender || this.props.message.sender === "agent" ? (this.props.chatColor || "") : "")})} className={(this.props.message.type.indexOf("image") !== -1 ? "no-border " : "")+"msg-bubble-"+(this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot")  && this.props.channel !== "EMAIL" && this.props.channel !== "MANUAL" ? "right" : "left")+(this.state.forceExpand ? " force-expand" : "")+(this.props.message.emotion_2.action ? " "+this.props.message.emotion_2.action.color+(this.props.dark ? " darken-3" : " lighten-4") : "")}>
                  <div className="message-wrapper">  
                    {(this.props.message.type.indexOf("image") !== -1 && this.props.message.type.indexOf("text") === -1 ? <Img loader={
                    <div className="block-center" style={{height:"100px",width:"100px",display:"flex",alignItems:"center",textAlign:"center"}}>
                    <div className='typing-animation block-center'>
                        <div className='bounce1'></div><div className='bounce2'></div><div className='bounce3'></div>
                    </div>                      
                    </div>
                    } onClick={e=> {this.openViewer(this.props.text)}} src={[(this.props.text.indexOf("ticket-cdn.superceeduniverse.com/attachment") !== -1  || this.props.text.indexOf("ticket-api.superceed.com/attachment") !== -1 || this.props.text.indexOf("ticket-api.superceeduniverse.com/attachment") !== -1 || this.props.text.indexOf("ticket-api.hyperceed.com/attachment") !== -1  ? this.props.text+"/resize" : "https://ticket-api.superceed.com/image/resize?url="+encodeURIComponent(this.props.text)),]} style={{backgroundColor:'#ffffff',cursor:'zoom-in',borderRadius:'5px'}} unloader={
                      
                      (this.validURL(this.props.text) && this.props.message.type.indexOf("video") === -1 && this.props.message.type.indexOf("audio") === -1 && this.props.rich && this.state.metaData !== null ? 
                        <React.Fragment>
                          <Linkify componentDecorator={componentDecorator}>{this.props.text}</Linkify>
                          {
                            this.state.metaData.map((obj,i)=>(
                              <div key={i}>
                                <RichLink token={this.props.token} forceExpand={this.forceExpand} openViewer={this.openViewer} message={obj.url} meta={obj}/>
                              </div>
                            ))
                          }
                          
                        </React.Fragment>
                      : null)                       
                      
                      /*any valid react element */} className="message-image"/> : 
                          null
                      
                      )}
                      {(this.props.rich ? <div className="hidden-div"></div> : null)}
                      {(this.props.message.type === "text" ? <div className="text-wrapper">
                      {/* {
                        this.props.message.hasKb ? <Tippy content={
                          <div>{
                          this.props.message.kbData.qa ? 
                          <div>
                            <div className="tippy-text">Q: {this.props.message.kbData.qa.q.en}</div>
                            <div className="tippy-text">A: {this.props.message.kbData.qa.a.en}</div>
                          </div>
                                                    
                          :
                          ""
                          }
                          </div>
                        }>
                          
                          <span className="linked" onClick={()=>this.props.kbDataHighlight(this.props.message.id)}>
                          {
                            emoji("💡",{
                            baseUrl: '//cdnjs.cloudflare.com/ajax/libs/emojione/2.2.5/assets/png/',
                            size: '',
                            props:{class:"emoji-image-2"}
                          })}
                            </span></Tippy>: null
                      }                         */}
                        {(this.props.viewTranslated  ? 
                         
                           (this.props.message.sender !== "translatebot" ?
                        <Tippy content={this.props.originalText}>
                          <Language/>
                        </Tippy>
                        :
                        null
                          )
                        : 
                        null)}
                      <Linkify componentDecorator={(href,text,key)=>componentDecorator(href,text,key,this.props.linkColor)} style={{color:"#000000"}}> 
                      {this.props.message.emotion && this.props.emotion && Array.isArray(this.props.message.emotion_2) ? 
                        (
                          this.props.message.emotion.length > 0 ?
                          <Tippy content={<span>{this.getEmotionPercentage(this.props.message.emotion[0])}%</span>}>
                            <span className={((this.props.message.emotion) && this.props.emotion ? (this.props.message.emotion.length > 0 ? this.getEmotionColor(this.props.message.emotion[0]) : ""):"")} style={(this.props.username === this.props.message.sender || this.props.message.sender === "agent" ? (this.props.chatColor ? {color:Color(this.colorTest(this.props.chatColor)).isDark() ? "#fefefe" : "#545454"} : {}):{})}>{replacedText}</span>
                          </Tippy>
                          :
                            <span className={(this.props.message.emotion && this.props.emotion ? (this.props.message.emotion.length > 0 ? this.getEmotionColor(this.props.message.emotion[0]) : ""):"")} style={(this.props.username === this.props.message.sender || this.props.message.sender === "agent" ? (this.props.chatColor ? {color:Color(this.colorTest(this.props.chatColor)).isDark() ? "#fefefe" : "#545454"} : {}):{})}>{replacedText}</span>
                        )
                      : 
                      (((this.props.emotion && this.props.message.sender === "customer" ) || (this.props.agentEmotion && this.props.message.sender === "agent")) && !Array.isArray(this.props.message.emotion_2) && this.props.message.emotion_2 ? 
                      (this.props.message.emotion_2.result ?
                      // <Tippy content={<span>{this.props.message.emotion_2.action ? this.props.message.emotion_2.action.text : ""}</span>}>
                        <div className="inline-block">
                          <div className="valign-wrapper">
                          {this.props.emotion || this.props.agentEmotion ? 
                          <Tippy content={<span>{this.props.message.emotion_2.result}</span>}>
                           <span className={( "chat-border-bottom" )} style={(this.props.username === this.props.message.sender || this.props.message.sender === "agent" ? (this.props.chatColor ? {color:Color(this.colorTest(this.props.chatColor)).isDark() ? "#fefefe" : "#545454",borderColor:emotionColor[this.props.message.emotion_2.result.toLowerCase()]} : {borderColor:emotionColor[this.props.message.emotion_2.result.toLowerCase()]}):{borderColor:emotionColor[this.props.message.emotion_2.result.toLowerCase()]})}>{replacedText}</span>
                           </Tippy>
                           : null
                           }
                        </div>
                        </div>
           
                       : 
                        <span style={(this.props.username === this.props.message.sender || this.props.message.sender === "agent" ? (this.props.chatColor ? {color:Color(this.colorTest(this.props.chatColor)).isDark() ? "#fefefe" : "#545454"} : {}):{})}>{replacedText}</span>
                      )
                      :
                      <span style={(this.props.username === this.props.message.sender || this.props.message.sender === "agent" ? (this.props.chatColor ? {color:Color(this.colorTest(this.props.chatColor)).isDark() ? "#fefefe" : "#545454",borderColor:emotionColor[this.props.message.emotion_2 !== undefined ? this.props.message.emotion_2.result.toLowerCase() : ""]} : {}):{})}>{replacedText}</span>
                      )
                    }                       
                        
                      </Linkify>
                      {
                          this.props.message.translated_transcription ?
                          <div>
                            <Language/>
                            <span>{this.props.message.translated_transcription}</span>
                            </div>
                          :
                          null
                        }  
                      </div> : 
                      (
                        this.props.message.type.indexOf("text/html") !== -1 ?
                        <React.Fragment>
                          {(this.props.iframeId.includes(this.props.message.id) ? 
                              <div>
                                <div className={"iframe-container "+(!this.state.showCollapse && this.showCollapseMessageButton ? "collapsed-html" : "")}>
                                  <Iframe resizeHeight={this.resizeHeight} content={this.props.text} />                                   
                               </div>
                                {(this.showCollapseMessageButton  ? <button className="button-new" onClick={()=>{this.setState({showCollapse:!this.state.showCollapse})}}>Show {this.state.showCollapse ? "Less" : "More"}</button> : null)}
                               </div>
                            
                            // <div className="text-wrapper parsed-html">

                            //   {parse(this.getBodyHTML(this.props.text))}
                            // </div>
          
                       
                          :
                          
                          <div className={"text-wrapper parsed-html "}>
                            {/* {parse(this.getBodyHTML(this.props.text))} */}
                            <div className={(!this.state.showCollapse && this.showCollapseMessageButton ? "collapsed-html" : "")+" overflow-hidden"}>
                              <span className="email-text">
                                <Linkify componentDecorator={componentDecorator}>{this.convertHTML(this.props.text)}</Linkify>
                              </span>
                              </div>
                            {(this.showCollapseMessageButton  ? <button className="button-new" onClick={()=>{this.setState({showCollapse:!this.state.showCollapse})}}>Show {this.state.showCollapse ? "Less" : "More"}</button> : null)}
                          </div>
                          )}
                        </React.Fragment>
                        :
                        null
                      )
                      
                      )} 

                      { (this.props.message.type !== "text" && this.props.message.type !== "text/html" && this.props.message.type.indexOf("image") === -1 && this.props.message.type.indexOf("audio") === -1 && this.props.message.type.indexOf("video") === -1 ? 
                        <FileInfo info={this.props.message.file} message={this.props.text} type={this.props.message.type}/> : 
                        (this.props.message.type.indexOf("video") !== -1 ?
                        <div className='video-wrapper'>
                          <ReactPlayer
                            className="react-player"
                            url={this.props.text}
                            playing={false}
                            light={true}
                            controls={true}
                            width="280px"
                            height="100%"
                            volume={1}
                            muted={false}
                            onClick={this.forceExpand}
                            config={{ 
                              youtube:{
                                playerVars: { showinfo: 1 } 
                              }                                
                            }}
                            />
                        </div> : 
                        (this.props.message.type.indexOf("audio") !== -1 ?
                            <CustomAudioPlayer src={this.props.text}/>  : null                     
                        )
                        )) }   
                                        
                  </div> 
                  {/* {(this.props.rich ? <div className="hidden-div"></div> : null)}
                  {(!this.state.isLink? <span><Linkify componentDecorator={componentDecorator}>{this.props.text}</Linkify></span> : null)} */}
                  {/* <span>
                  { (this.validURL(this.props.text) && this.state.isLink && this.props.rich ? <ReactTinyLink
                      cardSize="small"
                      showGraphic={true}
                      maxLine={2}
                      minLine={1}
                      url={this.properURL(this.props.text)}
                   /> : null)}
                                       
                  </span>                                    */}



                  
                </div>  

                {
                      (this.validURL(this.props.text) && this.props.message.type.indexOf("video") === -1 && this.props.message.type.indexOf("image") === -1 && this.props.message.type.indexOf("audio") === -1  && this.props.message.type.indexOf("text/html") === -1 && this.props.rich && this.state.metaData !== null ? 
                      <React.Fragment>
                        {/* <Linkify componentDecorator={componentDecorator}>{this.props.text}</Linkify> */}
                        {
                          this.state.metaData.map((obj,i)=>(
                            <div key={i}>
                              <RichLink token={this.props.token} forceExpand={this.forceExpand} openViewer={this.openViewer} message={obj.url} meta={obj}/>
                            </div>
                          ))
                        }
                      </React.Fragment>
                    : 
                    (
                      this.props.message.type.indexOf("image") === -1 && this.props.message.type.indexOf("audio") === -1 && this.props.message.type.indexOf("video") === -1 && this.props.message.type.indexOf("text/html") === -1 ?
                      <React.Fragment>
                      {/* <Linkify componentDecorator={componentDecorator}>                        
                      {replacedText}
                      </Linkify> */}
                      
                      {(
                        this.props.rich && this.state.metaData !== null ?
                        (
                          this.state.metaData.map((obj,i)=>(
                            <div key={i}>
                              <RichLink token={this.props.token} forceExpand={this.forceExpand} openViewer={this.openViewer} message={obj.url} meta={obj}/>
                            </div>
                          ))
                        )
                      :
                        null
                      )}
                    </React.Fragment>  
                    
                    :
                      null
                    
                     
                     )
                    )    
}
{/* <div className="pill-outline">
  <h6>{this.props.message.context.text}</h6>
</div>
<div className="pill-outline">
<h6>{this.props.message.emotion.text}</h6>
</div> */}
<div className="clearfix"></div>
  {
    (this.props.message.notes !== undefined ? (this.props.message.notes.length > 0 ? 
      <div>
        <div className={("unselectable note-holder " )+(this.state.noteExpanded ? "expanded" : "")}>
          <div className="valign-wrapper linked" onClick={this.expandNote}>
            <NoteOutlined style={{fontSize: "18px",marginRight:"5px"}}/><div className="note-title">{ this.props.message.notes.length } {this.props.message.notes.length > 1 ? "notes" : "note"}</div>
            {this.state.noteExpanded ? <ExpandLess/> : <ExpandMore/>}
          </div>
          {
            this.state.noteExpanded ? 
              <div>
                {
                  
                }
                {this.props.message.notes.map((obj,i) => (
                  <React.Fragment>
                    <div>
                      {this.props.getInfo(obj.sender)}
                      <div className="valign-wrapper note-list-container" key={i}>
                        <span className="note-sender bold-text">{( this.props.userInfo[obj.sender] !== undefined ? this.props.userInfo[obj.sender].name : obj.sender)}</span>
                        <span className="note-text">{obj.text}</span>
                        <span className="note-timestamp"><Moment unix format="D MMMM YYYY kk:mm">{obj.ts}</Moment></span>
                        {(this.props.username === obj.sender ? 
                          <Tippy content={"Click again to remove"} trigger="manual" visible={this.state.noteToRemove.includes(obj.id)}>
                            <Close className="note-delete linked" onClick={()=>this.removeNote(obj.id)}/>
                          </Tippy>
                           : null)}
                      </div>
                    </div>
                  </React.Fragment>
                ))}
                </div>
            :
            null
          }
        </div>
        </div>
      
      :null
      )
      :
      null)
  }
  {
    (this.props.message.sender === "translatebot" ? 
    <div>
      <button className="option-button unselectable" onClick={this.props.openTranslate}>Translate</button>
      <button className="option-button unselectable" onClick={()=>this.props.deleteMessage(this.props.message.id,this.props.ticketId)}>I can type in that language</button>
    </div> : null)
  }
  {/* {
        (
          this.props.message.personality ? 
          
            (this.props.message.personality.result ? <img src={this.props.message.personality.result === "Controller" ?  ControllerImg : (this.props.message.personality.result === "Entertainer" ?  EntertainerImg : (this.props.message.personality.result === "Thinker" ?  ThinkerImg : (this.props.message.personality.result === "Feeler" ?  FeelerImg : ThinkerImg)))} style={{width:'40px'}}/> : null)
            :
            null
        )
  } */}
  <div className="nested-action">
  {
    <div className="relative-content action-container">
          {
        this.props.message.call_emotion ?
                <div className={"replied-message-container-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL2" && this.props.channel !== "MANUAL2" ? "right" : "left")}>
                <div className="replied-message-wrapper">
                  <div>  
                    <div className="label">Voice Emotion:</div>
                  <div>
                    {/* <ReplyRounded className="replied-message-icon"/> */}                  
                    <span className="replied-text">{this.props.message.call_emotion.emotion_data.emotion ? this.props.message.call_emotion.emotion_data.emotion : ""}</span>
                  </div>
                  <div className="label">Amplitude:</div>
                  <div>
                    {/* <ReplyRounded className="replied-message-icon"/> */}                  
                    <span className="replied-text">{this.props.message.call_emotion.emotion_data.emotion ? this.props.message.call_emotion.emotion_data.amplitude : ""}</span>
                  </div>
                  <div className="label">Pace:</div>
                  <div>
                    {/* <ReplyRounded className="replied-message-icon"/> */}                  
                    <span className="replied-text">{this.props.message.call_emotion.emotion_data.emotion ? this.props.message.call_emotion.emotion_data.pace : ""}</span>
                  </div>
                </div>
                </div>
              </div>
              :
              null
              }
      {
        this.props.message.emotion_2.action ?
                <div className={"replied-message-container-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL2" && this.props.channel !== "MANUAL2" ? "right" : "left")}>
                <div className="replied-message-wrapper">
                  <div>  
                    <div className="label">{this.props.message.emotion_2.action ? this.props.message.emotion_2.action.action : ""}</div>
                  <div>
                    {/* <ReplyRounded className="replied-message-icon"/> */}                  
                    <span className="replied-text">{this.props.message.emotion_2.action ? this.props.message.emotion_2.action.text : ""}</span>
                  </div>
                </div>
                </div>
              </div>
              :
              null
              }
    </div>
  }    
  {
    (
      this.props.message.context ? 
      
      (this.props.message.context.text ? 
      <div className="relative-content action-container">
        <div className={"replied-message-container-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL2" && this.props.channel !== "MANUAL2" ? "right" : "left")}>
            <div className="replied-message-wrapper"> 
              <div className="flex-col">  
                <div> 
                  <div className="label">Context</div>                    
                </div>
                <span className="replied-text"> {this.props.message.context.text}</span>
               {this.props.message.context.suggestion ?
               <React.Fragment>
                <div> 
                  <div className="label">Suggestion</div>                    
                </div>
                <span className="replied-text"> {this.props.message.context.suggestion}</span> 
                </React.Fragment>
                :
                null
              }               
              </div>       
            </div>
          </div> 
      </div>
        : null)
      
     : null   
    )
  }
  {
    (
      this.props.message.negative ? 
      
      (this.props.message.negative.pair ? 
      <div className="relative-content action-container">
        <div className={"replied-message-container-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL2" && this.props.channel !== "MANUAL2" ? "right" : "left")}>
            <div className="replied-message-wrapper"> 
              <div className="flex-col">  
                <div> 
                  <div className="label">Positive Positioning</div>                    
                </div>
                <span className="replied-text"> {this.props.message.negative.pair}</span>
              </div>       
            </div>
          </div> 
      </div>
        : null)
      
     : null   
    )
  }
 {
    (
      this.props.message && (parseFloat(this.props.value.threshold5) < this.props.message.rec_confidence) ? 
      
        (this.props.message ? 
          <React.Fragment>
            
            {
            this.props.message.classification ? 
            localStorage.getItem((this.props.message.classification).toLowerCase()+"_Recommendation") !== "no" ? <div className="relative-content action-container">
              <div className={"replied-message-container-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL2" && this.props.channel !== "MANUAL2" ? "right" : "left")}>
                  <div className="replied-message-wrapper"> 
                    <div className="flex-col">  
                      <div> 
                        <div className="label">{this.props.message.classification}</div>                    
                      </div>
                      <span className="replied-text"> {this.props.message.personality.result}</span>
                      {/* <span className="replied-text"> {this.props.message.handle}</span> */}
                    </div>       
                  </div>
                </div> 
            </div>: null
            :
            null
            }
            {
              (this.props.message.keywin ? 
                <div className="relative-content action-container">
                <div className={"replied-message-container-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL2" && this.props.channel !== "MANUAL2" ? "right" : "left")}>
                    <div className="replied-message-wrapper"> 
                      <div className="flex-col">  
                        <div> 
                          <div className="label">Key Win</div>                    
                        </div>
                        <span className="replied-text pre-wrap">{this.props.message.keywin}</span>
                      </div>       
                    </div>
                  </div> 
              </div>
                :
                null
              )
      }{
              (this.props.message.traits ? 
                <div className="relative-content action-container">
                <div className={"replied-message-container-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL2" && this.props.channel !== "MANUAL2" ? "right" : "left")}>
                    <div className="replied-message-wrapper"> 
                      <div className="flex-col">  
                        <div> 
                          <div className="label">Character Traits</div>                    
                        </div>
                        <span className="replied-text pre-wrap">{this.props.message.traits}</span>
                      </div>       
                    </div>
                  </div> 
              </div>
                :
                null
              )
              }{
              (this.props.message.handle ? 
                <div className="relative-content action-container">
                <div className={"replied-message-container-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL2" && this.props.channel !== "MANUAL2" ? "right" : "left")}>
                    <div className="replied-message-wrapper"> 
                      <div className="flex-col">  
                        <div> 
                          <div className="label">How to Handle</div>                    
                        </div>
                        <span className="replied-text pre-wrap">{this.props.message.handle}</span>
                      </div>       
                    </div>
                  </div> 
              </div>
                :
                null
              )
            }{
              localStorage.getItem(("what to do").toLowerCase()+"_Recommendation") !== "no" ?
              (this.props.message.what_to_do ? 
                <div className="relative-content action-container">
                <div className={"replied-message-container-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL2" && this.props.channel !== "MANUAL2" ? "right" : "left")}>
                    <div className="replied-message-wrapper"> 
                      <div className="flex-col">  
                        <div> 
                          <div className="label">What to do</div>                    
                        </div>
                        <span className="replied-text pre-wrap">{this.props.message.what_to_do}</span>
                      </div>       
                    </div>
                  </div> 
              </div>
                :
                null
              )
              :
              null
            }
            {
              localStorage.getItem(("what to say").toLowerCase()+"_Recommendation") !== "no" ?
              (this.props.message.what_to_say ? 
                <div className="relative-content action-container">
                <div className={"replied-message-container-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL2" && this.props.channel !== "MANUAL2" ? "right" : "left")}>
                    <div className="replied-message-wrapper"> 
                      <div className="flex-col">  
                        <div> 
                          <div className="label">What to say</div>                    
                        </div>
                        <span className="replied-text pre-wrap">{this.props.message.what_to_say}</span>
                      </div>       
                    </div>
                  </div> 
              </div>
                :
                null
              )
              :
              null
            }
            {
              localStorage.getItem(("how to say").toLowerCase()+"_Recommendation") !== "no" ?
              (this.props.message.how_to_say ? 
                <div className="relative-content action-container">
                <div className={"replied-message-container-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL2" && this.props.channel !== "MANUAL2" ? "right" : "left")}>
                    <div className="replied-message-wrapper"> 
                      <div className="flex-col">  
                        <div> 
                          <div className="label">How to say</div>                    
                        </div>
                        <span className="replied-text pre-wrap">{this.props.message.how_to_say}</span>
                      </div>       
                    </div>
                  </div> 
              </div>
                :
                null
              )
              :
              null
            }
        </React.Fragment>
          : null)
        
      
     : null   
    )
  }

{
    (
      this.props.message.alternative ? 
      
      (this.props.message.alternative.type ? 
      <div className="relative-content action-container">
        <div className={"replied-message-container-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL2" && this.props.channel !== "MANUAL2" ? "right" : "left")}>
            <div className="replied-message-wrapper"> 
              <div className="flex-col">  
                <div> 
                  <div className="label">{this.props.message.alternative.type}</div>                    
                </div>
                <span className="replied-text"> {this.props.message.alternative.data}</span>
              </div>       
            </div>
          </div> 
      </div>
        : null)
      
     : null   
    )
  }

{
    (
      this.props.message.energy ? 
      
      (this.props.message.energy ? 
      <div className="relative-content action-container">
        <div className={"replied-message-container-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL2" && this.props.channel !== "MANUAL2" ? "right" : "left")}>
            <div className="replied-message-wrapper"> 
              <div className="flex-col">  
                <div> 
                  <div className="label">Energy</div>                    
                </div>
                
                {(this.props.message.energy ? <span className="replied-text">{ (this.props.message.energy === "loud" ? "Your energy is too high. Lower your energy." : (this.props.message.energy === "soft" ? "Your energy is too low. Raise your energy." : "" ))}</span> : null)}
              </div>       
            </div>
          </div> 
      </div>
        : null)
      
     : null   
    )
  }
  {
    (
      this.props.message.pace ? 
      
      (this.props.message.pace ? 
      <div className="relative-content action-container">
        <div className={"replied-message-container-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL2" && this.props.channel !== "MANUAL2" ? "right" : "left")}>
            <div className="replied-message-wrapper"> 
              <div className="flex-col">  
                <div> 
                  <div className="label">Pace</div>                    
                </div>
                {(this.props.message.pace ? <span className="replied-text">{ (this.props.message.pace === "fast" ? "You're talking too fast. Speak slower." : (this.props.message.pace === "slow" ? "You're talking too slow. Speak faster." : "" ))}</span> : null)}
                
              </div>       
            </div>
          </div> 
      </div>
        : null)
      
     : null   
    )
  }
{
    (
      this.props.message.advocacy ? 
      
      (this.props.message.advocacy.result.length > 0 ? 
      <div className="relative-content action-container">
        <div className={"replied-message-container-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL2" && this.props.channel !== "MANUAL2" ? "right" : "left")}>
            <div className="replied-message-wrapper"> 
              <div className="flex-col">  
                <div> 
                  <div className="label">Advocacy</div>                    
                </div>
                {this.props.message.advocacy.result.map((obj,i) => (
                  <span className="replied-text pre-wrap" key={i}>• {obj}</span>
                  ))
                }
              </div>       
            </div>
          </div> 
      </div>
        : null)
      
     : null   
    )
  }
{
    (
      this.props.message.forwardres ? 
      
      (this.props.message.forwardres.result.length > 0 ? 
      <div className="relative-content action-container">
        <div className={"replied-message-container-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL2" && this.props.channel !== "MANUAL2" ? "right" : "left")}>
            <div className="replied-message-wrapper"> 
              <div className="flex-col">  
                <div> 
                  <div className="label">Forward Resolution</div>                    
                </div>
                {this.props.message.forwardres.result.map((obj,i) => (
                  <span className="replied-text pre-wrap" key={i}>• {obj}</span>
                  ))
                }
              </div>       
            </div>
          </div> 
      </div>
        : null)
      
     : null   
    )
  }
{
    (
      this.props.message.anchor ? 
      
      (this.props.message.anchor.result.length > 0 ? 
      <div className="relative-content action-container">
        <div className={"replied-message-container-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL2" && this.props.channel !== "MANUAL2" ? "right" : "left")}>
            <div className="replied-message-wrapper"> 
              <div className="flex-col">  
                <div> 
                  <div className="label">Anchoring</div>                    
                </div>
                {this.props.message.anchor.result.map((obj,i) => (
                  <span className="replied-text pre-wrap" key={i}>• {obj}</span>
                  ))
                }
              </div>       
            </div>
          </div> 
      </div>
        : null)
      
     : null   
    )
  }

  {
                        this.props.message.hasKb ? 
                      <div className="relative-content action-container">
                        <div className={"replied-message-container-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || (!this.props.reversed && this.props.message.sender === "chatbot"))  && this.props.channel !== "EMAIL2" && this.props.channel !== "MANUAL2" ? "right" : "left")}>
                        <div className="replied-message-wrapper">  
                            {
                          this.props.message.kbData.qa ? 
                          <div className="flex-col">
                            <div> 
                              <div className="label">Knowledgebase</div>                    
                            </div>
                            <div className="action-kb">
                              <h6 className="tippy-text">Q: {this.props.message.kbData.qa.q.en}</h6>
                              <h6 className="tippy-text">A: {this.props.message.kbData.qa.a.en}</h6>
                            </div>
                          </div>
                                                    
                          :
                          ""
                          }
                          </div>
                        </div>
                      </div>
                        :
                        null
           
}
  {/* {
    (
      this.props.message.emotion ? 
      
      (this.props.message.emotion.text !== "" ? <button className="option-button unselectable" >{this.props.message.emotion.text}</button> : null)
      
     : null    
    )
  } */}
  </div>
</div>
                {((this.props.showOverflowMenu) ? <div className={"option-block-"+((this.props.username === this.props.message.sender || this.props.message.sender === "agent" || this.props.message.sender === "chatbot")  && this.props.channel !== "EMAIL" && this.props.channel !== "MANUAL" ? "right" : "left")} ref={e=>this.overflowMenuButton=e} onClick={this.showOverflowMenu}>
                  <MoreHoriz className="linked"/>                 
                </div> : null)}                   
              </div>
              
              <div className="clearfix"></div>
            </div>
            </React.Fragment>

            </div>

        )
      }

    }
}

const mapDispatchToProps = dispatch => ( {
  thresholdChanged: (value) => 
  {
      dispatch({type: 'threshold',value:value});
  }
} )
const mapStateToProps = ( state ) => ( {
  value: state
} )


export default connect(mapStateToProps,mapDispatchToProps)(ConversationItem)
