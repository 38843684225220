import React, { Component } from 'react'
import axios from 'axios';
import {Search,Call} from '@material-ui/icons'
import GiphyIcon from './../../../images/Poweredby_100px-Black_VertText.png';
//import GiphyIconDark from './../../../images/Poweredby_100px-White_VertText.png';
//import VisibilitySensor from 'react-visibility-sensor';
import { Scrollbars } from 'react-custom-scrollbars';
import Variable from './../../../variable';

export default class DialPad extends Component {

    host = Variable.host;

    state = {
        categories : [],
        search : [],
        keyword : "",
        trendingImage : "",
        firstLoad: true,
        giphy:[],
        mode: "tenor",
        giphyTrending: []
    }

    mounted = false;
    searchTimeout;

    constructor(props){
        super(props);
        // this.load = this.loadCategory.bind(this);
    }

    componentDidMount(){
        this.mounted = true;
        // this.props.loadCategory(this.load);
        //this.loadCategory();
    }

    componentWillUnmount(){
        this.mounted = false;     
    }

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }    

    loadCategory = () => {
        // if(this.state.firstLoad){
        //     this.setState({firstLoad:false})
        //     axios.get('https://api.tenor.com/v1/categories?key=1QITS94KC2SS')
        //     .then(res => {
        
        //     if(this.mounted){
        //         this.setState({categories:res.data.tags});
        //     }
        
        //     })
        //     .catch(err =>{
        //     console.log(err);
        //     });   
            
        //     axios.get('https://api.tenor.com/v1/trending?key=1QITS94KC2SS&limit=1&locale=en_US&media_filter=minimal')
        //     .then(res => {
        
        //     if(this.mounted){
        //         this.setState({trendingImage:res.data.results[0].media[0].gif.url});
        //     }
        
        //     })
        //     .catch(err =>{
        //     console.log(err);
        //     });     
        // }     
    }

    search = (text) => {
        let url = '';
        let params = {};
        if(text === 'trending'){
            if(this.state.mode === "tenor"){
                url = 'https://api.tenor.com/v1/trending?key=1QITS94KC2SS&locale=en_US&media_filter=minimal';
            }
            else if(this.state.mode === "giphy"){
                url = this.host+"/giphy/trending";
                params = {_token:this.getCookie("auth")};
            }
        }
        else if (text === "random"){
            if(this.state.mode === "tenor"){
                url = 'https://api.tenor.com/v1/search?key=1QITS94KC2SS&q='+text+'&locale=en_US&media_filter=minimal';
            }
            else if(this.state.mode === "giphy"){
                url = this.host+"/giphy/random";
                params = {_token:this.getCookie("auth")};
            }            
        }
        else{
            if(this.state.mode === "tenor"){
                url = 'https://api.tenor.com/v1/search?key=1QITS94KC2SS&q='+text+'&locale=en_US&media_filter=minimal';
            }
            else if(this.state.mode === "giphy"){
                url = this.host+"/giphy/search";
                params = {_token:this.getCookie("auth"),keyword:text};
            }
        }
        if(this.state.mode === "tenor"){
            axios.get(url)
            .then(res => {
        
            if(this.mounted){
                if(res.data.results !== undefined){
                    this.setState({search:res.data.results});
                }
                if(res.data.data !== undefined){                
                    if(text === 'trending'){
                        this.setState({giphy:res.data.data,giphyTrending:res.data.data});
                    }
                    else{
                        this.setState({giphy:res.data.data});
                    }
                }
            }
        
            })
            .catch(err =>{
            console.log(err);
            });      
        }
        else{
            axios.get(url,{params:params},{withCredentials: true})
            .then(res => {
        
            if(this.mounted){
                if(res.data.results !== undefined){
                    this.setState({search:res.data.results});
                }
                if(res.data.data !== undefined){                
                    if(text === 'trending'){
                        this.setState({giphy:res.data.data,giphyTrending:res.data.data});
                    }
                    else{
                        this.setState({giphy:res.data.data});
                    }
                }
            }
        
            })
            .catch(err =>{
            console.log(err);
            });                
        }  
    } 
    
    textboxChaged = (e) => {
        this.setState({keyword:e.target.value,search:[],giphy:[]},()=>{
            this.props.setPhoneNumber(this.state.keyword)
        })
        // clearTimeout(this.searchTimeout);
        // if(e.target.value.length > 0){
        //     this.searchTimeout = setTimeout(()=>{
        //         this.search(this.state.keyword);
        //     },1000);
        // }
        // else if(this.state.mode === "giphy"){
        //     this.setState({giphy:this.state.giphyTrending});
        // }
    }

    jumpToCategory = (value) => {
        this.setState({search:[],giphy:[]});
        this.searchBox.value = value;
        this.setState({keyword:value});
        this.search(value);
    }

    switchMode = (mode) => {
        this.setState({search:[],giphy:[],mode:mode});
        this.searchBox.value = ""; 
        this.setState({keyword:""});  
        if(mode === "giphy"){
            if(this.state.giphyTrending.length === 0){
                setTimeout(()=>{
                    this.search("trending");
                },100)
            
            }
            else{
                this.setState({giphy:this.state.giphyTrending});
            }
            
        }     
    }

    inputKey = (key) => {
        this.setState({keyword:this.state.keyword+key},()=>{
            this.props.setPhoneNumber(this.state.keyword)
        });  
    }

    render() {
        return (
            <div className={"flex-col z-depth-1 "+(this.props.dark ? "grey darken-4" : "white")} style={{borderRadius:'10px'}}>
                <div className="overflow-hidden flex-col">
                    {(this.props.gifPickerShow ? <input onKeyDown={(e)=>{
                        if(e.keyCode === 13){
                            if(this.state.keyword.length > 0){
                                this.props.goToCall();
                                this.props.pickup();
                            }
                        }
                    }} value={this.state.keyword} ref={e => this.searchBox = e} onChange={e => this.textboxChaged(e)} placeholder={"Input Phone Number"} type="text" className="no-default-highlight no-margin" style={{padding:'5px 10px',width:'230px'}}/>: null)}
                    {/* {(this.state.keyword.length === 0 ? <Search style={{position:'absolute',right:'10px',top:'15px'}}/> : null)} */}
                    {(this.state.keyword.length > 0 ? <Call onClick={this.props.pickup} className="linked" style={{position:'absolute',right:'10px',top:'15px'}}/> : null)}

                    <div className="divider"></div>
                    <div className="dialpad-container">
                        <div className="dialpad-row">
                            <div className="dialpad-button" onClick={()=>this.inputKey('1')}>1</div>
                            <div className="dialpad-button" onClick={()=>this.inputKey('2')}>2</div>
                            <div className="dialpad-button" onClick={()=>this.inputKey('3')}>3</div>
                        </div>
                        <div className="dialpad-row">
                            <div className="dialpad-button" onClick={()=>this.inputKey('4')}>4</div>
                            <div className="dialpad-button" onClick={()=>this.inputKey('5')}>5</div>
                            <div className="dialpad-button" onClick={()=>this.inputKey('6')}>6</div>
                        </div>
                        <div className="dialpad-row">
                            <div className="dialpad-button" onClick={()=>this.inputKey('7')}>7</div>
                            <div className="dialpad-button" onClick={()=>this.inputKey('8')}>8</div>
                            <div className="dialpad-button" onClick={()=>this.inputKey('9')}>9</div>
                        </div>
                        <div className="dialpad-row">
                            <div className="dialpad-button" onClick={()=>this.inputKey('*')}>*</div>
                            <div className="dialpad-button" onClick={()=>this.inputKey('0')}>0</div>
                            <div className="dialpad-button" onClick={()=>this.inputKey('#')}>#</div>
                        </div>
                    </div>
                </div>
 
            </div>
        )
    }
}
