import React, { Component, useState } from 'react'

import Modal from 'react-modal';

import axios from 'axios';

import Moment from 'react-moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCrown } from '@fortawesome/free-solid-svg-icons';

import { Scrollbars } from 'react-custom-scrollbars';

function Owner(props){

    const [open,setOpen] = useState(false);

    const [tabSelected,setSelected] = useState("owner");
    const [owner,setOwner] = useState([]);
    const [action,setAction] = useState([]);

    const profileModalStyles = {
        content : {
          top                   : 'auto',
          left                  : 'auto',
          right                 : 'auto',
          bottom                : 'auto',
          width:'500px',
          maxHeight:'100vh',
          border: 'none',
          boxShadow: '0 2px 3px 0 rgba(60,64,67,.3),0 6px 10px 4px rgba(60,64,67,.15)',
          borderRadius : '15px',
          padding : '0px'
        },
        overlay : {
          backgroundColor: ('rgba(0,0,0,0.5)')
        }
    };    

    const openModal = () => {
        axios.get(props.host+'/ticket/owner/history', {params:{token:props.getCookie("auth"),ticket_id:props.ticketId}},{withCredentials: true})
        .then(res => {
            if(res.data.result === "success"){
                setOwner(res.data.data.owner);
                setAction(res.data.data.action);
                for(var i = 0; i < res.data.data.user.length; i++){
                    if(props.userInfo[res.data.data.user[i]] === undefined){
                        axios.get(props.host+'/user/profile/info', {params:{token:props.getCookie("auth"),username:res.data.data.user[i]}},{withCredentials: true})
                        .then(res => {
                            props.setInfo(res.data);
                        });
                    }
                }
            }
          
        }   
        )
        setOpen(true);
    }

    const closeModal = () => {
        setOpen(false);
    }

    const afterOpenModal = () => {

    }
    

    return (
        <React.Fragment>
        <Modal
        isOpen={open}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={profileModalStyles}
        contentLabel="Example Modal"
        ariaHideApp={false} 
        closeTimeoutMS={200}       
                                        
        className={""+(props.dark ? "dark" : null)}   

        
      >

        <div className="flex-col" style={{width:'100%',maxHeight:"100vh"}}>
            <div className="ng-modal-main-header" style={{paddingTop:"30px",paddingBottom:0}}>
                <div className="full-width margin-bottom-1x">
                    <h5 className="bold-text-2">Info</h5>
                                         
                </div>
                <div className="custom-tab">
                            <div className={"custom-tab-item unselectable waves-effect "+(tabSelected === "owner" ? "clicked" : "")+(props.dark ? " dark" : "")} onClick={()=>{setSelected("owner");}}>Owner</div> 
                            <div className={"custom-tab-item unselectable waves-effect "+(tabSelected === "action" ? "clicked" : "")+(props.dark ? " dark" : "")} onClick={()=>{setSelected("action");}}>Action</div>                        
                </div>              
            </div>    
            <div className="divider"></div>
            <Scrollbars autoHeight autoHeightMax={300}>
            <div className="ng-modal-main-body padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x flex-row full-height">
                {(tabSelected === "owner" ?
                <div className="full-width">
                    <h6 className="bold-text">Original Owner</h6>
                    <span>{owner.length > 0 ? 
                    (owner[0].original_owner === "" ? <h6>No original owner</h6> :
                        <div className="valign-wrapper">
                            <img className="margin-right-1x avatar-wrapper" src={(props.userInfo[owner[0].original_owner] !== undefined ? (props.userInfo[owner[0].original_owner].photo !== "" ? (props.userInfo[owner[0].original_owner].photo.indexOf("www.superceed.com") === -1 ? "https://www.superceed.com" : "")+props.userInfo[owner[0].original_owner].photo : "https://www.superceed.com/images/system/default.png") : "https://www.superceed.com/images/system/default.png")}/>
                            <h6>
                            {
                                (props.userInfo[owner[0].original_owner] !== undefined ? props.userInfo[owner[0].original_owner].name : "")
                            
                            }
                            </h6>
                        </div>
                    
                    )
                    :
                    <h6>No original_owner</h6>
                    }
                    </span>
                    <h6 className="bold-text">Current Owner</h6>
                    <span>
                        {(owner.length > 0 ? (owner[0].handler === "[]" ? 
                        <h6>No owner</h6> : 
                            JSON.parse(owner[0].handler).map((obj,i)=>(
                                <div className="valign-wrapper margin-bottom-1x" key={i}>
                                    <img className="margin-right-1x avatar-wrapper" src={(props.userInfo[obj] !== undefined ? (props.userInfo[obj].photo !== "" ? (props.userInfo[obj].photo.indexOf("www.superceed.com") === -1 ? "https://www.superceed.com" : "")+props.userInfo[obj].photo : "https://www.superceed.com/images/system/default.png") : "https://www.superceed.com/images/system/default.png")}/>
                                    <h6>
                                    {
                                        (props.userInfo[obj] !== undefined ? props.userInfo[obj].name : "")
                                    
                                    }
                                    </h6>                                    
                                </div>
                            ))
                            ): 
                        <h6>No owner</h6>
                        )}
                    </span>                    
                </div>
                :
                <div className="full-width">
                    {
                        (
                            action.map((obj,i)=>(
                                (obj.action === "TICKET_ESCALATE" ||  obj.action === "LC_ESCALATE" ? 
                                <div className="rounded-corner-10 margin-bottom-1x" style={{padding:"20px",backgroundColor:props.dark ? 'rgba(255,255,255,0.1)':'rgba(0,0,0,0.05)'}} key={i}>
                                    <h6><Moment unix format="D-M-YYYY h:mm:ss a">{obj.ts}</Moment></h6>
                                    <div className="valign-wrapper margin-bottom-1x">
                                        <img className="margin-right-1x avatar-wrapper" src={(props.userInfo[obj.username] !== undefined ? (props.userInfo[obj.username].photo !== "" ? (props.userInfo[obj.username].photo.indexOf("www.superceed.com") === -1 ? "https://www.superceed.com" : "")+props.userInfo[obj.username].photo : "https://www.superceed.com/images/system/default.png") : "https://www.superceed.com/images/system/default.png")}/>
                                        <h6>
                                        {
                                            (props.userInfo[obj.username] !== undefined ? props.userInfo[obj.username].name : "")
                                        
                                        }
                                        </h6>                                    
                                    </div>
                                    <h6>Escalated to</h6>
                                    {obj.department_id !== 0 ?
                                        <h6>{(props.department.filter(department => department.id === obj.department_id).length > 0 ? props.department.filter(department => department.id === obj.department_id)[0].name : "")}</h6>
                                      : null}
                                    <h6>
                                    {(obj.assigned_agent.indexOf(",") !== 0 ? 
                                        obj.assigned_agent.split(",").map((obj2,i)=>(
                                            <div className="valign-wrapper margin-bottom-1x" key={i}>
                                                <img className="margin-right-1x avatar-wrapper" src={(props.userInfo[obj2] !== undefined ? (props.userInfo[obj2].photo !== "" ? (props.userInfo[obj2].photo.indexOf("www.superceed.com") === -1 ? "https://www.superceed.com" : "")+props.userInfo[obj2].photo : "https://www.superceed.com/images/system/default.png") : "https://www.superceed.com/images/system/default.png")}/>
                                                <h6>
                                                {
                                                    (props.userInfo[obj2] !== undefined ? props.userInfo[obj2].name : "")
                                                
                                                }
                                                </h6>   
                                                {(obj.handler === obj2 ? <span style={{marginLeft:"5px"}} data-balloon-blunt aria-label="Owner" data-balloon-pos="right"><FontAwesomeIcon style={{fontSize:"15px"}} icon={faCrown}/></span> : null)}                                                                
                                            </div>
                                        ))
                                        :
                                        <div className="valign-wrapper">
                                            <img className="margin-right-1x avatar-wrapper" src={(props.userInfo[obj.assigned_agent] !== undefined ? (props.userInfo[obj.assigned_agent].photo !== "" ? (props.userInfo[obj.assigned_agent].photo.indexOf("www.superceed.com") === -1 ? "https://www.superceed.com" : "")+props.userInfo[obj].photo : "https://www.superceed.com/images/system/default.png") : "https://www.superceed.com/images/system/default.png")}/>
                                            <h6>
                                            {
                                                (props.userInfo[obj.assigned_agent] !== undefined ? props.userInfo[obj.assigned_agent].name : "")
                                            
                                            }
                                            </h6>     
                                            
                                        </div>
                                    )}
                                    </h6>
                                    
                                </div> :
                                (obj.action === "TICKET_SOLVED" ? 
                                <div className="rounded-corner-10 margin-bottom-1x" style={{padding:"20px",backgroundColor:props.dark ? 'rgba(255,255,255,0.1)':'rgba(0,0,0,0.05)'}} key={i}>
                                <h6><Moment unix format="D-M-YYYY h:mm:ss a">{obj.ts}</Moment></h6>
                                <div className="valign-wrapper margin-bottom-1x">
                                        <img className="margin-right-1x avatar-wrapper" src={(props.userInfo[obj.username] !== undefined ? (props.userInfo[obj.username].photo !== "" ? (props.userInfo[obj.username].photo.indexOf("www.superceed.com") === -1 ? "https://www.superceed.com" : "")+props.userInfo[obj.username].photo : "https://www.superceed.com/images/system/default.png") : "https://www.superceed.com/images/system/default.png")}/>
                                        <h6>
                                        {
                                            (props.userInfo[obj.username] !== undefined ? props.userInfo[obj.username].name : "")
                                        
                                        }
                                        </h6>                                    
                                </div>
                                <h6>Set status to solved.</h6>
                                </div> :
                                null)
                                )
                            ))
                        )
                    }
                </div>)}
            </div>
            </Scrollbars>
            <div className="ng-modal-main-footer right-text">                      
                <button className="btn-flat ng-close-btn waves-effect waves-dark" onClick={closeModal}>close</button>
            </div>            
        </div>          
        </Modal> 
            <button className="btn margin-top-1x" onClick={()=>openModal()}>More Info</button>  
        </React.Fragment>     
    )
}
export default Owner;