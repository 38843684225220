import React from 'react'
import iFrameResize from 'iframe-resizer-react'
//import { width } from '@material-ui/system';

class Iframe extends React.Component {
    
    // static propTypes = {
    //     content: React.PropTypes.string.isRequired
       
    // };

    constructor(props){
        super(props);
        this.state = {
            width:0,
            height:0
        }
    }

    /**
     * Called after mounting the component. Triggers initial update of
     * the iframe
     */
    componentDidMount() {
        this._updateIframe();
        // setTimeout(()=>{
        //     this._updateIframe();
        // },500)
    }

    /**
     * Called each time the props changes. Triggers an update of the iframe to
     * pass the new content
     */
    componentDidUpdate() {
        //this._updateIframe();
    }

    /**
     * Updates the iframes content and inserts stylesheets.
     * TODO: Currently stylesheets are just added for proof of concept. Implement
     * and algorithm which updates the stylesheets properly.
     */
    _updateIframe() {
        const iframe = this.refs.iframe;
        const document2 = iframe.contentDocument;
        //const head = document.getElementsByTagName('head')[0];
        document2.body.innerHTML = this.props.content;
        // console.log(document2);
        var style = document.createElement('style');

        var css = 'body{display:inline-block;}html{position:absolute;font-family:Arial, Helvetica, sans-serif;color:#545454;font-size:13px}';

        style.appendChild(document.createTextNode(css));

        style.type = 'text/css';

        document2.head.appendChild(style);

        // this.props.stylesheets.forEach(url => {
        //     const ref = document.createElement('link');
        //     ref.rel = 'stylesheet';
        //     ref.type = 'text/css';
        //     ref.href = url;
        //     head.appendChild(ref);
        // });
        console.log(document2.body.offsetHeight);
        this.props.resizeHeight(document2.body.offsetHeight);

        this.setState({height:(document2.body.offsetHeight > 0 ? document2.body.offsetHeight : document2.body.scrollHeight) + 20,width:document2.body.offsetWidth + 100});

    }

    loadedIframe = () => {
        
    }

    /**
     * This component renders just and iframe
     */
    render() {
        return <iframe className="email-iframe" onLoad={()=>{
            //const iframe = this.refs.iframe;
            // alert("loaded!");
            
        }} ref="iframe" width={this.state.width} height={this.state.height}/>
    }
}

export default Iframe