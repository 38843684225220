import React, { Component } from 'react'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { Emoji } from 'emoji-mart'

const customEmojis = [
    {
      name: 'Octocat',
      short_names: ['octocat'],
      text: '',
      emoticons: [],
      keywords: ['github'],
      imageUrl: 'https://github.githubassets.com/images/icons/emoji/octocat.png',
      //customCategory: 'GitHub'
    }
];

export default class CustomEmoji extends Component {
    

    // emoji = {
    //     "hokkeinmee":"https://ticket-cdn.superceeduniverse.com/attachment/v101/DdddU69NqASylFK/hokkein+mee.png",
    //     "wantanmee":"https://ticket-cdn.superceeduniverse.com/attachment/v101/YV48c6G9BV5CEpG/wantan+mee.png",
    //     "ayamkarirendang":"https://ticket-cdn.superceeduniverse.com/attachment/v101/37ZhuC4QWEBZ5xn/ayam+kari+rendang.png",
    //     "cheecheongfun":"https://ticket-cdn.superceeduniverse.com/attachment/v101/VwOvrLBqxYSozys/chee+cheong+fun.png",
    //     "asamlaksa":"https://ticket-cdn.superceeduniverse.com/attachment/v101/cStx0pC3CsGzOiJ/asamLaksa.png",
    //     "friedkueytew":"https://ticket-cdn.superceeduniverse.com/attachment/v101/91KeEUOTgaopCNH/fried_kuey_tew.png",
    //     "nasilemak":"https://ticket-cdn.superceeduniverse.com/attachment/v101/iWnvoMFF5BXb1MM/nasilemak.png",
    //     "nasiayam":"https://ticket-cdn.superceeduniverse.com/attachment/v101/INcmh72jM47jqg2/nasi-ayam.png",
    //     "rotitisu":"https://ticket-cdn.superceeduniverse.com/attachment/v101/22qsqEGigasWwO1/roti+tisu.png",
    //     "miloais":"https://ticket-cdn.superceeduniverse.com/attachment/v101/aLnUQDEGQXQicej/milo-ais.png",

    // }

    emoji = {};

    constructor(props){
        super(props);
        this.emoji = this.props.emojiSet;
    }

    render() {

        //let temp = this.props.data;
        //let string = this.props.data.substring(1, this.props.data.length-1);
        return (
            <Tippy content={this.props.data}>
                <div className="inline-block relative-content">
                <Emoji set={'emojione'} size={30} emoji={this.props.data} fallback={(emoji,props)=>{

                    return (
                        (this.emoji.hasOwnProperty(this.props.data.replace(/:/g,'')) ?
                        <React.Fragment>
                        <img className="show-tooltip" style={{maxWidth:100}} src={this.emoji[this.props.data.replace(/:/g,'')]}/>                 
                        </React.Fragment>
                        :
                        ""+this.props.data+""
                        )
                    )
                }}/>
                </div>
            </Tippy>
        )
    }
}
