import React, { useState,useEffect,useRef } from 'react';

import axios from 'axios';

import Variable from '../../variable';

import Dropdown from 'react-bootstrap/Dropdown'

import { IconArrowLeft,IconSquareX } from '@tabler/icons-react';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

export default function QA(props) {

    const host = Variable.host;

    const [template,setTemplate] = useState({});

    const [category,setCategory] = useState([]);

    const [question,setQuestion] = useState("");

    const [mark,setMark] = useState("");

    const [categoryName,setCategoryName] = useState("");

    const [description,setDescription] = useState("");

    const [selectedCategory,setSelectedCategory] = useState(-1);

    const [generating,setGenerating] = useState({});
    

    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }    

    useEffect(()=>{
        loadCategory();
    },[]);

    const loadCategory = () => {
        axios.get(host+'/api/qa/category', {params:{_token:getCookie("auth")}},{withCredentials: true})
        .then(res => {
            setCategory(res.data);
        }   
        )
    }

    const saveCategory = (i) => {
        axios.post(host+'/api/qa/category', {_token:getCookie("auth"),category:category[i]},{withCredentials: true})
        .then(res => {
            loadCategory();
        }   
        )
    }

    const addQuestion = () => {

        if(selectedCategory === -1){
            return false;
        }

        let temp = [...category];

        temp[selectedCategory].question.push({question:question,mark:parseInt(mark)});

        setCategory(temp);
    }

    const copyQuestion = (i,i2) => {


        let temp = [...category];

        temp[i].question.push({...template[i][i2]});

        setCategory(temp);
    }

    const removeQuestion = (i,i2) => {



        let temp = [...category];

        temp[i].question.splice(i2,1);

        setCategory(temp);
    }
    
    const addCategoryName = () => {
        axios.post(host+'/api/qa/category/add', {_token:getCookie("auth"),categoryName:categoryName,description:description},{withCredentials: true})
        .then(res => {
            loadCategory();
        }   
        )
    }

    const deleteCategoryName = (i) => {
        if(window.confirm("Are you sure you want to delete") === true){
            axios.delete(host+'/api/qa/category', {params:{_token:getCookie("auth"),id:category[i].id}},{withCredentials: true})
            .then(res => {
                loadCategory();
            }   
            )
        }
    }

    const generateQuestion = (i) => {
        let questions = [];
        for(let s = 0; s < category[i].question.length;s++){
            questions.push(category[i].question[s].question);
        }
        let gen = {...generating}
        gen[i] = true;
        setGenerating(gen);
        axios.get(host+'/api/qa/template', {params:{_token:getCookie("auth"),body:category[i].description,type:"question",category:category[i].category_name,num_questions:5,existing:questions}},{withCredentials: true})
        .then(res => {
            let temp = {...template};
            temp[i] = res.data.scorecard[0].questions
            setTemplate(temp)
            let gen = {...generating}
            delete gen[i];
            setGenerating(gen);
            // loadCategory();
        }   
        )
    }

    return (
        <div>
            {
                category.map((obj,i)=>(
                    <>
                    <div className='flex-row margin-bottom-1x'>
                        <div>
                            <h4 className='bold-text-2'>{obj.category_name}</h4>
                            <button className={(generating[i] ? "unclickable": "")} onClick={()=>generateQuestion(i)}>Generate Questions</button>
                            <button onClick={()=>deleteCategoryName(i)}>Delete Category</button>
                            {
                                obj.question !== null ?
                                    (
                                        obj.question.map((obj2,i2)=>(
                                            <div className='flex-row' key={i2}>
                                                <div>
                                                    <h6>{obj2.question}</h6>
                                                    <h6>Score: {obj2.mark}</h6>
                                                </div>
                                                <Tippy content="Remove" placement="right">
                                                    <div className='icon-button-hover' onClick={()=>removeQuestion(i,i2)}>
                                                    <IconSquareX
                                                        size={24} 
                                                        className='red-text'
                                                        stroke={2} 
                                                        strokeLinejoin="miter"
                                                    /> 
                                                    </div>
                                                </Tippy>
                                            </div>
                                        ))
                                    )
                                :
                                null
                            }
                            <button className='button-new margin-top-2x margin-bottom-1x' onClick={()=>saveCategory(i)}>Save</button>
                            
                        </div>
                        <div className='margin-left-2x'>
                            <h6 className='bold-text-2'>Template</h6>
                            {
                                generating[i] ? 
                                <h6>Generating...</h6>
                                :
                                null
                            }
                            {
                                template[i] ?
                                    template[i].map((obj2,i2)=>(
                                            <div className='flex-row' key={i2}>
                                                <div>
                                                    <h6>{obj2.question}</h6>
                                                    <h6>Score: {obj2.mark}</h6>
                                                </div>
                                                <Tippy content="Copy" placement="right">
                                                    <div className='icon-button-hover' onClick={()=>copyQuestion(i,i2)}>
                                                        <IconArrowLeft
                                                            size={24} 
                                                            className='green-text'
                                                            stroke={2} 
                                                            strokeLinejoin="miter"
                                                        /> 
                                                    </div>
                                                </Tippy>
                                            </div>
                                    ))

                                :
                                null
                            }
                        </div>
                        
                    </div>
                        <div className='divider margin-bottom-1x'></div>
                    </>
                ))
            }
            <div className='flex-row'>
                <div>
                    <h6 className='bold-text-2'>Add Category</h6>
                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                        <input className="browser-default" type="text" value={categoryName} onChange={(e)=>{setCategoryName(e.target.value)}}/>
                        <span>Category Name</span>
                        <p className="red-text hide">This cannot be empty.</p>
                    </label>
                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                        <input className="browser-default" type="text" value={description} onChange={(e)=>{setDescription(e.target.value)}}/>
                        <span>Description</span>
                        <p className="red-text hide">This cannot be empty.</p>
                    </label>

                    <button className='button-new' onClick={addCategoryName}>Add</button>
                </div>
                <div className='margin-left-2x'>
                    <h6 className='bold-text-2'>Add Question</h6>
                    <span className='input-label'>Select Category</span>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {category[selectedCategory] ? category[selectedCategory].category_name  : "No"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {
                                category.map((obj,i)=>(
                                    <Dropdown.Item eventKey={i} onSelect={()=>{setSelectedCategory(i)}}>{obj.category_name}</Dropdown.Item>
                                ))
                            }
                
                        </Dropdown.Menu>
                    </Dropdown>  
                    
                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x margin-top-2x">
                        <input className="browser-default" type="text" value={question} onChange={(e)=>{setQuestion(e.target.value)}}/>
                        <span>Question</span>
                        <p className="red-text hide">This cannot be empty.</p>
                    </label>
                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                        <input className="browser-default" type="text" value={mark} onChange={(e)=>{setMark(e.target.value)}}/>
                        <span>Score</span>
                        <p className="red-text hide">This cannot be empty.</p>
                    </label>
                    <button className='button-new' onClick={addQuestion}>Add</button>
                </div>
            </div>
        </div>
    );
}

