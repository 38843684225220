import React, { Component, useState, useEffect } from 'react'

import Modal from 'react-modal';

import { Scrollbars } from 'react-custom-scrollbars';

import { Translate,ArrowUpward,ArrowDownward,ArrowRight } from '@material-ui/icons';

function TranslateButton(props){

    const langList = {
        'Not Translated': '',
        'English': 'en',
        'Chinese (China)':'zh-CN',
        'Chinese (Taiwan)':'zh-TW',
        'Malay':'ms'
    }

    const [open,setOpen] = useState(false);
    const [lang,setLang] = useState("");

    const profileModalStyles = {
        content : {
          top                   : 'auto',
          left                  : 'auto',
          right                 : 'auto',
          bottom                : 'auto',
          width:'500px',
          maxHeight:'100vh',
          border: 'none',
          boxShadow: '0 2px 3px 0 rgba(60,64,67,.3),0 6px 10px 4px rgba(60,64,67,.15)',
          borderRadius : '15px',
          padding : '0px'
        },
        overlay : {
          backgroundColor: ('rgba(0,0,0,0.5)')
        }
    };    


    const openModal = () => {
        setOpen(true);
    }

    const closeModal = () => {
        setOpen(false);
    }

    const afterOpenModal = () => {

    }

    const getKeyByValue = (value) => {
        return Object.keys(langList).find(key => langList[key] === value);
    }

    const openEvent = openModal.bind();

    useEffect(()=> {
        props.openTranslate(openEvent);
    },[]);
    

    return (
        <React.Fragment>
        <Modal
        isOpen={open}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={profileModalStyles}
        contentLabel="Example Modal"
        ariaHideApp={false} 
        closeTimeoutMS={200}       
                                        
        className={""+(props.dark ? "dark" : null)}   

        
      >

        <div className="flex-col" style={{width:'100%',maxHeight:"100vh"}}>
            <div className="ng-modal-main-header" style={{paddingTop:"30px",paddingBottom:0}}>
                <div className="full-width flex-row valign-wrapper margin-bottom-1x">
                    <h5 className="bold-text-2">Translate</h5>
                    
                </div>
            
            </div>    
            <Scrollbars autoHeight autoHeightMin={200}>
                <div className="ng-modal-main-body padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x flex-col full-height">
                    <h6>Visitor Language : {props.sourceLang === "" ? 'Not Detected' : getKeyByValue(props.sourceLang)}</h6>
                    <div>
                        <ArrowUpward/><ArrowDownward/>
                    </div>
                    <h6>Agent Language : {getKeyByValue(props.currentLang)}</h6>
                    <div className="full-width">
                    {
                         Object.keys(langList).map((key, index)=> (
                            ( (props.currentLang !== langList[key] && props.sourceLang !== langList[key])  ? <div key={index} onClick={()=>{props.translateFunction(langList[key]);setOpen(false);setLang(langList[key])}} className="inline-block linked hover-tint rounded-corner-10 hover-tint-bg padding-all-1x margin-right-1x">
                                <h6>{key}</h6>
                            </div> : null)
                         )
                         )
                    }
                    </div>
                    {/* {
                        (lang !== "en" ? <div onClick={()=>{props.translateFunction("en");setOpen(false);setLang("en")}} className="linked hover-tint hover-tint-bg padding-all-1x margin-right-1x">
                        <h6>English</h6>
                        </div> : null)
                    } */}
                </div>
            </Scrollbars>
            
            <div className="ng-modal-main-footer right-text">                      
                <button className="btn-flat ng-close-btn waves-effect waves-dark" onClick={closeModal}>close</button>
            </div>            
        </div>          
        </Modal> 
            <div onClick={()=>openModal()} data-balloon-blunt aria-label="Translate" data-balloon-pos="down" className="relative-content icon-button-hover valign-wrapper">                    
                  {/* <Translate/> */}
                  <span className="bold-text nowrap">{(props.sourceLang)}{props.currentLang !== "" ? <React.Fragment><ArrowRight/> {props.currentLang}</React.Fragment> : null}</span>
            </div>
        </React.Fragment>     
    )
}
export default TranslateButton;