import React from 'react';
import ChatListItem from './ChatListItem';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ChatListTitle from './layout/ChatListTitle'
import { connect } from 'react-redux';
import { PhoneInTalk } from '@material-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee,faComment,faEnvelope,faCheck } from '@fortawesome/free-solid-svg-icons'
import { IconPhoneCall} from '@tabler/icons-react';

class ChatList extends React.Component{

  filter = [0,1,2];

  componentDidMount(){
    if(this.props.type === "ticket"){
      this.props.setClicked();
    }
  }

  render() {
    if(this.props.type === "ticket"){
      if(this.props.viewMode === 'all'){
          return (
            <React.Fragment>
              <div className="table-row ticket-list-header">
                <div className="table-cell ticket-cell header-cell" style={{position:"sticky",top:0,paddingLeft:'20px'}}>
                  <span className="bold-text">Channel</span>
                </div>
                <div className="table-cell ticket-cell header-cell" style={{position:"sticky",top:0}}>
                  <span className="bold-text">#</span>
                </div>
                <div className="table-cell ticket-cell header-cell" style={{position:"sticky",top:0}}>
                  <span className="bold-text">Title</span>
                </div>
                <div className="table-cell ticket-cell header-cell" style={{position:"sticky",top:0}}>
                  <span className="bold-text">Label</span>
                </div>
                <div className="table-cell ticket-cell header-cell" style={{position:"sticky",top:0}}>
                  <span className="bold-text">Emotion</span>
                </div>
                <div className="table-cell ticket-cell header-cell" style={{position:"sticky",top:0}}>
                  <span className="bold-text">Case Type</span>
                </div>                
                <div className="table-cell ticket-cell header-cell" style={{position:"sticky",top:0}}>
                  <span className="bold-text">Severity</span>
                </div>                
                <div className="table-cell ticket-cell header-cell" style={{position:"sticky",top:0}}>
                  <span className="bold-text">Date Time</span>
                </div>
              </div>
            {
              this.props.channel.filter(ticket => (ticket.channel === "FB_PM" || ticket.channel === "FB_PAGE_POST" || ticket.channel === "TW_DM" || ticket.channel === "TW_MENTION" || ticket.channel === "LINE_DM" || ticket.channel === "EMAIL" || ticket.channel === "WAUCHAT" || ticket.channel === "MANUAL" || ticket.channel === "WHATSAPP")).map((obj) => (
                <React.Fragment key={obj.id}>
                  <ChatListItem {...this.props} setSelectedId={this.props.setSelectedId} setClicked={this.props.setClicked} dark={this.props.dark} label={this.props.label} type={this.props.type} channel={obj} checkRead={this.props.checkRead} deleteChat={this.props.deleteChat} loadChat={this.props.loadChat}/>
                  {
                    this.props.channel.filter(ticket => (ticket.channel === "FB_PM" || ticket.channel === "FB_PAGE_POST" || ticket.channel === "TW_DM" || ticket.channel === "TW_MENTION" || ticket.channel === "LINE_DM" || ticket.channel === "EMAIL" || ticket.channel === "WAUCHAT" || ticket.channel === "MANUAL" || ticket.channel === "WHATSAPP")).length === 0 ?
                    <h6 className="mid-text">No Ticket</h6>
                    :
                    null
                  }
                </React.Fragment>
              ))
            }
            {
              this.props.channel.filter(ticket => (ticket.channel === "FB_PM" || ticket.channel === "FB_PAGE_POST" || ticket.channel === "TW_DM" || ticket.channel === "TW_MENTION" || ticket.channel === "LINE_DM" || ticket.channel === "EMAIL" || ticket.channel === "WAUCHAT" || ticket.channel === "MANUAL" || ticket.channel === "WHATSAPP")).length === 0 ?
              <h6 className="mid-text">No Ticket</h6>
              :
              null
            }
            
            </React.Fragment>
          )
      }
      else if(this.props.viewMode === 'department'){
        return (
          <React.Fragment>
            {
              this.props.departmentTicket.map((obj,i) => (
                <React.Fragment key={obj.id}>
                <ChatListTitle dark={this.props.dark} extraClass="bold-text" toggleExpand={this.props.toggleExpand} index={i} catId={obj.id} expanded={obj.expanded} showCollapse={true} title={obj.name} showFilterButton="false" showAddButton={false}/>
                {
                  obj.expanded ?
                    <React.Fragment>
                    {
                      obj.data.map((obj,i)=>(
                      <ChatListItem key={obj.id} {...this.props} setSelectedId={this.props.setSelectedId} dark={this.props.dark} label={this.props.label} type={this.props.type} key={obj.id} channel={obj} checkRead={this.props.checkRead} deleteChat={this.props.deleteChat} loadChat={this.props.loadChat}/>
                    ))
                    }
                    {
                      obj.data.length === 0 ?
                      <h6 className="mid-text">No Ticket</h6>
                      :
                      null
                    }  
                    {
                      obj.loadMore ?
                      <div className={"rounded-corner-10 hover-tint-2 linked"} style={{paddingTop:"5px",paddingBottom:"5px"}} onClick={()=>this.props.loadMoreDepartmentTicket(i,obj.id)}>
                                      <h6 className="mid-text bold-text" >Show More...</h6>
                      </div>         
                      :
                      null         
                    }                    
                    </React.Fragment>
                    :
                    null
                } 

                </React.Fragment>              
              ))
            }

        {
          this.props.department.length === 0 ? 
          <h6 className="mid-text">No Department</h6>
          :
          null
        }
        </React.Fragment>
        )
      }
      else if(this.props.viewMode === 'label'){
        return (
          <React.Fragment>
            {
              this.props.labelTicket.map((obj,i) => (
                <React.Fragment key={obj.id}>
                <ChatListTitle toggleExpand={this.props.toggleExpand2} extraClass="bold-text" dark={this.props.dark} index={i} catId={obj.id} expanded={obj.expanded} showCollapse={true} title={obj.name} showFilterButton="false" showAddButton={false}/>
                {
                  obj.expanded ?
                    <React.Fragment>
                    {
                      obj.data.map((obj,i)=>(
                      <ChatListItem key={obj.id} {...this.props} setSelectedId={this.props.setSelectedId} dark={this.props.dark} label={this.props.label} type={this.props.type} key={obj.id} channel={obj} checkRead={this.props.checkRead} deleteChat={this.props.deleteChat} loadChat={this.props.loadChat}/>
                    ))
                    }
                    {
                      obj.data.length === 0 ?
                      <h6 className="mid-text">No Ticket</h6>
                      :
                      null
                    }  
                    {
                      obj.loadMore ?
                      <div className={"rounded-corner-10 hover-tint-2 linked"} style={{paddingTop:"5px",paddingBottom:"5px"}} onClick={()=>this.props.loadMoreLabelTicket(i,obj.id)}>
                                      <h6 className="mid-text bold-text" >Show More...</h6>
                      </div>         
                      :
                      null         
                    }                    
                    </React.Fragment>
                    :
                    null
                } 

                </React.Fragment>              
              ))
            }

        {
          this.props.department.length === 0 ? 
          <h6 className="mid-text">No Department</h6>
          :
          null
        }
        </React.Fragment>
        )
      }      
      else{
        return null;
      }
    }
    else if (this.props.type === "call"){
      return (
        <React.Fragment>
          <div className="margin-top-1x"></div>
          {
            this.props.value.callStatus === "Engaged" ? 
              <div className="left-panel-list-item">
                <Link
                // onClick={(e)=>{this.onItemClick(this.props.channel.id,this.props.channel.ticket_number);this.props.setSelectedId(this.props.channel.id)}}
                className={'left-panel-item linked chat-item-padding ' + (this.props.match.params["0"] ? (this.props.match.params["0"].toString() === "ongoing" ? 'selected z-depth-1' : '') : '')+(this.props.channel.read ?  '' : ' ')} to={'/call/ongoing'}>                        
                <IconPhoneCall 
                    size={24} // set custom `width` and `height`
                    // color={"#78909C"} // set `stroke` color
                    stroke={2}  // set `stroke-width`
                    strokeLinejoin="miter" // override other SVG props
                    className="margin-right-2x green-text"
                />
                <span className="left-panel-item-text">{this.props.value.callerNumber}</span>
                {/* <span className="chat-timestamp" style={{fontSize:'12px'}}>{(this.checkDayDiff(this.props.channel.ts) < 0 && this.checkDayDiff(this.props.channel.ts) > -1  ? <Moment unix format="kk:mm">{this.props.channel.ts}</Moment> :(this.checkDayDiff(this.props.channel.ts) >= 0 && this.checkDayDiff(this.props.channel.ts) < 1 ? "Yesterday" : <Moment unix format="D/M/YYYY">{this.props.channel.ts}</Moment>))}</span> */}
                        
                </Link>
            </div>
            :
            null
          }
          {(this.props.channel.map((obj) => (
            <ChatListItem {...this.props} setSelectedId={this.props.setSelectedId} dark={this.props.dark} type={this.props.type} key={obj.id} channel={obj} checkRead={this.props.checkRead} deleteChat={this.props.deleteChat} loadChat={this.props.loadChat}/>
          )
          ))}
          {
            this.props.channel.length === 0 ?
            <h6 className="mid-text">No item</h6>
            :
            null
          }
        </React.Fragment>
         
      )     
    }
    else if (this.props.type === "livechat"){
      return (
        <React.Fragment>
          {/* <ChatListTitle title="Sessions" showFilterButton="false" dark={this.props.dark} showAddButton={false} extraClass="bold-text"/>           */}
          <div className="margin-top-1x"></div>
            <ChatListItem {...this.props} setSelectedId={this.props.setSelectedId} toggleCollapse={this.props.toggleCollapse} dark={this.props.dark} type={this.props.type} channel={this.props.channel} checkRead={this.props.checkRead} deleteChat={this.props.deleteChat} loadChat={this.props.loadChat}/>
          {/* {(this.props.channel.filter(ticket =>  (ticket.closed === 0)).map((obj) => (
            <ChatListItem {...this.props} dark={this.props.dark} type={this.props.type} key={obj.id} channel={obj} checkRead={this.props.checkRead} deleteChat={this.props.deleteChat} loadChat={this.props.loadChat}/>
          )
          ))}
          {
            this.props.channel.filter(ticket =>  (ticket.closed === 0)).length === 0 ?
            <h6 className="mid-text">No item</h6>
            :
            null
          }
          <ChatListTitle title="Pending" showFilterButton="false" dark={this.props.dark} showAddButton={false} extraClass="bold-text"/>          
          {(this.props.channel.filter(ticket =>  (ticket.closed === 1)).map((obj) => (
            <ChatListItem {...this.props} dark={this.props.dark} type={this.props.type} key={obj.id} channel={obj} checkRead={this.props.checkRead} deleteChat={this.props.deleteChat} loadChat={this.props.loadChat}/>
          )
          ))}
          {
            this.props.channel.filter(ticket =>  (ticket.closed === 1)).length === 0 ?
            <h6 className="mid-text">No item</h6>
            :
            null
          } 
          <ChatListTitle title="Resolved" showFilterButton="false" dark={this.props.dark} showAddButton={false} extraClass="bold-text"/>          
          {(this.props.channel.filter(ticket =>  (ticket.closed === 2)).map((obj) => (
            <ChatListItem {...this.props} dark={this.props.dark} type={this.props.type} key={obj.id} channel={obj} checkRead={this.props.checkRead} deleteChat={this.props.deleteChat} loadChat={this.props.loadChat}/>
          )
          ))}
          {
            this.props.channel.filter(ticket =>  (ticket.closed === 2)).length === 0 ?
            <h6 className="mid-text">No item</h6>
            :
            null
          }    
          <ChatListTitle title="Past Conversation" showFilterButton="false" dark={this.props.dark} showAddButton={false} extraClass="bold-text"/>          
          {(this.props.channel.filter(ticket =>  (ticket.closed === 3)).map((obj) => (
            <ChatListItem {...this.props} dark={this.props.dark} type={this.props.type} key={obj.id} channel={obj} checkRead={this.props.checkRead} deleteChat={this.props.deleteChat} loadChat={this.props.loadChat}/>
          )
          ))}
          {
            this.props.channel.filter(ticket =>  (ticket.closed === 3)).length === 0 ?
            <h6 className="mid-text">No item</h6>
            :
            null
          }                          */}
        </React.Fragment>
         
      )     
    }    
    else{
      return null;
    }
    

  }
}

ChatList.propTypes = {
  channel: PropTypes.array.isRequired
}

const mapDispatchToProps = dispatch => ( {
  callStatusChanged: (status) => 
  {
      dispatch({type: 'callStatus' , value: status});
  },
  callerNumberChanged: (number) => 
  {
      dispatch({type: 'callerNumber' , value: number});
  }   
} )
const mapStateToProps = ( state ) => ( {
  value: state
} )

export default connect(mapStateToProps,mapDispatchToProps)(ChatList);
