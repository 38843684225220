import React, { Component } from 'react';
import ConversationArea from './conversation/CallConversationArea';
import ConversationHeader from './conversation/CallHeader';
import Chatbox from './chatbox/CallBox';
import ForwardBox from './chatbox/ForwardBox';
import { Scrollbars } from 'react-custom-scrollbars';
import ImageViewer from './ImageViewer';
import ResizeDetector from 'react-resize-detector';
// import ForwardModal from './conversation/ForwardModal';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons'

import axios from 'axios';

import Variable from './../../variable';

export class ChatArea extends Component {
    host = Variable.host;

    scrollFlag = true;

    selectedTicket = "";
    firstLoaded = false;

    mounted = false;

    state = {
        chatId: null,
        showNewMessageBtn: false,
        showGoToBottom: false,
        channelData: {},
        forwardMode:false,
        selectedMessage:[],
        snackbarOpen:false,
        showOverflow:false,
        currentMessageId:0
    }

    showImages = (array) => {
        this.passToViewer(array);
    }
    

    setScrollFlag = () => {
        this.scrollFlag = true;
    }

    setOverflow = () => {
        if(this.mounted){
            this.setState({showOverflow:true});
        }
    }

    loadMoreChanges = () => {
        
    }

    getHeightFromChild = () => {
        return this.getHeight();
    }

    openSnackbar = (msg) => {
        this.setState({ snackbarOpen: true, message: msg });
      };    

    scrollToBottom = () => {

        if(this.scrollFlag){
            this.scrollElement.current.scrollTop = this.scrollElement.current.scrollHeight;
            // setTimeout(()=>{
                
            // },100)
                
  
        }
        else{
            if(!this.props.loadMoreChanged && this.props.newMessage){
                this.setState({showNewMessageBtn:true});
            }
        }
    }

    constructor(props){
        super(props);
        this.scrollElement = React.createRef();
        this.scrollNow = this.forceScrollToBottom.bind(this);
        this.flagScroll = this.checkFlagAndScroll.bind(this);
        this.getHeight = this.getHeightFromChild.bind(this);
        this.scrollToPos = this.scrollToOffset2.bind(this);
        
    }

    checkFlagAndScroll = (delay) => {
        // console.log("flag"+this.scrollFlag);
        if(this.scrollElement !== null){
            // console.log(this.state.showGoToBottom);
            if(!this.state.showGoToBottom){
                if(delay){
                    setTimeout(()=>{
                        if(this.scrollElement.current){
                            this.scrollElement.current.scrollTop = this.scrollElement.current.scrollHeight;
                        }
                    },100);
                }
                else{
                    this.scrollElement.current.scrollTop = this.scrollElement.current.scrollHeight;
                }
            }
            
        }
        
    }    
    

    forceScrollToBottom = (delay) => {
        
        if(this.scrollElement !== null){
            if(delay){
                setTimeout(()=>{
                    this.scrollElement.current.scrollTop = this.scrollElement.current.scrollHeight;
                },100);
            }
            else{
                if(this.scrollElement.current){
                    this.scrollElement.current.scrollTop = this.scrollElement.current.scrollHeight;
                }
            }
            
        }
        
    }

    scrollToOffset = (offset) => {
        //console.log(offset);
        let tempFlag = this.scrollFlag;
        //let currentScroll = this.scrollElement.scrollTop;
        //this.scrollElement.scrollTop(this.scrollElement.getScrollTop() - offset);
        //this.scrollElement.scrollTop((offset * this.scrollElement.getClientHeight() / this.scrollElement.getScrollTop()));
            
        if(tempFlag){
            this.forceScrollToBottom();
        }

    }

    scrollToOffset3 = (time) => {
        // console.log(time);
        // console.log(this.props.channelData.ts);
        if(this.props.conversation[this.props.idMap[this.props.match.params.id]]){

            if(!this.props.conversation[this.props.idMap[this.props.match.params.id]][1]){
                return false;
            }

            let currentTs = this.props.channelData.ts + time;
            let message = this.props.conversation[this.props.idMap[this.props.match.params.id]][1]["data"];


            
            let currentId = 0;
            // console.log(currentTs);
            for(let i = 0; i < message.length; i++){
                if(currentTs > message[i].ts){
                    currentId = message[i].id
                    
                }
                else{
                    break
                }
            }
            if(currentId !== 0){
                this.setState({currentMessageId:currentId});
                let pos = this.getMessagePos(currentId);
                // console.log(pos);
                if(pos.ref){
                    pos.ref.scrollIntoView({ block: 'center',  behavior: 'smooth' });
                }
            }
            else{
                this.setState({currentMessageId:0});
                this.scrollElement.current.scrollTop = 0;
            }
            
        }
        // let currentScroll = this.scrollElement.scrollTop;
        // let totalScroll = this.scrollElement.current.scrollHeight - this.scrollElement.current.clientHeight;
        // this.scrollElement.current.scrollTop = totalScroll * percentage / 100;

    }

    navigateTo = (url) => {
        this.props.history.push("/"+url);
    }

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }        

    scrollToOffset2 = (offset) => {
        console.log(offset);
        this.props.setToFalse();
        // var currentScroll = this.scrollElement.scrollTop();
        if(this.scrollElement.scrollTop){
            this.scrollElement.scrollTop(offset); 
        }
    }

      isBottom = () => {
          
        var scrolled = (this.scrollElement.scrollHeight - this.scrollElement.scrollTop)
        var threshold = (this.scrollElement.clientHeight + 50);
        return scrolled <= threshold;
      }      

      componentWillUnmount(){
        this.mounted = false;
      }
      
      componentDidMount() {

        // console.log("top "+this.scrollElement.current.scrollTop);
        // console.log("height"+this.scrollElement.current.clientHeight);
        // console.log("height"+this.scrollElement.current.scrollHeight);

        if(this.scrollElement.current.scrollHeight <= +this.scrollElement.current.clientHeight){
            this.scrollFlag = true;
            console.log("Auto scroll")
        }

        this.props.flagScroll(this.flagScroll);
        this.props.scrollToPos(this.scrollToPos);
        this.props.getHeight(this.getHeight);
        this.props.scroll(this.scrollNow);
        this.scrollElement.current.scrollTop = this.scrollElement.current.scrollHeight;
        this.mounted = true;
        // this.scrollElement.scrollTo(0, this.messagesEnd.offsetTop);
        //this.scrollElement.addEventListener('scroll', this.chatScroll);
        this.selectedTicket = this.props.match.params.id;
        this.firstLoaded = true;
        if(this.props.match.params.id !== "ongoing"){
            console.log(this.props.match);
            axios.get(this.host+'/api/ticket/info3/'+this.getCookie("auth")+'/'+this.props.match.params.id, {params:{_token:this.getCookie("auth")}},{withCredentials: true})
            .then(res => {      
                
                if(!this.props.conversation[res.data[0].id]){                    
                    this.props.loadChat(res.data[0].id);
                }
                else{
                    this.props.setSelectedId(res.data[0].id);
                    setTimeout(()=> {
                        this.scrollElement.current.scrollTop = this.scrollElement.current.scrollHeight;
                    },0)
                    

                }

                this.props.setTicketData(res.data[0]);
            }) 
        }    
        else{
            this.props.setTicketData({
                title:"Ongoing Call",
                channel:"CALL",
                language:""
            })
        }   
        
      }

      forwardEmail = (email,html) => {
        axios.post(this.host+'/api/ticket/forward', {_token:this.getCookie("auth"),message:html.replace(/(\r\n|\n|\r)/gm, ""),messageId:this.state.selectedMessage,ticket_id:this.props.channelData.id,email:email},{withCredentials: true})
        .then(res => {
            if(res.data.result === "success"){
                if(res.data.email === "OK"){
                    this.openSnackbar("Forward successful.");                         
                    this.setState({forwardMode:false,selectedMessage:[]})
                }
                else{
                    this.openSnackbar(res.data.email);  
                }

            }
            else{
                // this.openSnackbar(res.data.email);                         
                // this.setState({forwardMode:false,selectedMessage:[]})
            }
        })
      }

    saveLabel = (label) => {
        axios.post(this.host+'/api/ticket/label/assign', {_token:this.getCookie("auth"),ticket_id:this.props.channelData.id,label:JSON.stringify(label)},{withCredentials: true})
        .then(res => {
            if(res.data.result === "success"){
                let message = this.props.name + " has updated the label.";
                axios.post(this.host+'/api/ticket/message',{_token:this.getCookie("auth"),ticket_id:this.props.channelData.id,message:message,sender:"server",type:"system"},{withCredentials: true})
                .then(res => {
            
                })
                .catch(err => {
                    alert(err);
                });

                axios.get(this.host+'/ticket/info2/'+this.getCookie("auth")+'/'+this.props.match.params.id, {params:{_token:this.getCookie("auth")}},{withCredentials: true})
                .then(res => {      
                    //this.props.loadChat(res.data[0].id);                    
                    this.props.setTicketData(res.data[0]);
                    //this.props.updateInfo();
                })                  
            }
        }
        )   
    }
      
      componentDidUpdate() {

        if(!this.props.loadMoreChanged && this.props.newMessage && !this.scrollFlag){
            if(this.scrollElement.getScrollHeight() !== this.scrollElement.getClientHeight()){
                this.setState({showNewMessageBtn:true});
                this.props.change("newMessage",false);
            }
        }
        else if (!this.props.loadMoreChanged && this.props.newMessage && this.scrollFlag){
            this.scrollElement.current.scrollTop = this.scrollElement.current.scrollHeight;
            // this.scrollElement.scrollTo(0, this.messagesEnd.offsetTop);
            this.props.change("newMessage",false);
        }



        if(this.props.match.params.id !== this.selectedTicket){
  
            //this.scrollElement.scrollToBottom();
            this.setState({showNewMessageBtn:false,showGoToBottom:false})
            this.selectedTicket = this.props.match.params.id;
            if(this.props.match.params.id !== "ongoing"){
                axios.get(this.host+'/api/ticket/info3/'+this.getCookie("auth")+'/'+this.props.match.params.id, {params:{_token:this.getCookie("auth")}},{withCredentials: true})
                .then(res => {      
                    if(!this.props.conversation[res.data[0].id]){                    
                        this.props.loadChat(res.data[0].id);
                    }
                    else{
                        this.props.setSelectedId(res.data[0].id);
                        setTimeout(()=> {
                            this.scrollElement.current.scrollTop = this.scrollElement.current.scrollHeight;
                        },0)
                    }
                    this.props.setTicketData(res.data[0]);  
                    //this.props.updateInfo();  
                })        
            }    
            else{
                this.props.setTicketData({
                    title:"Ongoing Call",
                    channel:"CALL",
                    language:""
                })
            }    
        }

      }



      chatScroll = () => {


        if (this.isBottom()) {

            this.scrollFlag = true;
            this.setState({showGoToBottom:false,showNewMessageBtn:false});

        }
        else{

            this.setState({showGoToBottom:true});
            this.scrollFlag = false;
            if(this.scrollElement.scrollTop === 0){
                if(this.props.loadMore){
                    this.props.loadMoreChat(this.props.id,this.props.conversation[this.props.id][0]["count"]);


                }
            }
        }
      };   
      
      checkTyping = () => {
        if(this.props.id !== undefined){
            if(this.props.channel.filter(ticket=> ticket.id === this.props.id)[0] !== undefined){
                return this.props.channel.filter(ticket=> ticket.id === this.props.id)[0].typing;
            }
            else{
                return [];
            }
        //   for(var i = 0; i < this.props.channel.length; i++){
        //         if(this.props.channel[i].id.toString() === this.props.id.toString()){

        //             return this.props.channel[i].typing
        //         }
        //   }
        }
              return [];
          
      }

      getTitle = () => {
        
        for(var i = 0; i < this.props.channel.length; i++){
            if(this.props.channel[i].id.toString() === this.props.id){
              
              return this.props.channel[i].title
            }
        }
    }      

    getStatus = () => {
        for(var i = 0; i < this.props.channel.length; i++){
            if(this.props.channel[i].id.toString() === this.props.id){
              
              return this.props.channel[i].closed
            }
        }        
    }

    handleScrollFrame = (e) => {

        // console.log("handled");
        let totalScroll = this.scrollElement.current.scrollHeight - this.scrollElement.current.clientHeight;
        let scrollThres = (this.scrollElement.current.scrollTop/ totalScroll);
        // console.log(scrollThres);
        // if(this.scrollElement.current.scrollTop >= totalScroll){
        //     scrollThres = scrollThres - 0.01
        // }
        this.setState({scrollThres:scrollThres}); 
        if(!this.changedFromJump){
            if(this.checkElementVisibility){
                this.checkElementVisibility();
            }
        }
        

        // console.log(this.scrollElement.current.scrollTop);

        //this.scrollElement.current.getClientWidth();

        // console.log((this.scrollElement.current.getScrollHeight() - this.scrollElement.current.getClientHeight()) +" "+this.scrollElement.current.getScrollTop());


        if((totalScroll - this.scrollElement.current.scrollTop) > 100){
   
            this.scrollFlag = false;
            this.setState({showGoToBottom:true});
            
        }
        else if(!this.state.jumpMode){
            this.setState({showGoToBottom:false});
            this.scrollFlag = true;
            
        }

        
        
        if(this.scrollElement.current.scrollTop === 0 && this.props.loadMore && (this.props.channelData.conversation === null || !this.props.channelData.conversation)){

            

            if(this.props.conversation[this.props.id] !== undefined && !this.state.jumpMode){
                // this.props.loadMoreChat(this.props.id,this.props.conversation[this.props.id].count);
                this.currentHeight = this.scrollElement.current.scrollHeight - this.scrollElement.current.clientHeight;
            }

            if(this.state.jumpMode){
                if(!this.state.isTopEnded && !this.messageLoading){
                    this.loadMoreMessage(this.jumpId,"up");
                }
            }

        }
        if(scrollThres === 1 && this.state.showNewMessageBtn){
            this.setState({showNewMessageBtn:false});
        }
        if(scrollThres === 1){
            if(this.state.jumpMode){
                if(!this.state.isBottomEnded && !this.messageLoading){
                    this.loadMoreMessage(this.jumpId,"down");
                }
            }
            
        }
    }

    loadMoreChat = () => {
        if(this.props.conversation[this.props.id] !== undefined){
            this.props.loadMoreChat(this.props.id,this.props.conversation[this.props.id].count);
        }
    }

    heightChanged = (e) => {
        let isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null);
        // console.log(isInFullScreen);
        if(this.state.maintainScrollflag && !isInFullScreen){
            this.scrollElement.current.scrollTop = this.scrollElement.current.scrollHeight;
            this.setState({maintainScrollflag:false,showGoToBottom:false});
            this.scrollFlag = true;
        }        
        else if(this.scrollFlag && isInFullScreen){
            this.scrollElement.current.scrollTop = this.scrollElement.current.scrollHeight;
            this.setState({maintainScrollflag:this.scrollFlag});
        }
        else{
            if(this.scrollElement.current.scrollHeight === this.scrollElement.current.clientHeight){
                this.setState({showGoToBottom:false,showNewMessageBtn:false});
                this.scrollFlag = false;
            } 
            if(this.scrollFlag){
                this.scrollElement.current.scrollTop = this.scrollElement.current.scrollHeight;
            }              
        }   
    }

    toggleForward = (id) => {
        if(this.state.forwardMode){
            this.setState({forwardMode:!this.state.forwardMode,selectedMessage:[id],selectedMessage:[]});
        }
        else{                  
            this.setState({forwardMode:!this.state.forwardMode,selectedMessage:[id]});
        }
        
    }

    setSelectedMessage = (id) => {
        if(this.state.selectedMessage.includes(id)){
            let selectedMessage = this.state.selectedMessage;
            selectedMessage.splice(selectedMessage.indexOf(id),1);
            this.setState({selectedMessage:selectedMessage});
        }
        else{
            let selectedMessage = this.state.selectedMessage;
            selectedMessage.push(id);
            this.setState({selectedMessage:selectedMessage});            
        }
    }

    closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({ snackbarOpen: false });
      };      


    render() {
        //console.log(this.props.channel);
        
        return (            
            <div className={"flex-col  chat-area-background "+ (!this.props.showChatonMeeting && this.props.onMeeting ? "hide" : "")} style={{width:(this.props.onMeeting ? '350px':'100%'),flexShrink:(this.props.onMeeting ? 0 : ""),minWidth:0}}>  
                <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                autoHideDuration={3000}
                open={this.state.snackbarOpen}
                onClose={this.closeSnackbar}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{this.state.message}</span>}
                action={[
                    // <Button key="undo" color="secondary" size="small" onClick={this.handleClose}>
                    //   UNDO
                    // </Button>,
                    <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={this.closeSnackbar}
                    >
                    <Close />
                    </IconButton>,
                ]}
                />            
                <ImageViewer passToViewer={e=>this.passToViewer=e}/>              
                {(1   ?
                <ConversationHeader scrollToOffset2={this.scrollToOffset3} customStatus={this.props.customStatus} updateOption={this.props.updateOption} openTranslate={e =>this.openTranslate = e} loadFromHome={this.props.loadFromHome} translateFunction={this.props.translate} chatInfoShown={this.props.chatInfoShown} userType={this.props.userType} saveLabel={this.saveLabel} startMeeting={this.props.startMeeting} channelData={this.props.channelData} label={this.props.label} onMeeting={this.props.onMeeting} chatType={this.props.chatType} setTicketStatus={this.props.setTicketStatus} id={this.props.id} status={this.props.status} name={this.props.selectedChatTitle} panel={this.props.panel} show={this.props.show} dark={this.props.dark}/>
                :null)}
                {(
                    this.props.meeting[this.props.id] !== undefined && !this.props.onMeeting ?
                   ( this.props.meeting[this.props.id] > 0 ?
                   <div className="notification-bar-2 green linked" onClick={()=>this.props.startMeeting(this.props.id)}>
                    <h6 className="white-text" style={{lineHeight:'0'}}>{ this.props.meeting[this.props.id] + (this.props.meeting[this.props.id] > 1 ? " people" : " person")} joined the meeting. Click here to join!</h6>
                  </div>
                  :
                  null
                   )
                  :
                  null
                )}
                <ResizeDetector handleHeight onResize={this.heightChanged}>
                <div className={"full-height full-width" + (this.state.showOverflow ? " overflow-y" : " overflow-hidden")} ref={this.scrollElement} onScroll={this.handleScrollFrame}  >
                {/* <Scrollbars ref={(el) => { this.scrollElement = el; }} onScrollFrame={this.handleScrollFrame}> */}
                <div id="chat-scroller" className="full-height padding-right-2x padding-left-2x padding-top-2x chat-area-padding relative-content">

                {/* {(this.props.chatDataLoading ? <div className='chat-loading mid-text' style={{position: 'absolute',top: '10px',left: '48%'}}>
                    <div className="preloader-wrapper small active white circle spinner-shadow" style={{zIndex:998}}>
                        <div className="spinner-layer spinner-green-only spinner-padding">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div><div className="gap-patch">
                            <div className="circle"></div>
                        </div><div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                        </div>
                    </div>     
                </div> : null)}  */}

                {(
                    1  ?
                    (
                        this.props.conversation[this.props.idMap[this.props.match.params.id]] !== undefined ?
                            
                                <ConversationArea
                                    ts={this.props.channelData.ts}
                                    currentMessageId={this.state.currentMessageId}
                                    getMessagePos={e=>this.getMessagePos=e}
                                    setSelectedMessage={this.setSelectedMessage}
                                    selectedMessage={this.state.selectedMessage}
                                    toggleForward={this.toggleForward}
                                    forwardMode={this.state.forwardMode}
                                    channel={this.props.channelData.channel}
                                    language={this.props.channelData.language}
                                    adminPhoto={this.props.adminPhoto}
                                    getInfo={this.props.getInfo}
                                    viewTranslated={this.props.viewTranslated}
                                    emojiSet={this.props.emojiSet}
                                    username={this.props.username}
                                    profilePhoto={this.props.profilePhoto}
                                    dark={this.props.dark}
                                    id={this.props.channelData.id}
                                    getHeight={e=>this.getHeight=e}
                                    update={this.scrollToBottom}
                                    loadMoreChanged={this.props.loadMoreChanged}
                                    offsetScroll={this.scrollToOffset}
                                    offsetScroll2={this.scrollToOffset2}
                                    scroll={this.scrollToBottom} chats={this.props.conversation[this.props.idMap[this.props.match.params.id]] !== undefined ? this.props.conversation[this.props.idMap[this.props.match.params.id]] : []}
                                    imgViewer={this.props.imgViewer}
                                    rich={this.props.rich}
                                    change={this.props.change}
                                    passFromChild = {this.showImages}      
                                    deleteMessage={this.props.deleteMessage}  
                                    userType={this.props.userType}      
                                    loading = {this.props.chatDataLoading}    
                                    participant={this.props.participant}  
                                    userInfo={this.props.userInfo}
                                    displayName={this.props.displayName}
                                    alwaysDisplay={this.props.alwaysDisplay}
                                    name={this.props.channelData.name}
                                    chatbotData={this.props.channelData.conversation}
                                    loadMore={this.props.loadMore}
                                    loadMoreChat={this.loadMoreChat}
                                    translateFunction={this.props.translate}
                                    openTranslate={this.openTranslate}
                                    kbDataHighlight={this.props.kbDataHighlight}
                                    setOverflow={this.setOverflow}
                                    forceScroll={this.forceScrollToBottom}
                                />
                                :
                                null
                ) : null)}
                                <div>
                    
                                    {(this.props.id !== undefined && this.props.channel !== undefined ? <div className={"message-box-wrapper message-box "}>
                                        {
                                            this.checkTyping().map((obj,i) => (   
                                                <div data-balloon-pos="up" className="msgbox-detail-holder-left margin-bottom-2x" key={i}>
                                                    <div data-balloon-blunt aria-label={obj.name+" is typing..."} className="flex-row" data-balloon-pos="up">
                                                        <div className="user-img-bubble-left relative-content">
                                                            <img src={obj.url} alt="none" className="show-tooltip"/>                                     
                                                        </div>
                                                        <div className="msg-bubble-left">
                                                            <span>
                                                                <div className='typing-animation'>
                                                                    <div className='bounce1'></div><div className='bounce2'></div><div className='bounce3'></div>
                                                                </div>
                                                            </span>                                             
                                                        </div>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </div>   
                                            ))
                                        }
                                    </div> : null )}
                                                    
                                </div>                                
                                </div>   
                            </div>
                            {/* </Scrollbars>     */}
                            </ResizeDetector>
                           
                            {
                                this.state.forwardMode ? <ForwardBox selectedMessage={this.state.selectedMessage} openForward={this.openForward} selectedMessage={this.state.selectedMessage} closeForward={this.toggleForward}/> : null
                            }
                            {/* <ForwardModal dark={this.props.dark} forwardEmail={this.forwardEmail} openForward={e => this.openForward = e}/> */}
                                <Chatbox 
                                    showKB={this.props.showKB}
                                    goToCall={this.props.goToCall}
                                    match={this.props.match}
                                    energy={this.props.energy}
                                    pace={this.props.pace}
                                    energy_customer={this.props.energy_customer}
                                    pace_customer={this.props.pace_customer}
                                    callNumber={this.props.callNumber}
                                    hangUp={this.props.hangUp}
                                    mute={this.props.mute}
                                    emailAttachment={this.props.emailAttachment}
                                    additionalClass={this.state.forwardMode ? "hide" : ""}
                                    channel={this.props.channelData.channel}
                                    id={this.props.id}
                                    emojiSet={this.props.emojiSet}
                                    sendChat={this.props.sendChat}
                                    upload={this.props.upload}
                                    scrollBottom={this.scrollToBottom}
                                    setScrollFlag={this.setScrollFlag}
                                    change={this.props.change}
                                    emoji={this.props.emoji}
                                    dark={this.props.dark}
                                    forceScroll={this.forceScrollToBottom}
                                    goToBottom={this.state.showGoToBottom}
                                    showNewMessageBtn={this.state.showNewMessageBtn}
                                    uploadState={this.props.uploadState}
                                    uploadPercent={this.props.uploadPercent}
                                    sendTyping={this.props.sendTyping}
                                    removeTyping={this.props.removeTyping}    
                                    status={this.props.status}    
                                    reconnect={this.props.reconnect}
                                    asteriskError={this.props.asteriskError}           
                                />
                            </div>
                        )
                    }
}

export default ChatArea
