import React from 'react';
import { CallMade,CallReceived,ArrowForward,CompareArrows } from '@material-ui/icons';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';
import CustomAudioPlayer from './layout/CustomAudioPlayer';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

const emotionColor = {
  "angry" : "#C0392B",
  "fearful" : "#F4D03F",
  "frustration" : "#E67E22",
  "threat" : "#A93226",
  "commitment" : "#138D75",
  "satisfaction" : "#2980B9",
  "appreciation" : "#DE3163",
  "gratitude" : "#82E0AA",
  "happy" : "#85C1E9",
  "joy" : "#85C1E9",
  "disappointment" : "#90A4AE",
  "sad" : "#5D6D7E",
  "dissatisfaction" : "#FAD7A0",
  "disgust" : "#F5B7B1",
  "surprised" : "#D2B4DE",
  "concerned" : "#7D6608",
}

class RecordingList extends React.Component{



  componentDidMount(){

  }

  formatDate = (dateString) => {
    let momentObj = moment(dateString, 'YYYY-MM-DD HH:mm:ss.SSSSSS');
    // console.log(momentObj);
    return {date:momentObj.format('DD-MM-YY'),time:(momentObj.format('HH:mm:ss'))}    
  }

  getEmotionColor = (data) => {
    // let emotion = JSON.parse(emotionString);
    //     let total = emotion.anger + emotion.fear + emotion.happy + emotion.love + emotion.sad + emotion.surprise;
    //     if(emotion.curious){
    //         total = total + emotion.curious;
    //     }
    console.log("haha "+data);
        return emotionColor[data] || "#545454";
}
  


  render() {
    return (
      this.props.recordings.map((obj,i)=>(
      <div
      key={obj.call_id}
      // onClick={(e)=>{this.onItemClick(this.props.channel.id,this.props.channel.ticket_number);this.props.setSelectedId(this.props);this.props.setSelectedId(this.props.channel.id)}}
      className={'full-width ticket-item left-panel-item linked '}
      >
      <div className="ticket-channel-icon">
        {
          obj.type === "obd" ?
            <CallMade/>
            :
            (obj.type === "imc" ? <CallReceived/>

            :

            (obj.type === "c2d" ? <CompareArrows/>

            :

            null
              
            )
              
            )
        }
          {/* <FontAwesomeIcon className={"type-icon"} style={{fontSize:"18px",color : (this.props.channel.channel === 'FB_PAGE_POST' || this.props.channel.channel === 'FB_PM' ? "#1778f2" :(this.props.channel.channel === 'TW_DM' || this.props.channel.channel === 'TW_MENTION' ? "#00acee" : (this.props.channel.channel === "LINE_DM" ? "#00c300" : (this.props.channel.channel === "EMAIL" ? (this.props.dark ? "#9575CD":"#673AB7") : (this.props.channel.channel === "WAUCHAT" ? "#00BCD4" : (this.props.channel.channel === "MANUAL" ? "#78909C" :  (this.props.channel.channel === "WHATSAPP" ? "#075e54" : "#757575" ) ) )))) )}} icon={(this.props.channel.channel === 'FB_PAGE_POST' ? faFacebook :(this.props.channel.channel === 'TW_DM' || this.props.channel.channel === 'TW_MENTION' ? faTwitter : (this.props.channel.channel === "LINE_DM" ? faLine : (this.props.channel.channel === "EMAIL" ? faEnvelope :  (this.props.channel.channel === "WAUCHAT" ? faComment : (this.props.channel.channel === "FB_PM" ? faFacebookMessenger : (this.props.channel.channel === "WHATSAPP" ? faWhatsapp : faCoffee) ) ) ))) )} /> */}
      </div>
      {/* <div className="ticket-avatar-container margin-right-1x flex-no-shrink table-cell">
          {(this.props.channel.closed === 0 ? <LabelOutlined className="tiny left"/> : (this.props.channel.closed === 1 ? <AccessTime className="tiny left"/> : <FontAwesomeIcon icon={faCheck} className="tiny left"/>))}
      </div> */}
      <div className="ticket-number-wrapper">
        <span className="left-panel-ticket-number-text bold-text block">
          {obj.type === "imc" ? 
          (this.formatDate(obj.timeSystemConnectedAgent).date) :
           (
            obj.type === "obd" ? 
            (this.formatDate(obj.timeRecipientPickUp).date) :
            obj.type === "c2d" ? 
            (this.formatDate(obj.BstartTime).date) :
             ""
           )
           }
          </span>
      </div>
      <div className="flex-col full-width ticket-info-wrapper">
          <div className="ticket-title-wrapper">
              <span className="ticket-title-text ellipsis-text">
                {(obj.type === "imc" ? 
                <React.Fragment>
                  {obj.callerID} <ArrowForward/> {obj.digitsPressed} <ArrowForward/> {obj.DIDnumber} 
                </React.Fragment>
                : 
                (obj.type === "obd" ?
                <React.Fragment>
                  {obj.callFromCLID} <ArrowForward/> {obj.destinationNumber} 
                </React.Fragment>
                :
                (obj.type === "c2d" ?
                <React.Fragment>
                  {obj.Anumber} <ArrowForward/> {obj.Bnumber} 
                </React.Fragment>
                :
                null
                ) 
                )              
                )}
              </span>
              {/* {this.props.channel.case_type !== "" &&  this.props.channel.case_type ? <div className="ticket-case-type-wrapper">{this.props.channel.case_type}</div> : null}
              {this.props.channel.severity !== "" && this.props.channel.severity ? <div className={"ticket-severity-wrapper bold-text "+this.getSeverityColor(this.props.channel.severity)}>{this.getSeverityFull(this.props.channel.severity)}</div> : null} */}
          </div>
          {/* <div className="label-holder-2">
                  {(
                      JSON.parse(this.props.channel.label).map((obj, i) => (
                      (this.labelInfo(obj) !== null ? <span key={i} className="label" style={{color: Color(this.props.dark ? (this.labelInfo(obj)).colorDark :(this.labelInfo(obj)).color).isDark() ? "#fefefe" : "#333333",backgroundColor:(this.props.dark ? (this.labelInfo(obj)).colorDark :(this.labelInfo(obj)).color)}}>{(this.labelInfo(obj)).name}</span> : 
                      <span key={i} className="label">Removed</span>
                      )
                      )
                      )
                      
                      )}
          </div> */}
          
{                              
obj.emotion_3 ?

<ProgressBar className="browser-default margin-bottom-1x left-panel-progress">    
    { obj.emotion_3.percentage ? Object.keys(obj.emotion_3.percentage[0]).map((key, index) => (
       <Tippy content={key}>
        <ProgressBar isChild={true} now={obj.emotion_3.percentage[0][key]} temp={key}  key={index} ref={this.refBar} style={{backgroundColor:(this.getEmotionColor(key.toLowerCase()))}}/>
        </Tippy>
    )) : null
    }                                   
</ProgressBar>
:
null

}          
          {/* <div className="ticket-emotion-bar">
                  {
                  
                  !Array.isArray(this.props.channel.emotion_2) ?

                  <ProgressBar className="browser-default margin-bottom-1x left-panel-progress">    
                      { Object.keys(this.props.channel.emotion_2).map((key, index) => (
                          <ProgressBar now={this.getEmotionPercentage2(key)}  key={index} ref={this.refBar} style={{backgroundColor:emotionColor[key]}}/>
                      )) 
                      }                                   
                  </ProgressBar>

                  : (  this.props.channel.emotion !== ""  ? <div>
                          {(
                          Array.isArray(this.props.channel.emotion_2) ?
                          <ProgressBar className="browser-default margin-bottom-1x left-panel-progress">                                            
                              <ProgressBar  className="red" now={this.getEmotionPercentage("anger",this.props.channel.emotion)} key={1} ref={this.refBar}/>
  

                              <ProgressBar className="yellow" now={this.getEmotionPercentage("fear",this.props.channel.emotion)} key={2} ref={this.refBar} />


                              
                              <ProgressBar className="green" now={this.getEmotionPercentage("happy",this.props.channel.emotion)} key={3}  ref={this.refBar}/>


                              
                              <ProgressBar className="pink" now={this.getEmotionPercentage("love",this.props.channel.emotion)} key={4}  ref={this.refBar}/>


                              
                              <ProgressBar className="blue" now={this.getEmotionPercentage("sad",this.props.channel.emotion)} key={5}  ref={this.refBar}/>

                              
                              <ProgressBar className="purple" now={this.getEmotionPercentage("surprise",this.props.channel.emotion)} key={6}  ref={this.refBar}/>


                              <ProgressBar className="brown" now={this.getEmotionPercentage("curious",this.props.channel.emotion)} key={7}  ref={this.refBar}/>

                          </ProgressBar> 
                          :
                          null
                          )}                          
                      </div> : null)}                        
          </div>     */}

      </div>



      <div className="ticket-time-wrapper">
          <div className="valign-wrapper">                            
              <span className="ticket-timestamp" style={{fontSize:'12px'}}>
              {(obj.type === "imc" ? 
                <React.Fragment>
                  {this.formatDate(obj.timeSystemConnectedAgent).time + " - " + this.formatDate(obj.timeSystemHangup).time}
                </React.Fragment>
                : 
                (obj.type === "obd" ?
                <React.Fragment>
                  {this.formatDate(obj.timeRecipientPickUp).time + " - " + this.formatDate(obj.timeSystemHangUp).time}
                </React.Fragment>
                :
                (obj.type === "c2d" ?
                <React.Fragment>
                  {this.formatDate(obj.BstartTime).time + " - " + this.formatDate(obj.BendTime).time}
                </React.Fragment>
                :
                null
                )  
                )              
                )}                
                
              </span>

          </div>
      </div>
      <div className="recording-player-container">
      {obj.voiceFile.split(">").map((audio,i) => (<div key={audio} className="recording-audio-player-wrapper">
        <CustomAudioPlayer className="recording-audio-player" type={obj.type} text={audio} src={audio}/>
      </div>))}
      </div>
      </div>
      ))
    );

  }
}

// ChatList.propTypes = {
//   channel: PropTypes.array.isRequired
// }

export default RecordingList;
