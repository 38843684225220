import React, { Component } from 'react';
import axios from 'axios';
import Variable from './../../variable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook,faFacebookMessenger,faTwitterSquare,faWhatsapp, faLine, faTwitter,faTelegram,faInstagram } from '@fortawesome/free-brands-svg-icons'
import { IconTicket,IconHeadphones,IconPhone, IconPencil,IconGift,IconId,IconSend,IconUnlink,IconAffiliate,IconTool, IconX, IconTrash,IconNotebook } from '@tabler/icons-react';

import { faEnvelope, faComment, faHeadset, faCoffee,faPhone,faRedo,faCrown,faShareSquare,faTicketAlt,faSmileWink,faFlag,faAt } from '@fortawesome/free-solid-svg-icons';

import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';
import ProgressBar from 'react-bootstrap/ProgressBar'
import CustomModal from './../../components/CustomModal'

// import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";

import { BrowserRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom';

import Dropdown from 'react-bootstrap/Dropdown'

// import CaseForm from './../../components/layout/conversation/CaseForm';

import Modal from 'react-modal';

const emotionColor = {
    "neutral" : "#787878",
  "angry" : "#C0392B",
  "fear" : "#F4D03F",
  "frustration" : "#E67E22",
  "threat" : "#A93226",
  "commitment" : "#138D75",
  "satisfaction" : "#94C973",
  "appreciation" : "#DE3163",
  "gratitude" : "#82E0AA",
  "joy" : "#85C1E9",
  "disappointment" : "#90A4AE",
  "dissapointment" : "#90A4AE",
  "sadness" : "#5885AF",
  "dissatisfaction" : "#FAD7A0",
  "sarcasm" : "#F5B7B1",
  "surprise" : "#D2B4DE",
  "concerned" : "#7D6608",
}

const idType = [
    "None",
    "MyKad ID",
    "MyKid ID",
    "PR MyKad",
    "Passport No.",
    "Army ID",
    "Police ID"
]

const profileModalStyles = {
    content : {
      top                   : 'auto',
      left                  : 'auto',
      right                 : 'auto',
      bottom                : 'auto',
      width:'500px',
      maxHeight:'100vh',
      border: 'none',
      boxShadow: '0 2px 3px 0 rgba(60,64,67,.3),0 6px 10px 4px rgba(60,64,67,.15)',
      borderRadius : '15px',
      padding : '0px'
    },
    overlay : {
      backgroundColor: ('rgba(0,0,0,0.5)')
    }
};   

export default class SubCategory extends Component {

    currentId = "";

    host = Variable.host;

    constructor(props){
        super(props);
        this.state = {
            contactsData:{},
            dataLoading:false,
            editMode:false,
            contactsParam:[],
            editData:{},
            modalTitle:"",
            overflowShow:false,
            linkType:"",
            input:"",
            errorMessage:"",
            basicEditMode:false,
            info:{},
            mandatoryField:[],
            addField:[],
            deleteField:[],
            errorMessage:"",
            overflowTop:0,
            editModalShow:false,
            mode:"Add",
            selectedSub: -1,
            articleTitle:"",
            profile:""
        }
        this.overflowRef = React.createRef();
        this.buttonRef = React.createRef();
        this.modalRef = React.createRef();
    }

    loadContacts = () => {
        this.setState({dataLoading:true},()=>{
            axios.get(this.host+'/api/kb/category/sub', {params:{_token:this.getCookie("auth"),kb:this.currentId,type:this.props.match?.params.type ?? this.props.type}},{withCredentials: true})
            .then(res => {
                if(!res.data.result){
                    this.setState({contactsData:res.data[0],dataLoading:false})
                }
                else{
                    this.setState({contactsData:{},dataLoading:false})
                }
            }) 
        });
    }

    afterOpenModal = () => {

    }
    closeModal = () => {
        this.setState({editModalShow:false,selectedSub:-1,errorMessage:""})
    }


    componentDidMount(){
        this.currentId = this.props.match?.params.id ?? this.props.category;
        this.loadContacts();
    }

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
      }  

    componentDidUpdate(){
        if(this.props.match && this.currentId !== this.props.match.params.id){
            this.currentId = this.props.match.params.id;
            this.setState({dataLoading:true},()=>{
                axios.get(this.host+'/api/kb/category/sub', {params:{_token:this.getCookie("auth"),kb:this.currentId,type:this.props.match.params.type}},{withCredentials: true})
                .then(res => {
                    if(!res.data.result){
                        this.setState({contactsData:res.data[0],dataLoading:false,editMode:false,basicEditMode:false})
                    }
                    else{
                        this.setState({contactsData:{},dataLoading:false,basicEditMode:false})
                    }
                }) 
            });          
        }
    }

    setInfoValue = (e,type) => {
        let info = this.state.info;
        info[type] = e.target.value;
        this.setState({info:info});
    }

    setInfoValue2 = (e,type) => {
        let info = this.state.info;
        info[type] = e;
        this.setState({info:info});
    }

    editInfo = () => {
        let dateObj = null;
        if(this.state.contactsData.dob !== ""){
            if(moment(this.state.contactsData.dob).isValid()){
            dateObj =  moment(this.state.contactsData.dob).toDate();
            this.currentDate = dateObj;
            }
            else{
                dateObj = "";
            }
        }
        let info = {salutation:this.state.contactsData.salutation,name: this.state.contactsData.name, email: this.state.contactsData.email,phone:this.state.contactsData.phone,dob:this.state.contactsData.dob,id_number:this.state.contactsData.id_number,id_type:this.state.contactsData.id_type};
        
        this.setState({info:info,basicEditMode:true});

    }

    editContacts = () => {
        if(this.state.contactsParam.length === 0){
            axios.get(this.host+'/api/contacts/fields', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
            .then(res => {

                this.setState({contactsParam:res.data,editMode:true,editData:JSON.parse(JSON.stringify(this.state.contactsData.field))})
                
        
            }) 
        }
        else{
            this.setState({editMode:true,editData:JSON.parse(JSON.stringify(this.state.contactsData.field))})
        }
    }

    getEmotionPercentage3 = (data,obj) => {
        let obj2 = Object.keys(obj);
        return obj[data] / obj2.length * 100;
    }

    checkParam = (name) => {
        let arr = this.state.contactsParam;
        const { length } = arr;
        const id = length + 1;
        const found = arr.some(el => el.fields_param === name);
 
        return found;
    }

    getValue = (name) => {
        let arr = this.state.editData;
        const { length } = arr;
        const id = length + 1;
        const found = arr.find(el => el.contacts_field === name);
 
        return found.contacts_value;
    }

    saveContacts = () => {
        if(this.state.mode === "Edit"){
            let value = {};
            for(let i = 0; i < this.state.editArray.length; i++){
                value[this.state.editArray[i].key] = this.state.editArray[i].value;
            }
            axios.post(this.host+'/api/contacts/custom/save', {_token:this.getCookie("auth"),contactsId:this.state.tabSelected,value:value},{withCredentials: true})
            .then(res => {
                this.indexChanged(this.state.pageIndex[this.state.tabSelected]);
                this.setState({editModalShow:false})
            //   this.setState({customTabs:res.data})
            })   
            .catch(err=>{
                alert(JSON.stringify(err.response.data))
            }); 
        }  
        else{

            if(this.state.profile === "" && this.state.mode === "Add"){
                this.setState({errorMessage:"Please fill in the name."})
                this.modalRef.current.scrollTo(0, 0);
                return false;
            }

            if(this.state.selectedSub === -1 && this.state.mode === "New Article"){
                this.setState({errorMessage:"Please select Sub Category."})
                this.modalRef.current.scrollTo(0, 0);
                return false;
            }

            if(this.state.articleTitle === "" && this.state.mode === "New Article"){
                this.setState({errorMessage:"Please enter article title."})
                this.modalRef.current.scrollTo(0, 0);
                return false;
            }

            if(this.state.mode === "Add"){

                this.setState({contactCreating:true},()=>{
                    axios.post(this.host+'/api/kb/category/sub/add', {_token:this.getCookie("auth"),description:this.state.profile,sort:this.state.salutation,language:"en",parent: this.state.contactsData.id},{withCredentials: true})
                    .then(res => {
                        this.setState({contactCreating:false});
                        this.closeModal();
                        this.loadContacts();
                        // this.indexChanged(this.state.pageIndex[this.state.tabSelected]);
                        
                    //   this.setState({customTabs:res.data})
                    })  
                    .catch(err=>{
                
                    }); 
                })
           
            }
            else if(this.state.mode === "New Article"){

                this.setState({contactCreating:true},()=>{
                    axios.post(this.host+'/api/kb/article/add', {_token:this.getCookie("auth"),question:this.state.articleTitle,language:"en",sub: this.state.contactsData.sub[this.state.selectedSub].id},{withCredentials: true})
                    .then(res => {
                        this.setState({contactCreating:false});
                        this.closeModal();
                        this.loadContacts();
                        // this.indexChanged(this.state.pageIndex[this.state.tabSelected]);
                        
                    //   this.setState({customTabs:res.data})
                    })  
                    .catch(err=>{
                
                    }); 
                })
           
            }
            // axios.post(this.host+'/api/contacts/custom/save', {_token:this.getCookie("auth"),contactsId:this.state.tabSelected,additionalData:this.state.additionalData},{withCredentials: true})
            // .then(res => {
            //     this.indexChanged(this.state.pageIndex[this.state.tabSelected]);
            //     this.setState({editModalShow:false})
            // //   this.setState({customTabs:res.data})
            // })  
            // .catch(err=>{
            //     alert(JSON.stringify(err.response.data))
            // });    
        }      
    }

    updateBasicInfo = () => {
        axios.post(this.host+'/api/contacts/basic/update',{_token:this.getCookie("auth"),name:this.state.info.name,id:this.state.contactsData.id,id_type:this.state.info.id_type,id_number:this.state.info.id_number,dob:this.state.info.dob},{withCredentials: true})
        .then(res => {
            if(res.data.result === "success"){
                
                this.setState({basicEditMode:false});
                this.loadContacts();
            }
        });  
    }

    editCategory = () => {

        if(this.state.editDescription === ""){
            this.setState({errorMessage:"Please enter category name."})
            this.modalRef.current.scrollTo(0, 0);
            return false;
        }
        
        axios.post(this.host+'/api/kb/category/sub/edit',{_token:this.getCookie("auth"),description:this.state.editDescription,id:this.state.editId,sort:this.state.editSort},{withCredentials: true})
        .then(res => {
            if(res.data.result === "success"){
                
                this.closeModal();
                this.loadContacts();
            }
        });  
    }

    deleteCategory = () => {
        axios.delete(this.host+'/api/kb/category/sub/delete',{params:{_token:this.getCookie("auth"),id:this.state.editId}},{withCredentials: true})
        .then(res => {
            if(res.data.result === "success"){
                
                this.closeModal();
                this.loadContacts();
            }
        })
        .catch((err)=>{
            if(err.response.data){
                this.setState({errorMessage:err.response.data.description});
            }
            else{
                this.setState({errorMessage:"Unknown error occured."});
            }
        });  
    }

    deleteArticle = () => {
        axios.delete(this.host+'/api/kb/article/delete',{params:{_token:this.getCookie("auth"),question:this.state.editId}},{withCredentials: true})
        .then(res => {
            if(res.data.result === "success"){
                
                this.closeModal();
                this.loadContacts();
            }
        })
        .catch((err)=>{
            if(err.response.data){
                this.setState({errorMessage:err.response.data.description});
            }
            else{
                this.setState({errorMessage:"Unknown error occured."});
            }
        });  
    }

    editArticle = () => {

        if(this.state.editTitle === ""){
            this.setState({errorMessage:"Please enter article title."})
            this.modalRef.current.scrollTo(0, 0);
            return false;
        }

        axios.post(this.host+'/api/kb/article/edit',{_token:this.getCookie("auth"),articleTitle:this.state.editTitle,id:this.state.editId,category:this.state.contactsData.sub[this.state.selectedSub].id},{withCredentials: true})
        .then(res => {
            if(res.data.result === "success"){
                
                this.closeModal();
                this.loadContacts();
            }
        });  
    }

    setEditData = (value,param,i) => {
        let editData = this.state.editData;
        if(!editData[i].original_data){
            editData[i].original_data = editData[i].contacts_value;
        }
        editData[i].contacts_value = value;
        this.setState({editData:editData});
    }

    closeCallback = (res) => {
        console.log("closed");
    }

    inputCallback = (res) => {
        this.setState({input:res.input});
    }

    linkProfile = () => {
        axios.post(this.host+'/api/contacts/link',{_token:this.getCookie("auth"),id:this.state.contactsData.id,type:this.state.linkType,data:this.state.input},{withCredentials: true})
        .then(res => {
 
            // setTeam(res.data);
            this.closeModal();
            this.setState({errorMessage:""});
            this.loadContacts();
            
        }).catch(err => {
            this.setState({errorMessage:err.response.data.description});   
        }); 
    }

    openButton = () => {
        return <button className='button-new' onClick={this.linkProfile}>Add</button>;
    }

    extFormUpdated = (form) => {


        let editData = this.state.editData;
        let deleteField = [];
        let addField = [];

        Object.keys(form).map((key,s) => {
            let found = false;
            for(let i = 0; i < editData.length;i++){
              
                if(editData[i].contacts_field === key){
                    found = true;
                    if(form[key].ts){
                        if(!editData[i].original_data){
                            editData[i].original_data = parseInt(editData[i].contacts_value);
                        }
                        editData[i].contacts_value = form[key].ts;
                    }
                    else if(form[key].value){
                        if(!editData[i].original_data){
                            editData[i].original_data = editData[i].contacts_value;
                        }
                        editData[i].contacts_value = form[key].value;
                    }
                
                }
            }
            if(!found){
                addField.push({name:key,value:{value:form[key].value ? form[key].value : (form[key].ts ? form[key].ts : ""),type:form[key].value ? "text" : (form[key].ts ? "date" : "text")}})
            }
        })
        let found = false;
        for(let i = 0; i < editData.length;i++){
     
            if(form[editData[i].contacts_field]){

  
                found = true;
            }
            else{
                deleteField.push(editData[i]);
            }
        }

        // console.log(addField);
        // console.log(deleteField);


        this.setState({editData:editData,addField:addField,deleteField:deleteField});

        this.setState({extForm:form},()=>{
 
        });
    }

    setReminder = () => {

    }

    mandatoryField = (field) => {
        this.setState({mandatoryField:field})
    }

  render() {
    return (
      <div className='overflow-y mid-text full-height full-width'>
           <Modal
                    isOpen={this.state.editModalShow}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={profileModalStyles}
                    contentLabel="Example Modal"
                    ariaHideApp={false} 
                    closeTimeoutMS={200}       
                                                    
                    className={""+(this.props.dark ? "dark" : null)}   

                    
                >
                <div className="flex-col" style={{width:'100%',maxHeight:"100vh"}}>
                    <div className="ng-modal-main-header" style={{paddingTop:"30px",paddingBottom:0}}>
                        <div className="full-width flex-row valign-wrapper margin-bottom-1x">
                            <h5 className="bold-text-2">{this.state.mode}</h5>
                            
                        </div>
                    
                    </div>   
                    <div ref={this.modalRef} className="ng-modal-main-body padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x flex-col full-height">
                        {
                            this.state.mode === "Delete Category" || this.state.mode === "Delete Article" || this.state.mode === "Edit Category" || this.state.mode === "Edit Article Title" ?
                            (
                                this.state.errorMessage !== "" ? 
                                <h6 className="red lighten-3 callout-box white-text">{this.state.errorMessage}</h6>
                                :
                                null
                            )
                            :
                            null
                            
                        }
                        {
                            
                            this.state.mode === "Edit Category" ? 
                            <>
                                <div>
                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <span>Category Name</span>
                                        <input className={"browser-default " + (this.state.invalidName ? "invalid" : "")} type="text" onChange={ e => this.setState({editDescription:e.target.value}) } value={this.state.editDescription}/>
                                    </label>
                                    
                                </div>
                                <div>
                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <span>Sort (Optional)</span>
                                        <input className={"browser-default " + (this.state.invalidName ? "invalid" : "")} type="text" onChange={ e => {this.setState({editSort:e.target.value})} } value={this.state.editSort}/>
                                    </label>
                                </div>
                            </>
                            :
                            null
                        }
                        {
                            
                            this.state.mode === "Edit Article Title" ? 
                            <>
                                <div>
                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <span>Article Title</span>
                                        <input className={"browser-default " + (this.state.invalidName ? "invalid" : "")} type="text" onChange={ e => this.setState({editTitle:e.target.value}) } value={this.state.editTitle}/>
                                    </label>
                                    
                                </div>
                                <span className='input-label'>Sub Category</span>
                                <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            {this.state.selectedSub !== -1 ? this.state.contactsData.sub[this.state.selectedSub].description : "Please Select"}
                                        </Dropdown.Toggle> 
                                        <Dropdown.Menu>                          
                                        {(this.state.contactsData.sub.map((obj,i)=>(
                                        <Dropdown.Item eventKey={i} key={i} onSelect={()=>{ this.setState({selectedSub:i})}}>{obj.description}</Dropdown.Item>
                                        )))}
                                        {/* <Dropdown.Item eventKey="enquiry" onSelect={selectOptionType}>Enquiry</Dropdown.Item> */}
                                    </Dropdown.Menu>  
                                </Dropdown>   
                            </>
                            :
                            null
                        }
                        {
                            this.state.mode === "Add" ? (
                            <>
                            {
                                this.state.errorMessage !== "" ? 
                                <h6 className="red lighten-3 callout-box white-text">{this.state.errorMessage}</h6>
                                :
                                null
                            }
                            
                            
                            <div>
                                <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                    <span>Category Name</span>
                                    <input className={"browser-default " + (this.state.invalidName ? "invalid" : "")} type="text" onChange={ e => this.setState({profile:e.target.value}) } value={this.state.profile}/>
                                </label>
                                
                            </div>
                            <div>
                                <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                    <span>Sort (Optional)</span>
                                    <input className={"browser-default " + (this.state.invalidName ? "invalid" : "")} type="text" onChange={ e => {this.setState({salutation:e.target.value})} } value={this.state.salutation}/>
                                </label>
                            </div>
                            {(this.state.contactsParam.map((obj,i)=>(
                                    <div key={i}>
                                        {
                                            obj.type === "date" ? 

                                     
                                                    <div>
                                                        <h6 className='input-label'>{obj.field_name}</h6>
                                                        {/* <DatePicker dateFormat="yyyy-MM-dd"  selected={ this.dateData[obj.fields_param] ? new Date(this.dateData[obj.fields_param].value*1000) : ""} onChange={date => {
                                                            let additionalData = this.state.additionalData;
                                                          
                                                            additionalData[obj.fields_param] ={value:date.getTime()/1000,type:obj.type};
                                                            this.dateData[obj.fields_param] = {value:date.getTime()/1000,type:obj.type};
                                                            // console.log(date.getTime());
                                                            this.setState({additionalData:additionalData});
                                                        }}
                                                        popperPlacement="right-start"
                                                        /> */}
                                                    </div>
                                                
                                   
                                            :

                                            <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                            <span>{obj.field_name}</span>
                                            <input className={"browser-default "} type="text" onChange={ e => {
                                                let additionalData = this.state.additionalData;
                                                additionalData[obj.fields_param] = {value:e.target.value,type:obj.type};
                                                this.setState({additionalData:additionalData});
                                            } } />
                                            </label>
                                     
                                         }
                                    </div>
                                    ))
                                )}
                            {/* {this.props.extensions.length > 1 ? <CaseForm mandatoryField={this.mandatoryField} updateForm={this.extFormUpdated} formData={this.props.extensions[1].form.fields}/> : null} */}
                            </>
                     
                            )
                            :
                            null
                        }
                        {
                            this.state.mode === "New Article" && this.state.contactsData.sub ? (
                            <>           
                                {
                                    this.state.errorMessage !== "" ? 
                                    <h6 className="red lighten-3 callout-box white-text">{this.state.errorMessage}</h6>
                                    :
                                    null
                                }
                                
                                
                                <div>
                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <span>Article Title</span>
                                        <input className={"browser-default " + (this.state.invalidName ? "invalid" : "")} type="text" onChange={ e => this.setState({articleTitle:e.target.value}) } value={this.state.articleTitle}/>
                                    </label>
                                    
                                </div>
                                <span className='input-label'>Sub Category</span>
                                <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            {this.state.selectedSub !== -1 ? this.state.contactsData.sub[this.state.selectedSub].description : "Please Select"}
                                        </Dropdown.Toggle> 
                                        <Dropdown.Menu>                          
                                        {(this.state.contactsData.sub.map((obj,i)=>(
                                        <Dropdown.Item eventKey={i} key={i} onSelect={()=>{ this.setState({selectedSub:i})}}>{obj.description}</Dropdown.Item>
                                        )))}
                                        {/* <Dropdown.Item eventKey="enquiry" onSelect={selectOptionType}>Enquiry</Dropdown.Item> */}
                                    </Dropdown.Menu>  
                                </Dropdown>       
                            </>
                            )
                            :
                            null}
                        {/* {
                            this.state.mode === "Filter" ? (
                                <div>
                                    {this.props.extensions[1] ? <CaseForm type="filter" updateForm={this.extFormUpdated2} initValue2={this.state.filterInit} formData={this.props.extensions[1].form.fields}/> : null}
                                </div>
                            )
                            :
                            null
                        } */}
                        </div>  
                    <div className="ng-modal-main-footer right-text">                                          
                        <button className="btn-flat ng-close-btn waves-effect waves-dark" onClick={this.closeModal}>close</button>
                        {(this.state.mode === "Filter" ? <button className="button-new margin-left-1x" onClick={()=>{this.setState({filterInit:{},extForm2:{}})}}>Reset</button> : null)}
                        {(this.state.mode === "Edit Category" ? <button className="btn waves-effect waves-light margin-left-1x" onClick={this.editCategory}>Save</button> : null)}
                        {(this.state.mode === "Delete Category" ? <button className="btn waves-effect waves-light margin-left-1x red" onClick={this.deleteCategory}>Delete</button> : null)}
                        {(this.state.mode === "Delete Article" ? <button className="btn waves-effect waves-light margin-left-1x red" onClick={this.deleteArticle}>Delete</button> : null)}
                        {(this.state.mode === "Edit Article Title" ? <button className="btn waves-effect waves-light margin-left-1x" onClick={this.editArticle}>Save</button> : null)}
                        {(this.state.mode === "Filter" ? <button className="btn waves-effect waves-light margin-left-1x" onClick={()=>{this.setState({currentPage:0},()=>{this.listContacts()});this.closeModal()}}>Filter Now</button> : null)}
                        {(this.state.mode === "Add" || this.state.mode === "New Article"  ? <button className={"btn waves-effect waves-light margin-left-1x"+(this.state.contactCreating ? " disabled" : "")} onClick={this.saveContacts}>Create</button> : null)}
                    </div>            
                </div>                                           
            </Modal>
        <CustomModal inputCallback={this.inputCallback} errorMessage={this.state.errorMessage} closeModal={e=> this.closeModal2 = e} openModal={e=> this.openModal = e} optionButton={this.openButton()} closeCallback={this.closeCallback} />
        {
            !this.state.dataLoading ?
                <>
                    <div className={'full-width '+(!this.props.match ? ' pop-up-window' : '')}>
                        <div className='valign-wrapper margin-top-2x' style={{justifyContent:'center'}}>
                            <h5 className='contact-name inline-block'>{this.state.contactsData.description ?? "No name"}</h5>
                            {!this.state.editMode && this.props.match?.params.type === 'kb' ? <div className={"icon-button-hover-5 inline-block  margin-right-1x valign-wrapper"} onClick={()=>{this.setState({editMode:true})}}>
                                <IconTool 
                                    size={20} // set custom `width` and `height`
                                    // set `stroke` color
                                    stroke={2}  // set `stroke-width`
                                    strokeLinejoin="miter" // override other SVG props
                                    className=''
                                />   
                            </div> : null}
                            {this.state.editMode ? <div className={"icon-button-hover-5 inline-block  margin-right-1x valign-wrapper"} onClick={()=>{this.setState({editMode:false})}}>
                                <IconX 
                                    size={20} // set custom `width` and `height`
                                    // set `stroke` color
                                    stroke={2}  // set `stroke-width`
                                    strokeLinejoin="miter" // override other SVG props
                                    className=''
                                />   
                            </div> : null}
                        </div>
                        {this.props.match?.params.type === 'kb' ? <button className='button-new' onClick={()=>this.setState({mode:"Add",editModalShow:true})}>Add Sub Category</button> : null}
                        {this.props.match?.params.type === 'kb' && this.state.contactsData.sub ? ( this.state.contactsData.sub .length > 0 ? <button className='button-new margin-left-1x' onClick={()=>this.setState({mode:"New Article",editModalShow:true})}>New Article</button> : null) : null}
                    </div> 
                    <div className='kb-sub-category-list'>
                        
                    
                    {
                       this.state.contactsData.sub ?
                       <>{
                        (this.state.contactsData.sub.map((obj2,i2) => (
                        <div className=''>
                            <div className='flex-row'>
                                <h6 className='contact-section-title inline-block nowrap margin-right-1x'>{obj2.description}</h6>
                            {this.state.editMode ? 
                                <div className='margin-bottom-1x flex-row full-width'>
                                    <div onClick={()=>{this.setState({mode:"Edit Category",editModalShow:true,editId:obj2.id,editDescription:obj2.description,editSort:obj2.sort})}}>
                                        <IconPencil 
                                            size={20} // set custom `width` and `height`
                                            // set `stroke` color
                                            stroke={2}  // set `stroke-width`
                                            strokeLinejoin="miter" // override other SVG props
                                            className='linked unselectable'
                                        />  
                                    </div>
                                    <div onClick={()=>this.setState({mode:"Delete Category",editModalShow:true,editId:obj2.id})}>
                                        <IconTrash 
                                            size={20} // set custom `width` and `height`
                                            // set `stroke` color
                                            stroke={2}  // set `stroke-width`
                                            strokeLinejoin="miter" // override other SVG props
                                            className='linked unselectable'
                                        />  
                                    </div>
                                </div>
                                : null} 
                            </div>
                            <div className='kb-category'>
                                {
                                    obj2.question ? 
                                        obj2.question.map((obj,i) => (
                                            <div className={(this.props.match ? '' : 'pop-up-window-article-item')}>
                                                <Link
                                                onClick={(e)=>{
                                                    if(!this.props.match){
                                                        e.preventDefault();
                                                        this.props.openArticle(obj.question_id);
                                                    }
                                                }}
                                                key={obj.id} to={'/support/'+this.props.match?.params.type+'/'+this.props.match?.params.lang+'/'+this.currentId+'/'+obj.question_id}>
                                                    <div  className={"contacts-ticket-item"}>
                                                        <div>
                                                            {/* <p className="grey-text bold-text activity-emotion-margin-2 timeline-date"><Moment unix format="D MMMM YYYY kk:mm">{moment.utc(obj.created_at).unix()}</Moment></p> */}
                                                            <div className="flex-row activity-name-holder">
                                                                {/* <FontAwesomeIcon className="linked margin-left-1x show-tooltip margin-right-1x" style={{fontSize:'20px'}} icon={(obj.channel === "FB_PAGE_POST" ? faFacebook : (obj.channel === "FB_PM" ? faFacebookMessenger : (obj.channel === "TW_DM" || obj.channel === "TW_MENTION" ? faTwitterSquare : (obj.channel === "LINE_DM" ? faLine :  (obj.channel === "EMAIL" ? faEnvelope : (obj.channel === "WAUCHAT" ? faComment : (obj.channel === "LIVECHAT" ? faHeadset : (obj.channel === "MANUAL" ? faCoffee : (obj.channel === "WHATSAPP" ? faWhatsapp : faCoffee)))))))))}/>                             */}
                                                                <div className="activity-icon">
                                                                    <IconNotebook 
                                                                        size={20} // set custom `width` and `height`
                                                                        // set `stroke` color
                                                                        stroke={2}  // set `stroke-width`
                                                                        strokeLinejoin="miter" // override other SVG props
                                                                        className=''
                                                                    />          
                                                                </div>                                          
                                                                <h6>{obj.question}</h6>
                                                                {/* <h6 className="ellipsis-text">{
                                                                obj.channel === "FB_PAGE_POST" ? "Facebook Page" : 
                                                                (obj.channel === "FB_PM" ? "Facebook Messenger" : 
                                                                (obj.channel === "TW_DM" ? "Twitter Direct Message" : 
                                                                (obj.channel === "TW_MENTION" ? "Twitter Mentions" : 
                                                                (obj.channel === "LINE_DM" ? "Line Direct Messages" : 
                                                                (obj.channel === "EMAIL" ? "Email" : 
                                                                (obj.channel === "WAUCHAT" ? "Chat Bot" : 
                                                                (obj.channel === "LIVECHAT" ? "Live Chat" : 
                                                                (obj.channel === "MANUAL" ? "Manual" : 
                                                                (obj.channel === "WHATSAPP" ? "WhatsApp" : 
                                                                (obj.channel === "TELEGRAM" ? "Telegram" : 
                                                                "Other"))))))))))}</h6> */}
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </Link>
                                                {this.state.editMode ? 
                                                <div className='margin-bottom-1x flex-row'>
                                                    <div onClick={()=>this.setState({mode:"Edit Article Title",editModalShow:true,editTitle:obj.question,editId:obj.id,selectedSub:i2})}>
                                                        <IconPencil 
                                                            size={20} // set custom `width` and `height`
                                                            // set `stroke` color
                                                            stroke={2}  // set `stroke-width`
                                                            strokeLinejoin="miter" // override other SVG props
                                                            className='linked unselectable'
                                                        />  
                                                    </div>
                                                    <div onClick={()=>this.setState({mode:"Delete Article",editModalShow:true,editId:obj.question_id})}>
                                                        <IconTrash 
                                                            size={20} // set custom `width` and `height`
                                                            // set `stroke` color
                                                            stroke={2}  // set `stroke-width`
                                                            strokeLinejoin="miter" // override other SVG props
                                                            className='linked unselectable'
                                                        />  
                                                    </div>
                                                </div>
                                                : null} 
                                            </div>
                                        ))                       
                                    :
                                    null
                                }
                                {
                                    obj2.question ? 
                                        (obj2.question.length === 0 ? <h6 className='margin-left-2x'>No Article.</h6> : null)
                                    :
                                    null
                                }                                
                                {
                                    this.state.contactsData.ticket ? 
                                    (
                                        this.state.contactsData.ticket.length === 0
                                        ?
                                        <h6 className='case-empty-message'>No Ticket</h6>
                                        :
                                        null
                                    )
                                    :
                                    null
                                }
                            </div>
                        </div>  
                        )))
                        }
                        {
                            this.state.contactsData.sub.length === 0 ?
                            <h6 className='margin-left-2x'>{this.props.match.params.type === 'manual' ? "It's empty right now, please come back again later." : "Write a new article ? Add sub category first."}</h6>
                            :
                            null
                        }
                        </>
                         : null
                    }                                     
                    </div> 
                </>
            :
            null
        }
    </div>
    );
  }
}
