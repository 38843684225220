import React, { Component } from 'react';

export default class componentName extends Component {
  render() {
    return (
        <div className="flex">
        <div className="block-center pricing-table-orientation">
        <div className="services-box wow fadeInUp pricing-item-left">
            <div className="box-pricing flex-row full-height">
              <div className={"card full-width  "+(this.props.dark ? "grey darken-3" : "")}>
                        <div className="card-content full-height">
                            <div className="row flex-col full-height">
                            <div className="">
                            <div className="pricing">
                                <h3 className="mid-text bold-text-2 no-margin pink-text">$5</h3>
                                <p className="mid-text no-margin">/user/month</p>
                                <div className="divider margin-top-2x margin-bottom-2x"></div>
                            </div>
                            
                            </div>
                            
                            <div className="full-height">
                            <h5 className="mid-text bold-text-2">Novice</h5>
                            <ul className="text-left browser-default">
                                <li>Email Ticketing</li>
                                <li>Unified Social Media</li>
                                <li>Interaction Journey</li>
                                {/* <li className="hide-bullet">&nbsp;</li>
                                <li className="hide-bullet">&nbsp;</li>
                                <li className="hide-bullet">&nbsp;</li> */}
                            </ul>
                            
                            </div>
                            <div className="divider margin-top-2x margin-bottom-2x"></div>
                            <div className="mid-text">
                            <div className="top-big-link wow fadeInUp">
                                {(this.props.showButton ? <button className={"btn white-text green full-width "+(this.props.plan > 0 || this.props.onPayment ? "disabled" : "")} onClick={()=>this.props.upgradePlan("novice")}>Purchase</button> : null)}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="services-box wow fadeInDown">
            <div className="box-pricing flex-row full-height">
                <div className={"card full-width "+(this.props.dark ? "grey darken-3" : "")}>
                        <div className="card-content full-height">
                            <div className="row flex-col full-height">
                                <div className="">
                                <div className="pricing">
                                    <h3 className="mid-text bold-text-2 no-margin pink-text">$10</h3>
                                    <p className="mid-text no-margin">/user/month</p>
                                    <div className="divider margin-top-2x margin-bottom-2x"></div>
                                </div>
                                
                                </div>
                                
                                <div className="full-height">
                                <h5 className="mid-text bold-text-2">Pro</h5>
                                <ul className="text-left browser-default">
                                    <li>Live Chat</li>
                                    <li>Email Ticketing</li>
                                    <li>Unified Social Media</li>
                                    <li>Unified Mobile Messaging</li>                                    
                                    <li>Interaction Journey</li>
                                    {/* <li className="hide-bullet">&nbsp;</li>
                                    <li className="hide-bullet">&nbsp;</li> */}
                                </ul>
                                
                                </div>
                                <div className="divider margin-top-2x margin-bottom-2x"></div>
                                <div className="mid-text">
                                <div className="top-big-link wow fadeInUp">
                                {(this.props.showButton ? <button className={"btn white-text green full-width "+(this.props.plan === 1 || this.props.plan > 2 || this.props.onPayment ? "disabled" : "")} onClick={()=>this.props.upgradePlan("pro")}>Purchase</button> : null)}
                                </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="services-box wow fadeInUp pricing-item-right">
            <div className="box-pricing flex-row full-height">
                <div className={"card full-width  "+(this.props.dark ? "grey darken-3" : "")}>
                    <div className="card-content full-height">
                        <div className="row flex-col full-height">
                            <div className="">
                            <div className="pricing">
                                <h3 className="mid-text bold-text-2 no-margin pink-text">$25</h3>
                                <p className="mid-text no-margin">/user/month</p>
                                <div className="divider margin-top-2x margin-bottom-2x"></div>
                            </div>
                            </div>
                            
                            <div className="full-height">
                            <h5 className="mid-text bold-text-2">Expert</h5>
                            <ul className="text-left browser-default">
                                <li>Chat bot</li>
                                <li>Live Chat</li>
                                <li>Email Ticketing</li>
                                <li>Unified Social Media</li>
                                <li>Unified Mobile Messaging</li>
                                <li>Interaction Journey</li>                                
                            </ul>
                            
                            </div>
                            <div className="divider margin-top-2x margin-bottom-2x"></div>
                            <div className="mid-text">
                            <div className="top-big-link wow fadeInUp">
                            {(this.props.showButton ? <button className={"btn white-text green full-width "+(this.props.plan === 1 || this.props.plan > 3 || this.props.onPayment ? "disabled" : "" )} onClick={()=>this.props.upgradePlan("expert")}>Purchase</button> : null)}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="services-box wow fadeInUp pricing-item-right">
            <div className="box-pricing flex-row full-height">
                <div className={"card full-width  "+(this.props.dark ? "grey darken-3" : "")}>
                    <div className="card-content full-height">
                        <div className="row flex-col full-height">
                            <div className="">
                            <div className="pricing">
                                <h3 className="mid-text bold-text-2 no-margin pink-text">$120</h3>
                                <p className="mid-text no-margin">/10/users/month</p>
                                <div className="divider margin-top-2x margin-bottom-2x"></div>
                            </div>
                            </div>
                            
                            <div className="full-height">
                            <h5 className="mid-text bold-text-2">Team</h5>
                            <ul className="text-left browser-default">
                                <li>Chat bot</li>
                                <li>Live Chat</li>
                                <li>Email Ticketing</li>
                                <li>Unified Social Media</li>
                                <li>Unified Mobile Messaging</li>
                                <li>Interaction Journey</li>
                            </ul>
                            
                            </div>
                            <div className="divider margin-top-2x margin-bottom-2x"></div>
                            <div className="mid-text">
                            <div className="top-big-link wow fadeInUp">
                            {(this.props.showButton ? <button className={"btn white-text green full-width "+(this.props.plan === 1 || this.props.plan > 4 || this.props.onPayment ? "disabled" : "" )} onClick={()=>this.props.upgradePlan("team")}>Purchase</button> : null)}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
        </div>
        </div>
    );
  }
}
