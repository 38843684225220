import React, { Component, useState, useEffect } from 'react'

import Modal from 'react-modal';

import { Scrollbars } from 'react-custom-scrollbars';

import Select from 'react-select';

import chroma from 'chroma-js';

import axios from 'axios';

import Dropdown from 'react-bootstrap/Dropdown'

import Variable from './../variable';

import { CheckBox,CheckBoxOutlineBlank } from '@material-ui/icons';

// import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";

import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';

function CustomModal(props){

    const host = Variable.host;

    const [open,setOpen] = useState(false);

    const [title,setTitle] = useState(false);

    const [description,setDescription] = useState("");

    const [input,setInput] = useState([]);

    const [inputData,setInputData] = useState({});

    const [scrolled,setScrolled] = useState(false);

    const [updating,setUpdating] = useState(false);

    const [selectedIdType,setSelectedIdType] = useState(0);

    const [idNumber,setIdNumber] = useState("");

    const [refuseProvide,setRefuseProvide] = useState(false);

    const idType = [
      "None",
      "MyKad ID",
      "MyKid ID",
      "PR MyKad",
      "Passport No.",
      "Army ID",
      "Police ID",
      "Baby of (B/O)"
  ]

    const [options,setOptions] = useState([{"status_name":"Open","status_code":0},{"status_name":"Pending","status_code":1},{"status_name":"Resolved","status_code":2},{"status_name":"Closed","status_code":3}]);

    const dot = (color = '#ccc') => ({
        alignItems: 'center',
        display: 'flex',
      
        ':before': {
          backgroundColor: color,
          borderRadius: 10,
          content: '" "',
          display: 'block',
          marginRight: 8,
          height: 10,
          width: 10,
        },
      });  

      const popperConfig = {
        strategy: "fixed"
      };

    const [selectedStatus,setSelectedStatus] = useState(-1);

    const getSelectedStatus = (index) => {
        return options[index].status_name;
    }

    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }     



    const profileModalStyles = {
        content : {
          top                   : 'auto',
          left                  : 'auto',
          right                 : 'auto',
          bottom                : 'auto',
          width:'400px',
          maxHeight:'100vh',
          border: 'none',
          boxShadow: '0 2px 3px 0 rgba(60,64,67,.3),0 6px 10px 4px rgba(60,64,67,.15)',
          borderRadius : '15px',
          padding : '0px'
        },
        overlay : {
          backgroundColor: ('rgba(0,0,0,0.5)')
        }
    };    

    const openModal = (data) => {
        setTitle(data.title);
        setDescription(data.description);
        setInput(data.input);
        setOpen(true);
        if(data.initValue){
          console.log(data.initValue);
          setInputData(data.initValue);
        }
        if(data.initIdType !== undefined){
          setSelectedIdType(data.initIdType);
        }

        if(data.initIdNumber !== undefined){
          setIdNumber(data.initIdNumber);
          if(data.initIdNumber === "Refuses to provide"){
            setRefuseProvide(true);
          }
          else{
            setRefuseProvide(false);
          }
        }
    }

    const closeModal = () => {
        props.closeCallback();
        setOpen(false);
    }

    const afterOpenModal = () => {

    }

    const handleChange = (e,i,key) =>{ 

    } 

    const selectStyles = {
        menu: styles => ({...styles,margin:'0',borderRadius:'5px',overflow:'hidden',zIndex:999,width:"300px"}),
        menuList: styles => ({...styles,backgroundColor:'#fefefe'}),
        valueContainer: styles => ({...styles, height:'30px',color:'#757575'}),
        control: styles => ({ ...styles, backgroundColor: '#fefefe',width:'300px', color:'#757575'}),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          //console.log(data);
          //const color = chroma(data.color);
          
          const color = chroma('#fefefe');
          return {
            ...styles,
      
            backgroundColor: isDisabled
              ? null
              : isSelected
              ? "#dddddd"
              : isFocused
              ? color.alpha(0.1).css()
              : null,
            color: isDisabled
              ? '#ccc'
              : isSelected
              ? chroma.contrast(color, 'white') > 2
                ? 'white'
                : 'black'
              : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':hover':{
              ...styles[':hover'],
              backgroundColor: (isSelected ? '#e0e0e0' :'#f5f5f5'),
            },
            ':active': {
              ...styles[':active'],
              backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(1).css()),
            },
          };
        },
        input: (styles, { data }) => ({ ...styles, ...dot(data), height: '30px'}),
      
        singleValue: (styles, { data }) => ({ ...styles, ...dot(data), color: '#757575',':before':{display:'none'} }),
      };
      
      const selectStylesDark = {
        menu: styles => ({...styles,margin:'0',backgroundColor:'#333333',borderRadius:'5px',overflow:'hidden',zIndex:999,width:"300px"}),
        valueContainer: styles => ({...styles, height:'30px',color:'#fefefe'}),
        control: styles => ({ ...styles, backgroundColor: '#333333',width:'300px', color:'#fefefe'}),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          //console.log(data);
          //const color = chroma(data.color);
          
          const color = chroma('#000000');
          return {
            ...styles,
      
            backgroundColor: isDisabled
              ? null
              : isSelected
              ? "#212121"
              : isFocused
              ? color.alpha(0.1).css()
              : null,
            color: isDisabled
              ? '#ccc'
              : isSelected
              ? chroma.contrast(color, 'white') > 2
                ? 'white'
                : 'black'
              : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':hover':{
              ...styles[':hover'],
              backgroundColor: '#424242',
            },
            ':active': {
              ...styles[':active'],
              backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(1).css()),
            },
          };
        },
        input: (styles, { data }) => ({ ...styles, ...dot(data), height: '30px'}),
        dropdownIndicator: (styles, { data }) => ({ ...styles, ':hover':{color:'#fefefe'}}),
        singleValue: (styles, { data }) => ({ ...styles, ...dot(data), color: '#fefefe',':before':{display:'none'} }),
      };


    // const closeEvent = closeModal.bind();
    const openEvent = openModal.bind();
    const closeEvent = closeModal.bind();

    useEffect(() => {
        // props.closeCallback(closeEvent);
        props.openModal(openEvent);
        props.closeModal(closeEvent);
    },[]);

    useEffect(() => {
      if(props.setRefuseProvide){
        props.setRefuseProvide(refuseProvide);
      }
    },[refuseProvide]);

    return (
        <React.Fragment>
        <Modal
        isOpen={open}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={profileModalStyles}
        contentLabel="Example Modal"
        ariaHideApp={false} 
        closeTimeoutMS={200}       
                                        
        className={""+(props.dark ? "dark" : null)}   

        
      >

        <div className="flex-col" style={{width:'100%',maxHeight:"100vh"}}>
            {/* <div className="ng-modal-main-header" style={{paddingTop:"30px",paddingBottom:0}}>
                <div className="full-width flex-row valign-wrapper margin-bottom-1x">
                    
                </div>
            
            </div>     */}
            {/* <Scrollbars autoHeight autoHeightMin={200}> */}
                <div className={"ng-modal-main-header"+(scrolled ? " scrolled" : "")} style={{paddingBottom:0}}>
                    <h5 className="bold-text-2">{title}</h5>
                </div>
                <div className="ng-modal-main-body padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x flex-row full-height">                 
{/*                     
                    <Select
                    styles={props.dark ? selectStylesDark : selectStyles}
                    value={options.filter((obj) => obj.value === props.selectedStatus)[0]}
                    onChange={handleChange}
                    options={options}
                    isSearchable={false}
                    />   */}
                    <div className='full-width'>
                    <h6>{props.description}</h6>
                    <h6>{description}</h6>
                    {props.errorMessage !== "" ? <h6 className="red lighten-3 callout-box white-text">{props.errorMessage}</h6> : null}
                      {
                        input.map((obj,i)=>(
                          obj.type === "text" ? 
                          <label key={i} className="pure-material-textfield-outlined full-width margin-bottom-1x">
                              <span>{obj.placeholder}</span>
                              <input className={"browser-default "} type="text" onChange={ e =>
                              {
                                let inputData2 = {...inputData};
                                inputData2[obj.key] = e.target.value
                                setInputData(inputData2);
                                let callback = {};
                                callback[obj.key] = e.target.value;
                                props.inputCallback(callback);
                              }
                              }
                                value={inputData[obj.key] ? inputData[obj.key] : ""}/>
                              
                          </label>
                          :
                          (
                            obj.type === "button" ? 
                            <button className='button-new margin-right-1x' onClick={()=>{props.inputCallback(obj)}}>{obj.label}</button>
                          :
                          (
                            obj.type === "option" ?
                              <div className={"border-base margin-bottom-1x linked valign-wrapper"}  onClick={()=>{props.inputCallback(obj.key,obj.additional)}} style={{padding:'10px',borderRadius:'5px'}}>
                                
                                  <h6 className='margin-left-1x'>{obj.name}</h6>
                              </div>  
                            :
                            (
                              obj.type === "id" ?
                              <>
                                      <span className='input-label'>ID Type</span>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                {idType[selectedIdType]}
                                            </Dropdown.Toggle> 
                                            <Dropdown.Menu popperConfig={popperConfig} className="dropdown-fixed">                          
                                            {(idType.map((obj,i)=>(
                                            <Dropdown.Item eventKey={i} key={i} onSelect={()=>{ props.inputCallback({"id_type":i});setSelectedIdType(i)}}>{obj}</Dropdown.Item>
                                            )))}
                                            {/* <Dropdown.Item eventKey="enquiry" onSelect={selectOptionType}>Enquiry</Dropdown.Item> */}
                                        </Dropdown.Menu>   
                                        </Dropdown>      
                                        <div className='linked flex-row valign-wrapper unselectable margin-top-2x checkbox' onClick={()=>{setRefuseProvide(!refuseProvide)}}>
                                            {refuseProvide === false ? <CheckBoxOutlineBlank/> : <CheckBox/>}
                                            <span className='nowrap'>Refuses to provide</span>
                                        </div>                                
                                        {selectedIdType !== 0 && !refuseProvide ? <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                            <span>Identity Number</span>
                                            <input className={"browser-default "} type="text" onChange={ e => {props.inputCallback({"id_number":e.target.value});setIdNumber(e.target.value)} } value={idNumber}/>
                                        </label> : null}                              
                              </>
                              :
                              (obj.type === "date" ? 
                              <>
                              <span className='input-label'>{obj.placeholder}</span>
                              {/* <DatePicker dateFormat="yyyy-MM-dd"  selected={ inputData[obj.key] && moment(inputData[obj.key]).isValid() ? inputData[obj.key] : ""} onChange={date => {
                                let inputData2 = {...inputData};
                                inputData2[obj.key] = date
                                setInputData(inputData2);
                                let callback = {};
                                callback[obj.key] = moment(date).isValid() ? moment(date).format("YYYY-MM-DD 00:00:00") : "";

                          
                                props.inputCallback(callback);
                                // this.setInfoValue2(moment(date).isValid() ? moment(date).format("YYYY-MM-DD 00:00:00") : "","dob")
                              
                            }}
                            popperPlacement="bottom-start"
                            /> */}
                            </>
                            :
                              null)
                            )
                          )
                        
                          )
                        ))
                      }
                      {
                        props.listData ? 
                        <div style={{whiteSpace:'pre'}}>
                          {props.listData.map((obj,i) =>(
                            <div>{obj}</div>
                          ))}
                          </div>
                        :
                        null
                      }
                    </div>
                </div>

            {/* </Scrollbars> */}
            
            <div className="ng-modal-main-footer right-text">                      
                <button className="btn-flat ng-close-btn waves-effect waves-dark margin-right-1x" onClick={closeModal}>close</button>
                {
                  props.optionButton ?
                    props.optionButton
                    :
                    null
                }                    
                {/* <button className={"btn-flat waves-effect waves-light white-text "+(props.className)} onClick={props.confirm}>Delete</button> */}
            </div>            
        </div>          
        </Modal> 
        {/* <div className="" style={{width:'200px'}} onClick={openModal}>
            <div className="valign-wrapper">
                <span className="valign-wrapper overflow-menu-text bold-text unselectable margin-left-1x">Update Status</span>
            </div>        
        </div>  */}
        {/* <div onClick={openModal}>
        {
          props.triggerButton
        }
        </div> */}
        </React.Fragment>     
    )
}
export default CustomModal;