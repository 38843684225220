import React, { Component } from 'react';
import {InsertDriveFile} from '@material-ui/icons';
import FileIcon from './../FileIcon';
import axios from 'axios';
import Variable from './../../../variable';

export default class FileInfo extends Component {

    host = Variable.host;

    state = {
        fileName : "",
        fileInfo : {}
    }

    componentDidMount(){
        this.setState({fileName:"",fileInfo:this.props.info});
        if(this.props.info === undefined && this.props.message.indexOf("ticket-cdn.superceeduniverse.com") !== -1){
            axios.get(this.host+'/attachment/info/'+this.getPart(this.props.message,3)+'/'+this.getPart(this.props.message,2)+'/'+this.getPart(this.props.message,1),{},{withCredentials: true})
            .then(res => {
                this.setState({fileInfo:res.data});
        
            })
            .catch(err =>{
            console.log(err);
            });          
        }
        else{
            let filename = this.getLastPart(this.props.message);

            let obj = {"file_name":filename,"file_path":"",file_type:this.props.type};
            this.setState({fileInfo:obj});
        }
    }

    getLastPart = (url) => {
        let split = url.split("/");
        return split[split.length - 1];
    }    

    getPart = (url,part) => {
        let split = url.split("/");
        return split[split.length - part];
    }     
    
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }    

    render() {
        return (
            (this.state.fileInfo !== undefined ? (this.state.fileInfo.file_name !== undefined ? <a className="file-link" href={this.props.message} rel="noopener" target="_blank">
                <div className="flex-row file-type-wrapper">
                    <FileIcon type={this.props.type}/>
                    <div className="flex-col">
                    <span className="bold-text file-name-text">{this.state.fileInfo.file_name}</span>
                    {this.state.fileInfo.file_size ? <span className='file-size-text'>{this.formatBytes(this.state.fileInfo.file_size)}</span> : null}
                    </div>
                </div>
            </a> : null) : null)
        )
    }
}
