import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux'
import Store from './store'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';


const store = Store();

ReactDOM.render(
    <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
            <App/>
        </SnackbarProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register({
//     onUpdate : (registration ) => {
//         const waitingServiceWorker = registration.waiting

//         if (waitingServiceWorker) {
//           waitingServiceWorker.addEventListener("statechange", event => {
//             if (event.target.state === "activated") {
//               //window.location.reload()
//               store.dispatch({type: 'new_version' });
//             }
//           });
          
//           waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
//         }        
        
//     }
// });
