import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ImageViewer from './../components/layout/ImageViewer';
import ConversationItem from './../components/layout/conversation/CallConversationItem';
import Chatbox from './../components/layout/chatbox/CallBox';
import openSocket from 'socket.io-client';
import { Helmet } from 'react-helmet';
import bubble from './../audio/bubble.mp3';
import ping from './../audio/ping.mp3';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import ConversationDate from './../components/layout/conversation/ConversationDate'
import Variable from './../variable';
import TranslateButton from './../components/layout/conversation/TranslateLivechat';

const audio = new Audio(bubble);
const audio2 = new Audio(ping);

const langList = {
    'English': 'en',
    'Chinese (China)':'zh-CN',
    'Chinese (Taiwan)':'zh-TW',
    'Malay':'ms'
}

const langList2 = {
    'en': 'English',
    'zh-CN':'China',
    'zh-TW':'Taiwan',
    'ms':'Malay'
}

export default class componentName extends Component {

    host = Variable.host;

    obj = {
        currentDate : null,
        chatLength : null,
        currentUser : null
        
    }    

    socket;

    state = { 
        isOpen: false,
        value: undefined,
        addModalOpen: false,
        ticketTitle: "",
        phone: "",
        email: "",
        description: "",
        name: "",
        titleError: false,
        phoneError: false,
        emailError: false,
        desError: false,
        nameError: false,
        continue: false,
        clientInfo: {},
        liveChatError: false,
        conversation:[],
        info:{username:"visitor",profilePhoto:"https://www.superceed.com/images/system/default.png",clientName:"Visitor",name:""},
        uploadPercent: 0,
        showNewMessageBtn: false,
        showGoToBottom: false,       
        messages: {},
        emojiSet:[],
        userInfo: {},
        socketError: false,
        width:0,
        height:0
      };

      setCurrentUser = (username) => {
        if(this.obj.currentUser === null){
            this.obj.currentUser = username;

            return true;
        }
        else if(this.obj.currentUser !== username){
            this.obj.currentUser = username;

            return true;
        }
        else{
            return false;
        }
      }      

      scrollFlag = true;

      updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      };  
      
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
    

    componentDidMount(){

        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();
        axios.post(this.host+'/api/livechat/info', {token:this.props.match.params.token},{withCredentials: true})
        .then(res => {
            if(res.data.result === "success"){
                if(res.data.ticket_id !== null){
                    

                    axios.get(this.host+'/emoji/custom2', {params:{token:res.data.csrf}},{withCredentials: true})
                    .then(res => {
                      this.setState({emojiSet:res.data});
                    });                    
                    

                    axios.get(this.host+'/livechat/data', {params:{_token:res.data.csrf,token:this.props.match.params.token}},{withCredentials: true})
                    .then(res => {      


                        var tempChat = this.state.messages;
                  
                        for(let i = 0; i < res.data.message.length; i++){
                          let tempDate = moment.unix(res.data.message[i].ts).format('DD/MM/YYYY');
                          if(tempChat[tempDate] !== undefined ){
                            tempChat[tempDate].push(res.data.message[i]);
                          }
                          else{
                            tempChat[tempDate] = [];
                            tempChat[tempDate].push(res.data.message[i]);
                          }
                        }

                        this.setState({messages:tempChat})




                        let userInfo = this.state.userInfo;
                        for(var i = 0; i < res.data.agent.length; i++){
                          userInfo[ res.data.agent[i].username] = {name:  res.data.agent[i].name,photo: res.data.agent[i].photo};
                        }   
                        this.setState({userInfo:userInfo})                        
                    })  

                    this.socket = openSocket("https://ticket-api.superceed.com:8000");
                    let info = this.state.info;
                    info.clientName = res.data.custName;
                    if(res.data.title !== undefined){
                        info.title = res.data.title;
                    }
                    info.csrf = res.data.csrf;
                    info.name = res.data.name;
                    info.photo = res.data.photo;
                    info.id = res.data.ticket_id;
                    info.client = res.data.client;
                    info.chat_color = res.data.chat_color;
                    info.link_color = res.data.link_color;
                    info.source_lang = res.data.source_lang;
                    this.setState({info:info});
                    this.socket.emit("joinLiveChat",{id:res.data.ticket_id});
                    
                    //this.socket.emit("newChatMessage",{message:message,ticketId:this.state.info.id,type:type,sender:this.state.info.username,name:this.state.info.clientName,title:this.state.info.title,client:this.state.info.client})
                    //this.setState({info: res.data});

                    this.socket.on("connect", ()=>{
                        if(this.state.socketError){
                          window.location.reload();
                        }
                        //console.log("connected");
                    });      
                    
                    this.socket.on("disconnect", ()=>{
                        this.setState({ socketError: true })
                    });
            
                    this.socket.on("connect_failed", ()=>{
                        this.setState({ socketError: true })
                    });   

            
                    this.socket.on("connect_error", ()=>{
                        this.setState({ socketError: true })
                    });                       
                    
                    this.socket.on("liveChatParticipantChanged", ()=>{
                        axios.get(this.host+'/livechat/data', {params:{_token:res.data.csrf,token:this.props.match.params.token}},{withCredentials: true})
                        .then(res => {      
                            var tempChat = this.state.messages;
                  
                            for(let i = 0; i < res.data.message.length; i++){
                              let tempDate = moment.unix(res.data.message[i].ts).format('DD/MM/YYYY');
                              if(tempChat[tempDate] !== undefined ){
                                tempChat[tempDate].push(res.data.message[i]);
                              }
                              else{
                                tempChat[tempDate] = [];
                                tempChat[tempDate].push(res.data.message[i]);
                              }
                            }
    
                            this.setState({messages:tempChat})
                            let userInfo = this.state.userInfo;
                            for(var i = 0; i < res.data.agent.length; i++){
                              userInfo[ res.data.agent[i].username] = {name:  res.data.agent[i].name,photo: res.data.agent[i].photo};
                            }   
                            this.setState({userInfo:userInfo})                        
                        })                          
                    })

                    this.socket.on("newLiveChatMessage", data => {
                        if(!this.props.focused){
                            audio2.play();
                        }
                        else{
                            audio.play();
                        }
                        let tempChat = this.state.messages;
                        var obj = data;
                        obj.has_meta = 0;
                        obj.isSender = false;
                        obj.ts = Math.floor(Date.now() / 1000);
                        //obj.sender = "customer";
                        let tempDate = moment.unix(data.ts).format('DD/MM/YYYY');
                        if(tempChat[tempDate] !== undefined ){
                          tempChat[tempDate].push(obj);
                        }
                        else{
                          tempChat[tempDate] = [];
                          tempChat[tempDate].push(obj);
                        } 
                        this.setState({messages:tempChat});                
                    });

                    this.socket.on("liveChatEnded", data => {
                        window.location.replace("/register/livechat/"+this.props.match.params.token);
                    });                    
                }
                else{
                    this.setState({liveChatError : true})
                    window.location.replace("/register/livechat/"+this.props.match.params.token);
                }
            }
            else{
                window.location.replace("/register/livechat/"+this.props.match.params.token);
                this.setState({liveChatError : true})
            }
        })
        .catch(err => {
            window.location.replace("/register/livechat/"+this.props.match.params.token);
            this.setState({liveChatError : true})
        });
    }
    
    

    setCurrentDate(date){

        if(this.obj.currentDate === null){
            this.obj.currentDate = date;

            return true;
        }
        else if(this.obj.currentDate !== date){
            this.obj.currentDate = date;

            return true;
        }
        else{
            return false;
        }
    }    
    scrollToOffset = (offset) => {
        let tempFlag = this.scrollFlag;
        //let currentScroll = this.scrollElement.scrollTop;
        //this.scrollElement.scrollTop(this.scrollElement.getScrollTop() - offset);
        //this.scrollElement.scrollTop((offset * this.scrollElement.getClientHeight() / this.scrollElement.getScrollTop()));
            
        if(tempFlag){
            this.forceScrollToBottom();
        }        

        // let tempFlag = this.scrollFlag;
        // let currentScroll = this.scrollElement.scrollTop;
        // this.scrollElement.scrollBy(0,(offset * this.scrollElement.clientHeight / this.scrollElement.scrollHeight));
            
        // if(tempFlag){
        //     this.forceScrollToBottom();
        // }

    }

    scrollToBottom = () => {

        if(this.scrollFlag){
            setTimeout(()=>{
                this.scrollElement.scrollToBottom();
            },100)
                
  
        }
        else{
            if(!this.props.loadMoreChanged && this.props.newMessage){
                this.setState({showNewMessageBtn:true});
            }
        }
    }    

    sendTyping = (id) => {
        console.log(id);
        //{url:"https://www.superceed.com/images/svg/blue_icon.svg",name:"Koh",id:0},{url:"https://www.superceed.com/images/svg/blue_icon.svg",name:"Lee",id:1}
            if(this.socket){
                this.socket.emit("sendTyping",{ticketId:this.state.info.id,url:this.state.info.profilePhoto,name:this.state.info.name,username:this.state.info.username});
            }
      }
    
      removeTyping = (id) => {
            if(this.socket){
                this.socket.emit("removeTyping",{ticketId:this.state.info.id,username:this.state.info.username});
            }
      }    

    uploadFiles = (files,id) => {
        this.setState({uploadState:true,uploadPercent:0});
        const formData = new FormData();
        for(var i = 0; i < files.length; i++){
          formData.append('attachment[]',files[i]);
        }
        formData.append('_token',this.state.info.csrf);
        formData.append('ticket_id',this.state.info.id);
        const config = {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
          withCredentials: true,
          onUploadProgress: progressEvent => {
            const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
            //console.log("onUploadProgress", totalLength);
            if (totalLength !== null) {
                let percent = Math.round( (progressEvent.loaded * 100) / totalLength );
                this.setState({uploadPercent:percent});
            }
          }
        }   
        axios.post(this.host+'/api/upload2',formData , config)
        .then(res => {
          this.setState({uploadState:false});
          //console.log(res);
          //console.log(res.data);
          if(res.data.result === "success"){
            for(var i = 0; i < res.data.file.length; i++){
              if(res.data.fileType[i].includes("image/")){
                this.sendChat("https://ticket-cdn.superceeduniverse.com/attachment/v101/"+res.data.file[i],id,"image");
              }
              else{
                this.sendChat("https://ticket-cdn.superceeduniverse.com/attachment/v101/"+res.data.file[i],id,res.data.fileType[i],res.data.fileName[i]);
              }
            }
          }
        })     
      }
      
      forceScrollToBottom = () => {
        
        this.scrollElement.scrollToBottom();
        
    }      

    closeTicket = () => {

        axios.post(this.host+'/api/livechat/end', {token:this.props.match.params.token,_token:this.state.info.csrf},{withCredentials: true})
        .then( res =>{
            this.socket.emit("endLiveChat",{ticketId:this.state.info.id})
        })

        
    }

    sendChat = (message,id,type,fileName) => {
        // const newObj = {
        //     id: 0,
        //     message: message,
        //     date: "07-06-2019",
        //     time: "11:00",
        //     type: type,
        //     isSender: true,
        //     has_meta: 0,        
        //     sender: this.state.info.username,
        //     ts: Math.floor(Date.now() / 1000)    
        //   }

        //   let messages = this.state.messages;

        //   messages.push(newObj);

        //   this.setState({messages:messages});

        if(this.state.info.source_lang === ""){
            axios.post(this.host+'/api/language/detect', {ticketId:this.state.info.id, text: message},{withCredentials: true})
            .then( res => {
                let info = this.state.info;
                info.source_lang = res.data.languageCode;
                this.setState({info:info});
                console.log(res);
            })
            .catch ( err => {
                console.error(err);
            })
        }

        if(this.socket){
            this.socket.emit("newChatMessage",{message:message,ticketId:this.state.info.id,type:type,sender:this.state.info.username,name:this.state.info.clientName,title:this.state.info.title,client:this.state.info.client})
        }
        else{
            //alert("error");
        }
    }

    showImages = (array) => {
        this.passToViewer(array);
    }  
    
    setScrollFlag = () => {
        this.scrollFlag = true;
    } 
    
    checkTyping = () => {
        if(this.props.id !== undefined){
          for(var i = 0; i < this.props.channel.length; i++){
                if(this.props.channel[i].id.toString() === this.props.id.toString()){

                    return this.props.channel[i].typing
                }
          }
        }
              return [];
          
      }    

  render() {
    return (           
      <div className="flex-col">
        <Helmet defer={false}>
            <title>{this.state.info.name + " - Live Chat"}</title>
        </Helmet>            
        {/* <div className="valign-wrapper">
            <div className="logo-section">
                <div style={{padding:"20px"}}>
                    <Link to={'/'}>
                        <div className="valign-wrapper">
                        <img style={{width:"30px"}} src={process.env.PUBLIC_URL + '/icon-256px.png'} /> 
                        <h5 className="bold-text-2">Hyperceed</h5>
                        </div>
                    </Link>
                </div>
            </div>
        </div> */}
            <div className="full-width full-height brown lighten-5">
                <div className="limit-width-500 block-center z-depth-1 flex-col white" style={{height:this.state.height+"px"}}>
               {( !this.state.liveChatError ?
               <React.Fragment>
                <ImageViewer passToViewer={e=>this.passToViewer=e}/>    
                {(
                        this.state.info.name ? 
                        <div className="valign-wrapper" style={{padding:"20px",backgroundColor:"rgba(0,0,0,0.05)"}}>
                            <img className="circle" style={{width:'35px', height: '35px', marginRight:'12px'}} src={this.state.info.photo} alt="error"/>
                            <h5 className="small-font bold-text full-width">{this.state.info.name}</h5>
                            <button className="btn" style={{width:"200px"}} onClick={this.closeTicket}>End Chat</button>
                        </div>
                        :
                        null
                )}
                     {(this.state.socketError ? <div className="notification-bar-2 red">
                        <h6 className="white-text" style={{lineHeight:'0'}}>No connection</h6>
                      </div> : null)}   
                      {/* {
                          (<div className="padding-left-2x padding-right-2x">
                              <span>Detected Language: {langList2["en"]}</span>
                             <TranslateButton sourceLang={"en"}/>
                          </div>)
                      }              */}
                <Scrollbars ref={(el) => { this.scrollElement = el; }} onScrollFrame={this.handleScrollFrame}>
                <div id="chat-scroller" className="full-height padding-right-2x padding-left-2x padding-top-2x chat-area-padding relative-content">
                    {
                        
                            Object.keys(this.state.messages).map((key, index)=> (
                                (key !== 'count' ? <React.Fragment key={key}>
                                    <div className="conversation-wrapper">
                                    <ConversationDate date={moment(key, 'DD/MM/YYYY').format('D MMMM YYYY')}/>
                                    {(this.state.messages[key].map((obj,i) => (  
                                        <ConversationItem
                                            viewTranslated={false}
                                            originalText={obj.message}
                                            text={obj.type === "text" ? (obj.translated_text[this.state.info.source_lang] ? obj.translated_text[this.state.info.source_lang] : obj.message) : obj.message}
                                            key={obj.id}
                                            emojiSet={this.state.emojiSet}
                                            username={this.state.info.username}
                                            profilePhoto={this.state.info.profilePhoto}
                                            // loadMoreChanged={this.props.loadMoreChanged}
                                            offsetScroll={this.scrollToOffset}
                                            message={obj}
                                            showDate={ this.setCurrentDate.bind(this)}
                                            imgViewer={this.props.imgViewer}
                                            rich={true}
                                            passFromChild={this.showImages}
                                            showOverflowMenu={false}      
                                            userType={this.props.userType}     
                                            token={this.state.info.csrf}    
                                            userInfo={this.state.userInfo}     
                                            liveChatPhoto={this.state.info.photo}
                                            currentUser={this.setCurrentUser.bind(this)}   
                                            displayName={true}
                                            visitorName={this.state.info.clientName}
                                            chatColor={this.state.info.chat_color}
                                            linkColor={this.state.info.link_color}
                                            reversed={true}

                                        />
                                
                            ))
                            )}
                            
                            </div>

                            </React.Fragment> : null)
                        )
                            )
                    } 
                    
                    <div 
                        ref={(el) => { this.messagesEnd = el; }}>
        
                        <div className={"message-box-wrapper message-box " + (this.checkTyping().length === 0 ? 'hide' : '')}>
                            {
                                this.checkTyping().map((obj,i) => (   
                                    <div data-balloon-blunt aria-label={obj.name+" is typing..."} data-balloon-pos="up" className="msgbox-detail-holder-left" key={i} style={{display:"inline-block"}}>
                                        <div className="user-img-bubble-left relative-content">
                                            <img src={obj.url} alt="none" className="show-tooltip"/>                                     
                                        </div>
                                        <div className="msg-bubble-left" style={{backgroundColor:this.state.info.chat_color || ""}}>
                                            <span>
                                                <div className='typing-animation'>
                                                    <div className='bounce1'></div><div className='bounce2'></div><div className='bounce3'></div>
                                                </div>
                                            </span>                                             
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>   
                                ))
                            }
                        </div> 
                                        
                    </div>  
                    <div style={{height:'30px'}}></div>                    
                </div>  
                </Scrollbars>           
                    <Chatbox                                 
                                    liveChatToken={this.state.info.csrf}
                                    id={this.state.ticketId}
                                    emojiSet={this.state.emojiSet}
                                    sendChat={this.sendChat}
                                    upload={this.uploadFiles}
                                    scrollBottom={this.scrollToBottom}
                                    setScrollFlag={this.setScrollFlag}
                                    change={this.props.change}
                                    emoji={this.state.emoji}
                                    dark={false}
                                    forceScroll={this.forceScrollToBottom}
                                    goToBottom={this.state.showGoToBottom}
                                    showNewMessageBtn={this.state.showNewMessageBtn}
                                    uploadState={this.state.uploadState}
                                    uploadPercent={this.state.uploadPercent}
                                    sendTyping={this.sendTyping}
                                    removeTyping={this.removeTyping}    
                                    hideGiphy={true}
                                    status={0}                
                                />   
                    </React.Fragment>
                    :
                    <h5 className="mid-text bold-text-2">An error occured.</h5>
                    )}      
                </div>
            </div>      
      </div>
    );
  }
}
