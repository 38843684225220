import React, { Component } from 'react'
import { Search } from '@material-ui/icons'
import axios from 'axios'
import { Scrollbars } from 'react-custom-scrollbars';
import Variable from './../../../variable';

export default class AgentPicker extends Component {

    host = Variable.host;

    state = {
        agent : [],
        keyword: ""
    }

    textboxChaged = (e) => {
        this.setState({keyword:e.target.value})
    }

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }    

    componentDidMount(){
        axios.get(this.host + '/agent/'+this.getCookie("auth"))
        .then(res => {
            this.props.setInfo(res.data);
            this.setState({agent:res.data})
        })        
    }

    render() {
        return (
            <div className={"flex-col z-depth-1 "+(this.props.dark ? "grey darken-3" : "white")} style={{borderRadius:'10px'}}>
                <div className="overflow-hidden flex-col">
                    {(this.props.show ? <input value={this.state.keyword} ref={e => this.searchBox = e} onChange={e => this.textboxChaged(e)} placeholder="Search agent" type="text" className="no-default-highlight no-margin" style={{padding:'5px 10px',width:'230px'}}/>: null)}
                    {(this.state.keyword.length === 0 ? <Search style={{position:'absolute',right:'10px',top:'15px'}}/> : null)}
                    {/* <div className={"custom-tab "}>
                        <div onClick={()=>this.switchMode('tenor')} className={"custom-tab-item unselectable "+(this.state.mode === "tenor" ? "clicked" : "")+(this.props.dark ? " dark" : "")}>Tenor</div>
                        <div onClick={()=>this.switchMode('giphy')} className={"custom-tab-item unselectable "+(this.state.mode === "giphy" ? "clicked" : "")+(this.props.dark ? " dark" : "")}>GIPHY</div>
                        {(this.state.mode === "giphy" ? <img src={GiphyIcon} style={{position:'absolute',bottom:'10px',right:'25px',width:'60px',pointerEvents:'none'}}/>:null)}
                    </div> */}
                </div>
                <Scrollbars className="flex-col" style={{width:'250px',height:'200px'}}>
                    {
                        this.state.agent.filter(agent => agent.name.indexOf(this.state.keyword) !== -1 || agent.username.indexOf(this.state.keyword) !== -1).map((obj,i)=>
                            (
                                <div key={obj.username} style={{paddingLeft:"10px",paddingTop:"5px",paddingBottom:"5px",paddingRight:"10px"}} className="relative-content linked item-hover valign-wrapper" onClick={()=>this.props.itemClicked(obj.username)}>
                                    <img className="margin-right-1x avatar-wrapper" src={(this.props.userInfo[obj.username] !== undefined ? (this.props.userInfo[obj.username].photo !== "" ? (this.props.userInfo[obj.username].photo.indexOf("www.superceed.com") === -1 ? "https://www.superceed.com" : "")+this.props.userInfo[obj.username].photo : "https://www.superceed.com/images/system/default.png") : "https://www.superceed.com/images/system/default.png")}/>
                                    <h6>{obj.name}</h6>
                                    {/* <img key={i} src={value[1]} className="full-width"/> */}
                                    {/* <div className="tooltip-wrapper md-tooltip--top" style={{right:"25%"}}>
                                                <span className="tooltip-content black white-text" data-tooltip-content="57%">{value[0]}</span>
                                    </div>                                      */}
                                </div>
                            )
                        )
                    }
                </Scrollbars>
            </div>
        )
    }
}