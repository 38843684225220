import {InsertDriveFile} from '@material-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt,faFile,faFileWord,faFileExcel,faFilePowerpoint,faFileCsv,faFileVideo,faFileAudio,faFilePdf,faFileArchive } from '@fortawesome/free-solid-svg-icons'
import { faAndroid } from '@fortawesome/free-brands-svg-icons'
import React, { Component } from 'react'

export default class FileIcon extends Component {
    render() {
        return (

            
                (this.props.type.indexOf("zip") !== -1 ? 

                <FontAwesomeIcon icon={faFileArchive}/>
                
                :

                (
                this.props.type.indexOf("ms-excel") !== -1 || this.props.type.indexOf("spreadsheetml.sheet") !== -1
                ?
                <FontAwesomeIcon icon={faFileExcel}/>
                : 
                (
                this.props.type.indexOf("video") !== -1 
                ?
                <FontAwesomeIcon icon={faFileVideo}/>
                : 
                (
                this.props.type.indexOf("audio") !== -1
                ?
                <FontAwesomeIcon icon={faFileAudio}/>
                : 
                (
                    this.props.type.indexOf("pdf") !== -1
                    ?
                    <FontAwesomeIcon icon={faFilePdf}/>
                    : 
                    (
                        this.props.type.indexOf("csv") !== -1
                        ?
                        <FontAwesomeIcon icon={faFileCsv}/>
                        : 
                        (
                            this.props.type.indexOf("android") !== -1
                            ?
                            <FontAwesomeIcon icon={faAndroid}/>
                            : 
                            <FontAwesomeIcon icon={faFile}/>
                            )
                        )
                    )
                )
                )
                )                     
                )
            
        )
    }
}
