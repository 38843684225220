
import React, { useState,useEffect,useRef } from 'react'
import axios from 'axios'

function Chatbot(props){
    //const [data,setData] = useState({});
    //const host = (window.location.hostname.replace('omni.','').replace('.www','') !== "localhost" && window.location.hostname.replace('omni.','').replace('.www','') !== "laravel.me" && window.location.hostname.replace('omni.','').replace('.www','') !== "hyperceed-beta.web.app" ? "https://ticket-api."+window.location.hostname.replace('omni.','').replace('www.','') : "https://ticket-api.hyperceed.com");
    useEffect(()=>{
        const script = document.createElement('script');
        script.src = "https://www.wauchat.com/inject/js/chatbot.js";   
        script.async = true;

        document.body.appendChild(script);    
        
        return () => {
            document.body.removeChild(script);
        }   
        // axios.post(host+'/auth/wauchat', {_token:props.getCookie("auth")},{withCredentials: true})
        // .then(res => {
        //     setData(res.data);
        //     if(res.data.id !== undefined){
             
        //     }
        // })
    },[]);    
    
    return (
        props.apiKey !== "" ? 
        <div id="chatbot" api_key={props.apiKey}></div>
        :
        null
    )
}

export default Chatbot;