import React, { Component } from 'react'
import ConversationItem from './CallConversationItem'
import Modal from 'react-modal';
import moment from 'moment';
import ConversationDate from './ConversationDate'
import { DeleteOutline,EditOutlined, ForwardOutlined,CodeRounded, ChatBubbleOutline } from '@material-ui/icons';
// import Store from './../../../store';
import { connect } from 'react-redux';
import Note from './Note';
// import {List,CellMeasurer, CellMeasurerCache} from 'react-virtualized';
// import { FixedSizeList as List } from 'react-window';

// const getState =  (state, action) => {
//     switch (action) {
//         case 'new_version':
//             state.newVersion = !state.newVersion;
//         return state;
//         case 'emotion':
//             state.emotion = !state.emotion;
//             return state;
//       default:
//         return state
//     }
//   }
  
const mapStateToProps = state => {
    console.log(state);
    return {
        emotion: state.emotion,
        agent_emotion:state.agent_emotion,
        context:state.context
    }
  }

const modalStyles = {
    content : {
      top                   : 'auto',
      left                  : 'auto',
      right                 : 'auto',
      bottom                : 'auto',
      maxWidth:'500px',
      border: 'none',
      boxShadow: '0 2px 3px 0 rgba(60,64,67,.3),0 6px 10px 4px rgba(60,64,67,.15)',
      borderRadius : '15px',
      padding : '0px'
    },
    overlay : {
      backgroundColor: ('rgba(0,0,0,0.5)')
    }
  };

class ConversationArea extends Component {
    currentId = "";

    obj = {
        currentDate : "",
        chatLength : null,
        currentUser : null
        
    }

    state = {
        currentHeight : null,
        overflowMenuVisible: false,
        overflowPos: 'left',
        modalIsOpen: false,
        showDelete: false,
        iframeId:[]
        
    }

    

    left = 0;

    top = 0;

    messageId = 0;

    messageType = "";

    constructor(props){
        super(props);
        //this.mainFragment = React.createRef();
        this.overflowRef = React.createRef();
        this.getHeight = this.getChatAreaHeight.bind(this);
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getMessagePos = this.getMessagePosFn.bind(this);
        this.itemRef = {};
    }

    getChatAreaHeight = () =>{
        // console.log(this.mainFragment.current);
        return document.getElementById("chat-area") !== null ? document.getElementById("chat-area").clientHeight : 0;
    }
    

    componentDidMount(){
        
        this.props.getHeight(this.getHeight);
        //this.setState({chats:this.props.chats[this.props.id].chats});
        this.currentId = this.props.id;
        setTimeout(()=>{
            // console.log("ok");            
            this.props.forceScroll();
            this.props.setOverflow();
            this.setState({showChat:true});
        },100);

        this.props.getMessagePos(this.getMessagePos);
        //console.log(this.props.chats);
    }

    closeOverflowMenu = () => {
        this.setState({overflowMenuVisible:false});
    }

    openModal() {
        this.setState({modalIsOpen: true,overflowMenuVisible:false});
      }
      
    
      afterOpenModal() {
        // references are now sync'd and can be accessed.
        // if(this.state.darkTheme){
        //   this.subtitle.style.color = '#fefefe';
        // }
        // else{
        //   this.subtitle.style.color = '#f00';
        // }
      }
    
      closeModal() {
        this.setState({modalIsOpen: false});
      }  

      setCurrentUser = (username) => {
        if(this.obj.currentUser === null){
            this.obj.currentUser = username;

            return true;
        }
        else if(this.obj.currentUser !== username){
            this.obj.currentUser = username;

            return true;
        }
        else{
            return false;
        }
      }

    setCurrentDate(date){

        if(this.obj.currentDate === ""){
            if(date === ""){
                this.obj.currentDate = moment().format('D-MM-YYYY');

            }
            else{
                this.obj.currentDate = date;
            }

            return true;
        }
        else if(this.obj.currentDate !== date){
            if(date === ""){
                let newDate = moment().format('D-MM-YYYY');
                
                

                if(newDate !== this.obj.currentDate){
                    this.obj.currentDate = newDate;

    
                    return true;
                }
                else{
                    
                    return false;
                }
                // console.log(this.obj.currentDate);
            }
            else{
                this.obj.currentDate = date;
            }

                return true;
        }
        else{
            return false;
        }
    }

    componentDidUpdate(){
        //console.log("updated");
        // if(this.obj.chatLength === null){
        //     this.obj.chatLength = this.props.chats.length;
        // }
        // else if(this.obj.chatLength !== this.props.chats.length){
        //     this.props.update(true);
        //     this.obj.chatLength = this.props.chats.length;
        // }
        
    }

    deleteMessage = () => {
        this.setState({modalIsOpen: false});
        this.props.deleteMessage(this.messageId,this.props.id)
    }

    hideOverflowMenu = () => {
        this.setState({overflowMenuVisible:false})
    }

    showOverflowMenu = (top,left,pos,messageId,showDelete,type) => {
        console.log(this.overflowRef.current.clientHeight);
        this.top = top;
        this.left = left;
        this.messageType = type;
        this.setState({overflowMenuVisible:true,overflowPos:pos,showDelete:showDelete});
        this.messageId = messageId;
        //console.log(top+" "+left);
    }

    mainChanged = () => {
        //console.log(this.mainFragment.clientHeight);
        //this.props.offsetScroll(this.mainFragment.clientHeight);
        //console.log(e);
        //console.log(this.mainFragment.clientHeight);
        //this.props.change("chatAreaHeight",this.mainFragment.clientHeight);
        // if(this.state.currentHeight !== null){
        //     if(this.state.currentHeight < this.mainFragment.clientHeight){
        //       //console.log(this.messageBox);
        //       var oldHeight = this.state.currentHeight;
        //       var diff = this.mainFragment.clientHeight - oldHeight;
        //       //console.log(diff);
        //       this.props.offsetScroll2(diff);
        //     }
        //     this.setState({currentHeight:this.mainFragment.clientHeight});
        //   }
        //   else{
        //     this.setState({currentHeight:this.mainFragment.clientHeight});
        //   }        
    }  
    
    toggleIframe = (id) => {
        let iframeId = this.state.iframeId;
        if(iframeId.includes(id)){
            iframeId.splice(iframeId.indexOf(id),1);
        }
        else{
            iframeId.push(id);
            
        }

        this.setState({iframeId:iframeId})
    }

    getMessagePosFn = (id) => {
        if(this.itemRef[id]){
            return {ref:this.itemRef[id],data:this.itemRef[id].getBoundingClientRect()};
            console.log(this.itemRef[id].getBoundingClientRect());
            // console.log(this.itemRef[id].getBoundingClientRect().y +" "+ document.innerHeight );
            if(this.itemRef[id].getBoundingClientRect().height === 0){
                console.log("false!");
                return false;
            }
            else{
                return this.itemRef[id].getBoundingClientRect().y;
            }
        }
        else{
            return {ref:false,data:{height:0}};
        }
    }
    

    render() {
        this.obj.currentDate = "";
        //this.setState({currentHeight:null});
        return( 
            <div id="chat-area" ref={el => { if(el !== null){this.mainFragment = el; }}}> 
                <div onClick={this.hideOverflowMenu} className={"overflow-menu-bg "+(!this.state.overflowMenuVisible ? "overflow-menu-bg-hide" : "")}>
                </div>   
                <div ref={this.overflowRef} className={(this.props.dark ? "dark":"")+" overflow-menu-wrapper "+(this.state.overflowPos === "left" ? "transform-top-left" : "transform-top-right")+" z-depth-1 chat-overflow-menu " + (this.state.overflowMenuVisible ? "overflow-menu-show" : "overflow-menu-hide")}
                style={{left:this.left,top:this.top}}>
                    <div className="overflow-menu-title padding-right-2x padding-left-2x">
                        <h6 className="unselectable bold-text">Action</h6>
                    </div>
                    {
                        (this.props.channel === "EMAIL" && this.messageType === "text/html" ? 
                            <div className="overflow-menu-item" style={{width:'200px'}} onClick={()=>{this.toggleIframe(this.messageId);this.setState({overflowMenuVisible:false})}}>
                                <div className="valign-wrapper">
                                    {(this.state.iframeId.includes(this.messageId) ? <ChatBubbleOutline/> : <CodeRounded/>)}
                                    <span className="valign-wrapper overflow-menu-text bold-text unselectable margin-left-1x">View in {this.state.iframeId.includes(this.messageId) ? "text" : "HTML"}</span>
                                </div>        
                            </div>                
                            :
                            null
                        )
                    }                    
                    {
                        (this.props.channel === "EMAIL" ? 
                            <div className="overflow-menu-item" style={{width:'200px'}} onClick={()=>{this.props.toggleForward(this.messageId);this.setState({overflowMenuVisible:false})}}>
                                <div className="valign-wrapper">
                                    <ForwardOutlined/>
                                    <span className="valign-wrapper overflow-menu-text bold-text unselectable margin-left-1x">Forward</span>
                                </div>        
                            </div>                
                            :
                            null
                        )
                    }
                    {/* <div className="divider margin-bottom-5"></div> */}
                    <Note ticketId={this.props.id} id={this.messageId} username={this.props.username} closeOverflowMenu={this.closeOverflowMenu}/>                      
                    {(this.state.showDelete  ? <div className={"overflow-menu-item delete-item"+(this.props.forwardMode ? " disabled" : "")} style={{width:'200px'}} onClick={this.openModal}>
                        <div className="valign-wrapper">
                            <DeleteOutline/>
                            <span className="valign-wrapper overflow-menu-text bold-text unselectable margin-left-1x">Delete</span>
                        </div>
                    </div> : null )}
                    {/* <div className="overflow-menu-item" style={{width:'200px'}}>
                        <span className="valign-wrapper overflow-menu-text bold-text">Whisper</span>
                    </div>        
                    <div className="overflow-menu-item" style={{width:'200px'}}>
                        <span className="valign-wrapper overflow-menu-text bold-text">Barge</span>
                    </div>        */}
                </div>    

                <Modal
                      isOpen={this.state.modalIsOpen}
                      onAfterOpen={this.afterOpenModal}
                      onRequestClose={this.closeModal}
                      style={modalStyles}
                      contentLabel="Example Modal"
                      ariaHideApp={false} 
                      closeTimeoutMS={200}       
                                                       
                      className={""+(this.props.dark ? "dark" : null)}   
 
                      
                    >
                      <div style={{width:'500px'}}>
                        <div className="ng-modal-main-header">
                          <h5 className="bold-text-2 smaller-font">Delete</h5>
                        </div>
                        <div className="ng-modal-main-body padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x">
                          <h6 className="modal-bottom-margin">Are you sure you want to delete this message?</h6>
                        </div>
                        <div className="ng-modal-main-footer right-text">                      
                          <button className="btn-flat ng-close-btn waves-effect waves-dark margin-right-1x" onClick={this.closeModal}>cancel</button>
                          <button className="btn-flat red waves-effect waves-light white-text" onClick={this.deleteMessage}>Delete</button>
                          {/* <button className="btn waves-effect waves-light margin-left-1x" onClick={this.closeModal}>OK</button> */}
                        </div>
                      </div>
                    </Modal>          
                    {/* {
                        
                        (this.props.chatbotData !== null &&  this.props.chatbotData !== undefined ? 
                            <React.Fragment>
                            {this.props.chatbotData.conversation.map((obj,i) => (
                               (typeof obj === 'object' && obj !== null ? 
                                    <ConversationItem
                                    key={i}
                                    ticketId={this.props.id}
                                    getInfo={this.props.getInfo}
                                    viewTranslated={this.props.viewTranslated}
                                    firstMessage={i === 0 ? true : false}
                                    emojiSet={this.props.emojiSet}
                                    username={this.props.username}
                                    profilePhoto={this.props.adminPhoto}
                                    loadMoreChanged={this.props.loadMoreChanged}
                                    offsetScroll={this.props.offsetScroll}
                                    message={{type:"text",emotion_2 : [],sender:"chatbot"}}
                                    text={this.props.viewTranslated ? (obj.translateText ? obj.translateText : obj.text.en) : obj.text.en}
                                    originalText={obj.text.en}
                                    showDate={false}
                                    imgViewer={this.props.imgViewer}
                                    rich={false}
                                    passFromChild={this.props.passFromChild}
                                    showOverflowMenu={false}      
                                    userType={this.props.userType}   
                                    participant={this.props.participant} 
                                    userInfo={this.props.userInfo}      
                                    currentUser={this.setCurrentUser.bind(this)}     
                                    displayName={false} 
                                    alwaysDisplay={false}
                                    visitorName={this.props.name}
                                    emotion={this.props.emotion}
                                    isChatbot={true}
                                />
                                :
                                <ConversationItem
                                key={i}
                                ticketId={this.props.id}
                                getInfo={this.props.getInfo}
                                viewTranslated={this.props.viewTranslated}
                                firstMessage={i === 0 ? true : false}
                                emojiSet={this.props.emojiSet}
                                username={this.props.username}
                                profilePhoto={this.props.profilePhoto}
                                loadMoreChanged={this.props.loadMoreChanged}
                                offsetScroll={this.props.offsetScroll}
                                message={{type:"text",emotion_2 : [],sender:"user"}}
                                text={this.props.viewTranslated ? (obj.translateText ? obj.translateText : obj) : obj}
                                originalText={obj}
                                showDate={false}
                                imgViewer={this.props.imgViewer}
                                rich={false}
                                passFromChild={this.props.passFromChild}
                                showOverflowMenu={false}      
                                userType={this.props.userType}   
                                participant={this.props.participant} 
                                userInfo={this.props.userInfo}      
                                currentUser={this.setCurrentUser.bind(this)}     
                                displayName={false} 
                                alwaysDisplay={false}
                                visitorName={this.props.name}
                                emotion={this.props.emotion}
                            />
                               )
                            ))}
                            <div className="special-strikethrough red-text">
                            <span className="special-strikethrough-text bold-text">End of Chat Bot Conversation</span>
                            </div>
                            {
                                (this.props.loadMore ? 
                                    <button className={"btn-flat full-width margin-bottom-1x "+(this.props.dark ? "grey darken-3" : "grey lighten-2")}onClick={this.props.loadMoreChat}>Load More Message</button>
                                    :
                                    null
                                    )
                            }
                        </React.Fragment>
                        
                            :
                            null
                        )                            
                    } */}
                    {(this.props.chats !== undefined ?
                        // <List height={100} itemCount={this.props.chats.length} itemSize={35} itemData={this.props.chats}>
                        //     {
                        //         (props) => (
                        //                 <ConversationItem
                        //                 emojiSet={this.props.emojiSet}
                        //                 username={this.props.username}
                        //                 profilePhoto={this.props.profilePhoto}
                        //                 loadMoreChanged={this.props.loadMoreChanged}
                        //                 offsetScroll={this.props.offsetScroll}
                        //                 message={props.data[props.index]}
                        //                 showDate={ this.setCurrentDate.bind(this)}
                        //                 imgViewer={this.props.imgViewer}
                        //                 rich={this.props.rich}
                        //                 passFromChild={this.props.passFromChild}
                        //                 showOverflowMenu={this.showOverflowMenu}      
                        //                 userType={this.props.userType}   
                        //                 participant={this.props.participant} 
                        //                 userInfo={this.props.userInfo}      
                        //                 currentUser={this.setCurrentUser.bind(this)}     
                        //                 displayName={this.props.displayName} 
                        //                 alwaysDisplay={this.props.alwaysDisplay}
                        //                 visitorName={this.props.name}
                        //             />
                        //         )
                        //     }
                        // </List>

                        this.props.chats.map((obj2, index)=> (
                            (!obj2.count ? <React.Fragment key={index}>
                                
                                <div className="conversation-wrapper">
                                    {(obj2.data ?
                                        <ConversationDate date={moment(obj2.date, 'DD/MM/YYYY').format('D MMMM YYYY')}/>
                                        : null )}
                                    <div style={{height:'30px'}}>

                                    </div>
                                {(obj2.data ? obj2.data.map((obj,i) => (                           
                                    <ConversationItem
                                        ts={this.props.ts}
                                        currentMessageId={this.props.currentMessageId}
                                        refCallback={e => {this.itemRef[obj.id] = e}}
                                        iframeId={this.state.iframeId}
                                        channel={this.props.channel}
                                        setSelectedMessage={this.props.setSelectedMessage}
                                        selectedMessage={this.props.selectedMessage}
                                        forwardMode={this.props.forwardMode}
                                        ticketId={this.props.id}
                                        getInfo={this.props.getInfo}
                                        viewTranslated={this.props.viewTranslated}
                                        firstMessage={i === 0 ? true : false}
                                        key={obj.id}
                                        emojiSet={this.props.emojiSet}
                                        username={this.props.username}
                                        profilePhoto={this.props.profilePhoto}
                                        loadMoreChanged={this.props.loadMoreChanged}
                                        offsetScroll={this.props.offsetScroll}
                                        message={obj}
                                        text={this.props.viewTranslated && obj.type === "text" && obj.translated_text ? (obj.translated_text[this.props.language] ? obj.translated_text[this.props.language] : obj.message) : obj.message}
                                        originalText={obj.message}
                                        showDate={ this.setCurrentDate.bind(this)}
                                        imgViewer={this.props.imgViewer}
                                        rich={this.props.rich}
                                        passFromChild={this.props.passFromChild}
                                        showOverflowMenu={false}      
                                        userType={this.props.userType}   
                                        participant={this.props.participant} 
                                        userInfo={this.props.userInfo}      
                                        currentUser={this.setCurrentUser.bind(this)}     
                                        displayName={this.props.displayName} 
                                        alwaysDisplay={this.props.alwaysDisplay}
                                        visitorName={this.props.name}
                                        emotion={this.props.emotion}
                                        deleteMessage={this.props.deleteMessage}
                                        openTranslate={this.props.openTranslate}
                                        kbDataHighlight={this.props.kbDataHighlight}
                                        agentEmotion={this.props.agent_emotion}
                                    />
                                )): null)   }
                                <div style={{height:'30px'}}>

                                </div>                                
                                </div>                             
                            </React.Fragment> : null)
                        ))


                    : null )}                 
           </div>
        );
        

        
    }
}

export default connect(mapStateToProps)(ConversationArea);