import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars';
import {NavigateNext,NavigateBefore} from '@material-ui/icons'
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Moment from 'react-moment';
import moment from 'moment';
import axios from 'axios';


export default class FilterPanel extends Component {

    toggleDep = (e,id) => {
 
        if(e.target.checked){
            let dep = this.state.dep;
            if(dep.indexOf(id) === -1){
                dep.push(id);
            }
            this.setState({dep:dep});
            console.log(dep);
            this.props.setDepFilter(dep);
        }
        else{
            let dep = this.state.dep;
            if(dep.indexOf(id) !== -1){
                let index = dep.indexOf(id);
                dep.splice(index,1);
            }      
            this.setState({dep:dep}); 
            this.props.setDepFilter(dep);
            console.log(dep);     
        }
    }

    toggleLabel = (e,id) => {
        if(e.target.checked){
            let label = this.state.label;
            if(label.indexOf(id) === -1){
                label.push(id);
            }
            this.setState({label:label});
            this.props.setLabelFilter(label);
            console.log(label);
        }
        else{
            let label = this.state.label;
            if(label.indexOf(id) !== -1){
                let index = label.indexOf(id);
                label.splice(index,1);
            }      
            this.setState({label:label}); 
            this.props.setLabelFilter(label);
            console.log(label);     
        }
    }

    toggleEmotion = (e,id) => {
        if(e.target.checked){
            let emotionFilter = this.props.emotionFilter;
            if(emotionFilter.indexOf(id) === -1){
                emotionFilter.push(id);
            }
            
            this.props.setLabelFilter(emotionFilter);

        }
        else{
            let emotionFilter = this.props.emotionFilter;
            if(emotionFilter.indexOf(id) !== -1){
                let index = emotionFilter.indexOf(id);
                emotionFilter.splice(index,1);
            }
            this.props.setLabelFilter(emotionFilter);
   
        }
    }

    dateChanged = ({ startDate, endDate }) => {
        let sd = startDate;
        let ed = endDate
        this.setState({ startDate, endDate },()=>{
            if(sd !== null){
                this.props.setStartDateFilter(sd.format('YYYY-MM-DD'));
            }
            if(ed !== null){
                this.props.setEndDateFilter(ed.format('YYYY-MM-DD'));
            }
        });

    }

    constructor(props){
        super(props);
        this.state = {
            startDate:null,
            endDate:null,
            dep:[],
            label:[],
            emotion:[
                {name:"Angry",value:"angry"},
                {name:"Happy",value:"happy"},
                {name:"Calm",value:"calm"},
                {name:"Sad",value:"sad"},
                {name:"Fearful",value:"fearful"},
                {name:"Disgust",value:"disgust"},
                {name:"Surprised",value:"surprised"},
            ]
        }

    }

    componentDidMount(){
        this.props.setStartDateFilter(null);
        this.props.setEndDateFilter(null);
        this.props.setDepFilter([]);
        this.props.setLabelFilter([]);
    }

    clearDates = () => {
        this.props.setStartDateFilter(null);
        this.props.setEndDateFilter(null);
        this.setState({startDate:null,endDate:null})
    }

    render() {
        return (
            <div className="flex-no-shrink flex-col" style={{width:"300px",minWidth:"1px"}}>
                <div className="padding-left-2x padding-right-2x padding-top-2x padding-bottom-2x flex-col header-border-bottom" style={{flexShrink:0,position:'relative',height:'64px',backgroundColor:!this.props.dark ? 'rgba(255,255,255,1)' : '#333333'}}>
                    <div className="chat-info-header full-width">
                        <span className="bold-text emphasize-text chat-info-header-text">Advanced Filter</span>
                    </div>
                    {/* <div className="icon-button-hover linked" style={{marginTop:"5px"}} onClick={this.closePanel}>
                        <Close className="material-icons linked"/>
                    </div> */}
                </div>  
                {/* <Scrollbars> */}
                    <div className="full-width full-height overflow-y relative-content">
                        {/* <div className="relative-content date-picker-container">
                            <div className="date-picker-bg" style={{position:'fixed',zIndex:999,width:'300px'}}>
                            <DateRangePicker
                                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                onDatesChange={this.dateChanged} // PropTypes.func.isRequired,
                                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                hideKeyboardShortcutsPanel={true}
                                noBorder={true}
                                displayFormat="DD-MM-YYYY"
                                minimumNights={0}
                                navNext={<div className="react-date-next icon-button-hover-2"><NavigateNext/></div>}
                                navPrev={<div className="react-date-prev icon-button-hover-2"><NavigateBefore/></div>}
                                customArrowIcon={<NavigateNext/>}
                                isOutsideRange={day => (moment().endOf('day').diff(day) < 0)}
                                readOnly={true}
                                />  

                                <button className={"btn-flat grey grey-text text-darken-3"+(this.state.startDate === null && this.state.endDate === null ? " disabled" : "") + (this.props.dark ? " darken-3" : " lighten-2")} onClick={this.clearDates}>Clear</button>
                            </div>
                        </div>        */}
                        {/* <div className="divider flex-no-shrink" ></div>                     */}
                        {/* <div className="padding-left-2x padding-right-2x padding-top-1x padding-bottom-1x">
                            <h6 className="bold-text-2">Department</h6>
                            {this.props.department.map((obj,i) => (
                                <div className="flex-row" key={i}>
                                    <div className="full-width">
                                        <h6>{obj.name}</h6>
                                    </div>
                                    <div className="table-cell">
                                        <div className="ios-toggle">
                                            <input className="ios" type="checkbox" id={("switch"+obj.id)} onChange={(e)=>this.toggleDep(e,obj.id)}/><label className="ios" htmlFor={("switch"+obj.id)}>Toggle</label>
                                        </div> 
                                    </div>
                                </div>
                            ))}
                        </div> */}
                        {/* <div className="divider"></div>
                        <div className="padding-left-2x padding-right-2x padding-top-1x padding-bottom-1x">
                            <h6 className="bold-text-2">Label</h6>
                            {this.props.label.map((obj,i) => (
                                <div className="flex-row" key={i}>
                                    <div className="full-width">
                                        <h6>{obj.name}</h6>
                                    </div>
                                    <div className="table-cell">
                                        <div className="ios-toggle">
                                            <input className="ios" type="checkbox" id={("switch-label-"+obj.id)} onChange={(e)=>this.toggleLabel(e,obj.id)}/><label className="ios" htmlFor={("switch-label-"+obj.id)}>Toggle</label>
                                        </div> 
                                    </div>
                                </div>
                            ))}
                        </div>     */}
                        <div className="divider"></div>
                        <div className="padding-left-2x padding-right-2x padding-top-1x padding-bottom-1x">
                            <h6 className="bold-text-2">Emotion</h6>
                            {this.state.emotion.map((obj,i) => (
                                <div className="flex-row" key={i}>
                                    <div className="full-width">
                                        <h6>{obj.name}</h6>
                                    </div>
                                    <div className="table-cell">
                                        <div className="ios-toggle">
                                            <input className="ios" type="checkbox" id={("switch-label-"+obj.value)} onChange={(e)=>this.toggleEmotion(e,obj.value)}/><label className="ios" htmlFor={("switch-label-"+obj.value)}>Toggle</label>
                                        </div> 
                                    </div>
                                </div>
                            ))}
                        </div>                           
                                   
                    </div>  
                {/* </Scrollbars>               */}
                    <div className="divider"></div>
                    <div style={{padding:' 10px 20px 10px 20px'}}>
                        <button className={"button-new"+(this.props.recordingLoading ? " disabled" : "")} onClick={this.props.loadTicket}>{this.props.recordingLoading ? "Loading" : "Filter"}</button>
                    </div>  
         
            </div>
        )
    }
}
