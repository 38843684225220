import React, { useState,useEffect,useRef } from 'react';
import Variable from '../../variable';
import axios from 'axios';
import 'react-dates/lib/css/_datepicker.css';
import BootstrapModal from 'react-bootstrap/Modal';
import { RadioButtonChecked,RadioButtonUnchecked } from '@material-ui/icons';
import { IconPencil, IconTrash,IconRefresh, IconAlertTriangle } from '@tabler/icons-react';
import DropdownMenu from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'

const lang = {"en":"English","ms":"Malay","zh_CN":"Chinese (China)","zh_TW":"Chinese (TW)"};

const popperConfig = {
    strategy: "fixed"
  };
  

export default function Voicebot(props) {

    const host = Variable.host;

    const [canned,setCanned] = useState([]);

    const [whatsapp,setWhatsapp] = useState([]);

    const [modalOpen,setModalOpen] = useState(false);


    const [modalType,setModalType] = useState("add");

    const [loading,setLoading] = useState(false);

    const [title,setTitle] = useState("");

    const [templateName,setTemplateName] = useState("");

    const [message,setMessage] = useState("");

    const [origin,setOrigin] = useState("");

    const [errorMessage,setErrorMessage] = useState("");

    const [editTitle,setEditTitle] = useState("");

    const [editMessage,setEditMessage] = useState("");

    const [editOrigin,setEditOrigin] = useState("");

    const [selectedId,setSelectedId] = useState(-1);

    const [account,setAccount] = useState([]);

    const [handleAccount,setHandleAccount] = useState([]);

    const [attachment,setAttachment] = useState([]);

    const fileUploader = useRef(null);

    const [uploaded,setUploaded] = useState([]);

    const [readyToDelete,setReadyToDelete] = useState(false);

    const [tabSelected,setTabSelected] = useState(0);

    const [selectedCategory,setSelectedCategory] = useState("MARKETING");

    const [parameter,setParameter] = useState({});

    const [parameterCount,setParameterCount] = useState([]);

    const [selectedLanguage,setSelectedLanguage] = useState("en");

    const [whatsappAccount,setWhatsappAccount] = useState([]);

    const [selectedNumber,setSelectedNumber] = useState(-1);

    const [adding,setAdding] = useState(false);

    const [boost,setBoost] = useState(0);
    const [stability,setStability] = useState(0);
    const [style,setStyle] = useState(0);
    const [speaker,setSpeaker] = useState(false);

    let removeTimeout;

    const toggleAccount = (acc)=>{      

        let temp = [...handleAccount];

        if(temp.indexOf(acc) !== -1){
            let index = temp.indexOf(acc);
            temp.splice(index,1);
            setHandleAccount(temp);
        }
        else{
            temp.push(acc);
            setHandleAccount(temp);
        }

    }

    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    } 

    const loadCanned = () => {
        axios.get(host+'/api/get_voice', {params:{_token:getCookie("auth")}},{withCredentials: true})
        .then(res => {
            setCanned(res.data);
        })  
    }

    const loadWhatsapp = () => {
        axios.get(host+'/api/message/whatsapp/get', {params:{_token:getCookie("auth")}},{withCredentials: true})
        .then(res => {
            setWhatsapp(res.data);
        })  

        axios.get(host+'/api/whatsapp_number', {params:{_token:getCookie("auth")}},{withCredentials: true})
        .then(res => {
        if(res.data.length > 0){
            setWhatsappAccount(res.data);
        }
        else{
            setWhatsappAccount(res.data);
        }

        }) 
    }

    useEffect(()=>{
        loadCanned();
    },[]);

    const addCannedResponse = () => {
        if(title === ""){
            setErrorMessage("Please fill");
            return false;
        }
        else{
            setErrorMessage("");
        }
        const formData = new FormData();
        for(var i = 0; i < attachment.length; i++){
            formData.append('attachment[]',attachment[i]);
        }
        formData.append('_token',getCookie("auth"));

        formData.append('name',title);
        setAdding(true);
        axios.post(host+'/api/add_voice',formData,{headers:{'Content-Type':'multipart/form-data'},withCredentials: true})
        .then(res => {
            setModalOpen(false);
            // props.setChildrenModalOpened(false);
            loadCanned();
            setAdding(false);
            setAttachment([]);
            
        }).catch(err => {
            setErrorMessage(err.response.data.description);
        });  
    }

    const addWhatsappTemplate = () => {
        if(title === "" || message === "" || selectedNumber === -1){
            setErrorMessage("Please fill");
            return false;
        }
        else{
            setErrorMessage("");
        }
        const formData = new FormData();
        for(var i = 0; i < attachment.length; i++){
            formData.append('attachment[]',attachment[i]);
        }
        formData.append('_token',getCookie("auth"));

        formData.append('name',title);
        axios.post(host+'/api/add_voice',formData,{headers:{'Content-Type':'multipart/form-data'},withCredentials: true})
        .then(res => {
            setModalOpen(false);
            // props.setChildrenModalOpened(false);
            loadCanned();
            setAttachment([]);
            
        }).catch(err => {
            setErrorMessage(err.response.data.description);
        });  
    }

    const editCannedResponse = () =>{
        const formData = new FormData();
        // for(var i = 0; i < attachment.length; i++){
        //     formData.append('attachment[]',attachment[i]);
        // }
        let config = {
            "similarity_boost" : parseFloat(boost),
            "stability" : parseFloat(stability),
            "style":parseFloat(style),
            "use_speaker_boost":speaker
        }
        // console.log(config);
        // return false;
        formData.append('_token',getCookie("auth"));
        formData.append('config',JSON.stringify(config));
        // formData.append('message',editMessage);
        // formData.append('title',editTitle);
        formData.append('id',selectedId);
        // formData.append('origin',handleAccount.join(","));
        axios.post(host+'/api/edit_voice',formData,{withCredentials: true})
        .then(res => {
            setModalOpen(false);
            props.setChildrenModalOpened(false);
            loadCanned();
            setAttachment([]);
            
        }).catch(err => {
            setErrorMessage(err.response.data.description);
        });  
    }

    const editWhatsappTemplate = () =>{
        const formData = new FormData();

        formData.append('_token',getCookie("auth"));
        formData.append('message',editMessage);
        formData.append('title',editTitle);
        formData.append('id',selectedId);
        formData.append('language',selectedLanguage);
        formData.append('category',selectedCategory);
        formData.append('parameter',JSON.stringify(parameter));
        axios.post(host+'/api/message/whatsapp/edit',formData,{headers:{'Content-Type':'multipart/form-data'},withCredentials: true})
        .then(res => {
            setModalOpen(false);
            props.setChildrenModalOpened(false);
            loadWhatsapp();

            
        }).catch(err => {
            setErrorMessage(err.response.data.description);
        });  
    }

    const fileUploaded = (e) => {
        //this.fileUploader.files[0];
        //console.log("file upload detected");
        //console.log(this.fileUploader.files[0]);
        var files = [...attachment];
        for(let i = 0; i < fileUploader.current.files.length; i++){
            files.push(fileUploader.current.files[i]);
        }
        setAttachment(files);        
    }

    const deleteCannedResponse = () => {
        if(tabSelected === 0){
            axios.delete(host+'/api/delete_voice',{params:{_token:getCookie("auth"),id:selectedId}},{withCredentials: true})
            .then(res => {
                setModalOpen(false);
                props.setChildrenModalOpened(false);
                loadCanned();
                
            }).catch(err => {
                setErrorMessage(err.response.data.description);
            });  
        }
        else{
            // axios.delete(host+'/api/message/whatsapp',{params:{_token:getCookie("auth"),id:selectedId}},{withCredentials: true})
            // .then(res => {
            //     setModalOpen(false);
            //     props.setChildrenModalOpened(false);
            //     loadWhatsapp();
                
            // }).catch(err => {
            //     setErrorMessage(err.response.data.description);
            // });  
        }
    }

    const deleteAll = () => {
        if(readyToDelete){
            clearTimeout(removeTimeout);
            axios.delete(host+'/api/message/canned/attachment',{params:{_token:getCookie("auth"),id:selectedId}},{withCredentials: true})
            .then(res => {
                // setModalOpen(false);
                // props.setChildrenModalOpened(false);
                // loadCanned();
                setUploaded([]);
                setReadyToDelete(false);
                
                
            }).catch(err => {
                setErrorMessage(err.response.data.description);
                setReadyToDelete(false);
            });  
        }
        else{
            setReadyToDelete(true);
            clearTimeout(removeTimeout);
            removeTimeout = setTimeout(()=>{
                setReadyToDelete(false);
            },3000);
        }
    }

    const handleModalClose = () => {
        setModalOpen(false);
        props.setChildrenModalOpened(false);
        setAttachment([]);
    }


    const edit = (i) => {
        if(tabSelected === 0){
            setEditTitle(canned[i].title);
            // setEditMessage(canned[i].response_message);
            // setHandleAccount(canned[i].origin.split(","));
            // if(canned[i].attachment !== null && canned[i].attachment !== ""){
            //     setUploaded(JSON.parse(canned[i].attachment));
            // }
            // else{
            //     setUploaded([]);
            // }
            if(canned[i].config.similarity_boost){
                setBoost(canned[i].config.similarity_boost);
            }
            else{
                setBoost(0);
            }
            if(canned[i].config.stability){
                setStability(canned[i].config.stability);
            }
            else{
                setStability(0);
            }
            if(canned[i].config.style){
                setStyle(canned[i].config.style);
            }
            else{
                setStyle(0);
            }
            if(canned[i].config.use_speaker_boost){
                setSpeaker(canned[i].config.use_speaker_boost);
            }
            else{
                setSpeaker(false);
            }
            
            setModalType("edit");
            setModalOpen(true);
            props.setChildrenModalOpened(true);
            setSelectedId(canned[i].id);
        }
        else{
            setEditTitle(whatsapp[i].template_name);
            setEditMessage(whatsapp[i].template_data);
            setSelectedLanguage(whatsapp[i].wa_language);
            updateParameter(whatsapp[i].template_data);
            // setHandleAccount(canned[i].origin.split(","));
            // if(canned[i].attachment !== null && canned[i].attachment !== ""){
            //     setUploaded(JSON.parse(canned[i].attachment));
            // }
            // else{
            //     setUploaded([]);
            // }

            let item  = {}
            for(let s = 0; s < whatsapp[i].wa_param.length; s++){
                item[s] = whatsapp[i].wa_param[s].param ?? "";
            }
            setParameter(item);

            setModalType("edit");
            setModalOpen(true);
            props.setChildrenModalOpened(true);
            setSelectedId(whatsapp[i].id);
        }
    }

    const updateParameter = (text) => {
        let pattern = /{{(\w+)}}/g;

        let count = [];

        let match = text.replace( pattern,(match, contents, offset, input_string) =>
        {
            // let res = this.getVariable(contents,"case")
            // return (res !== "" ?  res : match);
            count.push(match);
            return match;
        });

        console.log(count);

        setParameterCount(count);
    }




    return (
        <div className="settings-section-1 full-height">
            <BootstrapModal show={modalOpen} onHide={handleModalClose}>
                    <BootstrapModal.Header closeButton>
                        <BootstrapModal.Title><h5 className="bold-text-2">{modalType === "add" ? "Add "+(tabSelected === 0 ? "Voice" : "Whatsapp Template") : (modalType === "edit" ? "Voice Settings" : (modalType === "delete" ? "Delete Voice" : ""))}</h5></BootstrapModal.Title>
                    </BootstrapModal.Header>
                    <BootstrapModal.Body>
                        { errorMessage !== "" ? <h6 className="red lighten-3 callout-box white-text">{errorMessage}</h6> : null }
                        {
                            tabSelected  === 1 && modalType === "edit" ?
                            <div className="info-box warning">
                                <div className="valign-wrapper">
                                    <IconAlertTriangle 
                                        size={18} // set custom `width` and `height`
                                        //   color="white" // set `stroke` color
                                        stroke={3}  // set `stroke-width`
                                        strokeLinejoin="miter" // override other SVG props
                                    />     
                                    <h6 className="margin-left-1x">You can only edit active template once every 24 hours.</h6>
                                </div>
                            </div>
                        : null
                        }
                         {
                           tabSelected === 1 && modalType === "add" ?
                            <>
                              <span className='input-label margin-top-2x'>WhatsApp Number</span>
                              <DropdownMenu className=''>
                                  <DropdownMenu.Toggle variant="success" id="dropdown-basic">
                                      {
                                        selectedNumber === -1 ? "Please Select Number" : (
                                          whatsappAccount[selectedNumber].desc
                                        )
                                      }
                                  </DropdownMenu.Toggle>

                                  <DropdownMenu.Menu popperConfig={popperConfig} className="dropdown-fixed">
                      
                                    {
                                      whatsappAccount.map((obj,i) => (
                                        <DropdownMenu.Item eventKey={i}  onSelect={()=>{
                                         setSelectedNumber(i)
                                          }}>{obj.desc}</DropdownMenu.Item>
                                        
                                      ))
                                    }

                                  </DropdownMenu.Menu>
                              </DropdownMenu> 
                            </>
                            :
                            null
                          } 
                        {
                            modalType === "add" ?
                                <div className="full-width full-height">
                                    <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <span>Title</span>
                                        <input onChange={(e)=>setTitle(e.target.value)} className="browser-default" type="text" value={title}/>
                                    </label> 
                                    {
                                        tabSelected === 1 ?
                                        <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                            <span>Template Name</span>
                                            <input onChange={(e)=>{if(e.target.value.match(/^[a-z\d\_\s]+$/i) === null && e.target.value.length > 0){return false;}setTemplateName(e.target.value.replace(/\s/g, "_").toLowerCase())}} className="browser-default" type="text" value={templateName}/>
                                        </label> 
                                        :
                                        null
                                    }
                                    {/* <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <span>Message</span>
                                        <textarea onChange={(e)=>{setMessage(e.target.value);if(tabSelected === 1){
                                            updateParameter(e.target.value);
                                        }}} className="browser-default" type="text" value={message}/>
                                    </label>  */}
                                    {/* <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <span>Origin Email (Optional)</span>
                                        <input onChange={(e)=>setOrigin(e.target.value)} className="browser-default" type="text" value={origin}/>
                                    </label>  */}

                                    <span className='input-label'>Attachment</span>
                                    {
                                        uploaded.map((obj,i)=>(
                                            <h6>{obj}</h6>
                                        ))
                                    }
                                    {readyToDelete ? <h6 className='red-text'>Click again to confirm delete</h6> : null}
                                    {/* <button className='button-new' onClick={deleteAll}>Delete All Uploaded</button> */}
                                    <button className='button-new margin-left-1x' onClick={()=>{fileUploader.current.click()}}>Add File</button>
                                    <button className={'button-new margin-left-1x'+(attachment.length > 0 ? '' : ' disabled')} onClick={()=>{setAttachment([])}}>Remove Attached</button>
                                    <h6>{attachment.length} file attached.</h6>
                                    <input multiple="multiple" type="file" id="file" ref={fileUploader} style={{display:'none'}} onChange={(e)=>fileUploaded(e)} onClick={(event)=> { event.target.value = null}}/>           
                                </div>

                            :
                            (
                                modalType === "edit" ?
                                <div className="full-width full-height">
                                    {/* <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <span>Title</span>
                                        <input onChange={(e)=>setEditTitle(e.target.value)} className="browser-default" type="text" value={editTitle}/>
                                    </label> */}
                                <Form>
                                    <Form.Group controlId="formBasicRange">
                                        <Form.Label>Similarity Boost {boost}</Form.Label>
                                        <Form.Control min={0} step={0.05} max={1} value={boost} type="range" onChange={(e)=>{setBoost(e.target.value)}}/>
                                    </Form.Group>
                                </Form>
                                <Form>
                                    <Form.Group controlId="formBasicRange">
                                        <Form.Label>Stability {stability}</Form.Label>
                                        <Form.Control min={0} step={0.05} max={1} value={stability} type="range" onChange={(e)=>{setStability(e.target.value)}}/>
                                    </Form.Group>
                                </Form>
                                <Form>
                                    <Form.Group controlId="formBasicRange">
                                        <Form.Label>Style {style}</Form.Label>
                                        <Form.Control min={0} step={0.05} max={1} value={style} type="range" onChange={(e)=>{setStyle(e.target.value)}}/>
                                    </Form.Group>
                                </Form>
                                <div className="ios-toggle">
                                    <span className='input-label'>Speaker Boost</span>
                                    <input className="ios" type="checkbox" id="switch" checked={speaker} onChange={()=>{setSpeaker(!speaker)}}/><label className="ios" htmlFor="switch">Toggle</label>
                                </div> 
                                    {/* <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <span>Message</span>
                                        <textarea onChange={(e)=>{setEditMessage(e.target.value);
                                        if(tabSelected === 1){
                                            updateParameter(e.target.value);
                                        }
                                        }} className="browser-default" type="text" value={editMessage}/>
                                    </label>  */}
                                    {/* <span className='input-label'>Attachment</span> */}
                                    {/* {
                                        uploaded.map((obj,i)=>(
                                            <h6>{obj}</h6>
                                        ))
                                    } */}
                                    {/* {readyToDelete ? <h6 className='red-text'>Click again to confirm delete</h6> : null} */}
                                    {/* <button className='button-new' onClick={deleteAll}>Delete All Uploaded</button> */}
                                    {/* <button className='button-new margin-left-1x' onClick={()=>{fileUploader.current.click()}}>Add File</button> */}
                                    {/* <button className={'button-new margin-left-1x'+(attachment.length > 0 ? '' : ' disabled')} onClick={()=>{setAttachment([])}}>Remove Attached</button> */}
                                    {/* <h6>{attachment.length} file attached.</h6> */}
                                    {/* <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                        <span>Origin</span>
                                        <textarea onChange={(e)=>setEditOrigin(e.target.value)} className="browser-default" type="text" value={editOrigin}/>
                                    </label>  */}
                                    
                                    {/* <input type="file" id="file" ref={fileUploader} style={{display:'none'}} onChange={(e)=>fileUploaded(e)} onClick={(event)=> { event.target.value = null}}/>            */}
                                </div>

                            :
                            null
                            )
                        }
                        {
                            tabSelected === 1 ?
                            <>
                    {         modalType === "add" ?       
                    <><span className='input-label'>Language</span>
                                    <DropdownMenu>
                                        <DropdownMenu.Toggle variant="success" id="dropdown-basic">
                                            {lang[selectedLanguage]}
                                        </DropdownMenu.Toggle> 
                                        <DropdownMenu.Menu popperConfig={popperConfig} className="dropdown-fixed">                          
                                        {(Object.keys(lang).map((obj,i)=>(
                                        <DropdownMenu.Item eventKey={i} key={i} onSelect={()=>{ setSelectedLanguage(obj)}}>{lang[obj]}</DropdownMenu.Item>
                                        )))}
                                        {/* <Dropdown.Item eventKey="enquiry" onSelect={selectOptionType}>Enquiry</Dropdown.Item> */}
                                    </DropdownMenu.Menu>   
                                    </DropdownMenu>   </>
                                    : null}

                            {
                                modalType === "add" || modalType === "edit" ? 
                                <>
                                <span className='input-label'>Category</span>
                            <div className='linked flex-row valign-wrapper unselectable checkbox' onClick={()=>{setSelectedCategory("MARKETING")}}>
                                {selectedCategory !== "MARKETING" ? <RadioButtonUnchecked/> : <RadioButtonChecked/>}
                                <span className='nowrap'>Marketing</span>
                            </div>
                            <div className='linked flex-row valign-wrapper unselectable checkbox' onClick={()=>{setSelectedCategory("UTILITY")}}>
                                {selectedCategory !== "UTILITY" ? <RadioButtonUnchecked/> : <RadioButtonChecked/>}
                                <span className='nowrap'>Utility</span>
                            </div> 
                            <div className='linked flex-row valign-wrapper unselectable checkbox' onClick={()=>{setSelectedCategory("AUTHENTICATION")}}>
                                {selectedCategory !== "AUTHENTICATION" ? <RadioButtonUnchecked/> : <RadioButtonChecked/>}
                                <span className='nowrap'>Authentication</span>
                            </div> </> : null}
                            </>
                            :
                            null
                        }
                        {
                            tabSelected === 1 && modalType !== "delete" ?
                           parameterCount.map((obj,i)=>( 
                           <label className="pure-material-textfield-outlined full-width margin-bottom-1x">
                                <span>Example value for Parameter {obj}</span>
                                <input type="text" onChange={(e)=>{
                                    let item  = {...parameter}
                                    item[i] = e.target.value;
                                    setParameter(item);
                                    }} className="browser-default" placeholder='Ex. John, 0121234567' value={parameter[i]}/>
                            </label> ))

                            :

                            null
                        }
                        {
                            tabSelected === 0 && modalType !== "delete" ?
                            <>
                            <span className='input-label margin-top-2x'>Origin</span>
                            {
                                account.map((obj,i)=>(
                                    <div className="team-channel-list" key={obj.account_name}>
                                        <input className="material filled-in" type="checkbox" id={"switch-account-"+obj.account_name} checked={handleAccount.indexOf(obj.account_name) !== -1 ? true : false} onChange={()=>toggleAccount(obj.account_name)}/><label className="material" htmlFor={"switch-account-"+obj.account_name}>{obj.account_user ? obj.account_user : obj.account_name}</label>                             
                                    </div>
                                ))
                            }
                            </>   
                        :
                        null
                        }
                        {
                            modalType === "delete" && tabSelected === 1 ? 
                            <h6>Names of an approved template that has been deleted cannot be used again for 30 days.</h6>
                            :
                            null
                        }
                    </BootstrapModal.Body>
                    <BootstrapModal.Footer>
                    <div>
                    <button className="btn-flat ng-close-btn waves-effect waves-dark margin-right-1x" onClick={()=>{setModalOpen(false);props.setChildrenModalOpened(true);setAttachment([]);}}>cancel</button>
                        {
                            !loading ?
                            (modalType === "add" ? 
                            (tabSelected === 0 ? <button className={(adding ? "disabled " : "")+"button-new"} onClick={addCannedResponse}>{adding ? "Adding" : "Add"}</button> : (
                                <button className="button-new" onClick={addWhatsappTemplate}>Add</button>
                            ))
                            :
                                (modalType === "delete" ? 
                                <button className="button-new delete-button" onClick={deleteCannedResponse}>Delete</button>
                                :
                                    (modalType === "edit" ? 
                                    <button className="button-new" onClick={()=>{if(tabSelected === 0){editCannedResponse()}else{editWhatsappTemplate()}}}>Edit</button>
                                    :
                                    null
                                    )
                                )
                            )
                            :
                            null
                        }
                    </div>
                    </BootstrapModal.Footer>
            </BootstrapModal>
            {/* <div className={'margin-bottom-2x pill-plan linked'+( tabSelected === 0 ? ' selected' : '')} onClick={()=>{setTabSelected(0)}}>Canned Response</div> */}
            {/* <div className={'pill-plan margin-left-1x linked'+( tabSelected === 1 ? ' selected' : '')} onClick={()=>{setTabSelected(1);loadWhatsapp();}}>Whatsapp</div> */}
            {(whatsappAccount.length > 0 && tabSelected === 1) || tabSelected === 0 ? <button className='button-new' onClick={()=>{setModalType("add");setModalOpen(true);props.setChildrenModalOpened(true);setHandleAccount([]);setErrorMessage("");}}>Add</button> : null}
            {tabSelected === 0 ?
            <>
            <h5 className='settings-title-text bold-text-2'>Voice</h5>
                
                <div className='flex-col'>
                    <div>
                        
                    </div>
                </div>
                {
                    canned.map((obj,i)=>(
                        <div className={"margin-right-1x status-item "} key={i}>
                            <div className='valign-wrapper'>
                                <div className='full-width margin-left-2x' style={{minWidth:'1px'}}>
                                    <h5 className={"bold-text-2 small-font label-item-title ellipsis-text "+(props.dark ? "" : "grey-text text-darken-3")}>{obj.name}</h5>  
                                    {/* <h6>{obj.origin}</h6> */}
                                </div>
                                <div className={"linked icon-button-hover-3 "+ (props.dark ? "dark-mode" : "")} onClick={()=>{edit(i)}}>
                                    <IconPencil 
                                        size={20} // set custom `width` and `height`
                                        // set `stroke` color
                                        stroke={2}  // set `stroke-width`
                                        strokeLinejoin="miter" // override other SVG props
                                    /> 
                                </div>
                                <div className={"linked icon-button-hover-3 "+ (props.dark ? "dark-mode" : "")} onClick={()=>{setSelectedId(obj.id);setModalType("delete");setModalOpen(true);props.setChildrenModalOpened(true)}}>
                                    <IconTrash 
                                        size={20} // set custom `width` and `height`
                                        // set `stroke` color
                                        stroke={2}  // set `stroke-width`
                                        strokeLinejoin="miter" // override other SVG props
                                    /> 
                                </div>
                            </div>
                        </div>
                    ))
                }
                
            </>
            :
            <>
            <div className='flex-row'>
            <h5 className='settings-title-text bold-text-2'>Whatsapp Templates</h5>
            <div className={"linked icon-button-hover-3 "+ (props.dark ? "dark-mode" : "")} onClick={()=>{loadWhatsapp()}}>
                <IconRefresh 
                    size={20} // set custom `width` and `height`
                    // set `stroke` color
                    stroke={2}  // set `stroke-width`
                    strokeLinejoin="miter" // override other SVG props
                /> 
            </div>
            </div>
                <div className='flex-col'>
                    <div>
                        
                    </div>
                </div>
                {

                    whatsappAccount.length === 0 ?
                    <div>
                        <h6>You don't have any WhatsApp Business Account associated.</h6>
                    </div>
                    :

                    whatsapp.map((obj,i)=>(
                        <div className={"margin-right-1x status-item "} key={i}>
                            <div className='valign-wrapper'>
                                <div className='full-width margin-left-2x' style={{minWidth:'1px'}}>
                                    <h5 className={"bold-text-2 small-font label-item-title ellipsis-text "+(props.dark ? "" : "grey-text text-darken-3")}>{obj.template_name}</h5>  
                                    <h6>{obj.approved === 0 ? "PENDING" : (obj.approved === 1 ? "APPROVED" : (obj.approved === 2 ? "REJECTED" : (obj.approved === 3 ? "PAUSED" : ""))) }</h6>
                                    {
                                        obj.wa_account_id !== 0 && whatsappAccount.length > 0 ?
                                        whatsappAccount.filter(account => account.id === obj.wa_account_id).map((elem,i2)=>(
                                            <h6>{elem.desc}</h6>
                                        ))
                                        :
                                        null
                                    }
                                </div>
                                {obj.approved === 1 ? <div className={"linked icon-button-hover-3 "+ (props.dark ? "dark-mode" : "")} onClick={()=>{edit(i);setErrorMessage("");}}>
                                    <IconPencil 
                                        size={20} // set custom `width` and `height`
                                        // set `stroke` color
                                        stroke={2}  // set `stroke-width`
                                        strokeLinejoin="miter" // override other SVG props
                                    /> 
                                </div> : null}
                                <div className={"linked icon-button-hover-3 "+ (props.dark ? "dark-mode" : "")} onClick={()=>{setSelectedId(obj.id);setModalType("delete");setModalOpen(true);props.setChildrenModalOpened(true);setErrorMessage("");}}>
                                    <IconTrash 
                                        size={20} // set custom `width` and `height`
                                        // set `stroke` color
                                        stroke={2}  // set `stroke-width`
                                        strokeLinejoin="miter" // override other SVG props
                                    /> 
                                </div>
                            </div>
                        </div>
                    ))
                }
                
            </>
            }
        </div>
    )
}