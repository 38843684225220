import React, { Component } from 'react'

// import Modal from 'react-modal';

// import PricingTable from './../components/PricingTable';

import LoginFrame from './Login';

// import ContactUs from './ContactUs';

// import { BrowserRouter as Router, Route, Link, Switch, Redirect,useHistory } from 'react-router-dom';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee,faComment,faEnvelope,faSitemap,faClock, faUserCheck, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
// import { faFacebook, faTwitter, faLine } from '@fortawesome/free-brands-svg-icons'
// import HomeImage from './../images/hyperceed_img.png';

// import Bg from './../images/20920.jpg';

// import { StickyContainer, Sticky} from 'react-sticky';

// import './../assets/bootstrap/css/bootstrap.min.css';
// import './../assets/font-awesome/css/font-awesome.min.css';
// import './../assets/css/animate.css';
// import './../assets/css/form-elements.css';
//import './../assets/css/style.css';
// import './../assets/css/media-queries.css';

// import socialLogo from './../assets/img/socialLogo.svg';

// import logo from './../assets/img/logo.png';

// import background from './../assets/img/backgrounds/1.jpg';

const modalStyles = {
  content : {
    top                   : 'auto',
    left                  : 'auto',
    right                 : 'auto',
    bottom                : 'auto',
    maxWidth:'',
    border: 'none',
    boxShadow: '0 2px 3px 0 rgba(60,64,67,.3),0 6px 10px 4px rgba(60,64,67,.15)',
    borderRadius : '10px',
    padding : '0px'
  },
  overlay : {
    backgroundColor: ('rgba(0,0,0,0.5)')
  }
};

export default class HomePage extends Component {

    state = {
        position:0,
        firstLoad: true,
        navDrawerShown: false,
        loginWindow: true
    }

    componentDidMount() {
      window.addEventListener('scroll', this.listenToScroll,true)
    }
    
    componentWillUnmount() {
      window.removeEventListener('scroll', this.listenToScroll,true)
    }

    closeModal = () => {
      this.setState({demoShown: false});
    }      
    
    listenToScroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop
    
      this.setState({
        navBarFixed: (winScroll > 200),
        demoShown:false     
      })
    }    

    closeLogin = () => {
      this.setState({loginWindow:false})
    }    

    // componentDidMount(){
    //     this.setState({loading:false})
    // }

    render() {
        return (
          <React.Fragment>
                 {(this.state.loginWindow ? <LoginFrame logging={this.props.logging} change={this.props.change} closeLogin={this.closeLogin} clear={this.props.clear} tfa={this.props.tfa} login={this.props.login} loginError={this.props.loginError} loginValid={this.props.loginValid} dark={this.props.dark} oauth={this.props.oauth} enableOAuth={this.props.enableOAuth}/> : null)}
                                   
            </React.Fragment>
        )
    }
}
