import React, { Component } from 'react'
import Variable from './../variable';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Moment from 'react-moment';
import moment from 'moment';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown'
import { ResponsiveBar } from '@nivo/bar'
import EmotionChart from './../components/EmotionChart';
import Circular from './../components/Circular';
import {NavigateNext,NavigateBefore,Close} from '@material-ui/icons'

import StackedBarChart from './../components/StackedBarChart';
import { isObject } from 'lodash';



export default class ServiceTime extends Component {

    host = Variable.host;

    constructor(props){
      super(props);
      this.state = {
        agentPool:[],
        agentValue:""
      }
    }

    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
  }

    componentDidMount(){
      axios.get(this.host+'/api/proxy/agent_pool', {params:{_token:this.getCookie("auth")}},{withCredentials: true})
      .then(res => {
        this.setState({agentPool:res.data});
      })
    }

  




    render(){
        return (
            <div className='padding-left-2x padding-right-2x'>
              {
                this.state.agentPool.map((obj,i)=>(
                  <table>
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Value</th>
                      </tr>                  
                    </thead>
                    <tbody>
                      <tr>
                        <td>Description</td>
                        <td>
                          <input value={obj.description.trim()} onChange={
                            (e)=>{
                              obj.description = e.target.value
                              this.setState({agentPool:this.state.agentPool});
                            }
                          }/>
                        </td>
                      </tr>
                      <tr>
                        <td>Agent List</td>
                        <td>
                          {   
                          obj.agentList.split("|").map((item,i) => (
                            item !== "" ? 
                            <div key={i} className="email-chip-2 unselectable">                        
                              <div className="email-chip-content valign-wrapper">
                                <span>{item}</span>
                                <div className="linked" onClick={()=>{
                                  var split = obj.agentList.split("|");
                                  var index = split.indexOf(item);
                                  if(index !==  -1){
                                    split.splice(index,1);
                                    var agentString = split.join("|");
                                    obj.agentList = agentString;
                                    this.setState({agentPool:this.state.agentPool})                                    
                                  }
                                }}>
                                    <Close/>
                                </div>
                              </div>
                            </div>
                            :
                            null
                          ))                     
 
                          }
                          <input
                            value={this.state.agentValue}
                            onChange={
                              (e) => {
                                this.setState({agentValue:e.target.value})
                              }
                            } 
                            onKeyDown={
                            (e) => {
                              if(e.key === 'Enter'){
                                var split = obj.agentList.split("|");
                                if(split[0] === ""){
                                  split[0] = this.state.agentValue
                                }
                                else{
                                  if(!split.includes(this.state.agentValue)){
                                    split.push(this.state.agentValue)
                                  }
                                }
                                var agentString = split.join("|");
                                obj.agentList = agentString;
                                this.setState({agentPool:this.state.agentPool,agentValue:""})
                              }
                            }
                            }/>
                      </td>
                      </tr>
                      <tr>
                        <td>Hunting Code</td>
                        <td>
                          <select className='browser-default' value={obj.huntingCode.trim()} onChange={(event)=>{
                            
                            obj.huntingCode = event.target.value;

                            this.setState({agentPool:this.state.agentPool});
                          }}>
                            {
                              this.props.references.huntingMode.map((hunting,i)=>(
                                <option value={hunting.huntingCode.trim()}>{hunting.description}</option>
                              ))
                            }
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>Hunting Timeout</td>
                        <td>
                          <input value={obj.huntingTimeout} onChange={
                            (e)=>{
                              obj.huntingTimeout = e.target.value
                              this.setState({agentPool:this.state.agentPool});
                            }
                          }/>
                        </td>
                      </tr>
                      <tr>
                        <td>Overflow Pool ID</td>
                        <td>
                          <select className='browser-default' value={obj.overflowPoolID} onChange={(event)=>{
                              
                              obj.overflowPoolID = event.target.value;

                              this.setState({agentPool:this.state.agentPool});
                            }}>
                              <option value="0">No Overflow</option>
                              {
                                this.state.agentPool.filter(item => item.ID !== obj.ID).map((item,i)=>(
                                  <option value={item.ID}>{item.description}</option>
                                ))
                              }
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>PassANI or DNIS</td>
                        <td>
                          <select className='browser-default' value={obj.passANIorDNIS.trim()} onChange={(event)=>{
                              
                              obj.passANIorDNIS = event.target.value;

                              this.setState({agentPool:this.state.agentPool});
                            }}>
                              <option value="passANI">passANI</option>
                              <option value="DNIS">DNIS</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>Add Prefix</td>
                        <td>
                          <input value={obj.addPrefix.trim()} onChange={
                            (e)=>{
                              obj.addPrefix = e.target.value
                              this.setState({agentPool:this.state.agentPool});
                            }
                          }/>
                        </td>
                      </tr>
                      <tr>
                        <td>Remove Prefix</td>
                        <td>
                          <input value={obj.removePrefix.trim()} onChange={
                            (e)=>{
                              obj.removePrefix = e.target.value
                              this.setState({agentPool:this.state.agentPool});
                            }
                          }/>                          
                        </td>
                      </tr>
                      <tr>
                        <td>Use Proceeding</td>
                        <td>
                          <select className='browser-default' value={obj.useProceeding} onChange={(event)=>{
                              
                              obj.useProceeding = event.target.value;

                              this.setState({agentPool:this.state.agentPool});
                            }}>
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                          </select>
                        </td>
                      </tr>
                      </tbody>
                  </table>
                ))
              }
            </div>
        );
    }
}